import { useRef } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useTeamTypeShortHand } from '@atlassian/jira-atlassian-account/src/controllers/index.tsx';
import { useFetchInitialProjectTemplateName } from '@atlassian/jira-onboarding-core/src/controllers/use-fetch-initial-project-template-name/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	KANBAN_TEAM_MANAGED_KEY,
	SCRUM_TEAM_MANAGED_KEY,
} from '@atlassian/jira-common-constants/src/project-template-type-keys.tsx';
import {
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	USER_SEGMENTATION_TEAM_TYPE_SOFTWARE,
} from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useFeatures } from '@atlassian/jira-navigation-apps-sidebar-next-gen-use-features/src/controllers/features/index.tsx';

export const isTailoredOnboardingM3Enabled = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('tailored_onboarding_m3');

	return fg('tailored_onboarding_m3_gate') && expConfig.get('isEnabled', false);
};

export const isTailoredOnboardingScrumAndSoftwareKanbanEnabled = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('tailored_onboarding_scrum_and_software_kanban');

	return expConfig.get('isEnabled', false);
};

/**
 * Checks if either the tailored onboarding M3 experiment or the tailored onboarding
 * scrum and software kanban experiment is enabled.
 *
 * This function is a combination check that returns true if at least one of the
 * experiments is active, allowing code to handle either case appropriately.
 *
 * @returns {boolean} True if either experiment is enabled, false otherwise.
 */
export const isEitherTailoredOnboardingEnabled = () => {
	return isTailoredOnboardingM3Enabled() || isTailoredOnboardingScrumAndSoftwareKanbanEnabled();
};

export const isTailoredOnboardingEnabled = functionWithCondition(
	() => fg('jira_tailored_onboarding_shared_experiment_check'),
	isEitherTailoredOnboardingEnabled,
	isTailoredOnboardingM3Enabled,
);

export const isEligibleForTailoredOnboardingM3 = ({
	teamType,
	templateKey,
	isSiteAdmin,
}: {
	teamType: string | null | undefined;
	templateKey: string | undefined;
	isSiteAdmin: boolean;
}): boolean => {
	if (!isSiteAdmin) {
		return false;
	}

	const isSoftwareTeamType = teamType === USER_SEGMENTATION_TEAM_TYPE_SOFTWARE;
	const isKanbanTemplate = templateKey?.startsWith(KANBAN_TEAM_MANAGED_KEY);

	if (isKanbanTemplate && !isSoftwareTeamType && isTailoredOnboardingM3Enabled()) {
		return true;
	}

	return false;
};

export const isEligibleForTailoredOnboardingScrumAndSoftwareKanban = ({
	teamType,
	templateKey,
	isSiteAdmin,
}: {
	teamType: string | null | undefined;
	templateKey: string | undefined;
	isSiteAdmin: boolean;
}): boolean => {
	if (!isSiteAdmin) {
		return false;
	}

	const isSoftwareTeamType = teamType === USER_SEGMENTATION_TEAM_TYPE_SOFTWARE;
	const isScrumTemplate = templateKey?.startsWith(SCRUM_TEAM_MANAGED_KEY);
	const isKanbanTemplate = templateKey?.startsWith(KANBAN_TEAM_MANAGED_KEY);

	if (
		isKanbanTemplate &&
		isSoftwareTeamType &&
		isTailoredOnboardingScrumAndSoftwareKanbanEnabled()
	) {
		return true;
	}

	if (isScrumTemplate && isTailoredOnboardingScrumAndSoftwareKanbanEnabled()) {
		return true;
	}

	return false;
};

export const isEligibleForTailoredOnboarding = ({
	teamType,
	templateKey,
	isSiteAdmin,
}: {
	teamType: string | null | undefined;
	templateKey: string | undefined;
	isSiteAdmin: boolean;
}): boolean => {
	return (
		isEligibleForTailoredOnboardingM3({
			teamType,
			templateKey,
			isSiteAdmin,
		}) ||
		isEligibleForTailoredOnboardingScrumAndSoftwareKanban({
			teamType,
			templateKey,
			isSiteAdmin,
		})
	);
};

export const useIsTailoredOnboardingM3EnabledAndEligible = () => {
	const isEnabled = isTailoredOnboardingM3Enabled();
	const isSiteAdmin = useIsSiteAdmin();
	const { data: projectData } = useProjectContext();
	const projectType = projectData?.projectType;
	const isSoftwareProject = projectType === 'software';
	const shouldFetch = isEnabled && isSiteAdmin && isSoftwareProject;

	const { isReady: teamTypeIsReady, teamType } = useTeamTypeShortHand({
		shouldFetch,
	});

	const { loading: projectContextLoading, data: projectContext } = useProjectContext();

	const { isReady: templateKeyIsReady, data: templateKey } = useFetchInitialProjectTemplateName({
		shouldFetch,
		projectId: projectContext?.projectId,
	});

	if (!isEnabled) {
		return {
			isReady: true,
			isTailoredOnboardingM3EnabledAndEligible: false,
			isTailoredOnboardingScrumAndSoftwareKanbanEnabled: false,
		};
	}

	const isSoftwareTeamType = teamType === USER_SEGMENTATION_TEAM_TYPE_SOFTWARE;
	const isTmpKanban = !!templateKey?.startsWith(KANBAN_TEAM_MANAGED_KEY);
	const isEligible = isSiteAdmin && isTmpKanban && !isSoftwareTeamType && isSoftwareProject;

	return {
		isReady: teamTypeIsReady && templateKeyIsReady && !projectContextLoading,
		isTailoredOnboardingM3EnabledAndEligible: isEnabled && isEligible,
		isTailoredOnboardingScrumAndSoftwareKanbanEnabled: false,
		teamType,
	};
};

const useIsTailoredOnboardingM3EnabledWithScrumAndSoftwareKanbanSupport = () => {
	const isEnabled = isTailoredOnboardingEnabled();
	const isSiteAdmin = useIsSiteAdmin();
	const { data: projectData } = useProjectContext();
	const projectType = projectData?.projectType;
	const isSoftwareProject = projectType === 'software';
	const shouldFetch = isEnabled && isSiteAdmin && isSoftwareProject;

	const { isReady: teamTypeIsReady, teamType } = useTeamTypeShortHand({
		shouldFetch,
	});

	const { loading: projectContextLoading, data: projectContext } = useProjectContext();

	const { isReady: templateKeyIsReady, data: templateKey } = useFetchInitialProjectTemplateName({
		shouldFetch,
		projectId: projectContext?.projectId,
	});

	if (!isEnabled) {
		return {
			isReady: true,
			isTailoredOnboardingM3EnabledAndEligible: false,
			isTailoredOnboardingScrumAndSoftwareKanbanEnabled: false,
		};
	}

	const isTailoredOnboardingM3EnabledAndEligible = isEligibleForTailoredOnboardingM3({
		teamType,
		templateKey,
		isSiteAdmin,
	});

	const IsTailoredOnboardingEnabledWithScrumAndSoftware =
		isEligibleForTailoredOnboardingScrumAndSoftwareKanban({
			teamType,
			templateKey,
			isSiteAdmin,
		});

	return {
		isReady: teamTypeIsReady && templateKeyIsReady && !projectContextLoading,
		isTailoredOnboardingM3EnabledAndEligible: isEnabled && isTailoredOnboardingM3EnabledAndEligible,
		isTailoredOnboardingScrumAndSoftwareKanbanEnabled:
			isEnabled && IsTailoredOnboardingEnabledWithScrumAndSoftware,
		teamType,
	};
};

// Do not use this hook for exposure, use isTailoredOnboardingM3EnabledWithExposure instead
export const useIsTailoredOnboardingEnabledAndEligible = functionWithCondition(
	() => fg('jira_tailored_onboarding_shared_experiment_check'),
	useIsTailoredOnboardingM3EnabledWithScrumAndSoftwareKanbanSupport,
	useIsTailoredOnboardingM3EnabledAndEligible,
);

export const isTailoredOnboardingM3EnabledWithExposure = ({
	isTmpKanban,
	isSoftwareTeamType,
	isSiteAdmin,
	analyticEvent,
	teamType,
}: {
	isTmpKanban: boolean;
	isSoftwareTeamType: boolean;
	isSiteAdmin: boolean;
	analyticEvent: UIAnalyticsEvent;
	teamType: string | null | undefined;
}): boolean => {
	if (isSiteAdmin && isTmpKanban && !isSoftwareTeamType && fg('tailored_onboarding_m3_gate')) {
		fireTrackAnalytics(analyticEvent, 'tailoredM3Redirect personalised', {
			teamType,
			isEnabled: expVal('tailored_onboarding_m3', 'isEnabled', false),
		});
		return expVal('tailored_onboarding_m3', 'isEnabled', false);
	}

	return false;
};

export const isTailoredOnboardingM3EnabledWithExposureWithScrumAndSoftwareKanban = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	isTmpKanban,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	isSoftwareTeamType,
	isSiteAdmin,
	analyticEvent,
	teamType,
	templateKey,
}: {
	isTmpKanban: boolean;
	isSoftwareTeamType: boolean;
	isSiteAdmin: boolean;
	analyticEvent: UIAnalyticsEvent;
	teamType: string | null | undefined;
	templateKey: string | undefined;
}): boolean => {
	if (
		isEligibleForTailoredOnboardingM3({
			teamType,
			templateKey,
			isSiteAdmin,
		})
	) {
		fireTrackAnalytics(analyticEvent, 'tailoredM3Redirect personalised', {
			teamType,
			templateKey,
			isEnabled: expVal('tailored_onboarding_m3', 'isEnabled', false),
		});

		return expVal('tailored_onboarding_m3', 'isEnabled', false);
	}

	if (
		isEligibleForTailoredOnboardingScrumAndSoftwareKanban({
			teamType,
			templateKey,
			isSiteAdmin,
		})
	) {
		fireTrackAnalytics(
			analyticEvent,
			'tailoredOnboardingeWithScrumAndSoftwareKanban personalised',
			{
				teamType,
				templateKey,
				isEnabled: expVal('tailored_onboarding_scrum_and_software_kanban', 'isEnabled', false),
			},
		);

		return expVal('tailored_onboarding_scrum_and_software_kanban', 'isEnabled', false);
	}

	return false;
};

export const isTailoredOnboardingEnabledWithExposure = functionWithCondition(
	() => fg('jira_tailored_onboarding_shared_experiment_check'),
	isTailoredOnboardingM3EnabledWithExposureWithScrumAndSoftwareKanban,
	isTailoredOnboardingM3EnabledWithExposure,
);

export const useIsTailoredOnboardingM2p5EligibleWithExposure = ({
	isBusinessProject,
	isTrelloCrossflow,
	isOnboarding,
}: {
	isBusinessProject: boolean;
	isTrelloCrossflow?: boolean;
	isOnboarding: boolean;
}) => {
	const isSiteAdmin = useIsSiteAdmin();
	const isBasicEligible = isSiteAdmin && isBusinessProject && !isTrelloCrossflow;
	const { isReady: teamTypeIsReady, teamType } = useTeamTypeShortHand({
		shouldFetch: isBasicEligible,
	});
	const isTargettedTeamType = ![
		USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
		USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	].includes(teamType ?? '');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireOnce = useRef(false);
	if (!isBasicEligible) {
		return { isEligible: false, isReady: true };
	}
	if (!teamTypeIsReady) {
		return { isEligible: undefined, isReady: false };
	}
	if (isBusinessProject && isSiteAdmin && isTargettedTeamType) {
		if (!fireOnce.current) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'tailoredM2p5 exposed', {
				teamType,
				isOnboarding,
				isEnabled: expVal('tailored_onboarding_m2p5', 'isEnabled', false),
			});
			fireOnce.current = true;
		}
		return {
			isEligible: expVal('tailored_onboarding_m2p5', 'isEnabled', false),
			isReady: true,
			teamType,
		};
	}
	return { isEligible: false, isReady: true, teamType };
};

export const useIsScrumProject = () => {
	if (!isTailoredOnboardingScrumAndSoftwareKanbanEnabled()) {
		return false;
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data } = useFeatures();

	return (data?.hasSprint && data?.hasBacklog) ?? false;
};
