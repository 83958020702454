/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { filtersDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/filtersDirectoryRoute.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import messages from './messages.tsx';
import type { FooterProps } from './types.tsx';

// Removes the bullet (•) and padding from <li>
const filterUnorderedList = css({
	listStyle: 'none',
	padding: '0',
});

export const Footer = ({ testIdPrefix }: FooterProps) => {
	const { formatMessage } = useIntl();

	const testId = testIdConcat(testIdPrefix, 'items');

	return fg('camptasks-1105-filter-menu-list-markup') ? (
		<ul data-testid={testId} css={filterUnorderedList}>
			<li>
				<MenuItem
					analytics={{
						actionSubjectId: 'filtersNavigationMenuItem',
						menuItemId: 'viewAll',
						menuItemType: 'action',
						position: 0,
					}}
					href="/jira/filters?searchName=&Search=Search&filterView=search"
					testId={testIdConcat(testId, 'item-view-all')}
					to={filtersDirectoryRoute}
				>
					{formatMessage(messages.viewAll)}
				</MenuItem>
			</li>
			<li>
				<MenuItem
					analytics={{
						actionSubjectId: 'filtersNavigationMenuItem',
						menuItemId: 'create',
						menuItemType: 'action',
						position: 1,
					}}
					href={`/issues/?jql=${encodeURIComponent('created >= -30d order by created DESC')}`}
					testId={testIdConcat(testId, 'item-create')}
				>
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.viewAllIssuesIssueTermRefresh
							: messages.viewAllIssues,
					)}
				</MenuItem>
			</li>
		</ul>
	) : (
		<span data-testid={testId}>
			<MenuItem
				analytics={{
					actionSubjectId: 'filtersNavigationMenuItem',
					menuItemId: 'viewAll',
					menuItemType: 'action',
					position: 0,
				}}
				href="/jira/filters?searchName=&Search=Search&filterView=search"
				testId={testIdConcat(testId, 'item-view-all')}
				to={filtersDirectoryRoute}
			>
				{formatMessage(messages.viewAll)}
			</MenuItem>
			<MenuItem
				analytics={{
					actionSubjectId: 'filtersNavigationMenuItem',
					menuItemId: 'create',
					menuItemType: 'action',
					position: 1,
				}}
				href={`/issues/?jql=${encodeURIComponent('created >= -30d order by created DESC')}`}
				testId={testIdConcat(testId, 'item-create')}
			>
				{formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.viewAllIssuesIssueTermRefresh
						: messages.viewAllIssues,
				)}
			</MenuItem>
		</span>
	);
};
