import log, { type Event } from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { IssueErrorConstructor } from './issue-error';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	EntityLimitError,
	getEntityLimitError,
	getErrorMessageDescriptor,
} from './entity-limit-error';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ENTITY_LIMIT_ERROR_TYPE } from './entity-limit-error/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DuplicateNameError, isDuplicateFieldNameError } from './duplicate-name-error';

/**
 * Wrapper over log.safeErrorWithoutCustomerData that converts FetchError's originalResponse to a string
 */
export const logSafeErrorWithoutCustomerDataWrapper = async (
	location: string,
	message: string,
	event?: Event,
) => {
	const modifiedEvent = event;
	if (modifiedEvent instanceof FetchError && modifiedEvent.originalResponse) {
		const originalResponseText = await modifiedEvent.originalResponse.text();
		modifiedEvent.message = `${modifiedEvent.message} - ${originalResponseText}`;
		delete modifiedEvent.originalResponse;
	}

	log.safeErrorWithoutCustomerData(location, message, modifiedEvent);
};
