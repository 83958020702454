import React, { useContext, useCallback } from 'react';
import {
	SECTION_ITEM_MAP,
	SECTION_ITEM_MORE,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import {
	NavItemsContext,
	PeekMenuIdContext,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import type {
	JiraConfigurableNavigationItem,
	OnCloseProps,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { L1MoreNavMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/l1-more-nav-menu-item/main.tsx';
import type { ConfigurableMenuItem$key } from '@atlassian/jira-relay/src/__generated__/ConfigurableMenuItem.graphql';
import { GlobalMoreMenu } from '@atlassian/global-more-menu';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { CustomizableMenuItemData } from '@atlassian/customization-modal';
import { MoreMenuIsOpenContext } from '../../../controllers/context.tsx';
import { CustomiseSidebar } from '../customise-sidebar/index.tsx';
import { MenuItem, MoreMenuItem } from './more-menu-item/index.tsx';
import { PeekMenuItem } from './peek-menu-item/index.tsx';
import messages from './messages.tsx';
import { CustomizeSidebar } from './customize-sidebar/index.tsx';

export type MoreMenuItemProps = {
	navItems: JiraConfigurableNavigationItem[];
	menuData: ConfigurableMenuItem$key;
	productItemsData: CustomizableMenuItemData[];
	appItemsData: CustomizableMenuItemData[];
};

export function SidebarCustomizationOld({ navItems, menuData }: MoreMenuItemProps) {
	const { moreMenuIds } = useContext(NavItemsContext);
	const { peekMenuId, setPeekMenuId } = useContext(PeekMenuIdContext);
	const { moreMenuIsOpen, setMoreMenuIsOpen } = useContext(MoreMenuIsOpenContext);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const handleMenuClose = useCallback(() => setMoreMenuIsOpen(false), [setMoreMenuIsOpen]);

	const handleMenuToggle = useCallback(() => {
		setMoreMenuIsOpen((value) => !value);
		sendSectionItemAnalyticsEvent({ sectionItem: SECTION_ITEM_MORE });
	}, [sendSectionItemAnalyticsEvent, setMoreMenuIsOpen]);

	const MenuItems = [
		({ onClose }: OnCloseProps) => (
			<>
				{moreMenuIds
					.filter((menuId) => menuId !== peekMenuId)
					.map((menuId) => (
						<MenuItem
							key={menuId}
							menuId={menuId}
							onClick={(e) => {
								setPeekMenuId(menuId);
								onClose?.(e);

								sendSectionItemAnalyticsEvent({
									menuId,
									sectionItem: SECTION_ITEM_MAP[menuId],
								});
							}}
						/>
					))}
			</>
		),
		() => <CustomiseSidebar navItems={navItems} />,
	];

	return moreMenuIds.length === 0 ? (
		<CustomiseSidebar navItems={navItems} />
	) : (
		<>
			<PeekMenuItem menuData={menuData} />
			<L1MoreNavMenuItem
				MenuItems={MenuItems}
				isOpen={moreMenuIsOpen}
				onClose={handleMenuClose}
				onToggle={handleMenuToggle}
			/>
		</>
	);
}

export function SidebarCustomizationNew({
	menuData,
	productItemsData,
	appItemsData,
}: MoreMenuItemProps) {
	const { moreMenuIds } = useContext(NavItemsContext);
	const { peekMenuId } = useContext(PeekMenuIdContext);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const { formatMessage } = useIntl();

	return moreMenuIds.length === 0 ? (
		<CustomizeSidebar productItemsData={productItemsData} appItemsData={appItemsData} />
	) : (
		<>
			<PeekMenuItem menuData={menuData} />
			<GlobalMoreMenu
				productItemsData={productItemsData}
				appItemsData={appItemsData}
				menuItemComponent={MoreMenuItem}
				customizeSidebarMenuItem={
					<CustomizeSidebar productItemsData={productItemsData} appItemsData={appItemsData} />
				}
				moreMenuItemLabel={formatMessage(messages.more)}
				peekingMenuIds={peekMenuId ? [peekMenuId] : undefined}
				onClickMoreMenuTrigger={() =>
					sendSectionItemAnalyticsEvent({ sectionItem: SECTION_ITEM_MORE })
				}
			/>
		</>
	);
}

export const SidebarCustomization = componentWithFG(
	'blu-6576_migrate_to_platform_customisation',
	SidebarCustomizationNew,
	SidebarCustomizationOld,
);
