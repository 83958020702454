import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { permissionSchemePageResource } from '@atlassian/jira-router-resources-admin-pages-permissions/src/services/permission-scheme/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsPermissionSchemesRoute } from '@atlassian/jira-router-routes-admin-pages-permission-schemes-routes/src/globalSettingsPermissionSchemesRoute.tsx';
import PermissionSchemePage, {
	LazyPermissionSchemePage,
} from './ui/spa/admin-pages/permission-schemes/permission-scheme-page/index.tsx';

export const globalSettingsPermissionSchemesRouteEntry = createEntry(
	globalSettingsPermissionSchemesRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'global-admin.issues.configure-permission-scheme',
		component: PermissionSchemePage,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			permissionSchemePageResource,
		],
		canTransitionIn: () => true,
		forPaint: [LazyAtlassianNavigation, LazyPermissionSchemePage],
		meta: {
			reporting: {
				id: 'PermissionScheme',
				packageName: 'jiraAdminPagesPermissionScheme',
				teamName: 'gordian',
			},
		},
	}),
);
