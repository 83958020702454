// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { sortedInsertCollection } from '../utils.tsx';

export const copyCollection =
	(
		collectionUUID: string,
		patch: {
			uuid: string | null;
			name: string;
			ownerId: string | null;
		},
	): Action<State, Props> =>
	async ({ setState, getState }, { onSuccess }) => {
		const state = getState();

		if (!patch.uuid) {
			return;
		}

		const originalCollection = state.collections.data.find(
			(collection) => collection.uuid === collectionUUID,
		);

		if (originalCollection) {
			const clonedCollection = {
				...originalCollection,
				uuid: patch.uuid,
				name: patch.name,
				ownerId: patch.ownerId || '',
			};

			setState({
				collections: {
					...state.collections,
					data: sortedInsertCollection(state.collections.data, clonedCollection),
				},
			});

			onSuccess?.('copyCollection');
		}
	};
