import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsEmailRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsEmailRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { navigation } from './common/constants.tsx';
import { HelpCenterSettingsEmail, LazyHelpCenterSettingsEmail } from './ui/email/index.tsx';

export const helpCenterSettingsEmailRouteEntry = createEntry(helpCenterSettingsEmailRoute, () => ({
	group: 'helpcenter-settings',
	perfMetricKey: 'help-center-settings.email',
	layout: serviceProjectLayout,
	navigation,
	component: componentWithFG('jcs_master_flag', HelpCenterSettingsEmail, ErrorPagesNotFound),
	forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsEmail],
	resources: [...getServiceDeskSettingsResources()],
}));
