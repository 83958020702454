/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/HorizontalNavJiraInnerQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common/src/index.tsx';

export const jiraHorizontalNavEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-jira-horizontal-nav-entrypoint" */ './src/ui/HorizontalNavJiraInner'
			),
	),
	getPreloadProps: ({
		context: { match, query },
		tenantContext: { cloudId },
	}: EntryPointRouteParams) => {
		const boardId = match.params.boardId;
		const { projectKey, projectId } = getProjectKeyId(match, query);

		const projectIdOrKey = projectId || projectKey;

		const isTMPBoardRoute = boardId ? match.url.includes('/software/projects/') : false;

		const boardAri = boardId ? `ari:cloud:jira-software:${cloudId}:board/${boardId}` : '';

		const projectAri = projectId
			? JiraProjectAri.create({
					siteId: cloudId,
					projectId,
				}).toString()
			: '';

		let containerNavigationInput;
		if (isTMPBoardRoute) {
			containerNavigationInput = projectAri
				? { scopeId: boardAri }
				: { projectKeyQuery: { cloudId, projectKey } };
		} else {
			containerNavigationInput =
				boardAri || projectAri
					? { scopeId: boardAri || projectAri }
					: { projectKeyQuery: { cloudId, projectKey } };
		}

		return {
			queries: {
				horizontalNavQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						cloudId,
						projectIdOrKey,
						withProject: Boolean(projectIdOrKey),
						boardId: boardAri,
						withBoard: Boolean(boardAri),
						containerNavigationInput,
						isTMPBoardRoute,
						first: NAVIGATION_ITEMS_PAGE_SIZE,
					},
				},
			},
		};
	},
});
