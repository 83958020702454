import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { advancedRoadmapsScenarioIdResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { legacyPlanPageRedirectRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/legacyPlanPageRedirectRoute.tsx';
import { RedirectLegacyPlanPage } from './common/ui/redirect/plan/index.tsx';
import { LazyPlanTimeline } from './ui/plan-timeline/index-old.tsx';

export const legacyPlanPageRedirectRouteEntry = createEntry(legacyPlanPageRedirectRoute, () => ({
	group: 'software',
	component: RedirectLegacyPlanPage,
	isRedirect: true,
	layout: globalLayout,
	resources: [...getNavigationResources(), advancedRoadmapsScenarioIdResource],

	navigation: {
		menuId: MENU_ID.PLANS,
	},

	forPaint: [LazyAtlassianNavigation, LazyPlanTimeline],
}));
