import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import Tick from '@atlaskit/icon/core/migration/success--check-circle';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import {
	AkFlag,
	useFlagsService,
	type Flag,
	AkAutoDismissFlag,
	toFlagId,
} from '@atlassian/jira-flags';
import type {
	IdentifiableFlag,
	NotificationHandlers,
	PartialFlagProps,
	DismissalHandler,
	IdentifiableCustomFlag,
} from './types.tsx';

const customFlagCommon = (
	notification: PartialFlagProps,
	onDismiss: DismissalHandler,
	AkFlagComponent: typeof AkFlag | typeof AkAutoDismissFlag,
): Flag => {
	const id = uuid();

	return {
		id,
		render: (props) => (
			<AkFlagComponent
				id={id}
				{...notification}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onDismissed={(flagId) => onDismiss(flagId as string)}
				{...props}
				messageId={
					notification.messageId ?? `polaris-lib-notifications.controllers.ak-flag-component.${id}`
				}
				messageType={notification.messageType ?? 'transactional'}
			/>
		),
	};
};

const customFlag = (notification: PartialFlagProps, onDismiss: DismissalHandler): Flag =>
	customFlagCommon(notification, onDismiss, AkFlag);

const customAutoDismissFlag = (notification: PartialFlagProps, onDismiss: DismissalHandler): Flag =>
	customFlagCommon(notification, onDismiss, AkAutoDismissFlag);

const success = (notification: PartialFlagProps, onDismiss: DismissalHandler): Flag =>
	customAutoDismissFlag(
		{
			title: notification.title,
			description: notification.description,
			actions: notification.actions?.map((action, index: number) => ({
				...action,
				testId: `polaris.nofitication.success.action.${
					action.testId !== undefined ? action.testId : `action-${index + 1}`
				}`,
			})),
			icon: <Tick spacing="spacious" label="Success icon" color={token('color.icon.success')} />,
			appearance: 'normal',
			testId: 'polaris-lib-notifications.controllers.notification.success',
			messageId:
				notification.messageId ??
				'polaris-lib-notifications.controllers.customAutoDismissFlag.success',
			messageType: notification.messageType ?? 'transactional',
		},
		onDismiss,
	);

const errorWithRefresh = (notification: PartialFlagProps, onDismiss: DismissalHandler): Flag =>
	customAutoDismissFlag(
		{
			title: notification.title,
			description: notification.description,
			actions: [
				...(notification.actions || []),
				{
					content: 'Refresh',
					testId: 'refresh',

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					onClick: () => window.location.reload(),
				},
			].map((action, index) => ({
				...action,
				testId: `polaris.nofitication.error-refresh.action.${
					action.testId !== undefined ? action.testId : `action-${index + 1}`
				}`,
			})),
			icon: <ErrorIcon spacing="spacious" label="Error" color={token('color.icon.danger')} />,
			appearance: 'normal',
			testId: 'polaris-lib-notifications.controllers.notification.error-refresh',
			messageId:
				notification.messageId ??
				'polaris-lib-notifications.controllers.customAutoDismissFlag.errorWithRefresh',
			messageType: notification.messageType ?? 'transactional',
		},
		onDismiss,
	);

const error = (notification: PartialFlagProps, onDismiss: DismissalHandler): Flag =>
	customAutoDismissFlag(
		{
			title: notification.title,
			description: notification.description,
			actions: notification.actions?.map((action, index: number) => ({
				...action,
				testId: `polaris.nofitication.error.action.${
					action.testId !== undefined ? action.testId : `action-${index + 1}`
				}`,
			})),
			icon: <ErrorIcon spacing="spacious" label="Error" color={token('color.icon.danger')} />,
			appearance: 'normal',
			testId: 'polaris-lib-notifications.controllers.notification.error',
			messageId:
				notification.messageId ??
				'polaris-lib-notifications.controllers.customAutoDismissFlag.error',
			messageType: notification.messageType ?? 'transactional',
		},
		onDismiss,
	);

export const useNotifications = (): NotificationHandlers => {
	const { showFlag, dismissFlag } = useFlagsService();

	return useMemo(
		() => ({
			customFlag: (notification: PartialFlagProps) => {
				const flagId = showFlag(customFlag(notification, dismissFlag));
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return () => dismissFlag(flagId as string);
			},
			success: (notification: PartialFlagProps) => {
				const flagId = showFlag(success(notification, dismissFlag));
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return () => dismissFlag(flagId as string);
			},
			errorWithRefresh: (notification: PartialFlagProps) => {
				const flagId = showFlag(errorWithRefresh(notification, dismissFlag));
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return () => dismissFlag(flagId as string);
			},
			error: (notification: PartialFlagProps) => {
				const flagId = showFlag(error(notification, dismissFlag));
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return () => dismissFlag(flagId as string);
			},
			showFlag: (flag: IdentifiableFlag) => {
				const id = flag.id !== undefined ? flag.id : toFlagId(uuid());
				const flagId = showFlag({
					...flag,
					id,
					key: id,
					messageId: flag.messageId ?? `polaris-lib-notifications.controllers.showFlag.${id}`,
					messageType: flag.messageType ?? 'transactional',
				});
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return () => dismissFlag(flagId as string);
			},
			showCustomFlag: (flag: IdentifiableCustomFlag) => {
				const id = flag.id !== undefined ? flag.id : toFlagId(uuid());
				const flagId = showFlag({
					messageId: `polaris-lib-notifications.controllers.showCustomFlag.${id}`,
					messageType: 'transactional',
					...flag,
					id,
				});

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return () => dismissFlag(flagId as string);
			},
			dismissFlag,
		}),
		[showFlag, dismissFlag],
	);
};
