import React from 'react';
import { Nav4NotificationsIndicator } from './Nav4NotificationsIndicator.tsx';

type Nav4NotificationsBadgeProps = {
	count: number;
};

export const Nav4NotificationsBadge = (props: Nav4NotificationsBadgeProps) => {
	const { count } = props;
	return <Nav4NotificationsIndicator count={count} />;
};
