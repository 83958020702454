import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { servicedeskCmdbInsightConfigureGlobalRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightConfigureGlobalRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import GlobalConfigure, {
	LazyServicedeskCmdbGlobalConfigure,
} from './ui/global-configure/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbInsightConfigureGlobalRouteEntry = createEntry(
	servicedeskCmdbInsightConfigureGlobalRoute,
	() => ({
		group: 'servicedesk',
		layout: globalLayout,
		navigation: { menuId: MENU_ID.INSIGHT },
		ufoName: 'servicedesk-cmdb-insight-configure-global',
		component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : GlobalConfigure,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbGlobalConfigure],
		perfMetricKey: 'service-management.insight-cmdb.interact-with-global-config-page',
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION),
				teamName: 'falcons',
			},
		},
	}),
);
