/**
 * @generated SignedSource<<f97e78ab59091e8012bd8a05696ce789>>
 * @relayHash c51281360f2d7bd5387e9bb058f11f6d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 29dd5915a4af58bbd22d79842971790506a1f3ab87507ab96eca150acfd91229

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PlanViewNotFoundQuery } from './PlanViewNotFoundQuery.graphql';

const node: PreloadableConcreteRequest<PlanViewNotFoundQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "29dd5915a4af58bbd22d79842971790506a1f3ab87507ab96eca150acfd91229",
    "metadata": {},
    "name": "PlanViewNotFoundQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
