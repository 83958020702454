import pick from 'lodash/fp/pick';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Changeboarding, Onboarding } from './types.tsx';

const PERSIST_KEY = 'jira.user.arj.onboarding';

export const rehydrate: Action<State> = async ({ getState, setState }) => {
	if (getState()._rehydrated) {
		return;
	}

	const assertUnreachable = (_: never): never => {
		throw new Error("Didn't expect to get here");
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const parseDate = (raw: any): Date => {
		const maybeDate = new Date(raw);
		return !Number.isNaN(maybeDate.getTime()) ? maybeDate : new Date();
	};

	// Mark as any since it's assumed to be opaque, we ensure type safety in the code below.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const raw: any = (await userPreferenceAPI.get(PERSIST_KEY)) ?? {};

	const changeboarding: Changeboarding | undefined = (() => {
		try {
			const stage: Changeboarding['stage'] = raw.changeboarding?.stage;
			switch (stage) {
				case 'READY':
					return { stage };
				case 'CLOSED':
					return { stage, closedAt: parseDate(raw.changeboarding?.closedAt) };
				default:
					assertUnreachable(stage);
					return undefined;
			}
		} catch (err) {
			return undefined;
		}
	})();

	const onboarding: Onboarding | undefined = (() => {
		try {
			const stage: Onboarding['stage'] = raw.onboarding?.stage;
			switch (stage) {
				case 'READY':
					return { stage };
				case 'CLOSED':
					return { stage };
				default:
					assertUnreachable(stage);
					return undefined;
			}
		} catch (err) {
			return undefined;
		}
	})();

	setState({
		_rehydrated: true,
		changeboarding,
		onboarding,
	});
};

const persist =
	(update?: Partial<State>): Action<State> =>
	async ({ getState }) => {
		const serialize = pick(['changeboarding', 'onboarding']);
		userPreferenceAPI.set(
			PERSIST_KEY,
			serialize({
				...getState(),
				...update,
			}),
		);
	};

export const update =
	(options: { ui: boolean; persist: boolean } = { ui: true, persist: true }) =>
	(partial: Partial<State>): Action<State> =>
	async ({ getState, setState, dispatch }) => {
		const { _rehydrated, _rehydratePromise } = getState();

		if (!_rehydrated) {
			await _rehydratePromise;
		}

		if (options.ui) {
			setState(partial);
		}

		if (options.persist) {
			dispatch(persist({ ...getState(), ...partial }));
		}
	};
