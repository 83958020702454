import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getQueuesResources,
	getIssueListSsrResource,
	getQueueConfigResource,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskPracticeEditRoute } from '@atlassian/jira-router-routes-servicedesk-queues-routes/src/servicedeskPracticeEditRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import ServiceDeskQueuesEditView, { LazyServicedeskQueuesEditView } from './ui/edit-view/index.tsx';
import { ServicedeskQueuesConfig, LazyServicedeskQueuesConfig } from './ui/queues-config/index.tsx';
import { serviceDeskQueuesConfigPageEntryPoint } from './ui/queues-config-page-entry-point/index.tsx';

// TODO: Remove the `forPaint` and `component` properties when jsm_views_inside_queues_-_main_flag is cleaned up
export const servicedeskPracticeEditRouteEntry = createEntry(servicedeskPracticeEditRoute, () => ({
	group: 'servicedesk',
	component: componentWithCondition(
		() => fg('jsm_views_inside_queues_-_main_flag'),
		ServicedeskQueuesConfig,
		ServiceDeskQueuesEditView,
	),
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getQueuesResources(),
		...getIssueListSsrResource(),
		getConsolidationStateResource(),
		...getQueueConfigResource(),
	],
	forPaint: [
		LazyAtlassianNavigation,
		fg('jsm_views_inside_queues_-_main_flag')
			? LazyServicedeskQueuesConfig
			: LazyServicedeskQueuesEditView,
	],
	pageId: ({ query, match }) =>
		fg('jsm_views_inside_queues_-_main_flag')
			? `spa-apps/servicedesk/queues/views-config/${query.config}`
			: `spa-apps/servicedesk/queues/edit/${(match.params && match.params.queueId) || ''}`,
	entryPoint() {
		return fg('jsm_views_inside_queues_-_main_flag')
			? serviceDeskQueuesConfigPageEntryPoint
			: undefined;
	},
}));
