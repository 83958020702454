import React from 'react';
import { detailResources } from '@atlassian/eoc-incoming-call/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalIncomingCallDetailRoute } from '@atlassian/jira-router-routes-eoc-incoming-call-routes/src/globalIncomingCallDetailRoute.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { LazyIncomingCall, LazyIncomingCallDetail } from './ui/async.tsx';

const IncomingCallDetail = () => (
	<LazyPage
		Page={LazyIncomingCallDetail}
		pageId="async-jira-eoc-incoming-call-detail"
		shouldShowSpinner={false}
	/>
);

// Global routes
export const globalIncomingCallDetailRouteEntry = createEntry(
	globalIncomingCallDetailRoute,
	() => ({
		group: 'eoc',
		component: IncomingCallDetail,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			...detailResources(),
		],
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		forPaint: [LazyAtlassianNavigation, LazyIncomingCall],
	}),
);
