import { useEffect } from 'react';
import { type RefetchFnDynamic, useRelayEnvironment, fetchQuery } from 'react-relay';
import type { ConcreteRequest, OperationType } from 'relay-runtime';
import { useGetCurrentUrl, useGetCurrentUrlPath } from '../use-get-current-url/index.tsx';

type OperationWithCloudIdAndCurrentURL = Omit<OperationType, 'variables'> & {
	readonly variables: { cloudId: string; currentURL: string; entityAriProjects: string };
};

type UseRefetchProjectsParams<
	RefetchContentKey extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
	QueryVariables,
> = {
	cloudId: string;
	isSelectedPath(menuId: string): boolean;
	menuId: string;
	refetch: RefetchFnDynamic<OperationWithCloudIdAndCurrentURL, RefetchContentKey>;
	QUERY: ConcreteRequest;
	queryVariables?: Partial<QueryVariables>;
};

export function useRefetchWhenIsSelected<
	K extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
	Q,
>(params: UseRefetchProjectsParams<K, Q>) {
	const { cloudId, isSelectedPath, menuId, refetch, queryVariables, QUERY } = params;
	const environment = useRelayEnvironment();
	const currentURL = useGetCurrentUrl();
	const currentURLPath = useGetCurrentUrlPath();

	useEffect(() => {
		if (isSelectedPath(menuId)) {
			fetchQuery(environment, QUERY, {
				cloudId,
				currentURL,
				currentURLPath,
				...queryVariables,
			}).subscribe({
				complete: () => {
					refetch(
						{ cloudId, currentURL, ...queryVariables },
						{
							fetchPolicy: 'store-only',
						},
					);
				},
			});
		}
	}, [
		cloudId,
		isSelectedPath,
		menuId,
		refetch,
		currentURL,
		currentURLPath,
		environment,
		queryVariables,
		QUERY,
	]);
}
