import { projectSettingsCustomerServiceFeaturesRoute } from '@atlassian/jira-router-routes-project-settings-customer-service-routes/src/projectSettingsCustomerServiceFeaturesRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { featuresResource } from '@atlassian/jira-router-resources-service-project-features/src/services/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { projectSettingFeaturesAppPageEntryPoint } from './ui/project-settings-features/index.tsx';

export const projectSettingsCustomerServiceFeaturesRouteEntry = createEntry(
	projectSettingsCustomerServiceFeaturesRoute,
	() => ({
		group: 'project-settings-servicedesk',
		ufoName: 'jcs-project-settings.features',
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('berry-5039-jcs-features-page')
				? projectSettingFeaturesAppPageEntryPoint
				: undefined;
		},
		layout: serviceProjectLayout,
		navigation: {
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			featuresResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation],
		perfMetricKey: 'jcs-project-settings.features',
	}),
);
