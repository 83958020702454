import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async.tsx';
import { planLayout } from '@atlassian/jira-plan-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwarePlanSettingsSchedulingRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwarePlanSettingsSchedulingRoute.tsx';
import PlanSettingsSchedulingPage, {
	LazyPlanSettingsScheduling,
} from './ui/plan-settings-scheduling/index.tsx';

export const softwarePlanSettingsSchedulingRouteEntry = createEntry(
	softwarePlanSettingsSchedulingRoute,
	() => ({
		group: 'software',
		component: PlanSettingsSchedulingPage,
		layout: composeLayouts(globalLayout, planLayout),
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],
		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},
		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},
		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsScheduling],
	}),
);
