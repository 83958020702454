import React from 'react';
import type ScreensPageType from '@atlassian/jira-admin-pages-screens-page/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyFieldsPage = lazyForPaint<typeof ScreensPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-admin-pages-fields-page", jiraSpaEntry: "async-jira-admin-pages-fields-page" */ '@atlassian/jira-admin-pages-fields-page'
		),
	),
);

export const FieldsPage = () => (
	<LazyPage Page={LazyFieldsPage} pageId="jira-admin-pages-fields-page" shouldShowSpinner />
);
