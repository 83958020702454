/**
 * @generated SignedSource<<834fddde735677817bb414d426c4ffa7>>
 * @relayHash b1d5bddbd0778f4be3f4876e1525e6f4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 661dfc8e2a4d3163499a24e3bae8c13315e369090fa8e455d22560e15a9e00f4

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListEmbedQuery } from './src_jiraSoftwareListEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "661dfc8e2a4d3163499a24e3bae8c13315e369090fa8e455d22560e15a9e00f4",
    "metadata": {},
    "name": "src_jiraSoftwareListEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
