import { fetchQuery, graphql } from 'react-relay';
// eslint-disable-next-line jira/import-whitelist
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { issueDetailsForAIContextPanelQuery } from '@atlassian/jira-relay/src/__generated__/issueDetailsForAIContextPanelQuery.graphql';
// eslint-disable-next-line jira/import-whitelist
import { metrics } from '@atlassian/browser-metrics';
import type { IssueDetails } from './types.tsx';

const ISSUE_DETAILS_AGG_METRIC = 'context-panel-issue-details-agg';
const issueDetailsAggMetric = metrics.custom({
	key: ISSUE_DETAILS_AGG_METRIC,
});

export const getIssueDetails = async (issueKey: string, cloudId: string): Promise<IssueDetails> => {
	issueDetailsAggMetric.start();
	const issueDetails = await fetchQuery<issueDetailsForAIContextPanelQuery>(
		getRelayEnvironment(),
		graphql`
			query issueDetailsForAIContextPanelQuery($issueKey: String!, $cloudId: ID!) {
				jira @optIn(to: "JiraIssueFieldByIdOrAlias") {
					issueByKey(key: $issueKey, cloudId: $cloudId) {
						issueId
						fieldByIdOrAlias(idOrAlias: "servicedesk-practices") {
							... on JiraSingleLineTextField {
								text
							}
						}
						projectField {
							project {
								isAIEnabled
								projectId
							}
						}
					}
				}
			}
		`,
		{
			issueKey,
			cloudId,
		},
		{ fetchPolicy: 'store-or-network' },
	)
		.toPromise()
		.then((data) => {
			issueDetailsAggMetric.stop();
			return data;
		})
		.catch((e) => {
			issueDetailsAggMetric.cancel();
			throw e;
		});

	return {
		issueId: issueDetails?.jira?.issueByKey?.issueId ?? undefined,
		practice: issueDetails?.jira?.issueByKey?.fieldByIdOrAlias?.text ?? undefined,
		projectId: issueDetails?.jira?.issueByKey?.projectField?.project?.projectId ?? undefined,
		isAIEnabled: issueDetails?.jira?.issueByKey?.projectField?.project?.isAIEnabled ?? undefined,
	};
};
