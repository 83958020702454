import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { legacyPlanReportPageRedirectRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/legacyPlanReportPageRedirectRoute.tsx';
import RedirectLegacyReportPage from './common/ui/redirect/report/index.tsx';
import { LazyPlanTimeline } from './ui/plan-timeline/index-old.tsx';

export const legacyPlanReportPageRedirectRouteEntry = createEntry(
	legacyPlanReportPageRedirectRoute,
	() => ({
		group: 'software',
		component: RedirectLegacyReportPage,
		isRedirect: true,
		layout: globalLayout,
		resources: [...getNavigationResources()],

		navigation: {
			menuId: MENU_ID.PLANS,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanTimeline],
	}),
);
