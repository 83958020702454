import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { roadmapSettingsResource } from '@atlassian/jira-router-resources-roadmap/src/services/roadmap-settings/index.tsx';
import { projectSettingsSoftwareBoardsTimelineRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareBoardsTimelineRoute.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';
import Roadmap, {
	LazyRoadmap,
} from './ui/project-settings/product-routes/software/board-settings/roadmap/index.tsx';

export const projectSettingsSoftwareBoardsTimelineRouteEntry = createEntry(
	projectSettingsSoftwareBoardsTimelineRoute,
	() => ({
		group: 'project-settings-software',
		ufoName: 'board-settings-apps.roadmap-settings-page.page.load',
		component: Roadmap,
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources(), roadmapSettingsResource],
		forPaint: [LazyAtlassianNavigation, LazyRoadmap],
	}),
);
