import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskPracticesResource } from '@atlassian/jira-router-resources-service-desk-practices/src/controllers/index.tsx';
import { projectSettingsServicedeskUnassignedTicketTypeFieldsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-routes/src/projectSettingsServicedeskUnassignedTicketTypeFieldsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import ServiceDeskTicketTypeFields, {
	LazyProjectSettingsServiceDeskTicketTypeFields,
} from './ui/service-desk-ticket-type-fields/index.tsx';

export const projectSettingsServicedeskUnassignedTicketTypeFieldsRouteEntry = createEntry(
	projectSettingsServicedeskUnassignedTicketTypeFieldsRoute,
	() => ({
		group: 'project-settings-servicedesk',
		component: ServiceDeskTicketTypeFields,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			servicedeskPracticesResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskTicketTypeFields],
		meta: {
			reporting: {
				id: APP_NAMES.REQUEST_TYPE_FIELDS,
				packageName: toPackageName(APP_NAMES.REQUEST_TYPE_FIELDS),
				teamName: 'jsd-shield',
			},
		},
		ufoName: 'project-settings-servicedesk-ticket-type-fields',
	}),
);
