import type { Environment } from 'react-relay';
import { fetchQuery, graphql } from 'relay-runtime';
import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { trialButtonJiraEditionAwarenessQuery } from '@atlassian/jira-relay/src/__generated__/trialButtonJiraEditionAwarenessQuery.graphql';
import { PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types/src/application-key.tsx';
import type { TrialButtonBillingInformation } from '../types.tsx';

/**
 * Similar to commerceSharedApiEditionAwarenessTrialQuery, however does not fetch
 * for information we have already checked in platform Audience and Timing
 */
export const Query = graphql`
	query trialButtonJiraEditionAwarenessQuery(
		$cloudId: ID!
		$productKey: String!
		$isTrialCountdownFgExpEnabled: Boolean!
		$isInGracePeriodFixFgEnabled: Boolean!
	) {
		tenantContexts(cloudIds: [$cloudId]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement(where: { AND: [{ inTrialOrPreDunning: true }] }) {
					subscription {
						accountDetails {
							invoiceGroup {
								... on CcpInvoiceGroup {
									id
								}
							}
						}
					}
					transactionAccount {
						key
						isCurrentUserBillingAdmin
					}
					offering @include(if: $isTrialCountdownFgExpEnabled) {
						... on CcpOffering {
							trial {
								lengthDays
							}
						}
					}
					preDunning @include(if: $isInGracePeriodFixFgEnabled) {
						status
					}
				}
			}
		}
	}
`;

export const getTrialButtonBillingInformationGraphql = async (
	cloudId: string,
	productKey: CanonicalId,
	relayEnvironment: Environment,
): Promise<TrialButtonBillingInformation> => {
	// The product key as used by Commerce.
	// JPD product key as used by Commerce does not include .ondemand
	const commerceProductKey =
		productKey === PRODUCT_DISCOVERY ? productKey : `${productKey}.ondemand`;

	const response = await fetchQuery<trialButtonJiraEditionAwarenessQuery>(
		relayEnvironment,
		Query,
		{
			cloudId,
			productKey: commerceProductKey,
			isTrialCountdownFgExpEnabled: fg('jira_edition_awareness_trial_countdown_gate'),
			isInGracePeriodFixFgEnabled: fg('jira_edition_awareness_isgraceperiod_check_fix'),
		},
		{
			fetchPolicy: 'store-or-network',
		},
	).toPromise();

	const entitlementInfo =
		response?.tenantContexts && response.tenantContexts.length > 0
			? response.tenantContexts[0]?.entitlementInfo
			: undefined;

	const entitlement = entitlementInfo?.entitlement;
	const subscription = entitlement?.subscription;
	const invoiceGroup = subscription?.accountDetails?.invoiceGroup;
	const transactionAccount = entitlement?.transactionAccount;

	const offering = entitlement?.offering;

	return {
		transactionAccountId: transactionAccount?.key ?? null,
		invoiceGroupId: invoiceGroup?.id ?? null,
		isBillingAdmin: transactionAccount?.isCurrentUserBillingAdmin ?? null,
		trialLengthDays: offering?.trial?.lengthDays ?? null,
		preDunningStatus: entitlement?.preDunning?.status ?? null,
	};
};
