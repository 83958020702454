/**
 * @generated SignedSource<<49ec598e80dc172e6cad4c1dd2ef945f>>
 * @relayHash 60508ac552f02ed69773e0e19b90de78
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2b02b40f27ce5c8c3225d6a27e82a9706924145c4b461f4a3f6ce1d65ea2418f

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { wrapperEditionAwarenessModalWrapperQuery } from './wrapperEditionAwarenessModalWrapperQuery.graphql';

const node: PreloadableConcreteRequest<wrapperEditionAwarenessModalWrapperQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2b02b40f27ce5c8c3225d6a27e82a9706924145c4b461f4a3f6ce1d65ea2418f",
    "metadata": {},
    "name": "wrapperEditionAwarenessModalWrapperQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
