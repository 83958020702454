import type {
	CAPACITY_UNIT,
	CHART_TYPES,
	SCHEDULING_MODES,
	SPRINT_STATE,
} from '../../common/constants.tsx';

export const CUSTOM_TIME_RANGE_TYPES = {
	FIXED: 'fixed',
	RELATIVE: 'relative',
} as const;

export const DATE_UNITS = {
	DAYS: 'days',
	MONTHS: 'months',
	WEEKS: 'weeks',
} as const;

export type CustomTimeRangeType =
	(typeof CUSTOM_TIME_RANGE_TYPES)[keyof typeof CUSTOM_TIME_RANGE_TYPES];

export type DateUnit = (typeof DATE_UNITS)[keyof typeof DATE_UNITS];

export type CustomDateRange = {
	typeOfCustomDateRange?: CustomTimeRangeType;
	fromDate?: number;
	toDate?: number;
	fromNowUnitCount?: number;
	toNowUnitCount?: number;
	fromNowUnit?: DateUnit;
	toNowUnit?: DateUnit;
};

export const IssueStatus = {
	TODO: 'TODO',
	IN_PROGRESS: 'IN_PROGRESS',
	DONE: 'DONE',
} as const;

export type IssueStatus = (typeof IssueStatus)[keyof typeof IssueStatus];

export type Progress = {
	issueCount?: number;
	timeEstimateInHours?: number;
	storyPoints?: number;
};

export type ProgressByStatus = Partial<Record<IssueStatus, Progress>>;

type BaseIssue = {
	id: string;
	key: string;
	summary: string;
	status: {
		category: IssueStatus;
	};
	type: {
		id: string;
		iconUrl: string;
		name: string;
	};
};

export interface IssueWithProgress extends BaseIssue {
	progress: ProgressByStatus;
}

interface DetailedIssue extends BaseIssue {
	assignee: {
		displayName: string;
		avatarUrl: string;
	};
	priority: {
		name: string;
		iconUrl: string;
	};
}

export type Issues = IssueWithProgress[];

type IssueProgressHierarchyLevel = {
	title: string;
	level: number;
	issues: Issues;
};

export type IssueProgressData = {
	hierarchyLevels: IssueProgressHierarchyLevel[];
};

export const IssueHierarchy = {
	INITIATIVE: 'INITIATIVE',
	EPIC: 'EPIC',
} as const;

export type IssueHierarchy = (typeof IssueHierarchy)[keyof typeof IssueHierarchy];

type HierarchyLevel = {
	title: string;
	level: number;
};

export type SchedulingMode = (typeof SCHEDULING_MODES)[keyof typeof SCHEDULING_MODES];

export type TeamHierarchyLevel = ProgressByStatus;

export type Team = {
	id: string;
	isPlanTeam: boolean;
	avatarUrl: string | null;
	issueSource: number | null;
	title: string;
	schedulingMode: SchedulingMode;
	progress: {
		[level: number]: TeamHierarchyLevel;
	};
	verified?: boolean;
};

export type TeamsProgressData = {
	hierarchyLevels: HierarchyLevel[];
	teams: Team[];
};

export type CapacityUnit = (typeof CAPACITY_UNIT)[keyof typeof CAPACITY_UNIT];

export type SprintState = (typeof SPRINT_STATE)[keyof typeof SPRINT_STATE];

export type TeamAllocation = {
	iteration: {
		id: number;
		name: string;
		goal?: string;
		state: SprintState;
		startDate?: number;
		endDate?: number;
		plannedCapacity?: number;
	};
	allocatedCapacity: number;
	capacityUnit: CapacityUnit;
	progress: TeamHierarchyLevel;
};

export type TeamMember = {
	itemKey: string;
	avatarUrl: string | null;
	title: string;
};

export type TeamCapacityAllocation = {
	team: Omit<Team, 'progress'> & {
		itemKey: string;
		velocity: number | null;
		weeklyHours: number | null;
		estimationUnit: CapacityUnit;
		iterationLength: number;
		members: TeamMember[];
	};
	allocations: TeamAllocation[];
};

export type TeamCapacityData = {
	teamCapacityAllocations: TeamCapacityAllocation[];
};
export const BlockType = {
	DELAY_WORK: 'DELAY_WORK',
	CRITICAL_PATH: 'CRITICAL_PATH',
	BLOCK_WORK: 'BLOCK_WORK',
} as const;

export type BlockType = (typeof BlockType)[keyof typeof BlockType];

export interface Blocker extends Omit<DetailedIssue, 'status'> {
	status: {
		statusCategory: IssueStatus;
		name: string;
	};
	blockType: BlockType;
	description: string;
	isMissingDate: boolean;
}

export type BlockersData = {
	blockers: Blocker[];
};

export interface DataCardData {
	count: number;
}

export interface HighestPriorityDataCardData extends DataCardData {
	highestPriority?: {
		id: string;
		name: string;
		iconUrl: string;
	};
}

type StatusCategories = {
	[key: string]: number;
};

type SummaryItem = {
	statusCategories: StatusCategories;
};

type Summaries = {
	[key: string]: SummaryItem;
};

export type StatusItem = {
	name: string;
	statusCategoryId: number;
};

type StatusCategoryItem = {
	alias: string;
	id: number;
};

export type StatusCategoryData = {
	hierarchyLevels: HierarchyLevel[];
	summaries: Summaries;
	statuses: StatusItem[];
	statusCategories: StatusCategoryItem[];
};

export type SummaryPageDataResponse = {
	charts: {
		[CHART_TYPES.statusCategory]?: StatusCategoryData;
		[CHART_TYPES.issueProgress]?: IssueProgressData;
		[CHART_TYPES.teamProgress]?: TeamsProgressData;
		[CHART_TYPES.teamCapacity]?: TeamCapacityData;
		[CHART_TYPES.blockers]?: BlockersData;
		[CHART_TYPES.highestPriority]?: HighestPriorityDataCardData;
		[CHART_TYPES.unassignedIssues]?: DataCardData;
		[CHART_TYPES.overlappingBlockers]?: DataCardData;
		[CHART_TYPES.overdue]?: DataCardData;
	};
	dateRange: {
		from: number;
		to: number;
	};
	customDateRange?: CustomDateRange;
	errorMessages?: {
		[CHART_TYPES.statusCategory]?: string;
		[CHART_TYPES.issueProgress]?: string;
		[CHART_TYPES.teamProgress]?: string;
		[CHART_TYPES.teamCapacity]?: string;
		[CHART_TYPES.blockers]?: string;
		[CHART_TYPES.highestPriority]?: string;
		[CHART_TYPES.unassignedIssues]?: string;
		[CHART_TYPES.overlappingBlockers]?: string;
		[CHART_TYPES.overdue]?: string;
	};
	lastCommittedTimestamp?: number;
	leadAccountId?: string;
	dependencyViewEnabled?: boolean;
};
