import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwareAdvancedRoadmapsGettingStartedRedirectRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwareAdvancedRoadmapsGettingStartedRedirectRoute.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { LazyPlanTimeline } from './ui/plan-timeline/index-old.tsx';

export const softwareAdvancedRoadmapsGettingStartedRedirectRouteEntry = createEntry(
	softwareAdvancedRoadmapsGettingStartedRedirectRoute,
	() => ({
		group: 'software',
		component: () => <ScreenRedirect to="/jira/plans?create-sample-plan=true" />,
		isRedirect: true,
		layout: globalLayout,
		resources: [...getNavigationResources()],
		navigation: {
			menuId: MENU_ID.PLANS,
		},
		forPaint: [LazyAtlassianNavigation, LazyPlanTimeline],
	}),
);
