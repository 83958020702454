export const MEATBALL_DROPDOWN = 'meatballDropdown';
export const REMOVE_FROM_STARRED = 'removeFromStarred';
export const ADD_TO_STARRED = 'addToStarred';
export const ADD_PEOPLE = 'addPeople';
export const PROJECTS = 'projects';
export const PROJECT_SETTINGS = 'projectSettings';
export const ARCHIVE_PROJECT = 'archiveProject';
export const DELETE_BOARD = 'deleteBoard';
export const DELETE_PROJECT = 'deleteProject';
export const BOARD_SETTINGS = 'boardSettings';
export const ALERT_SETTINGS = 'alertSettings';

// JSM Assets
export const ASSETS_SCHEMAS = 'schemas';
export const ASSETS_REPORTS = 'reports';
export const ASSETS_DATA_MANAGER = 'dataManager';
export const ASSETS_ADAPTERS = 'adapters';
export const ASSETS_CONFIG = 'assetsGlobalConfiguration';

// Project Recommendation
export const DISMISS_RECOMMENDATION = 'dismissRecommendation';
export const NEW_DISCOVERY_PROJECT = 'newDiscoveryProject';
export const JWM_SAMPLE_PROJECT = 'jwmSampleProject';
export const WHY_AM_I_SEEING_THIS = 'whyAmISeeingThis';

// Display Settings -> Change View -> View Options
export const STARRED_AND_RECENT_PROJECTS = 'starredAndRecentProjects';
export const STARRED_PROJECTS_ONLY = 'starredProjectsOnly';
export const MOST_RECENT_ONLY = 'mostRecentOnly';

export type ItemId =
	| typeof MEATBALL_DROPDOWN
	| typeof REMOVE_FROM_STARRED
	| typeof ADD_TO_STARRED
	| typeof ADD_PEOPLE
	| typeof PROJECTS
	| typeof PROJECT_SETTINGS
	| typeof ARCHIVE_PROJECT
	| typeof DELETE_BOARD
	| typeof DELETE_PROJECT
	| typeof BOARD_SETTINGS
	| typeof DISMISS_RECOMMENDATION
	| typeof NEW_DISCOVERY_PROJECT
	| typeof JWM_SAMPLE_PROJECT
	| typeof WHY_AM_I_SEEING_THIS
	| typeof STARRED_AND_RECENT_PROJECTS
	| typeof STARRED_PROJECTS_ONLY
	| typeof MOST_RECENT_ONLY;
