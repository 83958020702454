import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncDirectoryNav } from '@atlassian/jira-horizontal-nav-jsm-directory/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { servicedeskCustomersV2Route } from '@atlassian/jira-router-routes-servicedesk-customers-routes/src/servicedeskCustomersV2Route.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	ServiceDeskCustomersV2,
	LazyServicedeskCustomersV2,
	ServiceDeskContacts,
} from './ui/index.tsx';

export const servicedeskCustomersV2RouteEntry = createEntry(servicedeskCustomersV2Route, () => ({
	group: 'servicedesk',
	layout: serviceProjectLayout,
	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
	],
	component: componentWithFG(
		'jsm_customers_directory_page_for_all',
		ServiceDeskContacts,
		ServiceDeskCustomersV2,
	),
	forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomersV2],
	perfMetricKey: 'jsm-customers-component-load',
	ufoName: 'jsm-customers-component-load',
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncDirectoryNav,
		// TODO: Remove this during jira_nav4 cleanup
		onlyShowHorizontalOnNav4: true,
	},
}));
