import React, { type ReactNode } from 'react';

import { useIntl } from 'react-intl-next';

import LinkExternalIcon from '@atlaskit/icon/utility/link-external';
import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';
import { MenuLinkItem as MenuLinkItemPrimitive } from '@atlassian/navigation-system/side-nav/menu-link-item';

import type { GlobalShortcutLinkItem } from '../../types';

import { messages } from './messages';

type MenuLinkItemProps = GlobalShortcutLinkItem & {
	children?: ReactNode;
	url?: string;
	disabled?: boolean;
};

// renders null if url is undefined, renders disabled if url is null but disabled is true, renders link item if url has a value
export const MenuLinkItem = ({ url, label, icon, disabled }: MenuLinkItemProps) => {
	const { formatMessage } = useIntl();

	// Don't render anything if url is undefined and disbaled is not explcitly set to true
	if (!url && !disabled) {
		return null;
	}

	// Render disabled items if no url and disabled is true
	if (!url || disabled) {
		return (
			<MenuButtonItem elemBefore={icon} elemAfter={<LinkExternalIcon label="" />} isDisabled>
				{label}
				<span aria-label={formatMessage(messages.disabledItemAriaLabel)} />
			</MenuButtonItem>
		);
	}

	// Only render link item if url is defined
	return (
		<MenuLinkItemPrimitive
			elemBefore={icon}
			elemAfter={<LinkExternalIcon label="open external link" />}
			href={url}
			target="_blank"
		>
			{label}
		</MenuLinkItemPrimitive>
	);
};
