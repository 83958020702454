import { createRouterSelector } from '@atlassian/react-resource-router';
import { getCollectionViewPageUrl } from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import type { NavigationCollection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-jpd-nav-views/src/common/types.tsx';
import type { GetViewUrl } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-jpd-nav-views/src/controllers/types.tsx';

/**
 * Returns `collectionUUID` and `viewUUID` of currently visible collection, otherwise `undefined`
 */
export const useCollectionSelectedMatch = createRouterSelector(({ route, match }) => {
	const viewUUID = match.params?.resource || undefined;
	const collectionUUID = match.params?.collectionUUID || undefined;

	const isCollectionSelected =
		route.name === 'polaris-collections' && collectionUUID !== undefined && viewUUID !== undefined;

	return isCollectionSelected ? { collectionUUID, viewUUID } : undefined;
});

/**
 * Returns `true` if user is on `/jira/polaris/roadmaps`, otherwise `false`
 */
export const useIsCollectionsSelected = createRouterSelector(({ route, match }) => {
	const viewUUID = match.params?.resource || undefined;
	const collectionUUID = match.params?.collectionUUID || undefined;

	return (
		route.name === 'polaris-collections' && viewUUID === undefined && collectionUUID === undefined
	);
});

type UseIsCollectionSelectedOptions = {
	collectionUUID: string;
};

/**
 * Returns `true` if user is on collection with view.
 * When `options` are defined, returns `true` if `collectionUUID` matches.
 * Otherwise `false`
 */
export const useIsCollectionSelected = (options?: UseIsCollectionSelectedOptions) => {
	const collectionMatched = useCollectionSelectedMatch();

	if (options?.collectionUUID) {
		return collectionMatched && collectionMatched.collectionUUID === options.collectionUUID;
	}

	return Boolean(collectionMatched);
};

export const getViewUrl: GetViewUrl<NavigationCollection> = ({ container, view }) => {
	// In collections we don't use `view.polarisId` like we do in jpd-project-menu
	// but instead we use `view.id` that contains UUID
	return getCollectionViewPageUrl(container.collectionUUID, view.id);
};
