import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { softwareProformaIssueFormsDirectEmbedRoute } from '@atlassian/jira-router-routes-proforma-routes/src/softwareProformaIssueFormsDirectEmbedRoute.tsx';
import ProformaIssueFormsDirectEmbed, {
	LazyProformaIssueFormsDirectEmbed,
} from './ui/issue-forms-direct/embed/index.tsx';

export const softwareProformaIssueFormsDirectEmbedRouteEntry = createEntry(
	softwareProformaIssueFormsDirectEmbedRoute,
	() => ({
		group: 'software',
		layout: chromelessLayout,
		resources: [projectContextResource],
		component: ProformaIssueFormsDirectEmbed,
		forPaint: [LazyProformaIssueFormsDirectEmbed],
		perfMetricKey: 'issue-forms-direct-embed',
	}),
);
