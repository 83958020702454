import uiVirtualAgentIntentTemplatesQuery from '@atlassian/jira-relay/src/__generated__/uiVirtualAgentIntentTemplatesQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	cloudId: string;
	key: string;
	isHideIntentDiscoveryWhenVaNotConfiguredEnabled: boolean;
};

export const virtualAgentCreateIntentTemplateSidebarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-virtual-agent-create-intent-templates-sidebar" */ './src/ui'
			),
	),
	getPreloadProps: ({
		cloudId,
		key,
		isHideIntentDiscoveryWhenVaNotConfiguredEnabled,
	}: EntryPointParams) => ({
		queries: {
			intentTemplateQueryReference: {
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
				parameters: uiVirtualAgentIntentTemplatesQuery,
				variables: {
					cloudId,
					key,
					isHideIntentDiscoveryWhenVaNotConfiguredEnabled,
				},
			},
		},
		entryPoints: {},
		extraProps: {},
	}),
});
