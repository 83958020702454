import {
	tailoredM2P5TeamTypes,
	tailoredTeamTypes,
	tailoredTeamTypesWithSoftware,
} from './constants.tsx';
import type {
	TailoredOnboardingM2P5TailoredTeamType,
	TailoredOnboardingTailoredTeamType,
	TailoredOnboardingWithSoftwareTailoredTeamType,
} from './types.tsx';

export const isTailoredOnboardingTailoredTeamType = (
	teamType: string,
): teamType is TailoredOnboardingTailoredTeamType => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return tailoredTeamTypes.includes(teamType as TailoredOnboardingTailoredTeamType);
};

export const isTailoredOnboardingWithScrumAndSoftwareKanbanTeamType = (
	teamType: string,
): teamType is TailoredOnboardingWithSoftwareTailoredTeamType => {
	return tailoredTeamTypesWithSoftware.includes(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		teamType as TailoredOnboardingWithSoftwareTailoredTeamType,
	);
};

export const isTailoredOnboardingM2P5TailoredTeamType = (
	teamType: string,
): teamType is TailoredOnboardingM2P5TailoredTeamType => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return tailoredM2P5TeamTypes.includes(teamType as TailoredOnboardingM2P5TailoredTeamType);
};
