import { useEffect, useRef } from 'react';

type UsePolling = <T, U>(callback: (vars?: U) => T, delay: number | null) => void;

export const usePolling: UsePolling = (callback, delay) => {
	const callbackRef = useRef<typeof callback | null>(null);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => {
			if (callbackRef.current) {
				callbackRef.current();
			}
		};
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
};
