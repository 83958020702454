import isMobileUserAgentBrowser from '@atlassian/jira-common-is-mobile-user-agent/src/index.tsx';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';

const isMobileUserAgent = () => (__SERVER__ ? false : isMobileUserAgentBrowser());

// We don't inline usage of feature gates/exp in other packages as our logic to show or no show will become complex at times and should not be duplicated.
// Also because there will be many other potential experiments/flags that will be onboarded to having mobile view enabled

export const isMobileAndInMVPExperiment = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [mobileWebConfig] = UNSAFE_noExposureExp('jira_mobile_web_mvp_2');
	return (
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_mobile_web_mvp_2_feature_gate') &&
		isMobileUserAgent() &&
		mobileWebConfig.get('isEnabled', false)
	);
};

const isMobileAndInViewPanelNewTenantsExperiment = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [newTenantsConfig] = UNSAFE_noExposureExp('jira_mobile_web_mvp_view_panel_new_tenants');
	return (
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_mobile_web_mvp_view_panel_kill_switch') &&
		isMobileUserAgent() &&
		newTenantsConfig.get('isEnabled', false)
	);
};

const isMobileAndInViewPanelExistingUsersExperiment = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [existingUsersMobileWebConfig] = UNSAFE_noExposureExp(
		'jira_mobile_web_mvp_view_panel_existing_users',
	);
	return (
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_mobile_web_mvp_view_panel_kill_switch') &&
		isMobileUserAgent() &&
		existingUsersMobileWebConfig.get('isEnabled', false)
	);
};

export const isMobileAndInViewPanelIterationExperiment = () => {
	return (
		isMobileAndInViewPanelNewTenantsExperiment() || isMobileAndInViewPanelExistingUsersExperiment()
	);
};

export const isMobileAndInMvpOrExistingUsersExperiment = () => {
	return isMobileAndInViewPanelIterationExperiment() || isMobileAndInMVPExperiment();
};

export const isEnabledForMobileMvpOrExistingUsersExperiment = () => {
	return (
		expVal('jira_mobile_web_mvp_view_panel_new_tenants', 'isEnabled', false) ||
		expVal('jira_mobile_web_mvp_view_panel_existing_users', 'isEnabled', false) ||
		expVal('jira_mobile_web_mvp_2', 'isEnabled', false)
	);
};

export const MobileWebExperimentExposure = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [, manuallyFireExposure] = UNSAFE_noExposureExp('jira_mobile_web_mvp_2');

	if (isMobileUserAgent()) {
		manuallyFireExposure();
	}
	return null;
};

export const MobileWebExperimentViewPanelExistingUserExposure = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [, manuallyFireExposure] = UNSAFE_noExposureExp(
		'jira_mobile_web_mvp_view_panel_existing_users',
	);

	if (isMobileUserAgent()) {
		manuallyFireExposure();
	}
	return null;
};

export const MobileWebExperimentViewPanelNewTenantsExposure = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [, manuallyFireExposure] = UNSAFE_noExposureExp(
		'jira_mobile_web_mvp_view_panel_new_tenants',
	);

	if (isMobileUserAgent()) {
		manuallyFireExposure();
	}
	return null;
};
