import {
	DEFAULT_ENVIRONMENT_KEY,
	GLOBAL_PAGE_MODULE,
	DEVELOPMENT_ENVIRONMENT,
	SOURCE_NAVIGATION,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type {
	ExtensionEnvironment,
	GlobalPage,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils-internal/src/utils/parse-extension-id/index.tsx';
import { isExtensionVisible } from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { fetchModules } from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createSubscriber,
	createHook,
	createContainer,
	type SubscriberComponent,
	type HookReturnValue,
	type ContainerComponent,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import type { ForgeGlobalMenuItem } from './types.tsx';

type GenericPublicState<I extends ForgeGlobalMenuItem> = {
	hasFetchedOnce: boolean;
	items: I[];
	fetchError: Error | null;
	promise: Promise<undefined> | null;
	hasSuccessOnce: boolean;
	isFetching: boolean;
};

type GenericState<I extends ForgeGlobalMenuItem> = GenericPublicState<I>;

const getItemHref = (extension: GlobalPage) => {
	const { appId, envId } = parseExtensionId(extension.id);
	return `/jira/apps/${appId}/${envId}`;
};

const transformTitle = (
	title: string,
	environmentType: ExtensionEnvironment,
	environmentKey: string,
) => {
	if (environmentType === DEVELOPMENT_ENVIRONMENT && environmentKey !== DEFAULT_ENVIRONMENT_KEY) {
		return `${title} - ${environmentKey}`;
	}
	return title;
};

const handleFetch =
	({ cloudId, isAnonymous }: { cloudId: string; isAnonymous: boolean }) =>
	// @ts-expect-error - TS7031 - Binding element 'setState' implicitly has an 'any' type. | TS7031 - Binding element 'getState' implicitly has an 'any' type.
	async ({ setState, getState }) => {
		const { isFetching } = getState();

		if (!isFetching) {
			try {
				// If Nav4 is enabled, we don't need to fetch global items since they are implemented elsewhere.
				if (getWillShowNav4()) return;

				const promise = fetchModules({
					cloudId,
					isAnonymous,
					context: {},
					includeHidden: true,
					types: [GLOBAL_PAGE_MODULE],
					source: SOURCE_NAVIGATION,
				});

				setState({
					isFetching: true,
					fetchError: null,
					promise,
				});

				const data = await promise;
				const globalPageData = data ? data[GLOBAL_PAGE_MODULE] : [];

				const items = globalPageData.filter(isExtensionVisible).map((extension) => ({
					id: extension.id,
					title: transformTitle(
						extension.properties.title,
						extension.environmentType,
						extension.environmentKey,
					),
					avatarUrl: extension.properties.icon,
					url: getItemHref(extension),
					environmentType: extension.environmentType,
					module: GLOBAL_PAGE_MODULE,
				}));

				setState({
					items,
					hasFetchedOnce: true,
					hasSuccessOnce: true,
					isFetching: false,
					fetchError: null,
					promise: null,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (fetchError: any) {
				setState({
					...getState(),
					fetchError,
					hasFetchedOnce: true,
					isFetching: false,
					promise: null,
				});
			}
		}
	};

const actions = {
	load:
		({ cloudId, isAnonymous }: { cloudId: string; isAnonymous: boolean }) =>
		async ({ dispatch }: StoreActionApi<GenericState<ForgeGlobalMenuItem>>) => {
			const promise = dispatch(handleFetch({ cloudId, isAnonymous }));
			await promise;
		},
} as const;

type Actions = typeof actions;
export type UseForgeGlobalItemsType = () => HookReturnValue<
	GenericPublicState<ForgeGlobalMenuItem>,
	Actions
>;

type Ret<I> = {
	// @ts-expect-error - TS2344 - Type 'I' does not satisfy the constraint 'ForgeGlobalMenuItem'.
	ForgeGlobalItemsSubscriber: SubscriberComponent<GenericPublicState<I>, Actions, undefined>;
	// @ts-expect-error - TS2344 - Type 'I' does not satisfy the constraint 'ForgeGlobalMenuItem'.
	useForgeGlobalItems: () => HookReturnValue<GenericPublicState<I>, Actions>;
	// @ts-expect-error - TS2344 - Type 'I' does not satisfy the constraint 'ForgeGlobalMenuItem'.
	ForgeGlobalItemsTestContainer: ContainerComponent<GenericState<I>>;
};

export const createForgeGlobalItemsStore = <I extends ForgeGlobalMenuItem>(): Ret<I> => {
	type State = GenericState<I>;
	type PublicState = GenericPublicState<I>;
	const initialState = {
		hasFetchedOnce: false,
		hasSuccessOnce: false,
		items: [],
		promise: null,
		fetchError: null,
		isFetching: false,
	};

	const Store = createStore({
		name: 'forge-global-items-store',
		// @ts-expect-error - TS2322 - Type '{ readonly load: (cloudId: string) => ({ dispatch }: StoreActionApi<GenericState<ForgeGlobalMenuItem>>) => Promise<void>; }' is not assignable to type 'Record<string, ActionThunk<{ fetchForgeModule: (arg1: { cloudId: string; moduleName: FullPageModule; }) => Promise<FullPage[] | null>; hasFetchedOnce: boolean; ... 4 more ...; isFetching: boolean; }, { ...; }>>'.
		actions,
		initialState,
	});

	// @ts-expect-error - TS2344 - Type '{ readonly load: (cloudId: string) => ({ dispatch }: StoreActionApi<GenericState<ForgeGlobalMenuItem>>) => Promise<void>; }' does not satisfy the constraint 'Record<string, ActionThunk<GenericState<I>, { readonly load: (cloudId: string) => ({ dispatch }: StoreActionApi<GenericState<ForgeGlobalMenuItem>>) => Promise<...>; }>>'.
	const ForgeGlobalItemsTestContainer = createContainer<State, Actions, State>(Store, {
		onInit: () => (api: StoreActionApi<State>, props: State) => {
			api.setState(props);
		},
	});

	const selector = ({
		// @ts-expect-error - TS7031 - Binding element 'items' implicitly has an 'any' type.
		items,
		// @ts-expect-error - TS7031 - Binding element 'hasFetchedOnce' implicitly has an 'any' type.
		hasFetchedOnce,
		// @ts-expect-error - TS7031 - Binding element 'fetchError' implicitly has an 'any' type.
		fetchError,
		// @ts-expect-error - TS7031 - Binding element 'promise' implicitly has an 'any' type.
		promise,
		// @ts-expect-error - TS7031 - Binding element 'hasSuccessOnce' implicitly has an 'any' type.
		hasSuccessOnce,
		// @ts-expect-error - TS7031 - Binding element 'isFetching' implicitly has an 'any' type.
		isFetching,
	}) => ({
		items,
		hasFetchedOnce,
		fetchError,
		promise,
		hasSuccessOnce,
		isFetching,
	});

	// @ts-expect-error - TS2344 - Type '{ readonly load: (cloudId: string) => ({ dispatch }: StoreActionApi<GenericState<ForgeGlobalMenuItem>>) => Promise<void>; }' does not satisfy the constraint 'Record<string, ActionThunk<GenericState<I>, { readonly load: (cloudId: string) => ({ dispatch }: StoreActionApi<GenericState<ForgeGlobalMenuItem>>) => Promise<...>; }>>'.
	const ForgeGlobalItemsSubscriber = createSubscriber<State, Actions, PublicState, undefined>(
		Store,
		{
			displayName: 'ForgeGlobalItemsSubscriber',
			selector,
		},
	);

	const useForgeGlobalItems = createHook(Store, { selector });

	// @ts-expect-error - TS2322 - Type 'HookFunction<{ items: any; hasFetchedOnce: any; fetchError: any; promise: any; hasSuccessOnce: any; isFetching: any; }, BoundActions<{ fetchForgeModule: (arg1: { cloudId: string; moduleName: FullPageModule; }) => Promise<...>; ... 5 more ...; isFetching: boolean; }, Record<...>>, void>' is not assignable to type '() => HookReturnValue<GenericPublicState<I>, { readonly load: (cloudId: string) => ({ dispatch }: StoreActionApi<GenericState<ForgeGlobalMenuItem>>) => Promise<...>; }>'.
	return { ForgeGlobalItemsSubscriber, useForgeGlobalItems, ForgeGlobalItemsTestContainer };
};

export const { useForgeGlobalItems } = createForgeGlobalItemsStore<ForgeGlobalMenuItem>();
