import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	lozenge: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-global-app-shortcuts.assets.lozenge',
		defaultMessage: 'Try',
		description: 'Growth lozenge for Assets app shortcut',
	},
	assets: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-global-app-shortcuts.assets.assets',
		defaultMessage: 'Assets',
		description: 'Label for Assets app shortcut button',
	},
});
