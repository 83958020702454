import React, { useEffect } from 'react';
import { usePreloadedQuery, graphql, useQueryLoader, type PreloadedQuery } from 'react-relay';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import {
	advancedRoadmapsScenarioIdResource,
	advancedRoadmapsPlanPageInitialChecksResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { ErrorScreen } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/error-screens/index.tsx';
import { useRouter, useResource } from '@atlassian/react-resource-router';
import QUERY, {
	type planNoScenarioQuery,
} from '@atlassian/jira-relay/src/__generated__/planNoScenarioQuery.graphql';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

const PERSIST_SEARCH_PARAMS = ['search'];

const RedirectLegacyPlanPageWithoutScenarioId = () => {
	const [{ query }] = useRouter();
	const { data, loading } = useResource(advancedRoadmapsScenarioIdResource);

	if (loading || data === null) {
		return null;
	}

	return (
		<ScreenRedirect
			to={`/jira/plans/${query.id}/scenarios/${data.scenarioId}`}
			persistSearchParams={PERSIST_SEARCH_PARAMS}
		/>
	);
};

export const RedirectLegacyPlanPage = () => {
	const [{ query }] = useRouter();
	const { id, sid } = query;

	if (Number.isNaN(Number.parseFloat(sid))) {
		return <RedirectLegacyPlanPageWithoutScenarioId />;
	}

	return (
		<ScreenRedirect
			to={`/jira/plans/${id}/scenarios/${sid}`}
			persistSearchParams={PERSIST_SEARCH_PARAMS}
		/>
	);
};

export const RedirectPlanPageWithoutScenarioId = () => {
	const [{ match }] = useRouter();
	const { planId } = match.params;

	if (planId === null || planId === undefined || Number.isNaN(Number.parseFloat(planId))) {
		throw new Error('Plan cannot be loaded without planId');
	}

	const { data, loading } = useResource(advancedRoadmapsPlanPageInitialChecksResource);

	if (loading || data === null) {
		return <Spinner />;
	}

	if (!data.success) {
		return <ErrorScreen errorMessage={data.errorMessage} />;
	}

	if (
		data.scenarioId === null ||
		data.scenarioId === undefined ||
		(typeof data.scenarioId === 'string' && Number.isNaN(Number.parseFloat(data.scenarioId)))
	) {
		return null;
	}

	return (
		<ScreenRedirect
			to={`/jira/plans/${planId}/scenarios/${data.scenarioId}`}
			persistSearchParams={PERSIST_SEARCH_PARAMS}
		/>
	);
};

export const RedirectPlanPageWithoutScenarioIdPreloadQuery = ({
	queryRef,
	planId,
}: {
	queryRef: PreloadedQuery<planNoScenarioQuery>;
	planId: string;
}) => {
	const gqlData = usePreloadedQuery<planNoScenarioQuery>(
		graphql`
			query planNoScenarioQuery($planId: ID!) {
				jira @optIn(to: "JiraNavigationItems") {
					planById(id: $planId) @optIn(to: "JiraPlan") {
						defaultNavigationItem @required(action: THROW) {
							... on JiraNavigationItem {
								url
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	const { data, loading } = useResource(advancedRoadmapsPlanPageInitialChecksResource);

	const defaultTabUrl = gqlData?.jira?.planById?.defaultNavigationItem?.url;

	if (defaultTabUrl) {
		return <ScreenRedirect to={defaultTabUrl} persistSearchParams={PERSIST_SEARCH_PARAMS} />;
	}

	// Fallback if can not get defaultTabUrl

	if (loading || data === null) {
		return <Spinner />;
	}

	if (!data.success) {
		return <ErrorScreen errorMessage={data.errorMessage} />;
	}

	if (
		data.scenarioId === null ||
		data.scenarioId === undefined ||
		(typeof data.scenarioId === 'string' && Number.isNaN(Number.parseFloat(data.scenarioId)))
	) {
		return null;
	}

	return (
		<ScreenRedirect
			to={`/jira/plans/${planId}/scenarios/${data.scenarioId}`}
			persistSearchParams={PERSIST_SEARCH_PARAMS}
		/>
	);
};

export const RedirectPlanPageWithoutScenarioIdLoadQuery = () => {
	const [{ match }] = useRouter();
	const { planId } = match.params;

	if (planId === null || planId === undefined || Number.isNaN(Number.parseFloat(planId))) {
		throw new Error('Plan cannot be loaded without planId');
	}

	const { cloudId, activationId } = useTenantContext();

	const [queryRef, loadQuery, disposeQuery] = useQueryLoader<planNoScenarioQuery>(QUERY);

	useEffect(() => {
		loadQuery(
			{
				planId: `ari:cloud:jira:${cloudId}:plan/activation/${activationId}/${planId}`,
			},
			{
				fetchPolicy: 'store-or-network' as const,
			},
		);
		return disposeQuery;
	}, [loadQuery, disposeQuery, cloudId, activationId, planId]);

	return queryRef ? (
		<RedirectPlanPageWithoutScenarioIdPreloadQuery queryRef={queryRef} planId={planId} />
	) : null;
};
