// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps, type ComponentType } from 'react';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useLegacySearchTheme } from '@atlassian/navigation-system/experimental/use-legacy-search-theme';
import { ProductSearchInputSkeleton as ProductSearchInputSkeletonDI } from '@atlassian/product-search-dialog/skeleton';
import { useTheme as useThemeDI } from '../../../controllers/theme/main.tsx';
import type { UseThemeType } from '../../../controllers/theme/types.tsx';

type Props = {
	useTheme?: UseThemeType;
	ProductSearchInputSkeleton?: ComponentType<
		JSX.LibraryManagedAttributes<
			typeof ProductSearchInputSkeletonDI,
			ComponentProps<typeof ProductSearchInputSkeletonDI>
		>
	>;
	placeholder?: string;
	ariaLabel?: string;
};

export const Skeleton = ({
	ProductSearchInputSkeleton = ProductSearchInputSkeletonDI,
	useTheme = useThemeDI,
	placeholder,
	ariaLabel,
}: Props) => {
	/**
	 * The nav3 custom theme object
	 */
	const theme = useTheme();
	/**
	 * A nav4 search theme that works with the nav3 theming API
	 */
	const newTheme = useLegacySearchTheme();
	return (
		<ProductSearchInputSkeleton
			shouldFillContainer={getWillShowNav4()}
			theme={getWillShowNav4() ? newTheme : theme && theme.mode && theme.mode.search}
			placeholder={placeholder}
			ariaLabel={ariaLabel}
		/>
	);
};
