import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsSupportWebsiteRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsSupportWebsiteRoute.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncHelpCenterSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-help-center-settings/src/async.tsx';
import { AsyncSupportWebsiteNav } from '@atlassian/jira-horizontal-nav-csm-support-website/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { HelpCenterSettingsSupportWebsiteEntryPoint } from './ui/support-website/index.tsx';

export const helpCenterSettingsSupportWebsiteRouteEntry = createEntry(
	helpCenterSettingsSupportWebsiteRoute,
	() => ({
		group: 'helpcenter-settings',
		perfMetricKey: 'customer-service.support-website',
		layout: serviceProjectLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.HELP_CENTER_SETTINGS,
			menuId: MENU_ID.HELP_CENTER_SETTINGS,
			sidebar: AsyncHelpCenterSettingsSidebar,
			horizontal: AsyncSupportWebsiteNav,
		},
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('enable_support_site_details_csm')
				? HelpCenterSettingsSupportWebsiteEntryPoint
				: undefined;
		},
		resources: [...getServiceDeskNavigationResources()],
	}),
);
