import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import getMeta from '@atlassian/jira-get-meta';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, type StoreActionApi } from '@atlassian/react-sweet-state';

type State = {
	isEnabled: boolean;
	error: null | FetchError;
};

const URL_FRAGMENT = '/rest/internal/latest/user/preferences/keyboardShortcuts?turn=';

export const toggleKeyboardShortcut =
	(analyticsEvent: UIAnalyticsEvent) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const currentState = getState().isEnabled;
		const status = currentState ? 'off' : 'on';
		const url = `${URL_FRAGMENT}${status}`;
		try {
			await performPutRequest(url);
			setState({ isEnabled: !currentState, error: null });
			fireUIAnalytics(analyticsEvent, 'keyboardShortcutsToggle', {
				isEnabled: !currentState,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setState({ error: err });
			fireErrorAnalytics({
				meta: {
					id: 'keyboardShortcutToggleFailure',

					packageName: 'jiraCommonLegacyDoNotAddAnythingNew',
				},
				error: err,
			});
		}
	};

const Store = createStore({
	initialState: {
		isEnabled: __SERVER__ ? false : !(getMeta('ajs-keyboard-shortcuts-enabled') === 'false'),
		error: null,
	},
	actions: {
		// @ts-expect-error - TS2322 - Type '( analyticsEvent: UIAnalyticsEventInterface) => ({ setState, getState }: StoreActionApi<State>) => Promise<void>' is not assignable to type 'ActionThunk<{ isEnabled: boolean; error: null; }, { toggle: ( analyticsEvent: UIAnalyticsEventInterface) => ({ setState, getState }: StoreActionApi<State>) => Promise<void>; }>'.
		toggle: toggleKeyboardShortcut,
	},
});

export const useKeyboardShortcutsToggle = createHook(Store);
