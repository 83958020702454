import { addTimingFromPerformanceMark as reactUFOAddTimingFromPerformanceMark } from '@atlaskit/react-ufo/initial-page-load-extra-timing';

let po: PerformanceObserver | null = null;
let timeout: ReturnType<typeof setTimeout> | null = null;
let started = false;

export const observePreloadedBundlesProgress = () => {
	if (__SERVER__ || started || !globalThis.PerformanceObserver) return;
	started = true;

	const expectedEntries = new Set(
		Array.from(globalThis.document?.querySelectorAll?.('link[rel=modulepreload]') || [])
			.map((el) => el.getAttribute('href'))
			.filter((url) => url?.includes('jira-frontend-bifrost')),
	);

	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	const loadedResources = (
		Array.from(
			globalThis.performance?.getEntriesByType('resource'),
		) as Array<PerformanceResourceTiming>
	).filter(({ name }) => {
		if (expectedEntries.has(name)) {
			expectedEntries.delete(name);
			return true;
		}
		return false;
	});

	if (expectedEntries.size > 0) {
		timeout = setTimeout(() => {
			po?.disconnect();
		}, 30000);

		po = new PerformanceObserver((entry) => {
			entry.getEntries().forEach((e) => {
				if (expectedEntries.has(e.name)) {
					expectedEntries.delete(e.name);
					loadedResources.push(e as PerformanceResourceTiming);
				}
				if (expectedEntries.size === 0) {
					timeout !== null && clearTimeout(timeout);
					po?.disconnect();
					processCapturedData(loadedResources);
				}
			});
		});
	} else {
		processCapturedData(loadedResources);
	}
	/* eslint-enable @typescript-eslint/consistent-type-assertions */
};

type CombinedResourceReduceAggType = {
	all: ResourceReduceAggType;
	common: ResourceReduceAggType;
	route: ResourceReduceAggType;
};

type ResourceReduceAggType = {
	min: null | number;
	max: null | number;
};

const determineMinAndMax = (agg: ResourceReduceAggType, entry: PerformanceResourceTiming) => {
	if (agg.min === null || entry.startTime < agg.min) {
		// eslint-disable-next-line no-param-reassign
		agg.min = entry.startTime;
	}

	if (agg.max === null || entry.responseEnd > agg.max) {
		// eslint-disable-next-line no-param-reassign
		agg.max = entry.responseEnd;
	}
};

const processCapturedData = (loadedResources: PerformanceResourceTiming[]) => {
	const { all, common, route } = loadedResources.reduce<CombinedResourceReduceAggType>(
		(agg, entry) => {
			determineMinAndMax(agg.all, entry);

			if (isCommon(entry)) {
				determineMinAndMax(agg.common, entry);
			} else {
				determineMinAndMax(agg.route, entry);
			}

			return agg;
		},
		{
			all: { max: null, min: null },
			common: { max: null, min: null },
			route: { max: null, min: null },
		},
	);

	if (all.max === null || all.min === null) {
		return;
	}

	setTimings('preloading-bundles', all);
	setTimings('preloading-bundles/common', common);
	setTimings('preloading-bundles/route', route);
};

const isCommon = (entry: PerformanceResourceTiming) => {
	const url = entry.name;
	const hasJiraSpa = url.includes('jira-spa');
	const isSharedWithoutNav = url.includes('/shared') && !url.includes('atlassian-navigation');
	const isNavWithVendor = url.includes('atlassian-navigation') && url.includes('vendor');
	return (hasJiraSpa || isSharedWithoutNav || isNavWithVendor) && url.endsWith('.js');
};

const setTimings = (label: string, { min, max }: ResourceReduceAggType) => {
	if (max === null || min === null) {
		return;
	}

	const startMark = `${label}-start`;
	const stopMark = `${label}-end`;

	performance.mark(startMark, { startTime: min });
	performance.mark(stopMark, { startTime: max });

	reactUFOAddTimingFromPerformanceMark(label, startMark, stopMark, true, true);
};
