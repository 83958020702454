import React from 'react';
import { resources as scheduleGlobalResources } from '@atlassian/eoc-schedule-global/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocScheduleGlobalRoute } from '@atlassian/jira-router-routes-eoc-schedule-global-routes/src/eocScheduleGlobalRoute.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazySidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-operations/src/ui/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LazyScheduleGlobalPage = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-schedule-global", jiraSpaEntry: "async-jira-eoc-schedule-global" */ '@atlassian/jira-eoc-schedule-global/src/ui'
	).then((m) => m.ScheduleGlobalPage),
);

const EocScheduleGlobalComponent = () => (
	<LazyPage
		Page={LazyScheduleGlobalPage}
		pageId="async-jira-eoc-schedule-global"
		shouldShowSpinner={false}
	/>
);

const getNavigationSpecifications = () => {
	return fg('operations_nav4_remaining_items')
		? {
				sidebarId: SIDEBAR_ID.OPERATIONS_GENERAL_CONFIGURATION,
			}
		: {
				sidebar: AsyncSidebarGlobalSettingsProducts,
				horizontal: AsyncHorizontalOperationsConfigurationNav,
				onlyShowHorizontalOnNav4: true,
			};
};

export const eocScheduleGlobalRouteEntry = createEntry(eocScheduleGlobalRoute, () => ({
	group: 'eoc',
	component: EocScheduleGlobalComponent,
	layout: opsSettingsLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...scheduleGlobalResources(),
		getConsolidationStateResource(),
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		...getNavigationSpecifications(),
	},
	forPaint: fg('operations_nav4_remaining_items')
		? [LazySidebarContent, LazyAtlassianNavigation, LazyScheduleGlobalPage]
		: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyScheduleGlobalPage],
}));
