import L1MenuItemsQuery from '@atlassian/jira-relay/src/__generated__/L1MenuItemsQuery.graphql';
import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';

export const nav4SidebarContentCoreEntryPoint = createEntryPoint({
	// sidebar is critical to rendering the initial view of a page
	root: JSResourceForUserVisible(() =>
		import(/* webpackChunkName: "nav4-sidebar-content-core-entry-point" */ './src/index').then(
			({ SidebarContentForEntryPoint }) => SidebarContentForEntryPoint,
		),
	),
	// Result matches the generics of the async component's EntryPointProps
	// except for TComponentProps which is derived from `root`
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getPreloadProps(params: EntryPointRouteParams & any) {
		const {
			context,
			tenantContext,
			entryPoints = {},
			isAppsExpanded,
			isFiltersExpanded,
			isPlansExpanded,
			isProjectsExpanded,
			isOperationsExpanded,
			isOverviewsExpanded,
		} = params;
		// The two parameters have been injected by the theme setter, and should
		// not be here. Setting them naively just to be able to use the sidebar entryPoint again.
		const projectAri = '';
		const projectKey = context.match.params.projectKey || '';

		return {
			// Queries your async component uses
			queries: {
				nav4SidebarContentCore: {
					// generated .graphql
					parameters: L1MenuItemsQuery,
					// variables for your query
					variables: {
						cloudId: tenantContext.cloudId,
						isAppsExpanded,
						isFiltersExpanded,
						isPlansExpanded,
						isProjectsExpanded,
						isOperationsExpanded,
						isOverviewsExpanded,
						projectAri,
						projectKey,
						entityAriProjects: `ari:cloud:jira:${tenantContext.cloudId}:navigation-settings/activation/${tenantContext.activationId}/projects`,
						ownerAri:
							tenantContext.atlassianAccountId &&
							`ari:cloud:identity::user/${tenantContext.atlassianAccountId}`,
					},
					// fetch options
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
				},
			},
			entryPoints,
		};
	},
});
