import React from 'react';
import { styled } from '@compiled/react';
import SuccessIcon from '@atlaskit/icon/core/migration/success--check-circle';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';

export type IconProps = {
	label: string;
};

export const IconError = ({ label }: IconProps) => (
	<IconContainer iconColor={token('color.icon.danger')}>
		<ErrorIcon spacing="spacious" label={label} />
	</IconContainer>
);

export const IconSuccess = ({ label }: IconProps) => (
	<IconContainer iconColor={token('color.icon.success')}>
		<SuccessIcon spacing="spacious" label={label} />
	</IconContainer>
);

export const IconInfo = ({ label }: IconProps) => (
	<IconContainer iconColor={token('color.icon.discovery')}>
		<InfoIcon spacing="spacious" label={label} />
	</IconContainer>
);

export const IconWarning = ({ label }: IconProps) => (
	<IconContainer iconColor={token('color.icon.warning')}>
		<WarningIcon spacing="spacious" label={label} />
	</IconContainer>
);

export const BoldIconError = ({ label }: IconProps) => (
	<ErrorIcon spacing="spacious" label={label} LEGACY_secondaryColor={token('color.icon.danger')} />
);

export const BoldIconSuccess = ({ label }: IconProps) => (
	<SuccessIcon
		spacing="spacious"
		label={label}
		LEGACY_secondaryColor={token('color.icon.success')}
	/>
);

export const BoldIconInfo = ({ label }: IconProps) => (
	<InfoIcon
		spacing="spacious"
		label={label}
		LEGACY_secondaryColor={token('color.icon.discovery')}
	/>
);

export const BoldIconWarning = ({ label }: IconProps) => (
	<WarningIcon
		spacing="spacious"
		label={label}
		LEGACY_secondaryColor={token('color.icon.warning')}
	/>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconContainer = styled.span<{ iconColor: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ iconColor }) => iconColor,
});
