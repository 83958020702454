import { createResource } from 'react-resource-router';
import { createHeaders } from '@atlassian/csm-ui-components';
import { fg } from '@atlaskit/platform-feature-flags';

import { getFiltersQuery, serialiseFiltersQueryToSearchParams } from './../common/filters';
import { API_GATEWAY_BASE_URL } from '../utils';

import { isNotExcludedOwner } from './utils';
import type { ConversationResourceResponse, MessageResourceResponse } from './types';

const TWENTY_FOUR_HOURS_IN_MS = 86400000;
const THIRTY_SECONDS_IN_MS = 30000;

export const conversationsResource = createResource<ConversationResourceResponse>({
	type: 'CONVERSATIONS',
	getKey: (ctx) => serialiseFiltersQueryToSearchParams(getFiltersQuery(ctx.query)).toString(),
	getData: async (ctx) => {
		const headers = createHeaders();
		const queryString = serialiseFiltersQueryToSearchParams(getFiltersQuery(ctx.query));

		const versionedAPIPathParam = fg('csm-conversation-review-v2-apis') ? 'v2' : 'v1';

		return fetch(
			`${API_GATEWAY_BASE_URL}/assist/csm/${versionedAPIPathParam}/reporting/conversations?${queryString}`,
			{
				method: 'GET',
				headers,
			},
		)
			.then((response) => response.json())
			.then((data) => {
				let conversations = [];
				// V1
				if ('conversations' in data) {
					conversations = data.conversations;
				}
				// V2
				if ('items' in data) {
					conversations = data.items;
				}
				conversations = conversations.filter(isNotExcludedOwner);
				const error = 'errorMessage' in data ? data : undefined;

				return {
					conversations,
					// V2 only, needs pagination support https://product-fabric.atlassian.net/browse/CSMAI-790
					hasNext: data.hasNext || false,
					// V2 only
					cursor: data.cursor,
					error,
				};
			});
	},
	maxAge: TWENTY_FOUR_HOURS_IN_MS,
	isBrowserOnly: true,
});

export const messagesResource = createResource<MessageResourceResponse>({
	type: 'CONVERSATION_MESSAGES',
	getKey: ({ match }) => String(match.params.conversationId),
	getData: async ({ match }) => {
		const conversationId = match.params.conversationId;
		if (!conversationId) {
			return {
				messages: [],
				error: {
					errorMessage: 'No conversationId provided',
					errors: ['Missing conversationId'],
					statusCode: 400,
				},
			};
		}

		const versionedAPIPathParam = fg('csm-conversation-review-v2-apis') ? 'v2' : 'v1';
		const headers = createHeaders();

		return fetch(
			`${API_GATEWAY_BASE_URL}/assist/csm/${versionedAPIPathParam}/reporting/conversations/${match.params.conversationId}/messages`,
			{
				method: 'GET',
				headers,
			},
		)
			.then((response) => response.json())
			.then((data) => {
				let conversation;
				let messages = [];
				if (versionedAPIPathParam === 'v2') {
					conversation = data.conversation;
					messages = data.messages;
				} else {
					messages = Array.isArray(data) ? data : [];
				}
				const error = 'errorMessage' in data ? data : undefined;

				return {
					conversation,
					messages,
					error,
				};
			});
	},
	maxAge: THIRTY_SECONDS_IN_MS,
	isBrowserOnly: true,
});
