import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { classicSoftwareBacklogEmbedRoute } from '@atlassian/jira-router-routes-software-classic-backlog-routes/src/classicSoftwareBacklogEmbedRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { RapidBoardSpaPage } from '@atlassian/jira-router-routes-user-board-entries/src/ui/async.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	getUIFBacklogDataResource,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import {
	forgeProjectModuleResource,
	forgeBacklogModuleResource,
	forgeSprintModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { LazyBacklogPage } from './ui/LazyBacklogPage.tsx';
import { classicBacklogCapability } from './common/constants.tsx';

export const classicSoftwareBacklogEmbedRouteEntry = createEntry(
	classicSoftwareBacklogEmbedRoute,
	() => ({
		group: 'classic-software',
		ufoName: 'classic-backlog-embed',
		shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-backlog'),

		component: RapidBoardSpaPage,

		layout: chromelessLayout,

		resources: [
			...getUIFBacklogDataResource(),
			...getRapidboardResources(),
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			getRapidBoardDataResource(),
			getEditModelResource(),
			getBoardDetailsResource(),
			forgeBacklogModuleResource,
			forgeSprintModuleResource,
			themePreferenceResource,
		],

		afterPaint: [LazyIssueApp],
		forPaint: [LazyBacklogPage],

		preloadOptions: {
			earlyChunks: ['software-cmp-backlog-early-entry'],
		},

		meta: {
			capability: classicBacklogCapability,
		},

		earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
	}),
);
