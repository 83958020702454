import React, { useCallback, useMemo, useRef, type ReactNode, type MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { CUSTOM_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	APP,
	APP_SUBPAGE,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import {
	LEVEL_ONE,
	LEVEL_TWO,
	LEVEL_THREE,
	type Level,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	ENTITY_ID,
	MENU_ID_APPS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { toMenuIdFromMenuIdList } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/utils/menu-id/index.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { AppActions } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/app-actions/AppActions.tsx';
import { AppIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/app-icon/AppIcon.tsx';
import { EnvLozenge } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/env-lozenge/EnvLozenge.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	Nav4AppMenuItem$key,
	Nav4AppMenuItem$data,
} from '@atlassian/jira-relay/src/__generated__/Nav4AppMenuItem.graphql';
import {
	Divider,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
	MenuSection,
	MenuList,
	MenuListItem,
} from '@atlassian/navigation-system';
import type { AppSection } from '../../types.tsx';

type Nav4AppMenuItemProps = {
	queryRef: Nav4AppMenuItem$key;
	onData?: <T extends Nav4AppMenuItem$data>(data: T) => T;
	onClick?: OnClick;
};

type OnClick = (
	e: MouseEvent,
	target: HTMLAnchorElement | null,
	styleClass: string,
	url: string | null,
) => void;

export function Nav4AppMenuItem({ queryRef, onData, onClick }: Nav4AppMenuItemProps) {
	const rawData = useFragment<Nav4AppMenuItem$key>(
		graphql`
			fragment Nav4AppMenuItem on JiraAppNavigationItem {
				appId
				envLabel
				iconUrl
				label
				styleClass
				sections {
					label
					links {
						label
						url
						styleClass
					}
				}
				settingsUrl
				url
			}
		`,
		queryRef,
	);

	const data = useMemo(
		() => (onData ? onData(normalizeData(rawData)) : normalizeData(rawData)),
		[onData, rawData],
	);
	const icon = useMemo(() => <AppIcon iconUrl={data.iconUrl || ''} />, [data.iconUrl]);
	const withSeparators = useMemo(() => shouldUseSeparators(data), [data]);
	const envLozenge = useMemo(() => <EnvLozenge label={data.envLabel || ''} />, [data.envLabel]);
	const actionsOnHover = useMemo(
		() => <AppActions settingsUrl={data.settingsUrl || ''} appId={data.appId || ''} />,
		[data.settingsUrl, data.appId],
	);

	const expandable = useMemo(() => {
		if ((data.sections || []).length === 0) {
			return null;
		}
		return (
			<MaybeExpandable
				actionsOnHover={actionsOnHover}
				appId={data.appId || ''}
				envLozenge={envLozenge}
				icon={icon}
				label={data.label || 'Unknown app'} // This should never be the case though
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				menuIds={toExpandableMenuId(data.sections as unknown as AppSection[])}
				itemId={data.appId || ''}
				level={LEVEL_ONE}
			>
				{(data.sections || []).map((section, sectionIndex) =>
					(section?.links || []).length ? (
						<MenuListItem key={`${sectionIndex}-${section?.label}`}>
							<MenuSection>
								{withSeparators && sectionIndex > 0 ? <Divider /> : null}
								<MenuList>
									<MaybeExpandable
										appId={data.appId || ''}
										label={section?.label || ''}
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										menuIds={toExpandableMenuId([
											{ links: section?.links },
										] as unknown as AppSection[])}
										itemId={`section/${sectionIndex}`}
										level={LEVEL_TWO}
									>
										{(section?.links || []).map((link, linkIndex) => (
											<SimpleItem
												appId={data.appId || ''}
												key={link?.url || ''}
												label={link?.label || ''}
												onClick={onClick}
												styleClass={link?.styleClass || ''}
												url={link?.url == null ? null : removeTrailingSlash(link?.url)}
												itemId={`section/${sectionIndex}/link/${linkIndex}`}
												level={section?.label ? LEVEL_THREE : LEVEL_TWO}
											/>
										))}
									</MaybeExpandable>
								</MenuList>
							</MenuSection>
						</MenuListItem>
					) : null,
				)}
			</MaybeExpandable>
		);
	}, [
		data.sections,
		data.label,
		data.appId,
		icon,
		envLozenge,
		actionsOnHover,
		withSeparators,
		onClick,
	]);

	if (expandable) {
		return expandable;
	}

	return (
		<SimpleItem
			actionsOnHover={actionsOnHover}
			appId={data.appId || ''}
			envLozenge={envLozenge}
			icon={icon}
			label={data.label || 'Unknown app'} // This should never be the case
			onClick={onClick}
			styleClass={data.styleClass || ''}
			url={data.url == null ? null : removeTrailingSlash(data.url || '')}
			itemId={data.appId || ''}
			level={LEVEL_ONE}
		/>
	);
}

function SimpleItem({
	actionsOnHover,
	appId,
	envLozenge,
	icon,
	label,
	onClick,
	styleClass,
	url,
	itemId,
	level,
}: {
	actionsOnHover?: ReactNode;
	appId: string;
	envLozenge?: ReactNode;
	icon?: JSX.Element;
	label: string;
	onClick?: OnClick;
	styleClass?: string;
	// It may be the case there are Connect pages without URLs that open modals etc
	// And in the click handler we do check for the null values, so it is better we just keep it this way
	url: string | null;
	itemId: string;
	level: Level;
}) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const anchorElement = useRef<HTMLAnchorElement>(null);

	const onItemClick = useCallback(
		(e: MouseEvent) => {
			fireAppClickEvent({
				event: createAnalyticsEvent({}),
				itemId,
				appId,
				level,
			});

			onClick?.(e, anchorElement.current, styleClass || '', url);
		},
		[url, onClick, itemId, appId, level, styleClass, createAnalyticsEvent],
	);

	// If a Connect app does NOT have a URL, we should use a MenuButtonItem instead.
	return (
		<Nav4MenuLinkItem
			href={removeTrailingSlash(url ?? '')}
			elemBefore={icon}
			elemAfter={envLozenge}
			actionsOnHover={actionsOnHover}
			menuId={toMenuItemMenuId(url ?? '')}
			ref={anchorElement}
			onClick={onItemClick}
		>
			{label}
		</Nav4MenuLinkItem>
	);
}

function MaybeExpandable(params: {
	actionsOnHover?: ReactNode;
	children: ReactNode;
	envLozenge?: ReactNode;
	icon?: JSX.Element;
	label: string;
	menuIds: string[];
	itemId: string;
	appId: string;
	level: Level;
}) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { actionsOnHover, children, envLozenge, icon, label, menuIds, itemId, appId, level } =
		params;
	const menuId = toMenuIdFromMenuIdList(menuIds);
	const onClick = useCallback(() => {
		fireAppClickEvent({
			event: createAnalyticsEvent({}),
			itemId,
			appId,
			level,
			isExpandable: true,
		});
	}, [createAnalyticsEvent, itemId, appId, level]);

	return label ? (
		<Nav4ExpandableMenuItem
			menuId={menuId}
			isExpanded={level !== LEVEL_ONE}
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) onClick();
			}}
		>
			<ExpandableMenuItemTrigger
				actionsOnHover={actionsOnHover}
				elemBefore={icon}
				elemAfter={envLozenge}
				testId={getTestId(menuId)}
			>
				{label}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>{children}</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	) : (
		<>{children}</>
	);
}

function fireAppClickEvent(params: {
	event: UIAnalyticsEvent;
	itemId: string;
	appId: string;
	level: Level;
	isExpandable?: boolean;
}) {
	const { event, itemId, appId, level, isExpandable } = params;
	fireUIAnalytics(event, {
		action: 'clicked',
		actionSubject: 'navigationItem',
		actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_APPS],
		attributes: {
			level,
			componentType: CUSTOM_ITEM,
			itemId,
			isExpandable,
			section: 'yourApps',
			...(level === LEVEL_ONE
				? {
						itemType: APP,
					}
				: {
						itemType: APP_SUBPAGE,
						parentItemId: appId,
						parentItemType: APP,
					}),
		},
	});
}

function shouldUseSeparators(data: Nav4AppMenuItem$data) {
	for (const section of data.sections || []) {
		if (section?.label) return false;
	}
	return true;
}

/**
 * Common data transformer.
 * Filters out sections with empty links.
 */
function normalizeData(data: Nav4AppMenuItem$data) {
	return {
		...data,
		sections: (data.sections || []).filter((section) => (section?.links || []).length > 0),
	};
}

const removeTrailingSlash = (url = '') => url.replace(/\/$/, '');
const removeQueryParams = (url: string) => url.split('?')[0];
const removeHash = (url: string) => url.split('#')[0];

// Since the underlying mechanism operates only on the path we must trim all but the pathname.
export const toMenuItemMenuId = (path_ = '') => {
	let path = path_;
	// This is the format we ask Connect vendors to use: /plugins/servlet/ac/:addonKey/:moduleKey
	// However, there are Connect apps that do not follow our recommendations and add extra path segments.
	// As result they might look like this: /plugins/servlet/ac/:addonKey/:moduleKey/:extraPathSegment
	// Because of that we have issues with menu ids matching since the path created out of current route differs from the actual url.
	// To fix this we remove those extra path segments from the url.
	if (path.startsWith('/plugins/servlet/ac/')) {
		path = path.split('/').slice(0, 6).join('/');
	}
	return ENTITY_ID.APP(removeTrailingSlash(removeQueryParams(removeHash(path))));
};

// Because of the apps nature, the parent url may differ from the child url.
// And if we want to make sure the expand state is properly calculated, we must pass down children menu ids.
const toExpandableMenuId = (sections?: AppSection[]) =>
	(sections || []).flatMap(({ links }) => (links || []).map((link) => toMenuItemMenuId(link.url)));
