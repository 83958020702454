// Project template type keys are used to create projects.
// TODO: KURO-1818 consolidate keys from `project-template-keys` in this file.

// Software template keys
export const KANBAN_TEAM_MANAGED_KEY = 'com.pyxis.greenhopper.jira:gh-simplified-agility-kanban';
export const KANBAN_COMPANY_MANAGED_KEY = 'com.pyxis.greenhopper.jira:gh-simplified-kanban-classic';

export const SCRUM_TEAM_MANAGED_KEY = 'com.pyxis.greenhopper.jira:gh-simplified-agility-scrum';
export const SCRUM_COMPANY_MANAGED_KEY = 'com.pyxis.greenhopper.jira:gh-simplified-scrum-classic';

export const BUG_TRACKING_COMPANY_MANAGED_KEY = 'com.pyxis.greenhopper.jira:gh-simplified-basic';

export const CROSS_TEAM_MANAGED_KEY = 'com.pyxis.greenhopper.jira:gh-cross-team-template';

export const CROSS_TEAM_PLANNING_COMPANY_MANAGED_KEY =
	'com.pyxis.greenhopper.jira:gh-cross-team-planning-template';

export type JSWProjectTemplateKey =
	| typeof KANBAN_TEAM_MANAGED_KEY
	| typeof KANBAN_COMPANY_MANAGED_KEY
	| typeof SCRUM_TEAM_MANAGED_KEY
	| typeof SCRUM_COMPANY_MANAGED_KEY
	| typeof BUG_TRACKING_COMPANY_MANAGED_KEY
	| typeof CROSS_TEAM_MANAGED_KEY
	| typeof CROSS_TEAM_PLANNING_COMPANY_MANAGED_KEY;

// Work management template keys
export const JWM_TEMPLATE_PREFIX = 'com.atlassian.jira-core-project-templates';

export const PROJECT_MANAGEMENT_COMPANY_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-core-simplified-project-management`;
export const PROJECT_MANAGEMENT_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-project-management-team-managed`;
export const BLANK_TEMPLATE_COMPANY_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-blank-project`;
export const BLANK_TEMPLATE_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-blank-project-team-managed`;
export const CAMPAIGN_MANAGEMENT_COMPANY_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-campaign-management`;
export const CAMPAIGN_MANAGEMENT_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-campaign-management-team-managed`;
export const CONTENT_MANAGEMENT_COMPANY_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-core-simplified-content-management`;
export const CONTENT_MANAGEMENT_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-content-management-team-managed`;
export const TASK_TRACKING_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-task-tracking-team-managed`;
export const DOCUMENT_APPROVAL_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-document-approval-team-managed`;
export const PROCESS_CONTROL_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-process-control-team-managed`;
export const POLICY_MANAGEMENT_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-policy-management-team-managed`;
export const SALES_PIPELINE_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-sales-pipeline-team-managed`;
export const PROCUREMENT_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-procurement-team-managed`;
export const WEB_DESIGN_PROCESS_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-web-design-process-team-managed`;
export const BUDGET_CREATION_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-budget-creation-team-managed`;
export const EMPLOYEE_ONBOARDING_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-employee-onboarding-team-managed`;
export const RECRUITMENT_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-recruitment-team-managed`;
export const LEAD_TRACKING_TEAM_MANAGED_KEY = `${JWM_TEMPLATE_PREFIX}:jira-work-management-lead-tracking-team-managed`;

export type JWMProjectTemplateKey =
	| typeof PROJECT_MANAGEMENT_COMPANY_MANAGED_KEY
	| typeof PROJECT_MANAGEMENT_TEAM_MANAGED_KEY
	| typeof BLANK_TEMPLATE_COMPANY_MANAGED_KEY
	| typeof BLANK_TEMPLATE_TEAM_MANAGED_KEY
	| typeof CAMPAIGN_MANAGEMENT_COMPANY_MANAGED_KEY
	| typeof CAMPAIGN_MANAGEMENT_TEAM_MANAGED_KEY
	| typeof CONTENT_MANAGEMENT_COMPANY_MANAGED_KEY
	| typeof CONTENT_MANAGEMENT_TEAM_MANAGED_KEY
	| typeof TASK_TRACKING_TEAM_MANAGED_KEY
	| typeof DOCUMENT_APPROVAL_TEAM_MANAGED_KEY
	| typeof PROCESS_CONTROL_TEAM_MANAGED_KEY
	| typeof POLICY_MANAGEMENT_TEAM_MANAGED_KEY
	| typeof SALES_PIPELINE_TEAM_MANAGED_KEY
	| typeof PROCUREMENT_TEAM_MANAGED_KEY
	| typeof WEB_DESIGN_PROCESS_TEAM_MANAGED_KEY
	| typeof BUDGET_CREATION_TEAM_MANAGED_KEY
	| typeof EMPLOYEE_ONBOARDING_TEAM_MANAGED_KEY
	| typeof RECRUITMENT_TEAM_MANAGED_KEY
	| typeof LEAD_TRACKING_TEAM_MANAGED_KEY;

// Service management template keys
export const IT_SERVICE_MANAGEMENT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-it-service-management' as const;
export const IT_SERVICE_MANAGEMENT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-it-service-desk' as const;

export const IT_SERVICE_MANAGEMENT_BASIC_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-it-service-management-basic' as const;

export const IT_SERVICE_MANAGEMENT_OPERATIONS_TEMPLATE_KEY =
	'com.atlassian.servicedesk:simplified-it-service-management-operations' as const;

export const JIRA_CUSTOMER_SERVICE_COMPANY_MANAGED_KEY =
	'com.atlassian.jcs:customer-service-management' as const;

// This is the key used by project-templates-v2 to mapped to the yaml file in the backend
// TODO START-1156: Delete this file and references to this key
export const BLANK_SERVICE_MANAGEMENT_PROJECT_BUSINESS_TEMPLATE_KEY =
	'jsm.blank-project-business-cmp-only' as const;
// TODO START-1156: Delete this file and references to this key
export const BLANK_SERVICE_MANAGEMENT_PROJECT_IT_COMPANY_MANAGED_TEMPLATE_KEY =
	'com.atlassian.servicedesk:simplified-blank-project-it' as const;
// This is the key used by project-templates-v2 to mapped to the yaml file in the backend
// TODO START-1156: Delete this file and references to this key
export const BLANK_SERVICE_MANAGEMENT_PROJECT_IT_TEMPLATE_KEY =
	'jsm.blank-project-it-cmp-only' as const;
// TODO START-1156: Delete this file and references to this key
export const BLANK_SERVICE_MANAGEMENT_PROJECT_BUSINESS_COMPANY_MANAGED_TEMPLATE_KEY =
	'com.atlassian.servicedesk:simplified-blank-project-business' as const;

// This is the key used by project-templates-v2 to mapped to the yaml file in the backend
export const BLANK_SERVICE_MANAGEMENT_TEMPLATE_KEY = 'jsm.blank-service-management' as const;
export const BLANK_SERVICE_MANAGEMENT_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:company-managed-blank-service-project' as const;
export const BLANK_SERVICE_MANAGEMENT_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:team-managed-blank-service-project' as const;

export const LEGAL_COMPANY_MANAGED_KEY = 'com.atlassian.servicedesk:simplified-legal-service-desk';
export const LEGAL_TEAM_MANAGED_KEY = 'com.atlassian.servicedesk:next-gen-legal-service-desk';

// TODO START-1156: Delete this const and references to this key
export const DEPRECATED_GENERAL_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-general-service-desk' as const;
// TODO START-1156: Delete this const and references to this key
export const DEPRECATED_GENERAL_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-general-service-desk' as const;

export const GENERAL_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:company-managed-general-service-project' as const;
export const GENERAL_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:team-managed-general-service-project' as const;

// TODO START-1156: Delete this const and references to this key
export const GENERAL_SERVICE_PROJECT_FOR_IT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-general-service-desk-it' as const;
// TODO START-1156: Delete this const and references to this key
export const GENERAL_SERVICE_PROJECT_FOR_IT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-general-it-service-desk' as const;

// TODO START-1156: Delete this const and references to this key
export const GENERAL_SERVICE_PROJECT_FOR_BUSINESS_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-general-service-desk-business' as const;
// TODO START-1156: Delete this const and references to this key
export const GENERAL_SERVICE_PROJECT_FOR_BUSINESS_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-general-business-service-desk' as const;

export const EXTERNAL_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-external-service-desk' as const;
export const EXTERNAL_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-external-service-desk' as const;

export const HR_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-hr-service-desk' as const;
export const HR_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-hr-service-desk' as const;

export const FACILITIES_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-facilities-service-desk' as const;
export const FACILITIES_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-facilities-service-desk' as const;

export const ANALYTICS_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-analytics-service-desk' as const;
export const ANALYTICS_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-analytics-service-desk' as const;

export const FINANCE_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-finance-service-desk' as const;
export const FINANCE_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-finance-service-desk' as const;

export const MARKETING_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-marketing-service-desk' as const;
export const MARKETING_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-marketing-service-desk' as const;

export const SALES_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-sales-service-desk' as const;
export const SALES_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-sales-service-desk' as const;

export const DESIGN_SERVICE_PROJECT_COMPANY_MANAGED_KEY =
	'com.atlassian.servicedesk:simplified-design-service-desk' as const;
export const DESIGN_SERVICE_PROJECT_TEAM_MANAGED_KEY =
	'com.atlassian.servicedesk:next-gen-design-service-desk' as const;

export const INITIAL_PROJECT_TEMPLATE_PROJECT_PROPERTY_KEY = 'initial.project.template.name';

export type JSMProjectTemplateKey =
	| typeof IT_SERVICE_MANAGEMENT_COMPANY_MANAGED_KEY
	| typeof IT_SERVICE_MANAGEMENT_TEAM_MANAGED_KEY
	| typeof IT_SERVICE_MANAGEMENT_BASIC_COMPANY_MANAGED_KEY
	| typeof IT_SERVICE_MANAGEMENT_OPERATIONS_TEMPLATE_KEY
	| typeof LEGAL_COMPANY_MANAGED_KEY
	| typeof LEGAL_TEAM_MANAGED_KEY
	| typeof BLANK_SERVICE_MANAGEMENT_PROJECT_COMPANY_MANAGED_KEY
	| typeof BLANK_SERVICE_MANAGEMENT_PROJECT_TEAM_MANAGED_KEY
	| typeof BLANK_SERVICE_MANAGEMENT_PROJECT_IT_COMPANY_MANAGED_TEMPLATE_KEY
	| typeof BLANK_SERVICE_MANAGEMENT_PROJECT_BUSINESS_COMPANY_MANAGED_TEMPLATE_KEY
	| typeof DEPRECATED_GENERAL_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof DEPRECATED_GENERAL_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof GENERAL_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof GENERAL_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof GENERAL_SERVICE_PROJECT_FOR_IT_COMPANY_MANAGED_KEY
	| typeof GENERAL_SERVICE_PROJECT_FOR_IT_TEAM_MANAGED_KEY
	| typeof GENERAL_SERVICE_PROJECT_FOR_BUSINESS_COMPANY_MANAGED_KEY
	| typeof GENERAL_SERVICE_PROJECT_FOR_BUSINESS_TEAM_MANAGED_KEY
	| typeof EXTERNAL_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof EXTERNAL_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof HR_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof HR_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof FACILITIES_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof FACILITIES_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof ANALYTICS_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof ANALYTICS_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof FINANCE_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof FINANCE_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof MARKETING_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof MARKETING_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof SALES_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof SALES_SERVICE_PROJECT_TEAM_MANAGED_KEY
	| typeof DESIGN_SERVICE_PROJECT_COMPANY_MANAGED_KEY
	| typeof DESIGN_SERVICE_PROJECT_TEAM_MANAGED_KEY;

// Product discovery template keys
export const PRODUCT_DISCOVERY_TEAM_MANAGED_KEY = 'jira.polaris:discovery';
export const PRODUCT_DISCOVERY_BLANK_TEAM_MANAGED_KEY = 'jira.polaris:blank-project';
export const PRODUCT_DISCOVERY_ROADMAP_TEAM_MANAGED_KEY = 'jira.polaris:roadmap';
// GALILEO-1300 START
export const PRODUCT_DISCOVERY_PRIORITIZE_TEAM_MANAGED_KEY = 'jira.polaris:prioritization';
// GALILEO-1300 END
export type JPDProjectTemplateKey =
	| typeof PRODUCT_DISCOVERY_TEAM_MANAGED_KEY
	| typeof PRODUCT_DISCOVERY_BLANK_TEAM_MANAGED_KEY
	| typeof PRODUCT_DISCOVERY_ROADMAP_TEAM_MANAGED_KEY;

// template key type
export type TemplateKey =
	| JSWProjectTemplateKey
	| JWMProjectTemplateKey
	| JSMProjectTemplateKey
	| JPDProjectTemplateKey;

export const getIsJsmBlankProjectTemplate = (T4JTemplateKey: string | null | undefined): boolean =>
	T4JTemplateKey === BLANK_SERVICE_MANAGEMENT_PROJECT_BUSINESS_TEMPLATE_KEY ||
	T4JTemplateKey === BLANK_SERVICE_MANAGEMENT_PROJECT_IT_TEMPLATE_KEY ||
	T4JTemplateKey === BLANK_SERVICE_MANAGEMENT_TEMPLATE_KEY;

export const getGeneralITOrBusinessProjectTemplateMap: {
	[key: string]: string | undefined;
} = {
	[GENERAL_SERVICE_PROJECT_FOR_BUSINESS_COMPANY_MANAGED_KEY]:
		GENERAL_SERVICE_PROJECT_COMPANY_MANAGED_KEY,
	[GENERAL_SERVICE_PROJECT_FOR_IT_COMPANY_MANAGED_KEY]: GENERAL_SERVICE_PROJECT_COMPANY_MANAGED_KEY,
	[GENERAL_SERVICE_PROJECT_FOR_BUSINESS_TEAM_MANAGED_KEY]: GENERAL_SERVICE_PROJECT_TEAM_MANAGED_KEY,
	[GENERAL_SERVICE_PROJECT_FOR_IT_TEAM_MANAGED_KEY]: GENERAL_SERVICE_PROJECT_TEAM_MANAGED_KEY,
};

export const isJwmTmpTemplateKey = (templateKey: string): templateKey is JWMProjectTemplateKey => {
	return [
		PROJECT_MANAGEMENT_TEAM_MANAGED_KEY,
		BLANK_TEMPLATE_TEAM_MANAGED_KEY,
		CAMPAIGN_MANAGEMENT_TEAM_MANAGED_KEY,
		CONTENT_MANAGEMENT_TEAM_MANAGED_KEY,
		TASK_TRACKING_TEAM_MANAGED_KEY,
		DOCUMENT_APPROVAL_TEAM_MANAGED_KEY,
		PROCESS_CONTROL_TEAM_MANAGED_KEY,
		POLICY_MANAGEMENT_TEAM_MANAGED_KEY,
		SALES_PIPELINE_TEAM_MANAGED_KEY,
		PROCUREMENT_TEAM_MANAGED_KEY,
		WEB_DESIGN_PROCESS_TEAM_MANAGED_KEY,
		BUDGET_CREATION_TEAM_MANAGED_KEY,
		EMPLOYEE_ONBOARDING_TEAM_MANAGED_KEY,
		RECRUITMENT_TEAM_MANAGED_KEY,
		LEAD_TRACKING_TEAM_MANAGED_KEY,
	].includes(templateKey);
};
