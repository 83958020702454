import React from 'react';
import type { QueuesIssueViewEntry } from '@atlassian/jira-spa-apps-servicedesk-queues-issue-view-entry/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';

export const LazyServicedeskQueuesIssueView = lazyForPaint<typeof QueuesIssueViewEntry>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-queues-issue-view", jiraSpaEntry: "async-servicedesk-queues-issue-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-issue-view-entry'
		).then(({ QueuesIssueViewEntry }) => QueuesIssueViewEntry),
	),
);

type Props = RouteContext;

export const CustomIssueRouteComponent = ({ match }: Props) => {
	const queueId = (match.params && match.params.queueId) || '';
	const issueKey = (match.params && match.params.issueKey) || '';
	const oldIssueView = `/${(match.query && match.query.oldIssueView) || ''}`;
	return (
		<LazyPage
			Page={LazyServicedeskQueuesIssueView}
			pageId={`spa-apps/servicedesk/queues/${queueId}/${issueKey}/${oldIssueView}`}
			shouldShowSpinner={!fg('jsm_views_inside_queues_-_main_flag')}
		/>
	);
};
