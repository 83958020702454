import React from 'react';
import Badge from '@atlaskit/badge';

type Nav4NotificationsIndicatorProps = {
	count: number;
	appearance?: 'added' | 'default' | 'important' | 'primary' | 'primaryInverted' | 'removed';
	max?: number;
};

const MAX_NOTIFICATIONS_COUNT = 9;

export const Nav4NotificationsIndicator = (props: Nav4NotificationsIndicatorProps) => {
	const { appearance = 'important', count, max = MAX_NOTIFICATIONS_COUNT } = props;

	return count ? (
		<div data-test-selector="NotificationIndicator">
			<Badge
				testId="atlassian-navigation.ui.notifications.notification-indicator-badge"
				max={max}
				appearance={appearance}
			>
				{count}
			</Badge>
		</div>
	) : null;
};
