import FilterIcon from '@atlaskit/icon/core/migration/filter';
import IssuesIcon from '@atlaskit/icon/core/migration/issues';
import LabelIcon from '@atlaskit/icon/core/migration/tag--label';
import TeamsIcon from '@atlaskit/icon/core/migration/teams';
import ProjectIcon from '@atlaskit/icon/core/project';
import type { IntlShape } from '@atlassian/jira-intl';
import { BOARD_SOURCE_FIELDS } from '../../../../common/constants.tsx';
import type { SourceTypeOptionGroup } from '../../../../common/types.tsx';
import messages from './messages.tsx';

export const getOptionGroups = ({
	formatMessage,
	projectAvatar,
}: {
	formatMessage: IntlShape['formatMessage'];
	projectAvatar?: string;
}): SourceTypeOptionGroup[] => {
	return [
		{
			options: [
				{
					label: formatMessage(messages.allIssuesFromProjectTitle),
					title: formatMessage(messages.allIssuesFromProjectTitle),
					selectedTitle: messages.allIssuesFromProjectSelectedTitle,
					value: null,
					icon: projectAvatar || ProjectIcon,
				},
			],
		},
		{
			options: [
				{
					label: formatMessage(messages.teamTitle),
					title: formatMessage(messages.teamTitle),
					selectedTitle: messages.teamSelectedTitle,
					description: formatMessage(messages.teamDescription),
					value: BOARD_SOURCE_FIELDS.TEAM,
					icon: TeamsIcon,
				},
				{
					label: formatMessage(messages.issueTypesTitle),
					title: formatMessage(messages.issueTypesTitle),
					selectedTitle: messages.issueTypesSelectedTitle,
					description: formatMessage(messages.issueTypesDescription),
					value: BOARD_SOURCE_FIELDS.ISSUE_TYPES,
					icon: IssuesIcon,
				},
				{
					label: formatMessage(messages.labelsTitle),
					title: formatMessage(messages.labelsTitle),
					selectedTitle: messages.labelsSelectedTitle,
					description: formatMessage(messages.labelsDescription),
					value: BOARD_SOURCE_FIELDS.LABELS,
					icon: LabelIcon,
				},
			],
		},
		{
			options: [
				{
					label: formatMessage(messages.savedFilterTitle),
					title: formatMessage(messages.savedFilterTitle),
					selectedTitle: messages.savedFilterSelectedTitle,
					description: formatMessage(messages.savedFilterDescription),
					value: BOARD_SOURCE_FIELDS.SAVED_FILTER,
					icon: FilterIcon,
				},
			],
		},
	];
};
