/**
 * @generated SignedSource<<5c34094f44d8d7b887c6cf2af036ca84>>
 * @relayHash c93ee393688d675e660c25a8e54da951
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a18593ff86489f314842ff3d23157f442a64e8cecdd87805e60d91cfa6983b41

import type { ConcreteRequest, Query } from 'relay-runtime';
export type issueDetailsForAIContextPanelQuery$variables = {
  cloudId: string;
  issueKey: string;
};
export type issueDetailsForAIContextPanelQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly fieldByIdOrAlias: {
        readonly text?: string | null | undefined;
      } | null | undefined;
      readonly issueId: string;
      readonly projectField: {
        readonly project: {
          readonly isAIEnabled: boolean | null | undefined;
          readonly projectId: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type issueDetailsForAIContextPanelQuery = {
  response: issueDetailsForAIContextPanelQuery$data;
  variables: issueDetailsForAIContextPanelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v4 = [
  {
    "kind": "Literal",
    "name": "idOrAlias",
    "value": "servicedesk-practices"
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ],
  "type": "JiraSingleLineTextField"
},
v6 = {
  "kind": "ScalarField",
  "name": "isAIEnabled"
},
v7 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "issueDetailsForAIContextPanelQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": (v4/*: any*/),
                "kind": "LinkedField",
                "name": "fieldByIdOrAlias",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": "fieldByIdOrAlias(idOrAlias:\"servicedesk-practices\")"
              },
              {
                "concreteType": "JiraProjectField",
                "kind": "LinkedField",
                "name": "projectField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "issueDetailsForAIContextPanelQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": (v4/*: any*/),
                "kind": "LinkedField",
                "name": "fieldByIdOrAlias",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  (v5/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": "fieldByIdOrAlias(idOrAlias:\"servicedesk-practices\")"
              },
              {
                "concreteType": "JiraProjectField",
                "kind": "LinkedField",
                "name": "projectField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ]
                  },
                  (v8/*: any*/)
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a18593ff86489f314842ff3d23157f442a64e8cecdd87805e60d91cfa6983b41",
    "metadata": {},
    "name": "issueDetailsForAIContextPanelQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5a922137c0bc9db6f7918d3817034879";

export default node;
