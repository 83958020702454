import { fg } from '@atlassian/jira-feature-gating';
import { AsyncViewsNav } from '@atlassian/jira-horizontal-nav-jsm-views/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { getCalendarFilterFieldsResource } from '@atlassian/jira-router-resources-servicedesk-calendar/src/services/filters/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { servicedeskCalendarRoute } from '@atlassian/jira-router-routes-servicedesk-calendar-routes/src/servicedeskCalendarRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { ClassicCalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-calendar/ClassicCalendarSkeleton.tsx';
import { calendarPageEntryPoint } from './ui/calendar/index.tsx';

export const servicedeskCalendarRouteEntry = createEntry(servicedeskCalendarRoute, () => ({
	group: 'servicedesk',
	ufoName: 'jsm-calendar',
	component: () => null,
	skeleton: ClassicCalendarSkeleton,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncViewsNav,
	},

	layout: serviceProjectLayout,

	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
		getCalendarFilterFieldsResource(),
		resourceWithCondition2(
			() => fg('jsd_shield_weekly_view_fast_follow') && !__SERVER__,
			uifBoardResource,
		),
	],

	entryPoint: () => calendarPageEntryPoint,

	meta: {
		reporting: {
			id: 'calendar',
			packageName: toPackageName(APP_NAMES.CALENDAR),
			teamName: 'jsd-shield',
		},
	},
}));
