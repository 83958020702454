import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessAttachmentsRoute } from '@atlassian/jira-router-routes-business-attachments-routes/src/jiraBusinessAttachmentsRoute.tsx';
import { attachmentsEntryPoint } from '@atlassian/jira-spa-apps-business-attachments/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';

const attachmentsPageEntryPoint = createPageEntryPoint({
	main: attachmentsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

export const jiraBusinessAttachmentsRouteEntry = createEntry(jiraBusinessAttachmentsRoute, () => ({
	group: 'classic-business',
	ufoName: 'jwm.attachments-view',
	layout: businessProjectLayout,
	skeleton: ThemedLazySkeleton,
	entryPoint: () => attachmentsPageEntryPoint,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalNavJira,
	},

	resources: getProjectViewResources(),
}));
