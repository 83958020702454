import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { homepageLayout } from '@atlassian/jira-homepage-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectsDirectoryPageResource } from '@atlassian/jira-router-resources-project-directories/src/services/index.tsx';
import { projectsDirectoryLegacyPathRedirectRoute } from '@atlassian/jira-router-routes-directories-routes/src/projectsDirectoryLegacyPathRedirectRoute.tsx';
import { ProjectsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import { ProjectsDirectoryRedirect } from './ui/ProjectsDirectoryRedirect.tsx';
import { LazyProjectsDirectory } from './common/LazyProjectsDirectory.tsx';

export const projectsDirectoryLegacyPathRedirectRouteEntry = createEntry(
	projectsDirectoryLegacyPathRedirectRoute,
	() => ({
		group: 'directories',
		component: ProjectsDirectoryRedirect,
		skeleton: ProjectsDirectorySkeleton,
		isRedirect: true,
		layout: homepageLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
		},
		resources: [...getNavigationResources(), projectsDirectoryPageResource],
		forPaint: [LazyAtlassianNavigation, LazyProjectsDirectory],
	}),
);
