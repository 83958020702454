import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { planViewNotFoundRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/planViewNotFoundRoute.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { planLayout } from '@atlassian/jira-plan-layout/src/index.tsx';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async.tsx';
import { planViewNotFoundPageEntrypoint } from '@atlassian/jira-spa-apps-error-pages-plan-view-not-found/entrypoint.tsx';

export const planViewNotFoundRouteEntry = createEntry(planViewNotFoundRoute, () => ({
	group: 'software',
	layout: composeLayouts(globalLayout, planLayout),
	navigation: {
		menuId: MENU_ID.PLANS,
		sidebar: AsyncSidebarPlan,
		horizontal: undefined,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [...getNavigationResources(), ...getNavigationSidebarPlanResources()],
	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},
	entryPoint: () => planViewNotFoundPageEntrypoint,
}));
