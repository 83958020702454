import React, { memo } from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import JiraPlaceholder from '@atlassian/jira-placeholder/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const LazyAtlassianOnboardingNav4 = lazyForPaint(() =>
	import(/* webpackChunkName: "async-jira-atlassian-onboarding-nav4" */ './index').then(
		({ Nav4Onboarding }) => Nav4Onboarding,
	),
);

const AsyncAtlassianOnboardingNav4 = memo(() => {
	if (fg('jfp-magma-fix-misaligned-suspense-boundaries')) {
		return (
			<JSErrorBoundary
				id="asyncAtlassianOnboardingNav4"
				packageName="jiraAtlassianOnboardingNav4"
				teamName="jira-cosmos"
				fallback="unmount"
			>
				<JiraPlaceholder name="lazyAtlassianOnboardingNav4">
					<LazyAtlassianOnboardingNav4 />
				</JiraPlaceholder>
			</JSErrorBoundary>
		);
	}

	return (
		<JSErrorBoundary
			id="asyncAtlassianOnboardingNav4"
			packageName="jiraAtlassianOnboardingNav4"
			teamName="jira-cosmos"
			fallback="unmount"
		>
			<Placeholder name="lazyAtlassianOnboardingNav4">
				<LazyAtlassianOnboardingNav4 />
			</Placeholder>
		</JSErrorBoundary>
	);
});

AsyncAtlassianOnboardingNav4.displayName = 'AsyncAtlassianOnboardingNav4';

export default AsyncAtlassianOnboardingNav4;
