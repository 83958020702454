import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/src_jiraBusinessBoardEmbedQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const boardEmbedEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-business-board-embed-entrypoint" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext: { cloudId } }: EntryPointRouteParams) => {
		const projectKey = context.match.params.projectKey;
		const itemId = context.match.params.itemId ?? '';
		let filterJql: string | undefined = context.query?.filter;
		let groupBy: string | undefined = context.query?.groupBy;

		// if in a client environment, check whether the router url matches the current window pathname
		// if it does, use the filter and groupBy from the current window location instead of the router query
		// this avoids reloading the query without the overrides when a user hovers over prefetching links that don't include them
		if (typeof window !== 'undefined' && window.location.pathname === context.match.url) {
			const searchParams = new URLSearchParams(window.location.search);
			filterJql = searchParams.get('filter') ?? undefined;
			groupBy = searchParams.get('groupBy') ?? undefined;
		}

		if (projectKey == null) {
			throw new Error('Project key missing');
		}

		return {
			queries: {
				boardEmbedQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						cloudId,
						projectKey,
						viewInput: {
							jiraBoardViewQueryInput: {
								projectKeyAndItemIdQuery: {
									cloudId,
									projectKey,
									itemId,
								},
							},
							settings: {
								filterJql,
								groupBy,
							},
						},
					},
				},
			},
		};
	},
});
