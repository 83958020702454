// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { State } from './types.tsx';

type Actions = typeof actions;

export const initialState = {
	projectId: undefined,
	data: undefined,
	error: undefined,
	isLoading: false,
} as const;

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'JSMOnboardingType',
});

export const useJsmOnboardingType = createHook(Store);
