import { ViewOptions } from '../change-view-menu-item/view-options/constants.tsx';

const PROJECTS = 'projects' as const;

export const DisplaySettingsScope = {
	PROJECTS,
} as const;

const VIEW_OPTION = 'VIEW_OPTION' as const;

export const DisplaySettingsPropertyKeys = {
	VIEW_OPTION,
} as const;

export const DisplaySettingsAllowedValuesMap = {
	[DisplaySettingsPropertyKeys.VIEW_OPTION]: Object.values(ViewOptions),
} as const;
