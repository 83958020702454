/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
	import JiraIpmIssueToWorkTopBanner from '@post-office/message-template--jira-ipm-issue-to-work-top-banner/placements/in-app/jira-top-banner/render';

export default {
	'jira-ipm-issue-to-work-top-banner': JiraIpmIssueToWorkTopBanner,
};
