import React, { useContext } from 'react';
import StarUnstarredIcon from '@atlaskit/icon/core/star-unstarred';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { SECTION_ITEM_STARRED } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { NavItemsContext } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	FlyoutMenuItem,
	FlyoutMenuItemTrigger,
	FlyoutMenuItemContent,
} from '@atlassian/navigation-system';
import {
	VALID_FAVORITE_TYPES,
	PACKAGE_NAME,
	TEAM_NAME,
	FAVOURITE_QUERY_LIMIT,
} from '../common/constants.tsx';
import messages from './messages.tsx';
import { sidebarStarredMenuEntryPoint } from './starred-menu/entrypoint.tsx';

export function StarredFlyoutMenuItem() {
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { moreMenuIds } = useContext(NavItemsContext);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		sidebarStarredMenuEntryPoint,
		{
			cloudId,
			filter: {
				types: VALID_FAVORITE_TYPES,
				sort: { order: 'DESC' },
			},
			count: FAVOURITE_QUERY_LIMIT,
		},
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const { formattedMessage, icon } = useFormattedMessageAndIcon(
		messages.starred,
		StarUnstarredIcon,
	);

	const onOpenChangeHandler = async (isOpen: boolean) => {
		if (isOpen) {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: NAVIGATION_ITEM,
			});
			const isInMore = moreMenuIds.includes(L1_MENU_ID.STARRED);
			fireUIAnalytics(event, SECTION_ITEM_STARRED, { isInMore });
		}
	};

	return (
		<FlyoutMenuItem onOpenChange={onOpenChangeHandler}>
			<FlyoutMenuItemTrigger
				interactionName="nav4-sidebar-starred"
				ref={triggerRef}
				elemBefore={icon}
			>
				{formattedMessage}
			</FlyoutMenuItemTrigger>
			<FlyoutMenuItemContent>
				<JiraEntryPointContainer
					// Data stream, will subscribe to load updates.
					entryPointReferenceSubject={entryPointReferenceSubject}
					// Required props for error JSErrorBoundary.
					id="nav4-sidebar-starred-default"
					packageName={PACKAGE_NAME}
					teamName={TEAM_NAME}
					// Optional props for error JSErrorBoundary.
					errorFallback="flag"
					// Fallback for `Suspense`.
					fallback={<SkeletonNavMenuItems />}
					// Props for the root component.
					runtimeProps={{}}
				/>
			</FlyoutMenuItemContent>
		</FlyoutMenuItem>
	);
}
