import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { NavItemsContext } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import { getConfigurableMenuIds } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-configurable-menu-ids/index.tsx';
import {
	getAppItemsData,
	getProductItemsData,
	getVisibleAppItemsData,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/items-data/index.tsx';
import type { ConfigurableMenuItems$key } from '@atlassian/jira-relay/src/__generated__/ConfigurableMenuItems.graphql';
import { GlobalAppShortcuts } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-global-app-shortcuts/src/index.tsx';
import { useGlobalApps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/global-apps/index.tsx';
import { TopLevelSpacer } from '@atlassian/navigation-system';
import { fg } from '@atlassian/jira-feature-gating';
import { ConfigurableMenuItem } from '../../common/ui/configurable-menu-item/ConfigurableMenuItem.tsx';
import { useFilterL1MenuItemsByEditionAndLicense } from '../../controllers/use-filter-l1-menu-items-by-edition-and-license/index.tsx';
import { useRefreshL1MenuItems } from '../../controllers/use-refresh-l1-menu-items/index.tsx';
import { MoreMenuIsOpenContextProvider } from './more-menu-is-open-context-provider/index.tsx';
import { SidebarCustomization } from './sidebar-customization/SidebarCustomization.tsx';
import { PeekMenuIdContextProvider } from './peek-menu-id-context-provider/index.tsx';
import { GlobalShortcuts } from './global-shortcuts/index.tsx';

export type ConfigurableMenuItemsProps = {
	menuData: ConfigurableMenuItems$key;
};

export function ConfigurableMenuItems({ menuData }: ConfigurableMenuItemsProps) {
	const data = useFragment<ConfigurableMenuItems$key>(
		graphql`
			fragment ConfigurableMenuItems on Query {
				jira @optIn(to: "JiraUserNavConfig") {
					userNavigationConfiguration(cloudID: $cloudId, navKey: "NAV_SIDEBAR") {
						navItems {
							# eslint-disable-next-line @atlassian/relay/unused-fields
							menuId @required(action: THROW)
							# eslint-disable-next-line @atlassian/relay/unused-fields
							isVisible @required(action: THROW)
						}
					}
				}
				...ConfigurableMenuItem
			}
		`,
		menuData,
	);

	const globalAppLicense = useGlobalApps();
	const navItems = useFilterL1MenuItemsByEditionAndLicense(
		// Add back any missing menu ids before filtering in case licensing has changed or a new L1 has been created.
		// This also handles when a user has no configuration yet.
		useRefreshL1MenuItems(data.jira?.userNavigationConfiguration?.navItems),
	);

	const navItemsContext = useMemo(
		() => ({
			navItems,
			visibleMenuIds: getConfigurableMenuIds(navItems, true),
			moreMenuIds: getConfigurableMenuIds(navItems, false),
			productItemsData: getProductItemsData(navItems),
		}),
		[navItems],
	);

	const visibleGlobalAppMenuIds = getVisibleAppItemsData(navItems, globalAppLicense);
	const appItemsData = getAppItemsData(navItems, globalAppLicense);

	return (
		<NavItemsContext.Provider value={navItemsContext}>
			<PeekMenuIdContextProvider>
				<MoreMenuIsOpenContextProvider>
					{navItemsContext.visibleMenuIds.map((menuId) => (
						<ConfigurableMenuItem key={menuId} menuData={data} menuId={menuId} />
					))}
					{visibleGlobalAppMenuIds.length > 0 && (
						<>
							<TopLevelSpacer />
							{visibleGlobalAppMenuIds.map((menuId) => (
								<GlobalAppShortcuts menuId={menuId} key={menuId} />
							))}
						</>
					)}
					{fg('nav4_global_links_jira') && <GlobalShortcuts />}
					{fg('blu-6576_migrate_to_platform_customisation') && <TopLevelSpacer />}
					<SidebarCustomization
						navItems={navItems}
						menuData={data}
						productItemsData={navItemsContext.productItemsData}
						appItemsData={appItemsData}
					/>
				</MoreMenuIsOpenContextProvider>
			</PeekMenuIdContextProvider>
		</NavItemsContext.Provider>
	);
}
