import React, { memo } from 'react';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/sidebar-boundary/index.tsx';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/common/ui/skeleton/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './main.tsx';

const LazySidebarContent = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-jcs-project-settings" */ './main'
	).then(({ JcsProjectSettingsSidebar }) => JcsProjectSettingsSidebar),
);

export const AsyncJcsProjectSettingsSidebar = memo(({ project }: Props) => (
	<SidebarBoundary
		id="nav4.sidebar.jcs-project-settings"
		packageName="jiraNavigationAppsSidebarNav4JCSProjectSettings"
		teamName="Boysenberry"
		ufoName="nav4.sidebar.jcs-project-settings"
	>
		<Placeholder name="nav4.sidebar.jcs-project-settings.loading-lazy" fallback={<Skeleton />}>
			<LazySidebarContent project={project} />
		</Placeholder>
	</SidebarBoundary>
));
