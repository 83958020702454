import React, { forwardRef, useState } from 'react';
import { Notifications as AkNotification } from '@atlaskit/atlassian-navigation';
import {
	SkeletonNotificationButton,
	Nav4SkeletonNotificationButton,
} from '@atlaskit/atlassian-navigation/skeleton-notification-button';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { SPOTLIGHT_MESSAGE_ID } from '@atlassian/jira-cross-flow-using-notifications-to-improve-admin-approval/src/controllers/constants.tsx';
import {
	useUsingNotificationsToImproveAdminApprovalContext,
	CoordinationStopProvider,
} from '@atlassian/jira-cross-flow-using-notifications-to-improve-admin-approval/src/controllers/index.tsx';
import { UsingNotificationsToImproveAdminApprovalSpotlight } from '@atlassian/jira-cross-flow-using-notifications-to-improve-admin-approval/src/ui/index.tsx';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { Notifications as Nav4Notifications } from '@atlassian/navigation-system';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { ExperimentalNotificationProps } from '../../types.tsx';

// Should this live in the migration layer (@atlassian/jira-navigation-system) instead?
const renderNotifications = (
	props: ExperimentalNotificationProps,
	ref: React.ForwardedRef<HTMLElement>,
) => {
	const { onViewRequests, ...notificationProps } = props;
	if (getWillShowNav4()) {
		const { badge, onClick, isSelected, label } = props;
		return (
			<Nav4Notifications
				badge={badge}
				label={label}
				onClick={onClick}
				isSelected={isSelected}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- PLEASE FIX - ENABLING FLAT LINT CONFIG
				ref={ref as React.ForwardedRef<HTMLButtonElement>}
			/>
		);
	}
	const buttonProps = {
		...notificationProps,
		ref,
	};

	return <AkNotification {...buttonProps} />;
};

export const Notification = forwardRef<HTMLElement, ExperimentalNotificationProps>((props, ref) => {
	const { markSpotlightAsShown, hasSpotlightBeenShown } =
		useUsingNotificationsToImproveAdminApprovalContext();

	const [isSpotlightActive, setIsSpotlightActive] = useState(true);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useTenantContext();

	// As an experiment we will bypass the SSR route for notifications so that instead of rendering
	// a skeleton component that will be replaced by the real component, we will render the real component.
	// This means that TTVC should increase because there will be less work to do on the client side.
	if (getWillShowNav4()) {
		if (!expVal('blu-6229-nav4-top-nav-notifications-perf', 'cohort', false) && __SERVER__) {
			// @ts-expect-error - Type 'ReactNode' is not assignable to type 'string'.
			return <Nav4SkeletonNotificationButton label={props.tooltip} />;
		}
	} else if (__SERVER__) {
		// @ts-expect-error - Type 'ReactNode' is not assignable to type 'string'.
		return <SkeletonNotificationButton label={props.tooltip} />;
	}

	const { onViewRequests, ...notificationProps } = props;

	/**
	 * Send analytics event when user clicks "Close" or "View requests" button on the request spotlight
	 * Event registry - https://data-portal.internal.atlassian.com/analytics/registry/52348
	 * @param {"close"|"viewRequests"} buttonType
	 */
	const handleSendAnalyticsEventForClickRequestSpotlightButton = (
		buttonType: 'close' | 'viewRequests',
	) => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});
		fireUIAnalytics(analyticsEvent, 'requestSpotlightButton', {
			buttonType,
			isSiteAdmin,
		});
	};

	const shouldShowRequestsSpotlight = isSiteAdmin && !hasSpotlightBeenShown;

	if (!shouldShowRequestsSpotlight) {
		return renderNotifications(notificationProps, ref);
	}

	return (
		<SpotlightManager>
			<SpotlightTarget name="admin-request-spotlight">
				{renderNotifications(notificationProps, ref)}
			</SpotlightTarget>
			<SpotlightTransition>
				{isSpotlightActive && (
					<CoordinationClient messageId={SPOTLIGHT_MESSAGE_ID} messageType="engagement">
						<CoordinationStopProvider>
							{(stop) => (
								<UsingNotificationsToImproveAdminApprovalSpotlight
									onViewRequests={() => {
										setIsSpotlightActive(false);
										markSpotlightAsShown();
										onViewRequests && onViewRequests();
										handleSendAnalyticsEventForClickRequestSpotlightButton('viewRequests');
										stop && stop();
									}}
									onClose={() => {
										setIsSpotlightActive(false);
										markSpotlightAsShown();
										handleSendAnalyticsEventForClickRequestSpotlightButton('close');
										stop && stop();
									}}
								/>
							)}
						</CoordinationStopProvider>
					</CoordinationClient>
				)}
			</SpotlightTransition>
		</SpotlightManager>
	);
});
