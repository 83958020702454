import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarGlobalSettingsApps from '@atlassian/jira-navigation-apps-sidebar-global-settings-apps/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { appRequestsRoute } from '@atlassian/jira-router-routes-marketplace-routes/src/appRequestsRoute.tsx';
import { LazyMarketplace, MarketplaceComponent } from './ui/MarketplaceComponent.tsx';

export const appRequestsRouteEntry = createEntry(appRequestsRoute, () => ({
	group: 'marketplace',
	component: MarketplaceComponent,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
		menuId: MENU_ID.ADDONS,
		sidebar: AsyncSidebarGlobalSettingsApps,
	},
	layout: globalLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarGlobalSettingsResources(),
		navigationSidebarGlobalResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyMarketplace],
	ufoName: 'embedded-marketplace',
}));
