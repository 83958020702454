import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { getIsNav4UserOptOut } from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';

/*
 *  This is a similar implementation to Nav4
 *  (ref: src/packages/navigation-apps/sidebar-nav4/rollout/src/common/utils/get-will-show-nav4/get-will-show-nav4-for-eap/index.tsx)
 *  to accommodate the complex rollout logic of the visual refresh.
 */

// Used for the rollout of the visual refresh
// All changes to this function should be replicated in the backend (see readme.md).
export const isVisualRefreshEnabled = () => {
	if (fg('nav4_visual_refresh_merger')) {
		return getWillShowNav4();
	}

	return (
		fg('visual-refresh') && // Kill switch for the entire rollout of the visual refresh
		(isVisualRefreshInDogfooding() || isVisualRefreshInEAP() || isVisualRefreshInOpenBeta())
	);
};

// Used for the dogfooding of the visual refresh
const isVisualRefreshInDogfooding = () => fg('visual-refresh-dogfood') && !getIsNav4UserOptOut();

// Used for the EAP of the visual refresh
const isVisualRefreshInEAP = () => fg('visual-refresh-eap') && !getIsNav4UserOptOut();

// Used for the OpenBeta of the visual refresh
const isVisualRefreshInOpenBeta = () =>
	// eslint-disable-next-line jira/ff/no-preconditioning
	(fg('visual-refresh-beta-existing-customers') && !getIsNav4UserOptOut()) ||
	fg('visual-refresh-beta-sandboxes') ||
	expVal('jira_nav4_beta_free-customers', 'isVisualRefreshEnabled', false) ||
	expVal('jira_nav4_beta_new-customers', 'isVisualRefreshEnabled', false) ||
	expVal('confluence_jira_nav4_new_customers', 'isVisualRefreshEnabled', false);
