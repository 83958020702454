import { TopBannerMessage } from '@post-office/jira-common/components/top-banner-message';

import { createRender } from './types';

export type Props = {
	messageInstanceId: string;
	readMoreLink: string;
};

export const render = createRender(({ messageInstanceId, readMoreLink }: Props) => (
	<TopBannerMessage readMoreLink={readMoreLink} messageInstanceId={messageInstanceId} />
));

export default render;
