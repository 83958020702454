import React from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { KnowledgeBaseNav } from './ui/index.tsx';

const LazyKbNav = lazyForPaint<typeof KnowledgeBaseNav>(() =>
	import(/* webpackChunkName: "async-kb-nav" */ './ui').then(
		({ KnowledgeBaseNav }) => KnowledgeBaseNav,
	),
);

export const AsyncKbNav = () => {
	return (
		<ErrorBoundary id="horizontal-jsm-kb-nav" packageName="jira-horizontal-nav-jsm-knowledge-base">
			<Placeholder name="horizontal-jsm-kb-nav">
				<LazyKbNav />
			</Placeholder>
		</ErrorBoundary>
	);
};
