/**
 * @generated SignedSource<<c25e21ec05fd2b676e0b39f50f317308>>
 * @relayHash aaa779177ef49e606102f9e257279c2f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 52e90730f9edc63b8e9c272f467903f50d287b068a5e9571a6fd34719bb2539c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineEmbedQuery } from './src_jiraBusinessTimelineEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "52e90730f9edc63b8e9c272f467903f50d287b068a5e9571a6fd34719bb2539c",
    "metadata": {},
    "name": "src_jiraBusinessTimelineEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
