import formatInTimeZone from 'date-fns-tz/formatInTimeZone';

export function guessUserTimeZone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function formatDate(date: number, timezone = guessUserTimeZone(), format = 'PP p') {
	return formatInTimeZone(new Date(date), timezone, format);
}

export const getDefaultTimes = () => {
	return [...Array(24).keys()]
		.map((time) => {
			let newTime = time.toString();
			if (newTime.length === 1) {
				newTime = `0${time}`;
			}
			return [`${newTime}:00`, `${newTime}:30`];
		})
		.flatMap((time) => time);
};
