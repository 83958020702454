/**
 * @generated SignedSource<<4b46a02a37c9c9712faf3f1c3cdb2810>>
 * @relayHash 4b1253adfc4d15a018eec6dd37c2a1a6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 567381a90f6d2527c3bad9e3134757d855ffc2d7a4fb51a9a98728011059d85e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PopupContentQuery } from './PopupContentQuery.graphql';

const node: PreloadableConcreteRequest<PopupContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "567381a90f6d2527c3bad9e3134757d855ffc2d7a4fb51a9a98728011059d85e",
    "metadata": {},
    "name": "PopupContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
