import React from 'react';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { piEditIssueEmbedRoute } from '@atlassian/jira-router-routes-product-integrations-routes/src/piEditIssueEmbedRoute.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import type { EditIssueEmbed } from '@atlassian/jira-gmail-embed/src/ui/edit-issue/index.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPIEditIssueEmbed = lazyForPaint<typeof EditIssueEmbed>(
	() =>
		import(
			/* webpackChunkName: "async-pi-issue-edit-embed", jiraSpaEntry: "async-pi-issue-edit-embed" */ '@atlassian/jira-gmail-embed/src/ui/edit-issue/index.tsx'
		).then((module) => module.EditIssueEmbed),
	{ ssr: false },
);

const PIIssueEditEmbedLazy = () => {
	return (
		<AuthenticationCheck>
			<LazyPage
				Page={LazyPIEditIssueEmbed}
				pageId="spa-apps/pi-issue-edit-embed"
				shouldShowSpinner
			/>
		</AuthenticationCheck>
	);
};

export const piEditIssueEmbedRouteEntry = createEntry(piEditIssueEmbedRoute, () => ({
	group: 'issue',
	component: PIIssueEditEmbedLazy,
	resources: [themePreferenceResource],
	layout: chromelessLayout,
	forPaint: [LazyPIEditIssueEmbed],
}));
