/**
 * @generated SignedSource<<2d36a9f85c5349b0ef9cbca078ed35a3>>
 * @relayHash ce78f88e717be15868a81b929e46b29a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8992349e96ccc6c73b6fb84302696f5d4cd0750beeeff610bc86d29382273273

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_jiraCalendarQuery } from './ui_jiraCalendarQuery.graphql';

import isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider from '@atlassian/jira-relay-provider/src/is-jira-calendar-release-flyout-lazy-loading-enabled.relayprovider';

const node: PreloadableConcreteRequest<ui_jiraCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8992349e96ccc6c73b6fb84302696f5d4cd0750beeeff610bc86d29382273273",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider": isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider
    }
  }
};

export default node;
