import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
// eslint-disable-next-line jira/import-whitelist
import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
// eslint-disable-next-line jira/import-whitelist
import { prefetchApiReadyMark } from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import { createResourceError } from '@atlassian/jira-router-resources-utils/src/utils/create-resource-error/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { getCmdbObjectByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
// eslint-disable-next-line jira/import-whitelist
import fetchWorkspaceId from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/get-data/index.tsx';

import type { InsightObjectDetailsResponse } from '@atlassian/jira-servicedesk-insight-object-types/src/common/types/object-details.tsx';

import { checkAuthentication } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import type { RouterDataContext, ResourceStoreContext } from '@atlassian/react-resource-router';

const fetchInsightObjectDetails = async (
	routeDataContext: RouterDataContext,
	routerContext: ResourceStoreContext,
): Promise<InsightObjectDetailsResponse | null> => {
	try {
		const {
			match: { params },
		} = routeDataContext;
		const { objectId } = params;

		if (objectId == null) {
			return null;
		}

		const workspaceId = await fetchWorkspaceId(routeDataContext, routerContext);
		if (workspaceId == null) {
			return null;
		}

		const data: InsightObjectDetailsResponse = await fetchJson(
			getCmdbObjectByIdUrl(workspaceId, objectId),
		);
		setMark(prefetchApiReadyMark);

		return data;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		logger.safeErrorWithoutCustomerData('jiraServicedeskInsightObjectResource', error);
		fireCmdbErrorAnalytics({
			meta: {
				id: 'jiraServicedeskInsightObjectResource',
				packageName: 'jiraSpa',
			},
			error,
		});
		throw error;
	}
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (routeDataContext: RouterDataContext, routerContext: ResourceStoreContext) => {
	const { tenantContext } = routerContext;

	// Early exit on these cases and return a valid response. We don't want to flood sentry.
	if (!checkAuthentication(tenantContext)) {
		throw createResourceError({
			component: 'spa.view.routes.servicedesk.cmdb-object.getData',
			message: 'Current user is not authenticated',
			name: 'AuthenticationError',
			skipSentry: true,
		});
	}

	return fetchInsightObjectDetails(routeDataContext, routerContext);
};
