import {
	MENU_ID_APPS,
	MENU_ID_FILTERS,
	MENU_ID_OPERATIONS,
	MENU_ID_PLANS,
	MENU_ID_PROJECTS,
	MENU_ID_OVERVIEWS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { getInitialSelectedPath } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/utils/selected-path/getInitialSelectedPath.tsx';
import { isSelectedPath } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/utils/selected-path/isSelectedPath.tsx';
import {
	createRelayResource,
	RELAY_RESOURCE_TYPE,
} from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import SidebarNav4Query, {
	type L1MenuItemsQuery,
} from '@atlassian/jira-relay/src/__generated__/L1MenuItemsQuery.graphql';
import SidebarNav4UiStateQuery, {
	type sidebarSelectorQuery,
} from '@atlassian/jira-relay/src/__generated__/sidebarSelectorQuery.graphql';
import { JiraNavigationSettingsAri } from '@atlassian/ari/jira/navigation-settings';
import { IdentityUserAri } from '@atlassian/ari/identity/user';

const RESOURCE_TYPE_SIDEBAR_V2_RELAY = 'SIDEBAR_V2_RELAY';

export const sidebarNav4Resource = createRelayResource<L1MenuItemsQuery>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_SIDEBAR_V2_RELAY}`,
	getQuery: (routerContext, { tenantContext: { cloudId, activationId, atlassianAccountId } }) => {
		const initialSelectedPath = getInitialSelectedPath(routerContext);

		return {
			parameters: SidebarNav4Query,
			variables: {
				cloudId,
				isAppsExpanded: isSelectedPath(initialSelectedPath, MENU_ID_APPS),
				isFiltersExpanded: isSelectedPath(initialSelectedPath, MENU_ID_FILTERS),
				isPlansExpanded: isSelectedPath(initialSelectedPath, MENU_ID_PLANS),
				isProjectsExpanded: isSelectedPath(initialSelectedPath, MENU_ID_PROJECTS),
				isOperationsExpanded: isSelectedPath(initialSelectedPath, MENU_ID_OPERATIONS),
				isOverviewsExpanded: isSelectedPath(initialSelectedPath, MENU_ID_OVERVIEWS),
				entityAriProjects: JiraNavigationSettingsAri.create({
					siteId: cloudId,
					activationId,
					navigationSettingScope: 'projects',
				}).toString(),
				ownerAri:
					atlassianAccountId && IdentityUserAri.create({ userId: atlassianAccountId }).toString(),
			},
			options: { fetchPolicy: 'store-and-network' },
		};
	},
});

export const sidebarNav4UiStateResource = createRelayResource<sidebarSelectorQuery>({
	type: `${RELAY_RESOURCE_TYPE}_SIDEBAR_V2_UI_STATE_RELAY`,
	getQuery: (_, { tenantContext: { cloudId } }) => ({
		parameters: SidebarNav4UiStateQuery,
		variables: { cloudId },
		options: { fetchPolicy: 'store-and-network' },
	}),
});
