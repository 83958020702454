import React, { useCallback, useMemo, useRef } from 'react';
import uniq from 'lodash/uniq';
import {
	graphql,
	useFragment,
	usePreloadedQuery,
	useRefetchableFragment,
	type PreloadedQuery,
} from 'react-relay';
import {
	useIrremovableFields,
	DEFAULT_IRREMOVABLE_FIELDS,
	type IrremovableFields,
} from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import {
	HYDRATION_QUERY,
	useHydrationQueryLoader,
} from '@atlassian/jira-jql-builder-common/src/ui/hydrate-jql-builder/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { hydrateJqlBuilderQuery } from '@atlassian/jira-relay/src/__generated__/hydrateJqlBuilderQuery.graphql';
import HYDRATE_REFETCH_QUERY, {
	type HydrationRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/HydrationRefetchQuery.graphql';

import type { ui_jqlBuilder_JQLBuilderUI$key } from '@atlassian/jira-relay/src/__generated__/ui_jqlBuilder_JQLBuilderUI.graphql';
import type { ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$key as HydrationFragment } from '@atlassian/jira-relay/src/__generated__/ui_jqlBuilder_JQLBuilderUI_hydrationQueryData.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SEARCH_MODE_ADVANCED,
	SEARCH_MODE_BASIC,
	SEARCH_MODE_SWITCHER_LEFT_LOCATION,
} from '../common/constants.tsx';
import type { SearchModeChangeReason, SearchMode, JQLBuilderBase } from '../common/types.tsx';
import { toSearchModeWithBasicModeFallback } from '../common/utils.tsx';
import {
	SelectedSearchModeContainer,
	SingleSearchModeContainer,
} from '../controllers/selected-search-mode-state/index.tsx';
import { useContextJql } from '../controllers/use-context-jql/index.tsx';
import {
	QuerySupportedContainer,
	QuerySupportedContainerOld,
} from '../controllers/use-query/index.tsx';
import { HydrationQueryProvider } from '../services/hydration-query/index.tsx';
import { useSearchModeMutation } from '../services/search-mode/index.tsx';
import { JQLBuilderFallback } from './jql-builder-fallback/index.tsx';
import { JQLBuilderUIImpl, JQLBuilderUIImplOld } from './main.tsx';

export type JQLBuilderUIProps = JQLBuilderBase & {
	userPreferences: ui_jqlBuilder_JQLBuilderUI$key | null;
	initialQueryRef: PreloadedQuery<hydrateJqlBuilderQuery>;
	selectedSearchMode: SearchMode;
	isSearchModeToggleEnabled: boolean;
	irremovableFields: IrremovableFields;
	uniqueSearchModes: [SearchMode, ...SearchMode[]];
	onLoadingMode: (mode: SearchMode) => void;
	loadingModeRef: React.MutableRefObject<SearchMode | undefined>;
};

export type JQLBuilderUIOldProps = JQLBuilderBase & {
	userPreferences: ui_jqlBuilder_JQLBuilderUI$key | null;
};

/* eslint-disable @atlassian/relay/graphql-naming */
export const JQLBUILDER_UI_FRAGMENT = graphql`
	fragment ui_jqlBuilder_JQLBuilderUI on JiraUserPreferences {
		...searchMode_useSearchMode_JQLBuilder
		jqlBuilderSearchMode
	}
`;
/* eslint-enable @atlassian/relay/graphql-naming */

const JQLBuilderUI = ({
	userPreferences,
	searchModes = [SEARCH_MODE_ADVANCED, SEARCH_MODE_BASIC],
	onUpdateSearchMode,
	selectedSearchMode,
	isSearchModeToggleEnabled,
	irremovableFields,
	uniqueSearchModes,
	hideOperatorDropdownBasicMode = false,
	hideTextSearchInput,
	searchPlaceholder,
	searchModeSwitcherLocation,
	projectKeys,
	showOrderByPicker,
	viewContext = null,
	initialQueryRef,
	onLoadingMode,
	loadingModeRef,
	...props
}: JQLBuilderUIProps) => {
	const userPreferencesData = useFragment<ui_jqlBuilder_JQLBuilderUI$key>(
		JQLBUILDER_UI_FRAGMENT,
		userPreferences,
	);

	const saveSearchModePreference = useSearchModeMutation(userPreferencesData);

	const contextJql = useContextJql({
		jql: props.query,
		projectKeys,
	});
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const hydrationQueryData = usePreloadedQuery<hydrateJqlBuilderQuery>(
		HYDRATION_QUERY,
		initialQueryRef,
	);
	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const [hydrationData, hydrationRefetch] = useRefetchableFragment<
		HydrationRefetchQuery,
		HydrationFragment
	>(
		graphql`
			fragment ui_jqlBuilder_JQLBuilderUI_hydrationQueryData on Query
			@refetchable(queryName: "HydrationRefetchQuery") {
				...useHydratedValues
				...jqlEditor_jqlBuilderBasic_JQLEditorUI
				...useQuery
				...useHydratedValues_cascadeSelectPicker
				# Force suspension of consumer while hydration data is not present
				jira @include(if: $includeJira) {
					jqlBuilder(cloudId: $cloudId) {
						hydrateJqlQuery(query: $jql, viewContext: $viewContext) @skip(if: $isFilter) {
							__typename
							... on JiraJqlHydratedQuery {
								jql
							}
						}
						hydrateJqlQueryForFilter(id: $filterAri, viewContext: $viewContext)
							@include(if: $isFilter) {
							__typename
							... on JiraJqlHydratedQuery {
								jql
							}
						}
					}
				}
			}
		`,
		hydrationQueryData,
	);
	/* eslint-enable @atlassian/relay/must-colocate-fragment-spreads */

	const hydratedJql = useMemo(() => {
		if (!fg('empanada_nin_concurrent_mode_fixes')) {
			return undefined;
		}

		const hydrationResult =
			hydrationData?.jira?.jqlBuilder?.hydrateJqlQuery ??
			hydrationData?.jira?.jqlBuilder?.hydrateJqlQueryForFilter;

		if (hydrationResult?.__typename === 'JiraJqlHydratedQuery') {
			return hydrationResult.jql ?? undefined;
		}
	}, [
		hydrationData?.jira?.jqlBuilder?.hydrateJqlQuery,
		hydrationData?.jira?.jqlBuilder?.hydrateJqlQueryForFilter,
	]);

	const onSearchModeChange = useCallback(
		(searchMode: SearchMode, reason?: SearchModeChangeReason) => {
			// Update the search mode preference when the reason is matching the allowed list
			// Note: The reasons currently used below were chosen to match previous behaviour when the new reason "QueryUnsupported" was introduced
			if (reason === 'UserTriggered' || reason === 'ExternalTriggered') {
				saveSearchModePreference(searchMode);
			}
			onUpdateSearchMode?.(searchMode, reason);
		},
		[onUpdateSearchMode, saveSearchModePreference],
	);

	const fallback = (
		<JQLBuilderFallback
			query={props.query}
			searchModes={searchModes}
			onLoad={onLoadingMode}
			selectedSearchMode={selectedSearchMode}
			isSearchModeToggleEnabled={isSearchModeToggleEnabled}
			beforeControls={props.beforeControls}
			renderExtraControls={props.renderExtraControls}
			excludedFields={props.excludedFields}
			components={props.components}
			irremovableFields={irremovableFields}
			hideTextSearchInput={hideTextSearchInput}
			searchPlaceholder={searchPlaceholder}
			searchModeSwitcherLocation={searchModeSwitcherLocation}
			showOrderByPicker={showOrderByPicker}
		/>
	);

	return (
		<Placeholder name="jql-builder-ui" fallback={fallback}>
			{hydrationData ? (
				<HydrationQueryProvider
					query={HYDRATE_REFETCH_QUERY}
					refetch={hydrationRefetch}
					contextJql={contextJql}
					hydratedJql={hydratedJql}
					{...(fg('jql_builder_add_view_context_to_hydration_query') && { viewContext })}
				>
					<QuerySupportedContainer
						query={props.query}
						excludedFields={props.excludedFields}
						jqlMessages={props.jqlMessages}
						hydrationData={hydrationData}
						simplifiedOperators={hideOperatorDropdownBasicMode}
						hideTextSearchInput={hideTextSearchInput}
						contextJql={contextJql}
					>
						{uniqueSearchModes.length === 1 ? (
							<SingleSearchModeContainer searchMode={uniqueSearchModes[0]}>
								<JQLBuilderUIImpl
									searchModes={uniqueSearchModes}
									hydrationData={hydrationData}
									selectedSearchMode={selectedSearchMode}
									onUpdateSearchMode={onUpdateSearchMode}
									hideOperatorDropdownBasicMode={hideOperatorDropdownBasicMode}
									irremovableFields={irremovableFields}
									hideTextSearchInput={hideTextSearchInput}
									searchPlaceholder={searchPlaceholder}
									searchModeSwitcherLocation={searchModeSwitcherLocation}
									contextJql={contextJql}
									showOrderByPicker={showOrderByPicker}
									loadingModeRef={loadingModeRef}
									viewContext={viewContext}
									{...props}
								/>
							</SingleSearchModeContainer>
						) : (
							<SelectedSearchModeContainer
								searchMode={selectedSearchMode}
								onSearchModeChange={onSearchModeChange}
							>
								<JQLBuilderUIImpl
									searchModes={uniqueSearchModes}
									hydrationData={hydrationData}
									selectedSearchMode={selectedSearchMode}
									onUpdateSearchMode={onUpdateSearchMode}
									hideOperatorDropdownBasicMode={hideOperatorDropdownBasicMode}
									irremovableFields={irremovableFields}
									hideTextSearchInput={hideTextSearchInput}
									searchPlaceholder={searchPlaceholder}
									searchModeSwitcherLocation={searchModeSwitcherLocation}
									contextJql={contextJql}
									showOrderByPicker={showOrderByPicker}
									loadingModeRef={loadingModeRef}
									viewContext={viewContext}
									{...props}
								/>
							</SelectedSearchModeContainer>
						)}
					</QuerySupportedContainer>
				</HydrationQueryProvider>
			) : (
				fallback
			)}
		</Placeholder>
	);
};

export const JQLBuilderUIOld = ({
	userPreferences,
	searchModes = [SEARCH_MODE_ADVANCED, SEARCH_MODE_BASIC],
	onUpdateSearchMode,
	hideOperatorDropdownBasicMode = false,
	hideTextSearchInput = false,
	searchPlaceholder,
	searchModeSwitcherLocation = SEARCH_MODE_SWITCHER_LEFT_LOCATION,
	projectKeys,
	showOrderByPicker,
	viewContext = null,
	...props
}: JQLBuilderUIOldProps) => {
	const { onLoad } = props;

	// Persist mode used in loading state to send in analytics
	const loadingModeRef = useRef<SearchMode | undefined>();
	const onLoadingMode = useCallback(
		(mode: SearchMode) => {
			loadingModeRef.current = mode;
			onLoad?.(mode);
		},
		[onLoad],
	);

	const userPreferencesData = useFragment<ui_jqlBuilder_JQLBuilderUI$key>(
		JQLBUILDER_UI_FRAGMENT,
		userPreferences,
	);

	const searchModeResponse = userPreferencesData?.jqlBuilderSearchMode;

	const selectedSearchMode = toSearchModeWithBasicModeFallback(searchModeResponse);
	const saveSearchModePreference = useSearchModeMutation(userPreferencesData);

	if (searchModes.length === 0) {
		throw new Error('Search mode should be non empty');
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const uniqueSearchModes = uniq(searchModes) as [SearchMode, ...SearchMode[]];

	const contextJql = useContextJql({
		jql: props.query,
		projectKeys,
	});

	const [hydrationQueryRef, isHydrating] = useHydrationQueryLoader(contextJql, viewContext);

	const isSearchModeToggleEnabled = uniqueSearchModes ? uniqueSearchModes.length > 1 : true;

	const onSearchModeChange = useCallback(
		(searchMode: SearchMode, reason?: SearchModeChangeReason) => {
			// Update the search mode preference when the reason is matching the allowed list
			// Note: The reasons currently used below were chosen to match previous behaviour when the new reason "QueryUnsupported" was introduced
			if (reason === 'UserTriggered' || reason === 'ExternalTriggered') {
				saveSearchModePreference(searchMode);
			}
			onUpdateSearchMode?.(searchMode, reason);
		},
		[onUpdateSearchMode, saveSearchModePreference],
	);

	const irremovableFields = useIrremovableFields(
		props.basicModeVisibleFields ?? DEFAULT_IRREMOVABLE_FIELDS(),
	);

	return (
		<Placeholder
			name="jql-builder-ui"
			fallback={
				<JQLBuilderFallback
					query={props.query}
					searchModes={searchModes}
					selectedSearchMode={selectedSearchMode}
					isSearchModeToggleEnabled={isSearchModeToggleEnabled}
					beforeControls={props.beforeControls}
					renderExtraControls={props.renderExtraControls}
					excludedFields={props.excludedFields}
					components={props.components}
					irremovableFields={irremovableFields}
					hideTextSearchInput={hideTextSearchInput}
					searchPlaceholder={searchPlaceholder}
					searchModeSwitcherLocation={searchModeSwitcherLocation}
					showOrderByPicker={showOrderByPicker}
				/>
			}
		>
			{hydrationQueryRef ? (
				<QuerySupportedContainerOld
					query={props.query}
					excludedFields={props.excludedFields}
					jqlMessages={props.jqlMessages}
					hydrationQueryRef={hydrationQueryRef}
					isHydrating={isHydrating}
					simplifiedOperators={hideOperatorDropdownBasicMode}
					hideTextSearchInput={hideTextSearchInput}
					contextJql={contextJql}
				>
					{uniqueSearchModes.length === 1 ? (
						<SingleSearchModeContainer searchMode={uniqueSearchModes[0]}>
							<JQLBuilderUIImplOld
								searchModes={uniqueSearchModes}
								hydrationQueryRef={hydrationQueryRef}
								selectedSearchMode={selectedSearchMode}
								isHydrating={isHydrating}
								onUpdateSearchMode={onUpdateSearchMode}
								hideOperatorDropdownBasicMode={hideOperatorDropdownBasicMode}
								irremovableFields={irremovableFields}
								hideTextSearchInput={hideTextSearchInput}
								searchPlaceholder={searchPlaceholder}
								searchModeSwitcherLocation={searchModeSwitcherLocation}
								contextJql={contextJql}
								showOrderByPicker={showOrderByPicker}
								loadingModeRef={loadingModeRef}
								viewContext={viewContext}
								{...props}
							/>
						</SingleSearchModeContainer>
					) : (
						<SelectedSearchModeContainer
							searchMode={selectedSearchMode}
							onSearchModeChange={onSearchModeChange}
						>
							<JQLBuilderUIImplOld
								searchModes={uniqueSearchModes}
								hydrationQueryRef={hydrationQueryRef}
								selectedSearchMode={selectedSearchMode}
								isHydrating={isHydrating}
								onUpdateSearchMode={onUpdateSearchMode}
								hideOperatorDropdownBasicMode={hideOperatorDropdownBasicMode}
								irremovableFields={irremovableFields}
								hideTextSearchInput={hideTextSearchInput}
								searchPlaceholder={searchPlaceholder}
								searchModeSwitcherLocation={searchModeSwitcherLocation}
								contextJql={contextJql}
								showOrderByPicker={showOrderByPicker}
								loadingModeRef={loadingModeRef}
								viewContext={viewContext}
								{...props}
							/>
						</SelectedSearchModeContainer>
					)}
				</QuerySupportedContainerOld>
			) : (
				<JQLBuilderFallback
					query={props.query}
					onLoad={onLoadingMode}
					searchModes={searchModes}
					selectedSearchMode={selectedSearchMode}
					isSearchModeToggleEnabled={isSearchModeToggleEnabled}
					beforeControls={props.beforeControls}
					renderExtraControls={props.renderExtraControls}
					excludedFields={props.excludedFields}
					components={props.components}
					irremovableFields={irremovableFields}
					hideTextSearchInput={hideTextSearchInput}
					searchPlaceholder={searchPlaceholder}
					searchModeSwitcherLocation={searchModeSwitcherLocation}
					showOrderByPicker={showOrderByPicker}
				/>
			)}
		</Placeholder>
	);
};

export default JQLBuilderUI;
