import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';
import { StarredFlyoutMenuItem } from './ui/index.tsx';

export const Starred = () => (
	<UFOSegment name="nav4-sidebar-starred">
		<JSErrorBoundary fallback="flag" id="starred" packageName={PACKAGE_NAME} teamName={TEAM_NAME}>
			<StarredFlyoutMenuItem />
		</JSErrorBoundary>
	</UFOSegment>
);
