import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';

import JIRA_HOME_WORK_SUGGESTIONS_IS_DEVELOPER_QUERY, {
	type JiraHomeWithWorkSuggestionsQuery,
} from '@atlassian/jira-relay/src/__generated__/JiraHomeWithWorkSuggestionsQuery.graphql';
import { LICENSE_STATE_ACTIVE } from '@atlassian/jira-shared-types/src/license-state.tsx';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';

export const jiraHomeWorkSuggestionsIsDeveloperResource =
	createRelayResource<JiraHomeWithWorkSuggestionsQuery>({
		type: 'JIRA_HOME_WORK_SUGGESTIONS_IS_DEVELOPER',
		getQuery: (_, { tenantContext }) => ({
			parameters: JIRA_HOME_WORK_SUGGESTIONS_IS_DEVELOPER_QUERY,
			variables: {
				cloudId: tenantContext.cloudId,
				isSoftwareLicensed:
					tenantContext.licensedProducts[LICENSED_PRODUCTS.JIRA_SOFTWARE] &&
					tenantContext.licenseStates?.software === LICENSE_STATE_ACTIVE,
			},
		}),
	});
