/**
 * Opaque types
 */
export type SanitizedUrl = string;
export type Timestamp = number;
type ProjectAri = string;

// Do not add such as timestampToNumber or UrlToString, because it is not type safe and we shouldn't need it
export const numberToTimestamp = (time: number): Timestamp => {
	if (time < 0) {
		throw new Error(`Trying to case invalid number(${time}) to Timestamp opaque type`);
	}

	return time;
};

export const toProjectAri = (cloudId: string, projectId: number): ProjectAri =>
	`ari:cloud:jira:${cloudId}:project/${projectId}`;

export const RepositoryTypes = {
	REPO_BITBUCKET: 'REPO_BITBUCKET',
	REPO_GITHUB: 'REPO_GITHUB',
	REPO_GITLAB: 'REPO_GITLAB',
} as const;
export type Repository = keyof typeof RepositoryTypes;

// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VcsProviderNamespaces = {
	CLASSIC: 'classic',
	OAUTH: 'oauth',
	BITBUCKET: 'bitbucket',
} as const;

type VcsProviderNamespace = (typeof VcsProviderNamespaces)[keyof typeof VcsProviderNamespaces];

export type VcsProvider = {
	id: string;
	name: string;
	providerId: string;
	providerNamespace: VcsProviderNamespace;
	baseUrl: SanitizedUrl;
	__typename?: 'CodeInJiraVcsProvider';
};

export type HelpArticle = {
	helpArticleId?: string;
	href?: string;
};
