// eslint-disable-next-line jira/import-whitelist
import { metrics } from '@atlassian/browser-metrics';
import type { RouterDataContext, ResourceStoreContext } from '@atlassian/react-resource-router';
import { featuresResource } from '@atlassian/jira-router-resources-service-project-features/src/controllers/get-data/index.tsx';

const FEATURE_STATE_METRIC = 'context-panel-feature-states';
const featureStateMetric = metrics.custom({
	key: FEATURE_STATE_METRIC,
});

export const getFeatureState = (
	routerDataContext: RouterDataContext,
	resourceStoreContext: ResourceStoreContext,
) => {
	featureStateMetric.start();
	return featuresResource(routerDataContext, resourceStoreContext)
		?.then((data) => {
			featureStateMetric.stop();
			return data;
		})
		.catch((e) => {
			featureStateMetric.cancel();
			throw e;
		});
};
