import React from 'react';
import { PlacementFetchingProvider } from '@atlassian/post-office-placement-data';
import type { RouteResource } from '@atlassian/react-resource-router';
import { usePlacementSSRFetch } from './preload-placement.tsx';

export const PlacementFetchProvider: React.FC<{
	children: React.ReactNode;
	resource: RouteResource;
}> = ({ children, resource }) => {
	const fetch = usePlacementSSRFetch(resource);

	return <PlacementFetchingProvider fetch={fetch}>{children}</PlacementFetchingProvider>;
};
