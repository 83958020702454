/**
 * @generated SignedSource<<28aa849ce641b8c8bef67b5f178b9188>>
 * @relayHash 886a8ebe0b7faaa17276f23a1313cc2e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ec953cd8f672124749ec67356940dfd48e2bc1b926c2f3ce46d963fbecdceed3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSummarySoftwareEmbedQuery } from './src_jiraSummarySoftwareEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSummarySoftwareEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ec953cd8f672124749ec67356940dfd48e2bc1b926c2f3ce46d963fbecdceed3",
    "metadata": {},
    "name": "src_jiraSummarySoftwareEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
