// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State, ReturnFocusTo } from '../../common/types.tsx';

export const setReturnFocusTo =
	(returnFocusTo: ReturnFocusTo | null): Action<State> =>
	({ setState }) => {
		setState({ returnFocusTo });
	};

export const actions = {
	setReturnFocusTo,
} as const;

export type Actions = typeof actions;
