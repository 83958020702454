// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { QUICKSTART_UI_STATUS } from '../../../common/constants.tsx';
import type { State } from '../../model/types.tsx';
import { getQuickstartTypeSelector } from '../../model/utils.tsx';

export const resetIsQuickstartLoaded =
	(): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		const quickstartType = getQuickstartTypeSelector(state);
		setState({
			visibility: {
				...state.visibility,
				[quickstartType]: QUICKSTART_UI_STATUS.NOT_LOADED,
			},
			hasNudgeActive: false,
			isQuickstartLoaded: false,
		});
	};
