import React from 'react';

import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';
import { Divider, MenuSection } from '@atlassian/navigation-system/side-nav/menu-section';

import type { MenuItemConfigData } from '../../../types';

import type { MenuItemComponentProps } from './types';

export function MoreMenuSection({
	itemsData,
	moreMenuItemComponent,
	closeMenu,
}: MenuItemComponentProps) {
	if (itemsData.length === 0) {
		return null;
	}

	const MenuItemComponent = moreMenuItemComponent;
	return (
		<MenuSection isMenuListItem>
			<MenuList>
				{itemsData.map(({ menuId }: MenuItemConfigData) => (
					<MenuItemComponent menuId={menuId} key={menuId} closeMenu={closeMenu} />
				))}
			</MenuList>
			<Divider />
		</MenuSection>
	);
}
