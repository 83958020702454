import type { JiraProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import wrapperEditionAwarenessModalWrapperQuery from '@atlassian/jira-relay/src/__generated__/wrapperEditionAwarenessModalWrapperQuery$parameters';

import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { SupportedJiraApplicationKey, SupportedApplicationKey } from '../../common/types.tsx';

type EntryPointParams = {
	cloudId: string;
	jiraApplicationKey: SupportedJiraApplicationKey;
	applicationKey: SupportedApplicationKey;
	offeringKey: string;
	chargeElement: 'user' | 'agent';
	projectType: JiraProjectType;
	isAvatarsFGEnabled: boolean;
};

export const editionAwarenessModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "edition-awareness-next-modal-wrapper" */ './wrapper'),
	),
	getPreloadProps: ({
		cloudId,
		jiraApplicationKey,
		applicationKey,
		offeringKey,
		chargeElement,
		projectType,
		isAvatarsFGEnabled,
	}: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			editionAwareness: {
				parameters: wrapperEditionAwarenessModalWrapperQuery,
				variables: {
					cloudId,
					jiraApplicationKey,
					hamsProductKey: applicationKey,
					offeringKey,
					chargeElement,
					projectType,
					isAvatarsFGEnabled,
				},
			},
		},
	}),
});
