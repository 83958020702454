import React, { useEffect, useCallback } from 'react';
import AlertIcon from '@atlaskit/icon/core/alert';
import OverviewIcon from '@atlaskit/icon/core/align-text-left';
import GraphBarIcon from '@atlaskit/icon/core/chart-bar';
import PhoneIcon from '@atlaskit/icon/core/phone';

import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { SECTION_ITEM_OPERATIONS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	MENU_ID_OPERATIONS,
	MENU_ID_OPS_ALERTS,
	MENU_ID_OPS_ON_CALL,
	MENU_ID_OPS_OVERVIEW,
	MENU_ID_OPS_REPORTS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import type { ExpandableEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-expandable-entry-point-trigger/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { useIsActiveRoute } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { eocGlobalAlertListRoute } from '@atlassian/jira-router-routes-eoc-global-alerts-routes/src/eocGlobalAlertListRoute.tsx';
import { eocTeamAlertDetailRoute } from '@atlassian/jira-router-routes-eoc-team-alerts-routes/src/eocTeamAlertDetailRoute.tsx';
import commonMessages from '../../common/messages.tsx';
import { useFeatures } from '../../controllers/features/index.tsx';
import messages from '../messages.tsx';
import { ActionButtons, PopoutToggleButton } from './action-buttons/index.tsx';
import { useAlertsPopoutStore } from './action-buttons/useAlertsPopoutStore.tsx';

export type OperationsMenuDefaultProps = {
	isExpanded: boolean;
};

const Nav4ExpandableMenuItemWithPressInteraction = ({
	onExpansionToggle,
	children,
	...props
}: React.ComponentProps<typeof Nav4ExpandableMenuItem>) => {
	const pressTracing = usePressTracing('operations-sidebar-alerts-pressed');

	const expansionToggleCallback = useCallback(
		(hasExpanded: boolean) => {
			if (hasExpanded) {
				pressTracing();
				onExpansionToggle?.(hasExpanded);
			}
		},
		[pressTracing, onExpansionToggle],
	);

	return (
		<Nav4ExpandableMenuItem {...props} onExpansionToggle={expansionToggleCallback}>
			{children}
		</Nav4ExpandableMenuItem>
	);
};

type Props = {
	AlertsMenuItemContent: React.ReactNode;
	isExpanded: boolean;
	onSystemToggle?: (isExpanded: boolean) => void;
	onExpansionToggle?: (isExpanded: boolean) => void;
	triggerRef?: ExpandableEntryPointTrigger;
};

export function OperationsExpandableMenuItem(props: Props) {
	const isAlertsRoute = useIsActiveRoute(
		new Set([eocGlobalAlertListRoute.name, eocTeamAlertDetailRoute.name]),
	);
	const { AlertsMenuItemContent, isExpanded, onExpansionToggle, onSystemToggle, triggerRef } =
		props;
	const { hasReports } = useFeatures();
	const { icon: alertsIcon, formattedMessage: alertsMessage } = useFormattedMessageAndIcon(
		commonMessages.alerts,
		AlertIcon,
	);
	const { icon: overviewIcon, formattedMessage: overviewMessage } = useFormattedMessageAndIcon(
		messages.overview,
		OverviewIcon,
	);
	const { icon: oncallSchedulesIcon, formattedMessage: oncallSchedulesMessage } =
		useFormattedMessageAndIcon(messages.onCallSchedules, PhoneIcon);
	const { icon: reportsIcon, formattedMessage: reportsMessage } = useFormattedMessageAndIcon(
		messages.reports,
		GraphBarIcon,
	);
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.OPERATIONS);
	const {
		state: { isPoppedOut },
		setIsPoppedOut,
	} = useAlertsPopoutStore();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const menuId = MENU_ID_OPERATIONS;

	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	useEffect(() => {
		if (!isAlertsRoute) {
			setIsPoppedOut(false);
		}

		return () => {
			setIsPoppedOut(false);
		};
	}, [isAlertsRoute, setIsPoppedOut]);

	const alertsChildren = (
		<>
			<ExpandableMenuItemTrigger
				actions={
					isAlertsRoute && fg('operations_nav4_remaining_items') ? (
						<PopoutToggleButton />
					) : undefined
				}
				onClick={(_e, _a, { isExpanded: hasExpanded }) => {
					if (hasExpanded) {
						fireUIAnalytics(createAnalyticsEvent({}), {
							action: 'clicked',
							actionSubject: NAVIGATION_ITEM,
							actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
							attributes: {
								componentType: FIXED_ITEM,
								level: LEVEL_ONE,
								itemId: 'alerts',
							},
						});
					}
				}}
				elemBefore={alertsIcon}
				testId={getTestId(MENU_ID_OPS_ALERTS)}
			>
				{alertsMessage}
			</ExpandableMenuItemTrigger>
			{(!isPoppedOut || !fg('operations_nav4_remaining_items')) && (
				<ExpandableMenuItemContent>{AlertsMenuItemContent}</ExpandableMenuItemContent>
			)}
		</>
	);

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.OPERATIONS,
						sectionItem: SECTION_ITEM_OPERATIONS,
					});
				}

				onExpansionToggle?.(hasExpanded);
			}}
			onSystemToggle={onSystemToggle}
		>
			<ExpandableMenuItemTrigger
				actionsOnHover={<ActionButtons />}
				elemBefore={icon}
				ref={triggerRef}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<Nav4MenuLinkItem
					elemBefore={overviewIcon}
					href="/jira/ops/overview"
					menuId={MENU_ID_OPS_OVERVIEW}
					onClick={() => {
						fireUIAnalytics(createAnalyticsEvent({}), {
							action: 'clicked',
							actionSubject: NAVIGATION_ITEM,
							actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
							attributes: {
								componentType: FIXED_ITEM,
								level: LEVEL_ONE,
								itemId: 'overview',
							},
						});
					}}
				>
					{overviewMessage}
				</Nav4MenuLinkItem>
				{fg('press_interaction_for_operations_alert_menu') ? (
					<Nav4ExpandableMenuItemWithPressInteraction menuId={MENU_ID_OPS_ALERTS}>
						{alertsChildren}
					</Nav4ExpandableMenuItemWithPressInteraction>
				) : (
					<Nav4ExpandableMenuItem menuId={MENU_ID_OPS_ALERTS}>
						{alertsChildren}
					</Nav4ExpandableMenuItem>
				)}

				<Nav4MenuLinkItem
					elemBefore={oncallSchedulesIcon}
					href="/jira/ops/who-is-on-call"
					menuId={MENU_ID_OPS_ON_CALL}
					onClick={() => {
						fireUIAnalytics(createAnalyticsEvent({}), {
							action: 'clicked',
							actionSubject: NAVIGATION_ITEM,
							actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
							attributes: {
								componentType: FIXED_ITEM,
								level: LEVEL_ONE,
								itemId: 'onCallSchedules',
							},
						});
					}}
				>
					{oncallSchedulesMessage}
				</Nav4MenuLinkItem>
				{hasReports && (
					<Nav4MenuLinkItem
						elemBefore={reportsIcon}
						href="/jira/ops/reports"
						menuId={MENU_ID_OPS_REPORTS}
						onClick={() => {
							fireUIAnalytics(createAnalyticsEvent({}), {
								action: 'clicked',
								actionSubject: NAVIGATION_ITEM,
								actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
								attributes: {
									componentType: FIXED_ITEM,
									level: LEVEL_ONE,
									itemId: 'reports',
								},
							});
						}}
					>
						{reportsMessage}
					</Nav4MenuLinkItem>
				)}
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}
