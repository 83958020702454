import { AsyncHorizontalOverviewNav } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getOverviewViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { classicBusinessOverviewCalendarRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessOverviewCalendarRoute.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { overviewCalendarEntrypoint } from '@atlassian/jira-spa-apps-business-overview-calendar/entrypoint.tsx';

const overviewCalendarPageEntrypoint = createPageEntryPoint({
	main: overviewCalendarEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const classicBusinessOverviewCalendarRouteEntry = createEntry(
	classicBusinessOverviewCalendarRoute,
	() => ({
		group: 'classic-business',
		layout: globalLayout,
		skeleton: ThemedLazySkeleton,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalOverviewNav,
		},

		resources: getOverviewViewResources(),

		entryPoint: () => overviewCalendarPageEntrypoint,
	}),
);
