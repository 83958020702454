import type { Action } from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import type { FeatureFlagValue } from '@atlassian/jira-feature-flagging-using-meta';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import {
	type Application,
	JIRA_SOFTWARE,
	JIRA_PRODUCT_DISCOVERY,
	JIRA_CORE,
	JIRA_SERVICE_DESK,
	JIRA_PORTFOLIO,
} from '@atlassian/jira-shared-types/src/application.tsx';
import {
	type ApplicationEdition,
	FREE_EDITION,
	STANDARD_EDITION,
	PREMIUM_EDITION,
	UNLICENSED,
} from '@atlassian/jira-shared-types/src/edition.tsx';

const transformApplication = (application: Application | null): string => {
	switch (application) {
		case JIRA_SOFTWARE:
			return 'jira-software';
		case JIRA_PRODUCT_DISCOVERY:
			return 'jira-product-discovery';
		case JIRA_CORE:
			return 'jira-core';
		case JIRA_SERVICE_DESK:
			return 'jira-servicedesk';
		case JIRA_PORTFOLIO:
			return 'jira-portfolio';
		default:
			return 'unknown';
	}
};

const transformEdition = (edition: ApplicationEdition | null): string => {
	switch (edition) {
		case FREE_EDITION:
			return 'jedi';
		case STANDARD_EDITION:
			return 'standard';
		case PREMIUM_EDITION:
			return 'premium';
		case UNLICENSED:
			return 'unlicensed';
		default:
			return 'unknown';
	}
};

const transformAction = (action?: Action, wasExperienceSuccesful?: boolean) => {
	if (action !== undefined) {
		return action;
	}

	return wasExperienceSuccesful ? 'taskSuccess' : 'taskFail';
};

export type AdditionalEventAttributes = {
	readonly [attributeKey: string]: string | boolean | number | Record<string, FeatureFlagValue>;
};

export type ExperienceDescription = {
	experience: string;
	wasExperienceSuccesful?: boolean;
	action?: Action;
	location?: string;
	field?: string;
	hasEdit?: boolean;
	analyticsSource: string;
	application: Application | null;
	edition: ApplicationEdition | null;
	additionalAttributes?: AdditionalEventAttributes;
};

const createAnalyticsEvent = ({
	experience,
	wasExperienceSuccesful,
	action,
	location,
	field,
	hasEdit,
	analyticsSource,
	application,
	edition,
	additionalAttributes,
}: ExperienceDescription) => ({
	source: 'ui',
	action: transformAction(action, wasExperienceSuccesful),
	actionSubject: 'ui',
	attributes: {
		...additionalAttributes,
		task: experience,
		...(location ? { reason: location } : {}),
		...(field ? { field } : {}),
		...(typeof hasEdit === 'boolean' ? { hasEdit } : {}),
		analyticsSource,
		application: transformApplication(application),
		edition: transformEdition(edition),
		...(!__SERVER__ && fg('chronos-add-shard-data-to-operational-metrics')
			? { shard: getMeta('ajs-shard') || '', region: getMeta('ajs-region') || '' }
			: {}),
	},
});

/**
 * @param {String} experienceDescription.experience
 * @param {Boolean} experienceDescription.wasExperienceSuccesful - Deprecated: Instead set `action` to `taskSuccess` (previously `true`), `taskFail` (previously `false`) or `taskAbort`
 * @param {Action} experienceDescription.action
 * @param {String} experienceDescription.location
 * @param {String} experienceDescription.field
 * @param {String} experienceDescription.hasEdit
 * @param {String} experienceDescription.analyticsSource
 * @param {String} experienceDescription.application
 * @param {Application} experienceDescription.edition
 * @param {ApplicationEdition} experienceDescription.additionalAttributes
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (experienceDescription: ExperienceDescription): void => {
	getAnalyticsWebClientPromise().then((client) => {
		const event = createAnalyticsEvent(experienceDescription);
		const clientInstance = client.getInstance();
		clientInstance.sendOperationalEvent(event);
	});
};
