import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/srcReportsOverviewPageQuery.graphql';

export const reportsOverviewPageEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "jira-reports-overview-page" */ './src').then(
			(mod) => mod.ReportsOverviewPage,
		),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => {
		const boardId = context.match.params.boardId ? String(context.match.params.boardId) : null;
		const projectKey = context.match.params.projectKey
			? String(context.match.params.projectKey)
			: null;
		const isBoardContext = !!boardId;
		const isProjectContext = !!projectKey;
		const cloudId = tenantContext.cloudId;

		return {
			queries: {
				reportsOverviewPage: {
					options: {
						fetchPolicy: 'network-only' as const,
					},
					parameters,
					variables: {
						boardId: isBoardContext ? `ari:cloud:jira-software:${cloudId}:board/${boardId}` : '',
						projectKey: !isBoardContext && isProjectContext ? projectKey : '',
						cloudId,
						isBoardContext,
					},
				},
			},
		};
	},
});
