import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskPortalSettingsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskPortalSettingsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import ServiceDeskClassicPortalSettings, {
	LazyProjectSettingsServiceDeskClassicPortalSettings,
} from './ui/service-desk-classic-portal-settings/index.tsx';

export const projectSettingsServicedeskPortalSettingsRouteEntry = createEntry(
	projectSettingsServicedeskPortalSettingsRoute,
	() => ({
		group: 'project-settings-servicedesk',
		component: ServiceDeskClassicPortalSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskClassicPortalSettings],
		meta: {
			reporting: {
				id: APP_NAMES.PORTAL_SETTINGS,
				packageName: toPackageName(APP_NAMES.PORTAL_SETTINGS),
				teamName: 'jsd-shield',
			},
		},
	}),
);
