/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useMemo } from 'react';

import { jsx } from '@atlaskit/css';
import { Stack } from '@atlaskit/primitives/compiled';
import {
	BFFConsumerEnum,
	BFFProvider,
	RequestStatus,
	useBFFDataProvider,
} from '@atlassian/navigation-bff-data-provider';
import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';

import type { GlobalShortcutsItem, GlobalShortcutsProps } from '../types';
import { sortAlphabetically } from '../utils';

import { MenuExpandItem } from './menu-expand-item';
import { MenuLinkItem } from './menu-link-item';
import { MenuSkeletonItem } from './menu-skeleton-item';

type MappedGlobalShortcutsItem = GlobalShortcutsItem & { key: string };

function Menu({ testId, shortcutItems = [], forceStatic, cloudId, orgId, useSkeleton }: GlobalShortcutsProps) {
	const { buildURLAndFetchData, status, productLinks } = useBFFDataProvider({
		request: {},
		cloudId,
		orgId,
	});

	const mappedShortcutItems: Array<MappedGlobalShortcutsItem> = useMemo(() => {
		return shortcutItems.map((item, index) => {
			return {
				...item,
				key: item.apsKey || `shortcut-${index}`,
			};
		});
	}, [shortcutItems]);

	const apsKeys = useMemo(() => {
		return shortcutItems.map((item) => item.apsKey || '').filter((item) => !!item);
	}, [shortcutItems]);

	useEffect(() => {
		if (!forceStatic) {
			buildURLAndFetchData(apsKeys);
		}
	}, [apsKeys, buildURLAndFetchData, forceStatic]);

	const createLinkItem = useCallback(
		(shortcut: MappedGlobalShortcutsItem) => {
			// Render nested items if the dynamic data is available
			if (
				!forceStatic &&
				status === RequestStatus.COMPLETE &&
				shortcut?.apsKey &&
				productLinks?.[shortcut.apsKey]
			) {
				const items: GlobalShortcutsItem[] = productLinks[shortcut.apsKey]
					.map((item) => {
						return {
							icon: shortcut.icon,
							label: item.displayName,
							url: item.url,
						};
					})
					.sort((a, b) => sortAlphabetically('asc')(a.label, b.label));

				return <MenuExpandItem icon={shortcut.icon} label={shortcut.label} items={items} />;
			}

			// Show skeleton while BFF is loading
			if (useSkeleton && status === RequestStatus.LOADING) {
				return <MenuSkeletonItem />
			}

			// We only want to allow static menu expand item if we are forcing static,
			// otherwise it should be showing the singular item as a disabled link until the
			// data is loaded.
			if (forceStatic && shortcut.items) {
				return (
					<MenuExpandItem icon={shortcut.icon} label={shortcut.label} items={shortcut.items} />
				);
			}

			return (
				<MenuLinkItem
					key={shortcut.key}
					apsKey={shortcut.apsKey}
					icon={shortcut.icon}
					url={shortcut?.fallbackUrl?.()}
					label={shortcut.label}
				/>
			);
		},
		[forceStatic, productLinks, status, useSkeleton],
	);

	return (
		<Stack testId={testId}>
			<MenuList>{mappedShortcutItems?.map(createLinkItem)}</MenuList>
		</Stack>
	);
}

export default function GlobalShortcuts(props: GlobalShortcutsProps) {
	return (
		<BFFProvider consumer={BFFConsumerEnum.GLOBAL_SHORTCUTS}>
			<Menu {...props} />
		</BFFProvider>
	);
}
