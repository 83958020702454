/** @jsx jsx */

import { cssMap, jsx } from '@atlaskit/css';
import { Drawer, DrawerCloseButton, DrawerContent } from '@atlaskit/drawer/compiled';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import CommerceExperience from '@atlassian/commerce-experience-sdk-react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { type Action, createStore, createHook } from '@atlassian/react-sweet-state';

const styles = cssMap({
	buttonContainer: {
		position: 'absolute',
		top: token('space.100'),
		left: token('space.100'),
	},
	drawerContent: {
		marginTop: token('space.0'),
	},
});

type State =
	| {
			isDrawerOpen: true;
			url: string;
	  }
	| { isDrawerOpen: false; url: null };

const initialState: State = {
	isDrawerOpen: false,
	url: null,
};

type Actions = typeof actions;

const actions = {
	openDrawer:
		(url: string): Action<State> =>
		({ setState }) => {
			setState({ isDrawerOpen: true, url });
		},
	closeDrawer:
		(): Action<State> =>
		({ setState }) => {
			setState({ isDrawerOpen: false, url: null });
		},
};

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'commerce-experience-drawer',
});

export const useCommerceExperienceDrawer = createHook(Store);

export const CommerceExperienceDrawer = () => {
	const [{ isDrawerOpen, url }, { closeDrawer }] = useCommerceExperienceDrawer();

	return (
		<Drawer
			testId="commerce-experience-drawer.ui.drawer"
			isOpen={isDrawerOpen}
			onClose={closeDrawer}
			width="wide"
		>
			<Box xcss={styles.buttonContainer}>
				<DrawerCloseButton />
			</Box>
			{url ? (
				<DrawerContent xcss={styles.drawerContent}>
					<CommerceExperience url={url} onComplete={closeDrawer} onError={closeDrawer} />
				</DrawerContent>
			) : null}
		</Drawer>
	);
};
