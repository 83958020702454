import React, { useMemo } from 'react';
import { ActionButtons } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/common/ui/action-buttons/ActionButtons.tsx';
import { DashboardsDefault } from './default/index.tsx';

export type Nav4DashboardsProps = {
	isExpanded: boolean;
};

export function Nav4Dashboards({ isExpanded }: Nav4DashboardsProps) {
	const actionButtons = useMemo(() => <ActionButtons />, []);

	return <DashboardsDefault actionsOnHover={actionButtons} isExpanded={isExpanded} />;
}
