import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { manageProjectsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/manageProjectsDirectoryRoute.tsx';
import { ProjectsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { adminProjectDirectoryResources } from './common/adminProjectDirectoryResources.tsx';
import { LazyProjectsDirectory } from './common/LazyProjectsDirectory.tsx';
import { ProjectsDirectoryPageEntryPoint } from './common/ProjectsDirectoryPageEntryPoint.tsx';

const LazyAdminProjectsDirectory = () => <LazyProjectsDirectory isAdminProjectDirectoryRoute />;

const ProjectsDirectoryManageComponent = () => (
	<LazyPage
		Page={LazyAdminProjectsDirectory}
		pageId="projects-directory-manage-v3"
		shouldShowSpinner={false}
	/>
);

export const manageProjectsDirectoryRouteEntry = createEntry(manageProjectsDirectoryRoute, () => ({
	group: 'directories',
	component: ProjectsDirectoryManageComponent,
	skeleton: ProjectsDirectorySkeleton,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProjects,
	},
	resources: [...getNavigationResources(), ...adminProjectDirectoryResources()],
	forPaint: [LazyAtlassianNavigation, LazyProjectsDirectory],
	ufoName: 'projects-directory-v3-manage',
	entryPoint() {
		return ProjectsDirectoryPageEntryPoint;
	},
}));
