import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/table_RequestTypesTableQuery$parameters';

type EntryPointProps = {
	projectKey: string;
	cloudId: string;
};

export const requestTypeTableEntrypoint = createEntryPoint({
	root: JSResourceForUserVisible(() =>
		import(
			/* webpackChunkName: "async-request-types-table-view-settings-panel" */
			'./index'
		).then((module) => module.RequestTypesTable),
	),
	getPreloadProps: ({ projectKey, cloudId }: EntryPointProps) => {
		return {
			queries: {
				tableSettings: {
					variables: {
						cloudId,
						projectKey,
					},
					parameters,
					options: {
						fetchPolicy: 'store-or-network' as const,
					},
				},
			},
		};
	},
});
