import memoizeOne from 'memoize-one';
import type { Query } from 'react-resource-router';

import { accuracyTagsGroup, languageTagsGroup, problemTagsGroup } from './tags';
import { fg } from '@atlaskit/platform-feature-flags';

const SUPPORTED_FILTER_TYPES = ['string', 'enum', 'number', 'boolean', 'date'] as const;

export type FilterType = (typeof SUPPORTED_FILTER_TYPES)[number];

type FilterSchemaType<Type extends FilterType, ExtendedFields = Record<string, unknown>> = {
	label: string;
	type: Type;
	param: Readonly<string>;
} & ExtendedFields;

export type StringFilterSchema = FilterSchemaType<'string'>;

export type Option<T = string> = { label: string; value: T };

export type OptionGroup<T = string> = { label: string; options: Option<T>[] };

export type EnumFilterSchema = FilterSchemaType<
	'enum',
	{
		options: Option[] | OptionGroup[];
	}
>;

export type NumberFilterSchema = FilterSchemaType<'number'>;

export type BooleanFilterSchema = FilterSchemaType<'boolean'> & {
	options?: Option<'true' | 'false' | ''>[];
};

export type DateFilterSchema = FilterSchemaType<'date'>;

export type FilterSchema =
	| StringFilterSchema
	| EnumFilterSchema
	| NumberFilterSchema
	| BooleanFilterSchema
	| DateFilterSchema;

export const BUILT_IN_FILTERS_BY_PARAM = {
	type: {
		label: 'Conversation type',
		param: 'type' as const,
		type: 'enum',
		options: [
			{
				label: 'Test',
				value: 'test',
			},
			{
				label: 'Real',
				value: 'real',
			},
			{
				label: 'Imported',
				value: 'imported',
			},
		],
	},
	reviewStatus: {
		label: 'Conversation',
		param: 'reviewStatus' as const,
		type: 'enum',
		options: [
			{
				label: 'Good conversation',
				value: 'good',
			},
			{
				label: 'Bad conversation',
				value: 'bad',
			},
			{
				label: 'Not review',
				value: 'not-reviewed',
			},
		],
	},
	messageReviewStatus: {
		label: 'Response',
		param: 'messageReviewStatus' as const,
		type: 'enum',
		options: [
			{
				label: 'Reviewed',
				value: 'reviewed',
			},
			{
				label: 'Not reviewed',
				value: 'not-reviewed',
			},
		],
	},
	lastUpdated: {
		label: 'Last updated',
		param: 'lastUpdated' as const,
		type: 'date',
	},
	feedback: {
		label: 'Customer feedback',
		param: 'feedback' as const,
		type: 'enum',
		options: [
			{
				label: 'Good response',
				value: 'good',
			},
			{
				label: 'Bad response',
				value: 'bad',
			},
		],
	},
	tags: {
		label: 'Review tags',
		param: 'tags' as const,
		type: 'enum',
		options: [accuracyTagsGroup, languageTagsGroup, problemTagsGroup],
	},
} satisfies Record<string, FilterSchema>;

export const BUILT_IN_FILTERS = Object.values(BUILT_IN_FILTERS_BY_PARAM);

export type BuiltInFilterParam = (typeof BUILT_IN_FILTERS)[number]['param'];

export const BUILT_IN_FILTERS_MAP = new Map<BuiltInFilterParam, FilterSchema>(
	BUILT_IN_FILTERS.map((filter) => [filter.param, filter]),
);

type MultiSelectValue = string[];

export type DateQueryValue =
	| 'today'
	| 'yesterday'
	| 'past7Days'
	| 'past30Days'
	| 'pastYear'
	| {
			from: string | undefined;
			to: string | undefined;
	  }
	| { from: string | undefined }
	| { to: string | undefined };

export const isValidDateQueryValue = (value: unknown): value is DateQueryValue => {
	if (typeof value === 'string') {
		return ['today', 'yesterday', 'past7Days', 'past30Days'].includes(value);
	}

	if (typeof value === 'object' && value) {
		if ('to' in value && 'from' in value) {
			return (
				['string', 'undefined'].includes(typeof value.to) &&
				['string', 'undefined'].includes(typeof value.from)
			);
		}
		if ('to' in value) {
			return ['string', 'undefined'].includes(typeof value.to);
		}
		if ('from' in value) {
			return ['string', 'undefined'].includes(typeof value.from);
		}
	}

	return false;
};

export type FilterQueryValue = MultiSelectValue | DateQueryValue | string;

export type FiltersQuery = {
	[builtin_param in BuiltInFilterParam]?: FilterQueryValue | undefined;
};

const BUILT_IN_FILTER_QUERY_PARAMS = BUILT_IN_FILTERS.map(({ param }) => param);

export const isBuiltInFilterQueryParam = (param: unknown): param is BuiltInFilterParam => {
	return BUILT_IN_FILTER_QUERY_PARAMS.some((x) => x === param);
};

export const isFilterField = (param: unknown): param is keyof FiltersQuery => {
	return isBuiltInFilterQueryParam(param);
};

const QUERY_STRING_PARSERS: Record<FilterType, (value: string) => unknown> = {
	string: (value) => value,
	number: (value) => (isNaN(Number(value)) ? undefined : Number(value)),
	boolean: (value) => (['true', 'false'].some((v) => v === value) ? value : undefined),
	enum: (value) => value.split(','),
	date: (value) => {
		const betweenMatch = value.match(
			/^between\.(\d{4}-\d{2}-\d{2}(?:T.*Z)?)?\.(\d{4}-\d{2}-\d{2}(?:T.*Z)?)?$/,
		);

		if (betweenMatch) {
			const [, a, b] = betweenMatch ?? [];
			const from = Boolean(a) ? a : undefined;
			const to = Boolean(b) ? b : undefined;

			return {
				from,
				to,
			};
		}

		const fromToMatch = value.match(/^(from|to)\.(\d{4}-\d{2}-\d{2}(?:T.*Z)?)$/);

		if (fromToMatch) {
			const [, operation, date] = fromToMatch ?? [];

			return operation === 'from'
				? {
						from: date,
					}
				: { to: date };
		}

		if (isValidDateQueryValue(value)) {
			return value;
		}
	},
};

type Entry = [string, string];
type Serialiser = (value: FilterQueryValue) => Entry[];

const startOfDate = (date: Date) => {
	const newDate = new Date(date);
	newDate.setHours(0, 0, 0, 0);
	return newDate;
};

const normaliseDateString = (date: string, endOfDate?: boolean) => {
	const isDateOnlyString = /^\d{4}-\d{2}-\d{2}$/.test(date);

	if (!isDateOnlyString) {
		return new Date(date);
	}

	const d = new Date(date);

	if (!endOfDate) {
		d.setHours(0, 0, 0, 0);
	} else {
		d.setHours(23, 59, 59, 999);
	}

	return d;
};

export const BUILT_IN_FILTERS_RESOURCE_QUERY_SERIALISERS: Partial<
	Record<BuiltInFilterParam, Serialiser>
> = {
	type: (value) => [['conversationType', String(value)]],
	lastUpdated: (value) => {
		if (typeof value === 'string') {
			switch (value) {
				case 'today':
					return [['updatedAfter', startOfDate(new Date()).toISOString()]];

				case 'yesterday':
					return [
						[
							'updatedAfter',
							startOfDate(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString(),
						],
						[
							'updatedBefore',
							startOfDate(new Date(new Date().setDate(new Date().getDate() - 0))).toISOString(),
						],
					];

				case 'past7Days':
					return [
						[
							'updatedAfter',
							startOfDate(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString(),
						],
					];

				case 'past30Days':
					return [
						[
							'updatedAfter',
							startOfDate(new Date(new Date().setDate(new Date().getDate() - 30))).toISOString(),
						],
					];
			}
		}

		if (typeof value === 'object') {
			if ('from' in value && 'to' in value && value.from && value.to) {
				return [
					['updatedAfter', normaliseDateString(value.from).toISOString()],
					['updatedBefore', normaliseDateString(value.to, true).toISOString()],
				];
			}

			if ('from' in value && value.from) {
				return [['updatedAfter', normaliseDateString(value.from).toISOString()]];
			}

			if ('to' in value && value.to) {
				return [['updatedBefore', normaliseDateString(value.to).toISOString()]];
			}
		}

		return [];
	},
	feedback: (value) => [['customerFeedback', String(value)]],
	reviewStatus: (value) => [['conversationReviewStatus', String(value)]],
	messageReviewStatus: (value) => [['messageReviewStatus', String(value)]],
	tags: (value) => [['annotatedLabels', String(value)]],
};

// v1 updatedBefore, v2 lastMessageTimestampBefore
// v1 updatedAfter, v2 lastMessageTimestampAfter
export const V2_BUILT_IN_FILTERS_RESOURCE_QUERY_DESERIALISERS: Partial<
	Record<BuiltInFilterParam, Serialiser>
> = {
	...BUILT_IN_FILTERS_RESOURCE_QUERY_SERIALISERS,
	lastUpdated: (value) => {
		if (typeof value === 'string') {
			switch (value) {
				case 'today':
					return [['lastMessageTimestampAfter', startOfDate(new Date()).toISOString()]];

				case 'yesterday':
					return [
						[
							'lastMessageTimestampAfter',
							startOfDate(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString(),
						],
						[
							'lastMessageTimestampBefore',
							startOfDate(new Date(new Date().setDate(new Date().getDate() - 0))).toISOString(),
						],
					];

				case 'past7Days':
					return [
						[
							'lastMessageTimestampAfter',
							startOfDate(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString(),
						],
					];

				case 'past30Days':
					return [
						[
							'lastMessageTimestampAfter',
							startOfDate(new Date(new Date().setDate(new Date().getDate() - 30))).toISOString(),
						],
					];
			}
		}

		if (typeof value === 'object') {
			if ('from' in value && 'to' in value && value.from && value.to) {
				return [
					['lastMessageTimestampAfter', normaliseDateString(value.from).toISOString()],
					['lastMessageTimestampBefore', normaliseDateString(value.to, true).toISOString()],
				];
			}

			if ('from' in value && value.from) {
				return [['lastMessageTimestampAfter', normaliseDateString(value.from).toISOString()]];
			}

			if ('to' in value && value.to) {
				return [['lastMessageTimestampBefore', normaliseDateString(value.to).toISOString()]];
			}
		}

		return [];
	},
};

/**
 * Given a parsed/normalised filters query, serialise it to a URLSearchParams object
 * to persist the state in the URL
 */
export const serialiseFiltersQueryToSearchParams = memoizeOne((query: FiltersQuery) => {
	return Object.entries(query).reduce((searchParams, [param, value]) => {
		const filters: Map<string, FilterSchema> = BUILT_IN_FILTERS_MAP;
		const filter = filters.get(param);
		if (!filter || typeof value === 'undefined') {
			return searchParams;
		}

		const serialisers: Record<string, Serialiser> = fg('csm-conversation-review-v2-apis')
			? V2_BUILT_IN_FILTERS_RESOURCE_QUERY_DESERIALISERS
			: BUILT_IN_FILTERS_RESOURCE_QUERY_SERIALISERS;
		const serialiser = serialisers[filter.param];

		if (filter.param in serialisers) {
			const params = serialiser(value);

			params.forEach(([key, value]) => {
				searchParams.append(key, value);
			});

			return searchParams;
		}

		return searchParams;
	}, new URLSearchParams());
});

/**
 * Given raw query parameters returned from the router, parse them into a
 * normalised set of filters/query for only the supported filters
 */
export const getFiltersQuery = memoizeOne((query: Query): FiltersQuery => {
	const filters: Map<string, FilterSchema> = BUILT_IN_FILTERS_MAP;

	return Object.entries(query).reduce<FiltersQuery>((acc, [param, value]) => {
		if (!isFilterField(param)) {
			return acc;
		}

		const filter = filters.get(param);
		if (!filter) {
			return acc;
		}

		try {
			const parsedValue = QUERY_STRING_PARSERS[filter.type](value);

			return {
				...acc,
				[param]: parsedValue,
			};
		} catch (err) {
			return acc;
		}
	}, {});
});
