import { useCallback, useContext, useMemo } from 'react';

import { BFFContext } from '../context';

export type CacheItem = {
	cloudId: string;
	avatar: string;
	org?: {
		id: string;
		displayName: string;
	};
	url: string;
	displayName: string;
	availableProducts: [
		{
			productType: string;
			companyHubName?: string;
			autoProvisionDate?: string;
			url?: string;
			endUserActivationDate?: string;
			isCompanyHubPublishedAndPremium?: boolean;
		},
	];
};

export function useCache() {
	const {
		value: { consumer, cache, cacheKey },
		setValue,
	} = useContext(BFFContext);

	if (!consumer) {
		throw new Error('BFFContext Consumer is not set');
	}

	const items: CacheItem[] = useMemo(() => {
		return cache[cacheKey] ?? [];
	}, [cache, cacheKey]);

	const updateCacheKey = useCallback(
		(updatedCacheKey: string) => {
			setValue((prev) => ({
				...prev,
				cacheKey: `${consumer}-${updatedCacheKey}`,
			}));
		},
		[setValue, consumer],
	);

	const addItemsToCache = useCallback(
		<T extends CacheItem>(items: T[], compareKey: keyof CacheItem = 'cloudId') => {
			setValue((prev) => {
				const itemData: T[] = prev.cache[cacheKey] ?? [];

				// Overwrite values with matched key if a new value is present
				// This shouldn't be necessary, but it helps act as a safeguard
				// for duplication.
				for (let item of items) {
					const replacedItemIndex = itemData.findIndex((i) => i[compareKey] === item[compareKey]);

					if (replacedItemIndex !== -1) {
						itemData[replacedItemIndex] = item;
					} else {
						itemData.push(item);
					}
				}

				return {
					...prev,
					cache: {
						...prev.cache,
						[cacheKey]: itemData,
					},
				};
			});
		},
		[cacheKey, setValue],
	);

	const clearCache = useCallback(() => {
		setValue((prev) => ({
			...prev,
			cache: {
				...prev.cache,
				[cacheKey]: [],
			},
		}));
	}, [cacheKey, setValue]);

	return {
		addItemsToCache,
		clearCache,
		updateCacheKey,
		cacheKey,
		items,
	};
}
