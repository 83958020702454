import { SUPPORTED_MODULES } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import {
	toEventId,
	type ModuleType,
	type EventId,
} from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { ForgeUIType } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type {
	EntryPoint,
	ExtensionPointModule,
} from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import { MODULE_TYPE_EXTENSION, PACKAGE_NAME_GASV3 } from '../../constants.tsx';

type ModuleParams = {
	module: ExtensionPointModule;
	moduleType?: ModuleType;
	entryPoint?: EntryPoint;
};

type BrowserMetricsModuleParams = {
	module: ExtensionPointModule;
	entryPoint?: EntryPoint;
	forgeUiType: ForgeUIType;
};

const capitalizeFirstLetter = (str?: string) =>
	(str != null && str.charAt(0).toUpperCase() + str.slice(1)) || '';

const prepareModuleMode = (entryPoint?: EntryPoint): string =>
	`${capitalizeFirstLetter(entryPoint)}`;

export const normalizeModuleName = (moduleName: ExtensionPointModule) =>
	moduleName.replace(/^jira:/, '');

const prepareModuleName = (moduleName: ExtensionPointModule) =>
	SUPPORTED_MODULES.includes(moduleName) ? normalizeModuleName(moduleName) : 'unknown';

export const getEventId = ({
	module,
	moduleType = MODULE_TYPE_EXTENSION,
	entryPoint,
}: ModuleParams): EventId => {
	const eventNamePostfix = moduleType === 'trigger' ? 'Trigger' : '';
	// we use view entry point for custom field and custom field type but we don't want to update all the event names
	if (
		entryPoint === 'view' &&
		(module === 'jira:customField' || module === 'jira:customFieldType')
	) {
		return toEventId(`${prepareModuleName(module)}${eventNamePostfix}`);
	}
	return toEventId(
		`${prepareModuleName(module)}${prepareModuleMode(entryPoint)}${eventNamePostfix}`,
	);
};
export const getEventIdOld = ({
	module,
	moduleType = MODULE_TYPE_EXTENSION,
	entryPoint,
}: ModuleParams): EventId => {
	const eventNamePostfix = moduleType === 'trigger' ? 'Trigger' : '';
	return toEventId(
		`${prepareModuleName(module)}${prepareModuleMode(entryPoint)}${eventNamePostfix}`,
	);
};

export const getBrowserMetricsEventId = ({
	module,
	entryPoint,
	forgeUiType,
}: BrowserMetricsModuleParams): EventId => {
	// we use view entry point for custom field and custom field type but we don't want to update all the event names
	if (
		entryPoint === 'view' &&
		(module === 'jira:customField' || module === 'jira:customFieldType')
	) {
		return toEventId(`${prepareModuleName(module)}-${forgeUiType}`);
	}
	return toEventId(`${prepareModuleName(module)}${prepareModuleMode(entryPoint)}-${forgeUiType}`);
};

export const getBrowserMetricsEventIdOld = ({
	module,
	entryPoint,
	forgeUiType,
}: BrowserMetricsModuleParams): EventId => {
	return toEventId(`${prepareModuleName(module)}${prepareModuleMode(entryPoint)}-${forgeUiType}`);
};

export const getEventActionName = (eventId: EventId, action: string) =>
	`${PACKAGE_NAME_GASV3}.${eventId} ${action}`;
