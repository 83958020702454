import React from 'react';

import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';

const DEFAULT_MENU_SKELETON_HEIGHT = 32
const DEFAULT_AVATAR_HEIGHT = 20;
const DEFAULT_CONTENT_HEIGHT = 12;
const DEFAULT_BORDER_RADIUS = 4;

type MenuSkeletonItemProps = {
    skeletonHeight?: number;
    avatarHeight?: number;
    contentHeight?: number;
}

const menuSkeletonItemWrapperStyle =  xcss({
    height: `${DEFAULT_MENU_SKELETON_HEIGHT}px`,
    width: '100%',
    paddingTop: 'space.0',
    paddingBottom: 'space.0',
    paddingLeft: 'space.050',
    paddingRight: 'space.050',
})

const menuSkeletonItemAvatarStyle = xcss({
    paddingLeft: 'space.025'
})

export const MenuSkeletonItem = ({ avatarHeight =  DEFAULT_AVATAR_HEIGHT, contentHeight = DEFAULT_CONTENT_HEIGHT}: MenuSkeletonItemProps) => {
    return (
        <Stack xcss={menuSkeletonItemWrapperStyle} alignBlock="center">
            <Flex alignItems='center' gap='space.050'>
                <Box xcss={menuSkeletonItemAvatarStyle}><Skeleton width={avatarHeight} height={avatarHeight} borderRadius={DEFAULT_BORDER_RADIUS}/></Box>
                <Skeleton width="100%" height={contentHeight} borderRadius="0" />
            </Flex>
        </Stack>
        
    )
}