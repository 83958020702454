import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { requestTypeTableEntrypoint } from '@atlassian/jira-request-types-table/src/ui/table/entrypoint.tsx';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';

export const TicketTypesConfigEntrypoint = createEntryPoint({
	root: JSResourceForUserVisible(
		() =>
			import(
				/* webpackChunkName: "async-project-settings-service-desk-ticket-types-config-entrypoint", jiraSpaEntry: "async-project-settings-service-desk-ticket-types-config-entrypoint" */
				'./ui/index-new'
			),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => {
		const cloudId = tenantContext.cloudId;
		const projectKey = context.match.params.projectKey;

		if (!projectKey) {
			throw new Error('Project key is required');
		}

		return {
			entryPoints: {
				requestTypeTableEntrypoint: {
					entryPoint: requestTypeTableEntrypoint,
					entryPointParams: {
						projectKey,
						cloudId,
					},
				},
			},
		};
	},
});
