import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getQueuesResources,
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
	getQueueViewConfigResource,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskQueuesViewsConfigRoute } from '@atlassian/jira-router-routes-servicedesk-queues-routes/src/servicedeskQueuesViewsConfigRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import { serviceDeskQueuesConfigPageEntryPoint } from './ui/queues-config-page-entry-point/index.tsx';
import {
	ServicedeskQueuesViewsConfig,
	LazyServicedeskQueuesViewsConfig,
} from './ui/views-config/index.tsx';

// TODO: Remove the `forPaint` and `component` properties when jsm_views_inside_queues_-_main_flag is cleaned up
export const servicedeskQueuesViewsConfigRouteEntry = createEntry(
	servicedeskQueuesViewsConfigRoute,
	() => ({
		group: 'servicedesk',
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getQueuesResources(),
			getConsolidationStateResource(),
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			...getQueueViewConfigResource(),
			getEditModelResource(),
			projectContextResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesViewsConfig],
		ufoName: 'service-management.views.config',
		component: componentWithCondition(
			() => fg('jsm_views_inside_queues_-_main_flag'),
			ServicedeskQueuesViewsConfig,
			ErrorPagesNotFound,
		),
		pageId: ({ query, match }) =>
			fg('jsm_views_inside_queues_-_main_flag')
				? `spa-apps/servicedesk/queues/views-config/${query.config}`
				: `spa-apps/servicedesk/queues/edit/${(match.params && match.params.queueId) || ''}`,
		entryPoint() {
			return fg('jsm_views_inside_queues_-_main_flag')
				? serviceDeskQueuesConfigPageEntryPoint
				: undefined;
		},
	}),
);
