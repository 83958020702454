import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import {
	MenuList,
	MenuListItem,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import {
	ENTITY_ID,
	MENU_ID_PROJECTS_VIEW_ALL_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';

import type {
	Nav4ProjectsSectionsWithDisplaySettings$data,
	Nav4ProjectsSectionsWithDisplaySettings$key,
} from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsSectionsWithDisplaySettings.graphql';

import {
	ViewOptions,
	DefaultViewOption,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/change-view-menu-item/view-options/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import type { DisplaySettingsPropertyKeysType } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/display-settings-provider/types.tsx';
import type { ViewOptionsType } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/change-view-menu-item/view-options/types.tsx';
import { DisplaySettingsPropertyKeys } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/display-settings-provider/constants.tsx';
import messages from './messages.tsx';
import type { FavoriteItemsType, RecentItemsType } from './types.tsx';

const VIEW_ALL_STARRED_HREF = '/jira/your-work?starred';

export type NavigationCustomisationNodes = NonNullable<
	NonNullable<
		Nav4ProjectsSectionsWithDisplaySettings$data['settings_navigationCustomisation']
	>['properties']
>['nodes'];

const isDisplaySettingsKey = (value: string): value is keyof typeof DisplaySettingsPropertyKeys => {
	const keys: String[] = Object.values(DisplaySettingsPropertyKeys);
	return keys.includes(value);
};

const getNavigationCustomisation = (
	nodes: NavigationCustomisationNodes,
	viewOption: DisplaySettingsPropertyKeysType,
	defaultValue: ViewOptionsType,
) => {
	return (
		nodes?.find(
			(node) =>
				node?.key &&
				isDisplaySettingsKey(node.key) &&
				DisplaySettingsPropertyKeys[node.key] === viewOption,
		)?.value || defaultValue
	);
};

export const Nav4ProjectsSectionsWithDisplaySettings = ({
	favoriteItems,
	recentItems,
	queryRef,
}: {
	favoriteItems: FavoriteItemsType;
	recentItems: RecentItemsType;
	queryRef: Nav4ProjectsSectionsWithDisplaySettings$key;
}) => {
	const data = useFragment<Nav4ProjectsSectionsWithDisplaySettings$key>(
		graphql`
			fragment Nav4ProjectsSectionsWithDisplaySettings on Query
			@argumentDefinitions(entityAri: { type: "ID!" }, ownerAri: { type: "ID!" }) {
				settings_navigationCustomisation(entityAri: $entityAri, ownerAri: $ownerAri) {
					properties {
						nodes {
							key
							value
						}
					}
				}
			}
		`,
		queryRef,
	);

	const selectedViewOption = getNavigationCustomisation(
		data.settings_navigationCustomisation?.properties?.nodes,
		DisplaySettingsPropertyKeys.VIEW_OPTION,
		DefaultViewOption,
	);

	const { isSelectedPath } = useSidebarNav4();

	const isStarredViewOptionSelected = selectedViewOption === ViewOptions.STARRED;
	const isStarredAndRecentViewOptionSelected =
		selectedViewOption === ViewOptions.STARRED_AND_RECENT;

	// If the current project is not starred, display it under the Recent section.
	// Once navigated away from the current non-starred project, it should disappear from the sidebar,
	// along with the Recent section
	const currentRecentItem = recentItems.list.find(
		(item) => item.key && isSelectedPath(ENTITY_ID.PROJECT(item.key)),
	);

	const displayedRecentItems = isStarredViewOptionSelected
		? {
				list: currentRecentItem ? [currentRecentItem] : [],
				hasItems: Boolean(currentRecentItem),
			}
		: recentItems;

	const shouldShowStarredSection =
		isStarredAndRecentViewOptionSelected || isStarredViewOptionSelected;

	const shouldShowRecentSection =
		isStarredAndRecentViewOptionSelected || displayedRecentItems.hasItems;

	return (
		<>
			{shouldShowStarredSection && <RenderStarredSection favoriteItems={favoriteItems} />}
			{shouldShowRecentSection && <RenderRecentSection recentItems={displayedRecentItems} />}
		</>
	);
};

const RenderStarredSection = ({ favoriteItems }: { favoriteItems: FavoriteItemsType }) => {
	const { formatMessage } = useIntl();

	return (
		favoriteItems.hasItems && (
			<>
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{formatMessage(messages.starred)}</MenuSectionHeading>
						<MenuList>{favoriteItems.list}</MenuList>
					</MenuSection>
				</MenuListItem>
				{favoriteItems.hasNextPage && (
					<Nav4MenuLinkItem href={VIEW_ALL_STARRED_HREF} menuId={MENU_ID_PROJECTS_VIEW_ALL_STARRED}>
						{formatMessage(messages.viewAllStarred)}
					</Nav4MenuLinkItem>
				)}
			</>
		)
	);
};

const RenderRecentSection = ({ recentItems }: { recentItems: RecentItemsType }) => {
	const { formatMessage } = useIntl();

	return (
		recentItems.hasItems && (
			<MenuListItem>
				<MenuSection>
					<MenuSectionHeading>{formatMessage(messages.recent)}</MenuSectionHeading>
					<MenuList>{recentItems.list}</MenuList>
				</MenuSection>
			</MenuListItem>
		)
	);
};
