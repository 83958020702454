import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getQueuesResources,
	getKnowledgeBaseResources,
	getInsightResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskIssueRoute } from '@atlassian/jira-router-routes-servicedesk-queues-routes/src/servicedeskIssueRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { aiContextPanelResource } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import ServiceDeskQueuesIssueViewRedirect, {
	LazyServicedeskQueuesIssueViewRedirect,
} from './ui/queues-issue-redirect/index.tsx';

export const servicedeskIssueRouteEntry = createEntry(servicedeskIssueRoute, () => ({
	group: 'servicedesk',
	component: ServiceDeskQueuesIssueViewRedirect,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getQueuesResources(),
		...getKnowledgeBaseResources(),
		...getInsightResources(),
		getConsolidationStateResource(),
		resourceWithCondition2(() => fg('rrr_for_context_panel_data'), aiContextPanelResource),
	],
	forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesIssueViewRedirect],
}));
