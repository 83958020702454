export const BOARD = 'board';
export const APP = 'app';
export const FILTER = 'filter';
export const DEFAULT_FILTER = 'defaultFilter';
export const DASHBOARD = 'dashboard';
export const QUEUE = 'queue';
export const PROJECT = 'project';
export const PLAN = 'plan';
export const APP_SUBPAGE = 'appSubpage';
export const ASSET = 'asset';
export const OPERATION = 'operation';
export const TRANSLATION_GROUP = 'translationGroup';
export const ROADMAP = 'roadmap';
export const PROJECT_RECOMMENDATION = 'projectRecommendation';
export const OVERVIEW = 'overview';
export const CHANGE_VIEW = 'changeView';

// TODO most of or all types in this folder are unused. Either use or remove them.
export type JiraItemType =
	| typeof BOARD
	| typeof FILTER
	| typeof DEFAULT_FILTER
	| typeof DASHBOARD
	| typeof QUEUE
	| typeof PROJECT
	| typeof PLAN
	| typeof APP
	| typeof APP_SUBPAGE
	| typeof ASSET
	| typeof OPERATION
	| typeof TRANSLATION_GROUP
	| typeof ROADMAP
	| typeof PROJECT_RECOMMENDATION
	| typeof OVERVIEW
	| typeof CHANGE_VIEW;
