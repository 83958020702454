import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncDirectoryNav } from '@atlassian/jira-horizontal-nav-jsm-directory/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { servicedeskOrganizationsRoute } from '@atlassian/jira-router-routes-servicedesk-organizations-routes/src/servicedeskOrganizationsRoute.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { ServiceDeskOrganizations, LazyServicedeskOrganizations } from './ui/index.tsx';

export const servicedeskOrganizationsRouteEntry = createEntry(
	servicedeskOrganizationsRoute,
	() => ({
		group: 'servicedesk',
		layout: serviceProjectLayout,
		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],
		component: ServiceDeskOrganizations,
		forPaint: [LazyAtlassianNavigation, LazyServicedeskOrganizations],
		perfMetricKey: 'jsm-organizations-component-load',
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncDirectoryNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorizontalOnNav4: true,
		},
		meta: {
			reporting: {
				id: APP_NAMES.ORGANIZATIONS,
				packageName: toPackageName(APP_NAMES.ORGANIZATIONS),
				teamName: 'boysenberry',
			},
		},
	}),
);
