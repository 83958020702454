import { useMemo } from 'react';
import OriginTracer from '@atlassiansox/origin-tracing';

/**
 * Generic function to use a traced URL to ensure that we have the correct origin added to it
 */
export function useTracedUrl(untracedUrl?: string | null) {
	const origin = useMemo(() => new OriginTracer({ product: 'jira' }), []);

	const url = useMemo(
		() => (untracedUrl ? origin.addToUrl(untracedUrl) : undefined),
		[untracedUrl, origin],
	);

	return {
		url,
		origin,
	};
}
