import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * This is copied from jira/src/packages/issue-navigator/rollout/src/is-refactor-nin-to-view-schema-enabled.tsx
 * to reduce the imports this package has to do.
 */
const isRefactorNinToViewSchemaEnabled = (): boolean =>
	// eslint-disable-next-line jira/ff/no-preconditioning
	expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false) &&
	fg('refactor_nin_to_jira_view_schema');

export default {
	get(): boolean {
		return isRefactorNinToViewSchemaEnabled();
	},
};
