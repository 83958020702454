import { fg } from '@atlassian/jira-feature-gating';

export default {
	get(): boolean {
		return (
			// eslint-disable-next-line jira/ff/no-preconditioning
			fg('enable_redaction_support_in_jira_issue_view') &&
			fg('jiv-replace-configuration-button-in-context-panel') &&
			fg('relay-migration-issue-header-and-parent')
		);
	},
};
