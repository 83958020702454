import uniq from 'lodash/uniq';
import { v4 as uuid } from 'uuid';
import { setInteractionError } from '@atlaskit/react-ufo/set-interaction-error';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { isMacOs } from '@atlassian/jira-common-components-keyboard-shortcuts-dialog/src/utils.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { TRACK_EVENT_TYPE, UI_EVENT_TYPE } from '../../common/constants.tsx';
import type {
	AnalyticsHandler,
	AnalyticsErrorHandler,
	AnalyticsAttributes,
} from '../../common/types/analytics/index.tsx';
import {
	type CommandInternal,
	type Command,
	type CommandSections,
	type ChildResult,
	CommandActionType,
	type DefaultFallback,
} from '../../common/types/commands/index.tsx';
import type {
	ActiveRegistry,
	CommandPaletteRegistry,
	State,
	ActionAnalytics,
} from '../../common/types/index.tsx';
import {
	getAnalyticsAttributesFromState,
	hasChildren,
	getCommandAction,
} from '../../common/utils.tsx';
import { getActiveCommandList } from '../../common/utils/get-active-command-list/index.tsx';
import { isSearchableChildrenAction } from '../../common/utils/is-searchable-children-action/index.tsx';
import { setEventAttribute } from './utils.tsx';

const rootCommands = {
	parentCommand: [],
	expandedCommand: undefined,
	childCommands: undefined,
	childResult: undefined,
};

const open =
	(): Action<State> =>
	({ setState, getState }) => {
		if (getState().isDisabled) {
			return;
		}
		setState({ commandPaletteSessionId: uuid(), isOpen: true, activeIndex: 0, search: '' });
	};

const close =
	(): Action<State> =>
	({ setState }) => {
		setState({ isOpen: false, commandPaletteSessionId: '', search: '', ...rootCommands });
	};

const toggle =
	(): Action<State> =>
	({ setState, getState }) => {
		const isOpen = !getState().isOpen;
		setState({
			isOpen,
			activeIndex: 0,
			search: '',
			commandPaletteSessionId: isOpen ? uuid() : '',
			...(!isOpen ? rootCommands : {}),
		});
	};

const addCommands =
	(commands: Command[]): Action<State> =>
	({ setState, getState }) => {
		const currentState = getState();
		const newCommands = currentState.commands.concat(commands);
		const newState: Partial<State> = { commands: newCommands };

		if (currentState.expandedCommand) {
			const matchedExpandedCommand = commands.filter(
				(command) => command.id === currentState.expandedCommand?.id,
			);
			if (matchedExpandedCommand.length === 1) {
				const [updatedExpandedCommand] = matchedExpandedCommand;
				newState.expandedCommand = updatedExpandedCommand;
			}
		}

		setState(newState);
	};

const removeCommands =
	(commands: Command[]): Action<State> =>
	({ setState, getState }) => {
		const newCommands = getState().commands.filter((command) => !commands.includes(command));
		setState({
			commands: newCommands,
		});
	};

const removeCommandsById =
	(commandIds: string[]): Action<State> =>
	({ setState, getState }) => {
		const newCommands = getState().commands.filter((command) => !commandIds.includes(command.id));
		setState({
			commands: newCommands,
		});
	};

const handleSearch =
	(search: string): Action<State> =>
	({ setState }) => {
		setState({ search, activeIndex: 0 });
	};

const forceSearchValue =
	(search: string): Action<State> =>
	({ setState }) => {
		setState({ search, shouldForceSearchValue: true });
	};

const resetShouldForceSearchValue =
	(): Action<State> =>
	({ setState }) => {
		setState({ shouldForceSearchValue: false });
	};

const goBackLevel =
	(goToRoot?: boolean, analytics?: ActionAnalytics): Action<State> =>
	({ setState, getState }) => {
		const currentState = getState();

		if (!currentState.expandedCommand) return;

		const newState: Partial<State> = {
			search: '',
			activeIndex: 0,
			childResult: undefined,
			forceSearchFocus: true,
		};

		if (currentState.parentCommand.length && !goToRoot) {
			newState.expandedCommand = currentState.parentCommand[currentState.parentCommand.length - 1];
			newState.parentCommand = currentState.parentCommand.slice(
				0,
				currentState.parentCommand.length - 1,
			);

			if (
				newState.expandedCommand?.primaryAction?.type === CommandActionType.COMMAND_LIST &&
				newState.expandedCommand?.primaryAction?.commands
			) {
				newState.childResult = {
					commands: newState.expandedCommand?.primaryAction?.commands,
					sections: newState.expandedCommand?.primaryAction?.sections,
				};
			}
		} else {
			newState.loadingList = false;
			newState.parentCommand = [];
			newState.expandedCommand = undefined;
			newState.childResult = undefined;
		}

		if (currentState?.onAnalytics && analytics?.createAnalyticsEvent) {
			currentState.onAnalytics(
				analytics.createAnalyticsEvent({
					action: 'navigation',
					actionSubject: 'commandPalette',
				}),
				TRACK_EVENT_TYPE,

				{
					attributes: {
						cpMenu: currentState.expandedCommand?.id || 'rootMenu',
						searchSessionId: currentState.commandPaletteSessionId,
						action: goToRoot ? 'backToRoot' : 'backToParent',
						actionCategory: 'back',
						method: analytics?.method,
					},
				},
			);
		}
		setState(newState);
	};

// Once search input is focused, forceSearchFocus can go back to false
const forceFocusSearch =
	(forceSearchFocus = false): Action<State> =>
	({ setState }) => {
		setState({ forceSearchFocus });
	};

const setActiveIndex =
	(index: number): Action<State> =>
	({ setState, getState }) => {
		const { isSearchFieldFocused } = getState();
		setState({
			activeIndex: index,
			...(!isSearchFieldFocused ? { forceSearchFocus: true } : {}),
		});
	};

const getAnalyticsAttributes =
	(
		command: CommandInternal,
		method?: 'mouse' | 'keyboard' | 'mode',
		wasSecondaryTriggered = false,
		attributes: AnalyticsAttributes = {},
	): Action<State, void, ReturnType<typeof getAnalyticsAttributesFromState>> =>
	({ getState }) =>
		getAnalyticsAttributesFromState(getState(), command, method, wasSecondaryTriggered, attributes);

const performDialogCloseCommand =
	(): Action<State> =>
	({ setState, getState }) => {
		// Timeout applied to ensure that this happens after react-focus-lock is shut down
		// react-focus-lock also has a timeout before it stops listening for focus events
		// tl;dr If you don't add this timeout, cypress gets sad (and probably some actual users too but I've never repro'd it manually)
		setTimeout(() => {
			const { onClosePendingCommandAction, onAnalytics } = getState();
			if (onClosePendingCommandAction !== undefined) {
				try {
					traceUFOPress('command-palette-executed');
					onClosePendingCommandAction.perform();
					if (onAnalytics && onClosePendingCommandAction?.createAnalyticsEvent) {
						const performAnalyticsEvent = onClosePendingCommandAction?.createAnalyticsEvent({
							action: 'taskSucceeded',
							actionSubject: 'commandPalette',
						});

						onAnalytics(performAnalyticsEvent, TRACK_EVENT_TYPE, {
							attributes: onClosePendingCommandAction?.analyticsArgs,
						});
					}
				} catch (err) {
					const error = err instanceof Error ? err : new Error('Command palette perform error');
					fireErrorAnalytics({
						error,
						meta: {
							id: 'compal.performDialogCloseCommand',
							packageName: 'jiraCommandPalette',
							teamName:
								typeof onClosePendingCommandAction?.analyticsArgs?.teamName === 'string'
									? onClosePendingCommandAction?.analyticsArgs.teamName
									: 'deliveroo',
						},
						attributes: onClosePendingCommandAction.analyticsArgs,
					});
					setInteractionError('command-palette-executed', {
						errorMessage: error.message,
						name: 'performDialogCloseCommandPerformError',
					});
				}
				setState({ onClosePendingCommandAction: undefined });
			}
		}, 0);
	};

const executeCommand =
	(
		command: CommandInternal,
		analytics?: ActionAnalytics,
		wasSecondaryTriggered = false,
	): Action<State> =>
	({ setState, getState }) => {
		const currentState = getState();
		const newState: Partial<State> = {
			...(!getActiveCommandList(command, wasSecondaryTriggered)?.keepSearch && {
				search: '',
			}),
			activeIndex: 0,
		};

		const analyticsAttributes: AnalyticsAttributes = getAnalyticsAttributesFromState(
			currentState,
			command,
			analytics?.method || 'undefined',
			wasSecondaryTriggered,
			analytics?.attributes,
		);
		if (fg('one_event_rules_them_all_fg')) {
			setEventAttribute(analyticsAttributes);
		}

		const currentCommandAction = getCommandAction(command, wasSecondaryTriggered);
		if (!currentCommandAction) return;
		if (currentState?.onAnalytics && analytics?.createAnalyticsEvent) {
			const analyticsEvent = analytics.createAnalyticsEvent({
				action: 'navigation',
				actionSubject: 'commandPalette',
			});

			currentState.onAnalytics(analyticsEvent, TRACK_EVENT_TYPE, {
				attributes: analyticsAttributes,
			});
		}

		const shouldLoadChildren = hasChildren({
			...command,
			wasSecondaryTriggered,
		});
		const shouldExecutePerform = currentCommandAction?.type === CommandActionType.PERFORM;

		if (command.mode) {
			newState.shouldForceSearchValue = true;
		}

		if (shouldLoadChildren) {
			traceUFOPress('command-palette-load-child-command-list');
			if (currentState.expandedCommand) {
				newState.parentCommand = [...currentState.parentCommand, currentState.expandedCommand];
			}
			newState.expandedCommand = { ...command, wasSecondaryTriggered };
			newState.childResult = undefined;
			newState.forceSearchFocus = true;

			if (isSearchableChildrenAction(currentCommandAction)) {
				newState.childResult = {
					commands: currentCommandAction.commands,
					sections: currentCommandAction.sections,
				};
				if (command.parentCommands?.length) {
					newState.parentCommand = [...(newState.parentCommand || []), ...command.parentCommands];
				}
			} else {
				newState.childResult = undefined;
			}

			setState(newState);

			if (
				currentCommandAction.type === CommandActionType.COMMAND_LIST &&
				currentCommandAction.onClick
			) {
				currentCommandAction.onClick();
			}
		} else if (shouldExecutePerform) {
			if (!currentCommandAction.preventModalClose) {
				newState.commandPaletteSessionId = '';
				newState.isOpen = false;
				newState.expandedCommand = undefined;
				newState.parentCommand = [];
				newState.childResult = undefined;

				setState(newState);

				if (currentState.onAnalytics && analytics?.createAnalyticsEvent) {
					const closeAnalyticsEvent = analytics.createAnalyticsEvent({
						action: 'closed',
						actionSubject: 'commandPalette',
					});

					currentState.onAnalytics(closeAnalyticsEvent, UI_EVENT_TYPE, {
						attributes: analyticsAttributes,
					});
				}
			}

			if (wasSecondaryTriggered && currentState.onAnalytics && analytics?.createAnalyticsEvent) {
				const closeAnalyticsEvent = analytics.createAnalyticsEvent({
					action: 'secondaryAction',
					actionSubject: 'commandPalette',
				});
				currentState.onAnalytics(closeAnalyticsEvent, TRACK_EVENT_TYPE, {
					attributes: {
						...analyticsAttributes,
						keyPressed: isMacOs() ? 'cmdEnter' : 'ctrlEnter',
						...currentCommandAction.analyticsArgs,
					},
				});
			}

			// If not closing dialog, execute immediately, otherwise cue for after dialog is closed
			if (currentCommandAction.preventModalClose) {
				try {
					traceUFOPress('command-palette-executed');
					currentCommandAction.perform();
					if (currentState.onAnalytics && analytics?.createAnalyticsEvent) {
						const performAnalyticsEvent = analytics.createAnalyticsEvent({
							action: 'taskSucceeded',
							actionSubject: 'commandPalette',
						});

						currentState.onAnalytics(performAnalyticsEvent, TRACK_EVENT_TYPE, {
							attributes: analyticsAttributes,
						});
					}
				} catch (err) {
					const error = err instanceof Error ? err : new Error('Command palette perform error');
					fireErrorAnalytics({
						error,
						meta: {
							id: 'compal.executeCommand',
							packageName: 'jiraCommandPalette',
							teamName:
								typeof analytics?.attributes?.teamName === 'string'
									? analytics?.attributes.teamName
									: 'deliveroo',
						},
						attributes: analytics?.attributes,
					});
					setInteractionError('command-palette-executed', {
						errorMessage: error.message,
						name: 'executeCommandPerformError',
					});
				}
			} else {
				setState({
					onClosePendingCommandAction: {
						...currentCommandAction,
						analyticsArgs: analyticsAttributes,
						createAnalyticsEvent: analytics?.createAnalyticsEvent,
					},
				});
			}
		}
	};

const setChildCommands =
	(childResult: ChildResult): Action<State> =>
	({ setState }) => {
		setState({ childResult });
	};

const setLoadingList =
	(loadingList: boolean): Action<State> =>
	({ setState }) => {
		setState({ loadingList });
	};

const setFocusInput =
	(isSearchFieldFocused: boolean): Action<State> =>
	({ setState }) =>
		setState({ isSearchFieldFocused });

const registerSections =
	(newSections: CommandSections): Action<State> =>
	({ setState, getState }) => {
		const currentState = getState();
		const sections = currentState.sections.slice(0);

		sections.push(newSections);

		setState({ sections });
	};

const unregisterSections =
	(newSections: CommandSections): Action<State> =>
	({ setState, getState }) => {
		const currentState = getState();
		const sections = currentState.sections.filter((section) => section !== newSections);
		setState({ sections });
	};

const markAsLoaded =
	(): Action<State> =>
	({ setState }) => {
		setState({ isLoaded: true });
	};

const setIsDisabled =
	(isDisabled: boolean): Action<State> =>
	({ setState }) => {
		setState({ isDisabled, ...(isDisabled ? { isOpen: false } : {}) });
	};

const setRegistry =
	(registry: CommandPaletteRegistry): Action<State> =>
	({ setState }) => {
		setState({ registry });
	};

const updateActiveRegistry =
	(registryId: string, commands: Command[]): Action<State> =>
	({ setState, getState }) => {
		const currentActiveRegistry = getState().activeRegistry;
		const activeRegistry: ActiveRegistry = {
			commandRegistry: {
				...currentActiveRegistry.commandRegistry,
				...commands.reduce(
					(acc, command) => {
						acc[command.id] = {
							registryId,
						};
						return acc;
					},
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					{} as ActiveRegistry['commandRegistry'],
				),
			},
			registryIds: uniq([...currentActiveRegistry.registryIds, registryId]),
		};
		setState({
			activeRegistry,
		});
	};

const removeFromActiveRegistry =
	(registryId: string, commands: Command[]): Action<State> =>
	({ setState, getState }) => {
		const currentActiveRegistry = getState().activeRegistry;
		const commandRegistry = {
			...currentActiveRegistry.commandRegistry,
		};
		commands.forEach((command) => {
			delete commandRegistry[command.id];
		});

		setState({
			activeRegistry: {
				commandRegistry,
				registryIds: currentActiveRegistry.registryIds.filter((id) => id !== registryId),
			},
		});
	};

const setAnalytics =
	(onAnalytics?: AnalyticsHandler, onErrorAnalytics?: AnalyticsErrorHandler): Action<State> =>
	({ setState }) => {
		setState({ onAnalytics, onErrorAnalytics });
	};

const setFallbackOverride =
	(fallbackOverride?: DefaultFallback): Action<State> =>
	({ setState }) => {
		setState({ fallbackOverride });
	};

const setHasPopupOverlay =
	(hasPopupOverlay: boolean): Action<State> =>
	({ setState }) => {
		setState({ hasPopupOverlay });
	};

export const actions = {
	open,
	toggle,
	close,
	addCommands,
	removeCommands,
	handleSearch,
	forceFocusSearch,
	setActiveIndex,
	executeCommand,
	goBackLevel,
	setChildCommands,
	setLoadingList,
	setFocusInput,
	registerSections,
	unregisterSections,
	markAsLoaded,
	getAnalyticsAttributes,
	setIsDisabled,
	setRegistry,
	updateActiveRegistry,
	removeCommandsById,
	removeFromActiveRegistry,
	performDialogCloseCommand,
	setAnalytics,
	setFallbackOverride,
	setHasPopupOverlay,
	forceSearchValue,
	resetShouldForceSearchValue,
} as const;

export type Actions = typeof actions;
