/* eslint-disable @atlassian/relay/must-use-inline */
/* eslint-disable @atlassian/relay/unused-fields */
/* eslint-disable jira/import-whitelist */
import { fetchQuery, graphql } from 'react-relay';
import type { graphqlContextPanelQuery } from '@atlassian/jira-relay/src/__generated__/graphqlContextPanelQuery.graphql.ts';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import {
	getTraceIds,
	startCapturingTraceIds,
	stopCapturingTraceIds,
} from '@atlassian/relay-traceid';
import { handleGraphQLError, handleGraphQLresponse } from './utils.tsx';

const OPERATION_NAME = 'graphqlContextPanelQuery';

const contextPanelQuery = graphql`
	query graphqlContextPanelQuery($cloudId: ID!, $issueId: String) {
		agentAI_contextPanel(issueId: $issueId, cloudId: $cloudId) {
			__typename
			... on AgentAIContextPanelResponse {
				summary
				nextSteps
				reporterDetails
				suggestedEscalation
				suggestedActions {
					type
					content {
						title
						description
					}
					context {
						fieldId
						suggestion
					}
				}
			}
			... on QueryError {
				message
				extensions {
					statusCode
					errorType
				}
			}
		}
	}
`;

export const getContextPanelSuggestions = async (cloudId: string, issueId: string) => {
	startCapturingTraceIds(OPERATION_NAME);
	const graphQLResponse = await fetchQuery<graphqlContextPanelQuery>(
		getRelayEnvironment(),
		contextPanelQuery,
		{
			cloudId,
			issueId,
		},
	)
		.toPromise()
		.then((data) => {
			const traceIds = getTraceIds(OPERATION_NAME);
			return handleGraphQLresponse(data, traceIds);
		})
		.catch((error) => {
			const traceIds = getTraceIds(OPERATION_NAME);
			return handleGraphQLError(error, traceIds);
		})
		.finally(() => {
			stopCapturingTraceIds(OPERATION_NAME);
		});

	return graphQLResponse;
};
