import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { arjSettingsLoadingLimitsLegacyRedirectRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-routes/src/arjSettingsLoadingLimitsLegacyRedirectRoute.tsx';
import { LazyArjSettingsLoadingLimits } from './ui/index.tsx';
import { redirectTo } from './ui/redirect.tsx';

export const arjSettingsLoadingLimitsLegacyRedirectRouteEntry = createEntry(
	arjSettingsLoadingLimitsLegacyRedirectRoute,
	() => ({
		group: 'software',
		component: redirectTo('/jira/plans/settings/loading-limits'),
		isRedirect: true,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsLoadingLimits],
	}),
);
