// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { updateCollection } from '../update-collection/index.tsx';

export const expandSectionForSelectedCollection =
	(): Action<State, Props> =>
	async ({ dispatch }, { activeCollectionUUID, onSuccess }) => {
		if (activeCollectionUUID) {
			dispatch(updateCollection(activeCollectionUUID, { isExpanded: true }));
			onSuccess?.('expandSectionForSelectedCollection');
		}
	};
