/**
 * @generated SignedSource<<5666ed04e9a2e83caea032b01a644e56>>
 * @relayHash d0b248a628f36db0b34ade6672994ffd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c79b3786f6919fbd23c348dbd30027bec79a9b40316b469c8bbceaf8cf103cc1

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsContentQuery$variables = {
  cloudId: string;
  currentURL?: AGG$URL | null | undefined;
  displaySettingsAPart1Enabled: boolean;
  displaySettingsPostGaEnabled: boolean;
  entityAriProjects: string;
  ownerAri: string;
};
export type Nav4ProjectsContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsContent">;
};
export type Nav4ProjectsContentQuery = {
  response: Nav4ProjectsContentQuery$data;
  variables: Nav4ProjectsContentQuery$variables;
};
({
  "displaySettingsAPart1Enabled": displaySettingsAPart1Enabled_provider,
  "displaySettingsPostGaEnabled": displaySettingsPostGaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcblu6315displaysettingsapart1relayprovider": displaySettingsAPart1Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider": displaySettingsPostGaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider": growthRecommendationsFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjcsprojecttypem3relayprovider": isJcsProjectMenuEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcblu6315displaysettingsapart1relayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjcsprojecttypem3relayprovider: {
    readonly get: () => boolean;
  };
  readonly displaySettingsAPart1Enabled: {
    readonly get: () => boolean;
  };
  readonly displaySettingsPostGaEnabled: {
    readonly get: () => boolean;
  };
});

import displaySettingsAPart1Enabled_provider from '@atlassian/jira-relay-provider/src/blu-6315-display-settings-a-part-1.relayprovider';
import displaySettingsPostGaEnabled_provider from '@atlassian/jira-relay-provider/src/blu-6987-display-settings-post-ga-experiment.relayprovider';
import growthRecommendationsFlag_provider from '@atlassian/jira-relay-provider/src/growth-recommendations.relayprovider';
import isJcsProjectMenuEnabled_provider from '@atlassian/jira-relay-provider/src/jcs-project-type-m3.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentURL"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displaySettingsAPart1Enabled"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displaySettingsPostGaEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityAriProjects"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ownerAri"
},
v6 = {
  "kind": "Variable",
  "name": "currentURL",
  "variableName": "currentURL"
},
v7 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v8 = {
  "kind": "Literal",
  "name": "first",
  "value": 15
},
v9 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v11 = {
  "kind": "ScalarField",
  "name": "id"
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v13 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_PROJECT_CONFIG"
  }
],
v14 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v15 = [
  (v11/*: any*/),
  {
    "alias": "projectKey",
    "kind": "ScalarField",
    "name": "key"
  },
  {
    "kind": "ScalarField",
    "name": "status"
  },
  {
    "kind": "ScalarField",
    "name": "projectType"
  },
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "large"
      }
    ]
  },
  {
    "kind": "ScalarField",
    "name": "name"
  },
  {
    "kind": "ScalarField",
    "name": "projectId"
  },
  {
    "kind": "ScalarField",
    "name": "isFavourite"
  },
  {
    "kind": "LinkedField",
    "name": "defaultNavigationItem",
    "plural": false,
    "selections": [
      (v10/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "kind": "ScalarField",
            "name": "url"
          }
        ],
        "type": "JiraNavigationItem",
        "abstractKey": "__isJiraNavigationItem"
      },
      (v12/*: any*/)
    ]
  },
  {
    "alias": "editProjectConfig",
    "args": (v13/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v14/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "args": (v13/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v14/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "projectStyle"
  },
  {
    "alias": "editPermission",
    "args": (v13/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v14/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "webUrl"
  },
  {
    "kind": "ScalarField",
    "name": "softwareBoardCount"
  }
],
v16 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v17 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v18 = {
  "concreteType": "JiraProjectEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": (v15/*: any*/)
    }
  ]
},
v19 = [
  (v18/*: any*/),
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "Nav4ProjectsContentQuery",
    "selections": [
      {
        "args": [
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Nav4ProjectsContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6315displaysettingsapart1relayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjcsprojecttypem3relayprovider"
      }
    ],
    "kind": "Operation",
    "name": "Nav4ProjectsContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "canCreateProject",
            "args": [
              (v7/*: any*/),
              {
                "kind": "Literal",
                "name": "type",
                "value": "CREATE_PROJECT"
              }
            ],
            "kind": "ScalarField",
            "name": "canPerform"
          },
          {
            "args": [
              (v7/*: any*/)
            ],
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "favouriteProjects",
                "args": [
                  (v7/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "sort": {
                        "order": "DESC"
                      },
                      "type": "PROJECT"
                    }
                  },
                  (v8/*: any*/)
                ],
                "concreteType": "JiraFavouriteConnection",
                "kind": "LinkedField",
                "name": "favourites",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "concreteType": "JiraFavouriteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v15/*: any*/),
                            "type": "JiraProject"
                          },
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v16/*: any*/)
                ]
              },
              {
                "args": [
                  (v7/*: any*/),
                  (v6/*: any*/),
                  (v17/*: any*/)
                ],
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "recentProjects",
                "plural": false,
                "selections": (v19/*: any*/)
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "GrowthRecQuery",
            "kind": "LinkedField",
            "name": "growthRecommendations",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "fields": [
                      {
                        "kind": "Literal",
                        "name": "product",
                        "value": "jira"
                      },
                      {
                        "kind": "Variable",
                        "name": "tenantId",
                        "variableName": "cloudId"
                      },
                      {
                        "kind": "Literal",
                        "name": "useCase",
                        "value": "jiraProjectMenuRecommendations"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "context"
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 2
                  }
                ],
                "kind": "LinkedField",
                "name": "recommendations",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "entityId"
                              }
                            ],
                            "type": "GrowthRecJiraTemplateRecommendation"
                          }
                        ]
                      }
                    ],
                    "type": "GrowthRecRecommendations"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6315displaysettingsapart1relayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "entityAri",
                    "variableName": "entityAriProjects"
                  },
                  {
                    "kind": "Variable",
                    "name": "ownerAri",
                    "variableName": "ownerAri"
                  }
                ],
                "concreteType": "SettingsNavigationCustomisation",
                "kind": "LinkedField",
                "name": "settings_navigationCustomisation",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "SettingsDisplayPropertyConnection",
                    "kind": "LinkedField",
                    "name": "properties",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "SettingsDisplayProperty",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "key"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "value"
                          }
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "concreteType": "JiraProjectsSidebarMenu",
            "kind": "LinkedField",
            "name": "jira_projectsSidebarMenu",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "current",
                "plural": false,
                "selections": (v15/*: any*/)
              },
              {
                "args": [
                  (v8/*: any*/)
                ],
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "favourites",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v18/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": "favourites(first:15)"
              },
              {
                "args": [
                  (v17/*: any*/)
                ],
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "recents",
                "plural": false,
                "selections": (v19/*: any*/),
                "storageKey": "recents(first:20)"
              },
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c79b3786f6919fbd23c348dbd30027bec79a9b40316b469c8bbceaf8cf103cc1",
    "metadata": {},
    "name": "Nav4ProjectsContentQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "displaySettingsAPart1Enabled": displaySettingsAPart1Enabled_provider,
      "displaySettingsPostGaEnabled": displaySettingsPostGaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcblu6315displaysettingsapart1relayprovider": displaySettingsAPart1Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider": displaySettingsPostGaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider": growthRecommendationsFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjcsprojecttypem3relayprovider": isJcsProjectMenuEnabled_provider
    }
  }
};
})();

(node as any).hash = "b0416203ad03d7912efe15fabde20269";

export default node;
