import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen, {
	ForPaintSoftwareProjectSidebar as AsyncForPaintSidebarNextGen,
} from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { issueNavigatorProjectSoftwareRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/issueNavigatorProjectSoftwareRoute.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { NIN_NEW_ISSUE_NAVIGATOR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { getCommonProjectResources } from './common/getCommonProjectResources.tsx';
import { getRouteReporting } from './common/getRouteReporting.tsx';
import { issueNavigatorPageEntryPoint } from './ui/index.tsx';

/**
 * Software Project Issue Navigator Route Entry
 * Handles Software projects.
 */
export const issueNavigatorProjectSoftwareRouteEntry = createEntry(
	issueNavigatorProjectSoftwareRoute,
	() => ({
		group: 'issue-navigator',
		apdexIgnoreParams: ['issueKey'],
		meta: {
			capability: NIN_NEW_ISSUE_NAVIGATOR,
			reporting: getRouteReporting(),
		},
		skeleton: ProjectIssueNavigatorSkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: componentWithFG(
				'make_nav3_sidebar_lazyforpaint',
				AsyncForPaintSidebarNextGen,
				AsyncSidebarNextGen,
			),
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},
		resources: [...getCommonProjectResources()],
		layout: createLayout(softwareProjectLayoutNoOnboarding),
		// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
		// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
		shouldOptOutConcurrentMode:
			fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
		entryPoint: () => issueNavigatorPageEntryPoint,
	}),
);
