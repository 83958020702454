import { downloadResources as customerLogsDownloadResources } from '@atlassian/eoc-customer-logs/resources';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { eocTeamCustomerLogsDownloadRoute } from '@atlassian/jira-router-routes-eoc-team-customer-logs-routes/src/eocTeamCustomerLogsDownloadRoute.tsx';
import {
	CustomerLogsDownloadPage,
	LazyCustomerLogsDownLoad,
} from './ui/spa/eoc/customer-logs/index.tsx';

export const eocTeamCustomerLogsDownloadRouteEntry = createEntry(
	eocTeamCustomerLogsDownloadRoute,
	() => ({
		group: 'eoc',
		component: CustomerLogsDownloadPage,
		layout: globalSettingsLayout,
		resources: [getConsolidationStateResource(), ...customerLogsDownloadResources()],
		forPaint: [LazyCustomerLogsDownLoad],
	}),
);
