import type { EntryPoint, EntryPointComponent } from 'react-relay';
import { topNavigationEntryPoint } from '@atlassian/jira-atlassian-navigation/entrypoint.tsx';
import type { MenuId } from '@atlassian/jira-atlassian-navigation/src/common/types.tsx';
import {
	HORIZONTAL_NAVIGATION,
	MAIN_CONTENT,
	TOP_NAVIGATION,
	RIGHT_SIDEBAR,
	LEFT_SIDEBAR,
} from '@atlassian/jira-entry-point-page-layout-fields/src/index.tsx';
import type { AnyEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type ChromelessPage = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	main: EntryPoint<EntryPointComponent<any, any, any, any>, EntryPointRouteParams>;
};

type Page = ChromelessPage & {
	horizontalNavigation?: AnyEntryPoint;
	horizontalNavigationFallback?: () => JSX.Element;
	/* @deprecated remove topNavigationMenuId when cleaning up getWillShowNav4 */
	topNavigationMenuId?: MenuId;
	leftSidebar?: {
		sidebar: AnyEntryPoint;
		entryPoints?: Record<string, { entryPoint: AnyEntryPoint }>;
		isAppsExpanded?: boolean;
		isFiltersExpanded?: boolean;
		isPlansExpanded?: boolean;
		isProjectsExpanded?: boolean;
		isOperationsExpanded?: boolean;
		isOverviewsExpanded?: boolean;
	};
	rightSidebar?: AnyEntryPoint;
	rightSidebarFallback?: () => JSX.Element;
};

export const createChromelessEntryPoint = (page: ChromelessPage) => ({
	root: page.main.root,
	getPreloadProps: (entryPointParams: EntryPointRouteParams) => ({
		entryPoints: {
			[MAIN_CONTENT]: {
				entryPoint: page.main,
				entryPointParams,
			},
		},
	}),
	toString: () => 'ChromelessEntryPoint',
});

export const createPageEntryPoint = (page: Page) => ({
	root: page.main.root,
	getPreloadProps: (entryPointParams: EntryPointRouteParams) => ({
		entryPoints: {
			[MAIN_CONTENT]: {
				entryPoint: page.main,
				entryPointParams,
			},
			[TOP_NAVIGATION]: {
				entryPoint: topNavigationEntryPoint,
				entryPointParams: { menuId: page.topNavigationMenuId },
			},
			...(page.leftSidebar && fg('blu-5799-jira-software-board-entrypoint-route')
				? {
						[LEFT_SIDEBAR]: {
							entryPoint: page.leftSidebar.sidebar,
							entryPointParams: {
								...entryPointParams,
								entryPoints: {
									...(page.leftSidebar.entryPoints
										? injectEntryPointParams(page.leftSidebar.entryPoints, entryPointParams)
										: undefined),
								},
								isAppsExpanded: page.leftSidebar.isAppsExpanded ?? false,
								isFiltersExpanded: page.leftSidebar.isFiltersExpanded ?? false,
								isPlansExpanded: page.leftSidebar.isPlansExpanded ?? false,
								isProjectsExpanded: page.leftSidebar.isProjectsExpanded ?? false,
								isOperationsExpanded: page.leftSidebar.isOperationsExpanded ?? false,
								isOverviewsExpanded: page.leftSidebar.isOverviewsExpanded ?? false,
							},
						},
					}
				: undefined),
			...(page.horizontalNavigation && getWillShowNav4()
				? {
						[HORIZONTAL_NAVIGATION]: {
							entryPoint: page.horizontalNavigation,
							entryPointParams: {
								...entryPointParams,
								fallback: page.horizontalNavigationFallback,
							},
						},
					}
				: undefined),
			...(page.rightSidebar
				? {
						[RIGHT_SIDEBAR]: {
							entryPoint: page.rightSidebar,
							entryPointParams: { ...entryPointParams, fallback: page.rightSidebarFallback },
						},
					}
				: undefined),
		},
	}),
	toString: () => 'PageEntryPoint',
});

function injectEntryPointParams(
	obj: Record<string, { entryPoint: AnyEntryPoint }>,
	entryPointParams: EntryPointRouteParams,
): Record<string, { entryPoint: AnyEntryPoint; entryPointParams: EntryPointRouteParams }> {
	const newParams: Record<
		string,
		{ entryPoint: AnyEntryPoint; entryPointParams: EntryPointRouteParams }
	> = {};

	// https://stackoverflow.com/a/16735184
	for (const prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			newParams[prop] = { entryPoint: obj[prop].entryPoint, entryPointParams };
		}
	}
	return newParams;
}
