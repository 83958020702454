// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '../../utils.tsx';

export const addDocumentTitle =
	(symbol: symbol, title?: string): Action<State> =>
	({ setState, getState }) => {
		const { contextArray } = getState();
		title && fg('jira-concurrent-document-title')
			? contextArray.push({
					title,
					symbol,
				})
			: contextArray.push(symbol);
		setState({
			contextArray,
		});
	};
