import React, { useMemo, type ReactElement } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { SECTION_ITEM_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useGetCurrentUrlPath } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-get-current-url/index.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { useExpandableEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-expandable-entry-point-trigger/index.tsx';
import {
	PACKAGE_NAME,
	TEAM_NAME,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-plans/src/ui/constants.tsx';
import { Nav4PlansFooter } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-plans/src/ui/plans/common/footer/Nav4PlansFooter.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { asyncPlansSectionPlanListViewQuery } from './async-plans-list/entrypoint.tsx';

type Props = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
};

export const Nav4PlansDefault = ({ actionsOnHover, isExpanded }: Props) => {
	const cloudId = useCloudId();
	const currentURLPath = useGetCurrentUrlPath();
	const entryPointParams = useMemo(() => ({ cloudId, currentURLPath }), [cloudId, currentURLPath]);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncPlansSectionPlanListViewQuery,
		entryPointParams,
	);
	const triggerRef = useExpandableEntryPointTrigger(entryPointActions);
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.PLANS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();
	const menuId = MENU_ID_PLANS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			onSystemToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					entryPointActions.load();
				}
			}}
			onExpansionToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					entryPointActions.load();

					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.PLANS,
						sectionItem: SECTION_ITEM_PLANS,
					});
				}
			}}
			menuId={menuId}
		>
			<ExpandableMenuItemTrigger
				actionsOnHover={actionsOnHover}
				aria-label={formattedMessage}
				elemBefore={icon}
				ref={triggerRef}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<JiraEntryPointContainer
					// Data stream, will subscribe to load updates
					entryPointReferenceSubject={entryPointReferenceSubject}
					// Required props for error JSErrorBoundary.
					id="nav4-plans-default"
					packageName={PACKAGE_NAME}
					// Optional props for error JSErrorBoundary.
					errorFallback="flag"
					teamName={TEAM_NAME}
					// Fallback for Suspense
					fallback={<SkeletonNavMenuItems />}
					// Props for the root component
					runtimeProps={entryPointParams}
				/>
				<Nav4PlansFooter />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
};
