import type { JiraSpaRoute, RouterContext } from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-name-types/src/RouteName.tsx';
import {
	MENU_ID_ASSETS,
	MENU_ID_ASSETS_REPORTS,
	MENU_ID_ASSETS_SCHEMAS,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<RouteName>([
	'assets',
	'assets-configure',
	'assets-object',
	'assets-schema',
	'assets-schema-configuration',
	'assets-object-type-configuration',
	'assets-reports',
	'assets-reports-default-redirect',
	'assets-reports-unsubscribe',
	'servicedesk-cmdb-assets',
	'servicedesk-cmdb-configure',
	'servicedesk-cmdb-object',
	'servicedesk-cmdb-object-schema',
	'servicedesk-cmdb-object-schema-configuration',
	'servicedesk-cmdb-object-type-configuration',
	'servicedesk-cmdb-assets-reports',
	'servicedesk-cmdb-assets-reports-default-redirect',
	'servicedesk-cmdb-assets-reports-unsubscribe',
	'servicedesk-cmdb-insight',
	'servicedesk-cmdb-configure',
	'servicedesk-cmdb-object',
	'servicedesk-cmdb-object-schema',
	'servicedesk-cmdb-object-schema-configuration',
	'servicedesk-cmdb-object-type-configuration',
]);

function convert(routeContext: RouterContext<JiraSpaRoute>): SelectedPath {
	const routeName: RouteName = routeContext.route.name;
	const path: string[] = [MENU_ID_ASSETS];
	return convertRouteToPath({ routeName, path });
}

function convertNew(args: { routeName: RouteName }): SelectedPath {
	const { routeName } = args;
	const path: string[] = [MENU_ID_ASSETS];
	return convertRouteToPath({ routeName, path });
}

function convertRouteToPath(args: { routeName: RouteName; path: string[] }): SelectedPath {
	const { routeName, path } = args;
	switch (routeName) {
		case 'servicedesk-cmdb-assets':
		case 'servicedesk-cmdb-object':
		case 'servicedesk-cmdb-object-schema':
		case 'servicedesk-cmdb-object-schema-configuration':
		case 'servicedesk-cmdb-object-type-configuration':
			path.push(MENU_ID_ASSETS_SCHEMAS);
			break;

		// Duplicate route-names of the above routes
		// eslint-disable-next-line no-duplicate-case
		case 'servicedesk-cmdb-object':
		// eslint-disable-next-line no-duplicate-case, no-fallthrough
		case 'servicedesk-cmdb-object-schema':
		// eslint-disable-next-line no-duplicate-case, no-fallthrough
		case 'servicedesk-cmdb-object-schema-configuration':
		// eslint-disable-next-line no-duplicate-case, no-fallthrough
		case 'servicedesk-cmdb-object-type-configuration':
			path.push(MENU_ID_ASSETS_SCHEMAS);
			break;

		case 'servicedesk-cmdb-assets-reports':
		case 'servicedesk-cmdb-assets-reports-default-redirect':
		case 'servicedesk-cmdb-assets-reports-unsubscribe':
			path.push(MENU_ID_ASSETS_REPORTS);
			break;

		default:
			break;
	}

	return path;
}

export const assetsConverter = { routes, convert, convertNew };
