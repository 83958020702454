import qs from 'query-string';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { RouterContext } from '@atlassian/react-resource-router';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	createContainer,
	type Action,
} from '@atlassian/react-sweet-state';

export const DefaultFilterFields = {
	// sorting
	SortOrder: 'sortOrder',
	SortKey: 'sortKey',

	// pagination
	Page: 'page',
};

type DefaultFilterKey = (typeof DefaultFilterFields)[keyof typeof DefaultFilterFields];
type DefaultInitialState = { [key: DefaultFilterKey]: string };
type FilterConfig<T> = {
	getInitialState: ({ routeQuery }: { routeQuery: RouterContext['query'] }) => T;
};

export const createUseFilter = <T extends DefaultInitialState>(
	storeName: string,
	{ getInitialState }: FilterConfig<T>,
) => {
	type Name = keyof T;
	type ContainerProps = {
		onUpdate: (filter: T) => void;
	};

	const actions = {
		updateFilter:
			(nameOrFilter: Name | Partial<T>, value?: string): Action<T, ContainerProps> =>
			({ setState, getState }, { onUpdate }) => {
				const newFilter =
					typeof nameOrFilter === 'object'
						? nameOrFilter
						: {
								[nameOrFilter]: value,
							};
				setState({ ...getState(), [DefaultFilterFields.Page]: 1, ...newFilter });
				onUpdate(getState());
			},
		forceUpdate:
			(): Action<T, ContainerProps> =>
			({ getState }, { onUpdate }) =>
				onUpdate(getState()),
	};

	type Actions = typeof actions;

	const Store = createStore<T, Actions>({
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		initialState: {} as T,
		actions,
		name: storeName,
	});

	const useFilter = createHook(Store);
	const useScopedFilter = createHook(Store, {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		selector: (state, name: Name) => state[name] as string,
	});

	const FilterContainer = createContainer<T, Actions, ContainerProps>(Store, {
		onInit:
			() =>
			({ setState }) => {
				try {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					const routeQuery = qs.parse(window.location.search);
					setState(getInitialState({ routeQuery }));
				} catch (error) {
					fireErrorAnalytics({
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						error: error as Error,
						meta: {
							id: 'createUseFilter',
							packageName: 'jiraDirectoryBaseV3',
							teamName: 'jira-cosmos',
						},
					});
				}
			},
		// reset initial state on unmount
		onCleanup:
			() =>
			({ setState }) =>
				setState(getInitialState({ routeQuery: {} })),
	});

	return { useFilter, useScopedFilter, FilterContainer };
};
