import React, { type ReactNode, useCallback } from 'react';

import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/flyout-menu-item';
import { MenuButtonItem as MenuButtonItemPrimitive } from '@atlassian/navigation-system/side-nav/menu-button-item';
import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';

import type { GlobalShortcutExpandItem, GlobalShortcutsItem } from '../../types';
import { MenuLinkItem } from '../menu-link-item';

type MenuExpandItemProps = GlobalShortcutExpandItem & { children?: ReactNode; disabled?: boolean };

export const MenuExpandItem = ({ icon, label, items, disabled }: MenuExpandItemProps) => {
	const createLinkItem = useCallback(
		(shortcut: GlobalShortcutsItem) => {
			return shortcut.items ? (
				<MenuExpandItem
					icon={shortcut.icon}
					label={shortcut.label}
					items={shortcut.items}
					disabled={disabled}
				/>
			) : (
				<MenuLinkItem
					apsKey={shortcut.apsKey}
					icon={shortcut.icon}
					url={shortcut.url || shortcut?.fallbackUrl?.()}
					label={shortcut.label}
					disabled={disabled}
				/>
			);
		},
		[disabled],
	);

	if (disabled) {
		return (
			<MenuButtonItemPrimitive
				elemBefore={icon}
				elemAfter={<ChevronRightIcon label="open menu" />}
				isDisabled={disabled}
			>
				{label}
			</MenuButtonItemPrimitive>
		);
	}

	return (
		<FlyoutMenuItem>
			<FlyoutMenuItemTrigger elemBefore={icon}>{label}</FlyoutMenuItemTrigger>
			<FlyoutMenuItemContent>
				<MenuList>{items?.map(createLinkItem)}</MenuList>
			</FlyoutMenuItemContent>
		</FlyoutMenuItem>
	);
};
