import { useEffect, useState } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

const localStorageProvider = createLocalStorageProvider('jira-nav4-prompt-banner');
const NAV4_PROMPT_BANNER_DISMISSED = 'jira.nav4-prompt-banner.dismissed';
const NAV4_PROMPT_BANNER_UPDATED_DISMISSED = 'jira.nav4-prompt-banner.updated.dismissed';

/** Show Nav4PromptBanner if:
 * - the `blu-6963-nav4-phase-1-admin-banner` FG is ON
 * - the Nav4 rollout is in phase 1 (precondition for the FG in StatSig)
 * - the user is site admin
 * - the user is on Nav3
 * - the banner wasn't dismissed
 *
 * Show Nav4PromptBanner with updated content:
 * - the `blu-6963-nav4-phase-1-admin-banner-pre-phase-2` FG is ON
 * - the Nav4 rollout is in phase 1 (precondition for the FG in StatSig)
 * - the user is site admin
 * - the user is on Nav3
 * - the banner wasn't dismissed
 */
export const useNav4PromptBanner = () => {
	const [isDismissed, setDismissed] = useState(
		localStorageProvider.get(NAV4_PROMPT_BANNER_DISMISSED),
	);
	const [isUpdatedDismissed, setUpdatedDismissed] = useState(
		localStorageProvider.get(NAV4_PROMPT_BANNER_UPDATED_DISMISSED),
	);

	const isAdmin = useIsAdmin();
	const isNav3 = getWillShowNav3();

	const shouldShowPromptBanner =
		isAdmin && isNav3 && !isDismissed && fg('blu-6963-nav4-phase-1-admin-banner');

	const shouldShowPromptBannerUpdated =
		isAdmin &&
		isNav3 &&
		!isUpdatedDismissed &&
		fg('blu-6963-nav4-phase-1-admin-banner-pre-phase-2');

	useEffect(() => {
		localStorageProvider.set(NAV4_PROMPT_BANNER_DISMISSED, isDismissed);
	}, [isDismissed]);

	useEffect(() => {
		localStorageProvider.set(NAV4_PROMPT_BANNER_UPDATED_DISMISSED, isUpdatedDismissed);
	}, [isUpdatedDismissed]);

	return fg('blu-6963-nav4-phase-1-admin-banner-pre-phase-2')
		? {
				shouldShowPromptBanner: shouldShowPromptBannerUpdated,
				setDismissed: setUpdatedDismissed,
			}
		: {
				shouldShowPromptBanner,
				setDismissed,
			};
};
