import React, { memo, useMemo } from 'react';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/sidebar-boundary/index.tsx';
import { SidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-common/src/ui/sidebar-content/index.tsx';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-common/src/ui/skeleton/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useNavigationGlobalResource } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/navigation-global-resource/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { extractData } from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/controllers/features/utils/extract-data.tsx';

const LazySidebarContent = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-global-settings-apps-settings" */ './main'
		),
);

const AsyncSidebar = () => {
	const { data } = useNavigationGlobalResource();
	const { isDevMode, isAdmin } = useTenantContext();
	const extractedData = useMemo(
		() => data && extractData(data, isDevMode, isAdmin),
		[data, isDevMode, isAdmin],
	);

	return (
		<SidebarBoundary
			id="nav4.sidebar.global-settings.apps-settings"
			packageName="jiraNavigationAppsSidebarNav4GlobalSettingsAppsSettings"
			ufoName="nav4.sidebar.global-settings.apps-settings"
		>
			<SidebarContent
				currentPageName="apps"
				hasGeneralConfiguration={extractedData?.hasGeneralConfiguration}
				testId="navigation-apps-sidebar-nav4-sidebars-settings-global-apps.ui.sidebar-content"
			>
				<Placeholder name="nav4.sidebar.global-settings.apps-settings" fallback={<Skeleton />}>
					<LazySidebarContent />
				</Placeholder>
			</SidebarContent>
		</SidebarBoundary>
	);
};

export default memo(AsyncSidebar);
