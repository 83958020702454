import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
import { sandboxBundlerBifrostEntrypointsRoute } from '@atlassian/jira-router-routes-sandbox-routes/src/sandboxBundlerBifrostEntrypointsRoute.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { sandboxBundlerBifrostEntryPoint } from './ui/entrypoint.tsx';

const sandboxBundlerBifrostPageEntryPoint = createPageEntryPoint({
	main: sandboxBundlerBifrostEntryPoint,
	// using PROJECTS as a placeholder
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const sandboxBundlerBifrostEntrypointsRouteEntry = createEntry(
	sandboxBundlerBifrostEntrypointsRoute,
	() => ({
		group: 'sandbox',
		layout: globalLayout,
		resources: [...getNavigationResources()],
		ufoName: 'sandbox-bundler',
		entryPoint: () =>
			fg('conditional_import_sandbox_bifrost')
				? sandboxBundlerBifrostPageEntryPoint
				: notFoundPageEntrypoint,
		bifrost: {
			bundling: {
				packageName:
					'@atlassian/jira-router-routes-sandbox-entries/src/sandboxBundlerBifrostEntrypointsRouteEntry.tsx',
				whichExportToUse: 'sandboxBundlerBifrostEntrypointsRouteEntry',
			},
			enabled: false,
		},
	}),
);
