import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sidebarFeedbackButtonLabel: {
		id: 'sidebar-nav4-feedback-button.sidebar-feedback-button-label',
		defaultMessage: 'Give feedback on the new navigation',
		description: 'The label for the feedback button',
	},
	feedbackTitle: {
		id: 'sidebar-nav4-feedback-button.utils.use-nav4-feedback-collector.feedback-title',
		defaultMessage: 'Help us improve the new navigation',
		description: 'Message for the title of nav4 feedback collector modal',
	},
	feedbackTitleDetailsWithLink: {
		id: 'sidebar-nav4-feedback-button.feedback-title-details',
		defaultMessage:
			'We’d love to hear about your experience with the new navigation. If needed, <a>you can switch back to the old navigation</a>.',
		description:
			'The text for the feedback title details in the feedback dialog. The placeholder will be replaced by a link that will navigate to the labs component page.',
	},
	feedbackTitleDetailsNoLink: {
		id: 'sidebar-nav4-feedback-button.feedback-title-details-no-link',
		defaultMessage: 'We’d love to hear about your experience with the new navigation.',
		description:
			'The text for the feedback title details in the feedback dialog. The placeholder will be replaced by a link that will navigate to the labs component page.',
	},
	feedbackTitleLearnMoreLinkText: {
		id: 'sidebar-nav4-feedback-button.feedback-title-learn-more-link-text',
		defaultMessage: 'Learn more about the new navigation',
		description: 'The text for the "learn more" link in the feedback dialog.',
	},
});
