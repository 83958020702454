import { useCallback, useEffect, useMemo, useState } from 'react';

import { FlightDeckClient } from '@atlassiansox/flight-deck-frontend-client';

import { isFedRamp } from '@atlassian/atl-context';

import {
	ASSETS_APP_KEY,
	DAYS_AUTO_PROVISIONED_ITEM_CONSIDERED_NEW,
	DAYS_END_USER_ACTIVE_PRODUCT_CONSIDERED_NEW,
	FLIGHTDECK_REQUEST_STATUS_KEY,
	RequestStatus,
} from '../../constants';
import { daysBetween } from '../utils/date';
import { formatText } from '../utils/text';

import { useCache } from './useCache';
import { useStatus } from './useStatus';

function checkIsRecentEndUserActivatedProduct(productType: string, endUserActivationDate?: string) {
	const parsedEndUserActivationDate = Date.parse(endUserActivationDate || '');

	if (
		!endUserActivationDate ||
		isNaN(parsedEndUserActivationDate) ||
		!['CONFLUENCE', 'JIRA_SERVICE_DESK', 'JIRA_PRODUCT_DISCOVERY'].includes(productType)
	) {
		return false;
	}

	const days = daysBetween(new Date(parsedEndUserActivationDate), new Date());

	return days >= 0 && days <= DAYS_END_USER_ACTIVE_PRODUCT_CONSIDERED_NEW;
}

function checkIsNewlyAutoProvisioned(autoProvisionDate?: string) {
	let isConsideredNew = false;

	if (autoProvisionDate) {
		const days = daysBetween(new Date(autoProvisionDate), new Date());
		isConsideredNew = days >= 0 && days <= DAYS_AUTO_PROVISIONED_ITEM_CONSIDERED_NEW;
	}

	return isConsideredNew;
}

function checkShouldRemoveJWM(productType: string) {
	return ['JIRA_SOFTWARE', 'JIRA_PRODUCT_DISCOVERY', 'JIRA_SERVICE_DESK'].includes(productType);
}

type UseProductLinksProps = {
	cloudId?: string;
	orgId?: string;
};

type ProductLink = {
	product: {
		autoProvisionDate?: string;
		companyHubName?: string;
		isCompanyHubPublishedAndPremium?: boolean;
		endUserActivationDate?: string;
		url?: string;
	};
	isCurrentSite: boolean;
	isCompanyHubPublishedAndPremium?: boolean;
	companyHubName?: string;
	shouldShowNewLozenge: boolean;
	shouldShowTryLozenge: boolean;
	autoProvisionDate?: string;
	displayName: string;
	url: string;
	avatar: string;
};

const flightdeckClient = new FlightDeckClient('atlassian-navigagtion-bff-data-provider');

export function useProductLinks({ cloudId, orgId }: UseProductLinksProps) {
	const { items } = useCache();
	const { setStatus } = useStatus();
	const [fdResponse, setFdResponse] = useState<any>(null);

	const [productConfig, setProductConfig] = useState<any>(null);

	useEffect(() => {
		const getFlightdeckData = async () => {
			try {
				setStatus(RequestStatus.LOADING, FLIGHTDECK_REQUEST_STATUS_KEY);
				const fdResponse: any = await flightdeckClient.fetchConfigFromFlightDeck({
					// app-switcher-fe is currently the only valid name that is configured
					// in Flightdeck
					name: 'app-switcher-fe',
					// This should get updated to be based on the supplied consumer
					// name: `app-global-shortcuts-${consumer}-fe`,
					dimensions: {
						environmentType: 'prod',
						perimeter: isFedRamp() ? 'fedramp-moderate' : 'commercial',
					},
				});

				setFdResponse(fdResponse);
				setStatus(RequestStatus.COMPLETE, FLIGHTDECK_REQUEST_STATUS_KEY);
			} catch (e) {
				setStatus(RequestStatus.ERROR, FLIGHTDECK_REQUEST_STATUS_KEY);
			}
		};

		if (!fdResponse) {
			getFlightdeckData();
		}
	}, [fdResponse, setStatus]);

	useEffect(() => {
		if (!fdResponse) {
			return;
		}

		const config: Record<string, any> = {};

		for (const product of fdResponse.products) {
			const {
				apsKey,
				tcsProductKey,
				siteUrlSuffix,
				displayName,
				rebrandedDisplayName,
				subtitle,
				ordinal,
				iconKey,
				rebrandedIconKey,
			} = product;

			config[apsKey] = {
				key: !!tcsProductKey ? tcsProductKey : apsKey.toLowerCase(),
				siteUrlSuffix,
				ordinal,
				label: formatText(displayName),
				rebrandedLabel: rebrandedDisplayName && formatText(rebrandedDisplayName),
				description: formatText(subtitle),
				iconKey,
				rebrandedIconKey,
			};

			// TODO: remove this once Asset app becomes standalone app
			if (apsKey === 'JIRA_SERVICE_DESK' && !config[ASSETS_APP_KEY]) {
				config[ASSETS_APP_KEY] = {
					key: ASSETS_APP_KEY,
					siteUrlSuffix: '/jira/assets',
					ordinal: ordinal + 0.1, // make sure assets is below jsm
					label: formatText('Assets'),
					iconKey: 'assets',
				};
			}
		}

		setProductConfig(config);
	}, [fdResponse]);

	const getProductLinkForItem = useCallback(
		(productType: string, siteUrl: string, productUrl?: string) => {
			if (productUrl) {
				return productUrl;
			}

			const siteSuffix = productConfig?.[productType]?.siteUrlSuffix;
			return `${siteUrl}${siteSuffix}`;
		},
		[productConfig],
	);

	const productLinks = useMemo(() => {
		const products: Record<string, ProductLink[]> = {};
		const shouldRemoveJWM = items.some((item) => {
			return (item.availableProducts || []).some(({ productType }) =>
				checkShouldRemoveJWM(productType),
			);
		});

		items.forEach((item) => {
			const {
				availableProducts = [],
				cloudId: cachedItemCloudId,
				avatar,
				url: siteUrl,
				displayName,
				org,
			} = item;

			// only apply org level filtering if org data is available
			if (org && orgId && org.id !== orgId) {
				return;
			}

			availableProducts.forEach((product) => {
				const {
					autoProvisionDate,
					companyHubName,
					isCompanyHubPublishedAndPremium,
					endUserActivationDate,
					url: productUrl,
				} = product;
				let { productType } = product;

				const isExcludeJiraBusiness = productType === 'JIRA_BUSINESS' && !shouldRemoveJWM;

				// if a site has JWM only and no other Jiras, JWM needs to appear under "Jira"
				// to ensure that this happens, we set JSM's productType to 'JIRA_SOFTWARE'
				// this will ensure that JWM is not removed, even if another site has JSW/JPD/JSM
				if (isExcludeJiraBusiness) {
					productType = 'JIRA_SOFTWARE';
				}

				if (!products[productType]) {
					products[productType] = [];
				}

				if (!isExcludeJiraBusiness) {
					products[productType].push({
						product,
						isCurrentSite:
							Boolean(cachedItemCloudId) &&
							cloudId?.toLowerCase() === cachedItemCloudId?.toLowerCase(),
						isCompanyHubPublishedAndPremium,
						companyHubName,
						shouldShowNewLozenge:
							checkIsRecentEndUserActivatedProduct(productType, endUserActivationDate) ||
							(productType !== 'CONFLUENCE' && checkIsNewlyAutoProvisioned(autoProvisionDate)),
						shouldShowTryLozenge:
							productType === 'CONFLUENCE' && checkIsNewlyAutoProvisioned(autoProvisionDate),
						autoProvisionDate,
						displayName,
						url: getProductLinkForItem(productType, siteUrl, productUrl),
						avatar,
					});
				}
			});
		});

		return products;
	}, [items, orgId, cloudId, getProductLinkForItem]);

	return {
		productLinks,
	};
}
