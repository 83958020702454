import React from 'react';
import { Flex, Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';

export const Skeleton = () => {
	return expVal('m1_features_page_experiment', 'cohort', 'not-enrolled') === 'variation' ? (
		<Box padding="space.200">
			<Stack space="space.200">
				<Flex gap="space.100" alignItems="center">
					<Box xcss={backButton} />
					<Box xcss={headingSkeleton} />
				</Flex>
				<Flex gap="space.100" alignItems="center">
					<Box xcss={titleSkeleton} />
				</Flex>
				<Stack space="space.100" grow="hug">
					<Box xcss={subtitleItem} />
					<Box xcss={menuItem} />
					<Box xcss={menuItem} />
					<Box xcss={menuItem} />
				</Stack>

				<Stack space="space.100" grow="hug">
					<Box xcss={subtitleItem} />
					<Box xcss={menuItem} />
					<Box xcss={menuItem} />
					<Box xcss={menuItem} />
					<Box xcss={menuItem} />
				</Stack>
				<Stack space="space.100" grow="hug">
					<Box xcss={subtitleItem} />
					<Box xcss={menuItem} />
				</Stack>
				<Stack space="space.100" grow="hug">
					<Box xcss={subtitleItem} />
					<Box xcss={menuItem} />
					<Box xcss={menuItem} />
				</Stack>
			</Stack>
		</Box>
	) : (
		<Stack space="space.200">
			<Flex gap="space.100" alignItems="center">
				<Box xcss={backButton} />
				<Box xcss={headingSkeleton} />
			</Flex>
			<Flex gap="space.100" alignItems="center">
				<Box xcss={titleSkeleton} />
			</Flex>
			<Stack space="space.100" grow="hug">
				<Box xcss={subtitleItem} />
				<Box xcss={menuItem} />
				<Box xcss={menuItem} />
				<Box xcss={menuItem} />
			</Stack>

			<Stack space="space.100" grow="hug">
				<Box xcss={subtitleItem} />
				<Box xcss={menuItem} />
				<Box xcss={menuItem} />
				<Box xcss={menuItem} />
				<Box xcss={menuItem} />
			</Stack>
			<Stack space="space.100" grow="hug">
				<Box xcss={subtitleItem} />
				<Box xcss={menuItem} />
			</Stack>
			<Stack space="space.100" grow="hug">
				<Box xcss={subtitleItem} />
				<Box xcss={menuItem} />
				<Box xcss={menuItem} />
			</Stack>
		</Stack>
	);
};

const titleSkeleton = xcss({
	backgroundColor: 'color.skeleton',
	width: token('space.800'),
	height: token('space.400'),
	borderRadius: 'border.radius.100',
});

const backButton = xcss({
	backgroundColor: 'color.skeleton',
	width: token('space.400'),
	height: token('space.400'),
	borderRadius: 'border.radius.100',
});

const headingSkeleton = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.400'),
	borderRadius: 'border.radius.100',
	flex: 1,
});

const menuItem = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.300'),
	borderRadius: 'border.radius.100',
});

const subtitleItem = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.200'),
	width: token('space.1000'),
	borderRadius: 'border.radius.100',
});
