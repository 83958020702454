import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { assetsConfigurationRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsConfigurationRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { assetsGlobalSettingsEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-global-configure/src/entrypoint.tsx';
import { LazyAssetsLayout, AssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { AssetsPageConfigContainer } from '@atlassian/jira-assets-app-page-config-container/src/ui/async.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';

const globalConfigurePageEntryPoint = createChromelessEntryPoint({
	main: assetsGlobalSettingsEntryPoint,
});

export const assetsConfigurationRouteEntry = createEntry(assetsConfigurationRoute, () => ({
	group: 'assets',
	LayoutOverrideComponent: AssetsLayout,
	PageConfigContainerOverride: AssetsPageConfigContainer,
	skeleton: Spinner,
	ufoName: 'servicedesk-cmdb-assets-configure',
	entryPoint: () => globalConfigurePageEntryPoint,
	pageId: () => 'spa-apps/servicedesk/insight/cmdb-configure',
	resources: getAssetsAppResources(),
	forPaint: [LazyAssetsLayout],
	perfMetricKey: 'service-management.insight-cmdb.interact-with-global-config-page',
	meta: {
		reporting: {
			id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
			packageName: toPackageName(APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION),
			teamName: 'falcons',
		},
	},
}));
