import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { dashboardsDirectoryResource } from '@atlassian/jira-router-resources-directories-dashboards/src/services/dashboards-directory/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { dashboardsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/dashboardsDirectoryRoute.tsx';
import { DashboardsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { nav4SidebarContentCoreEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-core/entrypoint.tsx';
import { nav4DashboardsContentEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/ui/dashboards/default/async-content/entrypoint.tsx';
import { dashboardDirectoryEntryPoint } from '@atlassian/jira-spa-apps-directories-dashboards/entrypoint.tsx';

export const dashboardsDirectoryRouteEntry = createEntry(dashboardsDirectoryRoute, () => ({
	group: 'directories',
	entryPoint: () => pageEntryPoint,
	skeleton: DashboardsDirectorySkeleton,
	layout: globalLayout,
	resources: [...getNavigationResources(), dashboardsDirectoryResource],
	navigation: {
		menuId: MENU_ID.DASHBOARDS,
		sidebarId: SIDEBAR_ID.CONTENT, // Nav4
	},
}));

// Page entrypoint, referred to above...
const pageEntryPoint = createPageEntryPoint({
	main: dashboardDirectoryEntryPoint,
	topNavigationMenuId: MENU_ID.DASHBOARDS,
	leftSidebar: {
		sidebar: nav4SidebarContentCoreEntryPoint,
		entryPoints: { dashboards: { entryPoint: nav4DashboardsContentEntryPoint } },
	},
});
