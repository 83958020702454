import type React from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore, type Action } from '@atlassian/react-sweet-state';

interface PopoutState {
	isPoppedOut: boolean;
	content: React.ReactNode;
}

// This is the value of the store on initialisation
const initialState: PopoutState = {
	isPoppedOut: false,
	content: null,
};

const actions = {
	setIsPoppedOut:
		(value: boolean): Action<PopoutState> =>
		({ getState, setState }) => {
			setState({ ...getState(), isPoppedOut: value });
		},
	setContent:
		(content: React.ReactNode): Action<PopoutState> =>
		({ getState, setState }) => {
			setState({ ...getState(), content });
		},
};

const Store = createStore<PopoutState, typeof actions>({
	initialState,
	actions,
	name: 'AlertsPopoutStore',
});
const useHook = createHook(Store);

export const useAlertsPopoutStore = () => {
	const [state, hookActions] = useHook();
	return {
		state,
		...hookActions,
	};
};
