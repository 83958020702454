import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { serviceDeskServiceDetailsWithoutProjectRoute } from '@atlassian/jira-router-routes-servicedesk-services-routes/src/serviceDeskServiceDetailsWithoutProjectRoute.tsx';
import ServicedeskServiceDetailsWithoutProjectContext, {
	LazyServicedeskServiceDetailsWithoutProjectContext,
} from './ui/service-details-without-project-context/index.tsx';

export const serviceDeskServiceDetailsWithoutProjectRouteEntry = createEntry(
	serviceDeskServiceDetailsWithoutProjectRoute,
	() => ({
		group: 'servicedesk',
		ufoName: 'service-management.service-details-without-project',
		component: ServicedeskServiceDetailsWithoutProjectContext,
		resources: [...getNavigationResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskServiceDetailsWithoutProjectContext],
		meta: {
			reporting: {
				id: 'servicedesk-service-details-without-project',
				packageName: 'jiraSpaAppsServicedeskServiceDetailsWithoutProjectContext"',
				teamName: 'Falcons',
			},
		},
	}),
);
