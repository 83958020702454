import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessListEmbedRoute } from '@atlassian/jira-router-routes-business-list-routes/src/jiraBusinessListEmbedRoute.tsx';
import { listEmbedEntryPoint } from '@atlassian/jira-spa-apps-business-list-embed/entrypoint.tsx';

const listEmbedPageEntryPoint = createChromelessEntryPoint({
	main: listEmbedEntryPoint,
});

export const jiraBusinessListEmbedRouteEntry = createEntry(jiraBusinessListEmbedRoute, () => ({
	group: 'classic-business',
	ufoName: 'jwm.list-embed-view',
	layout: chromelessLayout,
	skeleton: ThemedLazySkeleton,

	entryPoint: () => listEmbedPageEntryPoint,

	resources: getProjectViewResources(),
}));
