import type {
	AIContextServicePanelApiResponse,
	AIContextServicePanelResourceResponse,
} from './types.tsx';

export const transformContent = (content: { title: string; description: string }) => {
	return {
		title: parseAdf(content.title),
		description: parseAdf(content.description),
	};
};

export const transformData = (
	data: AIContextServicePanelApiResponse,
): AIContextServicePanelResourceResponse => ({
	type: 'ServicePanelSuggestions',
	summary: parseAdf(data.summary),
	reporterDetails: parseAdf(data.reporterDetails),
	nextSteps: parseAdf(data.nextSteps),
	suggestedEscalation: parseAdf(data.suggestedEscalation),
	suggestedActions: data.suggestedActions
		? data.suggestedActions.map((action) => {
				switch (action.type) {
					case 'priority':
						return {
							type: action.type,
							context: {
								...action.context,
								suggestion: action.context.suggestion,
							},
							content: transformContent(action.content),
						};
					case 'assignee':
					default:
						return {
							type: action.type,
							context: {
								...action.context,
								suggestion: action.context.suggestion,
							},
							content: transformContent(action.content),
						};
				}
			})
		: [],
});

export const parseAdf = (adfString: string | null | undefined) => {
	if (!adfString) return null;

	try {
		return JSON.parse(adfString);
	} catch (_error) {
		return null;
	}
};
