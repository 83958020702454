import type { JiraPlanDataRestResponse } from '@atlassian/jira-software-backlog-uif-types/src/index.tsx';
import type { UIFBacklogCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE_NEXTGEN_BACKLOG_REST_RESOURCE,
	RESOURCE_TYPE_NEXTGEN_BACKLOG_REST_RESOURCE_OLD,
	RESOURCE_TYPE_NEXTGEN_BACKLOG_CONFIG_DATA,
	RESOURCE_TYPE_NEXTGEN_BACKLOG_GLOBAL_CONFIG_DATA,
} from './constants.tsx';

export const uifBacklogResourceOld = createResource<JiraPlanDataRestResponse | null>({
	type: RESOURCE_TYPE_NEXTGEN_BACKLOG_REST_RESOURCE_OLD,
	getKey: ({
		match: {
			params: { boardId },
		},
		query: { config },
	}) =>
		/*
            When navigating to and from the board settings page we need
            the data to get reloaded. So we are updating the key to force a refresh
        */
		['uif-backlog-data']
			.concat(boardId ?? '')
			.concat(config ? `config-${config}` : '')
			.filter(Boolean)
			.join('-'),
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-nextgen-plandata-rest-old", jiraSpaEntry: "async-nextgen-plandata-rest-old" */ './get-data-old'
		),
	// Setting mexAge to 0 so it will get the data again after coming back from a config page
	maxAge: 0,
});

export const uifBacklogResource = createResource<UIFBacklogCachedDataResult | null>({
	type: RESOURCE_TYPE_NEXTGEN_BACKLOG_REST_RESOURCE,
	getKey: ({
		match: {
			params: { boardId },
		},
		query: { config },
	}) =>
		/*
            When navigating to and from the board settings page we need
            the data to get reloaded. So we are updating the key to force a refresh
        */
		['uif-backlog-data']
			.concat(boardId ?? '')
			.concat(config ? `config-${config}` : '')
			.filter(Boolean)
			.join('-'),
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-nextgen-plandata-rest", jiraSpaEntry: "async-nextgen-plandata-rest" */ './get-data'
		),
	// Setting mexAge to 0 so it will get the data again after coming back from a config page
	maxAge: 0,
});

export const backlogConfigResource = createResource({
	type: RESOURCE_TYPE_NEXTGEN_BACKLOG_CONFIG_DATA,
	getKey: ({
		match: {
			params: { boardId },
		},
	}) => `backlog-config-data-${boardId ?? ''}`,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-nextgen-configdata-rest", jiraSpaEntry: "async-nextgen-configdata-rest" */ './get-config-data'
		),
});

export const backlogGlobalConfigResource = createResource({
	type: RESOURCE_TYPE_NEXTGEN_BACKLOG_GLOBAL_CONFIG_DATA,
	getKey: ({
		match: {
			params: { boardId },
		},
	}) => `backlog-global-config-data-${boardId ?? ''}`,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-nextgen-global-configdata-rest", jiraSpaEntry: "async-nextgen-global-configdata-rest" */ './get-global-config-data'
		),
});
