import { useEffect, useState, useRef, useMemo } from 'react';
import type {
	ThemeSetting,
	MediaConfiguration,
} from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import { generateMediaImageUrl } from '@atlassian/jira-custom-theme-constants/src/utils.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';

import { unsplashTracker } from '@atlassian/jira-unsplash/src/controllers/unsplash-tracker/index.tsx';

export const useBackgroundImageUrl = (
	themeSetting: ThemeSetting | null | undefined, // remove undefined when cleaning up jira_theming_relay_migration
	media: MediaConfiguration | null | undefined,
): string | null => {
	const lowResUrl = useMemo(
		() =>
			media && themeSetting?.type === 'image'
				? generateMediaImageUrl({
						mediaClientId: media.clientId,
						mediaExternalEndpointUrl: media.externalEndpointUrl,
						mediaApiFileId: themeSetting.mediaApiFileId,
						token: themeSetting.mediaReadToken,
						mediaEndpoint: 'image',
						width: 1000,
						'max-age': Number.MAX_SAFE_INTEGER,
					})
				: null,
		[media, themeSetting],
	);

	const [imageUrl, setImageUrl] = useState<string | null>(lowResUrl);
	const previousThemeId = useRef<string | null>(null);

	useEffect(() => {
		if (themeSetting?.type !== 'image') {
			previousThemeId.current = null;
			setImageUrl(null);
			return;
		}

		if (previousThemeId.current !== themeSetting.id && media) {
			previousThemeId.current = themeSetting.id;
			setImageUrl(lowResUrl);
			// build a new URL to the /binary path
			const binaryUrl = generateMediaImageUrl({
				mediaClientId: media.clientId,
				mediaExternalEndpointUrl: media.externalEndpointUrl,
				mediaApiFileId: themeSetting.mediaApiFileId,
				mediaEndpoint: 'binary',
				'max-age': Number.MAX_SAFE_INTEGER,
			});

			// send a request to fetch the image with the binary URL with bearer token authentication
			fetch(binaryUrl.toString(), {
				headers: {
					Authorization: `Bearer ${themeSetting.mediaReadToken}`,
				},
			})
				.then((response) => response.blob())
				.then((blob) => {
					const objectUrl = URL.createObjectURL(blob);

					setImageUrl(objectUrl);

					// send viewing analytics to Unsplash for Unsplash images
					if (themeSetting.sourceType === 'UNSPLASH' && themeSetting.sourceIdentifier) {
						unsplashTracker.trackImage(themeSetting.sourceIdentifier);
					}
				})
				.catch((error) => {
					// only fire error analytics if the error is not a client fetch error (e.g. network error, aborted)
					if (!isClientFetchError(error)) {
						fireErrorAnalytics({
							meta: {
								id: 'fetchBinaryBackgroundImage',
								packageName: 'jiraProjectThemeComponents',
								teamName: 'work-management-frontier',
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
					}
				});
		}
	}, [themeSetting, media, lowResUrl]);

	return imageUrl;
};
