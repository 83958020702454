import React, { useEffect, useCallback, useRef } from 'react';
import Heading from '@atlaskit/heading';
import { xcss, Inline, Stack, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointDropdownItemTrigger } from '@atlassian/jira-modal-entry-point-dropdown-item-trigger/src/ModalEntryPointDropdownItemTrigger.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { BOARD_TYPES } from '../../../../common/constants.tsx';
import { boardCreateModalEntryPoint } from '../../../modal/entrypoint.tsx';
import { BoardCreateModalFallback } from '../../../modal/index.tsx';
import type { DropdownItemContentProps } from '../types.tsx';
import messages from './messages.tsx';

export const DropdownItemContainer = ({
	entryPointProps,
	title,
	description,
	icon,
	index,
}: DropdownItemContentProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const triggerRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (index === 0 && triggerRef.current) {
			triggerRef.current.focus();
		}
	}, [index]);

	const handleOnDropdownItemClick = useCallback(() => {
		const boardTypePascalCase =
			entryPointProps.boardType === BOARD_TYPES.SCRUM ? 'Scrum' : 'Kanban';
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'dropdownItem',
				action: 'clicked',
			}),
			`create${boardTypePascalCase}Board`,
		);
	}, [createAnalyticsEvent, entryPointProps.boardType]);

	return (
		<ModalEntryPointDropdownItemTrigger
			aria-label={formatMessage(messages.createBoardLabel, {
				boardType: entryPointProps.boardType.toLowerCase(),
			})}
			forwardedRef={triggerRef}
			entryPoint={boardCreateModalEntryPoint}
			testId="software-board-create.ui.flyout-menu.flyout-menu-content.dropdown-item-container.dropdown-item-trigger"
			interactionName="open-create-board-modal"
			entryPointProps={entryPointProps}
			Fallback={BoardCreateModalFallback}
			onClick={handleOnDropdownItemClick}
			useInternalModal={false}
		>
			<Inline space="space.100" alignBlock="start">
				<Inline alignBlock="center" alignInline="center" xcss={itemIconBackgroundStyles}>
					{icon}
				</Inline>
				<Stack>
					<Heading size="xsmall">{title}</Heading>
					<Text size="medium">{description}</Text>
				</Stack>
			</Inline>
		</ModalEntryPointDropdownItemTrigger>
	);
};

const ICON_BACKGROUND_CONTAINER_SIZE = '32px';

const itemIconBackgroundStyles = xcss({
	backgroundColor: 'color.background.neutral',
	width: ICON_BACKGROUND_CONTAINER_SIZE,
	height: ICON_BACKGROUND_CONTAINER_SIZE,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "2px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius.050', '3px'),
	padding: 'space.050',
	flexShrink: 0,
});
