import QUERY, {
	type ThemeSetterQuery,
} from '@atlassian/jira-relay/src/__generated__/ThemeSetterQuery.graphql';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import { withGiraAggAnonSsrFix } from './utils.tsx';

// We will revert it back to use without withGiraAggAnonSsrFix once the underlying
// auth issues have been fixed in https://hello.jira.atlassian.cloud/browse/DEE-7165
export const projectThemeResource = withGiraAggAnonSsrFix(
	createRelayResource<ThemeSetterQuery>({
		type: 'JIRA_PROJECT_THEME',
		getQuery: ({ match, query }, { tenantContext }) => {
			const { projectKey, projectId } = getProjectKeyId(match, query);
			const { cloudId } = tenantContext;

			return {
				parameters: QUERY,
				variables: {
					cloudId,
					projectIdOrKey: projectKey ?? projectId ?? '',
				},
				options: { fetchPolicy: 'store-or-network' },
			};
		},
	}),
);
