import cond from 'lodash/cond';
import otherwise from 'lodash/stubTrue';
import { fg } from '@atlassian/jira-feature-gating';
import { getIsNav4UserOptOut } from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';
import type { Nav4RolloutDogfooding, Phase } from '../../../types.tsx';

const dogfoodingPhase = (phase: Phase) => (): Nav4RolloutDogfooding =>
	phase !== 3
		? { stage: 'dogfooding', phase, isUserOptOut: getIsNav4UserOptOut() }
		: { stage: 'dogfooding', phase };

// eslint-disable-next-line jira/ff/inline-usage
export const getNav4RolloutForDogfooding = cond<Nav4RolloutDogfooding>([
	[() => fg('jira_nav4_dogfooding_phase-2'), dogfoodingPhase(2)],
	[() => fg('jira_nav4_dogfooding_phase-1'), dogfoodingPhase(1)],
	[otherwise, dogfoodingPhase(3)],
]);
