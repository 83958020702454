import {
	USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
	USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_SOFTWARE,
} from '@atlassian/jira-onboarding-core/src/constants.tsx';

export const tailoredTeamTypes = [
	USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
	USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
] as const;

export const tailoredTeamTypesWithSoftware = [
	...tailoredTeamTypes,
	USER_SEGMENTATION_TEAM_TYPE_SOFTWARE,
] as const;

export const tailoredM2P5TeamTypes = [
	USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
	USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_SOFTWARE,
] as const;
