import type {
	PartialVariantRoute,
	Route,
} from '@atlassian/react-resource-router/src/common/types.tsx';
import type { InvariantJiraRoute } from '@atlassian/jira-route-types/src/InvariantJiraRoute.tsx';
import { createRouteEntry } from './createRouteEntry.tsx';

/**
 * Create a route entry for single-page application pages.
 */
export const createEntry = createRouteEntry<InvariantJiraRoute, PartialVariantRoute, Route>;
