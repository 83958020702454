/**
 * @generated SignedSource<<d8f87fd6efcb3b2793833812a581fd4f>>
 * @relayHash 539d81342bcb3bdab170556f3882c1e6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1354395b89d1b40a39f597c3f378eb87813ab2626873be265cad98b57286915d

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AlertsContentViewQuery$variables = {
  cloudId: string;
};
export type AlertsContentViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AlertsMenuItemContent" | "AlertsMenuItemContentNew">;
};
export type AlertsContentViewQuery = {
  response: AlertsContentViewQuery$data;
  variables: AlertsContentViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "name": "id"
  },
  {
    "kind": "ScalarField",
    "name": "name"
  },
  {
    "kind": "ScalarField",
    "name": "alertSearchQuery"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "AlertsContentViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "AlertsMenuItemContentNew"
      },
      {
        "kind": "FragmentSpread",
        "name": "AlertsMenuItemContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AlertsContentViewQuery",
    "selections": [
      {
        "concreteType": "OpsgenieQuery",
        "kind": "LinkedField",
        "name": "opsgenie",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              }
            ],
            "concreteType": "OpsgenieSavedSearches",
            "kind": "LinkedField",
            "name": "savedSearches",
            "plural": false,
            "selections": [
              {
                "concreteType": "OpsgenieSavedSearch",
                "kind": "LinkedField",
                "name": "sharedWithMe",
                "plural": true,
                "selections": (v1/*: any*/)
              },
              {
                "concreteType": "OpsgenieSavedSearch",
                "kind": "LinkedField",
                "name": "createdByMe",
                "plural": true,
                "selections": (v1/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1354395b89d1b40a39f597c3f378eb87813ab2626873be265cad98b57286915d",
    "metadata": {},
    "name": "AlertsContentViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "879968e67a244943ed377d7fb5da054d";

export default node;
