/**
 * @generated SignedSource<<68ca1ff3c625c48e3b3f34fcd3122e1a>>
 * @relayHash c3ecae5efac1725022957b3584c6748a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d816bb1396041500986a655dbca037f2dba1138371158641897ea361c6ff7286

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiVirtualAgentIntentTemplatesQuery } from './uiVirtualAgentIntentTemplatesQuery.graphql';

const node: PreloadableConcreteRequest<uiVirtualAgentIntentTemplatesQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "d816bb1396041500986a655dbca037f2dba1138371158641897ea361c6ff7286",
    "metadata": {},
    "name": "uiVirtualAgentIntentTemplatesQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
