/**
 * @generated SignedSource<<b44f9761057ed7ea0b277fcdeb998d61>>
 * @relayHash 91af7345571ae29b0bcaa8c35ba4c2fe
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 937f60fcad28d0549884b2d0d71ae578315c0f9d27f22e844f8d3bf73d6dcd99

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraHarmonisedBusinessTimelineQuery } from './src_jiraHarmonisedBusinessTimelineQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraHarmonisedBusinessTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "937f60fcad28d0549884b2d0d71ae578315c0f9d27f22e844f8d3bf73d6dcd99",
    "metadata": {},
    "name": "src_jiraHarmonisedBusinessTimelineQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
