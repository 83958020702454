import { useMemo } from 'react';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';

export const useIsBacklogRoute = (): boolean => {
	const { name } = useCurrentRoute();

	return useMemo(
		() =>
			name === 'rapidboard-backlog' ||
			name === 'rapidboard-user-backlog' ||
			name === 'rapidboard-backlog-embed',
		[name],
	);
};
