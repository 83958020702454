import { useEffect, useState, useMemo, type ComponentType } from 'react';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsChromeless } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/chromeless/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { createRouterSelector } from '@atlassian/react-resource-router';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	addRouteListener,
	getRouterState,
} from '@atlassian/react-resource-router/src/controllers/router-store/index.tsx';
import {
	GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	GENERIC_PROJECT_HORIZONTAL_NAV,
} from '../../constants.tsx';

export const useHasBoardSettingsPageQueryParam = createRouterSelector((state) =>
	BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.has(state.query.config),
);

type HorizontalNavComponent =
	| ComponentType
	| 'GENERIC_PROJECT_HORIZONTAL_NAV'
	| 'GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV'
	| null;

/** "header" component above the page's content
 * It contains:
 * - Heading
 * - Breadcrumb
 * - misc buttons such as share, feedback, etc.
 * - tab navigation (if nav v4)
 */
export const useHorizontalNavComponentOld = (): ComponentType | null => {
	const route = useCurrentRoute();
	const { data: projectData } = useProjectContext();
	const hasBoardSettingsPageQueryParam = useHasBoardSettingsPageQueryParam();

	// chromeless mode is handled in page-container package with nav4
	if (!getWillShowNav4()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [isChromeless] = useIsChromeless();
		if (isChromeless) {
			return null;
		}
	}

	if (route.navigation?.horizontal == null) {
		return null;
	}

	// Shared horizontal navigation routes that currently live in business projects on prod
	// but we want to remove horizontal navigation on with the navigation redesign
	if (route.navigation.horizontal === GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV) {
		if (getWillShowNav4()) {
			return null;
		}
		switch (projectData?.projectType) {
			case CORE_PROJECT:
				return AsyncHorizontalNavJira;
			default:
				return null;
		}
	}

	// Board settings pages should not show the horizontal nav.
	// This is a workaround due to the fact that the software CMP board/backlog pages share the same route name
	// as the board settings pages. This code should be removed once the board settings pages have
	// their own dedicated route names.
	if (
		route.group === 'classic-software' &&
		hasBoardSettingsPageQueryParam &&
		getWillShowNav4() &&
		fg('simplified-boards-milestone-2_07hbk')
	) {
		return null;
	}

	if (route.navigation.horizontal === GENERIC_PROJECT_HORIZONTAL_NAV) {
		switch (projectData?.projectType) {
			case CORE_PROJECT:
				return AsyncHorizontalNavJira;
			case SOFTWARE_PROJECT:
				if (getWillShowNav4()) {
					return AsyncHorizontalNavJira;
				}
				return null;
			default:
				return null;
		}
	}

	// TODO: Remove this during jira_nav4 cleanup
	if ('onlyShowHorizontalOnNav4' in route.navigation && route.navigation.onlyShowHorizontalOnNav4) {
		return getWillShowNav4() ? route.navigation.horizontal : null;
	}

	return route.navigation.horizontal;
};

export const getNextHorizontalNavComponent = ({
	projectType,
	isChromeless,
	isSoftwareRouteGroup,
	navigationComponent,
	onlyShowHorizontalOnNav4,
	hasBoardSettingsPageQueryParam,
}: {
	projectType?: string;
	isChromeless: boolean;
	isSoftwareRouteGroup: boolean;
	navigationComponent: HorizontalNavComponent;
	onlyShowHorizontalOnNav4: boolean;
	hasBoardSettingsPageQueryParam: boolean;
}): ComponentType | null => {
	// chromeless mode is handled in page-container package with nav4
	if (!getWillShowNav4()) {
		if (isChromeless) {
			return null;
		}
	}

	if (navigationComponent == null) {
		return null;
	}

	// Shared horizontal navigation routes that currently live in business projects on prod
	// but we want to remove horizontal navigation on with the navigation redesign
	if (navigationComponent === GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV) {
		if (getWillShowNav4()) {
			return null;
		}
		switch (projectType) {
			case CORE_PROJECT:
				return AsyncHorizontalNavJira;
			default:
				return null;
		}
	}

	// Board settings pages should not show the horizontal nav.
	// This is a workaround due to the fact that the software CMP board/backlog pages share the same route name
	// as the board settings pages. This code should be removed once the board settings pages have
	// their own dedicated route names.
	if (
		isSoftwareRouteGroup &&
		hasBoardSettingsPageQueryParam &&
		getWillShowNav4() &&
		fg('simplified-boards-milestone-2_07hbk')
	) {
		return null;
	}

	if (navigationComponent === GENERIC_PROJECT_HORIZONTAL_NAV) {
		switch (projectType) {
			case CORE_PROJECT:
				return AsyncHorizontalNavJira;
			case SOFTWARE_PROJECT:
				if (getWillShowNav4()) {
					return AsyncHorizontalNavJira;
				}
				return null;
			default:
				return null;
		}
	}

	// TODO: Remove this during jira_nav4 cleanup
	if (onlyShowHorizontalOnNav4) {
		return getWillShowNav4() ? navigationComponent : null;
	}

	return navigationComponent;
};

export const useHorizontalNavComponentNew = (): ComponentType | null => {
	const { data: projectData } = useProjectContext();
	const hasBoardSettingsPageQueryParam = useHasBoardSettingsPageQueryParam();
	const [isChromeless] = useIsChromeless();
	const firstRoute = useMemo(() => getRouterState().route, []);
	const [horizontalNavComponent, setHorizontalNavComponent] = useState<ComponentType | null>(() =>
		// initialize with the first route's horizontal nav component
		getNextHorizontalNavComponent({
			projectType: projectData?.projectType,
			isChromeless,
			isSoftwareRouteGroup: firstRoute?.group === 'classic-software',
			navigationComponent: firstRoute?.navigation?.horizontal ?? null,
			onlyShowHorizontalOnNav4: Boolean(
				firstRoute?.navigation &&
					'onlyShowHorizontalOnNav4' in firstRoute?.navigation &&
					firstRoute?.navigation?.onlyShowHorizontalOnNav4,
			),
			hasBoardSettingsPageQueryParam,
		}),
	);

	useEffect(() => {
		const unsubscribe = addRouteListener(({ route }) => {
			setHorizontalNavComponent((prevState: ComponentType | null) => {
				const nextHorizontalNavComponent = getNextHorizontalNavComponent({
					projectType: projectData?.projectType,
					isChromeless,
					isSoftwareRouteGroup: route?.group === 'classic-software',
					navigationComponent: route?.navigation?.horizontal ?? null,
					onlyShowHorizontalOnNav4: Boolean(
						route?.navigation &&
							'onlyShowHorizontalOnNav4' in route?.navigation &&
							route?.navigation?.onlyShowHorizontalOnNav4,
					),
					hasBoardSettingsPageQueryParam,
				});

				if (prevState !== nextHorizontalNavComponent) {
					return nextHorizontalNavComponent;
				}
				return prevState;
			});
		});

		return () => unsubscribe();
	}, [projectData?.projectType, hasBoardSettingsPageQueryParam, isChromeless]);

	return horizontalNavComponent;
};

export const useHorizontalNavComponent = functionWithCondition(
	() => fg('reduce_navigation_rerenders_1'),
	useHorizontalNavComponentNew,
	useHorizontalNavComponentOld,
);
