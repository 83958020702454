import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { editPermissionSchemePageResource } from '@atlassian/jira-router-resources-admin-pages-permissions/src/services/edit-permission-scheme/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsEditPermissionSchemesRoute } from '@atlassian/jira-router-routes-admin-pages-permission-schemes-routes/src/globalSettingsEditPermissionSchemesRoute.tsx';
import EditPermissionSchemePage, {
	LazyEditPermissionSchemePage,
} from './ui/spa/admin-pages/permission-schemes/edit-permission-scheme-page/index.tsx';

export const globalSettingsEditPermissionSchemesRouteEntry = createEntry(
	globalSettingsEditPermissionSchemesRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'global-admin.issues.configure-permission-scheme',
		component: EditPermissionSchemePage,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			editPermissionSchemePageResource,
		],
		canTransitionIn: () => true,
		forPaint: [LazyAtlassianNavigation, LazyEditPermissionSchemePage],
		meta: {
			reporting: {
				id: 'EditPermissionScheme',
				packageName: 'jiraAdminPagesEditPermissionScheme',
				teamName: 'gordian',
			},
		},
	}),
);
