import React, { useMemo, useState, useCallback, type ReactNode } from 'react';
import flatten from 'lodash/flatten';
import noop from 'lodash/noop';
import Avatar from '@atlaskit/avatar';
import { Field, ErrorMessage, type FieldProps } from '@atlaskit/form';
import { Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import Select, { components, type SingleValueProps, type ValueType } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { BoardCreateFormValues, SourceTypeOption } from '../../../../common/types.tsx';
import { useBoardCreateContext } from '../../../../controllers/use-board-create-context/index.tsx';
import messages from './messages.tsx';
import { getOptionGroups } from './utils.tsx';

export const VALIDATION_ERROR_EMPTY = 'VALIDATION_ERROR_EMPTY';

const formatOptionLabel = (option: SourceTypeOption) => {
	const { icon: Icon, title, description } = option;

	return (
		<Flex alignItems="center" gap="space.150" xcss={optionStyles}>
			{typeof Icon === 'string' ? (
				<Avatar src={Icon} size="xsmall" appearance="square" borderColor="transparent" />
			) : (
				<Icon label="" name="projectIcon" />
			)}
			<Stack space="space.050">
				<Text>{title}</Text>
				{description && (
					<Text size="UNSAFE_small" color="color.text.subtlest">
						{description}
					</Text>
				)}
			</Stack>
		</Flex>
	);
};

const SingleValue = (props: SingleValueProps<SourceTypeOption>) => {
	const {
		data: { selectedTitle, icon: Icon },
	} = props;

	const { formatMessage } = useIntl();

	return (
		<components.SingleValue {...props}>
			<Flex alignItems="center" gap="space.100">
				{typeof Icon === 'string' ? (
					<Avatar src={Icon} size="xsmall" appearance="square" name="avatar" />
				) : (
					<Icon />
				)}
				<Text>
					{formatMessage(selectedTitle, {
						strong: (children: ReactNode) => <Text as="strong">{children}</Text>,
					})}
				</Text>
			</Flex>
		</components.SingleValue>
	);
};

export const useSelectedOption = () => useState<BoardCreateFormValues['sourceType'] | null>();

export const SourceTypePicker = ({ resetSourceValue }: { resetSourceValue: () => void }) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [selected, setSelected] = useSelectedOption();
	const { projectAvatar } = useBoardCreateContext();
	const optionGroups = useMemo(
		() => getOptionGroups({ formatMessage, projectAvatar }),
		[formatMessage, projectAvatar],
	);

	const flattenedOptions = useMemo(
		() => flatten(optionGroups.map((group) => group.options)),
		[optionGroups],
	);

	const handleChange = useCallback(
		(onFieldChange: FieldProps<BoardCreateFormValues['sourceType']>['onChange']) =>
			(value: ValueType<SourceTypeOption, false>) => {
				const option = value?.value || null;
				resetSourceValue();
				setSelected(option);
				onFieldChange(option);
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: 'select',
						action: 'changed',
					}),
					'boardSourceType',
					{ sourceType: option },
				);
			},
		[createAnalyticsEvent, resetSourceValue, setSelected],
	);

	const handleOnMenuOpen = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'select',
				action: 'opened',
			}),
			'boardSourceType',
		);
	}, [createAnalyticsEvent]);

	return (
		<Field<BoardCreateFormValues['sourceType']>
			name="sourceType"
			label={formatMessage(messages.sourceTypeFieldLabel)}
			defaultValue={undefined}
			isRequired
		>
			{({ fieldProps: { onChange: onFieldChange, ...rest }, error }) => (
				<>
					{/* Prevent ESC press on Select bubbling up to the modal */}
					<EnterEscapeHandler onEscape={noop}>
						<Select<SourceTypeOption, false>
							{...rest}
							aria-label={formatMessage(messages.sourceTypePickerAriaLabel)}
							options={optionGroups}
							isSearchable={false}
							formatOptionLabel={formatOptionLabel}
							components={{
								SingleValue,
							}}
							placeholder={formatMessage(messages.selectPlaceholder)}
							menuPosition="fixed"
							classNamePrefix="source-type-picker"
							onChange={handleChange(onFieldChange)}
							value={flattenedOptions.find((option) => option.value === selected)}
							// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
							styles={{
								control: (base, state) => ({
									...base,
									borderColor: state.isFocused ? token('color.border.focused') : base.borderColor,
									':focus-within': {
										boxShadow: state.isFocused
											? `inset 0px 0px 0px 1px ${token('color.border.focused')}`
											: base.boxShadow,
									},
									':hover': {
										borderColor: state.isFocused ? token('color.border.focused') : base.borderColor,
										boxShadow: state.isFocused ? 'elevation.shadow.raised' : base.boxShadow,
									},
								}),
								option: (base) => ({
									...base,
									// padding inside the option so hover covers the element
									padding: `${token('space.100')} 0 ${token('space.100')} ${token('space.300')}`,
								}),
								group: (base) => ({
									...base,
									padding: 0,
									':not(:last-of-type)': {
										borderBottomWidth: '2px',
										borderBottomStyle: 'solid',
										borderBottomColor: token('color.border'),
									},
								}),
								groupHeading: (base) => ({
									...base,
									margin: 0,
								}),
								menuList: (base) => ({
									...base,
									padding: 0,
									maxHeight: 325,
								}),
							}}
							onMenuOpen={handleOnMenuOpen}
						/>
					</EnterEscapeHandler>
					{error === VALIDATION_ERROR_EMPTY && (
						<ErrorMessage testId="software-board-create.ui.modal.form.source-type-picker.error">
							{formatMessage(messages.sourceTypeEmptyError)}
						</ErrorMessage>
					)}
				</>
			)}
		</Field>
	);
};

const optionStyles = xcss({
	minHeight: '40px',
});
