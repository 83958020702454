import React, { useEffect } from 'react';
import { Box } from '@atlaskit/primitives';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import {
	useDebuggerStatus,
	useJqlDebuggerStoreActions,
} from '../../controllers/jql-debugger-data/index.tsx';
import { ErrorSection } from '../error-section/index.tsx';
import { SuggestedFixContentEntrypoint } from '../suggested-fix-content/entrypoint.tsx';
import {
	ERROR_BOUNDARY_IDS,
	PACKAGE_NAME_JQL_DEBUGGER,
	TEAM_NAME,
} from '../../common/constants.tsx';

function OnError() {
	const debuggerActions = useJqlDebuggerStoreActions();

	useEffect(() => {
		// On error, set the feature state to be JS_ERROR, so that such scenarios can be handled without
		// interfering with the feature initial state nor with the AI event instrumentation
		debuggerActions.setStatus('JS_ERROR');
	}, [debuggerActions]);

	return null;
}

export const JQLDebuggerCommonEntry = () => {
	const [status] = useDebuggerStatus();

	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		SuggestedFixContentEntrypoint,
		{},
	);

	/*
		We load the entrypoint conditionally here as the JQL debugger panel is rendered underneath the JQL builder for large screen sizes,
		and across the issue view for smaller screen sizes - their visibilty is controlled by a <Show>, but they are two separately
		rendered components. As a result, if we are switching from one screen size or issue view to another, the newly rendered version of the
		JQL debugger will not have a loaded entry point, so we load it here to ensure the content is still visible across different views.

		Example of behaviour without this conditional:
		https://www.loom.com/share/725438e2ea16481591bbe510815e9ca3?sid=b3dc286e-9c37-4cf9-a2c9-f55d3561a166
	*/
	if (status !== 'INIT') {
		entryPointActions?.load();
	}

	return (
		<AIContainer
			spacing="none"
			hasNewBorderExperience
			shouldFitContainer
			isLoading={status === 'LOADING_RESULT'}
		>
			<Box padding="space.150">
				<ErrorSection entryPointActions={entryPointActions} />
			</Box>
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				runtimeProps={{}}
				packageName={PACKAGE_NAME_JQL_DEBUGGER}
				id={ERROR_BOUNDARY_IDS.FIX_WITH_AI_MODAL}
				teamName={TEAM_NAME}
				fallback={null}
				errorFallback={OnError}
			/>
		</AIContainer>
	);
};
