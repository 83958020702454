import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmEmailDomainsListResource } from '@atlassian/jira-router-resources-jsm-get-email-domains-list/src/index.tsx';
import { productsJsmOrganizationDetailsResource } from '@atlassian/jira-router-resources-jsm-organization-details/src/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { serviceDeskOrganizationPanelForgeDataResource } from '@atlassian/jira-router-resources-service-desk-org-panel-forge-data/src/controllers/index.tsx';
import { servicedeskOrganizationV2Route } from '@atlassian/jira-router-routes-servicedesk-organization-routes/src/servicedeskOrganizationV2Route.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { ServiceDeskOrganizationV2, LazyServicedeskOrganizationV2 } from './ui/index.tsx';

export const servicedeskOrganizationV2RouteEntry = createEntry(
	servicedeskOrganizationV2Route,
	() => ({
		group: 'servicedesk',
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			serviceDeskOrganizationPanelForgeDataResource,
			getConsolidationStateResource(),
			productsJsmOrganizationDetailsResource,
			jsmEmailDomainsListResource,
		],

		component: ServiceDeskOrganizationV2,
		forPaint: [LazyAtlassianNavigation, LazyServicedeskOrganizationV2],
		perfMetricKey: 'jsm-organizations-component-load',
	}),
);
