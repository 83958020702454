import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncViewsNav } from '@atlassian/jira-horizontal-nav-jsm-views/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getRapidBoardDataResource,
	getRapidboardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { jsmBoardRoute } from '@atlassian/jira-router-routes-servicedesk-board-routes/src/jsmBoardRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/ClassicBoardSkeleton.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	LazyServicedeskBoard,
	LazyServicedeskLegacyBoardSettings,
	ServicedeskBoard,
	LazyBoardSettings,
} from './ui/view.tsx';

export const jsmBoardRouteEntry = createEntry(jsmBoardRoute, () => ({
	group: 'servicedesk',
	component: ServicedeskBoard,
	layout: serviceProjectLayout,
	skeleton: ClassicBoardSkeleton,
	forPaint: [
		LazyAtlassianNavigation,
		LazyServicedeskBoard,
		LazyServicedeskLegacyBoardSettings,
		LazyBoardSettings,
	],
	perfMetricKey: 'servicedesk-boards-load',
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncViewsNav,
	},
	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		resourceWithCondition2(() => !__SERVER__, uifBoardResource),
		resourceWithCondition2(() => fg('jsm_views_configuration_migration'), getEditModelResource()),
		resourceWithCondition2(
			() => fg('jsm_views_configuration_migration'),
			getBoardDetailsResource(),
		),
		projectContextResource,
	],
}));
