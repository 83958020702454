import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const planSummaryEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() => {
		if (!__SERVER__) {
			return import(/* webpackChunkName: "async-plan-summary-entrypoint" */ './index');
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return Promise.resolve({ default: () => null }) as unknown as Promise<{
			default: typeof import('./index').default;
		}>;
	}),
	getPreloadProps: () => ({}),
});
