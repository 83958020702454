import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { legacyProjectReleasesNoteConfigRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/legacyProjectReleasesNoteConfigRoute.tsx';
import { classicSoftwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { LegacyFrontendReleaseNoteRedirect } from './ui/index.tsx';

export const legacyProjectReleasesNoteConfigRouteEntry = createEntry(
	legacyProjectReleasesNoteConfigRoute,
	() => ({
		group: 'project-settings-software',
		resources: [getConsolidationStateResource()],
		component: LegacyFrontendReleaseNoteRedirect,
		layout: classicSoftwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		forPaint: [LazyAtlassianNavigation],
	}),
);
