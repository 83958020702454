import { dashboardBackgroundScriptsLayout } from '@atlassian/jira-background-scripts-layout/src/index.tsx';
import { DASHBOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { gadgetDirectoryResource } from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { dashboardEditRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardEditRoute.tsx';
import { nav4DashboardsContentEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/ui/dashboards/default/async-content/entrypoint.tsx';
import { dashboardEditEntryPoint } from '@atlassian/jira-dashboard-edit/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { nav4SidebarContentCoreEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-core/entrypoint.tsx';
import { dashboardResources } from './ui/index.tsx';

// IMPORTANT - this route entry must precede the view route due to substring path match
export const dashboardEditRouteEntry = createEntry(dashboardEditRoute, () => ({
	group: 'dashboard',
	entryPoint: () => pageEntryPoint,
	layout: composeLayouts(globalLayout, dashboardBackgroundScriptsLayout),
	navigation: {
		menuId: MENU_ID.DASHBOARDS,
		sidebarId: SIDEBAR_ID.CONTENT, // Nav4
	},
	resources: [...getNavigationResources(), ...dashboardResources(), gadgetDirectoryResource],
	meta: {
		capability: DASHBOARD,
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));

// Page entrypoint, referred to above...
const pageEntryPoint = createPageEntryPoint({
	main: dashboardEditEntryPoint,
	topNavigationMenuId: MENU_ID.DASHBOARDS,
	leftSidebar: {
		sidebar: nav4SidebarContentCoreEntryPoint,
		entryPoints: { dashboards: { entryPoint: nav4DashboardsContentEntryPoint } },
	},
});
