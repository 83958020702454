import { useMemo } from 'react';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	toProjectType,
	PRODUCT_DISCOVERY_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useJsmPremiumPillExperiment } from '@atlassian/jira-edition-awareness-dropdown/src/common/utils.tsx';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import {
	FREE_EDITION,
	getEditionForProject,
	type ApplicationEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useSiteHasOnlyJira } from '@atlassian/jira-billing/src/controllers/explicitly-licensed-products/use-site-has-only-jira.tsx';
import type { Product, Edition } from '@atlassian/edition-awareness';
import { getApplicationKey, getEaEdition, getEaProduct } from '../ui/utils.tsx';
import type { SupportedApplicationKey } from '../common/types.tsx';

// Experiment specific code to be removed during cleanup
const isEnrolledInJPDFreeToStandard = () => {
	const [config] = UNSAFE_noExposureExp('jpd-free-to-upgrade-pill-au-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};

type ProductAndEditionInfo = {
	product: Product | undefined;
	edition: Edition | undefined;
	buttonProps:
		| {
				projectType: ProjectType;
				applicationEdition: ApplicationEdition;
				siteHasJswOnly: boolean;
				applicationKey: SupportedApplicationKey;
		  }
		| undefined;
};

/**
 * Condenses some logic from ProductAndEditionChecker. This is to be used
 * during the integration of Jira with the AudienceAndTiming code in Platform.
 * This will eventually be cleaned up as more EA code is shifted to Platform
 * @returns { product, edition, buttonProps }
 */
export const useProductAndEdition = (): ProductAndEditionInfo => {
	const appEditions = useAppEditions();
	const { loading, data: project } = useProject();
	const siteHasOnlyJira = useSiteHasOnlyJira();

	const { getIsEligibleForJsmPremiumPillExperiment } = useJsmPremiumPillExperiment();
	// Show premium pill button for JSM premium trial experiment
	const projectType = useMemo(() => {
		if (loading) return;

		const type = toProjectType(project?.type);

		if (type) return type;

		if (siteHasOnlyJira) return SOFTWARE_PROJECT;

		if (getIsEligibleForJsmPremiumPillExperiment()) {
			// If the user is on the JSM assets page, we want to default the project type to JSM
			return SERVICE_DESK_PROJECT;
		}
	}, [loading, project?.type, siteHasOnlyJira, getIsEligibleForJsmPremiumPillExperiment]);

	const localEdition = useMemo(() => {
		if (!projectType) return;

		return getEditionForProject(projectType, appEditions);
	}, [appEditions, projectType]);

	const { product, edition } = useMemo(() => {
		if (!projectType || !localEdition) {
			// We don't have a product or edition, so return nothing
			return {};
		}

		if (
			projectType === PRODUCT_DISCOVERY_PROJECT &&
			localEdition === FREE_EDITION &&
			!isEnrolledInJPDFreeToStandard()
		) {
			// JPD in free is in the process of being rolled out to 100%, this check is to be removed in cleanup
			return {};
		}
		if (projectType === CORE_PROJECT && !siteHasOnlyJira) {
			// We are supporting only JWM implicit licensing when the parent license is JSW
			// Do not show on core products if site does not have only Jira
			return {};
		}
		return {
			product: getEaProduct(projectType),
			edition: getEaEdition(localEdition),
		};
	}, [localEdition, projectType, siteHasOnlyJira]);

	const buttonProps = useMemo(() => {
		// this code is to be cleaned up when EA content is moved to platform
		if (!product || !edition || !projectType || !localEdition) return;

		const applicationKey = getApplicationKey(product);
		if (!applicationKey) return;
		return {
			projectType,
			applicationEdition: localEdition,
			siteHasJswOnly: siteHasOnlyJira,
			applicationKey,
		};
	}, [edition, localEdition, product, projectType, siteHasOnlyJira]);

	return {
		product,
		edition,
		buttonProps,
	};
};
