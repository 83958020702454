const STARRED_AND_RECENT = 'STARRED_AND_RECENT' as const;
const STARRED = 'STARRED' as const;
const MOST_RECENT_ONLY = 'MOST_RECENT_ONLY' as const;

export const ViewOptions = {
	STARRED_AND_RECENT,
	STARRED,
	MOST_RECENT_ONLY,
} as const;

export const DefaultViewOption = ViewOptions.STARRED_AND_RECENT;
