/**
 * @generated SignedSource<<513d91abcf431d67cd831934eb3cdd81>>
 * @relayHash c7ba33b8e7695997c7bbf9e29b42c7ed
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7b22213e57ba6874b345876f3c35d0dda118f459e6007f6c559d80ba94b6d1fe

import type { ConcreteRequest, Query } from 'relay-runtime';
export type planNoScenarioQuery$variables = {
  planId: string;
};
export type planNoScenarioQuery$data = {
  readonly jira: {
    readonly planById: {
      readonly defaultNavigationItem: {
        readonly url?: string | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
};
export type planNoScenarioQuery = {
  response: planNoScenarioQuery$data;
  variables: planNoScenarioQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "planId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "planId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "url"
    }
  ],
  "type": "JiraNavigationItem",
  "abstractKey": "__isJiraNavigationItem"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "planNoScenarioQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraPlan",
            "kind": "LinkedField",
            "name": "planById",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "kind": "LinkedField",
                  "name": "defaultNavigationItem",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ]
                },
                "action": "THROW",
                "path": "jira.planById.defaultNavigationItem"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "planNoScenarioQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraPlan",
            "kind": "LinkedField",
            "name": "planById",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "defaultNavigationItem",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7b22213e57ba6874b345876f3c35d0dda118f459e6007f6c559d80ba94b6d1fe",
    "metadata": {},
    "name": "planNoScenarioQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7791279196ac8025a83cda3fc2b29b08";

export default node;
