export const getIssuesNavigationPaths = () => ({
	// Features
	newIssueSearchTransition: '/jira/settings/issues/new-issue-search-transition',
	timeTrackingItem: '/secure/admin/TimeTrackingAdmin.jspa',
	issueLinkingItem: '/secure/admin/ViewLinkTypes!default.jspa',

	// IssueTypes
	issueTypesHierarchyItem: '/jira/settings/issues/issue-hierarchy',
	issueTypesItem: '/jira/settings/issues/issue-types',
	issueTypeSchemesItem: '/jira/settings/issues/issue-type-schemes',
	issueTypesSubTasksItem: '/secure/admin/subtasks/ManageSubTasks.jspa',

	// Workflows
	workflowsItem: '/jira/settings/issues/workflows',
	workflowSchemesItem: '/secure/admin/ViewWorkflowSchemes.jspa',

	// Screens
	screensItem: '/jira/settings/issues/screens',
	screenSchemesItem: '/jira/settings/issues/screen-schemes',
	screensIssueTypeScreenSchemesMenu: '/jira/settings/issues/issue-type-screen-schemes',

	// Fields
	fieldsManagement: '/jira/settings/issues/fields',
	customFieldsItem: '/secure/admin/ViewCustomFields.jspa',
	fieldConfigurationsItem: '/secure/admin/ViewFieldLayouts.jspa',
	fieldConfigurationSchemes: '/secure/admin/ViewFieldLayoutSchemes.jspa',

	// Priorities
	prioritiesItem: '/jira/settings/issues/priorities',
	prioritySchemesItem: '/jira/settings/issues/priority-schemes',

	// Forms
	dataConnectionsItem: '/jira/settings/forms/data-connections',

	// IssueAttributes
	statusesItem: '/secure/admin/ViewStatuses.jspa',
	resolutionsItem: '/secure/admin/ViewResolutions.jspa',
	issueSecuritySchemesItem: '/secure/admin/ViewIssueSecuritySchemes.jspa',
	notificationSchemesItem: '/secure/admin/ViewNotificationSchemes.jspa',
	permissionSchemesItem: '/secure/admin/ViewPermissionSchemes.jspa',
});

const getIssuesNavPaths = () => {
	return [
		'/jira/settings/issues/new-issue-search-transition',
		'/secure/admin/TimeTrackingAdmin',
		'/secure/admin/ViewLinkTypes',
		'/jira/settings/issues/issue-hierarchy',
		'/jira/settings/issues/issue-types',
		'/jira/settings/issues/issue-type-schemes',
		'/secure/admin/subtasks/ManageSubTasks',
		'/jira/settings/issues/workflows',
		'/secure/admin/ViewWorkflowSchemes',
		'/jira/settings/issues/screens',
		'/jira/settings/issues/screen-schemes',
		'/jira/settings/issues/issue-type-screen-schemes',
		'/jira/settings/issues/fields',
		'/secure/admin/ViewCustomFields',
		'/secure/admin/ViewFieldLayouts',
		'/secure/admin/ViewFieldLayoutSchemes',
		'/jira/settings/issues/priorities',
		'/jira/settings/issues/priority-schemes',
		'/secure/admin/ViewStatuses',
		'/secure/admin/ViewResolutions',
		'/secure/admin/ViewIssueSecuritySchemes',
		'/secure/admin/ViewNotificationSchemes',
		'/secure/admin/ViewPermissionSchemes',
	];
};

export const isIssuesNavPath = (baseUrl: String) => {
	const paths = getIssuesNavPaths();
	return paths.some((path) => baseUrl.includes(path));
};
