import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskReportsSatisfactionTimeScaleRoute } from '@atlassian/jira-router-routes-servicedesk-reports-routes/src/servicedeskReportsSatisfactionTimeScaleRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import SatisfactionReports, {
	LazyServicedeskReportsSatisfaction,
} from './ui/satisfaction/index.tsx';

export const servicedeskReportsSatisfactionTimeScaleRouteEntry = createEntry(
	servicedeskReportsSatisfactionTimeScaleRoute,
	() => ({
		group: 'servicedesk',
		perfMetricKey: 'service-management.reports.satisfaction',
		component: SatisfactionReports,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsSatisfaction],
	}),
);
