/**
 * @generated SignedSource<<77de2caa00c4513223c7b713bf724e5a>>
 * @relayHash a2b6adfd1ec94da5c71c412634dec345
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1d84d0c28258d14a680ab0152bc4d8da9f3b50260fa20b67f6cd4c7587391ac3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarEmbedQuery } from './src_jiraBusinessCalendarEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1d84d0c28258d14a680ab0152bc4d8da9f3b50260fa20b67f6cd4c7587391ac3",
    "metadata": {},
    "name": "src_jiraBusinessCalendarEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
