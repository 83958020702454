import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import { getChildIssuesLimit, getChildIssuesIssueLimitUrls } from './selectors.tsx';
import type { IssueContext, State } from './types.tsx';
import { getViewModeFromLocalStorage } from './utils.tsx';

export type Actions = typeof actions;

export const initialState: IssueContext = {
	childIssuesLimit: undefined,
	childIssuesIssueLimitUrls: undefined,
	issueKey: toIssueKey(''),
	isArchived: false,
	isClassifyEditEnabled: false,
	isModal: false,
	maxTokenLength: 0,
	isLoadedWithPage: false,
	rapidViewMap: undefined,
	estimationStatistic: undefined,
	isLoading: false,
	// initialise viewMode with the value stored in local storage
	viewMode: getViewModeFromLocalStorage(),
	analyticsSource: undefined,
	backButtonUrl: undefined,
	issueNavigatorData: undefined,
	boardId: undefined,
	boardType: undefined,
	isSpaEnabled: false,
	loginRedirectUrl: undefined,
	onIssueKeyChange: undefined,
	rapidViewId: undefined,
	viewScreenId: undefined,
	isAiOptInEnabled: false,
	isAiEnabledForIssue: false,
} as const;

export const store = createStore<State, Actions>({
	name: 'issue-context',
	initialState,
	actions,
});

const getIssueContext = (state: State) => state;
const getIsModal = (state: State) => state.isModal;
const getIssueKey = (state: State) => state.issueKey;
const getIsArchived = (state: State) => state.isArchived;
const getIsClassifyEditEnabled = (state: State) => state.isClassifyEditEnabled;
const getIssueId = (state: State) => state.issueId;
// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
const getAnalyticsSource = (state) =>
	state.analyticsSource !== undefined ? state.analyticsSource : undefined;
const getRapidViewMap = (state: State) => state.rapidViewMap;
const getMaxTokenLength = (state: State) => state.maxTokenLength;
const getIsLoadedWithPage = (state: State) => state.isLoadedWithPage;
const getEstimationStatistic = (state: State) => state.estimationStatistic;
const getIsLoading = (state: State) => state.isLoading;
const getViewMode = (state: State) => state.viewMode;
const getBackButtonUrl = (state: State) => state.backButtonUrl;
const getIssueNavigatorData = (state: State) => state.issueNavigatorData;
const getBoardId = (state: State) => state.boardId;
const getBoardType = (state: State) => state.boardType;
const getIsSpaEnabled = (state: State) => state.isSpaEnabled;
const getLoginRedirectUrl = (state: State) => state.loginRedirectUrl;
const getOnIssueKeyChange = (state: State) => state.onIssueKeyChange;
const getRapidViewId = (state: State) => state.rapidViewId;
const getViewScreenId = (state: State) => state.viewScreenId;
const getIsAiOptInEnabled = (state: State) => Boolean(state.isAiOptInEnabled);
const getisAiEnabledForIssue = (state: State) => Boolean(state.isAiEnabledForIssue);

export const useIssueContextStore = createHook(store, {
	selector: getIssueContext,
});

export const useIssueContextStoreChildIssuesLimit = createHook(store, {
	selector: getChildIssuesLimit,
});

export const useIssueContextStoreChildIssuesIssueLimitUrls = createHook(store, {
	selector: getChildIssuesIssueLimitUrls,
});

export const useIssueContextStoreIssueKey = createHook(store, {
	selector: getIssueKey,
});

export const useIssueContextStoreIssueId = createHook(store, {
	selector: getIssueId,
});

export const useIssueContextStoreIsArchived = createHook(store, {
	selector: getIsArchived,
});

export const useIssueContextStoreIsClassifyEditEnabled = createHook(store, {
	selector: getIsClassifyEditEnabled,
});

export const useIssueContextStoreIsModal = createHook(store, {
	selector: getIsModal,
});

export const useIssueContextStoreViewScreenId = createHook(store, {
	selector: getViewScreenId,
});

export const useIssueContextStoreAnalyticsSource = createHook(store, {
	selector: getAnalyticsSource,
});

export const useIssueContextRapidViewMap = createHook(store, {
	selector: getRapidViewMap,
});

export const useIssueContextStoreMaxTokenLength = createHook(store, {
	selector: getMaxTokenLength,
});

export const useIssueContextStoreIsLoadedWithPage = createHook(store, {
	selector: getIsLoadedWithPage,
});

export const useIssueContextStoreEstimationStatistic = createHook(store, {
	selector: getEstimationStatistic,
});

export const useIssueContextStoreIsLoading = createHook(store, {
	selector: getIsLoading,
});

export const useIssueContextStoreViewMode = createHook(store, {
	selector: getViewMode,
});

export const useIssueContextStoreBackButtonUrl = createHook(store, {
	selector: getBackButtonUrl,
});

export const useIssueContextStoreIssueNavigatorData = createHook(store, {
	selector: getIssueNavigatorData,
});

export const useIssueContextStoreBoardId = createHook(store, {
	selector: getBoardId,
});

export const useIssueContextStoreBoardType = createHook(store, {
	selector: getBoardType,
});

export const useIssueContextStoreIsSpaEnabled = createHook(store, {
	selector: getIsSpaEnabled,
});

export const useIssueContextStoreLoginRedirctUrl = createHook(store, {
	selector: getLoginRedirectUrl,
});

export const useIssueContextStoreOnIssueKeyChange = createHook(store, {
	selector: getOnIssueKeyChange,
});

export const useIssueContextStoreRapidViewId = createHook(store, {
	selector: getRapidViewId,
});

export const useIssueContextIsAiOptInEnabled = createHook(store, {
	selector: getIsAiOptInEnabled,
});

export const useIssueContextIsAiEnabledForIssue = createHook(store, {
	selector: getisAiEnabledForIssue,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IssueContextContainer = createContainer<State, Actions, Record<any, any>>(store);

export const useIssueContextStoreActions = createHook(store, {
	selector: null,
});
