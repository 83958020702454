import { useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getProjectRestoreUrl } from './utils.tsx';

type Props = {
	baseUrl: string;
	projectId: number | string;
};

type ProjectRestoreMethodProps = {
	onSuccess: () => void;
	onFailure: (arg1: Error) => void;
};

export const useProjectRestoreService = ({
	baseUrl,
	projectId,
}: Props): [boolean, (arg1: ProjectRestoreMethodProps) => void] => {
	const [loading, setLoading] = useState<boolean>(false);

	const restoreProject = ({ onSuccess, onFailure }: ProjectRestoreMethodProps) => {
		const url = getProjectRestoreUrl(baseUrl, projectId);
		setLoading(true);

		performPostRequest(url)
			.then(() => {
				setLoading(false);
				onSuccess();
			})
			.catch((error) => {
				if (
					!(error.statusCode === 409 && fg('hix-6266_-_no_error_fired_for_409_project_restore'))
				) {
					fireErrorAnalytics({
						meta: {
							id: 'useProjectRestoreService',
							packageName: 'jiraProjectRestoreModal',
						},
						error,
						attributes: {
							projectId,
							errorStatusCode: error.statusCode,
						},
					});
				}
				setLoading(false);
				onFailure(error);
			});
	};
	return [loading, restoreProject];
};
