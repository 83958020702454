// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type State,
	createTitleFromTemplate,
	type DocumentTitleContainerProps,
	checkDocumentTitle,
} from '../../utils.tsx';

const generateTitle = (title: string, titleSuffix: string | null) => {
	const titleTemplate = titleSuffix ? `%s - ${titleSuffix}` : '%s - Jira';
	const newTitle = createTitleFromTemplate(title, titleTemplate);
	return newTitle;
};

/**
 * This action is used to update the title of the document
 * This action also includes the `checkDocumentTitle` check (so we only update the title if it's the latest title in the context list)
 * By doing this we avoid requiring consumers of updateTitle to require listening to contextArray state
 */
export const updateTitle =
	(title: string, context: symbol): Action<State, DocumentTitleContainerProps> =>
	({ setState, getState }, { titleSuffix }) => {
		const state = getState();
		const contextItem = state.contextArray.find((item) => {
			if (typeof item === 'object') {
				return item.symbol === context;
			}
			return false;
		});

		// If there is no contextItem, it means that the component has been unmounted
		// And we'll just use the last used title from the contextArray
		if (!contextItem && state.contextArray.length && fg('jira-concurrent-document-title')) {
			const lastContextItem = state.contextArray[state.contextArray.length - 1];
			let newTitle = '';
			// This will always be an object but we have to check it to make TS happy
			if (typeof lastContextItem === 'object') {
				newTitle = generateTitle(lastContextItem.title, titleSuffix);
			}
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.title = newTitle;

			setState({
				title: newTitle,
			});

			return;
		}

		if (checkDocumentTitle(context, state.contextArray)) {
			const newTitle = generateTitle(title, titleSuffix);
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.title = newTitle;

			setState({
				title: newTitle,
			});
		}
	};
