import React, { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { OperationsMenuForLanding$key } from '@atlassian/jira-relay/src/__generated__/OperationsMenuForLanding.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { AlertsMenuItemContent } from './alerts-content/alerts-menu-item/AlertsMenuItemContent.tsx';
import { OperationsExpandableMenuItem } from './operations-expandable-menu-item/index.tsx';
import { AlertsMenuItemContentNew } from './alerts-content/alerts-menu-item/AlertsMenuItemContentNew.tsx';
import { useAlertsPopoutStore } from './operations-expandable-menu-item/action-buttons/useAlertsPopoutStore.tsx';

type OperationsMenuForLandingProps = {
	isExpanded: boolean;
	queryRef: OperationsMenuForLanding$key;
};

export const OperationsMenuForLanding = ({
	isExpanded,
	queryRef,
}: OperationsMenuForLandingProps) => {
	const data = useFragment<OperationsMenuForLanding$key>(
		graphql`
			fragment OperationsMenuForLanding on Query {
				...AlertsMenuItemContentNew
				...AlertsMenuItemContent
			}
		`,
		queryRef,
	);

	const {
		state: { isPoppedOut },
		setContent,
	} = useAlertsPopoutStore();

	useEffect(() => {
		if (isPoppedOut) {
			setContent(<AlertsMenuItemContent queryRef={data} />);
		}

		return () => {
			setContent(null);
		};
	}, [data, isPoppedOut, setContent]);

	return (
		<OperationsExpandableMenuItem
			isExpanded={isExpanded}
			AlertsMenuItemContent={
				fg('operations_nav4_remaining_items') ? (
					<UFOSegment name="nav4-sidebar-operations-landing">
						<AlertsMenuItemContentNew queryRef={data} />
					</UFOSegment>
				) : (
					<AlertsMenuItemContent queryRef={data} />
				)
			}
		/>
	);
};
