/**
 * @generated SignedSource<<946ceba807abd648cda43ef177a2bcb9>>
 * @relayHash fa32850122c7d6c7cb0342cc62aa4562
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 95dc01d4ad2ba07eec321bd73156fdef6778cc1fd9ed4fd46ec39b16b5a45fcf

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "95dc01d4ad2ba07eec321bd73156fdef6778cc1fd9ed4fd46ec39b16b5a45fcf",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
