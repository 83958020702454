/**
 * @generated SignedSource<<d35800e937cb2e46de55486c256f933a>>
 * @relayHash be931dc0c604a66f9d38de7a73d831b1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8b0d2d2a91bd794d7e5f3219657b6c356de5e3d769077be59a9c5c4818608b57

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentLandingQuery } from './srcVirtualAgentLandingQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentLandingQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8b0d2d2a91bd794d7e5f3219657b6c356de5e3d769077be59a9c5c4818608b57",
    "metadata": {},
    "name": "srcVirtualAgentLandingQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;
