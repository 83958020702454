/**
 * @generated SignedSource<<2c0776ec3e9f6a74e53887f11a831651>>
 * @relayHash 51efb3864f270fc508c3c4676be5d9f6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8b8af805c4172b0ed36c74fe68563d0a938d57e1cf91158c7fa6cbe92c6dc022

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSummarySoftwareQuery } from './src_jiraSummarySoftwareQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSummarySoftwareQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8b8af805c4172b0ed36c74fe68563d0a938d57e1cf91158c7fa6cbe92c6dc022",
    "metadata": {},
    "name": "src_jiraSummarySoftwareQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
