// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'create-project-permissions',
	initialState: {
		traits: [],
		siteTraits: [],
		isFetching: false,
		fetchError: null,
		promise: null,
		hasFetchedOnce: false,
		hasSuccessOnce: false,
	},
	actions,
});

export const usePersonalizationProvider = createHook(store);
