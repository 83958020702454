import React, { useState, useCallback } from 'react';
import ArchiveBoxIcon from '@atlaskit/icon/core/migration/archive-box--archive';
import CopyIcon from '@atlaskit/icon/core/migration/copy';
import DeleteIcon from '@atlaskit/icon/core/migration/delete--trash';
import FlagIcon from '@atlaskit/icon/core/migration/flag--emoji-flags';
import QuestionCircleIcon from '@atlaskit/icon/core/migration/question-circle';
import SettingsIcon from '@atlaskit/icon/core/migration/settings';
import StarStarredIcon from '@atlaskit/icon/core/migration/star-starred--star-filled';
import StarUnstarredIcon from '@atlaskit/icon/core/migration/star-unstarred--star';
import { MenuGroup, Section, ButtonItem, LinkItem } from '@atlaskit/menu';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JiraPlanAri } from '@atlassian/ari/jira/plan';
import { fg } from '@atlassian/jira-feature-gating';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { PlanSidebarNavInfo } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/plan-sidebar-navigation/types.tsx';
import { usePlan } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/plan/index.tsx';
import {
	LEVEL_ONE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { CttSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-spotlights/index.tsx';
import { Onboarding } from '@atlassian/jira-portfolio-3-onboarding/src/ui/onboarding/index.tsx';
import { SpotlightTarget } from '@atlassian/jira-portfolio-3-onboarding/src/ui/spotlight-target/index.tsx';
import { useRedirectToSpaPlanPage } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/redirect-to-spa-plan-page/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { PLAN_MENU_SUBJECT_ID, TRASH, ARCHIVE, DUPLICATE } from '../constants.tsx';
import {
	usePlanActionMenuStore,
	PlanActionMenuStoreContainer,
} from '../controllers/menu-state/index.tsx';
import { useSetFavourite } from '../services/set-favourite/index.tsx';
import { ArchivePlanModal } from './archive-modal/index.tsx';
import { DuplicatePlanModal } from './duplicate-modal/index.tsx';
import messages from './messages.tsx';
import { MoreNavMenuButton } from './more-nav-menu-button/main.tsx';
import { TrashPlanModal } from './trash-modal/index.tsx';
import { isPlanAndScenarioMatchingUrl } from './utils.tsx';

export type PlanActionMenuDropdownProps = {
	planId: number;
	scenarioId: number;
	planTitle: string;
	isReadOnly: boolean;
	isArchived: boolean;
	isStarred: boolean;
	isUsedForSidebarNav?: boolean;
	favouriteConnectionId?: string;
};

type ModalState = typeof DUPLICATE | typeof TRASH | typeof ARCHIVE | undefined;

export const PlanActionMenu = (props: PlanActionMenuDropdownProps) => (
	<PlanActionMenuStoreContainer
		scope={props.isUsedForSidebarNav ? `sidebar-plan-${props.planId}` : 'main-plan'}
	>
		<PlanActionMenuInternal {...props} />
	</PlanActionMenuStoreContainer>
);

export const PlanActionMenuItems = ({
	isStarred,
	isArchived,
	isReadOnly,
	onChangeFavouriteClicked,
	onDuplicateClicked,
	onSettingsMenuItemClicked,
	onArchiveRestoreClicked,
	onDeleteClicked,
	onHelpLinkClicked,
	onTakeTourClicked,
}: {
	isStarred: boolean;
	isReadOnly: boolean;
	isArchived: boolean;
	onChangeFavouriteClicked: () => void;
	onDuplicateClicked: () => void;
	onSettingsMenuItemClicked: () => void;
	onArchiveRestoreClicked: () => void;
	onDeleteClicked: () => void;
	onHelpLinkClicked: () => void;
	onTakeTourClicked: () => void;
}) => {
	const { formatMessage } = useIntl();

	return (
		<MenuGroup testId="jet-plan-action-menu.ui.menu-group">
			{fg('jet-fix-plan-action-menu') ? (
				<Section isList>
					{!isArchived && (
						<ButtonItem
							onClick={onChangeFavouriteClicked}
							iconBefore={
								isStarred ? (
									<StarStarredIcon label="" color={token('color.icon.accent.yellow')} />
								) : (
									<StarUnstarredIcon label="" color="currentColor" />
								)
							}
						>
							<Text color="color.text">
								{isStarred ? formatMessage(messages.unstarPlan) : formatMessage(messages.starPlan)}
							</Text>
						</ButtonItem>
					)}
					{!isReadOnly && (
						<>
							<ButtonItem
								onClick={onDuplicateClicked}
								iconBefore={<CopyIcon label="" color="currentColor" />}
							>
								<Text color="color.text">{formatMessage(messages.duplicate)}</Text>
							</ButtonItem>
							<SpotlightTarget name={CttSpotlights.Settings} delayOn>
								<ButtonItem
									onClick={onSettingsMenuItemClicked}
									iconBefore={<SettingsIcon label="" color="currentColor" />}
									testId="jet-plan-action-menu.ui.button-item"
								>
									<Text color="color.text">{formatMessage(messages.planSettings)}</Text>
								</ButtonItem>
							</SpotlightTarget>
							<Section isList hasSeparator>
								<ButtonItem
									onClick={onArchiveRestoreClicked}
									iconBefore={<ArchiveBoxIcon label="" color="currentColor" />}
								>
									<Text color="color.text">
										{isArchived ? formatMessage(messages.restore) : formatMessage(messages.archive)}
									</Text>
								</ButtonItem>
								<ButtonItem
									onClick={onDeleteClicked}
									iconBefore={<DeleteIcon label="" color={token('color.icon.danger')} />}
								>
									<Text color="color.text.danger">{formatMessage(messages.trash)}</Text>
								</ButtonItem>
							</Section>
						</>
					)}
				</Section>
			) : (
				!isReadOnly && (
					<>
						<Section isList>
							{!isArchived && (
								<ButtonItem
									onClick={onChangeFavouriteClicked}
									iconBefore={
										isStarred ? (
											<StarStarredIcon label="" color={token('color.icon.accent.yellow')} />
										) : (
											<StarUnstarredIcon label="" color="currentColor" />
										)
									}
								>
									<Text color="color.text">
										{isStarred
											? formatMessage(messages.unstarPlan)
											: formatMessage(messages.starPlan)}
									</Text>
								</ButtonItem>
							)}
							<ButtonItem
								onClick={onDuplicateClicked}
								iconBefore={<CopyIcon label="" color="currentColor" />}
							>
								<Text color="color.text">{formatMessage(messages.duplicate)}</Text>
							</ButtonItem>
							<SpotlightTarget name={CttSpotlights.Settings} delayOn>
								<ButtonItem
									onClick={onSettingsMenuItemClicked}
									iconBefore={<SettingsIcon label="" color="currentColor" />}
									testId="jet-plan-action-menu.ui.button-item"
								>
									<Text color="color.text">{formatMessage(messages.planSettings)}</Text>
								</ButtonItem>
							</SpotlightTarget>
						</Section>
						<Section isList hasSeparator>
							<ButtonItem
								onClick={onArchiveRestoreClicked}
								iconBefore={<ArchiveBoxIcon label="" color="currentColor" />}
							>
								<Text color="color.text">
									{isArchived ? formatMessage(messages.restore) : formatMessage(messages.archive)}
								</Text>
							</ButtonItem>
							<ButtonItem
								onClick={onDeleteClicked}
								iconBefore={<DeleteIcon label="" color={token('color.icon.danger')} />}
							>
								<Text color="color.text.danger">{formatMessage(messages.trash)}</Text>
							</ButtonItem>
						</Section>
					</>
				)
			)}
			<Section isList hasSeparator>
				<LinkItem
					onClick={onHelpLinkClicked}
					target="_blank"
					iconBefore={<QuestionCircleIcon label="" color="currentColor" />}
				>
					<Text color="color.text">{formatMessage(messages.help)}</Text>
				</LinkItem>
				<ButtonItem
					onClick={onTakeTourClicked}
					iconBefore={
						fg('plan_take_a_tour_icon_spacing') ? (
							<FlagIcon label="" color="currentColor" />
						) : (
							<FlagIcon label="" color="currentColor" spacing="spacious" />
						)
					}
				>
					<Text color="color.text">{formatMessage(messages.takeTour)}</Text>
				</ButtonItem>
			</Section>
		</MenuGroup>
	);
};

const PlanActionMenuInternal = ({
	planId,
	scenarioId,
	planTitle,
	isReadOnly,
	isArchived,
	isStarred,
	isUsedForSidebarNav = false,
	favouriteConnectionId,
}: PlanActionMenuDropdownProps) => {
	// eslint-disable-next-line jira/router/no-useRouter-in-components
	const [{ match }] = useRouter();
	const [{ isOpen }, { toggleMenu, closeMenu }] = usePlanActionMenuStore();

	const redirectToSpaPlanPage = useRedirectToSpaPlanPage();
	const [modalState, setModalState] = useState<ModalState>(undefined);
	const resetModalState = () => setModalState(undefined);

	const [onboarding, setOnboarding] = useState<string>();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const cloudId = useCloudId();
	const activationId = useActivationId();
	const { loading, data, update: updateResource } = usePlan();
	const { setFavourite } = useSetFavourite();

	const isThePlanAndScenarioSameAsInTheUrl = match.params
		? isPlanAndScenarioMatchingUrl({
				urlPlanId: match.params.planId,
				urlScenarioId: match.params.scenarioId,
				planId,
				scenarioId,
			})
		: false;

	const updateUsePlanResource = useCallback(
		(newState: Partial<PlanSidebarNavInfo>) => {
			if (isThePlanAndScenarioSameAsInTheUrl && data) {
				updateResource((oldState: PlanSidebarNavInfo | null) => ({
					...(oldState || data),
					...newState,
				}));
			}
		},
		[updateResource, isThePlanAndScenarioSameAsInTheUrl, data],
	);

	const setPlanArchiveStatus = useCallback(() => {
		if (data) {
			const newStatus = data.planStatus === 'ARCHIVED' ? 'ACTIVE' : 'ARCHIVED';
			updateUsePlanResource({ planStatus: newStatus });
		}
	}, [updateUsePlanResource, data]);

	const toggleFavourite = useCallback(() => {
		const entityId = JiraPlanAri.create({
			siteId: cloudId,
			activationId,
			planId: String(planId),
		}).toString();

		const onErrorCallback = () => {
			// If setFavourite API call fails, we want to revert the usePlan resource.
			updateUsePlanResource({ isFavourite: isStarred });
		};

		setFavourite({
			entityId,
			favouriteConnectionId,
			planTitle: data?.title || '',
			isFavourite: !isStarred,
			onErrorCallback,
		});
		updateUsePlanResource({ isFavourite: !isStarred });
	}, [
		data,
		planId,
		cloudId,
		activationId,
		isStarred,
		favouriteConnectionId,
		updateUsePlanResource,
		setFavourite,
	]);
	const openInProductHelp = useHelpPanelLink();

	const onPlanMenuButtonClicked = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: PLAN_MENU_SUBJECT_ID,
			attributes: {
				itemId: 'meatballDropdown',
				componentType: 'fixedItemButton',
				level: LEVEL_ONE,
			},
		});
	}, [createAnalyticsEvent]);

	const onHelpLinkClicked = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdown',
			actionSubjectId: PLAN_MENU_SUBJECT_ID,
			attributes: {
				itemId: 'openHelpPanel',
				componentType: 'fixedItemDropdownAction',
				parentItemId: 'plans',
				level: LEVEL_TWO,
			},
		});
		openInProductHelp('');
		closeMenu();
	}, [createAnalyticsEvent, closeMenu, openInProductHelp]);

	const onTakeTourClicked = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdown',
			actionSubjectId: PLAN_MENU_SUBJECT_ID,
			attributes: {
				itemId: 'takePlanTour',
				componentType: 'fixedItemDropdownAction',
				parentItemId: 'plans',
			},
		});
		redirectToSpaPlanPage('/timeline', {
			planId: String(planId),
			scenarioId: String(scenarioId),
		});
		setOnboarding(Math.random().toString(36).substring(2));
		closeMenu();
	}, [createAnalyticsEvent, redirectToSpaPlanPage, closeMenu, planId, scenarioId]);

	const onDuplicateClicked = useCallback(() => {
		setModalState(DUPLICATE);
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdown',
			actionSubjectId: PLAN_MENU_SUBJECT_ID,
			attributes: {
				itemId: 'duplicatePlan',
				componentType: 'fixedItemDropdownAction',
				parentItemId: 'plans',
				level: LEVEL_TWO,
			},
		});
		closeMenu();
	}, [createAnalyticsEvent, closeMenu]);

	const onDeleteClicked = useCallback(() => {
		setModalState(TRASH);
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdown',
			actionSubjectId: PLAN_MENU_SUBJECT_ID,
			attributes: {
				itemId: 'movePlanToTrash',
				componentType: 'fixedItemDropdownAction',
				parentItemId: 'plans',
				level: LEVEL_TWO,
			},
		});
		closeMenu();
	}, [createAnalyticsEvent, closeMenu]);

	const onArchiveRestoreClicked = useCallback(() => {
		setModalState(ARCHIVE);
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdown',
			actionSubjectId: PLAN_MENU_SUBJECT_ID,
			attributes: {
				itemId: isArchived ? 'restorePlan' : 'archivePlan',
				componentType: 'fixedItemDropdownAction',
				parentItemId: 'plans',
				level: LEVEL_TWO,
			},
		});
		closeMenu();
	}, [createAnalyticsEvent, isArchived, closeMenu]);

	const onChangeFavouriteClicked = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdown',
			actionSubjectId: PLAN_MENU_SUBJECT_ID,
			attributes: {
				itemId: 'favoritePlan',
				componentType: 'fixedItemDropdownAction',
				parentItemId: 'plans',
				level: LEVEL_TWO,
			},
		});
		toggleFavourite();
		closeMenu();
	}, [createAnalyticsEvent, toggleFavourite, closeMenu]);

	const onSettingsMenuItemClicked = useCallback(() => {
		redirectToSpaPlanPage('/settings/scheduling', {
			planId: String(planId),
			scenarioId: String(scenarioId),
		});
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdown',
			actionSubjectId: PLAN_MENU_SUBJECT_ID,
			attributes: {
				itemId: 'openPlanSettings',
				componentType: 'fixedItemDropdownAction',
				parentItemId: 'plans',
				level: LEVEL_TWO,
			},
		});
		closeMenu();
	}, [createAnalyticsEvent, planId, redirectToSpaPlanPage, scenarioId, closeMenu]);

	const closeOnboarding = useCallback(
		(options?: { silent: boolean }) => !options?.silent && setOnboarding(undefined),
		[],
	);

	const MenuItems = useCallback(
		() => (
			<PlanActionMenuItems
				isStarred={isStarred}
				isArchived={isArchived}
				isReadOnly={isReadOnly}
				onChangeFavouriteClicked={onChangeFavouriteClicked}
				onDuplicateClicked={onDuplicateClicked}
				onSettingsMenuItemClicked={onSettingsMenuItemClicked}
				onArchiveRestoreClicked={onArchiveRestoreClicked}
				onDeleteClicked={onDeleteClicked}
				onHelpLinkClicked={onHelpLinkClicked}
				onTakeTourClicked={onTakeTourClicked}
			/>
		),
		[
			isStarred,
			onChangeFavouriteClicked,
			onDuplicateClicked,
			onSettingsMenuItemClicked,
			onArchiveRestoreClicked,
			onDeleteClicked,
			onHelpLinkClicked,
			onTakeTourClicked,
			isArchived,
			isReadOnly,
		],
	);

	if (isThePlanAndScenarioSameAsInTheUrl && (loading || !data)) return;

	return (
		<>
			<MoreNavMenuButton
				isOpen={isOpen}
				MenuItems={MenuItems}
				onClick={() => {
					if (!isOpen) {
						onPlanMenuButtonClicked();
					}
					toggleMenu();
				}}
				onCloseMenu={closeMenu}
				isUsedForSidebarNav={isUsedForSidebarNav}
			/>
			{modalState === DUPLICATE && (
				<DuplicatePlanModal
					planId={Number(planId)}
					planTitle={planTitle}
					setClosed={resetModalState}
				/>
			)}
			{modalState === TRASH && (
				<TrashPlanModal planId={Number(planId)} setClosed={resetModalState} planTitle={planTitle} />
			)}
			{modalState === ARCHIVE && (
				<ArchivePlanModal
					planId={Number(planId)}
					scenarioId={Number(scenarioId)}
					planTitle={planTitle}
					isArchived={isArchived}
					setClosed={resetModalState}
					setPlanArchiveStatus={setPlanArchiveStatus}
				/>
			)}
			{onboarding && <Onboarding visible key={onboarding} onClose={closeOnboarding} />}
		</>
	);
};
