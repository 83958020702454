/**
 * @generated SignedSource<<b2791ce93d3a0a0e17fee486ad965601>>
 * @relayHash 5c749d389bf268ee9226560ad568adcf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9283dbd6d43bd23893ba0d1fb905c533d07635b370710c93db0ea67cb8b41e06

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { boardFilterRefinementQuery } from './boardFilterRefinementQuery.graphql';

const node: PreloadableConcreteRequest<boardFilterRefinementQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "9283dbd6d43bd23893ba0d1fb905c533d07635b370710c93db0ea67cb8b41e06",
    "metadata": {},
    "name": "boardFilterRefinementQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
