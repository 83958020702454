import React from 'react';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { piCreateIssueEmbedRoute } from '@atlassian/jira-router-routes-product-integrations-routes/src/piCreateIssueEmbedRoute.tsx';
import type { CreateIssueEmbed } from '@atlassian/jira-gmail-embed/src/ui/create-issue/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPICreateIssueEmbed = lazyForPaint<typeof CreateIssueEmbed>(
	() =>
		import(
			/* webpackChunkName: "async-pi-issue-create-embed", jiraSpaEntry: "async-pi-issue-create-embed" */ '@atlassian/jira-gmail-embed/src/ui/create-issue/index.tsx'
		).then((module) => module.CreateIssueEmbed),
	{ ssr: false },
);

const PIIssueCreateEmbedLazy = () => {
	return (
		<AuthenticationCheck>
			<LazyPage
				Page={LazyPICreateIssueEmbed}
				pageId="spa-apps/pi-issue-create-embed"
				shouldShowSpinner
			/>
		</AuthenticationCheck>
	);
};

export const piCreateIssueEmbedRouteEntry = createEntry(piCreateIssueEmbedRoute, () => ({
	group: 'issue',
	component: PIIssueCreateEmbedLazy,
	resources: [themePreferenceResource],
	layout: chromelessLayout,
	forPaint: [LazyPICreateIssueEmbed],
}));
