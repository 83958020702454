import { Children, isValidElement, type ReactElement, type ReactNode } from 'react';

/**
 * Given a react element, recursively traverse through the tree to extract the text
 *
 * For example:
 *
 * <div>
 * 		Hey <Box as="span" testId="maaate">mate</Box>!
 * </div>
 *
 * Would become `Hey mate!`
 */
export function extractTextFromReactElement(element: ReactElement): string {
	let result = '';

	function traverse(node: ReactNode): void {
		if (typeof node === 'string' || typeof node === 'number') {
			result += node.toString();
		} else if (isValidElement(node)) {
			Children.forEach(node.props.children, (child) => {
				traverse(child);
			});
		}
	}

	traverse(element);

	return result;
}
