import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const customerServiceInboxPageEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-software-customer-service-inbox-page-entrypoint" */ './src/ui'
			),
	),
	getPreloadProps: () => {
		return {};
	},
});
