import { fg } from '@atlassian/jira-feature-gating';
import type { CHRReporterSummary, CHRReporterSummaryEntry } from '../../types.tsx';
import { CHRSummary } from '../chrsummary/index.tsx';

export class CHRReporter {
	preloaded = new CHRSummary();

	all = new CHRSummary();

	ssrMark: number | null = null;

	constructor(ssrMark: number | null) {
		this.ssrMark = ssrMark;
	}

	isPreloaded(item: PerformanceResourceTiming) {
		const isPreloadedByLink = item.initiatorType === 'link';
		const isPreloadedByPreloadModule =
			item.initiatorType === 'other' &&
			item.name?.endsWith('.js') &&
			fg('ufo_support_other_resource_type_js');

		const isBeforeSSR = this.ssrMark === null || item.startTime < this.ssrMark;

		return (isPreloadedByLink || isPreloadedByPreloadModule) && isBeforeSSR;
	}

	add(item: PerformanceResourceTiming) {
		if (item.encodedBodySize === 0) {
			return;
		}

		if (this.isPreloaded(item)) {
			this.preloaded.add(item);
		}
		this.all.add(item);
	}

	static round(n: number) {
		return Math.round(n * 10000) / 10000;
	}

	private makePayload(summary: CHRSummary): CHRReporterSummaryEntry {
		const bundlesLoadType = {
			mem: CHRReporter.round(summary.bundles[CHRSummary.MEMORY_KEY] / summary.bundlesCount),
			disk: CHRReporter.round(summary.bundles[CHRSummary.DISK_KEY] / summary.bundlesCount),
			net: CHRReporter.round(summary.bundles[CHRSummary.NETWORK_KEY] / summary.bundlesCount),
		};

		const bundlesRatio = CHRReporter.round(
			(summary.bundles[CHRSummary.MEMORY_KEY] + summary.bundles[CHRSummary.DISK_KEY]) /
				summary.bundlesCount,
		);

		const sizeLoadType = {
			mem: CHRReporter.round(summary.size[CHRSummary.MEMORY_KEY] / summary.sizeTotal),
			disk: CHRReporter.round(summary.size[CHRSummary.DISK_KEY] / summary.sizeTotal),
			net: CHRReporter.round(summary.size[CHRSummary.NETWORK_KEY] / summary.sizeTotal),
		};

		const sizeRatio = CHRReporter.round(
			(summary.size[CHRSummary.MEMORY_KEY] + summary.size[CHRSummary.DISK_KEY]) / summary.sizeTotal,
		);

		return {
			bundles: {
				ratio: bundlesRatio,
				count: summary.bundlesCount,
				loadType: bundlesLoadType,
			},
			size: {
				ratio: sizeRatio,
				total: summary.sizeTotal,
				loadType: sizeLoadType,
			},
		};
	}

	get(): CHRReporterSummary | null {
		if (this.all.bundlesCount === 0) {
			return null;
		}

		return {
			preloaded: this.makePayload(this.preloaded),
			all: this.makePayload(this.all),
		};
	}
}
