import React from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyNav4PromptBanner = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-atlassian-nav4-prompt-banner" */ './Nav4PromptBanner.tsx'
	).then(({ Nav4PromptBanner }) => Nav4PromptBanner),
);

export const AsyncNav4PromptBanner = ({ onDismiss }: { onDismiss: (value: boolean) => void }) => {
	return (
		<Placeholder name="async-nav4-prompt-banner" fallback={null}>
			<LazyNav4PromptBanner onDismiss={onDismiss} />
		</Placeholder>
	);
};
