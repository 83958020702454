import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskNotificationsEmailRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskNotificationsEmailRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import EmailNotifications, {
	LazyProjectSettingsNotificationEmail,
} from './ui/notification-email/index.tsx';

export const projectSettingsServicedeskNotificationsEmailRouteEntry = createEntry(
	projectSettingsServicedeskNotificationsEmailRoute,
	() => ({
		group: 'project-settings-servicedesk',
		component: EmailNotifications,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyProjectSettingsNotificationEmail],
	}),
);
