import {
	createResource,
	type RouterDataContext,
	type ResourceStoreContext,
} from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/import-whitelist
import {
	INCIDENT_MANAGEMENT_FEATURE,
	SERVICE_REQUEST_FEATURE,
	DISABLED,
} from '@atlassian/jira-servicedesk-common/src/service-project-features/constants.tsx';
import { checkLicense } from '@atlassian/jira-spa-apps-common/src/interceptors/license/index.tsx';
import type { AIContextServicePanelResourceResponse } from './service/types.tsx';
import { getServiceSuggestionData } from './service/endpoints/index.tsx';
import { getIncidentSuggestionEndpoint } from './ops/endpoints/index.tsx';
import { getIssueDetails } from './issue-details/index.tsx';
import type { IncidentSuggestion } from './ops/common/types.tsx';
import { getFeatureState } from './feature-state/index.tsx';

const SUPPORTED_PRACTICES = new Set<string | null | undefined>([
	SERVICE_REQUEST_FEATURE,
	INCIDENT_MANAGEMENT_FEATURE,
]);

export const aiContextPanelResource = createResource<
	AIContextServicePanelResourceResponse | IncidentSuggestion | null
>({
	type: 'AI-CONTEXT-PANEL-RESOURCE-TYPE',
	getKey: ({ match }) => {
		const issueKey = match.params.issueKey || '';
		return `aiContextPanelResource-${issueKey}`;
	},
	getData: async (
		routerDataContext: RouterDataContext,
		resourceStoreContext: ResourceStoreContext,
	) => {
		const { skipChecks, issueKey } = routerDataContext.match.params;
		let { issueId, practice, projectId } = routerDataContext.match.params;
		const { cloudId, locale } = resourceStoreContext.tenantContext;

		if (!issueKey) {
			return null;
		}

		if (skipChecks !== '1') {
			// The suggestions panel is available only on service-desk.
			// Hence filtering them out to reduce the number of calls to AGG and features APIs
			if (
				!checkLicense(resourceStoreContext.tenantContext, 'servicedesk') ||
				!fg('jsm_issue_view_ai_context')
			) {
				return null;
			}

			const issueDetails = await getIssueDetails(issueKey, cloudId);
			const isAIEnabled = issueDetails.isAIEnabled;
			issueId = issueDetails.issueId;
			projectId = issueDetails.projectId;
			practice = issueDetails.practice;

			if (!isAIEnabled || !SUPPORTED_PRACTICES.has(practice)) {
				return null;
			}

			const featureState = await getFeatureState(routerDataContext, resourceStoreContext);
			const isAIContextPanelDisabled = featureState?.['jsm-ai-context']?.status === DISABLED;

			if (isAIContextPanelDisabled) {
				return null;
			}
		}

		if (!issueId) {
			throw new Error('AI context service panel resource cannot be loaded without an issueId');
		}

		if (practice === SERVICE_REQUEST_FEATURE) {
			return getServiceSuggestionData(issueId, cloudId);
		}
		if (practice === INCIDENT_MANAGEMENT_FEATURE && fg('jsm_issue_view_ai_context_ops')) {
			if (!projectId) {
				throw new Error('AI context ops panel resource cannot be loaded without a projectId');
			}

			return getIncidentSuggestionEndpoint({
				cloudId,
				issueId,
				projectId,
				locale,
			});
		}
		return null;
	},
	maxAge: 0,
	isBrowserOnly: true,
});
