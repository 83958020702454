import React, { useCallback, useMemo, useState } from 'react';
import SmartUserPicker, {
	type OnChange,
	type Props as SmartUserPickerProps,
} from '@atlaskit/smart-user-picker';
import TwoPeopleTeamIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/assets/team-two-people.svg';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { NO_TEAM_ID } from '../../common/constants.tsx';
import type { TeamValue, TriggerCreateTeamDialogCallback } from '../../common/types.tsx';
import messages from '../../messages.tsx';
import { CreateTeamOption } from './create-team-option/index.tsx';
import { TeamPickerEmptyState } from './team-picker-empty-state/index.tsx';

export type Props = {
	isClearable?: boolean;
	// `isDropdownMenuFixedAndLayered` should be true when we want to render TeamPickerEdit in modal dialog like GIC.
	isDropdownMenuFixedAndLayered?: boolean;
	isDisabled?: boolean;
	isInvalid?: boolean;
	autoFocus?: boolean;
	debounceTime?: number;
	defaultValue?: TeamValue;
	siteId: string;
	styles?: SmartUserPickerProps['styles'];
	placeholder?: string;
	width?: string | number;
	noValueText?: string;
	onChange: OnChange;
	createTeamDialogTrigger: TriggerCreateTeamDialogCallback;
	'aria-labelledby'?: string;
	isCompact?: boolean;
	hasNoTeamOption?: boolean;
};

// FIXME: These are overrides for @atlaskit/select@15.6 -> react-select@4.3.x
//        Once @atlaskit/select@15.6 resolution has been removed from package.json
//        these should be removed as well. See PTC-6238.
const stylesOverrides = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	valueContainer: (base: any) => ({ ...base, display: 'grid' }),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	placeholder: (base: any) => ({
		...base,
		position: undefined,
		top: undefined,
		left: undefined,
		transform: undefined,
	}),
};
export const TeamPickerEdit = (props: Props) => {
	const { formatMessage } = useIntl();
	const {
		siteId,
		defaultValue,
		isClearable = true,
		isDisabled = false,
		isInvalid = false,
		autoFocus = false,
		width = '100%',
		debounceTime = 300,
		onChange,
		placeholder = formatMessage(messages.placeholderTeamEditSelector),
		isDropdownMenuFixedAndLayered = false,
		styles,
		noValueText,
		createTeamDialogTrigger,
		isCompact = true,
		hasNoTeamOption = true,
		...fieldProps
	} = props;

	const [isCreateButtonFocused, setIsCreateButtonFocused] = useState(false);

	const { data: orgId } = useOrgId();

	const [hasTeams, setHasTeams] = useState(true);

	const formattedNoValueText = formatMessage(messages.defaultNoValueText);

	const [updatedPlaceholder, setUpdatedPlaceholder] = useState(placeholder);
	const [inputQuery, setInputQuery] = useState('');

	const finalStyles = {
		...defaultSelectStyles,
		...(styles || {}),
		...stylesOverrides,
	};

	const maybeAddNoTeamOption = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'users' implicitly has an 'any' type.
		(users, query: string) => {
			setHasTeams(!(users.length === 0 && query.length === 0));

			if (query === '' && !!defaultValue && hasNoTeamOption) {
				return [
					{
						avatarUrl: TwoPeopleTeamIllustration,
						id: NO_TEAM_ID,
						name: noValueText ?? formattedNoValueText,
						isVerified: false,
					},
				].concat(users);
			}
			setIsCreateButtonFocused(query.length > 0 && users.length === 0);

			return users;
		},
		[defaultValue, noValueText, formattedNoValueText, hasNoTeamOption],
	);

	const handleOnChange: OnChange = (newValue, actionType) => {
		if (Array.isArray(newValue)) return;

		if (onChange) {
			onChange(newValue?.id === NO_TEAM_ID ? null : newValue, actionType);
			setUpdatedPlaceholder(newValue?.id === NO_TEAM_ID ? formattedNoValueText : placeholder);
		}
	};

	const handleCreateTeam = useCallback(() => {
		createTeamDialogTrigger(inputQuery);
	}, [inputQuery, createTeamDialogTrigger]);

	const handleOnKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (isCreateButtonFocused && event.key === 'Enter') {
				handleCreateTeam();
			}
		},
		[isCreateButtonFocused, handleCreateTeam],
	);

	const handleOnInputChange = useCallback(
		(query?: string) => {
			setInputQuery(query || '');
		},
		[setInputQuery],
	);

	const footer = useMemo(
		() =>
			hasTeams && <CreateTeamOption isFocused={isCreateButtonFocused} onClick={handleCreateTeam} />,
		[hasTeams, handleCreateTeam, isCreateButtonFocused],
	);

	const noOptionsMessage = useMemo(
		() => (!hasTeams ? () => <TeamPickerEmptyState onActionClick={handleCreateTeam} /> : undefined),
		[hasTeams, handleCreateTeam],
	);

	const menuListStyle = useMemo(
		() =>
			!hasTeams
				? {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						menuList: (base: any) => ({
							...base,
							maxHeight: '350px',
						}),
					}
				: {},
		[hasTeams],
	);

	return (
		<SmartUserPicker
			// Setting width=100% will allow dropdown to size correctly
			width={width}
			autoFocus={autoFocus}
			appearance={isCompact ? 'compact' : 'normal'}
			debounceTime={debounceTime}
			fieldId="team"
			filterOptions={maybeAddNoTeamOption}
			footer={footer}
			includeGroups={false}
			includeTeams
			includeUsers={false}
			isClearable={isClearable}
			isMulti={false}
			isDisabled={isDisabled}
			isInvalid={isInvalid}
			productKey="jira"
			siteId={siteId}
			value={defaultValue}
			onChange={handleOnChange}
			orgId={orgId}
			placeholder={updatedPlaceholder}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			styles={{ ...finalStyles, ...menuListStyle }}
			onInputChange={handleOnInputChange}
			onKeyDown={handleOnKeyDown}
			noOptionsMessage={noOptionsMessage}
			ariaLabel={updatedPlaceholder}
			ariaLabelledBy={fieldProps['aria-labelledby']}
			placeholderAvatar="team"
		/>
	);
};

export const TeamPickerEditEntryPointContent = ({ props }: { props: Props }) => (
	<TeamPickerEdit {...props} />
);

export default TeamPickerEdit;
