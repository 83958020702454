import { useEffect, useRef } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, createActionsHook } from '@atlassian/react-sweet-state';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { initialCachedEndpointState } from '../../services/cached-endpoint/constants.tsx';
import type { UserSegmentationTeamTypes } from '../../utils/user-segmentation/index.tsx';
import { useIsBusinessProject } from '../is-business-project/index.tsx';
import { actions } from './actions.tsx';
import { ELIGIBLE_TEAM_TYPES_M2_FULL_NOTATION } from './constants.tsx';
import type {
	FetchOnboardingPersonalisation,
	OnboardingPersonalisationState,
	TailoredOnboardingPersonalisationM2Response,
} from './types.tsx';
import { isTailoredOnboardingM2ExperimentFlagEnabled } from './utils.tsx';

type Actions = typeof actions;

const store = createStore<OnboardingPersonalisationState, Actions>({
	name: 'jira-onboarding-personalisation',
	initialState: initialCachedEndpointState,
	actions,
});

export const usePersonalisationActions = createActionsHook(store);
export const useFetchOnboardingPersonalisationSweetState = createHook(store);

export const useCommonEligibilityConditionsForJtbdOnboarding = () => {
	const isSiteAdmin = useIsSiteAdmin();
	const { data: projectContextData } = useProjectContext();
	const isBusinessProject =
		projectContextData?.projectType === undefined || // letting undefined pass for non-project context usage, eg: onboarding
		projectContextData?.projectType === CORE_PROJECT; // limiting only to CORE for Tailired Onboarding M2 usage

	return fg('gups_personalisation_api_integration_improvements')
		? isBusinessProject && isSiteAdmin && fg('jira_tailored_onboarding_jtbd_feature_gate')
		: isSiteAdmin && fg('jira_tailored_onboarding_jtbd_feature_gate');
};

// Do not use during onboarding flow where useProjectContext might be unstable.
export const useCommonEligibilityConditionsForTOM2P5 = () => {
	const isSiteAdmin = useIsSiteAdmin();
	const { isBusinessProject } = useIsBusinessProject();

	return isBusinessProject && isSiteAdmin && fg('tailored_onboarding_m2p5_gate');
};

export const useFetchOnboardingPersonalisation = ({
	isEligible,
	experienceTypes,
	additionalParameters,
}: FetchOnboardingPersonalisation) => {
	const [{ fetchedOnce, isFetching, data }, { fetchOnboardingPersonalisation }] =
		useFetchOnboardingPersonalisationSweetState();

	useEffect(() => {
		if (isEligible && !fetchedOnce && !isFetching && data === undefined) {
			fetchOnboardingPersonalisation({
				experienceTypes,
				additionalParameters,
			});
		}
	}, [
		additionalParameters,
		data,
		experienceTypes,
		fetchOnboardingPersonalisation,
		fetchedOnce,
		isEligible,
		isFetching,
	]);

	if (!isEligible) {
		// If not eligible, no fetching is done. Data is ready to be read
		return {
			data: undefined,
			isReady: true,
		};
	}
	return {
		data,
		isReady: fetchedOnce && !isFetching,
	};
};

export const useTailoredOnboardingPersonalisationAttributes = () => {
	const isEligibleForTailoredOnboardingM2 = useCommonEligibilityConditionsForJtbdOnboarding();
	const { data: personalisationData, isReady: personalisationDataIsReady } =
		useFetchOnboardingPersonalisation({
			isEligible: isEligibleForTailoredOnboardingM2,
		});

	return {
		personalisationDataIsReady,
		teamType: personalisationData?.matched.variants
			.find((variant) => variant.startsWith('teamType.'))
			?.replace('teamType.', ''),
		jtbd: personalisationData?.matched.variants
			.filter((variant) => variant.startsWith('jtbd.option_'))
			.map((option) => option.replace('jtbd.option_', ''))
			.join(','),
	};
};

export const useTailoredOnboardingPersonalisationM2 = ({
	isEligible,
	additionalParameters,
}: FetchOnboardingPersonalisation): TailoredOnboardingPersonalisationM2Response => {
	const hasSentErrorOnce = useRef(false);
	const { data, isReady } = useFetchOnboardingPersonalisation({
		isEligible,
		experienceTypes: ['QUICKSTART', 'TEMPLATE', 'LANDING_PAGE'],
		additionalParameters,
	});
	try {
		const conditionalProjectType = `projectType.${additionalParameters?.projectType}`;

		// If projectType is not given as parameter, ignore restriction to match on projectType
		const isMatchingProjectType = Boolean(
			!additionalParameters?.projectType ||
				data?.matched.variants.some((variant) => variant === conditionalProjectType),
		);

		const isMatchingTeamType = Boolean(
			data?.matched.variants.some(
				(variant) =>
					variant.startsWith('teamType.') &&
					ELIGIBLE_TEAM_TYPES_M2_FULL_NOTATION.includes(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						variant.split('.')[1] as UserSegmentationTeamTypes,
					),
			),
		);

		const isMatchingJtbd = Boolean(
			data?.matched.variants.some((variant) => variant.startsWith('jtbd')),
		);

		const matched = Boolean(data && isMatchingJtbd && isMatchingProjectType && isMatchingTeamType);

		return {
			isReady,
			data: {
				matched,
				matchedVariants: {
					projectType: isMatchingProjectType,
					tt: isMatchingTeamType,
					jtbd: isMatchingJtbd,
				},
				QUICKSTART: matched && data ? data.experience.QUICKSTART : [],
				TEMPLATE: matched && data ? data.experience.TEMPLATE?.[0] : undefined,
				LANDING_PAGE: matched && data ? data.experience.LANDING_PAGE?.[0] : undefined,
			},
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (!hasSentErrorOnce.current) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'useTailoredOnboardingPersonalisationM2',
					packageName: 'jiraOnboardingCore',
					teamName: 'jlove-makkuro',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			hasSentErrorOnce.current = true;
		}
	}

	return {
		isReady,
		data: {
			matched: false,
			matchedVariants: {
				jtbd: false,
				tt: false,
				projectType: false,
			},
			QUICKSTART: [],
			TEMPLATE: undefined,
			LANDING_PAGE: undefined,
		},
	};
};

export const useIsTailoredOnboardingM2ForQuickstartEnabled = () => {
	const isEligibleForJtbdOnboarding = useCommonEligibilityConditionsForJtbdOnboarding();
	const isEnabled = isTailoredOnboardingM2ExperimentFlagEnabled();
	const { data: projectContextData } = useProjectContext();

	const projectType = fg('tailored_onboarding_m3_gate')
		? projectContextData?.projectType
		: CORE_PROJECT;

	const { isReady: personalisationDataIsReady, data: personalisationData } =
		useTailoredOnboardingPersonalisationM2({
			isEligible: isEligibleForJtbdOnboarding,
			additionalParameters: { projectType },
		});

	return Boolean(
		personalisationDataIsReady &&
			personalisationData.QUICKSTART &&
			personalisationData.QUICKSTART?.length > 0 &&
			isEnabled,
	);
};

export const useFireExposureForTailoredOnboardingM2ForQuickstart = ({
	projectType,
}: {
	projectType?: ProjectType;
}) => {
	const isEligibleForJtbdOnboarding = useCommonEligibilityConditionsForJtbdOnboarding();

	const { isReady: personalisationDataIsReady, data: personalisationData } =
		useTailoredOnboardingPersonalisationM2({
			isEligible: isEligibleForJtbdOnboarding,
			additionalParameters: { projectType: CORE_PROJECT },
		});

	if (
		projectType === CORE_PROJECT &&
		personalisationDataIsReady &&
		personalisationData.matched &&
		personalisationData.QUICKSTART &&
		personalisationData.QUICKSTART?.length > 0
	) {
		expVal('jira_tailored_onboarding_jtbd', 'isEnabled', false);
	}
};
