import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsArticlesRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsArticlesRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { navigation } from './common/constants.tsx';
import { helpCenterSettingArticlesAppPageEntryPoint } from './ui/articles/index.tsx';

export const helpCenterSettingsArticlesRouteEntry = createEntry(
	helpCenterSettingsArticlesRoute,
	() => ({
		group: 'helpcenter-settings',
		perfMetricKey: 'help-center-settings.articles',
		layout: serviceProjectLayout,
		navigation,
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('enable_articles_page_csm')
				? helpCenterSettingArticlesAppPageEntryPoint
				: undefined;
		},
		resources: [...getServiceDeskSettingsResources()],
	}),
);
