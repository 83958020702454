import { resources as alertIssueSyncingResources } from '@atlassian/eoc-alert-issue-syncing/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async.tsx';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocTeamAlertIssueSyncingDetailRoute } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-routes/src/eocTeamAlertIssueSyncingDetailRoute.tsx';
import { AlertIssueSyncDetail } from './ui/AlertIssueSyncDetail.tsx';
import { AlertIssueSync } from './ui/AlertIssueSync.tsx';
import { LazyAlertIssueSync } from './ui/async.tsx';

export const eocTeamAlertIssueSyncingDetailRouteEntry = createEntry(
	eocTeamAlertIssueSyncingDetailRoute,
	() => ({
		group: 'eoc',
		component: AlertIssueSync,
		layout: opsTeamLayout,
		slot: AlertIssueSyncDetail,
		basePath: '/jira/ops/teams',
		resources: [
			...getNavigationResources(),
			...teamDashboardResources(),
			getConsolidationStateResource(),
			alertIssueSyncingResources.configuredSyncsResource,
			alertIssueSyncingResources.userAccessRightsResource,
		],
		navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarEocTeamDashboard,
			horizontal: AsyncHorizontalOperationsNav,
			onlyShowHorizontalOnNav4: true,
		},
		forPaint: [
			LazyHorizontalOperationsNav,
			LazyAtlassianNavigation,
			LazyAlertIssueSync,
			LazyTeamDashboardSidebar,
		],
	}),
);
