/**
 * @generated SignedSource<<1297e52fc3bd1f2c835d01b332b497dd>>
 * @relayHash 20386ffeec0ab803889474656029a2be
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2753e00bae59e3ce31da8be67a8707519147394821c376e30cd60c5f2f7cf5b7

import type { ConcreteRequest, Query } from 'relay-runtime';
export type CcpEntitlementPreDunningStatus = "IN_PRE_DUNNING" | "NOT_IN_PRE_DUNNING" | "%future added value";
export type trialButtonJiraEditionAwarenessQuery$variables = {
  cloudId: string;
  isInGracePeriodFixFgEnabled: boolean;
  isTrialCountdownFgExpEnabled: boolean;
  productKey: string;
};
export type trialButtonJiraEditionAwarenessQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly offering?: {
          readonly trial?: {
            readonly lengthDays: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly preDunning?: {
          readonly status: CcpEntitlementPreDunningStatus | null | undefined;
        } | null | undefined;
        readonly subscription: {
          readonly accountDetails: {
            readonly invoiceGroup: {
              readonly id?: string;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly transactionAccount: {
          readonly isCurrentUserBillingAdmin: boolean | null | undefined;
          readonly key: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type trialButtonJiraEditionAwarenessQuery = {
  response: trialButtonJiraEditionAwarenessQuery$data;
  variables: trialButtonJiraEditionAwarenessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isInGracePeriodFixFgEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrialCountdownFgExpEnabled"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productKey"
},
v4 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "productKey"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "AND": [
        {
          "inTrialOrPreDunning": true
        }
      ]
    }
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "kind": "InlineFragment",
  "selections": (v8/*: any*/),
  "type": "CcpInvoiceGroup"
},
v10 = {
  "kind": "ScalarField",
  "name": "key"
},
v11 = {
  "kind": "ScalarField",
  "name": "isCurrentUserBillingAdmin"
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "CcpOfferingTrial",
      "kind": "LinkedField",
      "name": "trial",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "lengthDays"
        }
      ]
    }
  ],
  "type": "CcpOffering"
},
v13 = {
  "kind": "ScalarField",
  "name": "status"
},
v14 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "trialButtonJiraEditionAwarenessQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "args": (v6/*: any*/),
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  },
                  {
                    "condition": "isTrialCountdownFgExpEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "offering",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "isInGracePeriodFixFgEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "preDunning",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "entitlement(where:{\"AND\":[{\"inTrialOrPreDunning\":true}]})"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "trialButtonJiraEditionAwarenessQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              {
                "args": (v6/*: any*/),
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v8/*: any*/),
                        "type": "CcpSubscription"
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v8/*: any*/),
                        "type": "CcpTransactionAccount"
                      }
                    ]
                  },
                  {
                    "condition": "isTrialCountdownFgExpEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "offering",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v8/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "isInGracePeriodFixFgEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "preDunning",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v13/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "entitlement(where:{\"AND\":[{\"inTrialOrPreDunning\":true}]})"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2753e00bae59e3ce31da8be67a8707519147394821c376e30cd60c5f2f7cf5b7",
    "metadata": {},
    "name": "trialButtonJiraEditionAwarenessQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "dc0b5366e028422220b8a574e3c6b641";

export default node;
