import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { sandboxBundlerRoute } from '@atlassian/jira-router-routes-sandbox-routes/src/sandboxBundlerRoute.tsx';
import type SandboxBundlerPageType from '@atlassian/jira-spa-apps-sandbox-bundler/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazySandboxBundlerPage = lazyForPaint<typeof SandboxBundlerPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-sandbox-bundler", jiraSpaEntry: "async-sandbox-bundler" */ '@atlassian/jira-spa-apps-sandbox-bundler'
		),
	),
);

const SandboxBundler = () => (
	<LazyPage Page={LazySandboxBundlerPage} pageId="sandbox-bundler" shouldShowSpinner />
);

export const sandboxBundlerRouteEntry = createEntry(sandboxBundlerRoute, () => ({
	group: 'sandbox',
	component: componentWithFG('conditional_import_sandbox', SandboxBundler, ErrorPagesNotFound),
	layout: globalLayout,
	resources: [...getNavigationResources()],
	forPaint: [LazyAtlassianNavigation, LazySandboxBundlerPage],
	ufoName: 'sandbox-bundler',
}));
