import React, { type RefCallback, useState, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { NavigationAnalyticsContext } from '@atlaskit/analytics-namespaced-context';
import Popup from '@atlaskit/popup';
import type { ProfileProps } from '@atlaskit/atlassian-navigation';
import Spinner from '@atlaskit/spinner';
import { Box, xcss } from '@atlaskit/primitives';
import { SpotlightTarget } from '@atlaskit/onboarding';
import Account, { useAccountLinks } from '@atlassian/account';
import { isMobileAndInMvpOrExistingUsersExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { useProfileLink } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import type { DataBasic } from '@atlassian/jira-platform-services-user-current/src/types.tsx';
import type { Nav4Profile$key } from '@atlassian/jira-relay/src/__generated__/Nav4Profile.graphql';
import { useThemePreference } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import {
	getIdentityEnv,
	getSiteUrl,
	getWindow,
} from '@atlassian/jira-atlassian-navigation-nav4-common/src/common/utils.tsx';
import { MenuNav4 } from '@atlassian/jira-atlassian-navigation-nav4-profile-content/src/ui/profile-menu/main-nav4.tsx';
import { useUserBoardId } from '@atlassian/jira-atlassian-navigation-nav4-common/src/controllers/user-board/index.tsx';

import {
	ANALYTICS_CONTEXT,
	AccountMenuIntlProvider,
	AccountMenuLoggedOutState,
	AccountMenuTrigger,
} from '@atlassian/account-menu';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { Nav4ProfileMenuEntryPoint } from '@atlassian/jira-atlassian-navigation-nav4-profile-content/entrypoint.tsx';

type Nav4ProfileProps = {
	queryReference: Nav4Profile$key;
};

export const Nav4Profile = ({ queryReference }: Nav4ProfileProps) => {
	const data = useFragment<Nav4Profile$key>(
		graphql`
			fragment Nav4Profile on Query {
				me {
					user {
						name @include(if: $includeExtraMenuItems)
						picture
						... on AtlassianAccountUser @include(if: $includeExtraMenuItems) {
							email
						}
					}
				}
			}
		`,
		queryReference,
	);
	const {
		data: { user },
	} = useCurrentUser();
	const { theme, setTheme } = useThemePreference();
	const profileLink = useProfileLink();
	const isAnonymous = useIsAnonymous();
	const { environment } = useTenantContext();
	const isMobileExperience = isMobileAndInMvpOrExistingUsersExperiment();
	const identityEnv = getIdentityEnv(environment);

	const currentWindow = getWindow();
	const siteUrl = getSiteUrl(currentWindow);

	const [userBoardId, userBoardOwnerAccountId] = useUserBoardId(false);
	const testIdPrefix = testIdGenerate('secondary-actions', 'profile');

	const isUserType = (maybeUser: DataBasic | User): maybeUser is User =>
		'emailAddress' in maybeUser;

	const emailAddress = isUserType(user) && user.emailAddress ? user.emailAddress : '';
	const links = useAccountLinks({
		email: emailAddress,
		identityEnvironment: identityEnv,
		application: 'jira',
		continueUrl: siteUrl,
		isLoggedOut: isAnonymous,
	});

	if (expVal('jira_nav4_hot_113350_account_menu_experiment', 'cohort', false)) {
		return (
			<Nav4AccountMenu
				testId={testIdGenerate('secondary-actions', 'profile')}
				picture={data?.me?.user?.picture}
				email={emailAddress || ''}
				logInLink={links.logInLink}
			/>
		);
	}

	return (
		<Account
			name={
				expVal('jira_nav4_hot_113350_account_menu_experiment', 'cohort', false)
					? data?.me?.user?.name
					: user?.userFullname
			}
			picture={data?.me?.user?.picture}
			trustPicture={expVal('jira_nav4_hot_113350_account_menu_experiment', 'cohort', false)}
			email={
				expVal('jira_nav4_hot_113350_account_menu_experiment', 'cohort', false)
					? data?.me?.user?.email || ''
					: emailAddress
			}
			viewProfileLink={isMobileExperience ? undefined : profileLink}
			onThemeChange={setTheme}
			currentTheme={theme}
			identityEnvironment={identityEnv}
			application="jira"
			continueUrl={siteUrl}
			isLoggedOut={isAnonymous}
			extraMenuItems={
				expVal('jira_nav4_hot_113350_account_menu_experiment', 'cohort', false) && (
					<MenuNav4
						userBoardId={userBoardId}
						userBoardOwnerAccountId={userBoardOwnerAccountId}
						testIdPrefix={testIdPrefix}
					/>
				)
			}
		/>
	);
};

// The Nav4AccountMenu component re-composes the platform AccoutMenu component in order to leverage the performance
// benefits of EntryPoints. This was done at the time because the platform did not support EntryPoints. In the future
// it might be possible to swap this out for the platform AccountMenu component should it be updated to support EntryPoints.
export const Nav4AccountMenu: React.ComponentType<
	Pick<ProfileProps, 'onClick' | 'testId'> & {
		picture: string | undefined;
		email: string | undefined;
		logInLink?: string;
	}
> = ({ testId, picture, email, logInLink }) => {
	const [isOpen, setIsOpen] = useState(false);
	const entryPointParams = useMemo(
		() => ({
			includeExtraMenuItems: true,
		}),
		[],
	);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		Nav4ProfileMenuEntryPoint,
		entryPointParams,
	);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);

	const runtimeProps = useMemo(
		() => ({
			onClosePopup: () => {
				setIsOpen(false);
			},
			testId,
		}),
		[testId],
	);
	return (
		<NavigationAnalyticsContext data={ANALYTICS_CONTEXT}>
			<AccountMenuIntlProvider>
				{logInLink ? (
					<AccountMenuLoggedOutState
						testId={testId && `${testId}--logged-out-state`}
						logInLink={logInLink}
					/>
				) : (
					<Popup
						isOpen={isOpen}
						onClose={() => {
							setIsOpen(false);
						}}
						testId={testId}
						placement="bottom"
						trigger={(triggerProps) => {
							return (
								<SpotlightTarget name="profile-spotlight">
									<AccountMenuTrigger
										ref={(element: HTMLElement | null) => {
											triggerRef(element);
											// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
											(triggerProps.ref as RefCallback<HTMLElement>)(element);
										}}
										onClick={() => {
											setIsOpen((open) => !open);
										}}
										testId={testId && `${testId}--trigger`}
										picture={picture}
										trustPicture
										email={email}
										aria-expanded={isOpen}
										aria-haspopup
										aria-controls={testId && `${testId}--content`}
										data-ds--level={triggerProps['data-ds--level']}
									/>
								</SpotlightTarget>
							);
						}}
						content={() => (
							<JiraEntryPointContainer
								entryPointReferenceSubject={entryPointReferenceSubject}
								id="atlassian-navigation-profile-menu"
								packageName="atlassian-navigation-profile-menu"
								errorFallback="flag"
								fallback={
									<Box xcss={Nav4AccountMenuContentSkeletonStyles} padding="space.100">
										<Spinner />
									</Box>
								}
								runtimeProps={runtimeProps}
							/>
						)}
					/>
				)}
			</AccountMenuIntlProvider>
		</NavigationAnalyticsContext>
	);
};

// These styles are defined to ensure that the initial popup for the AccountMenu content is the same width when
// displaying the skeleton loader as when displaying the actual content.
const Nav4AccountMenuContentSkeletonStyles = xcss({
	width: '304px',
	height: '307px',
});
