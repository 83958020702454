// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '../../utils.tsx';

export const removeDocumentTitle =
	(titleSymbol: symbol): Action<State> =>
	({ setState, getState }) => {
		const { contextArray } = getState();
		setState({
			contextArray: fg('jira-concurrent-document-title')
				? contextArray.filter(
						(titleContext) =>
							typeof titleContext === 'object' && titleContext.symbol !== titleSymbol,
					)
				: contextArray.filter((symbol) => symbol !== titleSymbol),
		});
	};
