// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	KEY_OPEN,
	QUICKSTART_UI_STATUS,
	getBackendVisibilityKey,
} from '../../../common/constants.tsx';
import { pushToBackend } from '../../../common/utils/push-to-backend.tsx';
import type { State } from '../../model/types.tsx';
import { getQuickstartTypeSelector } from '../../model/utils.tsx';
import { setLocalStorageItem } from '../../utils.tsx';

// TODO: Remove this and replace with set visibility action
export const nextSetChecklistIsOpen =
	(isOpen: boolean): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		const quickstartType = getQuickstartTypeSelector(state);
		const currentIsOpen = state.visibility[quickstartType] === QUICKSTART_UI_STATUS.OPEN;
		const currentIsMinimised = state.visibility[quickstartType] === QUICKSTART_UI_STATUS.MINIMIZED;
		if ((currentIsOpen && isOpen) || (currentIsMinimised && !isOpen)) {
			// if state has not been updated we just return
			return;
		}
		const quickstartStatus = isOpen ? QUICKSTART_UI_STATUS.OPEN : QUICKSTART_UI_STATUS.MINIMIZED;

		setState({
			visibility: {
				...state.visibility,
				[quickstartType]: quickstartStatus,
			},
			hasNudgeActive: false,
			isFocusedOn: isOpen,
		});

		pushToBackend({
			key: getBackendVisibilityKey(quickstartType),
			value: quickstartStatus,
		});
		setLocalStorageItem(`${quickstartType}.visibility`, quickstartStatus);

		// Delete all code below when cleaning up isOpen in state
		pushToBackend({
			key: KEY_OPEN,
			value: isOpen,
		});
	};
