import React from 'react';

import { LoomIcon } from '@atlaskit/temp-nav-app-icons/loom';

import { Products } from '../../constants';
import { GlobalShortcutsItem } from '../../types';

export default function loom(
	localizedName: string,
	fallbackUrl: () => string,
): GlobalShortcutsItem {
	return {
		icon: <LoomIcon size="20" />,
		label: localizedName,
		apsKey: Products.LOOM,
		fallbackUrl,
	};
}
