import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { projectIssueNavigatorRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/projectIssueNavigatorRoute.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { NIN_NEW_ISSUE_NAVIGATOR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { getCommonProjectResources } from './common/getCommonProjectResources.tsx';
import { getRouteReporting } from './common/getRouteReporting.tsx';
import { issueNavigatorPinRedirectPageEntryPoint } from './ui/index.tsx';

export const projectIssueNavigatorRouteEntry = createEntry(projectIssueNavigatorRoute, () => ({
	group: 'issue-navigator',
	apdexIgnoreParams: ['issueKey'],
	meta: {
		capability: NIN_NEW_ISSUE_NAVIGATOR,
		reporting: getRouteReporting(),
	},
	skeleton: ProjectIssueNavigatorSkeleton,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},
	resources: [...getCommonProjectResources(), getConsolidationStateResource()],
	layout: createLayout(genericProjectLayout),
	entryPoint: () => issueNavigatorPinRedirectPageEntryPoint,
}));
