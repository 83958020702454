import React from 'react';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import type RovoSearchPageType from '@atlassian/jira-spa-apps-rovo-search/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { rovoSearchRoute } from '@atlassian/jira-router-routes-rovo-search-routes/src/rovoSearchRoute.tsx';

const LazyRovoSearchPage = lazyForPaint<typeof RovoSearchPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-rovo-search", jiraSpaEntry: "async-rovo-search" */ '@atlassian/jira-spa-apps-rovo-search'
		),
	),
);

const RovoSearchPage = () => (
	<LazyPage Page={LazyRovoSearchPage} pageId="rovo-search" shouldShowSpinner={false} />
);

export const rovoSearchRouteEntry = createEntry(rovoSearchRoute, () => ({
	group: 'search',
	component: RovoSearchPage,
	isRedirect: false,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	forPaint: [LazyRovoSearchPage],
}));
