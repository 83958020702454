/**
 * @generated SignedSource<<7720db90ecfa6c4681831e9e06d103aa>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type tooltip_adminPagesPerformance_CustomFieldsTooltip$data = {
  readonly resourceUsageMetricV2?: {
    readonly globalScopedCustomFieldsCount?: AGG$Long | null | undefined;
    readonly projectScopedCustomFieldsCount?: AGG$Long | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "tooltip_adminPagesPerformance_CustomFieldsTooltip";
};
export type tooltip_adminPagesPerformance_CustomFieldsTooltip$key = {
  readonly " $data"?: tooltip_adminPagesPerformance_CustomFieldsTooltip$data;
  readonly " $fragmentSpreads": FragmentRefs<"tooltip_adminPagesPerformance_CustomFieldsTooltip">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "metricKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tooltipEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "tooltip_adminPagesPerformance_CustomFieldsTooltip",
  "selections": [
    {
      "condition": "tooltipEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "kind": "Variable",
              "name": "metricKey",
              "variableName": "metricKey"
            }
          ],
          "kind": "LinkedField",
          "name": "resourceUsageMetricV2",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "globalScopedCustomFieldsCount"
                },
                {
                  "kind": "ScalarField",
                  "name": "projectScopedCustomFieldsCount"
                }
              ],
              "type": "JiraCustomFieldUsageMetric"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "e6113bc43303a707291b62cbe6c2b46f";

export default node;
