import React, { useCallback, useMemo, type PropsWithChildren } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import type { CustomComponents } from '@atlaskit/jql-editor';
import { JiraBanner as Banner } from '@atlassian/jira-banner/src/ui/jira-banner.tsx';
import { LazySuspense, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useIntl } from '@atlassian/jira-intl';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useDebuggerStatus, useIsAiEnabled } from '../../controllers/jql-debugger-data/index.tsx';
import {
	ERROR_BOUNDARY_IDS,
	PACKAGE_NAME_JQL_DEBUGGER,
	TEAM_NAME,
} from '../../common/constants.tsx';
import { jqlMessagesToErrors } from '../../common/jql-messages-to-errors.tsx';
import { JQLPanelRenderSlotForJQLEditor } from '../debugger-panel/index.tsx';
import messages from './messages.tsx';
import { useJqlDebuggerAdditionalContext } from './context.tsx';

const TriggerButtonWithLogic = lazyForPaint(
	() =>
		import(/* webpackChunkName: "jql-debugger-trigger-button" */ './trigger-button-content.tsx'),
);

export type JQLDebuggerProps = PropsWithChildren<{
	testId: string;
}>;

export function JQLDebuggerTriggerButtonContent(props: JQLDebuggerProps) {
	const { children, testId } = props;
	const { formatMessage } = useIntl();
	const { jqlMessages, onSearch } = useJqlDebuggerAdditionalContext();
	const [debuggerState] = useDebuggerStatus();

	const [isAiEnabled] = useIsAiEnabled();

	// A jqlMessage can be error, warning or info
	// We only case about the error scenario
	// Here we map the jqlMessages object list to a list of plain strings
	const errors = useMemo(() => {
		return jqlMessagesToErrors(jqlMessages);
	}, [jqlMessages]);

	// Do not render the trigger button when:
	// 1. AI is not enabled
	// 2. onSearch callback is not available - i.e. we cannot apply the corrected jql to the editor
	// 3. No validation errors coming from the jql-editor - duuh
	//
	// Special note: Default render is `children` (and not `null`) coz we wanto keep showing the errors
	// For example when AI is not available, we still want to show the errors
	if (!isAiEnabled || !onSearch || !errors?.length) {
		return children;
	}

	if (debuggerState === 'JS_ERROR') {
		return (
			<Box>
				{children}
				<Box xcss={errorBannerStyle}>
					<Banner
						messageId="jql-debugger.ui.trigger-button.banner.error"
						messageType="transactional"
						appearance="error"
						icon={
							<ErrorIcon
								spacing="spacious"
								label={formatMessage(messages.errorIconLabelNonFinal)}
								LEGACY_secondaryColor="inherit"
							/>
						}
					>
						{formatMessage(messages.jsErrorNonFinal)}
					</Banner>
				</Box>
			</Box>
		);
	}

	return (
		<Box xcss={errorComponentStyles}>
			{debuggerState === 'INIT' && children}
			{debuggerState === 'INIT' && (
				<LazySuspense fallback={<Button>{formatMessage(messages.fixErrorWithAiNonFinal)}</Button>}>
					<TriggerButtonWithLogic testId={testId} />
				</LazySuspense>
			)}
		</Box>
	);
}

const JQLDebuggerTriggerButtonOld: NonNullable<CustomComponents['ErrorMessage']> = (props) => {
	const ChildrenFallback = useCallback(() => props.children, [props.children]);

	return (
		<JSErrorBoundary
			packageName={PACKAGE_NAME_JQL_DEBUGGER}
			id={ERROR_BOUNDARY_IDS.TRIGGER_BUTTON}
			teamName={TEAM_NAME}
			fallback={ChildrenFallback} // Render the children (i.e. original error message(s)) when a JS error is thrown from the trigger button
		>
			<JQLDebuggerTriggerButtonContent {...props} />
		</JSErrorBoundary>
	);
};

export const JQLDebuggerTriggerButton = componentWithFG(
	'gravityai-2283-jql-debugger-rll-to-entrypoints',
	JQLPanelRenderSlotForJQLEditor,
	JQLDebuggerTriggerButtonOld,
);

const errorComponentStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	rowGap: 'space.025',
	marginBottom: 'space.050',
});

const errorBannerStyle = xcss({
	marginTop: 'space.025',
	marginBottom: 'space.050',
});
