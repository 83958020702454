import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectSettingsSoftwareNextGenFeaturesRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareNextGenFeaturesRoute.tsx';
import { ProjectSettingsFeaturesSkeleton } from '@atlassian/jira-skeletons/src/ui/project-settings/ProjectSettingsFeaturesSkeleton.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import Features, { LazyFeaturesSettings } from './ui/project-settings/next-gen-features/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';

export const projectSettingsSoftwareNextGenFeaturesRouteEntry = createEntry(
	projectSettingsSoftwareNextGenFeaturesRoute,
	() => ({
		group: 'project-settings-software',
		ufoName: 'project-settings.next-gen-features',
		component: Features,
		skeleton: ProjectSettingsFeaturesSkeleton,
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazyFeaturesSettings],
	}),
);
