import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { type XCSS, xcss } from '@atlaskit/primitives';
import { Show } from '@atlaskit/primitives/responsive';
import { UNSAFE_useMediaQuery as useMediaQuery } from '@atlaskit/primitives/compiled';
import type { CustomComponents } from '@atlaskit/jql-editor';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useDebuggerStatus,
	useJqlDebuggerStoreActions,
	useIsAiEnabled,
} from '../../controllers/jql-debugger-data/index.tsx';
import {
	useIsJqlEditorReady,
	useIsContextReady,
	useRenderSynchronizerStoreActions,
} from '../../controllers/render-synchronizer/index.tsx';
import { JQLDebuggerCommonEntry } from '../common-entry/index.tsx';
import {
	ERROR_BOUNDARY_IDS,
	PACKAGE_NAME_JQL_DEBUGGER,
	TEAM_NAME,
} from '../../common/constants.tsx';
import { extractTextFromReactElement } from '../../common/extract-text-from-element.tsx';

type Props = {
	fullWidthLayout: boolean;
	xcss?: XCSS;
};

function JqlDebuggerLayoutSwitcher(props: Props) {
	const layoutQuery = props.fullWidthLayout ? 'below.lg' : 'above.lg';
	const showProp = useMemo(
		() => (props.fullWidthLayout ? { below: 'lg' as const } : { above: 'lg' as const }),
		[props.fullWidthLayout],
	);

	const [matches, setMatches] = useState<boolean | null>(null);
	const mq = useMediaQuery(layoutQuery, (e) => {
		setMatches(e.matches);
	});

	return matches ?? mq?.matches ? (
		<Show {...showProp} xcss={props.xcss ? [showContainerStyles, props.xcss] : showContainerStyles}>
			<JQLDebuggerCommonEntry />
		</Show>
	) : null;
}

export const JQLPanelRenderSlotForJQLEditorLogic: NonNullable<CustomComponents['ErrorMessage']> = (
	props,
) => {
	const { errorMessages, testId, validationId } = props;

	const jqlDebuggerStoreActions = useJqlDebuggerStoreActions();
	const [isReady] = useIsContextReady();
	const renderSyncActions = useRenderSynchronizerStoreActions();
	const [isAiEnabled] = useIsAiEnabled();
	const [status] = useDebuggerStatus();

	/**
	 * When the JQL Editor unmounts for whatever reason, we want to mark jql editor as not ready
	 * So that JQLPanelRenderSlotForIssueView doesn't try to accidentally render any error messages
	 *
	 * For example, this happens when we enter NL-to-JQL AI mode while the regular JQL editor has an error
	 */
	useEffect(() => {
		return () => {
			renderSyncActions.setJqlEditorReady(false);
		};
	}, [renderSyncActions]);

	if (fg('gravityai-2553-fix-jql-debugger-flicker')) {
		if (!errorMessages?.length || !isReady || !isAiEnabled || status === 'UNRECOVERABLE_ERROR') {
			renderSyncActions.setJqlEditorReady(false);
			return props.children;
		}

		jqlDebuggerStoreActions.setJqlErrors(errorMessages.map(extractTextFromReactElement));
		jqlDebuggerStoreActions.setErrorComponentTestId(testId);
		jqlDebuggerStoreActions.setErrorComponentValidationId(validationId);
	} else if (!isReady || !isAiEnabled || status === 'UNRECOVERABLE_ERROR') {
		renderSyncActions.setJqlEditorReady(false);
		return props.children;
	}

	renderSyncActions.setJqlEditorReady(true);

	return <JqlDebuggerLayoutSwitcher fullWidthLayout={false} xcss={jqlBuilderContainerStyles} />;
};

// We have two versions of this as depending on the screen size, we want to either render the JQL Debugger Panel underneath the JQL builder component, or across
// the whole issue view. The breakpoint is 1400px (media.lg) - if it is greater than this, we render it underneath the JQL builder, and if it is less, we render
// it across the whole issue view.
export const JQLPanelRenderSlotForJQLEditor: NonNullable<CustomComponents['ErrorMessage']> = (
	props,
) => {
	const { children } = props;
	const ChildrenFallback = useCallback(() => children, [children]);

	return (
		<JSErrorBoundary
			packageName={PACKAGE_NAME_JQL_DEBUGGER}
			id={ERROR_BOUNDARY_IDS.COMMON_ENTRY_JQL_BUILDER}
			teamName={TEAM_NAME}
			fallback={ChildrenFallback} // Render the children (i.e. original error message(s)) when a JS error is thrown from the trigger button
		>
			<JQLPanelRenderSlotForJQLEditorLogic {...props} />
		</JSErrorBoundary>
	);
};

export const JQLPanelRenderSlotForIssueView = () => {
	const [isReady] = useIsContextReady();
	const [isJqlEditorReady] = useIsJqlEditorReady();
	const storeActions = useJqlDebuggerStoreActions();

	if (!isReady || !isJqlEditorReady) {
		return null;
	}

	return (
		<JSErrorBoundary
			packageName={PACKAGE_NAME_JQL_DEBUGGER}
			id={ERROR_BOUNDARY_IDS.COMMON_ENTRY_ISSUE_VIEW}
			teamName={TEAM_NAME}
			fallback={() => null}
			onError={() => {
				storeActions.setStatus('UNRECOVERABLE_ERROR');
			}}
		>
			<JqlDebuggerLayoutSwitcher fullWidthLayout xcss={issueViewContainerStyles} />
		</JSErrorBoundary>
	);
};

const jqlBuilderContainerStyles = xcss({
	// When we are rendering in JQL builder, we want to add the space between the editor and panel
	// This space is already present in the issue view
	marginTop: 'space.100',
});

const issueViewContainerStyles = xcss({
	// When we are rendering in JQL builder, we want to add the space between the editor and panel
	// This space is already present in the issue view
	marginTop: 'space.negative.100',
});

const showContainerStyles = xcss({
	width: '100%',
	marginBottom: 'space.100',
});
