/**
 * @generated SignedSource<<e514564c1688822aea8c6efba8699694>>
 * @relayHash 466f1e55c408e59be27b44b8156f9147
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 82d517df2c5a9cef088b63278282f98c94356907ff3ba4c909173bfd3d9477f7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessOverviewCalendarQuery } from './src_jiraBusinessOverviewCalendarQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessOverviewCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "82d517df2c5a9cef088b63278282f98c94356907ff3ba4c909173bfd3d9477f7",
    "metadata": {},
    "name": "src_jiraBusinessOverviewCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
