import isEqual from 'lodash/isEqual';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { LayoutState, RightSidebarState, RightSidebar } from '../../../../common/types.tsx';

const arrayMove = (array: RightSidebar[], oldIndex: number, newIndex: number) => {
	const arrayCopy = [...array];
	const f = arrayCopy.splice(oldIndex, 1)[0];
	arrayCopy.splice(newIndex, 0, f);
	return arrayCopy;
};
const dedupe = (stackState: RightSidebar[] = []): RightSidebar[] => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const visited: Record<string, any> = {};
	return stackState.filter((item) => {
		if (visited[item.panelId]) return false;
		visited[item.panelId] = true;
		return true;
	});
};
export const selectCurrentRightSidebar = (state: RightSidebarState): RightSidebar | null => {
	if (state.stackState.length === 1) {
		return state.stackState[0];
	}
	return state.stackState.find((sidebar) => !sidebar.isCollapsed && !sidebar.isMinimised) || null;
};
export const setRightSidebarState =
	(newState: RightSidebarState): Action<LayoutState> =>
	({ getState, setState }) => {
		const { content } = getState();
		setState({
			content: {
				...content,
				rightSidebar: {
					stackState: dedupe(newState.stackState),
				},
			},
		});
	};
export const closeAllRightSidebar =
	(): Action<LayoutState> =>
	({ getState, setState }) => {
		const { content } = getState();
		setState({
			content: {
				...content,
				rightSidebar: {
					stackState: [],
				},
			},
		});
	};
/**
 * Closes the active right sidebar by removing it from the state stack. This action is
 * only performed if a right sidebar is currently active, ensuring the state is
 * updated appropriately.
 */
export const closeCurrentRightSidebar =
	(): Action<LayoutState> =>
	({ getState, setState }) => {
		const { content } = getState();
		const currentStack = content.rightSidebar.stackState || [];
		if (!currentStack.length) return;
		const currentRightSidebar = selectCurrentRightSidebar(content.rightSidebar);
		if (!currentRightSidebar) return;
		setState({
			content: {
				...content,
				rightSidebar: {
					stackState: content.rightSidebar.stackState.filter(
						(item) => item !== currentRightSidebar,
					),
				},
			},
		});
	};
export const closeRightSidebar =
	(panelId: string): Action<LayoutState> =>
	({ getState, setState }) => {
		const { content } = getState();
		const currentStack = content.rightSidebar.stackState || [];
		setState({
			content: {
				...content,
				rightSidebar: {
					stackState: currentStack.filter((item) => item.panelId !== panelId),
				},
			},
		});
	};
export const minimiseRightSidebar =
	(panelId: string): Action<LayoutState> =>
	({ getState, setState }) => {
		const { content } = getState();
		const currentStack = content.rightSidebar.stackState || [];
		setState({
			content: {
				...content,
				rightSidebar: {
					stackState: currentStack.map((item) => {
						if (item.panelId !== panelId) return item;
						return {
							...item,
							isMinimised: true,
						};
					}),
				},
			},
		});
	};
/**
 * Adds or updates a panel in the right sidebar stack based on given properties. It
 * ensures the sidebar reflects these changes, with options to reposition the
 * panel within the stack.
 */
export const openRightSidebar =
	(panelId: string, width: number, isMinimised = false, toTop = false): Action<LayoutState> =>
	({ getState, setState }) => {
		const { content } = getState();
		const tempRightSidebarStackStateValue: RightSidebar[] = content.rightSidebar.stackState
			? [
					...content.rightSidebar.stackState.map((item) => {
						if (item.panelId === panelId) {
							return {
								...item,
								isMinimised,
								width,
							};
						}
						return item;
					}),
				]
			: [];
		const matchedPanelIndex = tempRightSidebarStackStateValue.findIndex(
			(obj) => obj.panelId === panelId,
		);

		// if there's any matched panel id, we only move it to the top of the stack.
		if (matchedPanelIndex >= 0) {
			const nextIdx = toTop ? 0 : tempRightSidebarStackStateValue.length - 1;
			tempRightSidebarStackStateValue[matchedPanelIndex].isCollapsed = false;
			tempRightSidebarStackStateValue[matchedPanelIndex].isMinimised = isMinimised;
			const newRightSidebarStackStateValue = arrayMove(
				tempRightSidebarStackStateValue,
				matchedPanelIndex,
				nextIdx,
			);
			newRightSidebarStackStateValue[nextIdx].width = width;
			setState({
				content: {
					...content,
					rightSidebar: {
						stackState: newRightSidebarStackStateValue,
					},
				},
			});
			return;
		}
		const newRightSidebarStackStateValue = toTop
			? [
					{
						panelId,
						width,
						isCollapsed: false,
						isMinimised,
					},
					...tempRightSidebarStackStateValue,
				]
			: [
					...tempRightSidebarStackStateValue,
					{
						panelId,
						width,
						isCollapsed: false,
						isMinimised,
					},
				];
		if (isEqual(newRightSidebarStackStateValue, content.rightSidebar.stackState)) {
			return;
		}
		setState({
			content: {
				...content,
				rightSidebar: {
					stackState: newRightSidebarStackStateValue,
				},
			},
		});
	};
export type RightSidebarControllerType = {
	setRightSidebarState: typeof setRightSidebarState;
	closeAllRightSidebar: typeof closeAllRightSidebar;
	closeCurrentRightSidebar: typeof closeCurrentRightSidebar;
	closeRightSidebar: typeof closeRightSidebar;
	openRightSidebar: typeof openRightSidebar;
	minimiseRightSidebar: typeof minimiseRightSidebar;
};
