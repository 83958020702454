import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { softwareCalendarClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareCalendarClassicRoute.tsx';
import { CalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/calendar/CalendarSkeleton.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { classicSoftwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { softwareCalendarRouteEntry } from './ui/software/calendar/index.tsx';

export const softwareCalendarClassicRouteEntry = createEntry(softwareCalendarClassicRoute, () => ({
	group: 'classic-software',
	skeleton: CalendarSkeleton,
	component: CalendarSkeleton,

	entryPoint() {
		if (__SERVER__) {
			return undefined;
		}
		return softwareCalendarRouteEntry;
	},

	layout: composeLayouts(
		classicSoftwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(), // these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		resourceWithCondition2(() => getIsSoftwareThemingEnabled(), projectThemeResource),
	],
	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation],
	ufoName: 'classic-calendar',
	EXPERIMENTAL__shouldReload: fg('jira_fix_calendar_suspending_when_switching_months')
		? ({ context, prevContext }) => {
				return context.match.url !== prevContext.match.url;
			}
		: undefined,
}));
