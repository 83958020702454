// This type inlined to reduce TS Complexity - Please reach out to #help-jfp-squads if you need more information.
export type Theme = 'light' | 'dark' | 'auto';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ThemeOptions = ['light', 'dark', 'auto'] as readonly Theme[];

/** Yes, the default theme is `light` and not `auto`
 * https://hello.atlassian.net/wiki/spaces/SQE/pages/2903479143/DACI+-+Default+theme+for+new+users
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DEFAULT_THEME = 'light' as Theme;
