import React from 'react';
import { widgetResources } from '@atlassian/eoc-who-is-on-call/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { checkShouldUseJSM } from '@atlassian/jira-eoc-tenant-info/src/controllers/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { jiraHomePageRightSidePanelResource } from '@atlassian/jira-home-right-side-panel/src/services/main.tsx';
import { homepageLayout } from '@atlassian/jira-homepage-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { CONSOLIDATION_STATE_RESOURCE_TYPE } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/constants.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import type { TenantInfo } from '@atlassian/jira-router-resources-eoc-tenant-info/types.tsx';
import { jiraHomeResourceWithCache } from '@atlassian/jira-router-resources-jira-home/src/index.tsx';
import { jiraHomeWorkSuggestionsIsDeveloperResource } from '@atlassian/jira-router-resources-jira-home-your-work-work-suggestions/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { jiraHomeRoute } from '@atlassian/jira-router-routes-jira-home-routes/src/jiraHomeRoute.tsx';
import { postOfficeJiraYourWorkResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { YourWorkSkeleton } from '@atlassian/jira-skeletons/src/ui/your-work/YourWorkItemListSkeleton.tsx';
import type JiraHomeType from '@atlassian/jira-spa-apps-jira-home/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteResourceResponse } from '@atlassian/react-resource-router';
import { expVal } from '@atlassian/jira-feature-experiments';

const conditionalPostOfficeJiraYourWorkResource = resourceWithCondition2(
	() => fg('post_office_ssr_jira_your_work_enabled'),
	postOfficeJiraYourWorkResource,
);

const conditionalJiraHomeWorkSuggestionsIsDeveloperResource = resourceWithCondition2(
	() => expVal('jira-your-work-work-suggestions-tab-experiment', 'isEnabled', false),
	jiraHomeWorkSuggestionsIsDeveloperResource,
);

const LazyJiraHomePage = lazyForPaint<typeof JiraHomeType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-home", jiraSpaEntry: "async-jira-home" */ '@atlassian/jira-spa-apps-jira-home'
		),
	),
);

// Redefined here since it is not exported from RRR.
type ResourceDependencies = {
	[type: string]: RouteResourceResponse;
};

const checkConsolidationOnRouteDependency = async (dependencies: ResourceDependencies) => {
	const data = await dependencies[CONSOLIDATION_STATE_RESOURCE_TYPE].promise;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return checkShouldUseJSM(data as TenantInfo | null);
};

const JiraHomeComponent = () => (
	<LazyPage Page={LazyJiraHomePage} pageId="jira-home" shouldShowSpinner={false} />
);

export const jiraHomeRouteEntry = createEntry(jiraHomeRoute, () => ({
	group: 'home',
	ufoName: 'jira-home',
	component: JiraHomeComponent,
	skeleton: YourWorkSkeleton,
	layout: homepageLayout,

	navigation: {
		menuId: MENU_ID.HOME,
	},

	resources: [
		...getNavigationResources(),
		jiraHomeResourceWithCache,
		jiraHomePageRightSidePanelResource,
		conditionalPostOfficeJiraYourWorkResource,
		getConsolidationStateResource(),
		...widgetResources.map((resourceCreator) =>
			resourceCreator(checkConsolidationOnRouteDependency, [CONSOLIDATION_STATE_RESOURCE_TYPE]),
		),
		conditionalJiraHomeWorkSuggestionsIsDeveloperResource,
	],

	forPaint: [LazyAtlassianNavigation, LazyJiraHomePage],
}));
