import React, { useCallback, useState, type FormEvent, type ReactElement } from 'react';
import Textfield from '@atlaskit/textfield';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import SearchIcon from '@atlaskit/icon/core/search';
import AlignTextLeftIcon from '@atlaskit/icon/core/align-text-left';
import Heading from '@atlaskit/heading';
import type { AlertsMenuItemContent$data } from '@atlassian/jira-relay/src/__generated__/AlertsMenuItemContent.graphql.ts';
import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
	MenuSection,
} from '@atlassian/navigation-system';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

import messages from '../../../messages.tsx';

type filterType = Array<
	AlertsMenuItemContent$data['opsgenie']['savedSearches']['createdByMe'][number] & {
		component: ReactElement;
	}
>;

export const AlertsFlyoutMenuItem = ({
	defaultFilterWithComponentList,
	sharedWithMeWithComponentList,
	createdByMeWithComponentList,
	allFiltersWithComponentList,
}: {
	defaultFilterWithComponentList: filterType;
	sharedWithMeWithComponentList: filterType;
	createdByMeWithComponentList: filterType;
	allFiltersWithComponentList: filterType;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [textFilterValue, setTextFilterValue] = useState('');
	const [foundItems, setFoundItems] = useState<ReactElement[]>([]);

	const handleChange = useCallback(
		(event: FormEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			setTextFilterValue(value);

			const foundItemsList = allFiltersWithComponentList.filter((item) =>
				item.name.toLowerCase().includes(value.toLowerCase()),
			);

			const components = foundItemsList.map((item) => item.component);

			setFoundItems(components);
		},
		[allFiltersWithComponentList],
	);

	const onStopSavedSearch = useCallback(() => {
		const savedSearchesEvent = createAnalyticsEvent({
			action: 'blurred',
			actionSubject: 'jsmViewAllSavedSearchesFlyoutSearch',
		});
		fireUIAnalytics(savedSearchesEvent, 'stopViewAllQueueSearch');
	}, [createAnalyticsEvent]);

	return (
		<FlyoutMenuItem>
			<FlyoutMenuItemTrigger
				elemBefore={<AlignTextLeftIcon label="" spacing="spacious" color="currentColor" />}
			>
				{formatMessage(messages.viewAllFiltersPopupMenuButtonText)}
			</FlyoutMenuItemTrigger>
			<FlyoutMenuItemContent>
				<MenuSection>
					<Stack space="space.050" xcss={flyoutContentStyles}>
						<Box xcss={stickyBarStyles}>
							<Box paddingBlock="space.100">
								<Heading size="xsmall">{formatMessage(messages.alerts)}</Heading>
							</Box>
							<Box xcss={textfieldStyles}>
								<Textfield
									isCompact
									value={textFilterValue}
									onChange={handleChange}
									aria-label={formatMessage(messages.viewAllFiltersSearchPlaceholder)}
									placeholder={formatMessage(messages.viewAllFiltersSearchPlaceholder)}
									elemBeforeInput={<SearchIcon label="" spacing="spacious" color="currentColor" />}
									onBlur={onStopSavedSearch}
								/>
							</Box>
						</Box>
						{!textFilterValue.length ? (
							<>
								<Box paddingBlock="space.100">
									<Heading size="xxsmall">{formatMessage(messages.defaultFilters)}</Heading>
								</Box>
								{defaultFilterWithComponentList.map((item) => item.component)}
								{sharedWithMeWithComponentList && sharedWithMeWithComponentList.length > 0 && (
									<>
										<Box paddingBlock="space.100">
											<Heading size="xxsmall">{formatMessage(messages.sharedWithMe)}</Heading>
										</Box>
										{sharedWithMeWithComponentList.map((item) => item.component)}
									</>
								)}
								{createdByMeWithComponentList && createdByMeWithComponentList.length > 0 && (
									<>
										<Box paddingBlock="space.100">
											<Heading size="xxsmall">{formatMessage(messages.createdByMe)}</Heading>
										</Box>
										{createdByMeWithComponentList.map((item) => item.component)}
									</>
								)}
							</>
						) : (
							<>
								{foundItems.length === 0 ? (
									<Box
										key="no-item-found"
										paddingBlock="space.100"
										xcss={noSearchResultsFoundStyles}
									>
										{formatMessage(messages.viewAllFiltersNoResultsFoundText)}
									</Box>
								) : (
									foundItems
								)}
							</>
						)}
					</Stack>
				</MenuSection>
			</FlyoutMenuItemContent>
		</FlyoutMenuItem>
	);
};

const stickyBarStyles = xcss({
	position: 'sticky',
	top: '0',
	backgroundColor: 'utility.elevation.surface.current',
	zIndex: 'navigation',
});

const textfieldStyles = xcss({
	paddingRight: 'space.100',
});

const noSearchResultsFoundStyles = xcss({
	fontStyle: 'italic',
});

const flyoutContentStyles = xcss({
	maxHeight: '500px',
	overflowY: 'auto',
});
