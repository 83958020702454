import {
  HelpHelpCenterAriResourceOwner,
  HelpHelpCenterAriResourceType
} from "./chunk-UIZK5L5W.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/help/help-center/manifest.ts
var helpHelpCenterAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: HelpHelpCenterAriResourceOwner,
  resourceType: HelpHelpCenterAriResourceType,
  resourceIdSlug: "{activationId}/{helpCenterId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    helpCenterId: /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/
    // eslint-disable-line no-useless-escape
  }
};

// src/help/help-center/index.ts
var HelpHelpCenterAri = class _HelpHelpCenterAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._activationId = opts.resourceIdSegmentValues.activationId, this._helpCenterId = opts.resourceIdSegmentValues.helpCenterId;
  }
  get activationId() {
    return this._activationId;
  }
  get helpCenterId() {
    return this._helpCenterId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: helpHelpCenterAriStaticOpts.qualifier,
      platformQualifier: helpHelpCenterAriStaticOpts.platformQualifier,
      cloudId: void 0,
      resourceOwner: helpHelpCenterAriStaticOpts.resourceOwner,
      resourceType: helpHelpCenterAriStaticOpts.resourceType,
      resourceId: `${opts.activationId}/${opts.helpCenterId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        helpCenterId: opts.helpCenterId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, helpHelpCenterAriStaticOpts);
    return new _HelpHelpCenterAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, helpHelpCenterAriStaticOpts);
    return new _HelpHelpCenterAri(opts);
  }
  getVariables() {
    return {
      activationId: this.activationId,
      helpCenterId: this.helpCenterId
    };
  }
};

export {
  HelpHelpCenterAri
};
