import { useMemo } from 'react';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	toProjectType,
	type ProjectType,
	PRODUCT_DISCOVERY_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useJsmPremiumPillExperiment } from '@atlassian/jira-edition-awareness-dropdown/src/common/utils.tsx';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import {
	getEditionForProject,
	type ApplicationEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useSiteHasOnlyJira } from '@atlassian/jira-billing/src/controllers/explicitly-licensed-products/use-site-has-only-jira.tsx';
import type { Product, Edition } from '@atlassian/edition-awareness';

type ProductAndEditionInfo = {
	product: Product | undefined;
	edition: Edition | undefined;
};

/**
 * Condenses some logic from ProductAndEditionChecker. This is to be used
 * during the integration of Jira with the AudienceAndTiming code in Platform.
 * This will eventually be cleaned up as more EA code is shifted to Platform
 * @returns { product, edition, buttonProps }
 */
export const useProductAndEdition = (): ProductAndEditionInfo => {
	const appEditions = useAppEditions();
	const { loading, data: project } = useProject();
	const siteHasOnlyJira = useSiteHasOnlyJira();

	const { getIsEligibleForJsmPremiumPillExperiment } = useJsmPremiumPillExperiment();
	// Show premium pill button for JSM premium trial experiment
	const projectType = useMemo(() => {
		if (loading) return;

		const type = toProjectType(project?.type);

		if (type) return type;

		if (siteHasOnlyJira) return SOFTWARE_PROJECT;

		if (getIsEligibleForJsmPremiumPillExperiment()) {
			// If the user is on the JSM assets page, we want to default the project type to JSM
			return SERVICE_DESK_PROJECT;
		}
	}, [loading, project?.type, siteHasOnlyJira, getIsEligibleForJsmPremiumPillExperiment]);

	const localEdition = useMemo(() => {
		if (!projectType) return;

		return getEditionForProject(projectType, appEditions);
	}, [appEditions, projectType]);

	const { product, edition } = useMemo(() => {
		if (!projectType || !localEdition) {
			// We don't have a product or edition, so return nothing
			return {};
		}

		if (projectType === CORE_PROJECT && !siteHasOnlyJira) {
			// We are supporting only JWM implicit licensing when the parent license is JSW
			// Do not show on core products if site does not have only Jira
			return {};
		}
		return {
			product: getEaProduct(projectType),
			edition: getEaEdition(localEdition),
		};
	}, [localEdition, projectType, siteHasOnlyJira]);

	return {
		product,
		edition,
	};
};

const getEaEdition = (e: ApplicationEdition): Edition | undefined => {
	if (e === 'FREE_EDITION') return 'free';
	if (e === 'STANDARD_EDITION') return 'standard';
	if (e === 'PREMIUM_EDITION') return 'premium';
};

const getEaProduct = (p: ProjectType): Product | undefined => {
	if (p === SOFTWARE_PROJECT) return 'jira';
	if (p === PRODUCT_DISCOVERY_PROJECT) return 'jira-product-discovery';
	if (p === SERVICE_DESK_PROJECT) return 'jira-service-management';
	if (p === CORE_PROJECT) return 'jira';
};
