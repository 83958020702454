import React from 'react';
import { MenuGroup } from '@atlaskit/menu';
import {
	MENU_ID_CUSTOMER_HUBS,
	MENU_ID_CUSTOMER_SERVICE,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const CUSTOMER_SERVICE_HREF = '/jira/helpcenter';

export const CustomerServiceMenuLinkItem = () => {
	const customerServiceMenuId = fg('jcs_project_type_m3')
		? L1_MENU_ID.CUSTOMER_HUBS
		: L1_MENU_ID.CUSTOMER_SERVICE;
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(customerServiceMenuId);

	const menuId = fg('jcs_project_type_m3') ? MENU_ID_CUSTOMER_HUBS : MENU_ID_CUSTOMER_SERVICE;

	return (
		<Nav4MenuLinkItem
			testId="navigation-apps-sidebar-nav4-sidebars-content-customer-service.ui.sidenav-customer-service"
			href={CUSTOMER_SERVICE_HREF}
			menuId={menuId}
			elemBefore={icon}
			actionsOnHover={
				<MoreNavMenuButton
					MenuItems={() => (
						<MenuGroup>
							<HideL1MenuItemSection menuId={menuId} hasSeparator={false} />
						</MenuGroup>
					)}
				/>
			}
		>
			{formattedMessage}
		</Nav4MenuLinkItem>
	);
};
