import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	shouldLoadUIFBoardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import {
	getNavigationResources,
	getUserBoardNavigationResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { userBoardSettingsRoute } from '@atlassian/jira-router-routes-user-board-routes/src/userBoardSettingsRoute.tsx';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/ClassicBoardSkeleton.tsx';
import { boardSettingsPageEntryPoint } from '@atlassian/jira-router-routes-software-classic-board-settings-entries/src/ui/page-entry-point/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';

/**
 * Jira Software user-located board-settings SPA route.
 *
 * NOTE: This component is mostly a copy of .../router-routes/software/classic/board-settings/entries/src/classicSoftwareBoardSettingsRouteEntry.tsx
 */
export const userBoardSettingsRouteEntry = createEntry(userBoardSettingsRoute, () => ({
	group: 'classic-software',
	ufoName: 'classic-board',
	skeleton: ClassicBoardSkeleton,
	entryPoint: () => boardSettingsPageEntryPoint,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		sidebarId: fg('blu-5799-jira-software-board-entrypoint-route')
			? SIDEBAR_ID.BOARD_SETTINGS
			: undefined,
	},

	layout: genericProjectLayout,

	// This list is based on the resources needed by ./userBoardRouteEntry.tsx
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		...getUserBoardNavigationResources(),
		resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
		getEditModelResource(),
		getBoardDetailsResource(),
	],
}));
