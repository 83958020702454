import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsIssueWorkflowEditRoute } from '@atlassian/jira-router-routes-admin-pages-workflows-routes/src/globalSettingsIssueWorkflowEditRoute.tsx';
import { conditionalPostOfficeJiraTopBannerResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';
import EditWorkflow, {
	LazyEditWorkflow,
} from './ui/spa/admin-pages/workflows/edit-workflow/index.tsx';

export const globalSettingsIssueWorkflowEditRouteEntry = createEntry(
	globalSettingsIssueWorkflowEditRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'global-admin.issues.edit-workflow',
		component: EditWorkflow,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			conditionalPostOfficeJiraTopBannerResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyEditWorkflow],
	}),
);
