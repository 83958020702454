/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useRef, useState } from 'react';

import { cssMap, jsx } from '@compiled/react';
import { bind } from 'bind-event-listener';

import { BANNER_HEIGHT, TOP_NAVIGATION_HEIGHT } from '@atlaskit/page-layout';
import { token } from '@atlaskit/tokens';

import { CONVERSATION_ASSISTANT_CONTAINER_WIDTH } from '../../constants/public';

const styles = cssMap({
	root: {
		right: token('space.0'),
		display: 'flex',
		position: 'fixed',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `calc(100vh - ${TOP_NAVIGATION_HEIGHT} - ${BANNER_HEIGHT})`,
	},

	handle: {
		cursor: 'ew-resize',
		userSelect: 'none',
		position: 'absolute',
		top: token('space.200'),
		bottom: token('space.0'),
		left: token('space.0'),
		width: '24px',
		borderTopLeftRadius: token('border.radius.200'),
		transition: 'border 0.2s ease-in-out',
		borderLeft: '2px solid transparent',
		'&:hover': {
			borderLeft: `2px solid ${token('color.background.information.bold.hovered')}`,
		},
	},

	handleSpacer: {
		width: '24px',
		position: 'absolute',
		display: 'block',
		left: '-24px',
		top: token('space.0'),
		bottom: token('space.0'),
	},
});

type ResizeWrapperProps = React.PropsWithChildren<{
	initialWidth?: number;
	maxWidth?: number | string;
	minWidth?: number | string;
	onResizeComplete?: (width: number) => void;
}>;

export const ResizeWrapper = ({
	children,
	onResizeComplete,
	initialWidth = CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
	minWidth = CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
	maxWidth = '50vw',
}: ResizeWrapperProps) => {
	const [width, setWidth] = useState<number>(initialWidth);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const isDraggingRef = useRef<boolean>(false);
	const initialMousePositionRef = useRef<number>(0);
	const prevDistanceMovedRef = useRef<number>(0);
	const rafRef = useRef<number | null>(null);

	const getWrapperWidth = () => {
		return wrapperRef.current?.getBoundingClientRect()?.width || 0;
	};

	const handleMouseMove = useCallback(
		(event: MouseEvent) => {
			if (!isDraggingRef.current) {
				return;
			}

			const distanceMoved = event.clientX - initialMousePositionRef.current;
			let updatedWidth: number;
			const currentWidth = getWrapperWidth();

			// Ignore events without change
			if (distanceMoved === prevDistanceMovedRef.current || distanceMoved === 0) {
				return;
			}

			// Acknowledge latest direction adjustment
			prevDistanceMovedRef.current = distanceMoved;

			// Clear queued animation frame
			if (rafRef.current !== null) {
				cancelAnimationFrame(rafRef.current);
			}

			rafRef.current = requestAnimationFrame(() => {
				// Update widths with upper and lower limits respected when `minWidth` and `maxWidth` are `number`
				// When `string`, we'll respect this value on the styles of the wrapper for use-cases such as '100%', '70vw' etc.
				if (distanceMoved < 0) {
					updatedWidth = currentWidth + Math.abs(distanceMoved);
					if (typeof maxWidth === 'number' && !isNaN(maxWidth)) {
						updatedWidth = Math.min(updatedWidth, maxWidth);
					}
				} else {
					updatedWidth = currentWidth - distanceMoved;
					if (typeof minWidth === 'number' && !isNaN(minWidth)) {
						updatedWidth = Math.max(updatedWidth, minWidth);
					}
				}

				setWidth(updatedWidth);
				initialMousePositionRef.current = event.clientX;
			});
		},
		[maxWidth, minWidth],
	);

	const handleMouseDown = useCallback((event: React.MouseEvent) => {
		// Indicate dragging
		isDraggingRef.current = true;
		// Indicate starting point
		initialMousePositionRef.current = event.clientX;
	}, []);

	const handleMouseUp = useCallback(() => {
		if (isDraggingRef.current && onResizeComplete) {
			onResizeComplete(wrapperRef.current?.getBoundingClientRect().width || width);
		}
		isDraggingRef.current = false;
	}, [onResizeComplete, width]);

	useEffect(() => {
		const mouseMoveHandler = bind(window, {
			type: 'mousemove',
			listener: handleMouseMove,
		});
		const mouseUpHandler = bind(window, {
			type: 'mouseup',
			listener: handleMouseUp,
		});

		return () => {
			mouseMoveHandler();
			mouseUpHandler();
			if (rafRef.current !== null) {
				cancelAnimationFrame(rafRef.current);
			}
		};
	}, [handleMouseMove, handleMouseUp]);

	return (
		<div
			css={styles.root}
			ref={wrapperRef}
			role="presentation"
			data-testid="panel-resize-wrapper"
			style={{
				width,
				maxWidth,
				minWidth,
			}}
		>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div data-testid="panel-resize-handle" onMouseDown={handleMouseDown} css={styles.handle}>
				<div css={styles.handleSpacer} />
			</div>

			{children}
		</div>
	);
};
