import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { softwareClassicProformaIssueFormsDirectEmbedRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareClassicProformaIssueFormsDirectEmbedRoute.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import ProformaIssueFormsDirectEmbed, {
	LazyProformaIssueFormsDirectEmbed,
} from './ui/proforma/issue-forms-direct/embed/index.tsx';

export const softwareClassicProformaIssueFormsDirectEmbedRouteEntry = createEntry(
	softwareClassicProformaIssueFormsDirectEmbedRoute,
	() => ({
		group: 'classic-software',
		component: ProformaIssueFormsDirectEmbed,
		layout: chromelessLayout,
		resources: [projectContextResource],
		forPaint: [LazyProformaIssueFormsDirectEmbed],
		perfMetricKey: 'issue-forms-direct-embed',
	}),
);
