import React, { useMemo, useCallback, useState } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { expVal } from '@atlassian/jira-feature-experiments';
import { Nav4OnboardingComponentNames } from '@atlassian/jira-atlassian-onboarding-nav4/src/types.tsx';
import { OnboardingSpotlightMaybe } from '@atlassian/jira-atlassian-onboarding-nav4/src/ui/onboarding-spotlight-maybe/index.tsx';
import { UpdateProjectsProvider } from '@atlassian/jira-navigation-apps-common/src/controllers/update-project-context/index.tsx';
import { MENU_ID_PROJECTS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useRefetchWhenIsSelected } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-refetch-when-is-selected/index.tsx';
import { useSubscribeAndUpdateFavorites } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/services/set-favorite/index.tsx';
import { useStableItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-stable-items/index.tsx';
import { Nav4ProjectsEmptyContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/common/ui/empty-content/Nav4ProjectsEmptyContent.tsx';
import { Nav4ProjectMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/common/ui/project-menu-item/Nav4ProjectMenuItem.tsx';
import {
	useSidebarSettingsAri,
	useOwnerAri,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/services/ari/use-ari.tsx';
import type { ProjectToRestore } from '@atlassian/jira-navigation-project-action-menu/src/types.tsx';
import ProjectRestoreModal from '@atlassian/jira-project-restore-modal/src/ui/index.tsx';
import type { Nav4ProjectsContent$key } from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContent.graphql';
import QUERY, {
	type Nav4ProjectsContentQuery,
	type Nav4ProjectsContentQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContentQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { Nav4Recommendations } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-growth-recommendations/src/index.tsx';
import { Nav4ProjectsSections } from './Nav4ProjectsSections.tsx';
import { Nav4ProjectsSectionsWithDisplaySettings } from './Nav4ProjectsSectionsWithDisplaySettings.tsx';

const MAX_RECENT_PROJECTS = 5;
export const MAX_FAVORITE_PROJECTS = 15;

type Nav4ProjectsContentProps = {
	queryRef: Nav4ProjectsContent$key;
};

export function Nav4ProjectsContent({ queryRef }: Nav4ProjectsContentProps) {
	const cloudId = useCloudId();
	const { getStableItems } = useStableItems();
	const { isSelectedPath } = useSidebarNav4();
	const [projectToRestore, setProjectToRestore] = useState<ProjectToRestore | null>(null);

	// https://jplat.jira.atlassian.cloud/browse/BLU-2719
	// We intentionally request 10 starred and 15 recent projects.
	// Then, if all 10 starred projects were recently visited, we still show 5 recent projects as well.
	const data = useRefetchableFragment<Nav4ProjectsContentQuery, Nav4ProjectsContent$key>(
		graphql`
			fragment Nav4ProjectsContent on Query
			@refetchable(queryName: "Nav4ProjectsContentQuery")
			@argumentDefinitions(
				currentURL: { type: URL }
				displaySettingsAPart1Enabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/blu-6315-display-settings-a-part-1.relayprovider"
				}
				displaySettingsPostGaEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/blu-6987-display-settings-post-ga-experiment.relayprovider"
				}
			) {
				...Nav4Recommendations
				...Nav4ProjectsSectionsWithDisplaySettings
					@arguments(entityAri: $entityAriProjects, ownerAri: $ownerAri)
					@include(if: $displaySettingsAPart1Enabled)
					@skip(if: $displaySettingsPostGaEnabled)
				jira @required(action: THROW) {
					favouriteProjects: favourites(
						cloudId: $cloudId
						filter: { type: PROJECT, sort: { order: DESC } }
						first: 15
					) @required(action: THROW) @skip(if: $displaySettingsPostGaEnabled) {
						__id
						pageInfo {
							hasNextPage
						}
						edges {
							node @required(action: THROW) {
								... on JiraProject {
									id @required(action: THROW)
									projectKey: key @required(action: THROW)
									...Nav4ProjectMenuItem
								}
							}
						}
					}
					recentProjects(cloudId: $cloudId, currentURL: $currentURL, first: 20)
						@required(action: THROW)
						@skip(if: $displaySettingsPostGaEnabled) {
						__id
						edges {
							node @required(action: THROW) {
								... on JiraProject {
									id @required(action: THROW)
									projectKey: key @required(action: THROW)
									...Nav4ProjectMenuItem
								}
							}
						}
					}
					jwmLicensing(cloudId: $cloudId) {
						currentUserSeatEdition
					}
				}
				jira_projectsSidebarMenu(cloudId: $cloudId, currentURL: $currentURL)
					@required(action: THROW)
					@include(if: $displaySettingsPostGaEnabled)
					@optIn(to: "JiraSidebarMenu") {
					current {
						id @required(action: THROW)
						projectKey: key @required(action: THROW)
						...Nav4ProjectMenuItem
					}
					favourites(first: 15) @required(action: THROW) {
						__id
						pageInfo {
							hasNextPage
						}
						edges {
							node @required(action: THROW) {
								id @required(action: THROW)
								projectKey: key @required(action: THROW)
								...Nav4ProjectMenuItem
							}
						}
					}
					recents(first: 20) @required(action: THROW) {
						__id
						edges {
							node @required(action: THROW) {
								id @required(action: THROW)
								projectKey: key @required(action: THROW)
								...Nav4ProjectMenuItem
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	const [{ jira, jira_projectsSidebarMenu: projectsSidebarMenu }, refetch] = data;

	const { jwmLicensing } = jira;

	const favouriteProjects = expVal(
		'blu-6987-display-settings-post-ga-experiment',
		'isEnabled',
		false,
	)
		? projectsSidebarMenu?.favourites
		: jira.favouriteProjects;

	const recentProjects = expVal('blu-6987-display-settings-post-ga-experiment', 'isEnabled', false)
		? projectsSidebarMenu?.recents
		: jira.recentProjects;

	// throwing here enforces the mutually exclusive @include/@skip directives which otherwise would be handled by @required
	if (!favouriteProjects || !recentProjects) {
		throw new Error(
			'JiraProjectConnection is missing in Nav4ProjectsContent for favourite or recent projects',
		);
	}

	const currentProject = projectsSidebarMenu?.current;

	const entityAriProjects = useSidebarSettingsAri('projects');
	const ownerAri = useOwnerAri();

	const queryVariables = useMemo(
		() => ({ cloudId, entityAriProjects, ownerAri }),
		[cloudId, entityAriProjects, ownerAri],
	);

	useRefetchWhenIsSelected<Nav4ProjectsContent$key, Nav4ProjectsContentQuery$variables>({
		cloudId,
		isSelectedPath,
		QUERY,
		refetch,
		menuId: MENU_ID_PROJECTS,
		queryVariables,
	});

	const refetchOnChange = useCallback(
		({ onComplete }: { onComplete: () => void }) => {
			refetch(queryVariables, {
				fetchPolicy: 'store-and-network',
				onComplete,
			});
		},
		[queryVariables, refetch],
	);
	useSubscribeAndUpdateFavorites({
		entityTypename: 'JiraProject',
		favouriteConnectionId: favouriteProjects.__id,
		recentConnectionId: recentProjects.__id,
		refetch: refetchOnChange,
	});

	const favorites = useMemo(() => {
		const ids = favouriteProjects.edges?.map((edge) => edge?.node.id).filter(Boolean) ?? [];

		const list =
			favouriteProjects.edges?.filter(Boolean).map((edge) => {
				return (
					<Nav4ProjectMenuItem
						key={edge.node.projectKey}
						queryRef={edge.node}
						isCorePremiumUserSeat={jwmLicensing?.currentUserSeatEdition === 'PREMIUM'}
						setProjectToRestore={setProjectToRestore}
						// Show onboarding spotlight on the first item
						spotlight={
							edge.node.id === ids[0]
								? Nav4OnboardingComponentNames.SIDEBAR_PROJECT_SPOTLIGHT
								: undefined
						}
					/>
				);
			}) ?? [];

		return {
			idSet: new Set(ids),
			list: list.slice(0, MAX_FAVORITE_PROJECTS),
			hasItems: Boolean(list.length),
			hasNextPage: favouriteProjects.pageInfo?.hasNextPage || list.length > MAX_FAVORITE_PROJECTS,
		};
	}, [favouriteProjects, jwmLicensing]);

	const recentItems = useMemo(() => {
		const recentItemsIdSet = new Set(
			recentProjects.edges?.map((edge) => edge?.node.id).filter(Boolean) ?? [],
		);

		const currentHiddenProject =
			currentProject &&
			!favorites.idSet.has(currentProject.id) &&
			!recentItemsIdSet.has(currentProject.id) &&
			expVal('blu-6987-display-settings-post-ga-experiment', 'isEnabled', false)
				? [currentProject]
				: [];

		const allNodes =
			recentProjects.edges
				?.filter(Boolean)
				.filter((edge) => edge.node.id && !favorites.idSet.has(edge.node.id))
				.map((edge) => edge.node) ?? [];

		const nodes = [...currentHiddenProject, ...allNodes].slice(0, MAX_RECENT_PROJECTS);

		const stableOrderedNodes = getStableItems({ nodes, idKey: 'id' });
		const firstItemKey = stableOrderedNodes[0]?.projectKey;

		const list = stableOrderedNodes.map((node) => {
			// Show onboarding spotlight on the first item if there are no favorites
			const showSpotlight = !favorites.hasItems && node.projectKey === firstItemKey;

			return (
				<Nav4ProjectMenuItem
					key={node.projectKey}
					queryRef={node}
					isCorePremiumUserSeat={jwmLicensing?.currentUserSeatEdition === 'PREMIUM'}
					setProjectToRestore={setProjectToRestore}
					spotlight={
						showSpotlight ? Nav4OnboardingComponentNames.SIDEBAR_PROJECT_SPOTLIGHT : undefined
					}
				/>
			);
		});

		return { list, hasItems: Boolean(list.length) };
	}, [
		recentProjects.edges,
		getStableItems,
		favorites.idSet,
		jwmLicensing?.currentUserSeatEdition,
		favorites.hasItems,
		currentProject,
	]);

	const restoreProjectModal = useCallback(() => {
		if (projectToRestore) {
			return (
				<ModalTransition>
					<ProjectRestoreModal
						{...projectToRestore}
						onProjectRestoreSuccess={() => {
							setProjectToRestore(null);
							refetch(queryVariables, {
								fetchPolicy: 'store-and-network',
							});
						}}
						onProjectRestoreFailure={() => {
							setProjectToRestore(null);
						}}
						onClose={() => setProjectToRestore(null)}
					/>
				</ModalTransition>
			);
		}
	}, [queryVariables, projectToRestore, refetch]);

	if (!favorites.hasItems && !recentItems.hasItems) {
		return (
			<OnboardingSpotlightMaybe spotlight={Nav4OnboardingComponentNames.SIDEBAR_PROJECT_SPOTLIGHT}>
				<Nav4ProjectsEmptyContent />
				<Nav4Recommendations queryRef={data[0]} />
				{restoreProjectModal()}
			</OnboardingSpotlightMaybe>
		);
	}

	return (
		<UpdateProjectsProvider
			favouriteConnectionId={favouriteProjects.__id}
			recentConnectionId={recentProjects.__id}
		>
			{!expVal('blu-6987-display-settings-post-ga-experiment', 'isEnabled', false) &&
			expVal('blu-6315-display-settings-a-part-1', 'isEnabled', false) ? (
				<Nav4ProjectsSectionsWithDisplaySettings
					favoriteItems={favorites}
					recentItems={recentItems}
					queryRef={data[0]}
				/>
			) : (
				<Nav4ProjectsSections favoriteItems={favorites} recentItems={recentItems} />
			)}
			<Nav4Recommendations queryRef={data[0]} />
			{restoreProjectModal()}
		</UpdateProjectsProvider>
	);
}
