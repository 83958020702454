import { useCallback, useMemo } from 'react';
import { PROJECTS_NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-id.tsx';
import {
	DROPDOWN_ITEM,
	BUTTON,
	NAVIGATION_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	CUSTOM_ITEM_BUTTON,
	CUSTOM_ITEM,
	FIXED_ITEM,
	FIXED_ITEM_DROPDOWN_ACTION,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	MEATBALL_DROPDOWN,
	PROJECTS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import {
	PROJECT,
	BOARD,
	CHANGE_VIEW,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import { LEVEL_TWO } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { BoardParams, ProjectParams } from '../types.tsx';
import { getSectionByStarredOrRecent } from '../utils.tsx';

export const useProjectsAnalyticsL2 = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const commonAttributes = useMemo(
		() => ({
			level: LEVEL_TWO,
		}),
		[],
	);

	const sendMeatBallDropDownManageProjectsAnalyticsEvent = useCallback(() => {
		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: DROPDOWN_ITEM,
		});

		const attributes = {
			...commonAttributes,
			componentType: FIXED_ITEM_DROPDOWN_ACTION,
			itemId: 'manageProjects',
			parentItemId: MEATBALL_DROPDOWN,
		};
		fireUIAnalytics(event, PROJECTS_NAVIGATION_ITEM, attributes);
	}, [commonAttributes, createAnalyticsEvent]);

	// board navigation item under each project when project is expandable menu
	const sendBoardNavigationItemInsideProjectsAnalyticsEvent = useCallback(
		({ boardId, projectId, recent, starred, projectType }: BoardParams) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: NAVIGATION_ITEM,
			});

			const attributes = {
				...commonAttributes,
				componentType: CUSTOM_ITEM,
				itemId: boardId,
				itemType: BOARD,
				parentItemId: projectId,
				parentItemType: PROJECT,
				projectType,
				section: getSectionByStarredOrRecent({ starred, recent }),
			};
			fireUIAnalytics(event, PROJECTS_NAVIGATION_ITEM, attributes);
		},
		[commonAttributes, createAnalyticsEvent],
	);

	// meatball menu on projects
	const getMeatBallDropDownOnProjectsAnalytics = useCallback(
		({ recent, starred, projectId, projectType }: ProjectParams) => {
			return {
				...commonAttributes,
				actionSubjectId: PROJECTS_NAVIGATION_ITEM,
				componentType: CUSTOM_ITEM_BUTTON,
				itemId: MEATBALL_DROPDOWN,
				parentItemId: projectId,
				parentItemType: PROJECT,
				projectType,
				section: getSectionByStarredOrRecent({ starred, recent }),
			};
		},
		[commonAttributes],
	);

	/** @deprecated, replace with getMeatBallDropDownOnProjectsAnalytics  */
	const sendMeatBallDropDownOnProjectsAnalyticsEvent = useCallback(
		(params: ProjectParams) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: BUTTON,
			});

			const { actionSubjectId, ...attributes } = getMeatBallDropDownOnProjectsAnalytics(params);
			fireUIAnalytics(event, actionSubjectId, attributes);
		},
		[createAnalyticsEvent, getMeatBallDropDownOnProjectsAnalytics],
	);

	// create board button
	const sendCreateBoardOnBoardMenuItemAnalyticsEvent = useCallback(
		({ recent, starred, projectId, projectType }: ProjectParams) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: BUTTON,
			});

			const attributes = {
				...commonAttributes,
				componentType: CUSTOM_ITEM_BUTTON,
				itemId: 'createBoard',
				parentItemId: projectId,
				parentItemType: PROJECT,
				projectType,
				section: getSectionByStarredOrRecent({ starred, recent }),
			};
			fireUIAnalytics(event, PROJECTS_NAVIGATION_ITEM, attributes);
		},
		[commonAttributes, createAnalyticsEvent],
	);

	// fixed item - view all boards link
	const sendViewAllBoardsAnalyticsEvent = useCallback(
		({ projectId, projectType }: { projectId: string; projectType: string | null | undefined }) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: NAVIGATION_ITEM,
			});

			const attributes = {
				...commonAttributes,
				componentType: FIXED_ITEM,
				itemId: 'viewAllBoards',
				parentItemId: projectId,
				parentItemType: PROJECT,
				projectType,
			};
			fireUIAnalytics(event, PROJECTS_NAVIGATION_ITEM, attributes);
		},
		[commonAttributes, createAnalyticsEvent],
	);

	// fixed item - Change View in Meatball Menu on Projects section
	const sendChangeViewAnalyticsEvent = useCallback(() => {
		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: DROPDOWN_ITEM,
		});

		const attributes = {
			...commonAttributes,
			componentType: FIXED_ITEM_DROPDOWN_ACTION,
			parentItemId: PROJECTS,
			itemId: CHANGE_VIEW,
		};
		fireUIAnalytics(event, PROJECTS_NAVIGATION_ITEM, attributes);
	}, [commonAttributes, createAnalyticsEvent]);

	return {
		sendMeatBallDropDownManageProjectsAnalyticsEvent,
		sendMeatBallDropDownOnProjectsAnalyticsEvent,
		sendBoardNavigationItemInsideProjectsAnalyticsEvent,
		sendCreateBoardOnBoardMenuItemAnalyticsEvent,
		sendViewAllBoardsAnalyticsEvent,
		sendChangeViewAnalyticsEvent,
	};
};
