/**
 * @generated SignedSource<<4581c0468a5d4ef30519c5609a70ff06>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type OperationsMenuForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AlertsMenuItemContent" | "AlertsMenuItemContentNew">;
  readonly " $fragmentType": "OperationsMenuForLanding";
};
export type OperationsMenuForLanding$key = {
  readonly " $data"?: OperationsMenuForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperationsMenuForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "OperationsMenuForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AlertsMenuItemContentNew"
    },
    {
      "kind": "FragmentSpread",
      "name": "AlertsMenuItemContent"
    }
  ],
  "type": "Query"
};

(node as any).hash = "539a9dffb7945e99bc6a650607eca8e4";

export default node;
