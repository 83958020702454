import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocReportsUnsubscribeRoute } from '@atlassian/jira-router-routes-eoc-reports-routes/src/eocReportsUnsubscribeRoute.tsx';
import {
	LazyOpsReportsUnsubscribe,
	OpsReportsUnsubscribe,
} from './ui/spa/eoc/ops-reports/index.tsx';

export const eocReportsUnsubscribeRouteEntry = createEntry(eocReportsUnsubscribeRoute, () => ({
	group: 'eoc',
	component: OpsReportsUnsubscribe,
	layout: opsTeamLayout,
	resources: [...getNavigationResources()],
	navigation: {
		menuId: MENU_ID.PEOPLE,
	},
	forPaint: [LazyAtlassianNavigation, LazyOpsReportsUnsubscribe],
}));
