import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { servicedeskCmdbInsightObjectSchemaConfigurationRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightObjectSchemaConfigurationRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import ObjectSchemaConfiguration, {
	LazyServicedeskCmdbObjectSchemaConfiguration,
} from './ui/object-schema-configuration/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbInsightObjectSchemaConfigurationRouteEntry = createEntry(
	servicedeskCmdbInsightObjectSchemaConfigurationRoute,
	() => ({
		group: 'servicedesk',
		layout: globalLayout,
		navigation: { menuId: MENU_ID.INSIGHT },
		component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : ObjectSchemaConfiguration,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaConfiguration],
		perfMetricKey: 'service-management.insight-cmdb.interact-with-object-schema-config',
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_SCHEMA_CONFIGURATION),
				teamName: 'krispy-krew',
			},
		},
	}),
);
