import { useContext } from 'react';

import { BFFContext } from '../context';

export function useConsumer() {
	const {
		value: { consumer },
	} = useContext(BFFContext);

	return {
		consumer,
	};
}
