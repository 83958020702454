import {
	MENU_ID_APPS,
	MENU_ID_ASSETS,
	MENU_ID_DASHBOARDS,
	MENU_ID_FILTERS,
	MENU_ID_GOALS,
	MENU_ID_OPERATIONS,
	MENU_ID_OVERVIEWS,
	MENU_ID_PLANS,
	MENU_ID_PROJECTS,
	MENU_ID_RECENT,
	MENU_ID_ROADMAPS,
	MENU_ID_STARRED,
	MENU_ID_TEAMS,
	MENU_ID_CUSTOMER_SERVICE,
	MENU_ID_CUSTOMER_SERVICE_ANALYTICS,
	type ConfigurableMenuId,
	MENU_ID_GLOBAL_APP_ASSETS,
	MENU_ID_GLOBAL_APP_GOALS,
	MENU_ID_CUSTOMER_HUBS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { NAVIGATION_ITEM } from './action-subject-type.tsx';
import { V4 } from './nav-state.tsx';
import { TOP } from './navigation-container.tsx';
import { GLOBAL } from './navigation-layer.tsx';
import { GLOBAL_APP_SHORTCUT_ASSETS, GLOBAL_APP_SHORTCUT_GOALS } from './section-type.tsx';

const SIDEBAR_TOGGLE = 'sidebarToggle';
const SIDEBAR_TOGGLE_ACTION_COLLAPSE = 'collapse';
const SIDEBAR_TOGGLE_ACTION_EXPAND = 'expand';
const CLICKED = 'clicked';

/*
    - This payload is used when SideNavToggleButton's onClick event is fired.
    - Note that the navigationContainer is TOP
    - This is because this button is shown in both mobile and desktop in the top bar when it is is Expand mode.
      and when it is in Collapse mode, it is shown in top bar as well but only for mobile view.
*/
export const getSideNavToggleButtonAnalyticEventPayload = (
	isSideNavVisible: boolean | undefined,
	stage: string,
) => {
	const toggleAction = isSideNavVisible
		? SIDEBAR_TOGGLE_ACTION_COLLAPSE
		: SIDEBAR_TOGGLE_ACTION_EXPAND;
	const payload = {
		action: CLICKED,
		actionSubject: NAVIGATION_ITEM,
		actionSubjectId: SIDEBAR_TOGGLE,
		attributes: {
			navigationLayer: GLOBAL,
			navigationContainer: TOP,
			stage,
			navState: V4,
			toggleAction,
		},
	};
	return payload;
};

export const MENU_ID_ANALYTIC_EVENT_IDS: Record<ConfigurableMenuId, string> = {
	[MENU_ID_APPS]: 'appsNavigationMenuItem',
	[MENU_ID_ASSETS]: 'assetsNavigationMenuItem',
	[MENU_ID_DASHBOARDS]: 'dashboardsNavigationMenuItem',
	[MENU_ID_FILTERS]: 'filtersNavigationMenuItem',
	[MENU_ID_GOALS]: 'goalsNavigationMenuItem',
	[MENU_ID_OPERATIONS]: 'operationsNavigationMenuItem',
	[MENU_ID_OVERVIEWS]: 'overviewsNavigationMenuItem',
	[MENU_ID_PLANS]: 'plansNavigationMenuItem',
	[MENU_ID_PROJECTS]: 'projectsNavigationMenuItem',
	[MENU_ID_RECENT]: 'recentNavigationMenuItem',
	[MENU_ID_STARRED]: 'starredNavigationMenuItem',
	[MENU_ID_TEAMS]: 'teamsNavigationMenuItem',
	[MENU_ID_ROADMAPS]: 'roadmapsNavigationMenuItem',
	[MENU_ID_CUSTOMER_SERVICE]: 'customerServiceMenuItem',
	[MENU_ID_CUSTOMER_HUBS]: 'customerHubsMenuItem',
	[MENU_ID_CUSTOMER_SERVICE_ANALYTICS]: 'customerServiceAnalyticsMenuItem',
	[MENU_ID_GLOBAL_APP_ASSETS]: GLOBAL_APP_SHORTCUT_ASSETS,
	[MENU_ID_GLOBAL_APP_GOALS]: GLOBAL_APP_SHORTCUT_GOALS,
} as const;
