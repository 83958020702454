import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';

export const getIsInPreGaStage = () =>
	fg('jira_nav4_dogfooding') ||
	fg('jira_nav4_eap') ||
	fg('jira_nav4_beta_existing-customers') ||
	fg('jira_nav4_beta_sandboxes') ||
	expVal('jira_nav4_beta_free-customers', 'willShowNav4', false) ||
	expVal('jira_nav4_beta_new-customers', 'willShowNav4', false) ||
	expVal('confluence_jira_nav4_new_customers', 'willShowNav4', false);
