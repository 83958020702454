/**
 * @generated SignedSource<<9af3482c29caafe3c266b9a94e6bf614>>
 * @relayHash 33a398085fffc1c8c240023b01b17ecb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6531092ed29d33046fe36572f33533c333d5d1c7642ce67bd8f17687b4e69ba1

import type { ConcreteRequest, Query } from 'relay-runtime';
export type WorkSuggestionsUserPersona = "DEVELOPER" | "%future added value";
export type JiraHomeWithWorkSuggestionsQuery$variables = {
  cloudId: string;
  isSoftwareLicensed: boolean;
};
export type JiraHomeWithWorkSuggestionsQuery$data = {
  readonly workSuggestions?: {
    readonly userProfileByCloudId: {
      readonly persona: WorkSuggestionsUserPersona | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type JiraHomeWithWorkSuggestionsQuery = {
  response: JiraHomeWithWorkSuggestionsQuery$data;
  variables: JiraHomeWithWorkSuggestionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSoftwareLicensed"
  }
],
v1 = [
  {
    "condition": "isSoftwareLicensed",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "concreteType": "WorkSuggestions",
        "kind": "LinkedField",
        "name": "workSuggestions",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              }
            ],
            "concreteType": "WorkSuggestionsUserProfile",
            "kind": "LinkedField",
            "name": "userProfileByCloudId",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "persona"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "JiraHomeWithWorkSuggestionsQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JiraHomeWithWorkSuggestionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "6531092ed29d33046fe36572f33533c333d5d1c7642ce67bd8f17687b4e69ba1",
    "metadata": {},
    "name": "JiraHomeWithWorkSuggestionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2ffc84c440c4cba253c916b64a96af89";

export default node;
