import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const useSelectedConfigTab = () => {
	const storageProvider = createLocalStorageProvider('SelectedTabConfig');
	const SELECTED_CONFIG_TAB_KEY = 'selectedConfigTab';

	const getSelectedConfigTab = () => {
		if (fg('jfp-vulcan-browser-storage-migration')) {
			return storageProvider.get(SELECTED_CONFIG_TAB_KEY) || '';
		}
		return localStorage.getItem(SELECTED_CONFIG_TAB_KEY) || '';
	};

	const setSelectedConfigTab = (newConfigTab: string) => {
		if (fg('jfp-vulcan-browser-storage-migration')) {
			storageProvider.set(SELECTED_CONFIG_TAB_KEY, newConfigTab);
		} else {
			localStorage.setItem(SELECTED_CONFIG_TAB_KEY, `"${newConfigTab}"`);
		}
	};

	return { getSelectedConfigTab, setSelectedConfigTab };
};
