import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { customFiltersResource } from '@atlassian/jira-project-settings-custom-filters/src/services/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectSettingsSoftwareBoardsCustomFiltersRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareBoardsCustomFiltersRoute.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import CustomFiltersSettings, {
	LazyCustomFiltersSettings,
} from './ui/project-settings/custom-filters/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';

export const projectSettingsSoftwareBoardsCustomFiltersRouteEntry = createEntry(
	projectSettingsSoftwareBoardsCustomFiltersRoute,
	() => ({
		group: 'project-settings-software',
		ufoName: 'project-settings.custom-filters',
		component: CustomFiltersSettings,
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources(), customFiltersResource],
		forPaint: [LazyAtlassianNavigation, LazyCustomFiltersSettings],
	}),
);
