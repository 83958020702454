import { DashboardBackgroundScripts } from '@atlassian/jira-background-scripts-layout/src/ui/dashboard/index.tsx';
import { DASHBOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { dashboardWallboardRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardWallboardRoute.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { dashboardWallboardEntryPoint } from '@atlassian/jira-dashboard-wallboard/entrypoint.tsx';
import { dashboardResources } from './ui/index.tsx';

export const dashboardWallboardRouteEntry = createEntry(dashboardWallboardRoute, () => ({
	group: 'dashboard',
	entryPoint: () => dashboardWallboardPageEntryPoint,
	layout: createLayout({
		globalComponents: [DashboardBackgroundScripts],
		// we don't want nav bar and right panel etc to appear in wallboard, so we use chromeless mode
		isChromeless: true,
	}),
	resources: [themePreferenceResource, ...dashboardResources()],
	meta: {
		capability: DASHBOARD,
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));

const dashboardWallboardPageEntryPoint = createChromelessEntryPoint({
	main: dashboardWallboardEntryPoint,
});
