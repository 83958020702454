import React, { memo } from 'react';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/sidebar-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { Skeleton } from './skeleton/index.tsx';

const LazySidebarContent = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-help-center-settings" */ './main'
		),
);

const AsyncSidebar = () => (
	<SidebarBoundary
		id="nav4.sidebar.help-center-settings"
		packageName="jiraNavigationAppsSidebarNav4HelpCenterSettings"
		ufoName="nav4.sidebar.help-center-settings"
	>
		<Placeholder name="nav4.sidebar.help-center-settings" fallback={<Skeleton />}>
			<LazySidebarContent />
		</Placeholder>
	</SidebarBoundary>
);

export default memo(AsyncSidebar);
