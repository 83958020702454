import React, { type ComponentType, type FC } from 'react';
import { MenuGroup } from '@atlaskit/menu';
import type { ContentProps, PopupProps } from '@atlaskit/popup';

type OnClose = NonNullable<PopupProps['onClose']>;

type MoreNavMenuButtonMenuItemsProps = { onClose?: OnClose };

export type MoreNavMenuButtonSubmenuPassThroughProps = {
	MenuItems: ComponentType<MoreNavMenuButtonMenuItemsProps>;
};

type MoreNavMenuButtonSubmenuProps = MoreNavMenuButtonSubmenuPassThroughProps & ContentProps;

export const MoreNavMenuButtonSubmenu: FC<MoreNavMenuButtonSubmenuProps> = ({
	MenuItems,
	onClose,
}) => (
	<MenuGroup minWidth={250}>
		<MenuItems onClose={onClose} />
	</MenuGroup>
);
