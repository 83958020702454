/**
 * @generated SignedSource<<13ada8ab5784924200cecfb33f227c54>>
 * @relayHash b54037546462386bcbf5461949d12fa7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 269fd60bec2d8a735ea27d4119024830098f5c9a3eec94ace82ccb0911de1867

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4PlansListViewQuery$variables = {
  cloudId: string;
};
export type Nav4PlansListViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PlansList">;
};
export type Nav4PlansListViewQuery = {
  response: Nav4PlansListViewQuery$data;
  variables: Nav4PlansListViewQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcmigrateplansnavigationtorelayrelayprovider": isMigratePlanNavRelayEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcmigrateplansnavigationtorelayrelayprovider: {
    readonly get: () => boolean;
  };
});

import isMigratePlanNavRelayEnabled_provider from '@atlassian/jira-relay-provider/src/migrate-plans-navigation-to-relay.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v5 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "name": "planId"
          },
          {
            "kind": "ScalarField",
            "name": "title"
          },
          {
            "kind": "ScalarField",
            "name": "isReadOnly"
          },
          {
            "kind": "ScalarField",
            "name": "planStatus"
          },
          {
            "concreteType": "JiraFavouriteValue",
            "kind": "LinkedField",
            "name": "favouriteValue",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "isFavourite"
              },
              (v3/*: any*/)
            ]
          },
          {
            "concreteType": "JiraScenario",
            "kind": "LinkedField",
            "name": "scenario",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "scenarioId"
              },
              (v3/*: any*/)
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcmigrateplansnavigationtorelayrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "defaultNavigationItem",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "url"
                      }
                    ],
                    "type": "JiraSoftwareBuiltInNavigationItem"
                  },
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ],
        "type": "JiraPlan"
      },
      (v4/*: any*/)
    ]
  }
],
v6 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/)
    ],
    "kind": "Fragment",
    "name": "Nav4PlansListViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "PlansList"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcmigrateplansnavigationtorelayrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "Nav4PlansListViewQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "favouritePlans",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "PLAN"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v5/*: any*/)
              },
              (v6/*: any*/)
            ]
          },
          {
            "alias": "recentPlans",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "types",
                "value": [
                  "PLAN"
                ]
              }
            ],
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v5/*: any*/)
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "269fd60bec2d8a735ea27d4119024830098f5c9a3eec94ace82ccb0911de1867",
    "metadata": {},
    "name": "Nav4PlansListViewQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcmigrateplansnavigationtorelayrelayprovider": isMigratePlanNavRelayEnabled_provider
    }
  }
};
})();

(node as any).hash = "e67bfd2d42ed8352fb6fada08981300c";

export default node;
