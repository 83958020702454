import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useProjectProperties } from '@atlassian/jira-project-properties-services/src/services/index.tsx';
import {
	type ProjectType,
	SERVICE_DESK_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';

//
// Inner implementations,
// Do not export
// ---------------------

type UseIsJCSProjectTypeFallback<T extends boolean> = T extends true
	? boolean | undefined
	: boolean;

const useIsJCSProjectTypeFallback = <T extends boolean = false>(
	projectType: ProjectType | string | null | undefined,
	isAsync?: T,
): UseIsJCSProjectTypeFallback<T> => {
	const isJCS = useAsyncIsJCS();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (isAsync ? isJCS : Boolean(isJCS)) as UseIsJCSProjectTypeFallback<T>;
};

//
// M3 helpers
// ---------------------

export const isJCSProjectType = (projectType: string | null | undefined) =>
	projectType === CUSTOMER_SERVICE_PROJECT && fg('jcs_project_type_m3');

export const isJSMorJCSProjectType = (projectType: string | null | undefined) =>
	projectType === SERVICE_DESK_PROJECT || isJCSProjectType(projectType);

export const useIsJCSProjectType = functionWithCondition(
	() => fg('jcs_project_type_m3'),
	isJCSProjectType,
	useIsJCSProjectTypeFallback,
);

//
//  M2 helpers, deprecated
// ---------------------

const useIsJCSUnderFF = (): boolean => {
	const { 'initial.project.template.name': initialProjectTemplate } = useProjectProperties([
		'initial.project.template.name',
	]);

	return initialProjectTemplate === 'com.atlassian.jcs:customer-service-management';
};

/**
 * @deprecated use useIsJCSProjectType instead
 */
export const useIsJCS = functionWithCondition(
	() => fg('jcs_master_flag'),
	useIsJCSUnderFF,
	() => false,
);

// Undefined can be used to check if project properties has been resolved or not
const useAsyncIsJCSUnderFF = (): boolean | undefined => {
	const { 'initial.project.template.name': initialProjectTemplate } = useProjectProperties([
		'initial.project.template.name',
	]);

	return initialProjectTemplate
		? initialProjectTemplate === 'com.atlassian.jcs:customer-service-management'
		: undefined;
};

/**
 * @deprecated use useIsJCSProjectType isAsync=true instead
 */
export const useAsyncIsJCS = functionWithCondition(
	() => fg('jcs_master_flag'),
	useAsyncIsJCSUnderFF,
	() => false,
);

const isJCSTemplateUnderFF = (template: string | undefined) =>
	template === 'com.atlassian.jcs:customer-service-management';

const isJCSTemplateFallback = (
	template: string | undefined,
): template is 'com.atlassian.jcs:customer-service-management' => false;

/**
 * @deprecated use isJCSProjectType instead
 */
export const isJCSTemplate = functionWithCondition(
	() => fg('jcs_master_flag'),
	isJCSTemplateUnderFF,
	isJCSTemplateFallback,
);
