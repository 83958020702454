/**
 * @generated SignedSource<<579d50b31430815e25d91a9a65380319>>
 * @relayHash 164c48c3824ef2f0755f6d70fb373e5c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5ae63d79ee56070a78fcd0f81b3991516463430dbed3ae6bcd427e7e0f13d71c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { HorizontalNavPlansQuery } from './HorizontalNavPlansQuery.graphql';

const node: PreloadableConcreteRequest<HorizontalNavPlansQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "5ae63d79ee56070a78fcd0f81b3991516463430dbed3ae6bcd427e7e0f13d71c",
    "metadata": {},
    "name": "HorizontalNavPlansQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
