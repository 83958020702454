import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/profileMenuQuery$parameters';

type EntryPointParams = {
	includeExtraMenuItems: boolean;
};

export const Nav4ProfileMenuEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "atlassian-navigation-profile-menu" */ './src/ui/profile-menu'),
	),
	getPreloadProps: ({ includeExtraMenuItems }: EntryPointParams) => ({
		queries: {
			profileMenuQuery: {
				parameters,
				variables: {
					includeExtraMenuItems,
				},
			},
		},
	}),
});
