// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createSubscriber } from '@atlassian/react-sweet-state';
import Store from '../store.tsx';
import type { State } from '../types.tsx';
import type { Actions } from '../actions/page-events/index.tsx';

export const SpaStateSubscriber = createSubscriber<State, Actions>(Store);

export const SpaStateActionsSubscriber = createSubscriber<State, Actions, undefined, undefined>(
	Store,
	{
		selector: null,
	},
);

export const useSpaState = createHook(Store);

export const useSpaStateActions = createHook(Store, {
	selector: null,
});
