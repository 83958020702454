import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { boardsDirectoryResource } from '@atlassian/jira-router-resources-directories-boards/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { boardsDirectoryRedirectRoute } from '@atlassian/jira-router-routes-directories-routes/src/boardsDirectoryRedirectRoute.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { BoardsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import { LazyBoardsDirectory } from './ui/boards/index.tsx';

const BoardsDirectoryRedirect = () => <ScreenRedirect to="/jira/boards" />;

export const boardsDirectoryRedirectRouteEntry = createEntry(boardsDirectoryRedirectRoute, () => ({
	group: 'directories',
	component: BoardsDirectoryRedirect,
	skeleton: BoardsDirectorySkeleton,
	layout: globalLayout,
	resources: [...getNavigationResources(), boardsDirectoryResource],

	navigation: {
		menuId: MENU_ID.PROJECTS,
	},

	forPaint: [LazyAtlassianNavigation, LazyBoardsDirectory],
	isRedirect: true,
}));
