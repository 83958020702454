import { CONNECT_PROJECT_QUERY_PARAMS } from '@atlassian/jira-common-constants/src/connect-project-query-params.tsx';
import type { InvariantJiraRoute } from '@atlassian/jira-route-types/src/InvariantJiraRoute.tsx';

// Don't forget to update CONNECT_ROUTES_SET in @atlassian/jira-common-constants/src/spa-routes
export const servicedeskConnectProjectRoute: InvariantJiraRoute = {
	name: 'servicedesk-connect-project',
	path: '/jira/servicedesk/projects/:projectKey',
	query: CONNECT_PROJECT_QUERY_PARAMS,
	exact: true,
};
