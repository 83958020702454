import { styled as styled2, keyframes as keyframes2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { keyframes } from 'styled-components';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
export const getKeyframes = (offsetX = 0) => {
	const fromPositionX = -Math.abs(offsetX) - 300;
	const toPositionX = fromPositionX + 2000;
	return keyframes({
		'0%': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundPosition: `${fromPositionX}px 0`,
		},
		'100%': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundPosition: `${toPositionX}px 0`,
		},
	});
};

const shimmer = getKeyframes();

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonOld = styled.div<{ height?: string | number; width?: string | number }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		animationDelay: props.theme.animationDelay || '0s',
		animationDuration: '1s',
		animationFillMode: 'forwards',
		animationIterationCount: 'infinite',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		animationName: props.theme.animationName || shimmer,
		animationTimingFunction: 'linear',

		backgroundColor: token('color.skeleton'),

		backgroundImage: `linear-gradient( to right, ${token('color.skeleton')} 10%, ${token('color.skeleton.subtle')} 30%, ${token('color.skeleton')} 50% )`,
		backgroundRepeat: 'no-repeat',
		borderRadius: '2px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: props.height,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: props.width,
	}),
);

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const SpacerOld = styled.div<{ height: string | number }>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: props.height,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonCompiled = styled2.div<{
	height?: string | number;
	width?: string | number;
}>({
	animationDelay: '0s',
	animationDuration: '1s',
	animationFillMode: 'forwards',
	animationIterationCount: 'infinite',
	animationName: keyframes2({
		'0%': {
			backgroundPosition: '-300px 0',
		},

		'100%': {
			backgroundPosition: '1700px 0',
		},
	}),
	animationTimingFunction: 'linear',

	backgroundColor: token('color.skeleton'),
	backgroundImage: `linear-gradient( to right, ${token('color.skeleton')} 10%, ${token('color.skeleton.subtle')} 30%, ${token('color.skeleton')} 50% )`,
	backgroundRepeat: 'no-repeat',
	borderRadius: '2px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => props.height,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => props.width,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpacerCompiled = styled2.div<{ height: string | number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => props.height,
});

export const Skeleton = componentWithCondition(
	() => fg('enghealth-4756-migrate-complex-styled-component'),
	SkeletonCompiled,
	SkeletonOld,
);

export const Spacer = componentWithCondition(
	() => fg('enghealth-4756-migrate-complex-styled-component'),
	SpacerCompiled,
	SpacerOld,
);
