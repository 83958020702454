/**
 * @generated SignedSource<<15c9ae96f4028d81805430160ec46868>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type JiraWorkManagementUserLicenseSeatEdition = "FREE" | "PREMIUM" | "STANDARD" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsContent$data = {
  readonly jira: {
    readonly favouriteProjects?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
          readonly projectKey?: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectMenuItem">;
        };
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
    readonly jwmLicensing: {
      readonly currentUserSeatEdition: JiraWorkManagementUserLicenseSeatEdition | null | undefined;
    } | null | undefined;
    readonly recentProjects?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly projectKey: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectMenuItem">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly jira_projectsSidebarMenu?: {
    readonly current: {
      readonly id: string;
      readonly projectKey: string;
      readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectMenuItem">;
    } | null | undefined;
    readonly favourites: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly projectKey: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectMenuItem">;
        };
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
    readonly recents: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly projectKey: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectMenuItem">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsSectionsWithDisplaySettings" | "Nav4Recommendations">;
  readonly " $fragmentType": "Nav4ProjectsContent";
};
export type Nav4ProjectsContent$key = {
  readonly " $data"?: Nav4ProjectsContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsContent">;
};

import Nav4ProjectsContentQuery_graphql from './Nav4ProjectsContentQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 15
},
v2 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "alias": "projectKey",
  "kind": "ScalarField",
  "name": "key"
},
v5 = {
  "kind": "FragmentSpread",
  "name": "Nav4ProjectMenuItem"
},
v6 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v7 = {
  "kind": "Variable",
  "name": "currentURL",
  "variableName": "currentURL"
},
v8 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6315displaysettingsapart1relayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentURL"
    },
    {
      "kind": "RootArgument",
      "name": "entityAriProjects"
    },
    {
      "kind": "RootArgument",
      "name": "ownerAri"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": Nav4ProjectsContentQuery_graphql
    }
  },
  "name": "Nav4ProjectsContent",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4Recommendations"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6315displaysettingsapart1relayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "entityAri",
                  "variableName": "entityAriProjects"
                },
                {
                  "kind": "Variable",
                  "name": "ownerAri",
                  "variableName": "ownerAri"
                }
              ],
              "kind": "FragmentSpread",
              "name": "Nav4ProjectsSectionsWithDisplaySettings"
            }
          ]
        }
      ]
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "alias": "favouriteProjects",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "filter",
                      "value": {
                        "sort": {
                          "order": "DESC"
                        },
                        "type": "PROJECT"
                      }
                    },
                    (v1/*: any*/)
                  ],
                  "concreteType": "JiraFavouriteConnection",
                  "kind": "LinkedField",
                  "name": "favourites",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "concreteType": "JiraFavouriteEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v3/*: any*/),
                                    "action": "THROW",
                                    "path": "jira.favouriteProjects.edges.node.id"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v4/*: any*/),
                                    "action": "THROW",
                                    "path": "jira.favouriteProjects.edges.node.projectKey"
                                  },
                                  (v5/*: any*/)
                                ],
                                "type": "JiraProject"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.favouriteProjects.edges.node"
                        }
                      ]
                    },
                    (v6/*: any*/)
                  ]
                },
                "action": "THROW",
                "path": "jira.favouriteProjects"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "args": [
                    (v0/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/)
                  ],
                  "concreteType": "JiraProjectConnection",
                  "kind": "LinkedField",
                  "name": "recentProjects",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraProjectEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v3/*: any*/),
                                "action": "THROW",
                                "path": "jira.recentProjects.edges.node.id"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v4/*: any*/),
                                "action": "THROW",
                                "path": "jira.recentProjects.edges.node.projectKey"
                              },
                              (v5/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.recentProjects.edges.node"
                        }
                      ]
                    },
                    (v6/*: any*/)
                  ]
                },
                "action": "THROW",
                "path": "jira.recentProjects"
              }
            ]
          },
          {
            "args": [
              (v0/*: any*/)
            ],
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcblu6987displaysettingspostgaexperimentrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "args": [
              (v0/*: any*/),
              (v7/*: any*/)
            ],
            "concreteType": "JiraProjectsSidebarMenu",
            "kind": "LinkedField",
            "name": "jira_projectsSidebarMenu",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "current",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "THROW",
                    "path": "jira_projectsSidebarMenu.current.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "THROW",
                    "path": "jira_projectsSidebarMenu.current.projectKey"
                  },
                  (v5/*: any*/)
                ]
              },
              {
                "kind": "RequiredField",
                "field": {
                  "args": [
                    (v1/*: any*/)
                  ],
                  "concreteType": "JiraProjectConnection",
                  "kind": "LinkedField",
                  "name": "favourites",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "concreteType": "JiraProjectEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v3/*: any*/),
                                "action": "THROW",
                                "path": "jira_projectsSidebarMenu.favourites.edges.node.id"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v4/*: any*/),
                                "action": "THROW",
                                "path": "jira_projectsSidebarMenu.favourites.edges.node.projectKey"
                              },
                              (v5/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "jira_projectsSidebarMenu.favourites.edges.node"
                        }
                      ]
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": "favourites(first:15)"
                },
                "action": "THROW",
                "path": "jira_projectsSidebarMenu.favourites"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "args": [
                    (v8/*: any*/)
                  ],
                  "concreteType": "JiraProjectConnection",
                  "kind": "LinkedField",
                  "name": "recents",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraProjectEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v3/*: any*/),
                                "action": "THROW",
                                "path": "jira_projectsSidebarMenu.recents.edges.node.id"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v4/*: any*/),
                                "action": "THROW",
                                "path": "jira_projectsSidebarMenu.recents.edges.node.projectKey"
                              },
                              (v5/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "jira_projectsSidebarMenu.recents.edges.node"
                        }
                      ]
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": "recents(first:20)"
                },
                "action": "THROW",
                "path": "jira_projectsSidebarMenu.recents"
              }
            ]
          },
          "action": "THROW",
          "path": "jira_projectsSidebarMenu"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "b0416203ad03d7912efe15fabde20269";

export default node;
