import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const pageSegmentLoad = createJpdExperience(
	new UFOExperience('jpd.idea-view', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

// separate metric for loading fullscreen Idea View on start
const directPageSegmentLoad = createJpdExperience(
	new UFOExperience('jpd.idea-view-direct', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

const summaryUpdate = createJpdExperience(
	new UFOExperience('jpd.idea-view.summary-update', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const descriptionUpdate = createJpdExperience(
	new UFOExperience('jpd.idea-view.description-update', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const deliveryTicketCreate = createJpdExperience(
	new UFOExperience('jpd.idea-view.delivery-ticket-create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const commentCreate = createJpdExperience(
	new UFOExperience('jpd.idea-view.comment-create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const insightCreate = createJpdExperience(
	new UFOExperience('jpd.idea-view.insight-create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const insightUpdate = createJpdExperience(
	new UFOExperience('jpd.idea-view.insight-update', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const insightDelete = createJpdExperience(
	new UFOExperience('jpd.idea-view.insight-delete', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const issueLinkCreate = createJpdExperience(
	new UFOExperience('jpd.idea-view.issue-link-create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const commentsSegmentLoad = createJpdExperience(
	new UFOExperience('jpd.idea-view.comments', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

const insightsSegmentLoad = createJpdExperience(
	new UFOExperience('jpd.idea-view.insights', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

const fileAttach = createJpdExperience(
	new UFOExperience('jpd.idea-view.attach-file', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

/**
 * Since field updates can be frequent, makeFieldUpdate is a factory
 * to avoid conccurency problems as JPDExperience objects are singletons.
 */
const makeFieldUpdate = () =>
	createJpdExperience(
		new UFOExperience('jpd.idea-view.field-update', {
			type: ExperienceTypes.Experience,
			performanceType: ExperiencePerformanceTypes.InlineResult,
		}),
	);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	pageSegmentLoad,
	directPageSegmentLoad,
	summaryUpdate,
	descriptionUpdate,
	deliveryTicketCreate,
	commentCreate,
	insightCreate,
	insightUpdate,
	insightDelete,
	issueLinkCreate,
	commentsSegmentLoad,
	insightsSegmentLoad,
	fileAttach,
	makeFieldUpdate,
};
