import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarAssetsReports from '@atlassian/jira-navigation-apps-sidebar-assets-reports/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskCmdbAssetsReportsTemplateRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbAssetsReportsTemplateRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';

import { AssetsReports, LazyServicedeskCmdbAssetsReports } from './ui/reports/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbAssetsReportsTemplateRouteEntry = createEntry(
	servicedeskCmdbAssetsReportsTemplateRoute,
	() => ({
		group: 'servicedesk',
		layout: globalLayout,
		component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : AssetsReports,
		resources: [
			...getNavigationResources(),
			...getServicedeskPageContextResources(),
			workspaceResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbAssetsReports],
		navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarAssetsReports,
		},
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS),
				teamName: 'ITSM SAGA',
			},
		},
		perfMetricKey: 'service-management.insight-cmdb.load-assets-reports-template',
	}),
);
