/**
 * @generated SignedSource<<805f3d821edff5b2ea87f2af2fd659c8>>
 * @relayHash f04cb6d0378b9b0715942f271eacb8f0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 143b3fe6f1188b3f1e858aa0c1ab0ac681eecff7cea7e1f05e6951e1d37cec33

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiFeaturesPlansSettingsQuery } from './uiFeaturesPlansSettingsQuery.graphql';

const node: PreloadableConcreteRequest<uiFeaturesPlansSettingsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "143b3fe6f1188b3f1e858aa0c1ab0ac681eecff7cea7e1f05e6951e1d37cec33",
    "metadata": {},
    "name": "uiFeaturesPlansSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
