import { di } from 'react-magnetic-di';
import { REQUEST_END, REQUEST_START } from '@atlassian/jira-business-performance/src/constants.tsx';
import { markViewMetric } from '@atlassian/jira-business-performance/src/ui/page-load/utils.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import type { RestResponse, Workflow } from './types.tsx';

export type WorkflowIssuesData = {
	workflows: Workflow[];
	lastChangedTime: string | null;
};

export class Unchanged extends Error {}

type Arguments = {
	projectKey: string;
	itemId?: string;
	ifChangedSince?: string;
};

export const fetchWorkflowIssues = async ({
	projectKey,
	itemId,
	ifChangedSince,
}: Arguments): Promise<WorkflowIssuesData> => {
	di(fetch);

	try {
		markViewMetric('jwm-board', REQUEST_START);

		let url = `/rest/board-api/1/board/${projectKey}/workflows?excludeLabels=true`;
		if (itemId) {
			url += `&viewItemId=${itemId}`;
		}

		const defaultOptions = getDefaultOptions(url);
		const response = await fetch(url, {
			...defaultOptions,
			headers: {
				...defaultOptions.headers,
				...(ifChangedSince ? { 'x-aif-changed-since': ifChangedSince } : undefined),
			},
		});

		if (!response.ok) {
			if (response.status === 304) {
				throw new Unchanged('Workflow issues have not changed since the last request');
			}

			throw new FetchError(response.status);
		}

		const lastChangedTime = response.headers.get('x-alast-changed-time');

		const workflows: RestResponse = await response.json();

		return { workflows: workflows.data, lastChangedTime };
	} finally {
		markViewMetric('jwm-board', REQUEST_END);
	}
};
