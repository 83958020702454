import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async.tsx';
import { planLayout } from '@atlassian/jira-plan-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { advancedRoadmapsSummaryDataResource } from '@atlassian/jira-router-resources-advanced-roadmaps-summary/src/services/summary-data/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwarePlanSummaryRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwarePlanSummaryRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import PlanSummaryPage, { LazyPlanSummary } from './ui/plan-summary/index-old.tsx';
import {
	planSummaryPageEntryPoint,
	planSummaryPageEntryPointWithoutHorizontalNavigation,
} from './ui/plan-summary/index.tsx';

export const softwarePlanSummaryRouteEntry = createEntry(softwarePlanSummaryRoute, () => ({
	group: 'software',

	entryPoint: () =>
		// eslint-disable-next-line no-nested-ternary
		fg('migrate_plans_to_entrypoints')
			? fg('migrate_plans_navigation_to_relay')
				? planSummaryPageEntryPoint
				: planSummaryPageEntryPointWithoutHorizontalNavigation
			: undefined,
	ufoName: 'software-advanced-roadmaps-summary',
	component: PlanSummaryPage,
	layout: composeLayouts(globalLayout, planLayout),
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsSummaryDataResource,
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	],
	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},
	navigation: {
		menuId: MENU_ID.PLANS,
		sidebar: AsyncSidebarPlan,
		horizontal: fg('migrate_plans_navigation_to_relay') ? undefined : AsyncHorizontalNavPlans,
		onlyShowHorizontalOnNav4: true,
	},
	forPaint: [LazyAtlassianNavigation, LazyPlanSummary],
}));
