/**
 * @generated SignedSource<<0a496a9de054c99bd4966c9618d3727f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AlertsMenuItemContentNew$data = {
  readonly opsgenie: {
    readonly savedSearches: {
      readonly createdByMe: ReadonlyArray<{
        readonly alertSearchQuery: string;
        readonly id: string;
        readonly name: string;
      }>;
      readonly sharedWithMe: ReadonlyArray<{
        readonly alertSearchQuery: string;
        readonly id: string;
        readonly name: string;
      }>;
    };
  };
  readonly " $fragmentType": "AlertsMenuItemContentNew";
};
export type AlertsMenuItemContentNew$key = {
  readonly " $data"?: AlertsMenuItemContentNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlertsMenuItemContentNew">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = {
  "kind": "ScalarField",
  "name": "alertSearchQuery"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "AlertsMenuItemContentNew",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "OpsgenieQuery",
        "kind": "LinkedField",
        "name": "opsgenie",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": [
                {
                  "kind": "Variable",
                  "name": "cloudId",
                  "variableName": "cloudId"
                }
              ],
              "concreteType": "OpsgenieSavedSearches",
              "kind": "LinkedField",
              "name": "savedSearches",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "OpsgenieSavedSearch",
                    "kind": "LinkedField",
                    "name": "sharedWithMe",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v0/*: any*/),
                        "action": "THROW",
                        "path": "opsgenie.savedSearches.sharedWithMe.id"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v1/*: any*/),
                        "action": "THROW",
                        "path": "opsgenie.savedSearches.sharedWithMe.name"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v2/*: any*/),
                        "action": "THROW",
                        "path": "opsgenie.savedSearches.sharedWithMe.alertSearchQuery"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "opsgenie.savedSearches.sharedWithMe"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "OpsgenieSavedSearch",
                    "kind": "LinkedField",
                    "name": "createdByMe",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v0/*: any*/),
                        "action": "THROW",
                        "path": "opsgenie.savedSearches.createdByMe.id"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v1/*: any*/),
                        "action": "THROW",
                        "path": "opsgenie.savedSearches.createdByMe.name"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v2/*: any*/),
                        "action": "THROW",
                        "path": "opsgenie.savedSearches.createdByMe.alertSearchQuery"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "opsgenie.savedSearches.createdByMe"
                }
              ]
            },
            "action": "THROW",
            "path": "opsgenie.savedSearches"
          }
        ]
      },
      "action": "THROW",
      "path": "opsgenie"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "62c2524a94628923eca8ac7d6870a893";

export default node;
