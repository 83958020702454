import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type GlobalPermissions from '@atlassian/jira-global-permissions/src/ui/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { globalPermissionsResource } from '@atlassian/jira-router-resources-admin-pages-permissions/src/services/global-permissions/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsGlobalPermissionsRoute } from '@atlassian/jira-router-routes-admin-pages-global-permissions-routes/src/globalSettingsGlobalPermissionsRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { conditionalPostOfficeJiraTopBannerResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';

const LazyGlobalPermissions = lazyForPaint<typeof GlobalPermissions>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-global-permissions", jiraSpaEntry: "async-global-permissions" */ '@atlassian/jira-global-permissions'
		),
	),
);

const LazyGlobalPermissionsPage = () => (
	<LazyPage Page={LazyGlobalPermissions} pageId="jira-global-permissions" shouldShowSpinner />
);

export const globalSettingsGlobalPermissionsRouteEntry = createEntry(
	globalSettingsGlobalPermissionsRoute,
	() => ({
		group: 'global-settings',
		component: componentWithFG(
			'olympus-global-permissions-fe-modernisation',
			LazyGlobalPermissionsPage,
			ErrorPagesNotFound,
		),

		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			globalPermissionsResource,
			conditionalPostOfficeJiraTopBannerResource,
		],

		canTransitionIn: () => fg('olympus-global-permissions-fe-modernisation'),
		forPaint: [LazyAtlassianNavigation, LazyGlobalPermissions],

		meta: {
			reporting: {
				id: 'GlobalPermissions',
				packageName: 'jiraAdminPagesGlobalPermissions',
				teamName: 'gordian',
			},
		},
	}),
);
