import React, { useCallback, useEffect, useState } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import Lozenge from '@atlaskit/lozenge';
import { Flex, xcss } from '@atlaskit/primitives';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { type ShowFlagFn, toFlagId, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	type UIAnalyticsEvent,
	useAnalyticsEvents,
	fireUIAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { UNLICENSED } from '@atlassian/jira-shared-types/src/edition.tsx';
import { SampleProjectAvatar } from '../../common/assets/project-avatar/index.tsx';
import type { OriginSource, recommendationContext } from '../../common/types.tsx';
import { RecommendationContainer } from '../../common/ui/recommendation-section/recommendation-container/index.tsx';
import { RecommendationHeader } from '../../common/ui/recommendation-section/recommendation-header/index.tsx';
import { RecommendationMenuItem } from '../../common/ui/recommendation-section/recommendation-menu-item/index.tsx';
import { RecommendationModal } from '../../common/ui/recommendation-section/recommendation-modal/index.tsx';
import { useProjectMenuRecommendations } from '../../controllers/project-menu-recommendations/index.tsx';
import { projectsMenuDropdownLoadJwmSampleProjectRecommendation } from '../../experiences.tsx';
import { fetchSiteTraits, fetchUserTraits } from '../../services/fetch-traits/index.tsx';
import {
	BRAIN_JSW_TO_JWM_IMPLICIT_SITE_BOOLEAN,
	CREATE_JWM_SAMPLE_PROJECT_ENDPOINT,
	CREATE_JWM_SAMPLE_PROJECT_ERROR_FLAG_ID,
	CREATE_JWM_SAMPLE_PROJECT_KEY,
	PACKAGE_NAME,
	PROJECTS_MENU_EXISTING_USERS,
	SITE_USER_TRAIT_HAS_CREATED_JWM_PROJECT,
	SITE_USER_TRAIT_HAS_CREATED_SAMPLE_PROJECT,
	SITE_USER_TRAIT_HAS_DISMISSED_EXPERIMENT,
	SITE_USER_TRAIT_VIEWED_EXPERIMENT_DROPDOWN_DATE,
} from './constants.tsx';
import messages from './messages.tsx';

export const CreateJwmSampleProjectRecommendation = () => {
	const [moreInformationModal, setMoreInformationModal] = useState(false);
	const [, { setRecommendationDismissal }] = useProjectMenuRecommendations();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();

	useEffect(() => {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'dropdown viewed',
			'projectsMenuRecommendationDropdown',
		);
	}, [createAnalyticsEvent]);

	const createSampleProjectClicked = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
			}),
			'jwmSampleProjectExperimentCreateProjectButton',
		);
		createJwmSampleProject(showFlag);
	}, [createAnalyticsEvent, showFlag]);

	const onLearnMoreClicked = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
			}),
			'explainButton',
		);
		setMoreInformationModal(true);
	}, [createAnalyticsEvent]);

	const onLearnMoreLinkClicked = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'link',
				action: 'clicked',
			}),
			'jwmSampleProjectLink',
		);
	}, [createAnalyticsEvent]);

	const onLearnMoreClosed = useCallback(() => {
		setMoreInformationModal(false);
	}, []);

	const onDismissClicked = useCallback(() => {
		setRecommendationDismissal();
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
			}),
			'jwmSampleProjectExperimentDismissButton',
		);
		showFlag({
			type: 'success',
			isAutoDismiss: true,
			title: formatMessage(messages.dismissConfirmationTitle),
			description: formatMessage(messages.dismissConfirmationText),
			messageId:
				'atlassian-navigation-recommendations.ui.jwm-sample-project-recommendation.show-flag.success',
			messageType: 'transactional',
		});
	}, [createAnalyticsEvent, setRecommendationDismissal, showFlag, formatMessage]);

	return (
		<RecommendationContainer>
			<RecommendationHeader
				title={formatMessage(messages.trySampleSectionHeader)}
				onDismissClicked={onDismissClicked}
				onLearnMoreClicked={onLearnMoreClicked}
			/>
			<RecommendationMenuItem
				title={formatMessage(messages.ctaHeadingText)}
				description={formatMessage(messages.ctaDescriptionText)}
				onClick={createSampleProjectClicked}
				lozenge={
					<Lozenge appearance="new" isBold={false}>
						{formatMessage(messages.lozengeText)}
					</Lozenge>
				}
				iconBefore={
					<Flex xcss={logoStyles} justifyContent="center" alignItems="center">
						<SampleProjectAvatar />
					</Flex>
				}
			/>
			<RecommendationModal
				isOpen={moreInformationModal}
				onClose={onLearnMoreClosed}
				title={formatMessage(messages.modalTitle)}
				body={
					fg('remove-learn-more-link-from-jwm-sample-project')
						? formatMessage(messages.modalBody, {
								br: <br />,
							})
						: formatMessage(messages.modalBodySpork, {
								br: <br />,
								link: (
									<a
										href="https://www.atlassian.com/software/jira/work-management"
										target="_blank"
										onClick={onLearnMoreLinkClicked}
									>
										{formatMessage(messages.learnMoreLinkSpork)}
									</a>
								),
							})
				}
				primaryCta={formatMessage(messages.modalCta)}
			/>
		</RecommendationContainer>
	);
};

const logoStyles = xcss({
	width: '28px',
	height: '28px',
});

const checkEligibility = async (recommendationContext: recommendationContext) => {
	const { licensedProducts, firstActivationDateMs, edition, canCreateProject, accountId, cloudId } =
		recommendationContext;

	if (!licensedProducts?.[LICENSED_PRODUCTS.JIRA_SOFTWARE])
		return { isEligible: false, reason: 'User does not have jira software' };

	if (edition === UNLICENSED) return { isEligible: false, reason: 'User is unlicensed' };

	if (!canCreateProject) return { isEligible: false, reason: 'User cannot create projects' };

	const [userTraits, siteTraits] = await Promise.all([
		fetchUserTraits(cloudId, accountId),
		fetchSiteTraits(cloudId),
	]);

	if (
		userTraits.some(
			(trait) => trait.name === SITE_USER_TRAIT_HAS_CREATED_JWM_PROJECT && trait.value,
		)
	)
		return {
			isEligible: false,
			reason: `User has trait ${SITE_USER_TRAIT_HAS_CREATED_JWM_PROJECT}`,
		};

	if (
		userTraits.some(
			(trait) => trait.name === SITE_USER_TRAIT_HAS_CREATED_SAMPLE_PROJECT && trait.value,
		)
	)
		return {
			isEligible: false,
			reason: `User has trait ${SITE_USER_TRAIT_HAS_CREATED_SAMPLE_PROJECT}`,
		};

	if (
		userTraits.some(
			(trait) => trait.name === SITE_USER_TRAIT_HAS_DISMISSED_EXPERIMENT && trait.value,
		)
	)
		return {
			isEligible: false,
			reason: `User has trait ${SITE_USER_TRAIT_HAS_DISMISSED_EXPERIMENT}`,
		};

	if (!firstActivationDateMs)
		return {
			isEligible: false,
			reason: 'firstActivationDateMs is undefined',
		};

	if (
		differenceInDays(new Date(), firstActivationDateMs) > 28 &&
		userTraits.some(
			(trait) =>
				trait.name === SITE_USER_TRAIT_VIEWED_EXPERIMENT_DROPDOWN_DATE &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				differenceInDays(new Date(), new Date(trait.value as string)) > 28,
		)
	) {
		return {
			isEligible: false,
			reason: 'Experiment dropdown date trait is older than 28 days',
		};
	}

	if (
		differenceInDays(new Date(), firstActivationDateMs) > 28 &&
		!siteTraits.some(({ name, value }) => name === BRAIN_JSW_TO_JWM_IMPLICIT_SITE_BOOLEAN && value)
	)
		return {
			isEligible: false,
			reason: 'User activation date is greater than 28 days and JSW to JWM ML trait is not set',
		};

	return { isEligible: true, reason: 'Pass all checks' };
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	key: CREATE_JWM_SAMPLE_PROJECT_KEY,
	shouldRender: async (
		recommendationContext: recommendationContext,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		projectsMenuDropdownLoadJwmSampleProjectRecommendation.start();
		const { isEligible, reason } = await checkEligibility(recommendationContext);

		fireOperationalAnalytics(
			analyticsEvent,
			'jiraAtlassianNavigation.loadJwmSampleProjectRecommendation succeeded',
			{ isEligible, reason },
		);

		projectsMenuDropdownLoadJwmSampleProjectRecommendation.success();
		return isEligible;
	},
	onLoadError: (error: Error) => {
		projectsMenuDropdownLoadJwmSampleProjectRecommendation.failure();
		fireErrorAnalytics({
			error,
			meta: {
				id: 'loadJwmSampleProjectRecommendation',
				packageName: 'jiraAtlassianNavigationRecommendations',
				teamName: 'navigation',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	},
	Component: CreateJwmSampleProjectRecommendation,
};

const jwmSampleProjectUrlWithOriginSource = (taskId: string, originSource: OriginSource) =>
	`/jira/core/sample/onboarding/${taskId}?originSource=moon-${originSource}`;

const createJwmSampleProject = async (showFlag: ShowFlagFn) => {
	try {
		const response = await performPostRequest(CREATE_JWM_SAMPLE_PROJECT_ENDPOINT);
		if (response?.taskId) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(
				jwmSampleProjectUrlWithOriginSource(response.taskId, PROJECTS_MENU_EXISTING_USERS),
			);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'createJwmSampleProjectService',
				packageName: PACKAGE_NAME,
				teamName: 'navigation',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		showFlag({
			key: toFlagId(CREATE_JWM_SAMPLE_PROJECT_ERROR_FLAG_ID),
			type: 'error',
			title: messages.errorFlagTitle,
			description: messages.errorFlagText,
			messageId:
				'atlassian-navigation-recommendations.ui.jwm-sample-project-recommendation.show-flag.error',
			messageType: 'transactional',
		});

		return error;
	}
};
