// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State, ProjectState } from '../../model/types.tsx';

export const setProjectState =
	(projectState: ProjectState): Action<State> =>
	({ setState }) => {
		setState({ projectState });
	};

export default setProjectState;
