import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskAccessRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskAccessRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { ProjectSettingsAccessSkeleton } from '@atlassian/jira-skeletons/src/ui/project-settings/ProjectSettingsFeaturesSkeleton.tsx';
import Access, { LazyPeopleSettings } from './ui/people/index.tsx';

export const projectSettingsServicedeskAccessRouteEntry = createEntry(
	projectSettingsServicedeskAccessRoute,
	() => ({
		group: 'project-settings-servicedesk',
		skeleton: ProjectSettingsAccessSkeleton,
		component: Access,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyPeopleSettings],
		ufoName: 'project-settings.access',
	}),
);
