import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	GlobalAppShortcuts as GlobalAppShortcutsWithoutErrorBoundary,
	type GlobalAppShortcutsProps,
} from './ui/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

export const GlobalAppShortcuts = ({ menuId }: GlobalAppShortcutsProps) => (
	<JSErrorBoundary
		fallback="flag"
		id="global-app-shortcuts-section"
		packageName={PACKAGE_NAME}
		teamName={TEAM_NAME}
	>
		<GlobalAppShortcutsWithoutErrorBoundary menuId={menuId} />
	</JSErrorBoundary>
);
