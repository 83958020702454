import parameters from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContentViewQuery.graphql';
import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JiraNavigationSettingsAri } from '@atlassian/ari/jira/navigation-settings';
import { IdentityUserAri } from '@atlassian/ari/identity/user';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';

export const nav4ProjectsContentEntryPoint = createEntryPoint({
	root: JSResourceForUserVisible(() =>
		import(
			/* webpackChunkName: "async-nav4-projects-content-view-query" */ './Nav4ProjectsContentViewQuery'
		).then(({ Nav4ProjectsContentViewQuery }) => Nav4ProjectsContentViewQuery),
	),
	getPreloadProps: (params: EntryPointRouteParams) => {
		const cloudId = params.tenantContext?.cloudId || '';
		const activationId = params.tenantContext.activationId;
		const atlassianAccountId = params.tenantContext.atlassianAccountId;
		return {
			queries: {
				projects: {
					parameters,
					variables: {
						cloudId,
						entityAriProjects: JiraNavigationSettingsAri.create({
							siteId: cloudId,
							activationId,
							navigationSettingScope: 'projects',
						}).toString(),
						ownerAri:
							atlassianAccountId &&
							IdentityUserAri.create({ userId: atlassianAccountId }).toString(),
					},
				},
			},
		};
	},
});
