import React, { type ComponentType, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { CreateIssueUFOExperience } from '@atlassian/jira-issue-create-analytics/src/services/experiences/index.tsx';
import { GLOBAL_EVENT_NAMESPACE } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import {
	fireTrackAnalyticsDeferred,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { gicOptInStatusResources } from '@atlassian/jira-router-resources-global-issue-create-switch/src/index.tsx';
import type { PersistentIssueCreateModalView } from '@atlassian/jira-router-resources-persistent-issue-create-modal-view/src/controllers/types.tsx';
import { useResource } from '@atlassian/react-resource-router';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createSubscriber,
	type Action,
	createStateHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { TRIGGER_POINT_KEY_NOT_DEFINED } from './constants.tsx';
import { useSitePickerContext } from './controllers/use-site-picker-context/index.tsx';
import type { TriggerPointKeyType, MinimizableModalView, State, GICPayload } from './types.tsx';

import {
	discoverabilityPushModalViewOverride,
	getNextModalView,
	isPartOfDiscoverabilityTriggers,
	startGlobalIssueCreateMetrics,
} from './utils.tsx';

export const initialState: State = {
	inProgressIssueID: undefined,
	isModalOpen: false,
	isSubsequentLoad: false,
	// isSubTaskCreationOpen is used for creating subtasks with new GIC
	isSubTaskCreationOpen: false,
	// isCreateLinkedIssue is used for creating linked issues with new GIC
	isCreateLinkedIssue: false,
	wasStateRestored: false,
	// payload can be used to control the initial values displayed in the GIC Modal
	payload: null,
	// id for the GIC Modal component
	id: 'create-issue-dialog',
	// title for the GIC modal
	title: null,
	// sectionMessage is used to show an info banner at the top of the GIC modal
	sectionMessage: null,
	// submitErrorMessage is used to show an error banner at the top of the GIC modal
	submitErrorMessage: undefined,
	// globalEventNamespaces is the list of event namespaces to which data of created issues will be published
	globalEventNamespaces: [GLOBAL_EVENT_NAMESPACE],
	// fieldsToAddInPublishedData: list of additioanl fields that should be appended in the data published for
	// created issues. By default we publish summary, status, assignee, duedate, project, issuetype & priority
	fieldsToAddInPublishedData: [],
	// Used on Create Incident from Alerts functionality. Only relevant for Opsgenie alert pages
	alertsToBeLinked: undefined,
	// if onIssueCreate is passed for every issue created in the session onIssueCreate is called with
	// the data of each issue created
	onIssueCreate: null,
	onClose: null,
	// onSiteSelection is called when a site is selected in the site picker
	onSiteSelection: null,
	// onProjectSelection is called when a project is selected in the project picker
	onProjectSelection: null,
	// only show required fields
	displayOnlyRequiredFields: false,
	// disables the success flag
	disableCreationSuccessFlag: false,
	// toggle the 'create another issue' checkbox
	displayCreateAnotherIssueToggle: true,
	// toggle display of site picker field.
	displaySitePicker: false,
	// do not allow user to change issue project
	disableProjectDropdown: false,
	// do not allow user to change issue type
	disableIssueTypeDropdown: false,
	// triggerPoinKey can be used to identify specific trigger point of new GIC
	triggerPointKey: TRIGGER_POINT_KEY_NOT_DEFINED,
	// context can be used to pass data like rank/board data which will be used as payload for issue creation
	context: undefined,
	// the view state for the minimizable modal
	minimizableModalView: 'modal',
	// the modal view maybe persistent depending on conditions, if this value is present, it may override the `minimizableModalView` during `openIssueCreateModal`.
	persistentModalView: undefined,
	// whether we should display mini modal by default for the first time. If this is true, it will override the view state to `mini` during `openIssueCreateModal`.
	shouldPushMiniModalDiscoverability: undefined,
	// whether we should display mini modal spotlight for the first time.
	shouldShowMiniModalSpotlight: undefined,
	// modal was attempted to be opened when already opened
	wasModalRetriggered: false,
	// if there is an existing gic open - we only apply the next state if they discard their current issue
	openIssueCreateModalNextState: {},
	// when an issue is discarded we will reinitialise the gic modal
	shouldInitFetchConfig: false,
	interactedWithModalChangerButtons: false,
	// open modal for the jql board trigger point
	isModalLoadInContext: false,
	// opt-out of the minimizable GIC modal
	disableMinimizableModal: false,
	// switch to control the hide and display of minimizable buttons
	displayMinimizableButtons: true,
	// switch to control the hide and display of header action container
	displayHeaderActionContainer: true,
	// do not override "use request type fields" toggle state
	overrideUseRequestTypeFieldsToggleState: undefined,
	/**
	 * this is for applying the container styles on the GIC containers except modal view
	 */
	containerStyle: {},
	// this flag is for enable the minidesign for a trigger point without being in mini view
	isMiniDesignEnabled: false,
	// this flag is for the GIC multiple layout experiment by Makkuro
	isEnrolledAndTargetedForGicMultipleLayouts: false,
	// optional sessionId used for the current issue creation session
	sessionId: undefined,
	// optional component - when supplied, this wraps every field as children within the GIC modal
	FieldWrapperComponent: undefined,
	// optional component - Renders external component above form fields
	ExternalControls: undefined,
	// Analytics property for board with custom filter and icc enabled
	isCustomBoardWithIcc: false,
	// optional ID of the jql swimlane we are creating issues in
	jqlSwimlaneId: undefined,
	// Analytics property for board with swimlane enabled
	swimlaneMode: undefined,
	// prefilled custom Fields if any
	prefilledCustomFields: {},
	// callback for ecosystem API to execute
	ecosystemCallback: undefined,
	// product where issue create anywhere modal is used
	product: undefined,
	// what's the consumer of the issue create anywhere modal in a product
	actionSource: undefined,
	// Field IDs that will remain visible even when the smart fields feature recommends them to be hidden.
	optOutSmartFieldIds: undefined,
	// refrence of the button from where GIC modal is triggered
	triggerButtonRef: null,
};

export const actions = {
	restoreState:
		(stateToRestore: Partial<State>): Action<State> =>
		({ setState, getState }) => {
			const { isSubsequentLoad, isModalOpen } = getState();

			if (!isModalOpen && stateToRestore.isModalOpen) {
				startGlobalIssueCreateMetrics(isSubsequentLoad);
			}

			setState(stateToRestore);
		},
	openIssueCreateModal:
		(nextState?: GICPayload): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();

			if (currentState.isModalOpen) {
				/* If there is an existing gic open then we will defer the state change to prevent
                       clashing with the issue in creation. */
				setState({
					wasModalRetriggered: true,
					openIssueCreateModalNextState: nextState,
				});
			} else {
				startGlobalIssueCreateMetrics(currentState.isSubsequentLoad);
				const isDiscoverabilityPushEnabled =
					!currentState.isEnrolledAndTargetedForGicMultipleLayouts;
				const isDiscoPushTriggerPoint =
					isDiscoverabilityPushEnabled &&
					isPartOfDiscoverabilityTriggers(nextState?.triggerPointKey);
				const nextModalView = isDiscoverabilityPushEnabled
					? getNextModalView(
							isDiscoPushTriggerPoint,
							currentState.persistentModalView,
							initialState.minimizableModalView,
						)
					: 'modal';

				if (fg('fix-gryf-a11y-issues')) {
					/*
					 * This is done to always focus the Issue Create Modal trigger button whenever it's clicked
					 * so that we can store the reference of this
					 * and use that to re-focus the button whenever the modal is closed
					 */

					let triggerButton: HTMLButtonElement | null;

					const handleButtonClick = (event: Event) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const triggerButtonTarget = event.target as HTMLElement;

						triggerButton = triggerButtonTarget?.closest('button');
						triggerButton?.focus();
					};

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					document.addEventListener('click', handleButtonClick);

					setTimeout(() => {
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						document.removeEventListener('click', handleButtonClick);
					}, 0);

					setTimeout(() => {
						setState({
							...initialState,
							...(isDiscoverabilityPushEnabled && {
								minimizableModalView: nextModalView,
								persistentModalView: currentState.persistentModalView,
								...discoverabilityPushModalViewOverride(
									currentState.shouldPushMiniModalDiscoverability,
									isDiscoPushTriggerPoint,
									nextModalView,
								),
							}),
							inProgressIssueID: uuid(),
							isSubsequentLoad: currentState.isSubsequentLoad,
							isModalOpen: true,
							wasModalRetriggered: currentState.isModalOpen,
							...(expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)
								? { triggeredViaHotKey: !!nextState?.triggeredViaHotKey }
								: {}),
							...nextState,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
							triggerButtonRef: triggerButton || (document.activeElement as HTMLButtonElement),
						});
					}, 0);
				} else {
					setState({
						...initialState,
						...(isDiscoverabilityPushEnabled && {
							minimizableModalView: nextModalView,
							persistentModalView: currentState.persistentModalView,
							...discoverabilityPushModalViewOverride(
								currentState.shouldPushMiniModalDiscoverability,
								isDiscoPushTriggerPoint,
								nextModalView,
							),
						}),
						inProgressIssueID: uuid(),
						isSubsequentLoad: currentState.isSubsequentLoad,
						isModalOpen: true,
						wasModalRetriggered: currentState.isModalOpen,
						...(expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)
							? { triggeredViaHotKey: !!nextState?.triggeredViaHotKey }
							: {}),
						...nextState,
					});
				}
			}
		},
	closeIssueCreateModal:
		(hasIssueCreated?: boolean): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();

			const isDiscoverabilityPushEnabled = !currentState.isEnrolledAndTargetedForGicMultipleLayouts;

			const isValidDiscoPushTriggerPoint =
				isDiscoverabilityPushEnabled &&
				isPartOfDiscoverabilityTriggers(currentState.triggerPointKey);

			const shouldUpdatePersistentModalView =
				isDiscoverabilityPushEnabled && hasIssueCreated && isValidDiscoPushTriggerPoint;

			const shouldPersistDiscoverabilityState =
				isDiscoverabilityPushEnabled &&
				currentState.shouldPushMiniModalDiscoverability &&
				!isValidDiscoPushTriggerPoint;

			setState({
				...initialState,
				...{ callbackPayload: undefined },
				...(shouldPersistDiscoverabilityState && {
					shouldPushMiniModalDiscoverability: true,
				}),
				...(isDiscoverabilityPushEnabled && {
					persistentModalView: currentState.persistentModalView,
				}),
				...(shouldUpdatePersistentModalView && {
					persistentModalView: currentState.minimizableModalView === 'mini' ? 'mini' : 'modal',
				}),
				isModalOpen: false,
				isSubsequentLoad: true,
			});

			CreateIssueUFOExperience.abort({
				metadata: { wasStateRestored: getState().wasStateRestored },
			});
		},
	clearSectionMessage:
		(): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();
			setState({
				...currentState,
				sectionMessage: null,
			});
		},
	setDisplayOnlyRequiredFields:
		(nextState: boolean): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();
			setState({ ...currentState, displayOnlyRequiredFields: nextState });
		},
	setMinimizableModalView:
		(minimizableModalView: MinimizableModalView): Action<State> =>
		({ setState }) => {
			setState({
				minimizableModalView,
				interactedWithModalChangerButtons: true,
			});
		},
	resetWasModalRetriggered:
		(applyNextState = false): Action<State> =>
		({ setState, getState }) => {
			if (applyNextState) {
				const currentState = getState();
				startGlobalIssueCreateMetrics(currentState.isSubsequentLoad);
				setState({
					...initialState,
					...(!currentState.isEnrolledAndTargetedForGicMultipleLayouts && {
						persistentModalView: currentState.persistentModalView,
					}),
					inProgressIssueID: currentState.inProgressIssueID,
					isSubsequentLoad: currentState.isSubsequentLoad,
					isModalOpen: true,
					wasModalRetriggered: false,
					minimizableModalView: 'modal',
					openIssueCreateModalNextState: {},
					shouldInitFetchConfig: true,
					...currentState.openIssueCreateModalNextState,
				});
			} else {
				setState({
					minimizableModalView: 'modal',
					wasModalRetriggered: false,
					openIssueCreateModalNextState: {},
				});
			}
		},
	resetShouldInitFetchConfig:
		(): Action<State> =>
		({ setState }) => {
			setState({
				shouldInitFetchConfig: false,
			});
		},
	setCallbackPayload:
		(callbackPayload: Required<GICPayload>['callbackPayload']['data']): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();
			setState({
				callbackPayload: {
					id: currentState.callbackPayload?.id ?? '',
					data: callbackPayload,
				},
			});
		},
	setShouldPushMiniModalDiscoverability:
		(shouldPushMiniModalDiscoverability?: boolean): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();
			if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) {
				return;
			}
			setState({
				shouldPushMiniModalDiscoverability,
			});
		},
	dismissModalSpotlight:
		(): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();
			if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) {
				return;
			}
			setState({
				shouldShowMiniModalSpotlight: false,
			});
		},
	setPersistentModalView:
		(persistentModalView: PersistentIssueCreateModalView): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();
			if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) {
				return;
			}
			setState({
				persistentModalView,
			});
		},
	removeAlertFromAlertsToBeLinked:
		(alertId: string): Action<State> =>
		({ setState, getState }) => {
			const currentState = getState();
			const alertsToBeLinked = currentState.alertsToBeLinked?.filter((entry) => entry !== alertId);
			setState({
				alertsToBeLinked,
			});
		},
	setSessionId:
		(sessionId: string): Action<State> =>
		({ setState }) => {
			setState({ sessionId });
		},
};

export type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'issue-create.issue-create-form.use-trigger-issue-create-modal',
	initialState,
	actions,
});

const useTriggerIssueCreateModalState = createStateHook(store);
const useTriggerIssueCreateModalActions = createActionsHook(store);

type Attributes = {
	gicViewState?: MinimizableModalView;
	isSubtask?: boolean;
	triggerPointKey?: TriggerPointKeyType;
	product?: string;
	actionSource?: string;
};

export const useTriggerIssueCreateModal = (): [
	ReturnType<typeof useTriggerIssueCreateModalState>,
	ReturnType<typeof useTriggerIssueCreateModalActions>,
] => {
	const createState = useTriggerIssueCreateModalState();
	const createActions = useTriggerIssueCreateModalActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { openIssueCreateModal, ...otherActions } = createActions;

	const openIssueCreateModalWithAnalytics = useCallback(
		(nextState?: GICPayload) => {
			const analyticsEvent = createAnalyticsEvent({});

			const attributes: Attributes = {
				gicViewState: nextState?.minimizableModalView,
				isSubtask: nextState?.isSubTaskCreationOpen,
				triggerPointKey: nextState?.triggerPointKey,
				...(nextState?.product ? { product: nextState.product } : {}),
				...(nextState?.actionSource ? { actionSource: nextState.actionSource } : {}),
			};

			fireTrackAnalyticsDeferred(analyticsEvent, 'issueCreateModal triggered', attributes);

			openIssueCreateModal(nextState);
		},
		[createAnalyticsEvent, openIssueCreateModal],
	);

	return [
		createState,
		{
			...otherActions,
			openIssueCreateModal: openIssueCreateModalWithAnalytics,
		},
	];
};

export const useOpenIssueCreateModal = () => {
	const { openIssueCreateModal } = useTriggerIssueCreateModalActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { setSitePickerState }] = useSitePickerContext();

	return useCallback(
		(nextState?: GICPayload) => {
			const analyticsEvent = createAnalyticsEvent({});

			const attributes: Attributes = {
				gicViewState: nextState?.minimizableModalView,
				isSubtask: nextState?.isSubTaskCreationOpen,
				triggerPointKey: nextState?.triggerPointKey,
				...(nextState?.product ? { product: nextState.product } : {}),
				...(nextState?.actionSource ? { actionSource: nextState.actionSource } : {}),
			};

			fireTrackAnalyticsDeferred(analyticsEvent, 'issueCreateModal triggered', attributes);

			// overrideCloudId only used for embedded GIC
			if (nextState?.payload?.overrideCloudId) {
				// set cloudId inside the state if there's an override
				setSitePickerState({
					selectedCloudId: nextState?.payload?.overrideCloudId,
				});
			}
			openIssueCreateModal(nextState);
		},
		[createAnalyticsEvent, openIssueCreateModal, setSitePickerState],
	);
};

export const TriggerIssueCreateModalSubscriber = createSubscriber<State, Actions>(store);

export function withNewGICProvider<Props>(WrappedComponent: ComponentType<Props>) {
	return (props: Props) => {
		const [, { openIssueCreateModal, closeIssueCreateModal }] = useTriggerIssueCreateModal();
		const { data } = useResource(gicOptInStatusResources);
		const currentIssueCreateVisibility = data && data?.gicOptInStatus;

		return (
			<WrappedComponent
				openIssueCreateModal={openIssueCreateModal}
				closeIssueCreateModal={closeIssueCreateModal}
				currentIssueCreateVisibility={currentIssueCreateVisibility?.isEnabled || false}
				{...props}
			/>
		);
	};
}
