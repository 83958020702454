import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { issueLinkInformationResource } from '@atlassian/jira-router-resources-advanced-roadmaps-settings/src/services/issue-link/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { arjSettingsDependenciesRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-routes/src/arjSettingsDependenciesRoute.tsx';
import { LazyArjSettingsDependencies, ArjSettingsDependencies } from './ui/index.tsx';

export const arjSettingsDependenciesRouteEntry = createEntry(arjSettingsDependenciesRoute, () => ({
	group: 'software',
	component: ArjSettingsDependencies,
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		issueLinkInformationResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyArjSettingsDependencies],
}));
