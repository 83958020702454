import CloseIcon from '@atlaskit/icon/core/close';
import { Anchor, Box, Inline, Pressable, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useMessagePlacementHooks } from '@atlassian/post-office-context';
// @ts-expect-error: 'React' is declared but its value is never read
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

const pressableStyles = xcss({
	color: 'color.text.inverse',
	cursor: 'pointer',
	textDecoration: 'underline',

	':hover': {
		color: 'color.text.inverse',
		textDecoration: 'underline',
	},
	':active': {
		textDecoration: 'none',
	},
});

const ComingSoonMessage = () => (
	<FormattedMessage
		id="post-office.placement.jira-top-banner.primary-message"
		description="Coming soon message for the Jira top banner"
		defaultMessage="As we roll out 'work' as the new term for items tracked in Jira, you may still see 'issue' in some areas."
	/>
);

const ReadMoreLink = () => (
	<Anchor
		href="https://community.atlassian.com/t5/Jira-articles/It-s-here-Work-is-the-new-collective-term-for-all-items-you/ba-p/2954892"
		rel="noopener noreferrer"
		target="_blank"
		xcss={pressableStyles}
	>
		<FormattedMessage
			id="st-office.placement.jira-top-banner.primary-cta-text"
			description="Read more link for the Jira top banner"
			defaultMessage="Read about this terminology change"
		/>
	</Anchor>
);

type TopBannerMessageProps = {
	messageInstanceId: string;
	readMoreLink?: string;
};

export const TopBannerMessage = ({ messageInstanceId }: TopBannerMessageProps) => {
	const { dismissMessageFromPlacement } = useMessagePlacementHooks();

	return (
		<Box
			backgroundColor="color.background.neutral.bold"
			padding="space.200"
			testId="jira-top-banner"
		>
			<Inline spread="space-between">
				<Text color="color.text.inverse">
					<ComingSoonMessage />
					&nbsp;
					<ReadMoreLink />
				</Text>
				<Pressable
					backgroundColor="color.background.neutral.bold"
					onClick={() => dismissMessageFromPlacement({ messageInstanceId })}
				>
					<CloseIcon color={token('color.text.inverse')} label="Dismiss" />
				</Pressable>
			</Inline>
		</Box>
	);
};
