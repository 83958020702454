import { fg } from '@atlassian/jira-feature-gating';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { knowledgeSourcesResource } from '@atlassian/csm-knowledge-config';
import { helpCenterSettingsAgentStudioKnowledgeRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsAgentStudioKnowledgeRoute.tsx';
import { agentStudioNavigation } from './common/constants.tsx';
import { agentStudioKnowledgePageEntryPoint } from './ui/agent-studio/index.tsx';

export const helpCenterSettingsAgentStudioKnowledgeRouteEntry = createEntry(
	helpCenterSettingsAgentStudioKnowledgeRoute,
	() => ({
		group: 'helpcenter-settings',
		perfMetricKey: 'help-center-settings.agent-studio-knowledge',
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('enable_support_center_agent_studio_page')
				? agentStudioKnowledgePageEntryPoint
				: undefined;
		},
		layout: serviceProjectLayout,
		navigation: agentStudioNavigation,
		resources: [...getServiceDeskSettingsResources(), knowledgeSourcesResource],
		earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
	}),
);
