import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { servicedeskProformaIssueFormsDirectRoute } from '@atlassian/jira-router-routes-proforma-routes/src/servicedeskProformaIssueFormsDirectRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import ProformaIssueFormsDirect, {
	LazyProformaIssueFormsDirect,
} from './ui/issue-forms-direct/index.tsx';

export const servicedeskProformaIssueFormsDirectRouteEntry = createEntry(
	servicedeskProformaIssueFormsDirectRoute,
	() => ({
		group: 'servicedesk',
		layout: serviceProjectLayout,
		resources: [projectContextResource],
		component: ProformaIssueFormsDirect,
		navigation: {
			menuId: MENU_ID.PROJECTS,
		},
		forPaint: [LazyProformaIssueFormsDirect],
		perfMetricKey: 'issue-forms-direct',
	}),
);
