import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { Edition, Product } from '@atlassian/edition-awareness';
import {
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { SupportedApplicationKey } from '../common/types.tsx';

export const getEaEdition = (e: ApplicationEdition): Edition | undefined => {
	if (e === 'FREE_EDITION') return 'free';
	if (e === 'STANDARD_EDITION') return 'standard';
	if (e === 'PREMIUM_EDITION') return 'premium';
};

export const getEaProduct = (p: ProjectType): Product | undefined => {
	if (p === SOFTWARE_PROJECT) return 'jira';
	if (p === PRODUCT_DISCOVERY_PROJECT) return 'jira-product-discovery';
	if (p === SERVICE_DESK_PROJECT) return 'jira-service-management';
	if (p === CORE_PROJECT) return 'jira';
};

export const getApplicationKey = (product?: string): SupportedApplicationKey | undefined => {
	if (product === 'jira') return 'jira-software';
	if (product === 'jira-product-discovery') return 'jira-product-discovery';
	if (product === 'jira-service-management') return 'jira-servicedesk';
};
