import React, { memo } from 'react';
import { EntryPointContainer } from 'react-relay';
import { EntryPointReferencePlaceholder } from '@atlassian/jira-entry-point-placeholder/src/index.tsx';
import {
	JiraEntryPointContainer,
	type Props,
} from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { AnyEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

type EntryPointAdapterProps<TEntryPoint extends AnyEntryPoint> = {
	epContainerProps: Omit<Props<TEntryPoint>, 'entryPointReferenceSubject'>;
	jiraEPRef: EntryPointReferenceSubject<TEntryPoint>;
	nestedEPRef?: never;
	placeholderEntryPoint: TEntryPoint;
	shouldRenderNestedEntryPoint?: boolean;
};

/**
 * This component is a Route-EntryPoint adapter. It is designed to support the situation
 * where a route is using entryPoints, which means that the route *might* be referencing
 * a left-sidebar entryPoint, which may subsequently be pre-loading a menu-component entryPoint.
 */
export const RouteEntryPointContainer = memo(
	<TEntryPoint extends AnyEntryPoint>({
		epContainerProps,
		jiraEPRef,
		nestedEPRef,
		shouldRenderNestedEntryPoint = true,
	}: EntryPointAdapterProps<TEntryPoint>) => {
		// If the route is an entryPoint, and the initialRoute was an entryPoint, use EntryPointContainer.
		// Otherwise, use the JiraEntryPointContainer.
		return shouldRenderNestedEntryPoint && nestedEPRef ? (
			// eslint-disable-next-line jira/ufo/valid-labels
			<UFOSegment name={`${epContainerProps.id}`}>
				<JSErrorBoundary
					attributes={epContainerProps.errorAttributes}
					fallback={epContainerProps.errorFallback}
					id={epContainerProps.id}
					packageName={epContainerProps.packageName}
					teamName={epContainerProps.teamName}
				>
					<EntryPointReferencePlaceholder
						name={`${epContainerProps.id || 'route-entrypoint-adapter'}-entrypoint-placeholder`}
						fallback={epContainerProps.fallback}
						entryPointReference={nestedEPRef}
					>
						<EntryPointContainer
							entryPointReference={nestedEPRef}
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							props={{} as never}
						/>
					</EntryPointReferencePlaceholder>
				</JSErrorBoundary>
			</UFOSegment>
		) : (
			<JiraEntryPointContainer {...epContainerProps} entryPointReferenceSubject={jiraEPRef} />
		);
	},
);
