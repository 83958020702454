import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { nextGenBacklogEntryPoint } from '@atlassian/jira-spa-apps-next-gen-backlog/entrypoint.tsx';
import { nav4JSWProjectEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-jsw-project-menu/src/ui/jsw-expandable-project/async-content/entrypoint.tsx';
import { nav4SidebarContentCoreEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-core/entrypoint.tsx';
import { nav4ProjectsContentEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/ui/projects/default/async-content/entrypoint.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';

export const classicBacklogPageEntryPoint = createPageEntryPoint({
	main: nextGenBacklogEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	leftSidebar: {
		sidebar: nav4SidebarContentCoreEntryPoint,
		entryPoints: {
			projects: {
				entryPoint: nav4ProjectsContentEntryPoint,
			},
			boards: {
				entryPoint: nav4JSWProjectEntryPoint,
			},
		},
		isProjectsExpanded: true,
	},
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});
