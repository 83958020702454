import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JSResourceForInteraction } from '@atlassian/react-async';
import type { ViewOptionsWithDisplaySettingsProps } from './async.tsx';

export const ViewOptionsMenuPopupContentEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-display-settings-menu-popup-content" */ './async').then(
			({ ViewOptionsWithDisplaySettings }) => ViewOptionsWithDisplaySettings,
		),
	),
	getPreloadProps: (extraProps: ViewOptionsWithDisplaySettingsProps) => ({
		queries: {}, // Need to specify `queries` as it is a mandatory prop
		extraProps,
	}),
});
