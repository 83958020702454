import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { productsJswConfigurationResource } from '@atlassian/jira-router-resources-products-jsw-configuration/src/index.tsx';
import { productsJswConfigurationRoute } from '@atlassian/jira-router-routes-products-jsw-configuration-routes/src/productsJswConfigurationRoute.tsx';
import ProductsJswConfiguration, { LazyProductsJswConfiguration } from './ui/index.tsx';

export const productsJswConfigurationRouteEntry = createEntry(
	productsJswConfigurationRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'admin.jsw-configuration',
		component: ProductsJswConfiguration,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			productsJswConfigurationResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyProductsJswConfiguration],
	}),
);
