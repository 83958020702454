import React from 'react';
import Banner, { type BannerProps } from '@atlaskit/banner';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { shouldBeChoreographed } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { ChoreographerBanner } from './choreographer-banner.tsx';

export type JiraBannerProps = BannerProps & MaybeChoreographedComponentProps;

export const JiraBanner = (props: JiraBannerProps) => {
	if (shouldBeChoreographed(props)) {
		const { messageType, ...rest } = props;
		return <ChoreographerBanner {...rest} />;
	}

	const { messageType, messageId, onMessageDisposition, ...rest } = props;
	return <Banner {...rest} />;
};
