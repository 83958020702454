import { fg } from '@atlassian/jira-feature-gating';
import { UNLICENSED } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { GLOBAL_APP_MENU_IDS_AS_STRINGS } from '../../constants/index.tsx';
import { L1_MENU_ID, type GlobalAppMenuId } from '../../types.tsx';

export type GlobalAppsLicense = {
	goals: boolean;
	assets: boolean;
};

/**
 * Assets should show when there's a JSM license
 * Goals should show for all other Jira products but not when it's JSM-only
 */
export function useGlobalApps(): GlobalAppsLicense {
	const appEditions = useAppEditions();

	const shouldShowGoals =
		// eslint-disable-next-line jira/ff/no-preconditioning
		(appEditions.productDiscovery !== UNLICENSED ||
			appEditions.software !== UNLICENSED ||
			// JSM comes with a free core license. Goals don't display with JSM-only, so checking for unlicensed JSM
			(appEditions.core !== UNLICENSED && appEditions.serviceDesk === UNLICENSED)) &&
		fg('blu-6313_sidebar_global_app_shortcuts') &&
		fg('blu-6576_migrate_to_platform_customisation');

	const shouldShowAssets =
		// eslint-disable-next-line jira/ff/no-preconditioning
		appEditions.serviceDesk !== UNLICENSED &&
		fg('assets_as_an_app_v2') &&
		fg('blu-6313_sidebar_global_app_shortcuts') &&
		fg('blu-6576_migrate_to_platform_customisation');

	return { goals: shouldShowGoals, assets: shouldShowAssets };
}

export const isGlobalAppMenuId = (value: string): value is GlobalAppMenuId =>
	GLOBAL_APP_MENU_IDS_AS_STRINGS.includes(value);

export const hasGlobalAppLicense = (
	value: string,
	globalAppLicense: GlobalAppsLicense,
): value is GlobalAppMenuId => {
	switch (value) {
		case L1_MENU_ID.GLOBAL_APP_ASSETS:
			return globalAppLicense.assets;
		case L1_MENU_ID.GLOBAL_APP_GOALS:
			return globalAppLicense.goals;
		default:
			return false;
	}
};
