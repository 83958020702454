import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	popoutIconLabel: {
		id: 'servicedesk-queues-nav4.common.popout-toggle-button.icons.popout-icon-label',
		defaultMessage: 'Pop out icon',
		description: 'Accessibility label for the pop-out icon',
	},
	popinIconLabel: {
		id: 'servicedesk-queues-nav4.common.popout-toggle-button.icons.popin-icon-label',
		defaultMessage: 'Pop in icon',
		description: 'Accessibility label for the pop-in icon',
	},
});
