import { resources as alertIssueSyncingResources } from '@atlassian/eoc-alert-issue-syncing/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocAlertIssueSyncingRoute } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-routes/src/eocAlertIssueSyncingRoute.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazySidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-operations/src/ui/index.tsx';
import { LazyAlertIssueSync } from './ui/async.tsx';
import { AlertIssueSync } from './ui/AlertIssueSync.tsx';

const getNavigationSpecifications = () => {
	return fg('operations_nav4_remaining_items')
		? {
				sidebarId: SIDEBAR_ID.OPERATIONS_GENERAL_CONFIGURATION,
			}
		: {
				sidebar: AsyncSidebarGlobalSettingsProducts,
				horizontal: AsyncHorizontalOperationsConfigurationNav,
				onlyShowHorizontalOnNav4: true,
			};
};

export const eocAlertIssueSyncingRouteEntry = createEntry(eocAlertIssueSyncingRoute, () => ({
	group: 'eoc',
	component: AlertIssueSync,
	slot: null,
	basePath: '/jira/settings/products/ops',
	layout: opsSettingsLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		...getNavigationSpecifications(),
	},
	forPaint: fg('operations_nav4_remaining_items')
		? [LazySidebarContent, LazyAtlassianNavigation, LazyAlertIssueSync]
		: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertIssueSync],
}));
