import formatInTimeZone from 'date-fns-tz/formatInTimeZone';

import { guessUserTimeZone } from '@atlassian/eoc-alert-common';
import { getMeta } from '@atlassian/eoc-fetch';

export const withAIOpsPrefix = (input: string): string => {
	const cloudId = getMeta('ajs-cloud-id');
	if (cloudId) {
		return `/gateway/api/jira/aiops/cloudId/${cloudId}/api` + input;
	}

	return input;
};

export function formatTimeZoneName(timezone: string) {
	const splittedTz = timezone.split('/');

	// EST || EST5EDT
	if (splittedTz.length === 0) {
		return splittedTz;
	}

	// Europe/Amsterdam || Europe/Isle_of_Man || America/North_Dakota/New_Salem
	if (splittedTz.length > 0) {
		const areaName = splittedTz[splittedTz.length - 1];
		return areaName.replace(/_/g, ' ');
	}

	return timezone;
}

export function formatTimezone(timezone?: string) {
	const timeZone = timezone ?? guessUserTimeZone();

	return formatInTimeZone(new Date(), timeZone, `z '${formatTimeZoneName(timeZone)}'`);
}