// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	KEY_DISMISSED,
	KEY_OPEN,
	QUICKSTART_UI_STATUS,
	getBackendVisibilityKey,
} from '../../../common/constants.tsx';
import { pushToBackend } from '../../../common/utils/push-to-backend.tsx';
import type { State } from '../../model/types.tsx';
import { getQuickstartTypeSelector } from '../../model/utils.tsx';
import { setLocalStorageItem } from '../../utils.tsx';

// TODO: Remove this and replace with set visibility action
export const nextSetChecklistIsDismissed =
	(isDismissed: boolean): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		const quickstartType = getQuickstartTypeSelector(state);
		const currentIsDismissed = state.visibility[quickstartType] === QUICKSTART_UI_STATUS.DISMISSED;
		if (isDismissed === currentIsDismissed) {
			// if state has not been updated we just return
			return;
		}

		const quickstartStatus = isDismissed
			? QUICKSTART_UI_STATUS.DISMISSED
			: QUICKSTART_UI_STATUS.OPEN;

		setState({
			visibility: {
				...state.visibility,
				[quickstartType]: quickstartStatus,
			},
			isFocusedOn: !isDismissed,
		});

		pushToBackend({
			key: getBackendVisibilityKey(quickstartType),
			value: quickstartStatus,
		});
		setLocalStorageItem(`${quickstartType}.visibility`, quickstartStatus);

		// Delete all code below when cleaning up isDismissed in state
		if (isDismissed === false) {
			// When setting IsDismissed to false, also setting QS status to OPEN so checklist appears in open state
			pushToBackend({
				key: KEY_OPEN,
				value: true,
			});
		} else {
			// When setting IsDismissed, also toggle the open state of the checklist to match.
			pushToBackend({
				key: KEY_OPEN,
				value: false,
			});
		}

		pushToBackend({
			key: KEY_DISMISSED,
			value: isDismissed,
		});
	};
