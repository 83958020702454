import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
// Planning to create a new layout for Jira business. Can remove if team decides to use existing layout
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { classicBusinessProjectFormRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectFormRoute.tsx';
import { formPageEntrypoint } from './ui/spa/business/form/index.tsx';

export const classicBusinessProjectFormRouteEntry = createEntry(
	classicBusinessProjectFormRoute,
	() => ({
		group: 'classic-business',
		ufoName: 'jwm.form-directory-view',
		layout: businessProjectLayout,
		skeleton: ThemedLazySkeleton,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			// delete when cleaning up getWillShowNav4
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavJira,
		},

		resources: getProjectViewResources(),

		entryPoint: () => formPageEntrypoint,
	}),
);
