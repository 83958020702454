import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsFormsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsFormsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { navigation } from './common/constants.tsx';
import { helpCenterSettingFormsAppPageEntryPoint } from './ui/forms/index.tsx';

export const helpCenterSettingsFormsRouteEntry = createEntry(helpCenterSettingsFormsRoute, () => ({
	group: 'helpcenter-settings',
	perfMetricKey: 'help-center-settings.forms',
	layout: serviceProjectLayout,
	navigation,
	component: ErrorPagesNotFound,
	entryPoint() {
		return fg('jcs_master_flag') ? helpCenterSettingFormsAppPageEntryPoint : undefined;
	},
	resources: [...getServiceDeskSettingsResources()],
}));
