// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { convertToHaveSeenItTask } from '../../../common/constants.tsx';
import { pushToBackend } from '../../../common/utils/push-to-backend.tsx';
import type { State } from '../../model/types.tsx';

export const nextSetChecklistItemSeen =
	(taskId: string): Action<State> =>
	({ getState, setState }) => {
		const hasSeenTaskId = convertToHaveSeenItTask(taskId);
		const { hasSeenTasks } = getState();

		if (hasSeenTasks.includes(hasSeenTaskId)) {
			return;
		}

		setState({ hasSeenTasks: [...hasSeenTasks, hasSeenTaskId] });

		pushToBackend({
			key: convertToHaveSeenItTask(taskId),
			value: true,
		});
	};
