import React from 'react';
import type ServicedeskQueuesAgentAndIssueViewType from '@atlassian/jira-spa-apps-servicedesk-queues-agent-and-issue-view/src/index.tsx';
import type { QueuesIssueViewEntry as QueuesIssueViewEntryType } from '@atlassian/jira-spa-apps-servicedesk-queues-issue-view-entry/src/index.tsx';
import type { QueuesAgentViewEntry as QueuesAgentViewEntryType } from '@atlassian/jira-spa-apps-servicedesk-queues-agent-view-entry/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { LazyServicedeskQueuesAgentView } from './CustomQueueNewRouteComponent.tsx';
import { LazyServicedeskQueuesIssueView } from './CustomIssueRouteComponent.tsx';
import type { InternalRouteName } from './types.tsx';

const importNewComponent = (route: InternalRouteName) => {
	if (route === 'issue') {
		return import(
			/* webpackChunkName: "async-servicedesk-queues-issue-view", jiraSpaEntry: "async-servicedesk-queues-issue-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-issue-view-entry'
		).then(({ QueuesIssueViewEntry }) => QueuesIssueViewEntry);
	}
	return import(
		/* webpackChunkName: "async-servicedesk-queues-agent-view", jiraSpaEntry: "async-servicedesk-queues-agent-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-agent-view-entry'
	).then(({ QueuesAgentViewEntry }) => QueuesAgentViewEntry);
};

// Conditionally import the correct chunk. The parameter is provided statically
// at module load time. If the FG is off the old chunk with two statically imported
// components (issue view and list view) is loaded insted. When the FG is on,
// this loads a different component for the two routes.
// TODO: remove this fundion (and also probably all of current file) when
// split_loading_of_queues_agent_view_from_issue_view is rolled out.
export const getLazyServicedeskQueuesAgentAndIssueView = (route: InternalRouteName) =>
	lazyForPaint<
		| typeof ServicedeskQueuesAgentAndIssueViewType
		| typeof QueuesIssueViewEntryType
		| typeof QueuesAgentViewEntryType
	>(() => {
		if (fg('split_loading_of_queues_agent_view_from_issue_view')) {
			return markAsProductBundleReady(importNewComponent(route));
		}
		return markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-servicedesk-queues-agent-and-issue-view", jiraSpaEntry: "async-servicedesk-queues-agent-and-issue-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-agent-and-issue-view'
			),
		);
	});

export const LazyServicedeskQueuesAgentAndIssueView =
	getLazyServicedeskQueuesAgentAndIssueView('list');

type Props = RouteContext;

// It is important to keep this component consistent across the two routes (one
// of which is temporary) so that React doesn't unmount it when switching between
// the routes. OTOH, when the split_loading_of_queues_agent_view_from_issue_view FG
// is on, it will behave as if the component was replaced with the new one after
// the feature rollout depending on whether this is issue or list route.
// TODO: remove this (and also probably all of current file) when split_loading_of_queues_agent_view_from_issue_view
// is rolled out. This is because this route is simply being replaced by CustomQueueNewRouteComponent
export const CustomQueueRouteComponent = ({ match }: Props) => {
	const queueId = (match.params && match.params.queueId) || '';
	const issueKey = (match.params && match.params.issueKey) || '';
	const oldIssueView = `/${(match.query && match.query.oldIssueView) || ''}`;

	let ComponentToRender = LazyServicedeskQueuesAgentAndIssueView;
	if (fg('split_loading_of_queues_agent_view_from_issue_view')) {
		if (issueKey) {
			ComponentToRender = LazyServicedeskQueuesIssueView;
		} else {
			ComponentToRender = LazyServicedeskQueuesAgentView;
		}
	}

	return (
		<LazyPage
			Page={ComponentToRender}
			pageId={`spa-apps/servicedesk/queues/${queueId}/${issueKey}/${oldIssueView}`}
			shouldShowSpinner={!fg('jsm_views_inside_queues_-_main_flag')}
		/>
	);
};
