import camelCase from 'lodash/camelCase';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskJourneyConfigurationRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-routes/src/projectSettingsServicedeskJourneyConfigurationRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { journeyConfigPageEntryPoint } from './ui/journey-config/index.tsx';

export const projectSettingsServicedeskJourneyConfigurationRouteEntry = createEntry(
	projectSettingsServicedeskJourneyConfigurationRoute,
	() => ({
		group: 'project-settings-servicedesk',
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		meta: {
			reporting: {
				id: 'jsm.journey-builder.journey-config',
				packageName: camelCase('journey-config'),
				teamName: 'JSM Getting Started',
			},
		},
		layout: serviceProjectLayout,
		resources: [...getServiceDeskSettingsResources()],
		entryPoint: () => (fg('jsm-journey-builder') ? journeyConfigPageEntryPoint : undefined),
	}),
);
