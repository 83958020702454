import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { planUpsellRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/planUpsellRoute.tsx';

import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';

import { planUpsellPageEntryPoint } from './ui/plan-upsell/index.tsx';

export const planUpsellRouteEntry = createEntry(planUpsellRoute, () => ({
	group: 'software',
	entryPoint() {
		return planUpsellPageEntryPoint;
	},
	layout: globalLayout,
	resources: [...getNavigationResources(), ...getNavigationSidebarPlanResources()],
	navigation: {
		menuId: MENU_ID.PLANS,
	},
}));
