import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { activityFeedResource } from '@atlassian/jira-router-resources-business-summary-activity-feed/src/index.tsx';
import { summaryDataResource } from '@atlassian/jira-router-resources-business-summary-data/src/index.tsx';
import { jiraBusinessSummaryRoute } from '@atlassian/jira-router-routes-business-summary-routes/src/jiraBusinessSummaryRoute.tsx';
import { summaryEntrypoint } from '@atlassian/jira-spa-apps-business-summary/entrypoint.tsx';
import { summaryBusinessEntrypoint } from '@atlassian/jira-spa-apps-summary/business/entrypoint.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * @deprecated delete this entrypoint when cleaning up jwm-summary-page-migration experiment flag
 */
const summaryPageEntrypoint = createPageEntryPoint({
	main: summaryEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

const summaryBusinessPageEntrypoint = createPageEntryPoint({
	main: summaryBusinessEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

const getSummaryPageResource = () => {
	return [
		...(fg('helios_biz_project_remove_rrr') ? [] : [summaryDataResource]),
		...(fg('helios_biz_project_fast_activity_feed') ? [] : [activityFeedResource]),
	];
};

export const jiraBusinessSummaryRouteEntry = createEntry(jiraBusinessSummaryRoute, () => ({
	group: 'classic-business',
	ufoName: 'jwm.summary-view',
	layout: businessProjectLayout,
	skeleton: ThemedLazySkeleton,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		// delete when cleaning up getWillShowNav4
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalNavJira,
	},

	resources: [...getProjectViewResources(), ...getSummaryPageResource()],

	entryPoint: () => {
		if (expVal('jwm-summary-page-migration', 'isEnabled', false)) {
			return summaryBusinessPageEntrypoint;
		}

		return summaryPageEntrypoint;
	},
}));
