/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { AppSkeletonImage } from '../../../common/ui/AppSkeletonImage.tsx';
import nav4ImgSVG from '../assets/project-issue-navigator-nav4-skeleton.tpl.svg';
import imgSVG from '../assets/project-issue-navigator-skeleton.tpl.svg';

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		borderRightWidth: '32px',
		borderLeftWidth: '32px',
		/* Fix FF 2x scale bug on high res devices https://bugzilla.mozilla.org/show_bug.cgi?id=986403 */
		'@media (min--moz-device-pixel-ratio: 2)': {
			borderLeftWidth: '16px',
		},
	},
});

export const ProjectIssueNavigatorSkeleton = () => (
	<AppSkeletonImage src={getWillShowNav4() ? nav4ImgSVG : imgSVG} css={customCssStyles} />
);
