import { type DependencyList, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import invariant from 'tiny-invariant';

// Ensures an effect does not run twice due to concurrency.
// NOTE: This hook does not support hooks that have a cleanup function.
//       The reason why is that we have no way of determining whether the cleanup function
//       about to executed is due to strict-mode, or due to an actual unmount/dependency change.
export function useEffectWithoutConcurrency(
	effect: () => void,
	dependencies?: DependencyList | undefined,
) {
	const effectCalledRef = useRef(false);
	const depsRef = useRef(dependencies);

	useEffect(() => {
		// If the dependencies have changed, reset the state of the ref.
		// This is how we catch the strict-mode execution.
		if (!isEqual(depsRef.current, dependencies)) {
			depsRef.current = dependencies;
			effectCalledRef.current = false;
		}

		// If the effect has already executed, we shouldn't be executing again unless we do a cleanup first.
		// This is the code that ignores the strict-mode update
		if (effectCalledRef.current) return;

		// Set this immediately
		effectCalledRef.current = true;

		const cleanup = effect();

		// If we do get a cleanup function, throw an error
		invariant(
			typeof cleanup !== 'function',
			'useEffectWithoutConcurrency() cannot be used with cleanup functions',
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
}
