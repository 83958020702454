import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import {
	AsyncJSMOperationsHorizontalNav,
	LazyJSMOperationsHorizontalNav,
} from '@atlassian/jira-horizontal-nav-jsm-operations/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskOncallScheduleRoute } from '@atlassian/jira-router-routes-servicedesk-oncall-routes/src/servicedeskOncallScheduleRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import ServiceDeskOpsgenieSchedule, {
	LazyServicedeskOpsgenieSchedule,
} from './ui/opsgenie-schedule/index.tsx';

export const servicedeskOncallScheduleRouteEntry = createEntry(
	servicedeskOncallScheduleRoute,
	() => ({
		group: 'servicedesk',
		ufoName: 'service-management.on-call-schedule',
		component: ServiceDeskOpsgenieSchedule,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncJSMOperationsHorizontalNav,
			onlyShowHorizontalOnNav4: true,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

		forPaint: [
			LazyAtlassianNavigation,
			LazyServicedeskOpsgenieSchedule,
			LazyJSMOperationsHorizontalNav,
		],
	}),
);
