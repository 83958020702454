import { fg } from '@atlassian/jira-feature-gating';
import { AnyAri } from '@atlassian/ari/any-ari';
import { JiraProjectCategoryAri } from '@atlassian/ari/jira/project-category';
import {
	PROJECT_TYPES,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
// eslint-disable-next-line jira/import-whitelist
import { matchAliases } from '@atlassian/jira-directory-base-v3/src/utils/search-string/index.tsx';
import { isAri, createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { JiraProjectFilterInput } from '@atlassian/jira-relay/src/__generated__/ProjectDirectoryRefetchQuery.graphql';
import type { Query } from '@atlassian/react-resource-router';
import {
	type TableColumns,
	SortableColumns,
	FilterFields,
	filterInitialState,
	type FilterInitialState as Filter,
} from '../constants.tsx';

type Dictionary = {
	[key: string]: string;
};

const LEGACY_ALL_VALUE = 'all';

export const sanitizeLegacyQuery = (query: Query) => ({
	...query,
	selectedProjectType:
		query.selectedProjectType === LEGACY_ALL_VALUE
			? filterInitialState[FilterFields.ProjectType]
			: query.selectedProjectType,
	selectedCategory:
		query.selectedCategory === LEGACY_ALL_VALUE
			? filterInitialState[FilterFields.ProjectCategoryId]
			: query.selectedCategory,
});

export const mapQueryToFilter = <T extends Dictionary>(
	filter: T,
	{
		cloudId,
		query,
		aliases,
	}: { cloudId: string; query?: Query; aliases?: { [alias: string]: keyof T } },
) => {
	const variables: JiraProjectFilterInput = {};
	const {
		[FilterFields.Keyword]: keyword,
		[FilterFields.ProjectCategoryId]: projectCategoryId,
		[FilterFields.ProjectType]: types,
		[FilterFields.SortOrder]: sortOrder,
		[FilterFields.SortKey]: sortKey,
	} = (aliases && query ? matchAliases(filter, query, aliases) : filter) as Filter; // eslint-disable-line @typescript-eslint/consistent-type-assertions

	if (keyword) {
		variables.keyword = keyword;
	}

	if (projectCategoryId) {
		const isAlreadyAri = fg('camptasks-1102-enable-atlassian-ari-projects')
			? AnyAri.check(projectCategoryId)
			: isAri(projectCategoryId);
		if (isAlreadyAri) {
			variables.projectCategoryId = projectCategoryId;
		} else {
			variables.projectCategoryId = fg('camptasks-1102-enable-atlassian-ari-projects')
				? JiraProjectCategoryAri.create({
						projectCategoryId,
						siteId: cloudId,
					}).toString()
				: createAri({
						resourceOwner: 'jira',
						cloudId,
						resourceType: 'project-category',
						resourceId: projectCategoryId,
					});
		}
	}

	if (sortKey || sortOrder) {
		const sortByValue =
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			sortKey && SortableColumns.includes(sortKey as TableColumns)
				? sortKey
				: filterInitialState[FilterFields.SortKey];

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		variables.sortBy = {
			order: ['ASC', 'DESC'].includes(sortOrder)
				? sortOrder
				: filterInitialState[FilterFields.SortOrder],
			sortBy: sortByValue.toUpperCase(),
		} as JiraProjectFilterInput['sortBy'];
	}

	if (types && typeof types === 'string') {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		variables.types = types
			.split(',') // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			.filter((type) => PROJECT_TYPES.includes(type as ProjectType))
			.map((type) => type.toUpperCase()) as unknown as JiraProjectFilterInput['types'];
	}
	return variables;
};
