import React, { useEffect } from 'react';
import { useGlobalScopeEnrolmentEnable } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/global-scope-enrolment-flow/index.tsx';
import {
	setIsGlobalScopeEnrolmentEnabledInLocalStorage,
	getIsGlobalScopeForcedOptOutGinFlagDisabledLocalStorage,
	setGlobalScopeForcedOptOutGinFlagEnabledInLocalStorage,
} from '@atlassian/jira-issue-navigator-global-scope-enrolment/src/services/global-scope-enrolment/local-storage/index.tsx';
import { resetGlobalScopeForcedOptOutGinFlag } from '@atlassian/jira-issue-navigator-global-scope-enrolment/src/services/global-scope-enrolment/set-global-scope-user-preference/index.tsx';
import { GlobalIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/global-app.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext, RouteContext as Props } from '@atlassian/react-resource-router';
import { issueNavigatorEntrypoint } from '@atlassian/jira-spa-apps-project-issue-navigator/entrypoint.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { issueNavigatorPinRedirectEntrypoint } from './pin-redirect/entrypoint.tsx';
import pageIdGenerator from './page-id-generator/index.tsx';
import { globalIssueNavigatorGinRedirectEntrypoint } from './gin-redirect/entrypoint.tsx';

export const globalIssueNavigatorGinRedirectPageEntryPoint = createPageEntryPoint({
	main: globalIssueNavigatorGinRedirectEntrypoint,
	topNavigationMenuId: MENU_ID.FILTERS,
});

export const issueNavigatorPageEntryPoint = createPageEntryPoint({
	main: issueNavigatorEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

export const issueNavigatorWithoutHorizontalNavPageEntryPoint = createPageEntryPoint({
	main: issueNavigatorEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const issueNavigatorPinRedirectPageEntryPoint = createPageEntryPoint({
	main: issueNavigatorPinRedirectEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyGlobalIssueNavigator = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-global-issue-navigator", jiraSpaEntry: "global-issue-navigator" */ '@atlassian/jira-spa-apps-global-issue-navigator'
		),
);

/**
 *
 * @deprecated delete when jira_list_gin_entrypoint is cleaned up
 */
export const GlobalIssueNavigator = ({ match, query }: Props) => (
	<LazyPage
		Page={LazyGlobalIssueNavigator}
		pageId={pageIdGenerator(match, query)}
		shouldShowSpinner={false}
	/>
);

/**
 * GinRedirect is responsible for deciding whether to render NIN in the global scope, or to redirect to legacy GIN. It
 * relies on two sources of truth:
 *
 * 1. Local storage. This is used as a cache to improve performance, and is prefered whenever it is available.
 *
 * 2. Jira's backend. This is used as a backup if local storage is unavailable, and is used to keep local storage
 *    up-to-date.
 * @deprecated delete when jira_list_gin_entrypoint is cleaned up
 */
export const GinRedirect = (props: RouteContext) => {
	const [isNinGlobalScopeEnrolmentEnabled, resourceData] = useGlobalScopeEnrolmentEnable();
	const isAnonymous = useIsAnonymous();
	// Regardless of the existing localStorageData value, we update the localStorage value once we get resourceData so
	// that future renders are fast and accurate.
	useEffect(() => {
		if (resourceData !== null && resourceData !== undefined) {
			setIsGlobalScopeEnrolmentEnabledInLocalStorage(resourceData);
		}
	}, [resourceData]);

	// If NIN in global scope is not enabled then we redirect to legacy GIN by performing a reload
	useEffect(() => {
		if (isNinGlobalScopeEnrolmentEnabled === false) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.reload();
		}
	}, [isNinGlobalScopeEnrolmentEnabled]);

	useEffect(() => {
		if (isNinGlobalScopeEnrolmentEnabled) {
			// If we are in global scope nin then reset/enable the forced opt out flag for GIN if we haven't done it already
			const globalGINFlagDisabled = getIsGlobalScopeForcedOptOutGinFlagDisabledLocalStorage();
			if (globalGINFlagDisabled !== false) {
				!isAnonymous && resetGlobalScopeForcedOptOutGinFlag();
				setGlobalScopeForcedOptOutGinFlagEnabledInLocalStorage();
			}
		}
	}, [isAnonymous, isNinGlobalScopeEnrolmentEnabled]);

	// If NIN in global scope is enabled then we render it. If NIN in global scope is not enabled, or we're blocked
	// waiting on the resource to load then we render GlobalIssueNavigatorSkeleton.
	return isNinGlobalScopeEnrolmentEnabled === true ? (
		<GlobalIssueNavigator {...props} />
	) : (
		<GlobalIssueNavigatorSkeleton />
	);
};
