import React, { useCallback, useState } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import AkForm, { Field, Label } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import EmojiAddIcon from '@atlaskit/icon/core/migration/emoji-add';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getCollectionDetailsPageUrl } from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import {
	getJQLForCollection,
	getFieldsFromJql,
} from '@atlassian/jira-polaris-component-collections/src/common/utils/jql.tsx';
import { useCollectionsActions } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { CollectionAdvancedFilters } from '@atlassian/jira-polaris-component-collection-advanced-filters/src/ui/index.tsx';
import {
	PROJECT_FIELDKEY,
	SUMMARY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import type { Collection } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import { ProjectSelect } from '@atlassian/jira-polaris-project-select/src/ui/index.tsx';
import { DRAWER_Z_INDEX } from '../../common/constants.tsx';
import { getIdeasCountForCollection } from '../../services/ideas/index.tsx';
import { getFieldsKeysWithJql } from '../../services/fields/index.tsx';
import { FormFieldKeys, IDEAS_COUNT_LIMIT, IDEAS_COUNT_LIMIT_OLD } from './constants.tsx';
import { Footer } from './footer/index.tsx';
import messages from './messages.tsx';
import { ProjectSelect as ProjectSelectLegacy } from './project-select/index.tsx';
import type { FormData } from './types.tsx';
import { useCollectionIdeasCount } from './utils/index.tsx';
import { FilterExplanation } from './filter-explanation/index.tsx';

const MAX_COLLECTION_NAME_LENGTH = 100;

export type FormProps = {
	onSuccess?: (collection: Collection) => void;
};

export const Form = ({ onSuccess }: FormProps) => {
	const [projectKeys, setProjectKeys] = useState<string[]>([]);
	const [filtersJql, setFiltersJql] = useState<string>('');
	const { formatMessage } = useIntl();
	const [selectedEmojiId, setSelectedEmojiId] = useState<string>();
	const [ideasCountOLD, setIdeasCountOLD] = useState(0);
	const { createCollection, createView } = useCollectionsActions();
	const { errorWithRefresh } = useNotifications();
	const { push } = useRouterActions();
	const ownerId = useAccountId() ?? '';
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [ideasCount, isIdeasCountLoading, _ideasCountError, ideasCountRequest] =
		useCollectionIdeasCount(projectKeys, filtersJql);

	const validateNameField = useCallback(
		(value: string | undefined) => {
			if (value === undefined || value.trim().length === 0) {
				return formatMessage(messages.emptyNameError);
			}
			if (value.length > MAX_COLLECTION_NAME_LENGTH) {
				return formatMessage(messages.nameTooLongError);
			}

			return undefined;
		},
		[formatMessage],
	);

	const validateProjectsField = useCallback(
		(value: string[] | undefined) => {
			if (value === undefined || value.length === 0) {
				return formatMessage(messages.emptyProjectsError);
			}

			return undefined;
		},
		[formatMessage],
	);

	const handleSubmit = useCallback(
		async (formData: FormData) => {
			try {
				const projectKeysOLD = formData[FormFieldKeys.PROJECTS];
				const jql = fg('jpd-aurora-roadmap-advanced-filtering')
					? getJQLForCollection(projectKeys, filtersJql)
					: getJQLForCollection(projectKeysOLD, undefined);

				if (fg('jpd-aurora-roadmap-advanced-filtering')) {
					let newIdeasCount = ideasCount;
					if (ideasCountRequest) {
						newIdeasCount = await ideasCountRequest;
					}
					if (newIdeasCount && newIdeasCount > IDEAS_COUNT_LIMIT) {
						return;
					}
				} else {
					const newIdeasCount = await getIdeasCountForCollection(jql);
					setIdeasCountOLD(newIdeasCount);

					if (newIdeasCount > IDEAS_COUNT_LIMIT) {
						return;
					}
				}

				const collection = await createCollection({
					name: formData[FormFieldKeys.COLLECTION_NAME],
					jql,
					emoji: selectedEmojiId,
					ownerId,
					createAnalyticsEvent,
				});

				if (!collection) {
					return;
				}

				const fieldsInJQL = await (fg('jpd-aurora-roadmap-advanced-filtering')
					? getFieldsKeysWithJql(jql)
					: Promise.resolve({}));

				const fields = [
					SUMMARY_FIELDKEY,
					PROJECT_FIELDKEY,
					...getFieldsFromJql(filtersJql, fieldsInJQL),
				];

				await createView(collection.uuid, {
					visualizationType: VIEW_KIND_TABLE,
					name: formatMessage(messages.listViewName),
					fields,
				});
				await createView(collection.uuid, {
					visualizationType: VIEW_KIND_TIMELINE,
					name: formatMessage(messages.timelineViewName),
					fields,
				});
				await createView(collection.uuid, {
					visualizationType: VIEW_KIND_BOARD,
					name: formatMessage(messages.boardViewName),
					fields,
				});
				await createView(collection.uuid, {
					visualizationType: VIEW_KIND_MATRIX,
					name: formatMessage(messages.matrixViewName),
					fields,
				});

				push(getCollectionDetailsPageUrl(collection.uuid));
				onSuccess?.({ ...collection });
			} catch (err) {
				errorWithRefresh({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.errorDescription),
				});
			}
		},
		[
			projectKeys,
			filtersJql,
			createCollection,
			selectedEmojiId,
			ownerId,
			createAnalyticsEvent,
			createView,
			formatMessage,
			push,
			onSuccess,
			ideasCount,
			errorWithRefresh,
			ideasCountRequest,
		],
	);

	const exceedsIdeasLimitOLD = ideasCountOLD > IDEAS_COUNT_LIMIT_OLD;

	return (
		<Box
			xcss={fg('jpd-aurora-roadmap-advanced-filtering') ? mainContainerStyles : undefined}
			paddingBlock={fg('jpd-aurora-roadmap-advanced-filtering') ? undefined : 'space.1000'}
			paddingInline={fg('jpd-aurora-roadmap-advanced-filtering') ? undefined : 'space.400'}
		>
			<AkForm<FormData> onSubmit={handleSubmit}>
				{({ formProps, submitting, setFieldValue, getValues }) => (
					<Box
						as="form"
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...formProps}
						name="collection-create-form"
						xcss={[
							formStyles,
							fg('jpd-aurora-roadmap-advanced-filtering') ? innerContainerStyles : containerStyles,
						]}
					>
						<Stack
							xcss={
								fg('jpd-aurora-roadmap-advanced-filtering')
									? scrollableContainerStyles
									: containerStyles
							}
						>
							<Heading size="xxlarge">{formatMessage(messages.heading)}</Heading>
							<Box paddingBlockStart="space.100">
								<Text as="p">{formatMessage(messages.description)}</Text>
							</Box>
							<Field
								label={formatMessage(messages.roadmapNameFieldLabel)}
								name={FormFieldKeys.COLLECTION_NAME}
								isRequired
								validate={validateNameField}
							>
								{({ fieldProps, error, meta }) => {
									const isInvalid =
										fieldProps.isInvalid && !!error && (meta.submitFailed || meta.dirty);

									return (
										<>
											<Textfield
												{...fieldProps}
												isInvalid={isInvalid}
												isRequired={false}
												testId="polaris-component-collection-create.ui.form.collection-name"
												autoFocus
												placeholder={formatMessage(messages.roadmapNameFieldPlaceholder)}
												elemBeforeInput={
													<Box paddingInlineStart="space.025">
														<EmojiPicker
															onEmojiSelected={(emoji) => {
																setSelectedEmojiId(emoji?.id);
															}}
															readonly={submitting}
															selectedEmojiId={selectedEmojiId}
															emojiAddIcon={(iconProps) => (
																<EmojiAddIcon
																	{...iconProps}
																	color={token('color.icon')}
																	LEGACY_primaryColor={token('color.icon')}
																/>
															)}
															zIndex={DRAWER_Z_INDEX + 1}
														/>
													</Box>
												}
											/>
											{isInvalid && (
												<ErrorContainer data-testid="polaris-component-collection-create.ui.form.name-field-error">
													{error}
												</ErrorContainer>
											)}
										</>
									);
								}}
							</Field>
							<Field<string[]>
								label={formatMessage(messages.projectsSelectFieldLabel)}
								name={FormFieldKeys.PROJECTS}
								isRequired
								validate={validateProjectsField}
							>
								{({ fieldProps: { onChange, ...restFieldProps }, error, meta }) => {
									const isInvalid =
										restFieldProps.isInvalid && !!error && (meta.submitFailed || meta.dirty);
									const { id, isRequired, value, ...selectProps } = restFieldProps;

									return (
										<>
											{fg('jpd_project_select_component') ? (
												<ProjectSelect
													{...selectProps}
													isInvalid={isInvalid}
													onChange={(newValue) => {
														const values = newValue.map((v) => v.value);
														if (fg('jpd-aurora-roadmap-advanced-filtering')) {
															if (Array.isArray(values)) {
																setProjectKeys(values);
															}
														} else {
															setIdeasCountOLD(0);
														}
														onChange(values);
													}}
												/>
											) : (
												<ProjectSelectLegacy
													{...restFieldProps}
													isInvalid={isInvalid}
													onChange={(values) => {
														if (fg('jpd-aurora-roadmap-advanced-filtering')) {
															if (Array.isArray(values)) {
																setProjectKeys(values);
															}
														} else {
															setIdeasCountOLD(0);
														}
														onChange(values);
													}}
												/>
											)}
											{isInvalid && (
												<ErrorContainer data-testid="polaris-component-collection-create.ui.form.projects-field-error">
													{error}
												</ErrorContainer>
											)}
										</>
									);
								}}
							</Field>
							{fg('jpd-aurora-roadmap-advanced-filtering') && (
								<Stack xcss={filtersContainerStyles} space="space.100">
									<Inline space="space.025" alignBlock="center">
										<Text color="color.text.subtle" weight="semibold" size="small">
											{formatMessage(messages.filtersLabel)}
										</Text>
										<FilterExplanation />
									</Inline>
									<Text size="small">{formatMessage(messages.filtersDescription)}</Text>
									<CollectionAdvancedFilters
										value={getValues()[FormFieldKeys.FILTERS]}
										projectKeys={projectKeys}
										onChange={(value: string) => {
											setFiltersJql(value);
											setFieldValue(FormFieldKeys.FILTERS, value);
										}}
									/>
								</Stack>
							)}
							{exceedsIdeasLimitOLD && !fg('jpd-aurora-roadmap-advanced-filtering') && (
								<Box paddingBlockStart="space.100">
									<Stack space="space.100">
										<Label htmlFor="">{formatMessage(messages.filtersLabel)}</Label>
										<Box
											padding="space.100"
											backgroundColor="color.background.warning"
											xcss={ideasLimitStyles}
										>
											{formatMessage(messages.limitExceededError, {
												ideasCount: ideasCountOLD,
												b: (text: React.ReactNode) => <b>{text}</b>,
											})}{' '}
											{formatMessage(messages.limitExceededErrorAction, {
												ideasCount: ideasCountOLD,
												maxIdeas: IDEAS_COUNT_LIMIT_OLD,
											})}
										</Box>
									</Stack>
								</Box>
							)}
						</Stack>
						<Footer
							submitting={submitting}
							ideasCount={fg('jpd-aurora-roadmap-advanced-filtering') ? ideasCount : ideasCountOLD}
							isIdeasCountLoading={isIdeasCountLoading}
						/>
					</Box>
				)}
			</AkForm>
		</Box>
	);
};

const mainContainerStyles = xcss({
	height: '100%',
});

const innerContainerStyles = xcss({
	height: '100%',
	paddingInline: 'space.400',
	overflow: 'auto',
});

// scrollable when we want to make footer sticky, we just need to add "overflow: auto" on it
const scrollableContainerStyles = xcss({
	paddingBlockStart: 'space.1000',
});

const containerStyles = xcss({
	margin: '0 auto',
	maxWidth: '410px',
	height: '100%',
});

const formStyles = xcss({
	display: 'flex',
	gap: 'space.150',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	minHeight: '0',
});

const ideasLimitStyles = xcss({
	borderRadius: 'border.radius.100',
});

const filtersContainerStyles = xcss({ paddingBlockStart: 'space.100', minHeight: '0' });
