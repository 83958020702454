/**
 * @generated SignedSource<<5f0514ae71f1cf87f13cf8b0cfb91670>>
 * @relayHash 09eec7021c360a9e38eb37c79037a670
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 77775bf11db6efc1fca1104f7ef4a5ee6a7b9bb9595edbe6f9140d71e16dfcdb

import type { ConcreteRequest, Query } from 'relay-runtime';
export type graphqlContextPanelQuery$variables = {
  cloudId: string;
  issueId?: string | null | undefined;
};
export type graphqlContextPanelQuery$data = {
  readonly agentAI_contextPanel: {
    readonly __typename: "AgentAIContextPanelResponse";
    readonly nextSteps: string | null | undefined;
    readonly reporterDetails: string | null | undefined;
    readonly suggestedActions: ReadonlyArray<{
      readonly content: {
        readonly description: string | null | undefined;
        readonly title: string | null | undefined;
      } | null | undefined;
      readonly context: {
        readonly fieldId: string | null | undefined;
        readonly suggestion: AGG$JSON | null | undefined;
      } | null | undefined;
      readonly type: string | null | undefined;
    } | null | undefined> | null | undefined;
    readonly suggestedEscalation: string | null | undefined;
    readonly summary: string | null | undefined;
  } | {
    readonly __typename: "QueryError";
    readonly extensions: ReadonlyArray<{
      readonly errorType: string | null | undefined;
      readonly statusCode: number | null | undefined;
    }> | null | undefined;
    readonly message: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type graphqlContextPanelQuery = {
  response: graphqlContextPanelQuery$data;
  variables: graphqlContextPanelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "issueId",
    "variableName": "issueId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "summary"
    },
    {
      "kind": "ScalarField",
      "name": "nextSteps"
    },
    {
      "kind": "ScalarField",
      "name": "reporterDetails"
    },
    {
      "kind": "ScalarField",
      "name": "suggestedEscalation"
    },
    {
      "concreteType": "AgentAISuggestAction",
      "kind": "LinkedField",
      "name": "suggestedActions",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "type"
        },
        {
          "concreteType": "AgentAISuggestedActionContent",
          "kind": "LinkedField",
          "name": "content",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "title"
            },
            {
              "kind": "ScalarField",
              "name": "description"
            }
          ]
        },
        {
          "concreteType": "AgentAISuggestedActionContext",
          "kind": "LinkedField",
          "name": "context",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "fieldId"
            },
            {
              "kind": "ScalarField",
              "name": "suggestion"
            }
          ]
        }
      ]
    }
  ],
  "type": "AgentAIContextPanelResponse"
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v6 = {
  "kind": "ScalarField",
  "name": "errorType"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "graphqlContextPanelQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "agentAI_contextPanel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ],
            "type": "QueryError"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphqlContextPanelQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "agentAI_contextPanel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ],
            "type": "QueryError"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "77775bf11db6efc1fca1104f7ef4a5ee6a7b9bb9595edbe6f9140d71e16dfcdb",
    "metadata": {},
    "name": "graphqlContextPanelQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a36eec22cb8eb04047a693d0800f10c6";

export default node;
