import {
	loadQuery,
	type LoadQueryOptions,
	type PreloadableConcreteRequest,
	type PreloadedQuery,
} from 'react-relay';
// eslint-disable-next-line jira/import-whitelist
import {
	getRequest,
	type GraphQLTaggedNode,
	type OperationType,
	type VariablesOf,
} from 'relay-runtime';
import { setMark, measureFunc, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
// eslint-disable-next-line jira/import-whitelist
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { startCaptureGraphQlErrors } from '@atlassian/jira-relay-errors/src/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises/src/index.tsx';
import {
	createResource,
	type createResource as CreateResourceType,
	type RouteResource,
	type ResourceStoreContext,
	type RouterContext,
	type RouterDataContext,
} from '@atlassian/react-resource-router';
// eslint-disable-next-line jira/import-whitelist
import { startCapturingTraceIds } from '@atlassian/relay-traceid';

export const MIN_MAX_AGE = 5 * 1000;

type CreateResourceShape<T> = Parameters<typeof CreateResourceType>[0] & {
	getData?: RouteResource<T>['getData'];
	getDataLoader?: () => Promise<{
		default: RouteResource<T>['getData'];
	}>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withResourceEarlyData = <T extends CreateResourceShape<any>>({
	type,
	getData: next,
	getDataLoader,
	...rest
}: T) => {
	const getData = (...args: Parameters<RouteResource['getData']>) => {
		performance.mark(`issueViewEarlyScripts:${type}:withResourceEarlyData:called`);

		if (!__SERVER__ && type in window) {
			// @ts-expect-error - Element implicitly has an 'any' type because index expression is not of type 'number'
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any, jira/jira-ssr/no-unchecked-globals-usage
			const promise = window[type] as Promise<any>;
			// @ts-expect-error - Element implicitly has an 'any' type because index expression is not of type 'number'

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			delete window[type];
			getDataLoader && promise.then(getDataLoader);
			return promise;
		}

		if (getDataLoader) {
			return getDataLoader().then(({ default: getDataPromise }) => getDataPromise(...args));
		}
		return next && next(...args);
	};
	return { ...rest, type, getData };
};

/**
 * Below is a duplicated version of jira/src/packages/platform/graphql/relay-utils/src/services/resources/index.tsx
 * The change is on the keyVariables which is required for issue view url params changing when perm link a comment in the current issue
 * So that the AGG query doesn't get reloaded.
 * We don't want to touch anything in graphql/relay-utils/ folder as it should be replaced by relay entry point
 * But introducing relay entry point for the whole issue view is a none trivial amount of effort
 * So we chose to duplicate relay-utils' logic instead. See: https://atlassian.slack.com/archives/C01E2MPPLE4/p1724285177787739
 */

/**
 * @deprecated Use Relay EntryPoint instead https://hello.atlassian.net/wiki/spaces/UAF/pages/2754170095
 */
export const RELAY_RESOURCE_TYPE = 'RELAY_RESOURCE_TYPE';

type Config<TQuery extends OperationType> = {
	parameters?: GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>;
	variables?: VariablesOf<TQuery>;
	options?: LoadQueryOptions;
	keyVariables?: VariablesOf<TQuery>;
};

type CreateRelayResourceConfig<TQuery extends OperationType> = {
	type: string;
	isBrowserOnly?: boolean;
	getQuery: (arg1: RouterContext | RouterDataContext, arg2: ResourceStoreContext) => Config<TQuery>;
	loadParameters?: () => Promise<GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>>;
	captureErrors?: boolean;
	captureTraceIds?: boolean;
};

const createRelayResourceImpl = <TQuery extends OperationType>({
	type,
	isBrowserOnly,
	getQuery,
	loadParameters,
	captureErrors = false,
	captureTraceIds = false,
}: CreateRelayResourceConfig<TQuery>): RouteResource<PreloadedQuery<TQuery>> =>
	createResource({
		type: `${RELAY_RESOURCE_TYPE}_${type}`,
		getKey: (routerContext: RouterContext, customContext: ResourceStoreContext) => {
			const {
				parameters: preloadableRequest,
				variables: queryVariables,
				keyVariables,
			} = getQuery(routerContext, customContext);

			// There are some variables that we don't want to trigger a reload even if they are changed, by default use 'variables', or you can also pass over
			// specific keyVariables that will trigger the reload
			const variables = keyVariables || queryVariables;

			// When providing an async query via `loadParameters` instead of getQuery's config, the query ID is not known upfront and so cannot be contributed to the resource key
			if (!preloadableRequest) {
				return JSON.stringify(variables || {});
			}

			let queryId;
			let params;

			// sources: https://github.com/facebook/relay/blob/4b78c7dd27b286f9ee8f5b993ccb160163956999/packages/react-relay/relay-hooks/loadQuery.js#L273
			// @ts-expect-error - TS2339 - Property 'kind' does not exist on type 'GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>'.
			if (preloadableRequest.kind === 'PreloadableConcreteRequest') {
				const preloadableConcreteRequest: PreloadableConcreteRequest<TQuery> =
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					preloadableRequest as any;
				({ params } = preloadableConcreteRequest);
				({ id: queryId } = params);
			} else {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				const graphQlTaggedNode: GraphQLTaggedNode = preloadableRequest as any;
				const request = getRequest(graphQlTaggedNode);
				params = request.params;
				queryId = 'cacheID' in params && params.cacheID != null ? params.cacheID : params.id;
			}

			return `${String(queryId)}${JSON.stringify(variables || {})}`;
		},
		getData: async (routerContext: RouterDataContext, customContext: ResourceStoreContext) => {
			const measureName = `relay_resource_get_data:${type}`;
			const startMark = `relay_resource_get_data:${type}:start`;
			const endMark = `relay_resource_get_data:${type}:end`;

			setMark(startMark);

			const { parameters, variables, options } = getQuery(routerContext, customContext);

			// We either have a sync query or an async one from loadParameters. Never neither
			// This is enforced by 2 aliases of this method having different types, but Typescript cannot infer that neatly, so we cast away the nullable case.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const query = (parameters ?? (await loadParameters?.())) as
				| GraphQLTaggedNode
				| PreloadableConcreteRequest<TQuery>;

			// loadQuery returns not a Promise, but Observable.
			// Resource waits until query Observable is completed and returns a queryReference
			// later queryReference could be used by usePreloadedQuery
			const queryReference = measureFunc(`relay_resource_load_query:${type}`, () =>
				loadQuery(getRelayEnvironment(), query, variables || {}, options),
			);

			if (__SERVER__ && queryReference.id != null) {
				await QueryPromisesMap.get(queryReference.id);
			}

			if (queryReference.id != null) {
				QueryPromisesMap.get(queryReference.id)?.then(() => {
					setMark(endMark);
					setMeasure(measureName, startMark, endMark);
				});
			}

			/**
			 * We want to store HTTP codes from fetch response so that we can use it to report SLA correctly.
			 * (Unfortunately errors thrown by relay client validation doesn't include HTTP codes at the moment, so we have to store it ourselves)
			 * Starting to capture errors here ensures we capture errors for the early fetch of the query on initial page load,
			 * as well as subsequent fetches made by route transitions between spa-apps.
			 */
			if (captureErrors) {
				startCaptureGraphQlErrors(queryReference.fetchKey.toString());
			}

			if (captureTraceIds) {
				startCapturingTraceIds(queryReference.name);
			}

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return queryReference as PreloadedQuery<TQuery>;
		},
		maxAge: 0,
		isBrowserOnly,
	});

// A public version of the internal `Config` type but with a mandatory `parameters`
type SyncConfig<TQuery extends OperationType> = {
	parameters: GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>;
	variables?: VariablesOf<TQuery>;
	options?: LoadQueryOptions;
};
// A public version of the internal `CreateRelayResourceConfig` type but with no `loadParameters`, since `SyncConfig` has a mandatory `parameters`
type CreateRelayResourceConfigSync<TQuery extends OperationType> = {
	type: string;
	isBrowserOnly?: boolean;
	getQuery: (
		arg1: RouterContext | RouterDataContext,
		arg2: ResourceStoreContext,
	) => SyncConfig<TQuery>;
	captureErrors?: boolean;
	captureTraceIds?: boolean;
};

/**
 * @deprecated Use Relay EntryPoint instead https://hello.atlassian.net/wiki/spaces/UAF/pages/2754170095
 *
 * Creates a Relay powered route resource. For use when the graphql query is in the same chunk, otherwise use {@link createAsyncRelayResource}
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const createRelayResourceDeprecated = createRelayResourceImpl as <
	TQuery extends OperationType,
>(
	param: CreateRelayResourceConfigSync<TQuery>,
) => RouteResource<PreloadedQuery<TQuery>>;
