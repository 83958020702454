import { resources as alertListResources } from '@atlassian/eoc-alert-list/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { eocChangeboardingLayout } from '@atlassian/jira-eoc-changeboarding-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	AsyncSidebarEocGlobalAlerts,
	LazyGlobalAlertsSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-global-alerts/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocGlobalAlertListRoute } from '@atlassian/jira-router-routes-eoc-global-alerts-routes/src/eocGlobalAlertListRoute.tsx';
import { AlertListComponent, LazyAlertList } from './ui/AlertListComponent.tsx';

export const eocGlobalAlertListRouteEntry = createEntry(eocGlobalAlertListRoute, () => ({
	group: 'eoc',
	component: AlertListComponent,
	layout: eocChangeboardingLayout,
	resources: [
		...getNavigationResources(),
		...alertListResources(),
		getConsolidationStateResource(),
	],
	navigation: {
		menuId: MENU_ID.HOME,
		sidebar: AsyncSidebarEocGlobalAlerts,
	},
	forPaint: [LazyAtlassianNavigation, LazyAlertList, LazyGlobalAlertsSidebar],
}));
