import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { planPageRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/planPageRoute.tsx';
import PlanTimelinePage, { LazyPlanTimeline } from './ui/plan-timeline/index-old.tsx';
import { withRedirection } from './ui/utils.tsx';

// This redirect is required to support legacy routes, it should never be deleted
const planPageRedirect = withRedirection(PlanTimelinePage, {
	to: '/timeline',
	redirectCondition: () => true,
});

export const planPageRouteEntry = createEntry(planPageRoute, () => ({
	group: 'software',
	ufoName: 'software-advanced-roadmaps-scenario',
	component: planPageRedirect,
	layout: globalLayout,

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	navigation: {
		menuId: MENU_ID.PLANS,
	},

	forPaint: [LazyAtlassianNavigation, LazyPlanTimeline],
}));
