/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { forwardRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { cssMap, jsx, cx } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import { Box } from '@atlaskit/primitives';
import Tooltip, { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { type MessageDescriptor, useIntl } from '@atlassian/jira-intl';
import type { tooltip_adminPagesPerformance_CustomFieldsTooltip$key } from '@atlassian/jira-relay/src/__generated__/tooltip_adminPagesPerformance_CustomFieldsTooltip.graphql';
import type { MetricState } from '../../../../../common/types.tsx';
import messages from '../../../../../common/assets/custom-fields/messages.tsx';

const CustomTooltip = forwardRef<HTMLDivElement, TooltipPrimitiveProps>(
	({ children, ...rest }, ref) => {
		return (
			<TooltipPrimitive
				{...rest}
				// "css" does not "exist" - it gets transformed into "className" by compiled
				css={styles.tooltipStyles}
				ref={ref}
			>
				{children}
			</TooltipPrimitive>
		);
	},
);

export type CustomFieldsTooltipProps = {
	children: React.ReactElement;
	dataRef: tooltip_adminPagesPerformance_CustomFieldsTooltip$key;
	metricState: MetricState;
};

export const CustomFieldsTooltip = ({
	children,
	dataRef,
	metricState,
}: CustomFieldsTooltipProps) => {
	const { resourceUsageMetricV2: metricUsageData } =
		useFragment<tooltip_adminPagesPerformance_CustomFieldsTooltip$key>(
			graphql`
				fragment tooltip_adminPagesPerformance_CustomFieldsTooltip on JiraQuery
				@argumentDefinitions(
					cloudId: { type: "ID!" }
					metricKey: { type: "String!" }
					tooltipEnabled: { type: "Boolean!" }
				) {
					resourceUsageMetricV2(cloudId: $cloudId, metricKey: $metricKey)
						@include(if: $tooltipEnabled) {
						... on JiraCustomFieldUsageMetric {
							globalScopedCustomFieldsCount
							projectScopedCustomFieldsCount
						}
					}
				}
			`,
			dataRef,
		);

	if (metricUsageData === null || metricUsageData === undefined) {
		return null;
	}

	const customFieldsCount = {
		globalScopedCustomFieldsCount: metricUsageData.globalScopedCustomFieldsCount,
		projectScopedCustomFieldsCount: metricUsageData.projectScopedCustomFieldsCount,
	};

	const customFieldsCountLabels = {
		globalScopedCustomFieldsCountLabel: messages.globalScopedCustomFieldsCountLabel,
		projectScopedCustomFieldsCountLabel: messages.projectScopedCustomFieldsCountLabel,
		globalScopedCustomFieldsCountAriaLabel: messages.globalScopedCustomFieldsCountAriaLabel,
		projectScopedCustomFieldsCountArialLabel: messages.projectScopedCustomFieldsCountAriaLabel,
	};

	return (
		<Tooltip
			content={
				<CustomFieldsTooltipContent
					customFieldsCount={customFieldsCount}
					customFieldsCountLabels={customFieldsCountLabels}
					metricState={metricState}
				/>
			}
			component={CustomTooltip}
		>
			{children}
		</Tooltip>
	);
};

type CustomFieldsCount = {
	globalScopedCustomFieldsCount: number | null | undefined;
	projectScopedCustomFieldsCount: number | null | undefined;
};

type CustomFieldsCountLabels = {
	globalScopedCustomFieldsCountLabel?: MessageDescriptor;
	projectScopedCustomFieldsCountLabel?: MessageDescriptor;
	globalScopedCustomFieldsCountAriaLabel?: MessageDescriptor;
	projectScopedCustomFieldsCountArialLabel?: MessageDescriptor;
};

type CustomFieldsTooltipContentProps = {
	customFieldsCount: CustomFieldsCount;
	customFieldsCountLabels: CustomFieldsCountLabels;
	metricState: MetricState;
};

const CustomFieldsTooltipContent = ({
	customFieldsCount,
	customFieldsCountLabels,
	metricState,
}: CustomFieldsTooltipContentProps) => {
	const { formatMessage, formatNumber } = useIntl();

	const { globalScopedCustomFieldsCount, projectScopedCustomFieldsCount } = customFieldsCount;
	const {
		globalScopedCustomFieldsCountLabel,
		projectScopedCustomFieldsCountLabel,
		globalScopedCustomFieldsCountAriaLabel,
		projectScopedCustomFieldsCountArialLabel,
	} = customFieldsCountLabels;

	return (
		<Box xcss={styles.numbersContainer}>
			{globalScopedCustomFieldsCount != null && globalScopedCustomFieldsCountLabel && (
				<Box
					xcss={styles.numberContainer}
					tabIndex={0}
					aria-label={
						globalScopedCustomFieldsCountAriaLabel
							? formatMessage(globalScopedCustomFieldsCountAriaLabel, {
									number: formatNumber(globalScopedCustomFieldsCount),
								})
							: undefined
					}
				>
					<Box
						xcss={cx(
							metricState === 'none' && styles.successNumber,
							metricState === 'warning' && styles.warningNumber,
							metricState === 'exceeded' && styles.errorNumber,
							styles.fontWeightStyles,
						)}
						aria-describedby="global-scoped-custom-fields-count-desc"
					>
						{formatNumber(globalScopedCustomFieldsCount)}
					</Box>
					<Box xcss={styles.textContainer}>
						<small id="global-scoped-custom-fields-count-desc">
							{formatMessage(globalScopedCustomFieldsCountLabel)}
						</small>
					</Box>
				</Box>
			)}
			{projectScopedCustomFieldsCount != null && projectScopedCustomFieldsCountLabel && (
				<Box
					xcss={styles.numberContainer}
					tabIndex={0}
					aria-label={
						projectScopedCustomFieldsCountArialLabel
							? formatMessage(projectScopedCustomFieldsCountArialLabel, {
									number: formatNumber(projectScopedCustomFieldsCount),
								})
							: undefined
					}
				>
					<Box
						xcss={cx(
							metricState === 'none' && styles.successNumber,
							metricState === 'warning' && styles.warningNumber,
							metricState === 'exceeded' && styles.errorNumber,
							styles.fontWeightStyles,
						)}
						aria-describedby="project-scoped-custom-fields-count-desc"
					>
						{formatNumber(projectScopedCustomFieldsCount)}
					</Box>
					<Box xcss={styles.textContainer}>
						<small id="project-scoped-custom-fields-count-desc">
							{formatMessage(projectScopedCustomFieldsCountLabel)}
						</small>
					</Box>
				</Box>
			)}
		</Box>
	);
};

const styles = cssMap({
	numbersContainer: {
		display: 'flex',
		textAlign: 'center',
		width: '300px',
		justifyContent: 'space-between',
		paddingTop: token('space.025'),
		paddingRight: token('space.025'),
		paddingBottom: token('space.025'),
		paddingLeft: token('space.025'),
	},
	numberContainer: {
		flex: '0 0 auto',
	},
	successNumber: {
		font: token('font.heading.large'),
		color: token('color.text.selected'),
	},
	warningNumber: {
		font: token('font.heading.large'),
		color: token('color.text.accent.orange'),
	},
	errorNumber: {
		font: token('font.heading.large'),
		color: token('color.text.accent.red'),
	},
	fontWeightStyles: {
		fontWeight: token('font.weight.semibold'),
	},
	textContainer: {
		maxWidth: '160px',
	},
	tooltipStyles: {
		backgroundColor: token('color.background.input'),
		color: token('color.text.inverse'),
		paddingTop: token('space.100'),
		paddingRight: token('space.100'),
		paddingBottom: token('space.100'),
		paddingLeft: token('space.100'),
		borderRadius: token('border.radius.100'),
		boxShadow: token('elevation.shadow.raised'),
	},
});
