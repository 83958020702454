/**
 * @generated SignedSource<<237b885497b658573d984371ca06695a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ConfigurableMenuItem$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Apps" | "Nav4Filters" | "Nav4Operations" | "Nav4Overviews" | "Nav4Plans" | "Nav4Projects">;
  readonly " $fragmentType": "ConfigurableMenuItem";
};
export type ConfigurableMenuItem$key = {
  readonly " $data"?: ConfigurableMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfigurableMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ConfigurableMenuItem",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4Projects"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Filters"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Plans"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Apps"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Operations"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Overviews"
    }
  ],
  "type": "Query"
};

(node as any).hash = "5c4da86f47569a65309ccf227624f58f";

export default node;
