import { fg } from '@atlassian/jira-feature-gating';
import { setInitialPageLoadTimingFromPerformanceMarks } from '../performance-marks-tools/index.tsx';
import { observePreloadedBundlesProgress } from '../preloaded-bundles/preloaded-bundles.tsx';

/**
 * Captures and sets the timings for key stages during the initial page load using performance marks.
 * This function is designed for client-side execution and aids in performance analysis by tracking stages such as
 * early flush, CSS loading, server-side rendering, and more.
 */
export const addInitialPageLoadTimings = () => {
	if (__SERVER__) {
		return;
	}
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush',
		'ssr.spa.early-common-flush:start',
		'ssr.spa.early-route-flush:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/metatags',
		'ssr.spa.metatags:start',
		'ssr.spa.metatags:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/statsig',
		'ssr.spa.statsig:start', // these are the marks used when parsing happens inline
		'ssr.spa.statsig:end',
	);
	if (fg('jira-ssr-perf-portal-cleanup')) {
		setInitialPageLoadTimingFromPerformanceMarks(
			'early-flush/statsig',
			'ssr.spa.statsig-gate-values:start', // modern SSR uses these marks, where parsing happens lazily
			'ssr.spa.statsig-gate-values:end',
		);
		setInitialPageLoadTimingFromPerformanceMarks(
			'early-flush/statsig-parse',
			'statsig-parse:start',
			'statsig-parse:end',
		);
		// In legacy SSR, parsing happens inline with processing the flush. In modern SSR or static fallback scenarios for legacy SSR, the parse will be timed seperately.
		// What we want to avoid is confusing people with a "parse timer" in legacy SSR breakdown charts that doesn't overlap with the "statsig" timer (it would have a very low volume, but it would distract people during perf-investigations)
		// This will make the statsig-parse timer fully overlap with "statsig" when the parsing happens inline, and will start at a different time otherwise
		setInitialPageLoadTimingFromPerformanceMarks(
			'early-flush/statsig-parse',
			'ssr.spa.statsig:start',
			'ssr.spa.statsig:start',
		);
		setInitialPageLoadTimingFromPerformanceMarks(
			'early-flush/inline-scripts-common',
			'ssr.spa.inline-assets:start',
			'ssr.spa.inline-assets:end',
		);
		setInitialPageLoadTimingFromPerformanceMarks(
			'early-flush/inline-scripts-route',
			'ssr.spa.async-inline-assets:start',
			'ssr.spa.async-inline-assets:end',
		);
		setInitialPageLoadTimingFromPerformanceMarks(
			'early-flush/css-assets',
			'ssr.spa.css-assets:start',
			'ssr.spa.css-assets:end',
		);
	} else {
		setInitialPageLoadTimingFromPerformanceMarks(
			'early-flush/inline-scripts',
			'ssr.spa.feature-flags:end',
			'ssr.spa.early-common-flush:end',
		);
		setInitialPageLoadTimingFromPerformanceMarks(
			'css-assets',
			'ssr.spa.css-assets:start',
			'ssr.spa.css-assets:end',
		);
	}
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/feature-flags',
		'ssr.spa.feature-flags:start',
		'ssr.spa.feature-flags:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'early-flush/route-preload',
		'ssr.spa.early-route-flush:start',
		'ssr.spa.early-route-flush:end',
	);
	setInitialPageLoadTimingFromPerformanceMarks('ssr-output', 'ssr.spa.start', 'ssr.spa.state');
	setInitialPageLoadTimingFromPerformanceMarks(
		'ssr-output/styles',
		'ssr.spa.start',
		'ssr.spa.styles',
	);
	setInitialPageLoadTimingFromPerformanceMarks('ssr-output/html', 'ssr.spa.styles', 'ssr.spa.html');
	setInitialPageLoadTimingFromPerformanceMarks('ssr-output/state', 'ssr.spa.html', 'ssr.spa.state');
	setInitialPageLoadTimingFromPerformanceMarks(
		'jira-spa/language-pack',
		'jira-spa/language-pack.start',
		'jira-spa/language-pack.end',
		true,
		true,
	);
	setInitialPageLoadTimingFromPerformanceMarks(
		'batch-js-heritage.eval',
		'jira.heritage_batch_file_eval:start',
		'jira.heritage_batch_file_eval:end',
	);

	fg('add-loading-bundles-span-initial-load') && observePreloadedBundlesProgress();
};
