import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { getIsNav4UserOptOut } from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';

// We don't inline feature gates as our logic to show nav4 is not trivial and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design#Decision-points
export const getWillShowNav4ForBeta = () =>
	// eslint-disable-next-line jira/ff/no-preconditioning
	(fg('jira_nav4_beta_existing-customers') && !getIsNav4UserOptOut()) ||
	fg('jira_nav4_beta_sandboxes') ||
	expVal('jira_nav4_beta_free-customers', 'willShowNav4', false) ||
	expVal('jira_nav4_beta_new-customers', 'willShowNav4', false) ||
	expVal('confluence_jira_nav4_new_customers', 'willShowNav4', false);
