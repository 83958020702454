import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	generalConfiguration: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.operations-expandable-menu-item.action-buttons.general-configuration',
		defaultMessage: 'General configuration',
		description:
			'Used as the alt text for an icon button that when used navigates to create project',
	},
	popOutLabel: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.operations-expandable-menu-item.action-buttons.pop-out-label',
		defaultMessage: 'Pop out operations alert searches',
		description:
			'Tooltip message for the button to pop-out the operations alert searches menu from the sidebar into the main pages content.',
	},
	popOutTooltip: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.operations-expandable-menu-item.action-buttons.pop-out-tooltip',
		defaultMessage: 'Pop out from sidebar',
		description:
			'Tooltip message for the button to pop-out the operations alert searches menu from the sidebar into the main pages content.',
	},
	popInLabel: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.operations-expandable-menu-item.action-buttons.pop-in-label',
		defaultMessage: 'Pop in operations alert searches',
		description:
			'Accessibility label for the button to pop-in the operations alert searches menu from the main pages content into the sidebar.',
	},
	popInTooltip: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.operations-expandable-menu-item.action-buttons.pop-in-tooltip',
		defaultMessage: 'Move back to sidebar',
		description:
			'Tooltip message for the button to pop-in the operations alert searches menu from the main pages content into the sidebar.',
	},
});
