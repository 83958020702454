import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { softwareFormSubmitPublicRoute } from '@atlassian/jira-router-routes-software-form-submit-public-routes/src/softwareFormSubmitPublicRoute.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { formSubmitPageEntrypoint } from '@atlassian/jira-router-routes-business-entries/src/ui/spa/business/form-submit-public/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';

export const softwareFormSubmitPublicRouteEntry = createEntry(
	softwareFormSubmitPublicRoute,
	() => ({
		group: 'software',
		ufoName: 'next-gen-form-submit-public',
		layout: chromelessLayout,
		resources: [themePreferenceResource],
		entryPoint: () => {
			return expVal('jira_forms_public_access_experiment', 'isEnabled', 'false')
				? formSubmitPageEntrypoint
				: notFoundPageEntrypoint;
		},
		// This public route should not include
		// DEPRECATED_DO_NOT_USE_projectDetailsResource as a resource, unless we
		// remove all usages of projectKey.
	}),
);
