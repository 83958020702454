import type { Match, Query } from '@atlassian/react-resource-router';

export const getProjectId = (query: Query) =>
	query?.selectedProjectId || query?.pid || query?.projectId || query?.['project.id'];

export const getProjectKey = (match: Match, query: Query) =>
	match?.params.projectKey ||
	query?.projectKey ||
	query?.['project.key'] ||
	(match.params?.issueKey || '').split('-')[0];

export const getProjectIdOrKey = (match: Match, query: Query) =>
	getProjectId(query) || getProjectKey(match, query);

/**
 * Determines the key and ID for a project based on URL parameters or query strings.
 * It handles the extraction of project keys and IDs from multiple potential sources
 * in the URL query parameters, providing a unified object containing these details.
 */
export const getProjectKeyId = (match: Match, query: Query) => {
	// handle reports legacy url or project settings details
	const projectId = getProjectId(query);
	const projectKey = getProjectKey(match, query);
	return {
		projectKey,
		projectId,
	};
};
