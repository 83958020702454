import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const standardToPremiumModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "jira-edition-awareness-standard-to-premium-modal-entrypoint" */ './index.tsx'
			),
	),
	getPreloadProps: () => ({}),
});
