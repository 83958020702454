import type { InvariantJiraRoute } from '@atlassian/jira-route-types/src/InvariantJiraRoute.tsx';

/**
 * Reports Overview page route for CMP projects that do not have a board.
 */
export const softwareReportsOverviewBoardlessRoute: InvariantJiraRoute = {
	name: 'software-reports-boardless',
	path: '/jira/software/c/projects/:projectKey/reports',
	exact: true,
};
