import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskProformaJiraFormBuilderRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskProformaJiraFormBuilderRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import ProformaJiraFormBuilder, {
	LazyProjectSettingsProformaJiraFormBuilder,
} from './ui/proforma-jira-form-builder/index.tsx';

export const projectSettingsServicedeskProformaJiraFormBuilderRouteEntry = createEntry(
	projectSettingsServicedeskProformaJiraFormBuilderRoute,
	() => ({
		group: 'project-settings-servicedesk',
		component: ProformaJiraFormBuilder,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaJiraFormBuilder],
		perfMetricKey: 'form-builder',
	}),
);
