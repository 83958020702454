import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsPerformanceCustomFieldRoute } from '@atlassian/jira-router-routes-admin-pages-performance-routes/src/globalSettingsPerformanceCustomFieldRoute.tsx';
import {
	CustomFieldMetricDetail,
	LazyCustomFieldDetail,
} from './ui/spa/admin-pages/performance/metric-detail/custom-field/index.tsx';

export const globalSettingsPerformanceCustomFieldRouteEntry = createEntry(
	globalSettingsPerformanceCustomFieldRoute,
	() => ({
		group: 'global-settings',
		component: CustomFieldMetricDetail,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyCustomFieldDetail],
	}),
);
