/**
 * @generated SignedSource<<5a71e1d1b1dbf7cb82e4e714dfd62b93>>
 * @relayHash e13196a4072c1e05f1d5cb92fe604cee
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d94a9943ce2554eb8cf7db62a3d014b0ef4ffb159df3aec135c71cb712c22610

import type { ConcreteRequest, Query } from 'relay-runtime';
export type helpCenterSettingsRedirectQuery$variables = {
  cloudId: string;
  jiraProjectAri: string;
  jiraWorkspaceAri: string;
};
export type helpCenterSettingsRedirectQuery$data = {
  readonly helpCenter: {
    readonly helpCenterByHoistedProjectIdRouted: {
      readonly __typename: "HelpCenter";
      readonly id: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type helpCenterSettingsRedirectQuery = {
  response: helpCenterSettingsRedirectQuery$data;
  variables: helpCenterSettingsRedirectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jiraProjectAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jiraWorkspaceAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "projectAri",
    "variableName": "jiraProjectAri"
  },
  {
    "kind": "Variable",
    "name": "workspaceAri",
    "variableName": "jiraWorkspaceAri"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = [
  {
    "kind": "ScalarField",
    "name": "id"
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "HelpCenter"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "helpCenterSettingsRedirectQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "concreteType": "HelpCenterQueryApi",
          "kind": "LinkedField",
          "name": "helpCenter",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "helpCenterByHoistedProjectIdRouted",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/)
                ]
              },
              "action": "THROW",
              "path": "helpCenter.helpCenterByHoistedProjectIdRouted"
            }
          ]
        },
        "action": "THROW",
        "path": "helpCenter"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "helpCenterSettingsRedirectQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterByHoistedProjectIdRouted",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v4/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d94a9943ce2554eb8cf7db62a3d014b0ef4ffb159df3aec135c71cb712c22610",
    "metadata": {},
    "name": "helpCenterSettingsRedirectQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "87969f8d9448b730bb329d59f60087f6";

export default node;
