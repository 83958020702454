import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsPerformanceProjectCleanupRoute } from '@atlassian/jira-router-routes-admin-pages-performance-routes/src/globalSettingsPerformanceProjectCleanupRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	ProjectCleanupMetricDetail,
	LazyProjectCleanupDetail,
} from './ui/spa/admin-pages/performance/metric-detail/project-cleanup/index.tsx';

export const globalSettingsPerformanceProjectCleanupRouteEntry = createEntry(
	globalSettingsPerformanceProjectCleanupRoute,
	() => ({
		group: 'global-settings',
		// On FF cleanup of site-optimizer-project-cleanup replace with ProjectCleanupMetricDetail
		component: componentWithFG(
			'site-optimizer-project-cleanup',
			ProjectCleanupMetricDetail,
			ErrorPagesNotFound,
		),
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyProjectCleanupDetail],
	}),
);
