import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';
import { RecentMenu } from './ui/index.tsx';

export const Recent = () => (
	<UFOSegment name="nav4-sidebar-recent">
		<JSErrorBoundary fallback="flag" id="recent" packageName={PACKAGE_NAME} teamName={TEAM_NAME}>
			<RecentMenu />
		</JSErrorBoundary>
	</UFOSegment>
);
