import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { LinkItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useAtlassianPersonalSettingsLink } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list/index.tsx';
import messages from './messages.tsx';

export type Props = {
	email: string;
	userFullname: string;
	avatarSrc?: string;
	testId?: string;
};

export const AccountDetails = ({ email, userFullname, avatarSrc, testId }: Props) => {
	const atlassianSettingsLink = useAtlassianPersonalSettingsLink(email);
	const { formatMessage } = useIntl();

	return (
		<>
			<NonInteractiveLinkItem>
				<ProfileWrapper>
					<AvatarWrapper>
						<Avatar appearance="circle" size="medium" src={avatarSrc} />
					</AvatarWrapper>
					<DetailsWrapper>
						<DisplayName>{userFullname}</DisplayName>
						<small>{email}</small>
					</DetailsWrapper>
				</ProfileWrapper>
			</NonInteractiveLinkItem>
			<MenuList>
				<MenuListItem>
					<MenuItem
						analytics={{
							actionSubjectId: 'profileNavigationMenuItem',
							menuItemId: 'manage-account',
							menuItemType: 'account',
							position: 1,
						}}
						href={atlassianSettingsLink}
						testId={testIdConcat(testId, 'manage-account')}
						target="_blank"
						iconAfter={
							<ShortcutIcon LEGACY_size="small" color={token('color.icon.subtle')} label="" />
						}
					>
						{formatMessage(messages.manageAccount)}
					</MenuItem>
				</MenuListItem>
			</MenuList>
		</>
	);
};

// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NonInteractiveLinkItem = styled(LinkItem)({
	pointerEvents: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DisplayName = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DetailsWrapper = styled.div({
	flexDirection: "'row'",
});
