import {
	FIRST_MODAL_MESSAGE_ID,
	SECOND_MODAL_MESSAGE_ID,
	THIRD_MODAL_MESSAGE_ID,
} from '../../common/constants.tsx';

export const getMessageId = (daysLeft: number) => {
	if (daysLeft === 20 || daysLeft === 19) {
		return FIRST_MODAL_MESSAGE_ID;
	}
	if (daysLeft === 18 || daysLeft === 17) {
		return SECOND_MODAL_MESSAGE_ID;
	}
	if (daysLeft <= 16) {
		return THIRD_MODAL_MESSAGE_ID;
	}
};
