// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../model/types.tsx';

// We use this action in cases where we want to hide the nudge no matter what.
const forceHideNudge =
	(): Action<State> =>
	({ setState }) => {
		setState({ hasNudgeActive: false });
	};

export { forceHideNudge };
