import React from 'react';
import noop from 'lodash/noop';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/core/migration/add';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from '../../messages.tsx';
import { LoadingStateAnimationWrapper } from '../loading-state/styled.tsx';
import {
	Container as LabelContainer,
	StyledBadge,
	StyledLabel,
} from '../picker-trigger/trigger-component-no-operator/main.tsx';

const AddFilterFallback = ({ optionalFieldsCount }: { optionalFieldsCount: number }) => {
	const { formatMessage } = useIntl();

	const moreLabel = isVisualRefreshEnabled()
		? formatMessage(messages.moreButton)
		: formatMessage(messages.moreButtonOld);
	/**
	 * We only show `More` button with loading state when there's more than 1
	 * optional field with selected values underneath.
	 * Otherwise we display the default `More` button
	 */

	if (optionalFieldsCount > 0) {
		return (
			<LoadingStateAnimationWrapper>
				<Button
					appearance="default"
					isSelected
					onClick={noop}
					label="addButton"
					testId="jql-builder-basic.common.ui.add-filter-fallback.loading"
				>
					<LabelContainer>
						<StyledLabel>{moreLabel}</StyledLabel>
						<StyledBadge>
							<Badge appearance="primary">{`+${optionalFieldsCount}`}</Badge>
						</StyledBadge>
					</LabelContainer>
				</Button>
			</LoadingStateAnimationWrapper>
		);
	}

	return (
		<Button
			appearance="default"
			iconAfter={
				isVisualRefreshEnabled() ? (
					<ChevronDownIcon label="" color="currentColor" />
				) : (
					<AddIcon label="addIcon" LEGACY_size="small" />
				)
			}
			isSelected={false}
			onClick={noop}
			label="addButton"
			testId="jql-builder-basic.common.ui.add-filter-fallback.loading"
		>
			{moreLabel}
		</Button>
	);
};

export default AddFilterFallback;
