import type { RouteResource } from 'react-resource-router';
import { conversationsResource, messagesResource } from './resources';

// packages/ai-mate/csm-conversation-review/src/ui/index.tsx
const conversationRouteNameAndPathFields = {
	name: 'conversationReview',
	path: '/:conversationId?',
} as const;

export type AvailableRoutes = [typeof conversationRouteNameAndPathFields];

export const resources: Record<AvailableRoutes[number]['name'], RouteResource<any>[]> = {
	conversationReview: [conversationsResource, messagesResource],
};
