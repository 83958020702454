/**
 * @generated SignedSource<<6793555e2dbfaf8db2fe4c7edd9d81f4>>
 * @relayHash 9a58486151638f4e45660f746394b248
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 187971dfb7440b89eb5a98f67e766ec91b921dc8dd450612125a7211123e347d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { DashboardsContentViewQuery } from './DashboardsContentViewQuery.graphql';

const node: PreloadableConcreteRequest<DashboardsContentViewQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "187971dfb7440b89eb5a98f67e766ec91b921dc8dd450612125a7211123e347d",
    "metadata": {},
    "name": "DashboardsContentViewQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
