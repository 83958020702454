import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

import GlobalShortcutsComponent from '@atlassian/global-shortcuts';
import { TopLevelSpacer } from '@atlassian/navigation-system';
import { confluence } from '@atlassian/global-shortcuts/confluence';
import { loom } from '@atlassian/global-shortcuts/loom';
import { goals } from '@atlassian/global-shortcuts/goals';
import { teams } from '@atlassian/global-shortcuts/teams';

import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import messages from './messages.tsx';

export function GlobalShortcuts() {
	const intl = useIntl();
	const cloudId = useCloudId();
	const { data: orgId } = useOrgId();

	return (
		<>
			<TopLevelSpacer />
			<GlobalShortcutsComponent
				cloudId={cloudId}
				orgId={orgId}
				useSkeleton
				shortcutItems={[
					confluence(intl.formatMessage(messages.confluenceShortcutText), () => ''),
					loom(intl.formatMessage(messages.loomShortcutText), () => ''),
					goals(intl.formatMessage(messages.goalsShortcutText), () => ''),
					teams(intl.formatMessage(messages.teamsShortcutText), () => ''),
				]}
			/>
		</>
	);
}
