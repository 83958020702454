import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsIssueTypesScreenSchemesRoute } from '@atlassian/jira-router-routes-admin-pages-screens-routes/src/globalSettingsIssueTypesScreenSchemesRoute.tsx';
import { conditionalPostOfficeJiraTopBannerResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';
import IssueTypeScreenSchemesPage, {
	LazyIssueTypeScreenSchemesPage,
} from './ui/spa/admin-pages/screens/issue-type-screen-schemes-page/index.tsx';

export const globalSettingsIssueTypesScreenSchemesRouteEntry = createEntry(
	globalSettingsIssueTypesScreenSchemesRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'global-admin.issues.issue-type-screen-schemes',
		component: IssueTypeScreenSchemesPage,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			conditionalPostOfficeJiraTopBannerResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyIssueTypeScreenSchemesPage],
	}),
);
