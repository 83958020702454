import React, {
	type ReactNode,
	useCallback,
	useEffect,
	useRef,
	type PropsWithChildren,
} from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import {
	SpotlightTarget as AkSpotlightTarget,
	// @ts-expect-error - TS2305 - Module '"@atlaskit/onboarding"' has no exported member 'SpotlightTargetProps'.
	type SpotlightTargetProps,
} from '@atlaskit/onboarding';
import type { TriggerProps } from '@atlaskit/popup';
import { ARTICLE_TYPE } from '@atlassian/help-widget';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import {
	HELP_PANEL_ID,
	HELP_PANEL_WIDTH,
	HELP_PANEL_VERSION,
} from '@atlassian/jira-help-panel-common-constant/src/index.tsx';
import { useHelpPanelActions } from '@atlassian/jira-help-panel-services/src/components/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useRightSidebarController,
	useActiveRightSidebarState as useRightSidebarState,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import {
	testIdConcat,
	testIdGenerate,
} from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { QuickstartNudge } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/quickstart-nudge/main.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { JWM_FEEDBACK_TASK } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import {
	useIsDismissed,
	useOpenTask,
	useQuickstartActions,
} from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { FeedbackNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-feedback/async.tsx';
import { useHasOpened } from '@atlassian/jira-software-onboarding-quickstart-has-opened/src/services/has-opened/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { NAVIGATION_ITEM_ID, HELP_SPOTLIGHT_KEY } from '../../common/constants.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import { Help as Trigger } from '../../common/ui/help-button/index.tsx';
import { useHelpButtonState } from '../../controllers/help-button/index.tsx';
import { useNavigationItemAnalytics } from '../../controllers/navigation-item-analytics/main.tsx';
import { Badge as SyncBadge } from './badge/main.tsx';
import type { BadgeProps } from './badge/types.tsx';
import messages from './messages.tsx';

const Badge = (props: BadgeProps) => {
	return <SyncBadge {...props} />;
};

const SpotlightTargetComponent = componentWithCondition(
	() => getWillShowNav4() && fg('blu-5788-spotlight-help-button'),
	JWMFeedbackSpotlightWrapper,
	SpotlightTargetComponentOriginal,
);

function SpotlightTargetComponentOriginal({ children }: { children: ReactNode }) {
	return __SERVER__ ? (
		<>{children}</>
	) : (
		<>
			<SpotlightTarget name={HELP_SPOTLIGHT_KEY}>{children}</SpotlightTarget>
			{fg('add_engagement_spotlight_to_navbar_button') && (
				<EngagementSpotlight name={HELP_SPOTLIGHT_KEY} />
			)}
		</>
	);
}

function SpotlightTarget({ children, ...props }: SpotlightTargetProps) {
	return (
		<AkSpotlightTarget {...props}>
			{/*
			 * The atlaskit spotlight target component merely registers a ref on the child component,
			 *  so there is no way to give it a test id. We want to keep the test id as closely coupled
			 *  with the target as possible, so we add a span with id here, rather than on the child
			 *  where it could become more easily uncoupled.
			 */}
			<div data-test-id={`ak-spotlight-target-${props.name}`}>{children}</div>
		</AkSpotlightTarget>
	);
}

function JWMFeedbackSpotlightWrapper({ children }: PropsWithChildren) {
	const renderNudgeWrapper = useCallback(
		(kids: ReactNode) => <FeedbackNudgeAsync>{kids}</FeedbackNudgeAsync>,
		[],
	);
	const [{ hasOpened: shouldShowNudge }] = useHasOpened();
	const [isUnloaded] = useIsDismissed();
	const [openTask] = useOpenTask();
	const [, { setHasNudgeActive }] = useQuickstartActions();

	useEffect(() => {
		if (!isUnloaded && openTask === JWM_FEEDBACK_TASK && shouldShowNudge) {
			setHasNudgeActive(true);
		}
	}, [isUnloaded, openTask, shouldShowNudge, setHasNudgeActive]);

	return !__SERVER__ && shouldShowNudge ? (
		<QuickstartNudge renderWrapper={renderNudgeWrapper}>{children}</QuickstartNudge>
	) : (
		<SpotlightTargetComponentOriginal>{children}</SpotlightTargetComponentOriginal>
	);
}

function Help() {
	const [{ data: projectData }] = useContainerContext();
	const { formatMessage } = useIntl();
	const [isMenuOpen, { off: closeMenu, on: openMenu }] = useTopMenus(MENU_ID.HELP);
	const isAnonymous = useIsAnonymous();
	const [topMenusValue, { off: closeAllMenus }] = useTopMenus();
	const [, { setNavigationData }] = useHelpPanelActions();
	const { releaseNotesNotifications, setReleaseNotesNotifications } = useHelpButtonState();

	const helpButtonRef = useRef<HTMLButtonElement>(null);
	let isProjectSimplified = false;
	if (projectData && 'project' in projectData && projectData.project) {
		isProjectSimplified = projectData.project.isSimplified;
	}

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const attributes: {
		helpPanelVersion: string;
		helpPanelType: string;
		nextGenProject: boolean;
	} = {
		helpPanelVersion: HELP_PANEL_VERSION,
		helpPanelType: 'inProductHelp',
		nextGenProject: isProjectSimplified,
	};

	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.HELP,
	});

	const { openRightSidebar, closeCurrentRightSidebar } = useRightSidebarController();
	const currentRightSidebarState = useRightSidebarState();
	const isHelpPanelOpenedPreviously = usePrevious(currentRightSidebarState);
	const testIdPrefix = testIdGenerate('secondary-actions', 'help');

	const onClick = useCallback(() => {
		if (
			currentRightSidebarState &&
			currentRightSidebarState.panelId === HELP_PANEL_ID &&
			!currentRightSidebarState.isCollapsed
		) {
			closeCurrentRightSidebar();
		} else {
			openRightSidebar(HELP_PANEL_ID, HELP_PANEL_WIDTH, false, true);
		}

		closeAllMenus();
		setNavigationData({ articleId: { id: '', type: ARTICLE_TYPE.HELP_ARTICLE }, history: [] });

		// Shows if the help panel is currently open or closed
		const isPanelOpen =
			currentRightSidebarState?.panelId === HELP_PANEL_ID && !currentRightSidebarState?.isCollapsed;

		triggerAnalytics({
			...attributes,
			isOpened: !isPanelOpen,
		});
	}, [
		triggerAnalytics,
		attributes,
		currentRightSidebarState,
		closeAllMenus,
		setNavigationData,
		closeCurrentRightSidebar,
		openRightSidebar,
	]);

	const badge = useCallback(() => {
		if (isAnonymous) {
			return null;
		}
		return <Badge isNotificationCleared={releaseNotesNotifications === 0} />;
	}, [isAnonymous, releaseNotesNotifications]);

	const TriggerComponent = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			<div
				data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}
				data-vc={`atlassian-navigation-help${__SERVER__ ? '-ssr' : ''}`}
			>
				<SpotlightTargetComponent>
					<Trigger
						{...triggerProps}
						isSelected={isMenuOpen}
						badge={badge}
						onClick={onClick}
						tooltip={formatMessage(messages.tooltip)}
						label={formatMessage(messages.label)}
						ref={helpButtonRef}
					/>
				</SpotlightTargetComponent>
			</div>
		),
		[testIdPrefix, isMenuOpen, badge, onClick, formatMessage],
	);

	useEffect(() => {
		if (!currentRightSidebarState?.isCollapsed && releaseNotesNotifications !== 0) {
			setReleaseNotesNotifications(0);
		}
	}, [releaseNotesNotifications, setReleaseNotesNotifications, currentRightSidebarState]);

	useEffect(() => {
		if (
			currentRightSidebarState?.panelId === HELP_PANEL_ID &&
			!currentRightSidebarState?.isCollapsed
		) {
			openMenu();
		} else {
			closeMenu();
			if (isHelpPanelOpenedPreviously?.panelId === HELP_PANEL_ID && !currentRightSidebarState) {
				requestAnimationFrame(() => helpButtonRef.current?.focus());
			}
		}
	}, [closeMenu, currentRightSidebarState, isHelpPanelOpenedPreviously, openMenu, topMenusValue]);

	if (
		// eslint-disable-next-line jira/ff/inline-expVal-params -- ESLint rule doesn't understand the array type
		expVal<Array<string>>('jira_nav4_hot_113350_changes', 'keys', []).includes(
			'blu-6050-render-help-button-once',
		)
	) {
		return (
			<div
				data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}
				data-vc={`atlassian-navigation-help${__SERVER__ ? '-ssr' : ''}`}
			>
				<SpotlightTargetComponent>
					<Trigger
						aria-expanded={false}
						aria-haspopup={false}
						isSelected={isMenuOpen}
						badge={badge}
						onClick={onClick}
						tooltip={formatMessage(messages.tooltip)}
						label={formatMessage(messages.label)}
						ref={helpButtonRef}
					/>
				</SpotlightTargetComponent>
			</div>
		);
	}

	return <TriggerComponent aria-expanded={false} aria-haspopup={false} />;
}

const HelpWithErrorBoundary = () => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.HELP}>
		<Help />
	</TopLevelErrorBoundary>
);

export { HelpWithErrorBoundary as Help };
