import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const HelpCenterSettingsArticlesViewArticleAppEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-help-center-setting-support-view-article-app" */ './src/ui'
			),
	),
	getPreloadProps: () => {
		return {};
	},
});
