import React, { forwardRef, type Ref } from 'react';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/utility/show-more-horizontal';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type MenuTriggerButtonProps = Omit<IconButtonProps, 'spacing' | 'icon' | 'label'>;

export const MenuTriggerButton = forwardRef(
	({ onClick, ...props }: MenuTriggerButtonProps, ref: Ref<HTMLButtonElement>) => {
		const { formatMessage } = useIntl();

		return (
			<IconButton
				{...props}
				icon={(iconProps) => <ShowMoreHorizontalIcon {...iconProps} color={token('color.icon')} />}
				label={formatMessage(messages.projectActionsMenu)}
				onClick={onClick}
				ref={ref}
				spacing="compact"
				appearance="subtle"
				testId="navigation-project-action-menu.ui.menu-container.themed-button"
			/>
		);
	},
);
