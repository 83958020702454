import { useCallback, useState, useRef } from 'react';
import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { OG_CONSOLIDATION_USER_STATE_STORAGE_KEY } from '@atlassian/jira-router-resources-eoc-og-consolidation-user-state/src/common/constants.tsx';
import type { OgConsolidationUserState } from '@atlassian/jira-router-resources-eoc-og-consolidation-user-state/src/common/types.tsx';
import { consolidationUserStateResource } from '@atlassian/jira-router-resources-eoc-og-consolidation-user-state/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

export const useOgConsolidationUserState = () => {
	const errorRef = useRef<unknown>();
	const loadingRef = useRef(false);
	const { showFlag } = useFlagsService();
	const {
		data,
		update: updateTransitionResource,
		error: transitionResourceError,
	} = useResource(consolidationUserStateResource);

	const { formatMessage } = useIntl();
	const [userPropertiesError, setUserPropertiesError] = useState<Error>();

	errorRef.current = userPropertiesError || transitionResourceError;

	const { atlassianAccountId: accountId } = useTenantContext();

	const update = useCallback(
		(newState: Partial<OgConsolidationUserState>) => {
			if (errorRef.current) {
				// If there is an error on resource or update, we don't want to update the state
				return Promise.reject(errorRef.current);
			}

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			let updatedData = {} as OgConsolidationUserState;
			updateTransitionResource((oldState) => {
				updatedData = {
					...oldState,
					...newState,
				};
				return updatedData;
			});

			if (accountId) {
				if (loadingRef.current) {
					const err = new Error('User properties is loading');
					fireErrorAnalytics({
						meta: {
							id: 'useOgConsolidationUserPreference-update',
							packageName: 'jiraEocChangeboarding',
							teamName: 'alexstrasza',
						},
						error: err,
						sendToPrivacyUnsafeSplunk: true,
					});
					setUserPropertiesError(err);
					return Promise.reject(err);
				}

				loadingRef.current = true;
				return setUserProperties(
					accountId,
					OG_CONSOLIDATION_USER_STATE_STORAGE_KEY,
					JSON.stringify(updatedData),
				)
					.catch((err) => {
						setUserPropertiesError(new Error('Error occured while setting user properties'));

						showFlag({
							type: 'error',
							isAutoDismiss: true,
							title: formatMessage(messages.errorFlagTitle),
							messageId:
								'eoc-changeboarding.controllers.use-og-consolidation-user-state.show-flag.error.jira-modal',
							messageType: 'transactional',
						});

						fireErrorAnalytics({
							meta: {
								id: 'useOgConsolidationUserPreference-update',
								packageName: 'jiraEocChangeboarding',
								teamName: 'alexstrasza',
							},
							error: err,
							sendToPrivacyUnsafeSplunk: true,
						});
					})
					.finally(() => {
						loadingRef.current = false;
					});
			}

			return Promise.resolve();
		},
		[updateTransitionResource, accountId, formatMessage, showFlag],
	);

	const reopenTransitionLozenge = useCallback(() => {
		update({ isTransitionHomeHidden: false });
	}, [update]);

	return {
		data,
		update,
		error: errorRef.current,
		reopenTransitionLozenge,
	};
};
