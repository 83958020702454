import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const globalIssueNavigatorEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-global-issue-navigator-entrypoint" */ './src').then(
			(module) => module.GlobalIssueNavigatorSPAEntryPoint,
		),
	),
	getPreloadProps: () => ({}),
});
