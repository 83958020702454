import React, {
	type RefCallback,
	type ReactNode,
	useCallback,
	memo,
	useMemo,
	useState,
} from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { TriggerProps } from '@atlaskit/popup';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { JiraProjectAri } from '@atlassian/ari/jira';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useFlagsService, type Flag } from '@atlassian/jira-flags';
import { defineMessages } from '@atlassian/jira-intl';
import { QuickstartNudge } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/quickstart-nudge/main.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import {
	ContextualAnalyticsData,
	MODAL,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { BackgroundColourNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next-jwm-background-colour/src/async.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { VIEW_THEME_PICKER_EXPERIENCE } from '../../common/constants.tsx';
import { ExperienceFailed } from '../../common/utils.tsx';
import { THEME_PICKER_ELEMENT_ID } from '../../constants.tsx';
import { ThemePickerButtonItem } from '../theme-picker-button-item/ThemePickerButtonItem.tsx';
import { backgroundPickerPopupContentEntryPoint } from './popup-content/entrypoint.tsx';
import { ThemePickerSkeleton } from './ThemePickerSkeleton.tsx';
import type { Props } from './types.tsx';

const SOURCE_NAME = 'projectThemePicker';

const messages = defineMessages({
	errorFlagTitle: {
		id: 'business-theme-components.theme-picker.error-flag-title',
		defaultMessage: "Something's gone wrong",
		description:
			'Title of the error flag when opening the theme picker popup fails indicating something went wrong',
	},
	errorFlagDescription: {
		id: 'business-theme-components.theme-picker.error-flag-description',
		defaultMessage:
			'Our team has been notified. If the problem persists, please contact Atlassian Support.',
		description: 'Description of the error flag when opening the theme picker popup fails',
	},
});

export const POPUP_ENTRYPOINT_ERROR_FLAG: Flag = {
	id: 'open-theme-picker-error',
	type: 'error',
	title: messages.errorFlagTitle,
	description: messages.errorFlagDescription,
	isAutoDismiss: true,
	messageId:
		'project-theme-components.ui.theme-picker.popup-entrypoint-error-flag.error.open-theme-picker-error',
	messageType: 'transactional',
};

/**
 * Nav4 version of the ThemePicker component. Should not be used when nav3 is enabled.
 * Do not introduce nav4 checks in this component.
 * The nav3 version of this component is located at ../theme-picker-nav3/ThemePicker.tsx
 *
 * Remove this comment when the jira_nav4 feature gate is cleaned up.
 */
export const ThemePicker = memo<Props>(
	({ projectId, withAdaptiveColorTheme, shouldRenderToParent = true }: Props) => {
		const cloudId = useCloudId();
		const route = useCurrentRoute();
		const projectAri = JiraProjectAri.create({
			siteId: cloudId,
			projectId: String(projectId),
		}).toString();
		const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
			backgroundPickerPopupContentEntryPoint,
			useMemo(() => ({ cloudId, projectAri }), [cloudId, projectAri]),
		);
		const startExperience = useExperienceStart(VIEW_THEME_PICKER_EXPERIENCE);

		const { showFlag } = useFlagsService();
		const [flagShown, setFlagShown] = useState(false);
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const [isThemePickerOpen, setIsThemePickerOpen] = useState(false);

		const toggleThemePicker = useCallback(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(_: any, analyticsEvent?: UIAnalyticsEvent) => {
				// If we are about to open the theme picker, start the experience
				if (!isThemePickerOpen) startExperience();
				setIsThemePickerOpen((oldValue) => !oldValue);
				fireUIAnalytics(
					analyticsEvent ?? createAnalyticsEvent({}),
					'button clicked',
					'themePickerButton',
				);
				setFlagShown(false);
			},
			[isThemePickerOpen, startExperience, setIsThemePickerOpen, createAnalyticsEvent],
		);

		const renderNudgeWrapper = useCallback(
			(children: ReactNode) => <BackgroundColourNudgeAsync>{children}</BackgroundColourNudgeAsync>,
			[],
		);

		const buttonTrigger = useEntryPointButtonTrigger(entryPointActions, !isThemePickerOpen);

		const runtimeProps = useMemo(
			() => ({
				onOpenChange: setIsThemePickerOpen,
				withAdaptiveColorTheme,
			}),
			[setIsThemePickerOpen, withAdaptiveColorTheme],
		);

		const trigger = useCallback(
			(triggerProps: TriggerProps | { ref: RefCallback<HTMLElement> }) => (
				<ThemePickerButtonItem
					{...triggerProps}
					isSelected={isThemePickerOpen}
					testId="project-theme-components.ui.theme-picker.trigger"
					ref={(element: HTMLElement) => {
						buttonTrigger(element);
						if (typeof triggerProps.ref === 'function') {
							triggerProps.ref(element);
						}
					}}
					onClick={toggleThemePicker}
				/>
			),
			[buttonTrigger, isThemePickerOpen, toggleThemePicker],
		);

		const errorFallback = useCallback(
			({ error }: { error: Error }) => {
				if (!flagShown) {
					showFlag(POPUP_ENTRYPOINT_ERROR_FLAG);
					setFlagShown(true);
				}

				return <ExperienceFailed experience={VIEW_THEME_PICKER_EXPERIENCE} error={error} />;
			},
			[flagShown, showFlag],
		);

		const content = useCallback(
			() => (
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="background-picker-popup-content"
					packageName="@atlassian/jira-project-theme-components"
					fallback={<ThemePickerSkeleton />}
					errorFallback={errorFallback}
					runtimeProps={runtimeProps}
				/>
			),
			[errorFallback, entryPointReferenceSubject, runtimeProps],
		);

		const onCloseHandler = useCallback(() => setIsThemePickerOpen(false), [setIsThemePickerOpen]);
		return (
			<QuickstartNudge renderWrapper={renderNudgeWrapper}>
				<ContextualAnalyticsData sourceName={SOURCE_NAME} sourceType={MODAL}>
					<Popup
						isOpen={isThemePickerOpen}
						onClose={onCloseHandler}
						shouldRenderToParent={route.name.includes('legacy') ? false : shouldRenderToParent}
						content={content}
						trigger={trigger}
						placement="right-start"
						id={THEME_PICKER_ELEMENT_ID}
						messageId="project-theme-components.ui.theme-picker.popup.theme-picker"
						messageType="transactional"
					/>
				</ContextualAnalyticsData>
			</QuickstartNudge>
		);
	},
);
