import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async.tsx';
import { planLayout } from '@atlassian/jira-plan-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwarePlanTimelineRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwarePlanTimelineRoute.tsx';
import PlanTimelinePage, { LazyPlanTimeline } from './ui/plan-timeline/index-old.tsx';
import {
	planTimelinePageEntryPoint,
	planTimelinePageEntryPointWithoutHorizontalNavigation,
} from './ui/plan-timeline/index.tsx';

export const softwarePlanTimelineRouteEntry = createEntry(softwarePlanTimelineRoute, () => ({
	group: 'software',
	entryPoint: () =>
		// eslint-disable-next-line no-nested-ternary
		fg('migrate_plans_to_entrypoints')
			? fg('migrate_plans_navigation_to_relay')
				? planTimelinePageEntryPoint
				: planTimelinePageEntryPointWithoutHorizontalNavigation
			: undefined,
	component: PlanTimelinePage,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-opt-out-timeline'),
	layout: composeLayouts(globalLayout, planLayout),
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	],
	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},
	navigation: {
		menuId: MENU_ID.PLANS,
		sidebar: AsyncSidebarPlan,
		horizontal: fg('migrate_plans_navigation_to_relay') ? undefined : AsyncHorizontalNavPlans,
		onlyShowHorizontalOnNav4: true,
	},
	forPaint: [LazyAtlassianNavigation, LazyPlanTimeline],
}));
