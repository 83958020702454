import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessCalendarEmbedRoute } from '@atlassian/jira-router-routes-business-calendar-routes/src/jiraBusinessCalendarEmbedRoute.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { calendarEmbedEntrypoint } from '@atlassian/jira-spa-apps-business-calendar-embed/entrypoint.tsx';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-business-calendar-new/entrypoint.tsx';

const calendarEmbedPageEntrypoint = createChromelessEntryPoint({
	main: calendarEntryPoint,
});

const legacyCalendarEmbedPageEntrypoint = createChromelessEntryPoint({
	main: calendarEmbedEntrypoint,
});

export const jiraBusinessCalendarEmbedRouteEntry = createEntry(
	jiraBusinessCalendarEmbedRoute,
	() => ({
		group: 'classic-business',
		ufoName: 'jwm.calendar-embed-view',

		entryPoint() {
			return expValEquals('business_projects_shared_calendar', 'cohort', 'variation')
				? calendarEmbedPageEntrypoint
				: legacyCalendarEmbedPageEntrypoint;
		},

		layout: chromelessLayout,
		skeleton: ThemedLazySkeleton,
		resources: getProjectViewResources(),
	}),
);
