import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { commonErrorAnalyticsMeta, getFeatureMap, listFeature } from '../../common/constants.tsx';
import type { FeatureStatusList, ToggleableBoardFeature } from '../../common/types.tsx';
import {
	filterAndSort,
	fireErrorAnalyticsOnUnmatchFeatureKey,
	updateFeatureState,
} from '../../common/utils.tsx';
import { getBoardFeatures } from '../../services/get-board-features/index.tsx';
import { updateBoardFeature } from '../../services/update-board-feature/index.tsx';
import type { State, UpdateFeatureParams } from './types.tsx';

const clearSiderbarFeatureToggled =
	(): Action<State> =>
	({ setState }) => {
		setState({
			sidebarFeatureToggled: null,
		});
	};

const fetchFeatures =
	(boardId: number): Action<State> =>
	async ({ getState, setState }) => {
		const { features, isLoading } = getState();
		if (isLoading) return;

		setState({
			isFetchError: false,
		});

		try {
			if (features.length === 0) {
				setState({ isLoading: true });
				const data = await getBoardFeatures(boardId);
				const filteredFeatures = filterAndSort(data);
				fireErrorAnalyticsOnUnmatchFeatureKey(filteredFeatures);
				setState({
					isLoading: false,
					features: filteredFeatures,
				});
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				isFetchError: true,
				isLoading: false,
			});
			fireErrorAnalytics({
				meta: {
					...commonErrorAnalyticsMeta,
					id: 'fetchFeatures',
				},
				error,
			});
		}
	};

const syncFeatures =
	(sidebarFeatureStatus: FeatureStatusList): Action<State> =>
	({ getState, setState }) => {
		const { features } = getState();
		const featureMap = getFeatureMap({
			isListViewEnabled: expValEquals('jsw_list_view', 'cohort', 'variation'),
		});
		const synced = features.map<ToggleableBoardFeature>((f) => {
			const id = f.feature;
			if (featureMap[id]) {
				const { statusKey } = featureMap[id];
				return {
					...f,
					state: sidebarFeatureStatus[statusKey] ? 'ENABLED' : 'DISABLED',
				};
			}
			return f;
		});
		setState({ features: synced });
	};

const updateFeature =
	({
		boardId,
		feature,
		dependentFeature,
		analyticsEvent,
		onError,
		onAfterUpdate,
	}: UpdateFeatureParams): Action<State> =>
	async ({ getState, setState }) => {
		const { features, enablingFeatures, sidebarFeatureToggled } = getState();
		let featureMap = getFeatureMap();
		if (expValEquals('jsw_list_view', 'cohort', 'variation')) {
			featureMap = { ...featureMap, ...listFeature };
		}
		const currentStatus = features.find((f) => f.feature === feature)?.state;
		const isEnabling = currentStatus === 'DISABLED';

		if (!boardId || feature.length === 0 || !currentStatus) {
			return;
		}

		try {
			// Optimistically update the toggle status before the API call finishes
			// and add feature to the enabling states
			setState({
				features: features.map(updateFeatureState(feature, dependentFeature)),
				sidebarFeatureToggled: {
					...(sidebarFeatureToggled ?? {}),
					[featureMap[feature].statusKey]: isEnabling,
					...(dependentFeature && {
						[featureMap[dependentFeature].statusKey]: isEnabling,
					}),
				},
				...(isEnabling && { enablingFeatures: [...enablingFeatures, feature] }),
			});

			await updateBoardFeature({
				boardId,
				feature,
				enabling: isEnabling,
			});
			fireTrackAnalytics(analyticsEvent, 'featureStatus updated', {
				featureId: feature,
				newStatus: currentStatus,
			});

			onAfterUpdate?.(feature, isEnabling);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			onError?.();
			setState({
				features,
				sidebarFeatureToggled,
			});
			fireErrorAnalytics({
				meta: {
					...commonErrorAnalyticsMeta,
					id: 'updateFeature',
				},
				error,
			});
		}
		if (isEnabling) {
			setState({
				// feature has been enabled, remove from enabling state
				enablingFeatures: getState().enablingFeatures.filter((f) => f !== feature),
			});
		}
	};

export const actions = { fetchFeatures, updateFeature, syncFeatures, clearSiderbarFeatureToggled };
