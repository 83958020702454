/**
 * @generated SignedSource<<fc9c2fcd14832ff6f021278465d2a9bc>>
 * @relayHash 73520ae724cd725d1b8a9eaf5e2c16fc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2757426f0a0e166111d8064199946a10478bc2b410dc09def2c817aed218a3c8

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { profileMenuQuery } from './profileMenuQuery.graphql';

const node: PreloadableConcreteRequest<profileMenuQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2757426f0a0e166111d8064199946a10478bc2b410dc09def2c817aed218a3c8",
    "metadata": {},
    "name": "profileMenuQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
