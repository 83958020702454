import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { classicBusinessProjectSampleOnboardingRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectSampleOnboardingRoute.tsx';
import SampleOnboardingView, {
	LazySampleOnboardingView,
} from './ui/spa/business/sample-onboarding/index.tsx';

export const classicBusinessProjectSampleOnboardingRouteEntry = createEntry(
	classicBusinessProjectSampleOnboardingRoute,
	() => ({
		group: 'classic-business',
		component: SampleOnboardingView,
		layout: chromelessLayout,
		skeleton: ThemedLazySkeleton,
		resources: getNavigationResources(),
		forPaint: [LazySampleOnboardingView],
	}),
);
