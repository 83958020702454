import React, { memo } from 'react';

import JiraTopBanner from '@post-office-placement/jira-top-banner/placement';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PostOfficeProvider } from '@atlassian/jira-post-office-provider/src/index.tsx';
import { PlacementFetchProvider } from '@atlassian/jira-post-office-provider/src/placement-fetch-provider.tsx';
import killswitch from '@atlassian/jira-killswitch/src/index.tsx';
import { postOfficeJiraTopBannerResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';

import { Placement } from '@atlassian/post-office-placement';

const PLACEMENT_ID = 'jira-top-banner';

const TopBannerPlacement = () => {
	return (
		<JSErrorBoundary
			id="topBannerPlacement"
			packageName="top-banner-placement"
			teamName="post-office"
			fallback={() => <></>}
		>
			<PostOfficeProvider>
				<div data-testid="top-banner-placement.jira-top-banner-placement">
					<PlacementFetchProvider resource={postOfficeJiraTopBannerResource}>
						<Placement
							placementId={PLACEMENT_ID}
							// Post Office and Jira uses different versions of react-error-boundary - https://atlassian.slack.com/archives/CL6HC337Z/p1741218999095929
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore - Type 'ComponentType<Partial<ErrorBoundaryPropsWithRender>> is not assignable to type 'PlacementComponent<LocalPlacementProps>'
							PlacementComponent={JiraTopBanner}
						/>
					</PlacementFetchProvider>
				</div>
			</PostOfficeProvider>
		</JSErrorBoundary>
	);
};

export const JiraTopBannerPlacement = memo(() => {
	if (!killswitch('killswitch_post_office_in_jira') && fg('jira-issue-term-refresh-banner')) {
		return <TopBannerPlacement />;
	}

	return null;
});
