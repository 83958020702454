/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type ComponentType, type Ref, forwardRef, useRef, type FC } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useIntl, type IntlShape } from 'react-intl-next';
import type { IntlShapeV2 } from './types.tsx';

// some of these types are copy-paste from react adjusted for our needs
type InexactPartial<T> = { [K in keyof T]?: T[K] | undefined };
type DefaultProps = { defaultProps?: any };

/**
 * marks default-ed props as optional
 * without requirement for D to be "react component"
 */
type Defaultize<P, D> = P extends any
	? string extends keyof P
		? P
		: Pick<P, Exclude<keyof P, keyof D>> &
				InexactPartial<Pick<P, Extract<keyof P, keyof D>>> &
				InexactPartial<Pick<D, Exclude<keyof D, keyof P>>>
	: P;

type DerivedFC<P, DF, Extra = {}> = FC<
	Defaultize<P, DF extends { defaultProps: infer R } ? R : {}> & Extra
> & { defaultProps?: undefined };

type InjectIntlOptions<WithRef extends boolean = false> = {
	withRef?: WithRef;
};

const isDisplayableString = (mayBeString?: string | null): boolean =>
	typeof mayBeString === 'string' && mayBeString !== '';

function getDisplayName<C extends ComponentType<any>>(Component: C): string {
	if (isDisplayableString(Component.displayName)) {
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
		return Component.displayName;
	}

	if (isDisplayableString(Component.name)) {
		return Component.name;
	}
	return 'Component';
}

export type WithIntlProvided<P> = P & { intl: IntlShapeV2 | IntlShape | undefined };

/**
 * @deprecated use useIntl
 */
export const injectIntlV2: {
	<K, Props extends { ref?: K }, DF extends DefaultProps>(
		WrappedComponent: ComponentType<WithIntlProvided<Props>> & DF,
		options: InjectIntlOptions<true>,
	): DerivedFC<Omit<Props, 'intl'>, DF, { ref?: Ref<K> }>;
	<Props, DF>(
		WrappedComponent: ComponentType<WithIntlProvided<Props>> & DF,
		options?: InjectIntlOptions<false>,
	): DerivedFC<
		Omit<Props, 'intl'>,
		DF,
		{
			forwardedRef?: Ref<any>;
		}
	>;
} = (WrappedComponent: ComponentType, options?: InjectIntlOptions<boolean>) => {
	const { withRef = false } = options || {};

	const WithIntl = (props: any) => {
		const intl = useIntl();
		const intlRef = useRef<IntlShapeV2 | null>(null);

		if (!intl) {
			throw new Error('REACT_INTL_FACADE: React Intl Could not find required `intl` object');
		}

		if (!intlRef.current) {
			intlRef.current = {
				// @ts-expect-error We force v5 messages type on v2 type
				messages: intl.messages,
				locale: intl.locale,
				formats: intl.formats,
				formatDate: intl.formatDate,
				formatMessage: intl.formatMessage,
				formatNumber: intl.formatNumber,
				formatPlural: intl.formatPlural,
				formatTime: intl.formatTime,
			};
		}

		return (
			<WrappedComponent
				{...props}
				intl={intlRef.current}
				ref={withRef ? props.forwardedRef : null}
			/>
		);
	};
	// Changed this display name to be capital letter to prevent snapshots from failing, here is the original: https://github.com/formatjs/formatjs/blob/main/packages/react-intl/src/components/injectIntl.tsx#L98
	WithIntl.displayName = `InjectIntl(${getDisplayName(WrappedComponent)})`;
	WithIntl.WrappedComponent = WrappedComponent;

	if (withRef) {
		return hoistNonReactStatics(
			forwardRef((props: any, ref) => <WithIntl {...props} forwardedRef={ref} />),
			WrappedComponent,
		);
	}

	return hoistNonReactStatics(WithIntl, WrappedComponent) as any;
};
