import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const HelpCenterSettingsCreateArticleAppEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-help-center-setting-create-article" */ './src/ui'),
	),
	getPreloadProps: () => {
		return {};
	},
});
