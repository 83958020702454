export const isBoardRoute = (routeName: string): boolean =>
	routeName === 'rapidboard-board' ||
	routeName === 'rapidboard-board-embed' ||
	routeName === 'rapidboard-board-settings' ||
	routeName === 'rapidboard-user-board' ||
	routeName === 'rapidboard-user-board-settings' ||
	routeName === 'servicedesk-boards' ||
	routeName === 'servicedesk-queues-custom-board' ||
	routeName === 'servicedesk-queues-practice-custom-board';

export const isBacklogRoute = (routeName: string): boolean =>
	routeName === 'rapidboard-backlog' ||
	routeName === 'rapidboard-user-backlog' ||
	routeName === 'rapidboard-backlog-embed';
