/**
 * @generated SignedSource<<ce045fc0dd46722872f41c70f570f768>>
 * @relayHash 989e1263c7e39eb54ef3504b593961dc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bc2fb1d6504af1e571c063410348f23ce17651af7eb3f1921b9b1483b8d48cc8

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { table_RequestTypesTableQuery } from './table_RequestTypesTableQuery.graphql';

const node: PreloadableConcreteRequest<table_RequestTypesTableQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "bc2fb1d6504af1e571c063410348f23ce17651af7eb3f1921b9b1483b8d48cc8",
    "metadata": {},
    "name": "table_RequestTypesTableQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
