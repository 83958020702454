import type { Match, Query, RouterContext } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import {
	WRM_RESOURCES_ARRAY,
	WRM_HERITAGE_RESOURCES_FHD_ARRAY,
	WRM_BOARD_RESOURCES_FHD_ARRAY,
	WRM_BACKLOG_RESOURCES_FHD_ARRAY,
} from '../../constants.tsx';
import { RAPID_VIEW_ROUTE_CONFIG } from '../wrm-bundle/get-data/constants.tsx';

export const buildAllDataQueryParams = (query: Query, match: Match) => {
	const { rapidView, quickFilter, projectKey: queryProjectKey, etag } = query;
	const boardId = match.params.boardId || rapidView;
	const projectKey = match.params.projectKey || queryProjectKey;

	let activeQuickFilters = '';
	if (quickFilter) {
		activeQuickFilters = Array.isArray(quickFilter)
			? quickFilter.reduce(
					(prevFilter, nextFilter) => `${prevFilter}&activeQuickFilters=${nextFilter}`,
					activeQuickFilters,
				)
			: `&activeQuickFilters=${quickFilter}`;
	}

	const etagParam = etag ? `&etag=${etag}` : '';

	const projectKeyParam = projectKey ? `&selectedProjectKey=${projectKey}` : '';

	const { GH } = window;

	const forceConsistency = '&forceConsistency=true';
	GH?.RapidBoard?.State?.setRapidViewIdForEventuallyConsistentBoard(undefined);

	return `rapidViewId=${boardId}${projectKeyParam}${activeQuickFilters}${etagParam}${forceConsistency}`;
};

const cleanUpInlineScript = (dataName: string) => {
	// @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	delete window[dataName];
};

export const getInlineScriptData = (dataName: string) => {
	// @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const req: XMLHttpRequest = window[dataName];
	if (!req) {
		return null;
	}

	if (req.readyState === req.DONE && req.status !== 200) {
		cleanUpInlineScript(dataName);
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return new Promise<any>((r: (result: Promise<any> | any) => void) => {
		if (req.readyState === req.DONE && req.status === 200) {
			cleanUpInlineScript(dataName);
			r(JSON.parse(req.response));
		} else {
			req.addEventListener('load', () => {
				cleanUpInlineScript(dataName);
				r(JSON.parse(req.response));
			});
		}
	});
};

export const getRapidViewConfigFromRouteName = (routeName: string) => {
	for (const routeConfig of Object.values(RAPID_VIEW_ROUTE_CONFIG)) {
		if (routeConfig.routes?.includes(routeName)) {
			return routeConfig;
		}
	}
	return RAPID_VIEW_ROUTE_CONFIG.DEFAULT;
};

export const getRequiredResourceList = (view: string | null) => {
	let rapidboardResources = WRM_RESOURCES_ARRAY;

	if (fg('reports-overview-page-migration')) {
		if (view) {
			const routeConfig = getRapidViewConfigFromRouteName(view);
			return [...WRM_HERITAGE_RESOURCES_FHD_ARRAY, ...routeConfig.resources];
		}
		return [...WRM_HERITAGE_RESOURCES_FHD_ARRAY, ...RAPID_VIEW_ROUTE_CONFIG.DEFAULT.resources];
	}

	// eslint-disable-next-line default-case
	switch (view) {
		case 'rapidboard-board':
		case 'rapidboard-board-embed':
		case 'rapidboard-user-board':
		case 'servicedesk-boards':
		case 'servicedesk-queues-custom-board':
		case 'servicedesk-queues-practice-custom-board':
			rapidboardResources = WRM_BOARD_RESOURCES_FHD_ARRAY;
			break;
		case 'rapidboard-backlog':
		case 'rapidboard-user-backlog':
		case 'rapidboard-backlog-embed':
			rapidboardResources = WRM_BACKLOG_RESOURCES_FHD_ARRAY;
			break;
	}

	return [...WRM_HERITAGE_RESOURCES_FHD_ARRAY, ...rapidboardResources];
};

export function getConfigFromRouterContext(routerContext: Partial<RouterContext>): string {
	return routerContext.match?.params.config || routerContext.query?.config || '';
}
