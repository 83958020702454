import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectSettingsSoftwareNotificationsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareNotificationsRoute.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';
import SoftwareNotifications, {
	LazySoftwareNotificationsSettings,
} from './ui/project-settings/software-notifications/index.tsx';

export const projectSettingsSoftwareNotificationsRouteEntry = createEntry(
	projectSettingsSoftwareNotificationsRoute,
	() => ({
		group: 'project-settings-software',
		ufoName: 'project-settings.software.notifications',
		component: SoftwareNotifications,
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazySoftwareNotificationsSettings],
	}),
);
