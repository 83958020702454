import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	Nav4Dashboards as Nav4DashboardsComponent,
	type Nav4DashboardsProps,
} from './ui/dashboards/Nav4Dashboards.tsx';
import { AnalyticContextProvider } from './common/controllers/analytic-context/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

export const Nav4Dashboards = (props: Nav4DashboardsProps) => (
	<UFOSegment name="nav4-sidebar-dashboards">
		<JSErrorBoundary
			fallback="flag"
			id="dashboards-section"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<AnalyticContextProvider>
				<Nav4DashboardsComponent {...props} />
			</AnalyticContextProvider>
		</JSErrorBoundary>
	</UFOSegment>
);
