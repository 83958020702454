import React from 'react';
import { fireBoardCreateErrorAnalytics } from '../common/utils.tsx';
import { BoardCreateFlyoutMenu } from './flyout-menu/index.tsx';
import type { BoardCreateProps } from './types.tsx';

export const BoardCreate = ({
	projectId,
	projectAvatar,
	projectKey,
	renderTrigger,
	triggerRef,
}: BoardCreateProps) => {
	if (!projectId || !projectKey) {
		fireBoardCreateErrorAnalytics({
			error: new Error(
				'Failed to load board create flyout menu: projectId or projectKey undefined',
			),
			attributes: {
				projectId,
				projectKey,
			},
			id: 'loadFlyoutMenu',
		});

		return null;
	}

	return (
		<BoardCreateFlyoutMenu
			renderTrigger={renderTrigger}
			projectId={projectId}
			projectKey={projectKey}
			projectAvatar={projectAvatar}
			triggerRef={triggerRef}
		/>
	);
};
