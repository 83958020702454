export const PAGE_SIZE = 50;

const getStartAt = (page?: string) => {
	const pageNum = Number(page);
	if (!page || Number.isNaN(pageNum)) {
		return 0;
	}
	return (pageNum - 1) * PAGE_SIZE;
};

export const getUrl = (page?: string, query?: string) =>
	`/rest/jcs/admin/v1/request-intake/global-fields?startAt=${getStartAt(page)}${query ? `&query=${query}` : ''}`;
