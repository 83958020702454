import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import { ThemeSetter } from '@atlassian/jira-project-theme-page-container/src/ui/theme-setter/ThemeSetter.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';
import { ThemeTokensSetter } from '@atlassian/jira-project-theme-components/src/ui/theme-tokens-setter/index.tsx';

export const ProjectThemeSetter = () => {
	const { queryReference } = useRelayResource(projectThemeResource);

	if (!queryReference) {
		/* The resource is available only for routes enabled for theming.
		We still need to set the default token color by using ThemeTokenSetter for all other routes.
		This can be removed once theming is enabled for all pages. */
		return <ThemeTokensSetter themeSetting={null} />;
	}

	return (
		<JSErrorBoundary id="projectThemeSetter" packageName="jiraPageContainerV2" teamName="deliveroo">
			<Placeholder name="project-theme-setter-placeholder" fallback={null}>
				<ThemeSetter queryReference={queryReference} />
			</Placeholder>
		</JSErrorBoundary>
	);
};
