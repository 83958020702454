import React, { useCallback, useContext, type FC } from 'react';
import type { ConfigurableMenuId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import {
	MenuButtonItem,
	type MenuButtonItemProps,
	type MenuItemOnClick,
} from '@atlassian/navigation-system/side-nav/menu-button-item';
import { PeekMenuIdContext } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import { convertToConfigurableMenuId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/convert-to-configurable-menu-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { SECTION_ITEM_MAP } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { isGlobalAppMenuId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/global-apps/index.tsx';
import { USER_NAVIGATION_ITEM_LINKS } from '../../../../common/constants/index.tsx';

/**
 * This type outwardly aligns with the `MenuButtonItem`, even though it could return a `MenuLinkItem`.
 */
type MenuItemProps = Omit<MenuButtonItemProps, 'children' | 'onClick'> & {
	/**
	 * Overriding the `onClick` prop to support being called for either a button or a link.
	 * This is because the `MenuItem` component internally uses either a `MenuLinkItem` or a `MenuButtonItem`
	 * based on an internal mapping, as opposed to the presence of certain props.
	 */
	onClick: MenuItemOnClick<HTMLButtonElement | HTMLAnchorElement>;
	menuId: ConfigurableMenuId;
};

/**
 * Internally renders either a `MenuLinkItem` or a `MenuButtonItem` based on the presence of a URL.
 * This is based on an internal mapping of menu IDs to URLs.
 */
// remove on blu-6576_migrate_to_platform_customisation cleanup
export const MenuItem: FC<MenuItemProps> = ({ menuId, ...props }) => {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(menuId);

	// This is part of an intermediate state workaround where links are accounted for;
	// we don't expect links to be in the More menu at the end state,
	// at which point this and the MenuLinkItem below can be removed.
	const url = USER_NAVIGATION_ITEM_LINKS[menuId];

	return (
		<>
			{url ? (
				<MenuLinkItem {...props} elemBefore={icon} href={url}>
					{formattedMessage}
				</MenuLinkItem>
			) : (
				<MenuButtonItem {...props} elemBefore={icon}>
					{formattedMessage}
				</MenuButtonItem>
			)}
		</>
	);
};

export type MoreMenuItemProps = {
	// eslint-disable-next-line jira/react/handler-naming
	closeMenu: () => void;
	menuId: string;
	key: string;
};

export function MoreMenuItem({ menuId, closeMenu, key }: MoreMenuItemProps) {
	const { setPeekMenuId } = useContext(PeekMenuIdContext);

	const configurableId = convertToConfigurableMenuId(menuId);
	const isGlobalApp = isGlobalAppMenuId(menuId);

	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(configurableId);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	// This is part of an intermediate state workaround where links are accounted for;
	// we don't expect links to be in the More menu at the end state,
	// at which point this and the MenuLinkItem below can be removed.
	const url = USER_NAVIGATION_ITEM_LINKS[menuId];

	const onClick = useCallback(() => {
		closeMenu();

		if (!isGlobalApp) {
			// Global apps open in a new tab onClick so they don't need to be a peek-item
			setPeekMenuId(configurableId);
		}

		sendSectionItemAnalyticsEvent({
			menuId: configurableId,
			sectionItem: SECTION_ITEM_MAP[configurableId],
		});
	}, [closeMenu, configurableId, isGlobalApp, sendSectionItemAnalyticsEvent, setPeekMenuId]);

	return (
		<>
			{url ? (
				<MenuLinkItem
					elemBefore={icon}
					href={url}
					onClick={onClick}
					key={key}
					target={isGlobalApp ? '_blank' : undefined}
				>
					{formattedMessage}
				</MenuLinkItem>
			) : (
				<MenuButtonItem elemBefore={icon} onClick={onClick} key={key}>
					{formattedMessage}
				</MenuButtonItem>
			)}
		</>
	);
}
