import parameters from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContentViewOldQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JiraNavigationSettingsAri } from '@atlassian/ari/jira/navigation-settings';
import { IdentityUserAri } from '@atlassian/ari/identity/user';
import type { AccountId, ActivationId } from '@atlassian/jira-shared-types/src/general.tsx';

type EntryPointParams = {
	cloudId: string;
	atlassianAccountId: AccountId | null;
	activationId: ActivationId;
};

// To be cleaned up with blu-5799-jira-software-board-entrypoint-route
export const asyncNav4ProjectsContentViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-nav4-projects-content-view-old-query" */ './Nav4ProjectsContentViewOldQuery'
		).then(({ Nav4ProjectsContentViewOldQuery }) => Nav4ProjectsContentViewOldQuery),
	),
	getPreloadProps: ({ cloudId, activationId, atlassianAccountId }: EntryPointParams) => ({
		queries: {
			projects: {
				parameters,
				variables: {
					cloudId,
					entityAriProjects: JiraNavigationSettingsAri.create({
						siteId: cloudId,
						activationId,
						navigationSettingScope: 'projects',
					}).toString(),
					ownerAri:
						atlassianAccountId && IdentityUserAri.create({ userId: atlassianAccountId }).toString(),
				},
			},
		},
	}),
});
