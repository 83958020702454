import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { systemConfigurationGeneralResource } from '@atlassian/jira-router-resources-system-configuration-settings/src/index.tsx';
import { systemConfigurationRoute } from '@atlassian/jira-router-routes-admin-pages-system-configuration-routes/src/systemConfigurationRoute.tsx';
import { conditionalPostOfficeJiraTopBannerResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';
import SystemConfigurationGeneral, {
	LazySystemConfigurationGeneral,
} from './ui/spa/admin-pages/system-configuration/async.tsx';

export const systemConfigurationRouteEntry = createEntry(systemConfigurationRoute, () => ({
	group: 'global-settings',
	component: SystemConfigurationGeneral,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsSystem,
	},
	resources: [
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		systemConfigurationGeneralResource,
		conditionalPostOfficeJiraTopBannerResource,
	],
	forPaint: [LazyAtlassianNavigation, LazySystemConfigurationGeneral],
}));
