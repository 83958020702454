import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const settingsMenuEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-top-nav-settings-menu" */ './index').then(
			({ MenuWithEntryPoint }) => MenuWithEntryPoint,
		),
	),
	getPreloadProps: () => ({}),
});
