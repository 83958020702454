import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const SuggestedFixContentEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "jql-debugger-suggested-fix-content" */ './index.tsx'),
	),
	getPreloadProps: () => {
		return {};
	},
});
