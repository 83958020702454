// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const deleteCollection =
	(collectionUUID: string): Action<State, Props> =>
	async ({ setState, getState }, { onSuccess }) => {
		const state = getState();
		let removed = false;

		const filteredCollections = state.collections.data.filter((collection) => {
			const shouldRemove = collection.uuid === collectionUUID;
			if (shouldRemove) {
				removed = true;
			}
			return !shouldRemove;
		});

		if (!removed) {
			return;
		}

		setState({
			collections: {
				...state.collections,
				data: filteredCollections,
			},
		});

		onSuccess?.('deleteCollection');
	};
