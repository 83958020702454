import React from 'react';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { helpCenterSettingsAgentStudioKnowledgeAppEntryPoint } from '@atlassian/jira-help-center-settings-agent-studio/src/ui/knowledge-config/entrypoint.tsx';

export const LazyAgentStudioOverviewPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-agent-studio-overview", jiraSpaEntry: "help-center-settings-agent-studio-overview" */ '@atlassian/jira-help-center-settings-agent-studio/src/ui/overview/index.tsx'
		),
	),
);

export const AgentStudioOverviewPage = () => (
	<LazyPage
		Page={LazyAgentStudioOverviewPage}
		pageId="help-center-settings-agent-studio-overview"
		shouldShowSpinner
	/>
);

export const LazyAgentStudioConversationReviewPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-agent-studio-conversation-review", jiraSpaEntry: "help-center-settings-agent-studio-conversation-review" */ '@atlassian/jira-help-center-settings-agent-studio/src/ui/conversation-review/index.tsx'
		),
	),
);

export const AgentStudioConversationReview = () => (
	<LazyPage
		Page={LazyAgentStudioConversationReviewPage}
		pageId="help-center-settings-agent-studio-conversation-review"
		shouldShowSpinner
	/>
);

export const LazyAgentStudioSimulatorPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-agent-studio-simulator", jiraSpaEntry: "help-center-settings-agent-studio-simulator" */ '@atlassian/jira-help-center-settings-agent-studio/src/ui/chat-simulator/index.tsx'
		),
	),
);

export const AgentStudioSimulatorComponent = () => (
	<LazyPage
		Page={LazyAgentStudioSimulatorPage}
		pageId="help-center-settings-agent-studio-chat-simulator"
		shouldShowSpinner={false}
	/>
);
export const LazyAgentStudioActionsPage = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-agent-studio-actions", jiraSpaEntry: "help-center-settings-agent-studio-actions" */ '@atlassian/jira-help-center-settings-agent-studio/src/ui/actions/index'
		),
	),
);

export const AgentStudioActionsComponent = () => (
	<LazyPage
		Page={LazyAgentStudioActionsPage}
		pageId="help-center-settings-agent-studio-chat-actions"
		shouldShowSpinner={false}
	/>
);

export const agentStudioKnowledgePageEntryPoint = createPageEntryPoint({
	main: helpCenterSettingsAgentStudioKnowledgeAppEntryPoint,
	topNavigationMenuId: MENU_ID.HELP_CENTER_SETTINGS,
});
