import React, { useState } from 'react';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { Drawer, DrawerCloseButton, DrawerContent, DrawerSidebar } from '@atlaskit/drawer/compiled';
import { useIntl } from '@atlassian/jira-intl';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { getRootPath } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import {
	L1_MENU_ID,
	type ConfigurableMenuId,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MENU_ID_ASSETS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import Nav4MenuButtonItem from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/menu-button-item-with-analytics/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { AsyncCmdbUpsellPage } from '@atlassian/jira-cmdb-upsell-page/src/async.tsx';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import messages from './messages.tsx';

const actionsOnHover = (
	<MoreNavMenuButton MenuItems={() => <HideL1MenuItemSection menuId={L1_MENU_ID.ASSETS} />} />
);
const linkAnalytics = {
	actionSubjectId: NAVIGATION_ITEM,
	itemId: MENU_ID_ASSETS,
	level: 1,
} as const;
const upsellButtonAnalytics = { ...linkAnalytics, componentType: FIXED_ITEM } as const;

const AssetsExternalLink = () => {
	const { formattedMessage: assetsMessage, icon: assetsIcon } = useConfigurableMessageAndIconMap(
		L1_MENU_ID.ASSETS,
	);
	const { icon: openExternalLinkIcon } = useFormattedMessageAndIcon(
		messages.openAssetsApp,
		LinkExternalIcon,
	);

	return (
		<Nav4MenuLinkItem
			href={getRootPath()}
			target="_blank"
			menuId={MENU_ID_ASSETS}
			analytics={linkAnalytics}
			elemBefore={assetsIcon}
			elemAfter={openExternalLinkIcon}
			actionsOnHover={actionsOnHover}
		>
			{assetsMessage}
		</Nav4MenuLinkItem>
	);
};

/* hard set assetsIcon to L1_MENU_ID.GLOBAL_APP_ASSETS and remove this prop on cleanup of:
 * blu-6313_sidebar_global_app_shortcuts &&
 * assets_as_an_app_v2 &&
 * blu-6576_migrate_to_platform_customisation
 */
type AssetsUpsellButtonProps = {
	configurableMenuId?: ConfigurableMenuId;
	lozengeText?: string;
};

export const AssetsUpsellButton = ({
	configurableMenuId,
	lozengeText,
}: AssetsUpsellButtonProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();
	const configurableMessageIcon = configurableMenuId ?? L1_MENU_ID.ASSETS;
	const { formattedMessage: assetsMessage, icon: assetsIcon } =
		useConfigurableMessageAndIconMap(configurableMessageIcon);
	const upsellText = lozengeText ?? formatMessage(messages.upsellLozengeText);

	return (
		<>
			<Nav4MenuButtonItem
				elemBefore={assetsIcon}
				analytics={upsellButtonAnalytics}
				onClick={() => {
					setIsOpen(true);
				}}
				actionsOnHover={configurableMessageIcon === L1_MENU_ID.ASSETS ? actionsOnHover : null}
				testId="navigation-apps-sidebar-nav4-sidebars-content-assets.ui.assets-app-item.nav-4-menu-button-item"
			>
				<Box xcss={assetsUpsellButtonStyles}>
					{assetsMessage}
					<Lozenge
						testId="navigation-apps-sidebar-nav4-sidebars-content-assets.ui.assets-app-item.upsell-lozenge"
						appearance="new"
					>
						{upsellText}
					</Lozenge>
				</Box>
			</Nav4MenuButtonItem>
			{/* Rendering the Drawer outside the menu button to not trigger its onClick handler when the drawer back button is pressed */}
			<Drawer
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false);
				}}
				width="full"
				testId="navigation-apps-sidebar-nav4-sidebars-content-assets.ui.assets-app-item.drawer"
				label={assetsMessage}
			>
				<DrawerSidebar>
					<DrawerCloseButton />
				</DrawerSidebar>
				<DrawerContent>
					<AsyncCmdbUpsellPage />
				</DrawerContent>
			</Drawer>
		</>
	);
};

export const AssetsAppItem = ({ showUpsell }: { showUpsell: boolean }) => {
	if (showUpsell) {
		return <AssetsUpsellButton />;
	}
	return <AssetsExternalLink />;
};

const assetsUpsellButtonStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	alignItems: 'center',
	alignContent: 'center',
});
