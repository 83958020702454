/**
 * @generated SignedSource<<fc6702d6d5b729b362f762d15d7d7323>>
 * @relayHash 3fb15375f0c81d09baad81021f24d532
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 298c60ba3b0de24944a6dc739efc5011d413a8237106a9bd71fdeeea48f62fc4

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiSupportWebsiteCustomPagesQuery } from './uiSupportWebsiteCustomPagesQuery.graphql';

const node: PreloadableConcreteRequest<uiSupportWebsiteCustomPagesQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "298c60ba3b0de24944a6dc739efc5011d413a8237106a9bd71fdeeea48f62fc4",
    "metadata": {
      "connection": [
        {
          "count": "NUMBER_OF_PAGES_INITIAL_FETCH",
          "cursor": null,
          "direction": "forward",
          "path": [
            "helpCenter",
            "helpCenterPages"
          ]
        }
      ]
    },
    "name": "uiSupportWebsiteCustomPagesQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
