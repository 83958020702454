import {
  JiraProjectCategoryAriResourceOwner,
  JiraProjectCategoryAriResourceType
} from "./chunk-23L4LUZM.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/project-category/manifest.ts
var jiraProjectCategoryAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraProjectCategoryAriResourceOwner,
  resourceType: JiraProjectCategoryAriResourceType,
  resourceIdSlug: "{projectCategoryId}",
  resourceIdSegmentFormats: {
    projectCategoryId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/project-category/index.ts
var JiraProjectCategoryAri = class _JiraProjectCategoryAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._projectCategoryId = opts.resourceIdSegmentValues.projectCategoryId;
  }
  get siteId() {
    return this._siteId;
  }
  get projectCategoryId() {
    return this._projectCategoryId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraProjectCategoryAriStaticOpts.qualifier,
      platformQualifier: jiraProjectCategoryAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraProjectCategoryAriStaticOpts.resourceOwner,
      resourceType: jiraProjectCategoryAriStaticOpts.resourceType,
      resourceId: `${opts.projectCategoryId}`,
      resourceIdSegmentValues: {
        projectCategoryId: opts.projectCategoryId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraProjectCategoryAriStaticOpts);
    return new _JiraProjectCategoryAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraProjectCategoryAriStaticOpts);
    return new _JiraProjectCategoryAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      projectCategoryId: this.projectCategoryId
    };
  }
};

export {
  JiraProjectCategoryAri
};
