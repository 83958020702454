import React from 'react';
import { SkeletonItem, MenuGroup, Section } from '@atlaskit/menu';
import { MENU_GROUP_MIN_WIDTH } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/constants/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';

export const Skeleton = () => (
	<MenuGroup minWidth={MENU_GROUP_MIN_WIDTH}>
		<Section isList>
			<SkeletonItem />
			<SkeletonItem />
			{expVal('blu-6987-display-settings-post-ga-experiment', 'isEnabled', false) && (
				<SkeletonItem />
			)}
		</Section>
	</MenuGroup>
);
