import React, { useMemo } from 'react';
import { Section } from '@atlaskit/side-navigation';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { getSystemNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/system/index.tsx';
import messages from './messages.tsx';

/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const AutomationSection = () => {
	const { formatMessage } = useIntl();

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	return (
		<Section title={formatMessage(messages.automationGroup)}>
			<MenuItem
				analytics={{ itemId: 'automation' }}
				href={navigationPath.globalAutomation}
				selectedOn={['global-automation']}
			>
				{formatMessage(messages.globalAutomation)}
			</MenuItem>
		</Section>
	);
};
