import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getQueuesResources,
	getKnowledgeBaseResources,
	getInsightResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskPracticeIssueRoute } from '@atlassian/jira-router-routes-servicedesk-queues-routes/src/servicedeskPracticeIssueRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { aiContextPanelResource } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import ServiceDeskQueuesIssueView, {
	LazyServicedeskQueuesIssueView,
} from './ui/issue-view/index.tsx';

export const servicedeskPracticeIssueRouteEntry = createEntry(
	servicedeskPracticeIssueRoute,
	() => ({
		group: 'servicedesk',
		component: ServiceDeskQueuesIssueView,
		layout: composeLayouts(serviceProjectLayout, embeddedIssuePreloadLayoutBuilder()),
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getQueuesResources(),
			...getKnowledgeBaseResources(),
			...getInsightResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
			resourceWithCondition2(() => fg('rrr_for_context_panel_data'), aiContextPanelResource),
		],
		afterPaint: [LazyIssueApp],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesIssueView],
	}),
);
