import React, { useRef } from 'react';
import type { EntryPointProps, PreloadedQuery } from 'react-relay';
import Nav4Onboarding from '@atlassian/jira-atlassian-onboarding-nav4/src/async.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { sidebarNav4Resource } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar-nav4/index.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { SidebarReady } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/metrics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import type { L1MenuItemsQuery } from '@atlassian/jira-relay/src/__generated__/L1MenuItemsQuery.graphql';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { SideNavContent } from '@atlassian/navigation-system/layout/side-nav';
import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';
import { useRouter } from '@atlassian/react-resource-router';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants/index.tsx';
import { L1MenuItems } from './ui/L1MenuItems.tsx';

export const SidebarContent = () => {
	const isAnonymous = useIsAnonymous();
	const { queryReference } = useRelayResource<L1MenuItemsQuery>(sidebarNav4Resource);
	const queryReferenceRef = useRef<PreloadedQuery<L1MenuItemsQuery> | null>(null);

	if (isAnonymous) {
		return <SidebarContentInnerAnonymous />;
	}

	// We don't want to use null query references. So only remember the non-null ones,
	// and pass the non-null query reference to the children.
	// From Slack: https://atlassian.slack.com/archives/C01E2MPPLE4/p1654736794416059?thread_ts=1654051102.548519&cid=C01E2MPPLE4
	if (queryReference) {
		queryReferenceRef.current = queryReference;
	}

	return <SidebarContentInner queryReference={queryReferenceRef.current} />;
};

export function SidebarContentForEntryPoint({
	queries: { nav4SidebarContentCore },
}: EntryPointProps<{ nav4SidebarContentCore: L1MenuItemsQuery }, Record<string, never>, {}, {}>) {
	const isAnonymous = useIsAnonymous();

	if (isAnonymous) {
		return <SidebarContentInnerAnonymous />;
	}

	return <SidebarContentInner queryReference={nav4SidebarContentCore} />;
}

type Props = {
	queryReference: PreloadedQuery<L1MenuItemsQuery> | null;
};

// For Storybook, but it also fixes https://hello.atlassian.net/wiki/spaces/~70121adfd59d619854e929bc192ed5ecb9b51/pages/3616160535
// By passing the queryReference as a param, it makes it easier to use for StoryBook
export function SidebarContentInner({ queryReference }: Props) {
	return (
		<UFOSegment name="nav4-sidebar-content">
			<JSErrorBoundary id="l1-nav-menu-items" packageName={PACKAGE_NAME} teamName={TEAM_NAME}>
				<SideNavContent>
					<MenuList>
						<Placeholder name="l1-nav-menu-items" fallback={null}>
							{queryReference && <L1MenuItems queryReference={queryReference} />}
						</Placeholder>
					</MenuList>
				</SideNavContent>
				{fg('blu-2735_nav4_onboarding') && <Nav4Onboarding />}
				<SidebarReady />
			</JSErrorBoundary>
		</UFOSegment>
	);
}

export function SidebarContentInnerAnonymous() {
	const { formattedMessage: projectsMessage, icon: projectsIcon } =
		useConfigurableMessageAndIconMap(L1_MENU_ID.PROJECTS);
	const { formattedMessage: dashboardsMessage, icon: dashboardsIcon } =
		useConfigurableMessageAndIconMap(L1_MENU_ID.DASHBOARDS);
	const { formattedMessage: filtersMessage, icon: filtersIcon } = useConfigurableMessageAndIconMap(
		L1_MENU_ID.FILTERS,
	);

	// We want to remove useRouter but the anonymous path is not as urgent as the authenticated path
	const [{ match }] = useRouter();

	return (
		<JSErrorBoundary id="l1-nav-menu-items" packageName={PACKAGE_NAME} teamName={TEAM_NAME}>
			<SideNavContent>
				<MenuList>
					<Placeholder name="l1-nav-menu-items" fallback={null}>
						<MenuLinkItem
							elemBefore={projectsIcon}
							href="/jira/projects"
							isSelected={match.path.startsWith('/jira/projects')}
						>
							{projectsMessage}
						</MenuLinkItem>
						<MenuLinkItem
							elemBefore={filtersIcon}
							href="/jira/filters"
							isSelected={match.path === '/jira/filters' || match.path.startsWith('/issues/')}
						>
							{filtersMessage}
						</MenuLinkItem>
						<MenuLinkItem
							elemBefore={dashboardsIcon}
							href="/jira/dashboards"
							isSelected={match.path.startsWith('/jira/dashboards')}
						>
							{dashboardsMessage}
						</MenuLinkItem>
					</Placeholder>
				</MenuList>
			</SideNavContent>
			<SidebarReady />
		</JSErrorBoundary>
	);
}
