import React, { useCallback, type RefCallback } from 'react';
import { useUID } from 'react-uid';
import type { TriggerProps } from '@atlaskit/popup';
import { Section } from '@atlaskit/menu';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import {
	testIdConcat,
	testIdGenerate,
} from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { Settings as NewSettingsButton } from '@atlassian/navigation-system/top-nav/settings';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import {
	EXPERIENCE_NAVIGATION_TOP_MENU,
	NAVIGATION_ITEM_ID,
	PACKAGE_NAME,
} from '../../common/constants.tsx';
import { Settings as OldSettingsButton } from '../../common/ui/settings-button/index.tsx';
import { useNavigationItemAnalytics } from '../../controllers/navigation-item-analytics/main.tsx';
import { MenuLayout } from '../../common/ui/menu/layout/index.tsx';
import { MenuSkeletonContent } from '../../common/ui/menu/skeleton/content/main.tsx';
import { MenuSkeletonFooter } from '../../common/ui/menu/skeleton/footer/main.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import { Menu } from './menu/index.tsx';
import { settingsMenuEntryPoint } from './menu/entrypoint.tsx';
import messages from './menu/messages.tsx';

export function Settings() {
	const SettingsMenu = componentWithFG(
		'blu-3339-nav4-topnav-settings-entrypoint',
		SettingsContainer,
		SettingsInner,
	);

	return (
		<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.SETTINGS}>
			<SettingsMenu />
		</TopLevelErrorBoundary>
	);
}

function SettingsContainer() {
	const isAnonymous = useIsAnonymous();

	if (isAnonymous) return null;

	return <SettingsInner />;
}

function SettingsInner() {
	const { formatMessage } = useIntl();
	// remove when cleaning up fg: blu-3339-nav4-topnav-settings-entrypoint
	const isAnonymous = useIsAnonymous();

	const [isOpen, { toggle: toggleMenu, off: closeMenu }] = useTopMenus(MENU_ID.SETTINGS);

	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.SETTINGS,
	});

	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.SETTINGS,
		analyticsSource: 'atlassian-navigation',
	});

	const onClick = useCallback(() => {
		if (!getWillShowNav4()) onStart();

		toggleMenu();
		triggerAnalytics({ shownCommandPalettePrompt: true });
	}, [onStart, toggleMenu, triggerAnalytics]);

	const testIdPrefix = testIdGenerate('secondary-actions', 'settings');

	// replace this with useId from react 18 when stable
	const id = useUID();
	const titleId = `top-navigation-settings-header-${id}-title`;

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		settingsMenuEntryPoint,
		{},
	);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);

	const Trigger = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			<div
				data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}
				data-vc={`atlassian-navigation-settings${__SERVER__ ? '-ssr' : ''}`}
			>
				{getWillShowNav4() ? (
					<NewSettingsButton
						{...triggerProps}
						ref={(element) => {
							if (fg('blu-3339-nav4-topnav-settings-entrypoint')) {
								triggerRef(element);
							}
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(triggerProps.ref as RefCallback<HTMLElement>)?.(element);
						}}
						onClick={onClick}
						isSelected={isOpen}
						interactionName="nav4-topNav-settings"
						label={formatMessage(messages.settings)}
					/>
				) : (
					<OldSettingsButton
						{...triggerProps}
						ref={(element) => {
							if (fg('blu-3339-nav4-topnav-settings-entrypoint')) {
								triggerRef(element);
							}
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(triggerProps.ref as RefCallback<HTMLElement>)?.(element);
						}}
						onClick={onClick}
						tooltip={formatMessage(messages.settings)}
						isSelected={isOpen}
					/>
				)}
			</div>
		),
		[formatMessage, isOpen, onClick, testIdPrefix, triggerRef],
	);

	const content = useCallback(
		() =>
			fg('blu-3339-nav4-topnav-settings-entrypoint') ? (
				<JiraEntryPointContainer
					id="nav4-topNav-settings"
					entryPointReferenceSubject={entryPointReferenceSubject}
					packageName={PACKAGE_NAME}
					errorFallback="flag"
					fallback={
						<MenuLayout isWide>
							<Section isScrollable>
								<MenuSkeletonContent testIdPrefix={testIdPrefix} />
							</Section>
							<Section hasSeparator>
								<MenuSkeletonFooter testIdPrefix={testIdPrefix} />
							</Section>
						</MenuLayout>
					}
					runtimeProps={{ testIdPrefix, titleId }}
				/>
			) : (
				<Menu testIdPrefix={testIdPrefix} titleId={titleId} />
			),
		[entryPointReferenceSubject, testIdPrefix, titleId],
	);

	// remove when cleaning up fg: blu-3339-nav4-topnav-settings-entrypoint
	if (isAnonymous) return null;

	if (__SERVER__) return <Trigger aria-expanded={false} aria-haspopup />;

	return (
		<Popup
			titleId={titleId}
			isOpen={isOpen}
			content={content}
			onClose={closeMenu}
			placement="bottom-end"
			messageId="atlassian-navigation.ui.settings.popup"
			messageType="transactional"
			trigger={Trigger}
			label={formatMessage(messages.settings)}
			shouldRenderToParent
		/>
	);
}
