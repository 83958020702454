import React, { Fragment } from 'react';

import differenceInMinutes from 'date-fns/differenceInMinutes';
import formatISO from 'date-fns/formatISO';
import isValidDate from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import subTime from 'date-fns/sub';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { type IntlShape } from 'react-intl-next';
import { type Query } from 'react-resource-router';

import { Text } from '@atlaskit/primitives/compiled';
import { type OptionsType, type OptionType } from '@atlaskit/select';
import { assert, AssertionError } from '@atlassian/eoc-common';

import { type DateTimeValue, type EventCategoriesGroupType, TimeRadioOptions } from '../types';
import { RANGE_OPTIONS } from '../ui/date-time-inputs/time-input/constants';
import { type TimeUnit } from '../ui/date-time-inputs/time-input/types';

import { DETAIL_ITEM_BASE_WIDTH, NANOSECONDS_IN_MILLISECOND } from './constants';
import messages from './messages';
import type { LogsRequestParams, LogsResponseType, TimeDifference } from './types';

// jira-frontend/src/packages/jql-builder-basic-datetime/src/ui/utils/time-functions.tsx
const isNumeric = (value: string) => /^\d+$/.test(value);

// jira-frontend/src/packages/jql-builder-basic-datetime/src/ui/utils/time-functions.tsx
export const isValidTimePeriodFormat = (timeDigits: string, timeUnit: TimeUnit): boolean => {
	return (
		timeDigits === '' ||
		(timeDigits !== undefined &&
			isNumeric(timeDigits) &&
			parseInt(timeDigits, 10) > -1 &&
			RANGE_OPTIONS.includes(timeUnit))
	);
};

// jira-frontend/src/packages/jql-builder-basic-datetime/src/ui/utils/between-config.tsx
export const getBetweenErrorMessage = (intl: IntlShape, value?: DateTimeValue) => {
	if (!value?.startDate && !value?.endDate) {
		return intl.formatMessage(messages.validationMessageAtLeastOneValue);
	}

	if (value?.startDate && value?.endDate && new Date(value?.startDate) > new Date(value?.endDate)) {
		return intl.formatMessage(messages.validationMessageBeforeGreaterThanAfter);
	}

	if (
		value &&
		((value?.startDate !== '' && !isValidDate(new Date(value?.startDate))) ||
			(value?.endDate !== '' && !isValidDate(new Date(value?.endDate))))
	) {
		return intl.formatMessage(messages.validationMessageInvalidDateFormat);
	}

	return undefined;
};

export const isValidBetweenInput = (value?: DateTimeValue) => {
	if (!value?.startDate && !value?.endDate) {
		return false;
	}

	if (value?.startDate && value?.endDate && new Date(value?.startDate) > new Date(value?.endDate)) {
		return false;
	}

	if (
		value &&
		((value?.startDate !== '' && !isValidDate(new Date(value?.startDate))) ||
			(value?.endDate !== '' && !isValidDate(new Date(value?.endDate))))
	) {
		return false;
	}

	return true;
};

export const isInvalidDateValue = (value: string) => {
	return !isValidDate(parseISO(value)) && value !== '';
};

export const optionQueryConverter = (value: OptionsType): string => {
	return value.map((event) => event.value).join(',');
};

export const checkQueryIncludesValue = (
	query: string | undefined,
	value: string | number,
): boolean => {
	return query?.includes(value.toString()) ?? false;
};

export const parseGroupOptionQuery = (
	query: string | undefined,
	options: EventCategoriesGroupType,
): OptionsType => {
	if (!query) {
		return [];
	}
	const labels: OptionType[] = [];

	options.map((option) => {
		option.options.map((item) => labels.push(item));
	});

	return labels.filter(({ value }) => checkQueryIncludesValue(query, value));
};

export const parseOptionQuery = (query: string | undefined, options: OptionsType): OptionType[] => {
	if (!query) {
		return [];
	}

	return options.filter((label) => query.includes(label.value.toString()));
};

export const isTimeUnit = (unit: any): unit is TimeUnit => RANGE_OPTIONS.includes(unit);

export const collectQueryParamsFromOptions = (options: OptionsType): string => {
	return options.map((option) => option.value).join(',');
};

const isTimeQueryParamsValid = (params: URLSearchParams) => {
	if (params.has('startTimestamp') && params.has('endTimestamp')) {
		return true;
	} else if (params.has('fromUnit') && params.has('fromValue')) {
		return true;
	}
	return false;
};

export const calculateTimeBetween = (params: URLSearchParams) => {
	const now = new Date();

	if (
		!params.get('startTimestamp') &&
		!params.get('endTimestamp') &&
		params.get('fromUnit') &&
		params.get('fromValue')
	) {
		const parsedFromValue = parseInt(params.get('fromValue') ?? '1');

		switch (params.get('fromUnit')) {
			case 'HOUR':
				params.set('endTimestamp', (now.getTime() * NANOSECONDS_IN_MILLISECOND).toString());
				params.set(
					'startTimestamp',
					(
						subTime(now, {
							hours: parsedFromValue,
						}).getTime() * NANOSECONDS_IN_MILLISECOND
					).toString(),
				);
				break;
			case 'DAY':
				params.set('endTimestamp', (now.getTime() * NANOSECONDS_IN_MILLISECOND).toString());
				params.set(
					'startTimestamp',
					(
						subTime(now, {
							days: parsedFromValue,
						}).getTime() * NANOSECONDS_IN_MILLISECOND
					).toString(),
				);
				break;
			case 'WEEK':
				params.set('endTimestamp', (now.getTime() * NANOSECONDS_IN_MILLISECOND).toString());
				params.set(
					'startTimestamp',
					(
						subTime(now, {
							weeks: parsedFromValue,
						}).getTime() * NANOSECONDS_IN_MILLISECOND
					).toString(),
				);
				break;
			case 'MONTH':
				params.set('endTimestamp', (now.getTime() * NANOSECONDS_IN_MILLISECOND).toString());
				params.set(
					'startTimestamp',
					(
						subTime(now, {
							months: parsedFromValue,
						}).getTime() * NANOSECONDS_IN_MILLISECOND
					).toString(),
				);
				break;
		}

		params.delete('fromUnit');
		params.delete('fromValue');
	}
	return params;
};

export const generateLogParams = (params: LogsRequestParams): URLSearchParams => {
	const urlParams = new URLSearchParams();

	Object.entries(params || {}).forEach(([key, value]) => {
		if (value) {
			urlParams.append(key, decodeURI(value));
		}
	});

	if (!isTimeQueryParamsValid(urlParams)) {
		// Remove time parameters if exists since they were not matched.
		urlParams.delete('fromUnit');
		urlParams.delete('fromValue');
		urlParams.delete('startTimestamp');
		urlParams.delete('endTimestamp');

		urlParams.append('fromUnit', 'DAY');
		urlParams.append('fromValue', '1');
	}

	return calculateTimeBetween(urlParams);
};

const calculateTimeDifference: (startDate: Date, endDate: Date) => TimeDifference = (
	startDate,
	endDate,
) => {
	const minutesDiff = differenceInMinutes(endDate, startDate);

	if (minutesDiff < 60) {
		return {
			hours: 1,
			days: 0,
			weeks: 0,
			months: 0,
			years: 0,
		};
	}

	const hoursDiff = minutesDiff / 60;
	const daysDiff = minutesDiff / 60 / 24;
	const weeksDiff = minutesDiff / 60 / 24 / 7;
	const monthsDiff = minutesDiff / 60 / 24 / 7 / 4;

	return {
		hours: hoursDiff > 0 ? Math.floor(hoursDiff) : 0,
		days: daysDiff > 0 ? Math.floor(daysDiff) : 0,
		weeks: weeksDiff > 0 ? Math.floor(weeksDiff) : 0,
		months: monthsDiff > 0 ? Math.floor(monthsDiff) : 0,
	};
};

export const fromUnitConverter = (
	startDate: string,
	endDate: string,
): {
	timeUnit: TimeUnit;
	timeDigits: string;
} => {
	const parsedStartDate = parseISO(startDate);
	const parsedEndDate = parseISO(endDate);

	const timeDifference = calculateTimeDifference(parsedStartDate, parsedEndDate);

	if (timeDifference.months > 0) {
		return {
			timeUnit: 'MONTH',
			timeDigits: timeDifference.months.toString(),
		};
	} else if (timeDifference.weeks > 0) {
		return {
			timeUnit: 'WEEK',
			timeDigits: timeDifference.weeks.toString(),
		};
	} else if (timeDifference.days > 0) {
		return {
			timeUnit: 'DAY',
			timeDigits: timeDifference.days.toString(),
		};
	} else {
		return {
			timeUnit: 'HOUR',
			timeDigits: timeDifference.hours.toString(),
		};
	}
};

export const createBaseQuery = (query: Query): Query => {
	let searchQuery = {};

	if (query.searchString) {
		searchQuery = {
			...searchQuery,
			searchString: query.searchString,
		};
	}

	if (query.startTimestamp && query.endTimestamp) {
		searchQuery = {
			...searchQuery,
			startTimestamp: query.startTimestamp,
			endTimestamp: query.endTimestamp,
		};
	}

	if (query.timeType) {
		searchQuery = {
			...searchQuery,
			timeType: query.timeType,
		};
	}

	if (query.eventCategories) {
		searchQuery = {
			...searchQuery,
			eventCategories: query.eventCategories,
		};
	}

	if (query.levels) {
		searchQuery = {
			...searchQuery,
			levels: query.levels,
		};
	}

	if (query.size) {
		searchQuery = {
			...searchQuery,
			size: query.size,
		};
	}

	return searchQuery;
};

/**
 * Calculate new start and end timestamp based on the given start and end date with using our common functions.
 * @param startTimestamp {string}
 * @param endTimestamp {string}
 * @returns {startTimestamp: string, endTimestamp: string}
 */
export function calculateNewStartAndEndTimestamp(
	startTimestamp: string,
	endTimestamp: string,
): {
	startTimestamp: string;
	endTimestamp: string;
} {
	const startDateIntValue = parseInt(startTimestamp) / NANOSECONDS_IN_MILLISECOND;
	const endDateIntValue = parseInt(endTimestamp) / NANOSECONDS_IN_MILLISECOND;

	const { timeDigits: fromValue, timeUnit: fromUnit } = fromUnitConverter(
		formatISO(startDateIntValue),
		formatISO(endDateIntValue),
	);
	const params = calculateTimeBetween(
		new URLSearchParams({
			fromValue,
			fromUnit,
		}),
	);
	const startTimestampParams = params.get('startTimestamp');
	const endTimestampParams = params.get('endTimestamp');

	assert(
		!isNil(startTimestampParams) && !isNil(endTimestampParams),
		new AssertionError(
			'calculateNewStartAndEndTimestamp',
			'startTimestamp and endTimestamp should not be null',
		),
	);

	return {
		startTimestamp: startTimestampParams,
		endTimestamp: endTimestampParams,
	};
}

/**
 * Checking if the details section is empty or not.
 * Decides whether detail section is empty based on the importantField, raw and isImportantFieldEmpty values.
 * @param importantField {string | null | undefined}
 * @param raw {string | null | undefined}
 * @param isImportantFieldEmpty {boolean | null | undefined}
 * @returns {boolean}
 */
export const isDetailsSectionEmpty = (
	importantField?: string | null,
	raw?: string | null,
	isImportantFieldEmpty?: boolean | null,
): boolean => {
	if (!importantField || !raw) {
		return true;
	}
	const parsedImportantField = JSON.parse(importantField);
	const parsedRaw = JSON.parse(raw);

	return (isEmpty(parsedImportantField) || isImportantFieldEmpty === true) && isEmpty(parsedRaw);
};

export const generateParamsForOneMonthInterval = (): {
	dateTimeValue: DateTimeValue;
	timeType: TimeRadioOptions.WITHIN_THE_LAST;
} => {
	const params = calculateTimeBetween(new URLSearchParams({ fromUnit: 'MONTH', fromValue: '1' }));
	const startDate = params.get('startTimestamp');
	const endDate = params.get('endTimestamp');

	assert(
		!!startDate && !!endDate,
		new AssertionError(
			'generateParamsForOneMonthInterval',
			'startTimestamp and endDate should not be null',
		),
	);

	return {
		dateTimeValue: {
			startDate,
			endDate,
		},
		timeType: TimeRadioOptions.WITHIN_THE_LAST,
	};
};

export const calculateWidthAccordingToLevel = (level: number) => {
	const BASE_OFFSET = 18;

	return `${DETAIL_ITEM_BASE_WIDTH - level * BASE_OFFSET}px`;
};

/**
 * when we try to get source param in url, since it includes some query params, new URLSearchParams function couldn't handle properly these query params.
 * In this function, we are splitting string from source and get whole string as unique.
 */
export const getSourceStringFromUrl = (search: string) => {
	const splittedSearch = search.split(/source=/i);

	if (splittedSearch.length > 1) {
		return decodeURIComponent(splittedSearch[1]);
	}

	return '';
};

export function makeMarkup(fragment: Node, key?: string) {
	const { nodeName, nodeType, childNodes, textContent } = fragment;

	if (nodeType === Node.TEXT_NODE) {
		return <Fragment key={key}>{textContent}</Fragment>;
	}

	const children: JSX.Element[] = [];
	childNodes.forEach((childNode, i) => {
		const markup = makeMarkup(childNode, String(i));
		if (markup) {
			children.push(markup);
		}
	});

	switch (nodeName) {
		case 'B':
			return <b key={key}>{children}</b>;
		case 'I':
			return <i key={key}>{children}</i>;
		case 'STRONG':
			return (
				<Text weight="bold" key={key}>
					{children}
				</Text>
			);
		case 'EM':
			return (
				<Text as="em" key={key}>
					{children}
				</Text>
			);
		case 'CODE':
			return <code key={key}>{children}</code>;
	}

	if (children.length === 1) {
		return <Fragment key={key}>{children[0]}</Fragment>;
	}
	if (children.length) {
		return <span key={key}>{children}</span>;
	}

	return null;
}
export const postProcessLogsSearch = (
	urlParams: URLSearchParams,
	response: LogsResponseType,
): LogsResponseType => {
	if (urlParams.get('startTimestamp') && urlParams.get('endTimestamp')) {
		return {
			...response,
			data: {
				...response.data,
				pagination: {
					...response.data.pagination,
					calcStartDate: urlParams.get('startTimestamp'),
					calcEndDate: urlParams.get('endTimestamp'),
				},
			},
		};
	}

	return response;
};

export type { LogsResponseType } from './types';
