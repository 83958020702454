import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';

// See https://hello.atlassian.net/wiki/spaces/JDP/pages/5046504913/WIP+LDR+Rollout+concurrent+at+1+based+on+nav+despite+perf+regressions+in+Internal+Production+Dogfooding

// There is a bit of complexity here and it is used in multiple places, so we are disabling the below rule
// eslint-disable-next-line jira/ff/inline-usage
export const getIsConcurrentEnabled = () => {
	if (fg('jira-concurrent-enabled-based-on-nav')) {
		if (getWillShowNav3()) {
			return fg('jira-concurrent-enabled-for-nav-3-gate');
		}
		return fg('jira-concurrent-enabled-for-nav-4-gate');
	}
	return fg('jfp-magma-concurrent-root-jira-spa');
};
