/**
 * @generated SignedSource<<283ed4f377b201f98d14321a82b83de5>>
 * @relayHash 02e143cfdd95667a01d51463a678a48c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 19a45252d433996c267e9e919b0907677c72d7ab779a1d8cf1a338ce162c39ae

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseConversationsQuery } from './srcVirtualAgentBrowseConversationsQuery.graphql';

import conversationFilterEnabled_provider from '@atlassian/jira-relay-provider/src/is-conversation-filter-enabled.relayprovider';
import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseConversationsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "19a45252d433996c267e9e919b0907677c72d7ab779a1d8cf1a338ce162c39ae",
    "metadata": {},
    "name": "srcVirtualAgentBrowseConversationsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisconversationfilterenabledrelayprovider": conversationFilterEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;
