import { resources as alertListResources } from '@atlassian/eoc-alert-list/resources';
import { resources as groupDetailResources } from '@atlassian/eoc-group-detail/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocTeamGroupDetailRoute } from '@atlassian/jira-router-routes-eoc-group-detail-routes/src/eocTeamGroupDetailRoute.tsx';
import GroupList, { LazyGroupDetail } from './ui/spa/eoc/group-detail/index.tsx';

export const eocTeamGroupDetailRouteEntry = createEntry(eocTeamGroupDetailRoute, () => ({
	group: 'eoc',
	component: GroupList,
	layout: opsTeamLayout,
	resources: [
		...getNavigationResources(),
		...teamDashboardResources(),
		...groupDetailResources(),
		getConsolidationStateResource(),
		...alertListResources(),
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
	},
	forPaint: [LazyAtlassianNavigation, LazyGroupDetail, LazyTeamDashboardSidebar],
}));
