import React from 'react';
import type SoftwareOnboardingPageType from '@atlassian/jira-spa-apps-software-onboarding/src/index.server.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazySoftwareOnboardingPage = lazyForPaint<typeof SoftwareOnboardingPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-software-onboarding", jiraSpaEntry: "async-software-onboarding" */ '@atlassian/jira-spa-apps-software-onboarding'
		),
	),
);

export const SoftwareOnboardingPage = () => (
	<LazyPage
		Page={LazySoftwareOnboardingPage}
		pageId="software-onboarding"
		shouldShowSpinner={false}
	/>
);
