import React, { createContext, useContext, type PropsWithChildren } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { JqlDebuggerJqlBuilderContextProviderProps } from '../../common/types.tsx';
import { JqlDebuggerJqlBuilderContextProvider as JqlDebuggerJqlBuilderContextProviderNew } from '../debugger-panel/context.tsx';

const jqlDebuggerContext = createContext<JqlDebuggerJqlBuilderContextProviderProps>({
	onSearch: () => undefined,
	query: '',
	jqlMessages: [],
});

/**
 * Provide additional context about the JQL Builder context to the JQL Debugger
 *
 * This additional context exists primarily because we need to pass some props from JQL builder to the customComponent override of ErrorMessage
 * Since ErrorMessage component (jql-debugger trigger-button) is passed as a component, we cannot pass the additional props as regular props
 * Hence we wrap the parent with this provider and pass the props as context
 */
const JqlDebuggerJqlBuilderContextProviderOld = (
	props: PropsWithChildren<JqlDebuggerJqlBuilderContextProviderProps>,
) => {
	const { jqlMessages, query, onSearch } = props;
	return (
		<jqlDebuggerContext.Provider value={{ query, jqlMessages, onSearch }}>
			{props.children}
		</jqlDebuggerContext.Provider>
	);
};

export const JqlDebuggerJqlBuilderContextProvider = componentWithFG(
	'gravityai-2283-jql-debugger-rll-to-entrypoints',
	JqlDebuggerJqlBuilderContextProviderNew,
	JqlDebuggerJqlBuilderContextProviderOld,
);

export const useJqlDebuggerAdditionalContext = () => useContext(jqlDebuggerContext);
