import { useCallback } from 'react';

import { BFFConsumerEnum } from '../../constants';
import { isLocalHost } from '../utils/request';

import { useCache } from './useCache';
import { useConsumer } from './useConsumer';

type buildURLProps = {
	requestedAPSKeys: string[];
	baseUrl?: string;
};

type BuildProductShortcutURLProps = {
	requestedAPSKeys: string[];
	baseUrl?: string;
};

enum BFFEndpointsEnum {
	AVAILABLE_PRODUCTS = '/gateway/api/app-switcher/api/available-products',
}

export function useURLBuilder() {
	const { consumer } = useConsumer();
	const { items: cachedItems } = useCache();

	const buildProductShortcutURL = useCallback(
		({ requestedAPSKeys, baseUrl }: BuildProductShortcutURLProps) => {
			baseUrl = baseUrl ?? BFFEndpointsEnum.AVAILABLE_PRODUCTS;

			const availableKVPairs = requestedAPSKeys
				.filter(
					(itemKey) =>
						!cachedItems.some((cachedItem) =>
							cachedItem.availableProducts.some((cap) => cap.productType === itemKey),
						),
				)
				.map((itemKey) => ['products', itemKey]);

			// No point in building a URL if there are no items to fetch and the cache is already populated
			if (!availableKVPairs.length && cachedItems.length > 0) {
				return '';
			}

			// Exclude items that have already been cached
			// and convert to URL query string
			const queryValues = new URLSearchParams(availableKVPairs);

			return `${getHomeUrl()}${baseUrl}?${queryValues}`;
		},
		[cachedItems],
	);

	const buildURL = useCallback(
		({ requestedAPSKeys, baseUrl }: buildURLProps) => {
			let url = '';

			if (baseUrl) {
				url = `${getHomeUrl()}${baseUrl}`;
			}

			// Consumer specific URL building implementations happen here
			switch (consumer) {
				case BFFConsumerEnum.GLOBAL_SHORTCUTS:
					url = buildProductShortcutURL({ requestedAPSKeys });
					break;
			}

			if (!url) {
				return url;
			}

			// Append the consumer to the URL
			const formattedUrl = new URL(url);
			const updatedParams = new URLSearchParams(formattedUrl.search);

			if (consumer) {
				updatedParams.append('consumer', consumer);
			}

			return `${formattedUrl.origin}${formattedUrl.pathname}?${updatedParams}`;
		},
		[buildProductShortcutURL, consumer],
	);

	const getHomeUrl = (): string | undefined => {
		if (isLocalHost()) {
			return 'https://home.stg.atlassian.com';
		}
		return new URL(window.location.href).origin;
	};

	return {
		buildURL,
		buildProductShortcutURL,
	};
}
