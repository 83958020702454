// Users who's conversations should be excluded from the list

import type { Conversation } from './types';

export const EXCLUDED_AUTHORS = [
	// Staging test bot
	'ari:cloud:identity::user/61c2c7a390cfd2007154ede7',
	// Prod test bot - 'cst-e2e-test-bot
	'ari:cloud:identity::user/61bc6c272f24e70068bf462a',
];

/**
 * Determine if the conversation owner is not in the excluded authors list
 */
export const isNotExcludedOwner = (c: Conversation): boolean => {
	return !EXCLUDED_AUTHORS.includes(c.owner);
};
