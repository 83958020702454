import difference from 'lodash/difference';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';
import remoteGet from '../remote-get/index.tsx';

export const get =
	(projectId: ProjectId, properties: string[]): Action<State> =>
	({ getState, setState }) => {
		const currentProperties = getState()[projectId] || {};

		const missingProperties = difference(properties, keys(currentProperties));

		missingProperties.length &&
			remoteGet({
				baseUrl: '',
				projectId,
				properties: missingProperties,
			}).then((fetchedProperties) => {
				const latestProperties = getState()[projectId] || {};
				setState({
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					[projectId as string]: {
						...latestProperties,
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						...missingProperties.reduce<Record<string, any>>((result, name) => {
							const reducedResult = result;
							const fetchedProperty = fetchedProperties[name];
							// `undefined` inside state means that property was never requested.
							// If value returned for the property is `undefined`, we will set it to `null`,
							// to differentiate it from the unrequested state. Otherwise, returned value will be set.
							reducedResult[name] = isUndefined(fetchedProperty) ? null : fetchedProperty;
							return reducedResult;
						}, {}),
					},
				});
			});
	};
