import { getMeta } from '@atlassian/eoc-fetch';

export const withAIOpsDomainPrefix = (input: string): string => {
	const cloudId = getMeta('ajs-cloud-id');
	if (cloudId) {
		return `/gateway/api/jira/aiops/cloudId/${cloudId}/api` + input;
	}

	return input;
};
