import React from 'react';
import { graphql, usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import { YourWork } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-your-work/src/ui/your-work/index.tsx';
import type { L1MenuItemsQuery } from '@atlassian/jira-relay/src/__generated__/L1MenuItemsQuery.graphql';
import { ConfigurableMenuItems } from './configurable-menu-items/ConfigurableMenuItems.tsx';

export type L1NavMenuItemsProps = {
	queryReference: PreloadedQuery<L1MenuItemsQuery>;
};

export function L1MenuItems({ queryReference }: L1NavMenuItemsProps) {
	// We define a query which requires a few variables: $cloudId and others
	// Looking at https://relay.dev/docs/guided-tour/rendering/variables/, we can either:
	// a) pass these query-level variables down to the fragments as arguments, OR,
	// b) allow the fragments to reference the query-level variables directly.
	// We've chosen b) for now, as it would be a hassle to have to specify 8+ variables
	// at every level of the component tree.
	const l1MenuData = usePreloadedQuery<L1MenuItemsQuery>(
		graphql`
			query L1MenuItemsQuery(
				$cloudId: ID!
				$isAppsExpanded: Boolean = false
				$isFiltersExpanded: Boolean = false
				$isPlansExpanded: Boolean = false
				$isProjectsExpanded: Boolean = false
				$isOperationsExpanded: Boolean = false
				$isOverviewsExpanded: Boolean = false
				$entityAriProjects: ID!
				$ownerAri: ID
			) {
				...ConfigurableMenuItems
			}
		`,
		queryReference,
	);

	return (
		<>
			<YourWork />
			<ConfigurableMenuItems menuData={l1MenuData} />
		</>
	);
}
