import React from 'react';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import IconBacklog from '@atlaskit/icon/core/migration/backlog';
import BoardIcon from '@atlaskit/icon/core/migration/board';
import ListIcon from '@atlaskit/icon/core/migration/list-bulleted--bullet-list';
import IconIssues from '@atlaskit/icon/core/migration/issues';
import PageIcon from '@atlaskit/icon/core/migration/page';
import RoadmapIcon from '@atlaskit/icon/core/migration/roadmap';
import type { AnalyticsPayload } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { FeatureMap } from './types.tsx';

export const commonErrorAnalyticsMeta: Partial<AnalyticsPayload['meta']> = {
	packageName: 'jiraTmpFeatureToggle',
	teamName: 'jlove-makkuro',
};

export const MANAGE_FEATURE_MENU_ITEM_TEST_ID = 'manage-feature-menu-item';

export const listFeature: FeatureMap = {
	'jsw.agility.list_view': {
		descriptionKey: 'listDescription',
		icon: <ListIcon spacing="spacious" label="" />,
		statusKey: 'hasList',
		order: 35,
		redirectOn: ['software-list'],
	},
};
export const featureMap: FeatureMap = {
	'jsw.agility.simple-roadmap': {
		descriptionKey: 'timelineDescription',
		icon: <RoadmapIcon spacing="spacious" label="" />,
		statusKey: 'hasRoadmap',
		order: 10,
		redirectOn: ['software-timeline', 'software-roadmap'],
	},
	'jsw.agility.backlog': {
		descriptionKey: 'backlogDescription',
		icon: <IconBacklog spacing="spacious" label="" />,
		statusKey: 'hasBacklog',
		order: 20,
		redirectOn: ['software-backlog'],
	},
	'jsw.agility.board': {
		descriptionKey: 'boardDescription',
		icon: <BoardIcon spacing="spacious" label="" />,
		statusKey: 'hasBoard',
		order: 30,
	},
	'jsw.agility.issue_navigator': {
		descriptionKey: 'issueNavigatorDescription',
		icon: <IconIssues spacing="spacious" label="" />,
		statusKey: 'hasSIN',
		order: 40,
		redirectOn: ['software-issue-navigator', 'issue'],
	},
	'jsw.agility.sprints': {
		descriptionKey: 'releasesDescription',
		icon: <></>,
		statusKey: 'hasSprint',
		order: 50,
		isHidden: true,
	},
}; // satisfies Partial<Record<ToggleableFeatureKey, FeatureProperties>>

type GetFeatureMap = {
	isSimplifyNavExperimentEnabled?: boolean;
	isListViewEnabled?: boolean;
};

export const getFeatureMap = memoizeOne(
	({ isSimplifyNavExperimentEnabled, isListViewEnabled }: GetFeatureMap = {}): FeatureMap => ({
		'jsw.agility.simple-roadmap': {
			descriptionKey: 'timelineDescription',
			icon: <RoadmapIcon spacing="spacious" label="" />,
			statusKey: 'hasRoadmap',
			order: 10,
			redirectOn: ['software-timeline', 'software-roadmap'],
		},
		'jsw.agility.backlog': {
			descriptionKey: 'backlogDescription',
			icon: <IconBacklog spacing="spacious" label="" />,
			statusKey: 'hasBacklog',
			order: 20,
			redirectOn: ['software-backlog'],
		},
		'jsw.agility.board': {
			descriptionKey: 'boardDescription',
			icon: <BoardIcon spacing="spacious" label="" />,
			statusKey: 'hasBoard',
			order: 30,
		},
		...(!isSimplifyNavExperimentEnabled && {
			'jsw.agility.issue_navigator': {
				descriptionKey: 'issueNavigatorDescription',
				icon: <IconIssues spacing="spacious" label="" />,
				statusKey: 'hasSIN',
				order: 40,
				redirectOn: ['software-issue-navigator', 'issue'],
			},
		}),
		'jsw.agility.sprints': {
			descriptionKey: 'releasesDescription',
			icon: <></>,
			statusKey: 'hasSprint',
			order: 50,
			isHidden: true,
		},
		'jsw.agility.pages': {
			descriptionKey: 'projectPagesDescription',
			icon: <PageIcon spacing="spacious" label="" />,
			statusKey: 'hasPages',
			order: 60,
			redirectOn: ['project-pages'],
		},
		...(isListViewEnabled && listFeature),
	}),
	isEqual,
); // satisfies Partial<Record<ToggleableFeatureKey, FeatureProperties>>;

export const ERROR_FLAG_ID = 'feature-toggle-error-flag';
