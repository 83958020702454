import React from 'react';
import { Dashboards } from './dashboards/index.tsx';
import { IssueViewModal } from './issue-view-modal/index.tsx';
import { KeyboardShortcuts } from './keyboard-shortcuts/index.tsx';
import { Projects } from './projects/index.tsx';

const Integrations = () => {
	if (!__SERVER__) {
		return (
			<>
				<KeyboardShortcuts />
				<IssueViewModal />
				<Projects />
				<Dashboards />
			</>
		);
	}
	return null;
};

export { Integrations };
