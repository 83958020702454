/**
 * @generated SignedSource<<8b35258409fd95feac3a7c2d1b173e38>>
 * @relayHash 2e1cdbc7b09ec37e9cc0993464d7aadd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 28ba20c081cda028e2d1967769b33eb171517e2ac4ff9ff74300564656ea5d9c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraJqlViewContext = "JPD_ROADMAPS" | "JSW_PLANS" | "JSW_SUMMARY_PAGE" | "JWM" | "SHADOW_REQUEST" | "%future added value";
export type hydrateJqlBuilderQuery$variables = {
  accountId: string;
  cloudId: string;
  filterAri: string;
  includeJira: boolean;
  includeUser: boolean;
  isFilter: boolean;
  jql: string;
  viewContext?: JiraJqlViewContext | null | undefined;
};
export type hydrateJqlBuilderQuery$data = {
  readonly jira?: {
    readonly jqlBuilder: {
      readonly hydrateJqlQuery?: {
        readonly __typename: "JiraJqlHydratedQuery";
        readonly jql: string | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
      readonly hydrateJqlQueryForFilter?: {
        readonly __typename: "JiraJqlHydratedQuery";
        readonly jql: string | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"jqlEditor_jqlBuilderBasic_JQLEditorUI" | "ui_jqlBuilder_JQLBuilderUI_hydrationQueryData" | "useHydratedValues" | "useHydratedValues_cascadeSelectPicker" | "useQuery">;
};
export type hydrateJqlBuilderQuery = {
  response: hydrateJqlBuilderQuery$data;
  variables: hydrateJqlBuilderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeJira"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeUser"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isFilter"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jql"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewContext"
},
v8 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v9 = {
  "kind": "Variable",
  "name": "viewContext",
  "variableName": "viewContext"
},
v10 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "jql"
  },
  (v9/*: any*/)
],
v11 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v12 = {
  "kind": "ScalarField",
  "name": "jql"
},
v13 = [
  (v11/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v12/*: any*/)
    ],
    "type": "JiraJqlHydratedQuery"
  }
],
v14 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "filterAri"
  },
  (v9/*: any*/)
],
v15 = {
  "kind": "ScalarField",
  "name": "id"
},
v16 = [
  (v11/*: any*/),
  {
    "kind": "ScalarField",
    "name": "picture"
  },
  (v15/*: any*/)
],
v17 = {
  "kind": "ScalarField",
  "name": "jqlTerm"
},
v18 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v19 = {
  "kind": "ScalarField",
  "name": "key"
},
v20 = [
  {
    "concreteType": "JiraStatusCategory",
    "kind": "LinkedField",
    "name": "statusCategory",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "colorName"
      },
      (v15/*: any*/)
    ]
  }
],
v21 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v22 = [
  (v11/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v12/*: any*/),
      {
        "kind": "LinkedField",
        "name": "fields",
        "plural": true,
        "selections": [
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v17/*: any*/),
              {
                "concreteType": "JiraJqlField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  {
                    "concreteType": "JiraJqlSearchTemplate",
                    "kind": "LinkedField",
                    "name": "searchTemplate",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "shouldShowInContext"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "description"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "operators"
                  },
                  {
                    "concreteType": "JiraFieldType",
                    "kind": "LinkedField",
                    "name": "fieldType",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/)
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "values",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v17/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "values",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraProject",
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  (v19/*: any*/),
                                  {
                                    "concreteType": "JiraAvatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "small"
                                      }
                                    ]
                                  },
                                  (v15/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlProjectFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v20/*: any*/),
                            "type": "JiraJqlStatusFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v20/*: any*/),
                            "type": "JiraJqlStatusCategoryFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueTypes",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraAvatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "xsmall"
                                      }
                                    ]
                                  },
                                  (v15/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlIssueTypeFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": (v16/*: any*/)
                              }
                            ],
                            "type": "JiraJqlUserFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "iconUrl"
                                  },
                                  (v15/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlPriorityFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "issue",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fields",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v11/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "text"
                                                  }
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              (v15/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  (v15/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlIssueFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraSprint",
                                "kind": "LinkedField",
                                "name": "sprint",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "state"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "boardName"
                                  },
                                  (v15/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlSprintFieldValue"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v21/*: any*/),
                              {
                                "concreteType": "JiraJqlCascadingOptionFieldValue",
                                "kind": "LinkedField",
                                "name": "parentOption",
                                "plural": false,
                                "selections": [
                                  (v18/*: any*/),
                                  (v17/*: any*/),
                                  (v21/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlCascadingOptionFieldValue"
                          }
                        ]
                      }
                    ],
                    "type": "JiraJqlQueryHydratedValue"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v17/*: any*/),
                      {
                        "concreteType": "QueryError",
                        "kind": "LinkedField",
                        "name": "error",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraJqlQueryHydratedError"
                  }
                ]
              }
            ],
            "type": "JiraJqlQueryHydratedField"
          }
        ]
      }
    ],
    "type": "JiraJqlHydratedQuery"
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "message"
      }
    ],
    "type": "QueryError"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "hydrateJqlBuilderQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ui_jqlBuilder_JQLBuilderUI_hydrationQueryData"
      },
      {
        "kind": "FragmentSpread",
        "name": "useHydratedValues"
      },
      {
        "kind": "FragmentSpread",
        "name": "jqlEditor_jqlBuilderBasic_JQLEditorUI"
      },
      {
        "kind": "FragmentSpread",
        "name": "useQuery"
      },
      {
        "kind": "FragmentSpread",
        "name": "useHydratedValues_cascadeSelectPicker"
      },
      {
        "condition": "includeJira",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v8/*: any*/),
                "concreteType": "JiraJqlBuilder",
                "kind": "LinkedField",
                "name": "jqlBuilder",
                "plural": false,
                "selections": [
                  {
                    "condition": "isFilter",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "args": (v10/*: any*/),
                        "kind": "LinkedField",
                        "name": "hydrateJqlQuery",
                        "plural": false,
                        "selections": (v13/*: any*/)
                      }
                    ]
                  },
                  {
                    "condition": "isFilter",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "args": (v14/*: any*/),
                        "kind": "LinkedField",
                        "name": "hydrateJqlQueryForFilter",
                        "plural": false,
                        "selections": (v13/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "hydrateJqlBuilderQuery",
    "selections": [
      {
        "condition": "includeUser",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              }
            ],
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v16/*: any*/)
          }
        ]
      },
      {
        "condition": "includeJira",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v8/*: any*/),
                "concreteType": "JiraJqlBuilder",
                "kind": "LinkedField",
                "name": "jqlBuilder",
                "plural": false,
                "selections": [
                  {
                    "condition": "isFilter",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "args": (v10/*: any*/),
                        "kind": "LinkedField",
                        "name": "hydrateJqlQuery",
                        "plural": false,
                        "selections": (v22/*: any*/)
                      }
                    ]
                  },
                  {
                    "condition": "isFilter",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "args": (v14/*: any*/),
                        "kind": "LinkedField",
                        "name": "hydrateJqlQueryForFilter",
                        "plural": false,
                        "selections": (v22/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "28ba20c081cda028e2d1967769b33eb171517e2ac4ff9ff74300564656ea5d9c",
    "metadata": {},
    "name": "hydrateJqlBuilderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1a3b28367e690225d2874708340ba3ff";

export default node;
