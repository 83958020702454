/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { CustomHeader } from '../custom-header/index.tsx';
import type { TitleHeaderProps } from './types.tsx';

const overrideStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-item-title]': {
		whiteSpace: 'normal',
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		lineHeight: 'initial',
	},
});

export const TitleHeader = ({ children }: TitleHeaderProps) => {
	return (
		<CustomHeader
			css={overrideStyles}
			testId="navigation-apps-sidebar-common.common.ui.title-header"
		>
			{children}
		</CustomHeader>
	);
};
