import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { eocChangeboardingLayout } from '@atlassian/jira-eoc-changeboarding-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { resources as unlockOperationResources } from '@atlassian/jira-router-resources-eoc-unlock-operations/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { unlockOperationsRoute } from '@atlassian/jira-router-routes-eoc-unlock-operations-routes/src/unlockOperationsRoute.tsx';
import UnlockOperations, { LazyUnlockOperations } from './ui/index.tsx';

export const unlockOperationsRouteEntry = createEntry(unlockOperationsRoute, () => ({
	group: 'eoc',
	layout: eocChangeboardingLayout,

	component: UnlockOperations,

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...unlockOperationResources(),
	],

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

	forPaint: [LazyAtlassianNavigation, LazyUnlockOperations],
}));
