import {
	type JiraTopBanner as MessagePipe,
	createClientPipeline,
	createServerPipeline,
} from '@post-office/placement-common';
import { type GenerateValidationFor } from '@post-office/validation-types';

import { type MessageTemplateContext } from '../../..';

export type HydrationResult = { readMoreLink: string };

export type VHydrationResult = GenerateValidationFor<HydrationResult>;

type Pipe = MessagePipe<{
	messageContext: MessageTemplateContext;
	hydrationResult: HydrationResult;
}>;

export const { createRender, createValidate } = createClientPipeline<Pipe>();
export const { createHydrate, createHydrateExample, createTransformMetadata } =
	createServerPipeline<Pipe>();
