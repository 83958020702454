import { resources as whoIsOnCallResources } from '@atlassian/eoc-who-is-on-call/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { eocChangeboardingLayout } from '@atlassian/jira-eoc-changeboarding-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { whoIsOnCallRoute } from '@atlassian/jira-router-routes-eoc-who-is-on-call-routes/src/whoIsOnCallRoute.tsx';
import { WhoIsOnCallComponent, LazyWhoIsOnCall } from './ui/WhoIsOnCallComponent.tsx';

const getWhoIsOnCallResources = () => whoIsOnCallResources;

export const whoIsOnCallRouteEntry = createEntry(whoIsOnCallRoute, () => ({
	group: 'eoc',
	layout: eocChangeboardingLayout,
	component: WhoIsOnCallComponent,

	resources: [
		...getNavigationResources(),
		getConsolidationStateResource(),
		...getWhoIsOnCallResources(),
	],

	forPaint: [LazyAtlassianNavigation, LazyWhoIsOnCall],
}));
