// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY {
	createIssue,
	searchOnCurrentPage,
	openQuickSearch, // TODO remove isCommandPaletteQuickSearchEnabled cleanup
	copyUrl,
	checkNotifications,
	openFilterPopup,
}
