import { DashboardBackgroundScripts } from '@atlassian/jira-background-scripts-layout/src/ui/dashboard/index.tsx';
import { DASHBOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { dashboardViewEmbedRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardViewEmbedRoute.tsx';
import { DashboardViewEmbedPage, dashboardResources, LazyDashboardViewEmbed } from './ui/index.tsx';

export const dashboardViewEmbedRouteEntry = createEntry(dashboardViewEmbedRoute, () => ({
	group: 'dashboard',
	component: DashboardViewEmbedPage,
	layout: createLayout({
		globalComponents: [DashboardBackgroundScripts],
		isChromeless: true,
	}),
	resources: [themePreferenceResource, ...dashboardResources()],
	forPaint: [LazyDashboardViewEmbed],
	meta: {
		capability: DASHBOARD,
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));
