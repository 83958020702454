import React from 'react';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	initEntryPointConfig as initPlatformEntryPointConfig,
	type PlaceholderComponentProps,
} from '@atlassian/entry-point-config';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';

const ModalErrorFallback = ({ error }: { error: Error }) => {
	return <ErrorFlagRenderer id="" error={error} />;
};

export function initEntryPointConfig() {
	initPlatformEntryPointConfig({
		PlaceholderComponent: ({ name, ...props }: PlaceholderComponentProps) => (
			<Placeholder name={name || ''} {...props} />
		),
		UFOSegmentComponent: UFOSegment,
		ModalErrorFallback,
	});
}
