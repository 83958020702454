import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { jiraSearchRoute } from '@atlassian/jira-router-routes-jira-search-routes/src/jiraSearchRoute.tsx';
import { JiraSearchRedirect } from './ui/redirect/index.tsx';
import { jiraSearchResource } from './ui/redirect/resources/index.tsx';

export const jiraSearchRouteEntry = createEntry(jiraSearchRoute, () => ({
	group: 'redirects',
	component: JiraSearchRedirect,
	isRedirect: true,
	layout: globalLayout,
	resources: [...getNavigationResources(), jiraSearchResource],
	forPaint: [LazyAtlassianNavigation],
}));
