import { RequestStatus } from '../../constants';

import packageContext from './package-context';

export type RequestCompletePayload<T> = {
	status: RequestStatus.COMPLETE;
	data: T;
};

export type RequestErrorPayload = {
	status: RequestStatus.ERROR;
	error: any;
	data: null;
};

/*
 * Returns true if not a production environment
 * This should be tested on a per-integration basis to ensure that we are
 * correctly identifying staging environments as different platforms have some
 * differences in how they identify said environments.
 */
export function isStaging() {
	const host = window.location.host;
	return (
		host.includes('localhost') || host.includes('.stg.atlassian') || host.includes('jira-dev.com')
	);
}

export function isLocalHost() {
	const host = window.location.host;
	return host.includes('localhost');
}

export async function fetchJSON<T>(
	url: string,
	init?: RequestInit,
): Promise<RequestCompletePayload<T> | RequestErrorPayload> {
	try {
		const response = await fetch(url, {
			...init,
			credentials: 'include',
			headers: {
				'Atl-BFF-Payload-Version': packageContext.packageVersion,
				...init?.headers,
			},
		});

		const jsonResponse = await response.json();

		return {
			status: RequestStatus.COMPLETE,
			data: jsonResponse,
		};
	} catch (e) {
		return {
			status: RequestStatus.ERROR,
			error: e,
			data: null,
		};
	}
}
