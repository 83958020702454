import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { issueNavigatorGlobalRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/issueNavigatorGlobalRoute.tsx';
import { GlobalIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/global-app.tsx';
import { NIN_NEW_ISSUE_NAVIGATOR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { getCommonGlobalNavigation } from './common/getCommonGlobalNavigation.tsx';
import { getCommonGlobalResources } from './common/getCommonGlobalResources.tsx';
import { getRouteReporting } from './common/getRouteReporting.tsx';
import {
	GinRedirect,
	LazyGlobalIssueNavigator,
	globalIssueNavigatorGinRedirectPageEntryPoint,
} from './ui/index.tsx';

/**
 * Global Issue Navigator Route Entry
 * Handles the older `/issues` route scheme
 */
export const issueNavigatorGlobalRouteEntry = createEntry(issueNavigatorGlobalRoute, () => ({
	group: 'issue-navigator',
	apdexIgnoreParams: ['issueKey'],
	meta: {
		capability: NIN_NEW_ISSUE_NAVIGATOR,
		reporting: getRouteReporting(),
	},
	// delete "component" when jira_list_gin_entrypoint is cleaned up
	component: componentWithCondition(
		() => fg('jira_list_gin_entrypoint'),
		() => null,
		GinRedirect,
	),
	skeleton: GlobalIssueNavigatorSkeleton,
	// delete "forPaint" when jira_list_gin_entrypoint is cleaned up
	forPaint: [LazyAtlassianNavigation, LazyGlobalIssueNavigator],
	ufoName: 'global-issue-navigator-jfe',
	navigation: getCommonGlobalNavigation(),
	resources: [...getCommonGlobalResources()],
	layout: globalLayout,
	// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
	// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
	shouldOptOutConcurrentMode:
		fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
	isHydrateRootEnabled: fg('nin-full-page-hydrate-root'),
	bifrost: {
		bundling: {
			packageName:
				'@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorGlobalRouteEntry.tsx',
			whichExportToUse: 'issueNavigatorGlobalRouteEntry',
		},
		enabled: true,
		manifestName: 'jira-spa-issue-navigator.json',
	},
	entryPoint: () =>
		fg('jira_list_gin_entrypoint') ? globalIssueNavigatorGinRedirectPageEntryPoint : undefined,
}));
