import type { AnalyticsEventPayload, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireJiraErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	fireTrackAnalytics,
	type AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import type { CrossJoinIntegraton } from '../../common/types.tsx';

export type CreateAnalyticsEvent = (payload: AnalyticsEventPayload) => UIAnalyticsEvent;

export const fireErrorAnalytics = (
	id: string,
	error: Error,
	attributes: AnalyticsAttributes = {},
) =>
	fireJiraErrorAnalytics({
		meta: {
			id,
			packageName: 'jiraCrossJoinNudges',
		},
		attributes,
		error,
		sendToPrivacyUnsafeSplunk: true,
	});

export const fireSiteOptedOutAnalytics = (
	createAnalyticsEvent: CreateAnalyticsEvent,
	integration: CrossJoinIntegraton,
) => {
	fireTrackAnalytics(createAnalyticsEvent({}), 'eligibility checked', {
		flagKey: 'NON_CONTEXTUAL_CJNS',
		ineligibilityReason: 'SITE_OPTED_OUT',
		integration,
	});
};
