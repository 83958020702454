import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import type { HorizontalNavQueueInnerQuery } from '@atlassian/jira-relay/src/__generated__/HorizontalNavQueueInnerQuery.graphql';
import parameters from '@atlassian/jira-relay/src/__generated__/HorizontalNavQueueInnerQuery$parameters';
import { JiraServicedeskQueueAri } from '@atlassian/ari/jira-servicedesk/queue';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common/src/index.tsx';
import { withGiraAggAnonSsrFix } from '../../../common/utils.tsx';

export const getQuery: Parameters<
	typeof createRelayResource<HorizontalNavQueueInnerQuery>
>[0]['getQuery'] = ({ match, query }, { tenantContext: { activationId, cloudId } }) => {
	const queueId = match.params.queueId;
	let { projectKey, projectId } = getProjectKeyId(match, query);
	projectKey = projectKey ?? '';
	projectId = projectId ?? '';
	const projectIdOrKey = projectId || projectKey;

	const queueAri = queueId
		? JiraServicedeskQueueAri.create({
				siteId: cloudId,
				queueId: queueId || '',
				activationId,
			}).toString()
		: '';

	const containerNavigationInput = queueAri
		? { scopeId: queueAri }
		: { projectKeyQuery: { cloudId, projectKey } };

	return {
		options: {
			fetchPolicy: 'store-and-network' as const,
		},
		parameters,
		variables: {
			cloudId,
			projectIdOrKey,
			containerNavigationInput,
			first: NAVIGATION_ITEMS_PAGE_SIZE,
		},
	};
};

export const horizontalNavQueueResource = withGiraAggAnonSsrFix(
	createRelayResource<HorizontalNavQueueInnerQuery>({
		type: 'HORIZONTAL_NAVIGATION_JIRA_QUERY',
		getQuery,
	}),
);
