import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	jqlDebuggerHeadingNonFinal: {
		id: 'jql-debugger-panel.header.text-non-final',
		defaultMessage: 'JQL error',
		description: 'Error section title when a single JQL error is present',
	},
	jqlDebuggerHeadingPluralNonFinal: {
		id: 'jql-debugger-panel.header.plural-text-non-final',
		defaultMessage: 'JQL errors',
		description: 'Error section title when multiple JQL errors are present',
	},
	jqlDebuggerLoadingNonFinal: {
		id: 'jql-debugger-panel.header.loading-non-final',
		defaultMessage: 'Generating query fixes',
		description: 'Text indicating that the AI JQL Debugger is loading',
	},
	betaLozenge: {
		id: 'jql-debugger-panel.header.beta-lozenge',
		defaultMessage: 'Beta',
		description: 'Lozenge text to indicate that the feature is in beta testing phase',
	},
});
