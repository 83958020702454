import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getQueuesResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskQueuesLandingRoute } from '@atlassian/jira-router-routes-servicedesk-queues-routes/src/servicedeskQueuesLandingRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import ServiceDeskQueuesLanding, { LazyServicedeskQueuesLanding } from './ui/landing/index.tsx';

export const servicedeskQueuesLandingRouteEntry = createEntry(
	servicedeskQueuesLandingRoute,
	() => ({
		group: 'servicedesk',
		isRedirect: true,
		component: ServiceDeskQueuesLanding,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getQueuesResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesLanding],
	}),
);
