import React from 'react';
import { LinkIconButton } from '@atlaskit/button/new';
import LogInIcon from '@atlaskit/icon/core/log-in';
import { useIntlV2 as useIntlDI } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useIsAnonymous as useIsAnonymousDI } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { NAVIGATION_ITEM_ID } from '../../common/constants.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import { useNavigationItemAnalytics as useNavigationItemAnalyticsDI } from '../../controllers/navigation-item-analytics/main.tsx';
import messages from './messages.tsx';
import type { SignInProps } from './types.tsx';
import { useDestinationParam as useDestinationParamDI } from './utils.tsx';

const SignIn = ({
	useDestinationParam = useDestinationParamDI,
	useIntl = useIntlDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	useIsAnonymous = useIsAnonymousDI,
}: SignInProps) => {
	const destinationParam = useDestinationParam();
	const isAnonymous = useIsAnonymous();
	const { formatMessage } = useIntl();
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.SIGN_IN,
	});

	if (!isAnonymous) return null;

	if (getWillShowNav4()) {
		return (
			<LinkIconButton
				href={`/login.jsp?${destinationParam}`}
				icon={LogInIcon}
				label={formatMessage(messages.signIn)}
				isTooltipDisabled={false}
				appearance="subtle"
				// @ts-expect-error - TS2769 - No overload matches this call.
				onClick={triggerAnalytics}
			/>
		);
	}

	return (
		<div role="listitem">
			<LinkIconButton
				href={`/login.jsp?${destinationParam}`}
				icon={LogInIcon}
				label={formatMessage(messages.signIn)}
				isTooltipDisabled={false}
				appearance="subtle"
				// @ts-expect-error - TS2769 - No overload matches this call.
				onClick={triggerAnalytics}
			/>
		</div>
	);
};

const SignInWithErrorBoundary = (props: SignInProps) => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.SIGN_IN}>
		<SignIn {...props} />
	</TopLevelErrorBoundary>
);

export { SignInWithErrorBoundary as SignIn };
