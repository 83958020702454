import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectSettingsServicedeskAutomationSummaryRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskAutomationSummaryRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ServiceDeskAutomationSummary, {
	LazyProjectSettingsServiceDeskAutomationSummary,
} from './ui/service-desk-automation/summary/index.tsx';

export const projectSettingsServicedeskAutomationSummaryRouteEntry = createEntry(
	projectSettingsServicedeskAutomationSummaryRoute,
	() => ({
		group: 'project-settings-servicedesk',
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServiceDesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		ufoName: 'service-management.automation.summary',
		component: ServiceDeskAutomationSummary,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationSummary],
	}),
);
