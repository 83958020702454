import React, { forwardRef, type ReactNode, useEffect } from 'react';

import useControlled from '@atlaskit/ds-lib/use-controlled';
import usePreviousValue from '@atlaskit/ds-lib/use-previous-value';
import { Popup } from '@atlaskit/popup/experimental';

import { MenuListItem } from '../menu-list-item';

import { IsOpenContext, SetIsOpenContext } from './flyout-menu-item-context';

export type FlyoutMenuItemProps = {
	children: ReactNode;
	/**
	 * ID that is assigned to the popup container element and used to associate the trigger with the content.
	 */
	id?: string;
	/**
	 * Allows to control the open state of the flyout externally.
	 */
	isOpen?: boolean;
	/**
	 * Whether the flyout menu is open by default.
	 */
	isDefaultOpen?: boolean;
	/**
	 * Callback that is called when the flyout menu is opened or closed.
	 */
	onOpenChange?: (isOpen: boolean) => void;
};

/**
 * __FlyoutMenuItem__
 *
 * Displays content in a flyout menu, triggered by a button.
 *
 * The top-level component that contains the trigger and content of a flyout menu.
 *
 * Usage example:
 * ```jsx
 * <FlyoutMenuItem>
 *   <FlyoutMenuItemTrigger>Trigger</FlyoutMenuItemTrigger>
 *   <FlyoutMenuItemContent>
 *     <MenuButtonItem>Item 1</MenuButtonItem>
 *     <MenuButtonItem>Item 2</MenuButtonItem>
 *   </FlyoutMenuItemContent>
 * </FlyoutMenuItem>
 * ```
 */
export const FlyoutMenuItem = forwardRef<HTMLDivElement, FlyoutMenuItemProps>(
	(
		{ children, id, isOpen: isOpenControlled, isDefaultOpen = false, onOpenChange },
		forwardedRef,
	) => {
		const [isOpen, setIsOpen] = useControlled(isOpenControlled, () => isDefaultOpen);

		const previousIsOpen = usePreviousValue(isOpen);

		useEffect(() => {
			if (previousIsOpen === undefined || previousIsOpen === isOpen) {
				/**
				 * The previous value is `undefined` on initialization, so if it is `undefined` then the value hasn't changed.
				 *
				 * The previous value can be equal to the current one if the component re-renders due to something else changing.
				 *
				 * In both cases the value hasn't changed and we don't want to notify consumers.
				 */
				return;
			}

			onOpenChange?.(isOpen);
		}, [isOpen, onOpenChange, previousIsOpen]);

		return (
			<IsOpenContext.Provider value={isOpen}>
				<SetIsOpenContext.Provider value={setIsOpen}>
					<MenuListItem ref={forwardedRef}>
						<Popup id={id} isOpen={isOpen}>
							{children}
						</Popup>
					</MenuListItem>
				</SetIsOpenContext.Provider>
			</IsOpenContext.Provider>
		);
	},
);
