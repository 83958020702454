import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/projectsViewOptionsQuery.graphql';
import type { ExtraProps } from './index.tsx';

type EntryPointParams = { cloudId: string } & ExtraProps;
export const ProjectsViewOptionsEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-projects-view-options" */ './index.tsx').then(
			({ ProjectsViewOptions }) => ProjectsViewOptions,
		),
	),
	getPreloadProps: ({ cloudId, onChange }: EntryPointParams) => ({
		queries: {
			projectsViewOptions: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: { cloudId },
			},
		},
		extraProps: { onChange },
	}),
});
