import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	atlasShortcutText: {
		id: 'jira.global-shortcuts.shortcut-text.atlas',
		defaultMessage: 'Atlas',
		description: 'Label for Atlas shortcut',
	},
	confluenceShortcutText: {
		id: 'jira.global-shortcuts.shortcut-text.confluence',
		defaultMessage: 'Confluence',
		description: 'Label for Confluence product shortcut',
	},
	loomShortcutText: {
		id: 'jira.global-shortcuts.shortcut-text.loom',
		defaultMessage: 'Loom',
		description: 'Label for Loom product shortcut',
	},
	goalsShortcutText: {
		id: 'jira.global-shortcuts.shortcut-text.goals',
		defaultMessage: 'Goals',
		description: 'Label for Goals product shortcut',
	},
	projectsShortcutText: {
		id: 'jira.global-shortcuts.shortcut-text.projects',
		defaultMessage: 'Projects',
		description: 'Label for Projects product shortcut',
	},
	teamsShortcutText: {
		id: 'jira.global-shortcuts.shortcut-text.teams',
		defaultMessage: 'Teams',
		description: 'Label for Teams product shortcut',
	},
});
