/**
 * Create route entry with entrypoint, resources, etc. override
 *
 * @param baseRoute Base route object that contains matching info
 * @param opts Route definition to fill and override
 */
export function createRouteEntry<IR, O, R>(baseRoute: IR, opts: () => O): R {
	// @ts-expect-error Re-using baseRoute to become the full route Object allows us to optimize
	// route transitions as RRR can directly use the route instead of comparing a separate InvariantRoute
	// against the full route list
	// eslint-disable-next-line no-param-reassign
	baseRoute.lazyOpts = opts;
	// @ts-expect-error TODO
	return baseRoute;
}
