import React from 'react';
import { Create as AkCreate, type CreateProps } from '@atlaskit/atlassian-navigation';
import { SkeletonCreateButton } from '@atlaskit/atlassian-navigation/skeleton';
import Button from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { GLOBAL_NAV_CREATE_BUTTON_SPOTLIGHT_ID } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/constants.tsx';
import { SpotlightTarget } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/spotlight-target/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { CreateButton as Nav4CreateButton } from '@atlassian/navigation-system';

// This id is required to ensure pollinator tests work between Nav3 and Nav4
const TEST_ID_NAV_4 = 'atlassian-navigation--create-button';

// Should this live in the migration layer (@atlassian/jira-navigation-system) instead?
export const Create = (props: CreateProps) => {
	if (__SERVER__) {
		if (getWillShowNav4()) {
			if (fg('jira_nav4_beta_drop_2')) {
				// TODO: when cleaning up we can probably remove the Nav 4 server branch
				return <Nav4CreateButton testId={TEST_ID_NAV_4}>{props.text}</Nav4CreateButton>;
			}

			return (
				<Button appearance="primary" iconBefore={AddIcon} testId={props.testId}>
					{props.text}
				</Button>
			);
		}
		return <SkeletonCreateButton text={props.text} testId={props.testId} />;
	}

	if (getWillShowNav4()) {
		const { onClick, text } = props;

		return (
			<SpotlightTarget name={GLOBAL_NAV_CREATE_BUTTON_SPOTLIGHT_ID}>
				<Box>
					<Nav4CreateButton onClick={onClick} testId={TEST_ID_NAV_4}>
						{text}
					</Nav4CreateButton>
				</Box>
			</SpotlightTarget>
		);
	}

	return <AkCreate {...props} />;
};
