import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { servicedeskCmdbInsightConfigureRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightConfigureRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import InsightConfigure, { LazyServicedeskCmdbConfigure } from './ui/configure/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbInsightConfigureRouteEntry = createEntry(
	servicedeskCmdbInsightConfigureRoute,
	() => ({
		group: 'servicedesk',
		layout: globalLayout,
		navigation: { menuId: MENU_ID.INSIGHT },
		ufoName: 'servicedesk-cmdb-insight-configure',
		component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : InsightConfigure,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbConfigure],
	}),
);
