/**
 * @generated SignedSource<<50bd771f2bce599bef2de190adce24dc>>
 * @relayHash 8b56abb57800b8827711da75cccda33c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b8a0e36827cbcbcdc88a96104072526e3c0e18f56de778081b52d1fdfb7923fc

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_timelineQuery } from './src_timelineQuery.graphql';

import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isReparentingEnabled_provider from '@atlassian/jira-relay-provider/src/jira-list-reparenting.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: PreloadableConcreteRequest<src_timelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b8a0e36827cbcbcdc88a96104072526e3c0e18f56de778081b52d1fdfb7923fc",
    "metadata": {},
    "name": "src_timelineQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider": isReparentingEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};

export default node;
