/**
 * @generated SignedSource<<eb4ae6aae065c938f532a846255332dc>>
 * @relayHash 007933a1073bb512097f0e95cf95622d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 554086782541cb72a7484650860ffea7ea940346b5eb77793d5681f9e287d807

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessOverviewTimelineQuery } from './src_jiraBusinessOverviewTimelineQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessOverviewTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "554086782541cb72a7484650860ffea7ea940346b5eb77793d5681f9e287d807",
    "metadata": {},
    "name": "src_jiraBusinessOverviewTimelineQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
