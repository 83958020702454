/**
 * @generated SignedSource<<6e9ff8558faf875cd1fb3efe32191034>>
 * @relayHash d60edddcce9abded6e473e7b00b820b1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 77530e950080d1180f6d423652afe470e45507cf93cfa991dff87c19978f3b49

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraProjectStatus = "ACTIVE" | "ARCHIVED" | "DELETED" | "%future added value";
export type servicesTrialSprintBoardProjectDataQuery$variables = {
  projectAri: string;
};
export type servicesTrialSprintBoardProjectDataQuery$data = {
  readonly jira: {
    readonly jiraProject: {
      readonly created: AGG$DateTime | null | undefined;
      readonly key: string;
      readonly status: JiraProjectStatus | null | undefined;
      readonly tmpBoardId: AGG$Long | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type servicesTrialSprintBoardProjectDataQuery = {
  response: servicesTrialSprintBoardProjectDataQuery$data;
  variables: servicesTrialSprintBoardProjectDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "status"
},
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = {
  "kind": "ScalarField",
  "name": "created"
},
v5 = {
  "kind": "ScalarField",
  "name": "tmpBoardId"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "servicesTrialSprintBoardProjectDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "servicesTrialSprintBoardProjectDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "77530e950080d1180f6d423652afe470e45507cf93cfa991dff87c19978f3b49",
    "metadata": {},
    "name": "servicesTrialSprintBoardProjectDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0bff8aa9317d4caac17388ad150c17cb";

export default node;
