import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { archivedProjectsDirectoryRoute } from '@atlassian/jira-router-routes-archived-projects-directory-routes/src/archivedProjectsDirectoryRoute.tsx';
import ArchivedProjectsDirectory, {
	LazyArchivedProjectsAdmin,
} from './ui/spa/admin-pages/archived-projects/index.tsx';

export const archivedProjectsDirectoryRouteEntry = createEntry(
	archivedProjectsDirectoryRoute,
	() => ({
		group: 'global-settings',
		component: ArchivedProjectsDirectory,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProjects,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyArchivedProjectsAdmin],
		ufoName: 'admin.archived-projects',
	}),
);
