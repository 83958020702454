import React, { type RefCallback, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import EditorPanelIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import type { TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { PACKAGE_NAME } from '../../constants.tsx';
import { COMPONENT_ID } from '../field-description-popup-common/constants.tsx';
import {
	ContentBox,
	ContentError,
	ContentLoading,
} from '../field-description-popup-common/main.tsx';
import FieldDescriptionContentEntryPoint from '../field-description-popup-content/entrypoint.tsx';
import type { Props as PopupContentProps } from '../field-description-popup-content/main.tsx';
import messages from './messages.tsx';

type Props = PopupContentProps & {
	/**
	 * Knowing the state of the parent picker is needed to to set some of the
	 * colours for the field description popup trigger (icon), when original
	 * theme is being used (I.e. not light nor dark theme)
	 */
	isParentPickerActive: boolean;

	/**
	 * Should only be needed For VR testing, to render a storybook example with
	 * the popup content immediately visible
	 */
	shouldOpenOnFirstRender?: boolean;
};

const entryPointParams = {};
/**
 * Render a Jira custom field description as rich text, within a popup
 */
export const FieldDescriptionPopup = ({
	fieldDescription,
	shouldOpenOnFirstRender = false,
	isParentPickerActive,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(shouldOpenOnFirstRender);

	const toggleOpenCloseState = useCallback(() => {
		setIsOpen((currentIsOpenValue) => !currentIsOpenValue);
	}, []);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		FieldDescriptionContentEntryPoint,
		entryPointParams,
	);

	const buttonTrigger = useEntryPointButtonTrigger(entryPointActions);

	/**
	 * lazily render the field description popup content (using entrypoints).
	 * The content is a rich text description that is configured in the 'custom
	 * fields' Jira settings.
	 */
	const renderContent = useCallback(
		() => (
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id={COMPONENT_ID}
				packageName={PACKAGE_NAME}
				errorFallback={() => (
					<ContentBox>
						<ContentError />
					</ContentBox>
				)}
				fallback={
					<ContentBox>
						<ContentLoading />
					</ContentBox>
				}
				runtimeProps={{ fieldDescription }}
			/>
		),
		[entryPointReferenceSubject, fieldDescription],
	);

	/**
	 * renders the icon that, when clicked, toggles open/close state of the
	 * field description popup
	 */
	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<Tooltip content={formatMessage(messages.showDescription)} position="top">
				{(tooltipProps) => (
					<Trigger
						{...tooltipProps}
						{...triggerProps}
						ref={(element) => {
							/*
							 * make sure the Trigger's ref is passed to all things
							 * that need it, otherwise bugs will appear such as
							 * tooltip/popup appearing in weird places
							 */
							buttonTrigger(element);
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(tooltipProps.ref as RefCallback<HTMLElement>)(element);
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(triggerProps.ref as RefCallback<HTMLElement>)(element);
						}}
						isOpen={isOpen}
						isParentPickerActive={isParentPickerActive}
						onClick={(e) => {
							e.stopPropagation();
							toggleOpenCloseState();
						}}
					>
						<EditorPanelIcon
							spacing="spacious"
							label={
								isOpen
									? formatMessage(messages.hideDescription)
									: formatMessage(messages.showDescription)
							}
						/>
					</Trigger>
				)}
			</Tooltip>
		),
		[buttonTrigger, formatMessage, isOpen, isParentPickerActive, toggleOpenCloseState],
	);

	return (
		<>
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-start"
				messageId="jql-builder-basic.common.ui.field-description-popup.popup"
				messageType="transactional"
				content={renderContent}
				trigger={renderTrigger}
			/>
			{isOpen && <ShortcutScope />}
		</>
	);
};

/**
 * Clickable element that will toggle the open / close state of the popup
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Trigger = styled.button<{ isOpen: boolean; isParentPickerActive: boolean }>({
	border: 'none',
	backgroundColor: 'transparent',
	cursor: 'pointer',
	padding: '0',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ isOpen, isParentPickerActive }) =>
		isOpen || isParentPickerActive ? token('color.icon.selected') : token('color.text.subtlest'),
	'&:hover': {
		color: token('color.text'),
	},
	'&:focus': {
		color: token('color.text'),
		/*
		 * Keyboard focus handling
		 * Note - use of box-shadow instead of outline is based on
		 * Atlaskit button implementation
		 */
		boxShadow: `0 0 0 2px ${token('color.border.focused')}`,
		outline: 'none',
		borderRadius: '3px',
	},
});
