import { fg } from '@atlassian/jira-feature-gating';

const getRemoteLinkedIssuesPreviewFragment = () => `
    id
    issueId
    globalId
    href
    applicationType
    applicationName
    relationship
    title
`;

const getAssociatedIssueFragment = () => `
    issueId
    issueKey {
        stringValue
    }
    summary {
        textValue
    }
    status {
        statusCategoryId
        name
        statusId
    }
    priority {
        iconUrl
        name
    }
    issueType {
        iconUrl
        name
    }
    assignee {
        userValue
        {
            displayName
            avatarUrl
        }
    }
   isResolved
`;

const getRemoteLinkedIssuesDetailsFragment = () => `
    ... on AssociatedIssueWrapper {
        associatedIssue {
            ${getAssociatedIssueFragment()}
        }
    }
    ... on FailedRemoteIssue {
        error
        repairLink { href }
    }
`;

const getRemoteLinkedIssuesFragment = () => `
    remoteLinkedIssues {
        preview {
            ${getRemoteLinkedIssuesPreviewFragment()}
        }
        details {
            ${getRemoteLinkedIssuesDetailsFragment()}
        }
    }
`;

const getWebLinksFragment = () => `
    webLinks(allowThirdParties: true, orderBy: "-id") {
        linkCount
        links {
            id
            href
            linkText
            iconUrl
            summary
            resolved
            relationship
            applicationName
            applicationType
        }
    }
`;

const getRemoteLinksQueryNew = () => `
    remoteLinks {
        isLinkingEnabled
        confluencePages {
            linkedPages {
				pageCount
				pages {
					... on ConfluencePage {
						id
						globalId
						href
						title
						type
					}
					... on FailedRemoteLink {
						id
						error
						link {
							id
							globalId
							href
						}
						repairLink { href }
					}
				}
            }
        }
        ${getWebLinksFragment()}
        ${getRemoteLinkedIssuesFragment()}
    }
`;

const getRemoteLinksQueryOld = () => `
    remoteLinks {
        isLinkingEnabled
        remoteLinkedIssues {
            preview {
                id
                issueId
                globalId
                href
                applicationType
                applicationName
                relationship
                title
            }
        }
    }
`;

export const getRemoteLinksQuery = () =>
	fg('jiv-19125-fetch-remote-links-in-interactive-query')
		? getRemoteLinksQueryNew()
		: getRemoteLinksQueryOld();
