import React from 'react';

import { GoalsIcon } from '@atlaskit/temp-nav-app-icons/goals';

import { Products } from '../../constants';
import { GlobalShortcutsItem } from '../../types';

export default function goals(
	localizedName: string,
	fallbackUrl: () => string,
): GlobalShortcutsItem {
	return {
		icon: <GoalsIcon size="20" />,
		label: localizedName,
		apsKey: Products.ATLAS_GOALS,
		fallbackUrl,
	};
}
