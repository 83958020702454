import { useCallback } from 'react';
import noop from 'lodash/noop';
import { ConnectionHandler, commitLocalUpdate, useRelayEnvironment } from 'react-relay';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { NO_OF_BOARDS_TO_SHOW } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/constants/index.tsx';
import type { useBoardCreateMutation$data as useBoardCreateMutationData } from '@atlassian/jira-relay/src/__generated__/useBoardCreateMutation.graphql';
import { useIsBacklogRoute } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/controllers/use-is-backlog-route/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import RelayDataID from '@atlassian/relay-data-id';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import type { SubmitData } from '../../common/types.tsx';
import { fireBoardCreateErrorAnalytics } from '../../common/utils.tsx';
import { BOARD_CREATE_PACKAGE_NAME } from '../../common/constants.tsx';
import { useBoardCreateMutation } from '../../services/use-board-create-mutation/index.tsx';
import { useBoardCreateContext } from '../use-board-create-context/index.tsx';
import { useBoardCreateFlag } from '../use-board-create-flag/index.tsx';
import messages from './messages.tsx';
import { transformInput, buildBoardUrl, redirectToBacklog, buildBoardUrlOld } from './utils.tsx';

export type SubmitPayload = SubmitData & {
	onSuccess?: (result: useBoardCreateMutationData) => void;
	onError?: (error: unknown) => void;
};

const EXPERIENCE = 'createBoard';

export const useAddBoardToProject = () => {
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();

	return useCallback(
		({ id, projectId }: { id: string | undefined; projectId: string }) => {
			commitLocalUpdate(environment, (store) => {
				try {
					if (!id) throw new Error('Failed to add board to project, missing id');
					if (!projectId) throw new Error('Failed to add board to project, missing projectId');

					const projectRelayDataId = RelayDataID(
						{
							id: JiraProjectAri.create({
								siteId: cloudId,
								projectId: String(projectId),
							}),
						},
						'JiraProject',
					);
					if (!projectRelayDataId)
						throw new Error(`Failed to create project relay data id, projectId: ${projectId}`);

					const project = store.get(projectRelayDataId);
					if (!project) throw new Error(`Failed to find the project, projectId: ${projectId}`);

					const projectBoardsConnection = project.getLinkedRecord('boards', {
						first: NO_OF_BOARDS_TO_SHOW,
					});
					if (!projectBoardsConnection)
						throw new Error('Failed to find the project-boards connection');

					const newNode = store.get(id);
					if (!newNode) throw new Error(`Failed to find the new board, id: ${id}`);

					const newEdge = ConnectionHandler.createEdge(
						store,
						projectBoardsConnection,
						newNode,
						'JiraBoardEdge',
					);
					ConnectionHandler.insertEdgeBefore(projectBoardsConnection, newEdge);
				} catch (error) {
					fireBoardCreateErrorAnalytics({
						id: 'addBoardToProject',
						error:
							error instanceof Error
								? error
								: new Error(`Failed to add board to project: ${error}`),
					});
				}
			});
		},
		[environment, cloudId],
	);
};

export const useBoardCreateController = () => {
	const { handleMutation } = useBoardCreateMutation();
	const { projectKey } = useBoardCreateContext(); // remove when clean up fix_board_url_value_in_graphql_apis
	const { showSuccessFlag, showErrorFlag } = useBoardCreateFlag();
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();
	const addBoardToProject = useAddBoardToProject();

	const isBacklogRoute = useIsBacklogRoute();
	const onExperienceStart = fg('simplified_board_creation_capacity_slo')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useExperienceStart({ experience: EXPERIENCE, analyticsSource: BOARD_CREATE_PACKAGE_NAME })
		: noop;
	const onExperienceSuccess = fg('simplified_board_creation_capacity_slo')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useExperienceSuccess({ experience: EXPERIENCE })
		: noop;
	const onExperienceFail = fg('simplified_board_creation_capacity_slo')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useExperienceFail({ experience: EXPERIENCE })
		: noop;

	const submit = useCallback(
		async ({ boardType, locationProjectId, formValues, onSuccess, onError }: SubmitPayload) => {
			const input = transformInput({ boardType, locationProjectId, formValues });
			try {
				fg('simplified_board_creation_capacity_slo') && onExperienceStart();
				const result = await handleMutation(input);

				if (
					!result?.jira?.createBoard?.success &&
					fg('dee_7246_improve_board_create_error_handling')
				) {
					throw result?.jira?.createBoard?.errors?.length
						? new MutationError(result?.jira?.createBoard?.errors?.[0])
						: new Error('Failed to create board');
				}

				onSuccess?.(result);
				showSuccessFlag({
					title: formatMessage(messages.successTitle),
				});
				addBoardToProject({
					projectId: locationProjectId,
					id: result.jira?.createBoard?.board?.__id,
				});
				fg('simplified_board_creation_capacity_slo') && onExperienceSuccess();

				const resultBoardId = result?.jira?.createBoard?.board?.boardId;
				const resultBoardType = result?.jira?.createBoard?.board?.boardType;

				const boardUrl = fg('fix_board_url_value_in_graphql_apis')
					? buildBoardUrl(result?.jira?.createBoard?.board?.boardUrl, resultBoardType)
					: buildBoardUrlOld(projectKey, resultBoardId, resultBoardType);

				if (!boardUrl) {
					fireBoardCreateErrorAnalytics({
						error: new Error('Failed to generate board URL after board creation'),
						attributes: {
							boardId: resultBoardId,
							boardType: resultBoardType,
						},
						id: 'generateUrl',
					});
				} else {
					resultBoardType === 'SCRUM' && isBacklogRoute
						? redirectToBacklog(boardUrl)
						: push(boardUrl);
				}
			} catch (error) {
				let errMsg: string | undefined;
				if (
					error instanceof MutationError &&
					error.message &&
					fg('dee_7246_improve_board_create_error_handling')
				) {
					errMsg = error.message;
				}
				onError?.(errMsg || error);
				showErrorFlag({
					title: formatMessage(messages.errorTitle),
					description: errMsg || formatMessage(messages.errorDescription),
				});
				fireBoardCreateErrorAnalytics({
					error: new Error(`Failed to create board: ${errMsg || error}`),
					id: 'createBoard',
				});
				fg('simplified_board_creation_capacity_slo') &&
					onExperienceFail(BOARD_CREATE_PACKAGE_NAME, errMsg || error);
			}
		},
		[
			onExperienceStart,
			handleMutation,
			showSuccessFlag,
			formatMessage,
			addBoardToProject,
			onExperienceSuccess,
			projectKey,
			isBacklogRoute,
			push,
			showErrorFlag,
			onExperienceFail,
		],
	);

	return { submit };
};
