import { isFedRamp } from '@atlassian/atl-context';
import { fg } from '@atlassian/jira-feature-gating';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import {
	forgeBoardModuleResource,
	forgeProjectModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';

import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';

import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';

import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';

import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	shouldLoadUIFBoardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';

import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';

import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { classicSoftwareBoardEmbedRoute } from '@atlassian/jira-router-routes-software-classic-board-routes/src/classicSoftwareBoardEmbedRoute.tsx';
import {
	UIFBoardSpa,
	RapidBoardSpaPage,
} from '@atlassian/jira-router-routes-user-board-entries/src/ui/async.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { classicBoardCapability } from './common/constants.tsx';

/**
 * Ordinarily it would be inadvisable to evaluate something like this in
 * a static route, as this could lead to inconsistent behaviour between
 * the server and the browser.
 *
 * In this case it is necessary and safe because:
 * 1) There are two versions of the board that share the same route
 *    and we can only tell them apart at runtime. When the old version
 *    is eventually removed, this should be cleaned up.
 * 2) No requests for board data are made on the server, so even
 *    if the value is incorrect on the server it won't be utilised
 * */

export const classicSoftwareBoardEmbedRouteEntry = createEntry(
	classicSoftwareBoardEmbedRoute,
	() => ({
		group: 'classic-software',
		ufoName: 'classic-board-embed',
		component: RapidBoardSpaPage,
		shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-board'),

		layout: createLayout({
			isChromeless: true,
		}),

		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
			// Load the NBT resource if we opt-in UIF, not fedRamp is enabled
			resourceWithCondition2(
				() => shouldLoadUIFBoardResources() && !isFedRamp(),
				nextBestTaskFeatureResource,
			),
			getEditModelResource(),
			getBoardDetailsResource(),
			themePreferenceResource,
			forgeBoardModuleResource,
		],

		afterPaint: [LazyIssueApp],
		forPaint: [UIFBoardSpa],

		meta: {
			capability: classicBoardCapability,
		},
	}),
);
