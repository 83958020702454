import React, { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import PagesIcon from '@atlaskit/icon/core/pages';
import {
	BOOLEAN_LITERAL_FALSE,
	IDENTIFIER_ACKNOWLEDGED,
	IDENTIFIER_ASSIGNEE,
	IDENTIFIER_IS_SEEN,
	IDENTIFIER_STATUS,
	LOGICAL_OPERATOR_AND,
	OPERATOR_EQUAL_TO,
	STRING_LITERAL_STATUS_CLOSED,
	STRING_LITERAL_STATUS_OPEN,
} from '@atlassian/eoc-query-editor';
import { useIntl } from '@atlassian/jira-intl';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	CUSTOM_ITEM,
	FIXED_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	LEVEL_THREE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	ENTITY_ID,
	MENU_ID_OPERATIONS,
	MENU_ID_OPS_ALERTS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { toMenuIdFromMenuIdList } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/utils/menu-id/index.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	AlertsMenuItemContent$data,
	AlertsMenuItemContent$key,
} from '@atlassian/jira-relay/src/__generated__/AlertsMenuItemContent.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { useQueryParam } from '@atlassian/react-resource-router';
import commonMessages from '../../../common/messages.tsx';
import messages from '../../messages.tsx';
import { SavedSearchActionButtons } from './action-button/index.tsx';

export function AlertsMenuItemContent({ queryRef }: { queryRef: AlertsMenuItemContent$key }) {
	const data = useFragment<AlertsMenuItemContent$key>(
		graphql`
			fragment AlertsMenuItemContent on Query {
				opsgenie @required(action: THROW) {
					savedSearches(cloudId: $cloudId) @required(action: THROW) @optIn(to: "opsgenie-beta") {
						sharedWithMe @required(action: THROW) {
							id @required(action: THROW)
							name @required(action: THROW)
							alertSearchQuery @required(action: THROW)
						}
						createdByMe @required(action: THROW) {
							id @required(action: THROW)
							name @required(action: THROW)
							alertSearchQuery @required(action: THROW)
						}
					}
				}
			}
		`,
		queryRef,
	);

	const [selectedFilter, setSelectedFilter] = useState('');

	const { isSelectedPath } = useSidebarNav4();
	const { sharedWithMe, createdByMe } = data.opsgenie.savedSearches;
	const { formatMessage } = useIntl();
	const accountId = useAccountId();

	const [viewType] = useQueryParam('view');
	const { icon: pagesIcon } = useFormattedMessageAndIcon(commonMessages.alerts, PagesIcon);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const defaultFilters = [
		{ id: 'all', name: formatMessage(messages.allSearch), alertSearchQuery: '' },
		{
			id: 'open',
			name: formatMessage(messages.openSearch),
			alertSearchQuery: `${IDENTIFIER_STATUS}${OPERATOR_EQUAL_TO} ${STRING_LITERAL_STATUS_OPEN}`,
		},
		{
			id: 'closed',
			name: formatMessage(messages.closedSearch),
			alertSearchQuery: `${IDENTIFIER_STATUS}${OPERATOR_EQUAL_TO} ${STRING_LITERAL_STATUS_CLOSED}`,
		},
		{
			id: 'unacked',
			name: formatMessage(messages.unackedSearch),
			alertSearchQuery: `${IDENTIFIER_STATUS}${OPERATOR_EQUAL_TO} ${STRING_LITERAL_STATUS_OPEN} ${LOGICAL_OPERATOR_AND} ${IDENTIFIER_ACKNOWLEDGED}${OPERATOR_EQUAL_TO} ${BOOLEAN_LITERAL_FALSE}`,
		},
		{
			id: 'notSeen',
			name: formatMessage(messages.notSeenSearch),
			alertSearchQuery: `${IDENTIFIER_IS_SEEN}${OPERATOR_EQUAL_TO} ${BOOLEAN_LITERAL_FALSE}`,
		},
		{
			id: 'assignedToMe',
			name: formatMessage(messages.assignedToMeSearch),
			alertSearchQuery: `${IDENTIFIER_ASSIGNEE}${OPERATOR_EQUAL_TO} "${accountId || 'me'}"`,
		},
	];

	const defaultFilterMenuIds = getParentMenuId(defaultFilters);
	const sharedWithMeMenuIds = getParentMenuId(sharedWithMe);
	const createdByMeMenuIds = getParentMenuId(createdByMe);

	useEffect(() => {
		if (!isSelectedPath(MENU_ID_OPS_ALERTS) && selectedFilter !== '') {
			setSelectedFilter('');
		}
	}, [isSelectedPath, selectedFilter]);

	return (
		<UFOSegment name="nav4.sidebar.operations.alerts">
			{/* todo: enable this when starred searches are done.
					<MenuSection>
						<MenuSectionHeading>{formatMessage(messages.starred)}</MenuSectionHeading>
						<MenuLinkItem
							isSelected={selectedFilter === 'allAlerts'}
							elemBefore={pagesIcon}
							href="/jira/ops/alerts"
							onClick={() => setSelectedFilter('allAlerts')}
						>
							{formatMessage(messages.allAlerts)}
						</MenuLinkItem>
						<MenuLinkItem
							isSelected={selectedFilter === 'myAlerts'}
							elemBefore={pagesIcon}
							href="/jira/ops/alerts"
							onClick={() => setSelectedFilter('myAlerts')}
						>
							{formatMessage(messages.myAlerts)}
						</MenuLinkItem>
					</MenuSection> */}
			<Nav4ExpandableMenuItem
				menuId={defaultFilterMenuIds}
				onExpansionToggle={(hasExpanded) => {
					if (hasExpanded) {
						fireUIAnalytics(createAnalyticsEvent({}), {
							action: 'clicked',
							actionSubject: NAVIGATION_ITEM,
							actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
							attributes: {
								componentType: FIXED_ITEM,
								level: LEVEL_TWO,
								itemId: 'defaultFilters',
								parentItemId: 'alerts',
							},
						});
					}
				}}
			>
				<ExpandableMenuItemTrigger testId={getTestId(defaultFilterMenuIds)}>
					{formatMessage(messages.defaultFilters)}
				</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>
					{defaultFilters.map((item) => (
						<Nav4MenuLinkItem
							elemBefore={pagesIcon}
							key={item.id}
							href={`/jira/ops/alerts?view=${viewType ?? 'list'}${item.alertSearchQuery ? `&query=${item.alertSearchQuery}` : ''}`}
							menuId={ENTITY_ID.ALARM_QUEUE(item.alertSearchQuery)}
							onClick={() => {
								setSelectedFilter(item.id);

								fireUIAnalytics(createAnalyticsEvent({}), {
									action: 'clicked',
									actionSubject: NAVIGATION_ITEM,
									actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
									attributes: {
										componentType: FIXED_ITEM,
										level: LEVEL_THREE,
										itemId: item.id,
										parentItemId: 'defaultFilters',
										itemType: 'defaultFilter',
									},
								});
							}}
						>
							{item.name}
						</Nav4MenuLinkItem>
					))}
				</ExpandableMenuItemContent>
			</Nav4ExpandableMenuItem>

			{sharedWithMe && sharedWithMe.length > 0 && (
				<Nav4ExpandableMenuItem
					menuId={sharedWithMeMenuIds}
					onExpansionToggle={(hasExpanded) => {
						if (hasExpanded) {
							fireUIAnalytics(createAnalyticsEvent({}), {
								action: 'clicked',
								actionSubject: NAVIGATION_ITEM,
								actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
								attributes: {
									componentType: FIXED_ITEM,
									level: LEVEL_TWO,
									itemId: 'sharedWithMe',
									parentItemId: 'alerts',
								},
							});
						}
					}}
				>
					<ExpandableMenuItemTrigger testId={getTestId(sharedWithMeMenuIds)}>
						{formatMessage(messages.sharedWithMe)}
						{sharedWithMe.some((filter) => isSelectedPath(filter.alertSearchQuery))}
					</ExpandableMenuItemTrigger>
					<ExpandableMenuItemContent>
						{sharedWithMe.map((item) => (
							<Nav4MenuLinkItem
								elemBefore={pagesIcon}
								href={`/jira/ops/alerts?view=${viewType ?? 'list'}&query=${item.alertSearchQuery}`}
								key={item.id}
								menuId={ENTITY_ID.ALARM_QUEUE(item.alertSearchQuery)}
								onClick={() => {
									setSelectedFilter('');

									fireUIAnalytics(createAnalyticsEvent({}), {
										action: 'clicked',
										actionSubject: NAVIGATION_ITEM,
										actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
										attributes: {
											componentType: CUSTOM_ITEM,
											level: LEVEL_THREE,
											itemId: item.id,
											itemType: 'savedSearch',
											parentItemId: 'sharedWithMe',
										},
									});
								}}
							>
								{item.name}
							</Nav4MenuLinkItem>
						))}
					</ExpandableMenuItemContent>
				</Nav4ExpandableMenuItem>
			)}
			{createdByMe && createdByMe.length > 0 && (
				<Nav4ExpandableMenuItem
					menuId={createdByMeMenuIds}
					onExpansionToggle={(hasExpanded) => {
						if (hasExpanded) {
							fireUIAnalytics(createAnalyticsEvent({}), {
								action: 'clicked',
								actionSubject: NAVIGATION_ITEM,
								actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
								attributes: {
									componentType: FIXED_ITEM,
									level: LEVEL_TWO,
									itemId: 'createdByMe',
									parentItemId: 'alerts',
								},
							});
						}
					}}
				>
					<ExpandableMenuItemTrigger testId={getTestId(createdByMeMenuIds)}>
						{formatMessage(messages.createdByMe)}
					</ExpandableMenuItemTrigger>
					<ExpandableMenuItemContent>
						{createdByMe.map((item) => (
							<Nav4MenuLinkItem
								elemBefore={pagesIcon}
								href={`/jira/ops/alerts?view=${viewType ?? 'list'}&query=${item.alertSearchQuery}`}
								key={item.id}
								menuId={ENTITY_ID.ALARM_QUEUE(item.alertSearchQuery)}
								onClick={() => {
									setSelectedFilter('');

									fireUIAnalytics(createAnalyticsEvent({}), {
										action: 'clicked',
										actionSubject: NAVIGATION_ITEM,
										actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
										attributes: {
											componentType: CUSTOM_ITEM,
											level: LEVEL_THREE,
											itemId: item.id,
											itemType: 'savedSearch',
											parentItemId: 'createdByMe',
										},
									});
								}}
								actionsOnHover={<SavedSearchActionButtons savedSearch={item} />}
							>
								{item.name}
							</Nav4MenuLinkItem>
						))}
					</ExpandableMenuItemContent>
				</Nav4ExpandableMenuItem>
			)}
		</UFOSegment>
	);
}

type AlertFilters = AlertsMenuItemContent$data['opsgenie']['savedSearches']['createdByMe'];

function getParentMenuId(arr?: AlertFilters) {
	return toMenuIdFromMenuIdList((arr || []).map((i) => ENTITY_ID.ALARM_QUEUE(i.alertSearchQuery)));
}
