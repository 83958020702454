import React from 'react';
import Icon from '@atlaskit/icon';
import type { CustomGlyphProps } from '@atlaskit/icon/types';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const PoputGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={props.className}
		fill="none"
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15 4L19.25 4C19.4489 4 19.6397 4.07902 19.7803 4.21967C19.921 4.36032 20 4.55109 20 4.75V9H18.5V6.56066L15.0303 10.0303L13.9697 8.96967L17.4393 5.5L15 5.5V4ZM7 7.5C6.72386 7.5 6.5 7.72386 6.5 8L6.5 10.5H11.5C12.6046 10.5 13.5 11.3954 13.5 12.5L13.5 17.5H16C16.2761 17.5 16.5 17.2761 16.5 17V12H18V17C18 18.1046 17.1046 19 16 19H12.7512C12.7508 19 12.7504 19 12.75 19L7 19C5.89543 19 5 18.1046 5 17L5 11.25C5 11.2498 5 11.2497 5 11.2495L5 8C5 6.89543 5.89543 6 7 6L12 6V7.5L7 7.5ZM7 17.5L12 17.5L12 12.5C12 12.2239 11.7761 12 11.5 12H6.5L6.5 17C6.5 17.2761 6.72386 17.5 7 17.5Z"
		/>
	</svg>
);

const PopinGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={props.className}
		fill="none"
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 4L18 4C19.1046 4 20 4.89543 20 6V10C20 11.1046 19.1046 12 18 12H14C13.7229 12 13.4589 11.9436 13.2189 11.8417L11.0607 14H13.5V15.5H9.25C8.83579 15.5 8.5 15.1642 8.5 14.75L8.5 10.5H10L10 12.9393L12.1583 10.7811C12.0564 10.5411 12 10.2771 12 10V6C12 4.89543 12.8954 4 14 4ZM18 5.5L14 5.5C13.7239 5.5 13.5 5.72386 13.5 6V10C13.5 10.2761 13.7239 10.5 14 10.5H18C18.2761 10.5 18.5 10.2761 18.5 10V6C18.5 5.72386 18.2761 5.5 18 5.5ZM7 6L10 6V7.5H7C6.72386 7.5 6.5 7.72386 6.5 8L6.5 17C6.5 17.2761 6.72386 17.5 7 17.5L16 17.5C16.2761 17.5 16.5 17.2761 16.5 17V14H18V17C18 18.1046 17.1046 19 16 19L7 19C5.89543 19 5 18.1046 5 17L5 8C5 6.89543 5.89543 6 7 6Z"
		/>
	</svg>
);

export const PopoutIcon = () => {
	const { formatMessage } = useIntl();
	return <Icon glyph={PoputGlyph} label={formatMessage(messages.popoutIconLabel)} />;
};

export const PopinIcon = () => {
	const { formatMessage } = useIntl();
	return <Icon glyph={PopinGlyph} label={formatMessage(messages.popinIconLabel)} />;
};
