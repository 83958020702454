import type { JiraSpaRoute, RouterContext } from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-name-types/src/RouteName.tsx';
import { MENU_ID_TEAMS, MENU_ID_YOUR_WORK } from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<RouteName>(['your-work', 'people-index']);

function convert(routeContext: RouterContext<JiraSpaRoute>): SelectedPath {
	const routeName = routeContext.route.name;
	const path: string[] = [];
	return convertRouteToPath({ routeName, path });
}

function convertNew(args: { routeName: RouteName }): SelectedPath {
	const { routeName } = args;
	const path: string[] = [];
	return convertRouteToPath({ routeName, path });
}

function convertRouteToPath(args: { routeName: RouteName; path: string[] }): SelectedPath {
	const { routeName, path } = args;
	switch (routeName) {
		case 'your-work':
			path.push(MENU_ID_YOUR_WORK);
			break;

		case 'people-index':
			path.push(MENU_ID_TEAMS);
			break;
		default:
	}

	return path;
}

export const topLevelConverter = { routes, convert, convertNew };
