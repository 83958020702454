// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { NavigationCollection, Props, State } from '../../types.tsx';
import { sortedInsertCollection } from '../utils.tsx';

export const addCollection =
	(collection: NavigationCollection): Action<State, Props> =>
	async ({ setState, getState }, { cloudId, onSuccess }) => {
		const state = getState();

		if (!cloudId || getState().collections?.isLoading || !getState().collections?.initialized) {
			return;
		}

		const isCollectionAlreadyAdded = state.collections.data.some(
			(currentCollection) => currentCollection.uuid === collection.uuid,
		);
		if (isCollectionAlreadyAdded) {
			return;
		}

		setState({
			collections: {
				...state.collections,
				data: sortedInsertCollection(state.collections.data, collection),
			},
		});

		onSuccess?.('addCollection');
	};
