import type { DocNode as ADF } from '@atlaskit/adf-schema';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	fetchCollectionDescription,
	updateCollection as updateCollectionRequest,
} from '../../../services/collections/index.tsx';
import type { State } from '../types.tsx';

export const loadCollectionDescription =
	(collectionUUID: string): Action<State, void, Promise<void>> =>
	async ({ setState, getState }) => {
		const state = getState();
		const existingDescription = state.descriptions[collectionUUID];

		if (existingDescription?.isLoading || (existingDescription && !existingDescription.error)) {
			return;
		}

		try {
			await wrapWithExperience(experience.collections.loadCollectionDescription, async () => {
				setState({
					descriptions: {
						...state.descriptions,
						[collectionUUID]: {
							description: undefined,
							isLoading: true,
							error: undefined,
						},
					},
				});

				const newDescription = await fetchCollectionDescription(collectionUUID);

				setState({
					descriptions: {
						...state.descriptions,
						[collectionUUID]: {
							description: newDescription ?? undefined,
							isLoading: false,
						},
					},
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.collectionDescriptionLoadingFailed', error),
				);
				setState({
					descriptions: {
						...state.descriptions,
						[collectionUUID]: {
							description: undefined,
							isLoading: false,
							error,
						},
					},
				});
			}
			throw error;
		}
	};

export const updateCollectionDescription =
	(collectionUUID: string, description: ADF): Action<State> =>
	async ({ setState, getState }) => {
		const state = getState();
		const existingCollection = state.collections[collectionUUID];
		const data = existingCollection?.data;

		if (!data) {
			return;
		}

		try {
			await wrapWithExperience(experience.collections.updateCollectionDescription, async () => {
				await updateCollectionRequest(collectionUUID, {
					...data,
					description,
				});

				setState({
					descriptions: {
						...state.descriptions,
						[collectionUUID]: {
							isLoading: false,
							error: undefined,
							description,
						},
					},
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.collectionDescriptionUpdateFailed', error),
				);
			}
			throw error;
		}
	};
