import { useEffect, useState } from 'react';
import { useRelayEnvironment, type Environment } from 'react-relay';
import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { memoizedFetchSitePersonalization } from '@atlassian/jira-personalization-service/src/services/site/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { PACKAGE_NAME, TEAM_NAME, TRAIT_NAME } from '../common/constants.tsx';
import { useIsEligibleForTrialSprintBoard } from '../common/eligibility.tsx';
import { getTrialSprintBoardProjectData, createTrialSprintBoard } from '../services/index.tsx';

export function useCreateTrialSprintBoard() {
	const isEligibleForExperiment = useIsEligibleForTrialSprintBoard();
	return () => {
		if (isEligibleForExperiment) {
			createTrialSprintBoard();
		}
	};
}

export const getTrialSprintBoardProjectId = async (cloudId: string) => {
	const siteTraits = await memoizedFetchSitePersonalization({ cloudId });
	const trialSprintBoardTrait = siteTraits.find((traits) => traits.name === TRAIT_NAME);
	if (typeof trialSprintBoardTrait?.value !== 'string') {
		return undefined;
	}
	return trialSprintBoardTrait.value;
};

export const isTrialSprintBoardProject = async (cloudId: string, projectId: string) => {
	const trialSprintBoardId = await getTrialSprintBoardProjectId(cloudId);
	return trialSprintBoardId === projectId;
};

export const isTrialSprintBoardProjectActive = async (
	cloudId: string,
	relayEnvironment: Environment,
	createAnalyticsEvent: (payload: AnalyticsEventPayload) => UIAnalyticsEvent,
) => {
	const trialSprintBoardId = await getTrialSprintBoardProjectId(cloudId);

	if (trialSprintBoardId === undefined) {
		return false;
	}

	const projectAri = JiraProjectAri.create({
		siteId: cloudId,
		projectId: trialSprintBoardId,
	}).toString();

	const { isProjectActive, projectCreated } = await getTrialSprintBoardProjectData(
		projectAri,
		relayEnvironment,
		createAnalyticsEvent,
	);

	const millisDay = 86400000;
	const daysLeft = projectCreated
		? Math.ceil((projectCreated + 30 * millisDay - Date.now()) / millisDay)
		: -1;
	return isProjectActive && daysLeft > 0;
};

export const getTrialSprintBoardPath = async (
	cloudId: string,
	relayEnvironment: Environment,
	createAnalyticsEvent: (payload: AnalyticsEventPayload) => UIAnalyticsEvent,
) => {
	const trialSprintBoardId = await getTrialSprintBoardProjectId(cloudId);

	if (trialSprintBoardId === undefined) {
		return undefined;
	}

	const projectAri = JiraProjectAri.create({
		siteId: cloudId,
		projectId: trialSprintBoardId,
	}).toString();

	const { projectKey, boardId } = await getTrialSprintBoardProjectData(
		projectAri,
		relayEnvironment,
		createAnalyticsEvent,
	);

	if (projectKey === null || boardId === null) {
		return undefined;
	}

	const projectBoardWithIssueSelectedPath = `/jira/software/projects/${projectKey}/boards/${boardId}?label=Paid-Jira-benefits&selectedIssue=LEARNJIRA-6`;

	return projectBoardWithIssueSelectedPath;
};

export const useIsNotTrialSprintBoardProject = (currentProjectId: string) => {
	const cloudId = useCloudId();

	const [isNotTsbProject, setIsNotTsbProject] = useState<boolean>(false);

	useEffect(() => {
		isTrialSprintBoardProject(cloudId, currentProjectId).then((isTsbProject) =>
			setIsNotTsbProject(!isTsbProject),
		);
	}, [cloudId, currentProjectId]);

	return isNotTsbProject;
};

export const useTrialSprintBoardCtaLink = () => {
	const cloudId = useCloudId();
	const relayEnvironment = useRelayEnvironment();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [ctaLink, setCtaLink] = useState<string>('');

	useEffect(() => {
		const getCtaLink = async () => {
			const isProjectAvailable = await isTrialSprintBoardProjectActive(
				cloudId,
				relayEnvironment,
				createAnalyticsEvent,
			);
			if (isProjectAvailable) {
				const tsbIssuePath = await getTrialSprintBoardPath(
					cloudId,
					relayEnvironment,
					createAnalyticsEvent,
				);

				if (tsbIssuePath !== undefined) {
					setCtaLink(tsbIssuePath);
				}
			}
		};
		getCtaLink().catch((e) => {
			fireErrorAnalytics({
				error: e,
				meta: {
					id: 'getTrialSprintBoardProjectData',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
			});
		});
	}, [cloudId, createAnalyticsEvent, relayEnvironment]);

	return ctaLink;
};
