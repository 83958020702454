import React from 'react';
import type DashboardViewEmbedType from '@atlassian/jira-dashboard-view-embed/src/index.tsx';
import type DashboardViewType from '@atlassian/jira-dashboard-view/src/index.tsx';
import {
	dashboardConfigAndWRMResource,
	dashboardAMDModulesResource,
	dashboardReactGadgetsResource,
	dashboardForgeResource,
} from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import type SystemDashboardEditType from '@atlassian/jira-spa-apps-dashboard-system-dashboard-edit/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const dashboardResources = () => [
	dashboardConfigAndWRMResource,
	dashboardAMDModulesResource,
	dashboardReactGadgetsResource,
	dashboardForgeResource,
];

export const LazyDashboardView = lazyForPaint<typeof DashboardViewType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-dashboard-view", jiraSpaEntry: "async-dashboard-view" */ '@atlassian/jira-dashboard-view'
		),
	),
);

export const LazyDashboardViewEmbed = lazyForPaint<typeof DashboardViewEmbedType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-dashboard-view-embed", jiraSpaEntry: "async-dashboard-view-embed" */ '@atlassian/jira-dashboard-view-embed'
		),
	),
);

export const LazySystemDashboardEdit = lazyForPaint<typeof SystemDashboardEditType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-dashboard-edit", jiraSpaEntry: "async-system-dashboard-edit" */ '@atlassian/jira-spa-apps-dashboard-system-dashboard-edit'
		),
	),
);

export const DashboardViewEmbedPage = () => (
	<LazyPage Page={LazyDashboardViewEmbed} pageId="dashboard" shouldShowSpinner />
);

export const SystemDashboardEditPage = () => (
	<LazyPage
		Page={LazySystemDashboardEdit}
		pageId="system-dashboard-edit"
		shouldShowSpinner={false}
	/>
);
