// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createActionsHook,
	createHook,
	type Action,
	type BoundActions,
} from '@atlassian/react-sweet-state';
import type { DiffItem, OnSearchCallback } from '../../common/types.tsx';

type ModalDismissSource =
	| 'CROSS_CLOSE_BUTTON'
	| 'ACCEPT_FIX'
	| 'ON_ENTER_NL_MODE'
	| 'NETWORK_CALL_ABORT'
	| 'ON_NEW_JQL_SEARCH';

export type State = {
	jqlQuery: string;
	userQueryError: string[];
	onSearch: NonNullable<OnSearchCallback>;
	status:
		| 'INIT'
		| 'LOADING_RESULT'
		| 'VIEW_RESULT'
		| 'RESULT_ERROR'
		| 'JS_ERROR'
		| 'UNRECOVERABLE_ERROR';
	jqlDiffTokens: DiffItem[];
	isAiEnabled: boolean;
	syntaxCorrectionAttemptErrors: string[];
	singleInstrumentationID: string;
	onAiInteractionDismissed: (source: ModalDismissSource) => void;
	onJsError: () => void;
	exitNaturalLanguageMode: () => void;
	errorSectionTestId: string;
	jqlErrors: string[];
	errorComponentValidationId?: string;
	errorComponentTestId: string;
};

export type Actions = {
	setJqlQuery: (query: State['jqlQuery']) => Action<State>;
	setOnSearch: (status: State['onSearch']) => Action<State>;
	setStatus: (status: State['status']) => Action<State>;
	resetStatus: (source: ModalDismissSource) => Action<State>;
	setIsAiEnabled: (isAiEnabled: boolean) => Action<State>;
	setStateOnFeatureInvoke: (
		data: Pick<State, 'jqlQuery' | 'singleInstrumentationID' | 'errorSectionTestId'>,
	) => Action<State>;
	setJqlErorCorrectionData: (
		data: Pick<
			State,
			'jqlDiffTokens' | 'userQueryError' | 'onSearch' | 'syntaxCorrectionAttemptErrors'
		>,
	) => Action<State>;
	setOnAiInteractionDismissed: (callback: State['onAiInteractionDismissed']) => Action<State>;
	setOnJsError: (callback: State['onJsError']) => Action<State>;
	exitNaturalLanguageMode: () => Action<State>;
	setExitNaturalLanguageMode: (callback: State['exitNaturalLanguageMode']) => Action<State>;
	setJqlErrors: (callback: State['jqlErrors']) => Action<State>;
	setErrorComponentValidationId: (callback: State['errorComponentValidationId']) => Action<State>;
	setErrorComponentTestId: (callback: State['errorComponentTestId']) => Action<State>;
};

const initialState: State = {
	jqlQuery: '',
	userQueryError: [],
	onSearch: () => undefined,
	status: 'INIT',
	jqlDiffTokens: [],
	isAiEnabled: false,
	syntaxCorrectionAttemptErrors: [],
	singleInstrumentationID: '',
	errorSectionTestId: '',
	onAiInteractionDismissed() {
		// do nothing
	},
	exitNaturalLanguageMode() {
		// do nothing
	},
	onJsError() {
		// do nothing
	},
	jqlErrors: [],
	errorComponentValidationId: '',
	errorComponentTestId: '',
};

const Store = createStore<State, Actions>({
	initialState,
	// actions that trigger store mutation
	actions: {
		setJqlQuery:
			(jqlQuery) =>
			({ setState }) => {
				setState({ jqlQuery });
			},
		setOnSearch:
			(onSearch) =>
			({ setState }) => {
				setState({ onSearch });
			},
		setStatus:
			(status) =>
			({ setState, getState }) => {
				if (status === 'JS_ERROR') {
					getState().onJsError();
				}
				setState({ status });
			},
		resetStatus:
			(source: ModalDismissSource) =>
			({ setState, getState }) => {
				if (getState().status !== 'INIT') {
					// AI modal gets dismissed when we accept a suggestion
					// However, we don't want to fire the `aiInteraction Dismissed` event in that case
					if (source !== 'ACCEPT_FIX') {
						getState().onAiInteractionDismissed(source);
					}
					setState({
						status: 'INIT',

						// Reset the aiInteractionDismissed handler so that duplicate events won't be fired
						onAiInteractionDismissed: initialState.onAiInteractionDismissed,
					});
				}
			},
		setStateOnFeatureInvoke:
			(initialDataOnInvoke) =>
			({ setState }) => {
				setState({
					...initialDataOnInvoke,
					status: 'LOADING_RESULT',
				});
			},
		setJqlErorCorrectionData:
			(data) =>
			({ setState }) => {
				setState({
					...data,
					status: 'VIEW_RESULT',
				});
			},
		setIsAiEnabled:
			(isAiEnabled) =>
			({ setState }) => {
				setState({ isAiEnabled });
			},
		setOnAiInteractionDismissed:
			(onAiInteractionDismissed) =>
			({ setState }) => {
				setState({ onAiInteractionDismissed });
			},
		setOnJsError:
			(onJsError) =>
			({ setState }) => {
				setState({ onJsError });
			},
		exitNaturalLanguageMode:
			() =>
			({ getState }) => {
				getState().exitNaturalLanguageMode?.();
			},
		setExitNaturalLanguageMode:
			(exitNaturalLanguageMode) =>
			({ setState }) => {
				setState({ exitNaturalLanguageMode });
			},
		setJqlErrors:
			(jqlErrors) =>
			({ setState }) =>
				setState({ jqlErrors }),
		setErrorComponentValidationId:
			(errorComponentValidationId) =>
			({ setState }) =>
				setState({ errorComponentValidationId }),
		setErrorComponentTestId:
			(errorComponentValidationId) =>
			({ setState }) =>
				setState({ errorComponentValidationId }),
	},
});

export const useJqlDebuggerStoreActions = createActionsHook(Store);

export const useStoreData = createHook(Store);

export const useDebuggerStatus = createHook(Store, {
	selector: (state) => state.status,
});

export const useJqlErrors = createHook(Store, {
	selector: (state) => state.jqlErrors,
});

export const useJqlEditorOnSearch = createHook(Store, {
	selector: (state) => state.onSearch,
});

export const useJqlQuery = createHook(Store, {
	selector: (state) => state.jqlQuery,
});

export const useIsAiEnabled = createHook(Store, {
	selector: (state) => state.isAiEnabled,
});

export const useErrorComponentTestId = createHook(Store, {
	selector: (state) => state.errorComponentTestId,
});

export const useErrorComponentValidationId = createHook(Store, {
	selector: (state) => state.errorComponentValidationId,
});

export type StoreActionsShape = BoundActions<State, Actions>;
