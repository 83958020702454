/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/HorizontalNavQueueInnerQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JiraServicedeskQueueAri } from '@atlassian/ari/jira-servicedesk/queue';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common/src/index.tsx';

export const horizontalNavQueueEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-horizontal-nav-queue-inner-entrypoint" */ './src/ui/HorizontalNavQueueInner.tsx'
			),
	),
	getPreloadProps: ({
		context: { match, query },
		tenantContext: { activationId, cloudId },
	}: EntryPointRouteParams) => {
		const queueId = match.params.queueId;
		let { projectKey, projectId } = getProjectKeyId(match, query);
		projectKey = projectKey ?? '';
		projectId = projectId ?? '';
		const projectIdOrKey = projectId || projectKey;

		const queueAri = queueId
			? JiraServicedeskQueueAri.create({
					siteId: cloudId,
					queueId: queueId || '',
					activationId,
				}).toString()
			: '';

		const containerNavigationInput = queueAri
			? { scopeId: queueAri }
			: { projectKeyQuery: { cloudId, projectKey } };
		return {
			queries: {
				horizontalNavQueueQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						cloudId,
						projectIdOrKey,
						containerNavigationInput,
						first: NAVIGATION_ITEMS_PAGE_SIZE,
					},
				},
			},
		};
	},
});
