import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { projectSettingsOpsgenieRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsOpsgenieRoute.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import LinkOpsgeniePage, {
	LazyLinkOpsgeniePage,
} from './ui/project-settings/link-opsgenie/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';

export const projectSettingsOpsgenieRouteEntry = createEntry(projectSettingsOpsgenieRoute, () => ({
	group: 'project-settings-software',
	component: componentWithCondition(
		isDevopsFeatureDisabledInFedRamp,
		ErrorPagesNotFound,
		LinkOpsgeniePage,
	),
	layout: softwareProjectLayoutNoOnboarding,
	resources: [...getSettingsNavigationResources()],
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},
	forPaint: [LazyAtlassianNavigation, LazyLinkOpsgeniePage],
	ufoName: 'project-settings.link-opsgenie',
}));
