// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import type { State, Attributes } from './types.tsx';

type Actions = typeof actions;

const initialState: State = {
	items: null,
	fetchError: null,
	hasSuccessOnce: false,
	isFetching: false,
	promise: null,
};

const store = createStore({
	actions,
	initialState,
	name: 'navigation-your-work-recent-activites',
});

export const RecentActivityContainer = createContainer<State, Actions, Attributes>(store);
export const useRecentActivities = createHook(store);
export const useRecentActivitiesActions = createHook(store, {
	selector: null,
});
