import type {
	GlobalAppMenuId,
	JiraConfigurableNavigationItem,
	MenuItemConfigData,
} from '../../types.tsx';
import {
	hasGlobalAppLicense,
	isGlobalAppMenuId,
	type GlobalAppsLicense,
} from '../global-apps/index.tsx';

/**
 * Gets all the ConfigurableMenuIds that are NOT global apps for platform ingestion.
 * MenuIds and GlobalAppIdsare split because \@atlassian/customization-modal and
 * \@atlassian/global-more-menu take in separate props for apps/product items.
 */
export const getProductItemsData = (
	navItems: JiraConfigurableNavigationItem[],
): MenuItemConfigData[] =>
	navItems
		.filter((navItem) => !isGlobalAppMenuId(navItem.menuId))
		.map((item) => {
			return {
				menuId: item.menuId,
				visible: item.isVisible,
			};
		});

/**
 * Gets only GlobalAppMenuIds from ConfigurableMenuIds for platform ingestion.
 * MenuIds and GlobalAppIds are split because \@atlassian/customization-modal and
 * \@atlassian/global-more-menu take in separate props for apps/product items.
 */
export const getAppItemsData = (
	navItems: JiraConfigurableNavigationItem[],
	globalAppLicense: GlobalAppsLicense,
): MenuItemConfigData[] =>
	navItems
		.filter((navItem) => isGlobalAppMenuId(navItem.menuId))
		.filter((navItem) => hasGlobalAppLicense(navItem.menuId, globalAppLicense))
		.map((item) => {
			return {
				menuId: item.menuId,
				visible: item.isVisible,
			};
		});

/**
 * Returns all the currently visible global apps.
 */
export const getVisibleAppItemsData = (
	navItems: JiraConfigurableNavigationItem[],
	globalAppLicense: GlobalAppsLicense,
): GlobalAppMenuId[] => {
	return navItems
		.filter((navItem) => navItem.isVisible)
		.map(({ menuId }) => menuId)
		.filter(isGlobalAppMenuId)
		.filter((menuId) => hasGlobalAppLicense(menuId, globalAppLicense));
};
