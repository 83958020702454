import { fg } from '@atlassian/jira-feature-gating';
import { getIssueKey } from '@atlassian/jira-router-resources-utils/src/utils/get-issue-key/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { browserOnlyResourceWithCondition } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { IS_MODERN_SSR } from '@atlassian/jira-conditional-ssr-render/src/constants.tsx';
import { withResourceEarlyData } from '../../utils.tsx';
import getData from './get-data/index.tsx';

export const issueGiraDataResource = browserOnlyResourceWithCondition(
	() => {
		if (IS_MODERN_SSR) {
			// not "browser only" if FF is on
			return !fg('issue-view-ssr-perf-m1');
		}
		return true;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createResource<any>(
		withResourceEarlyData({
			type: 'ISSUE_GIRA_DATA',
			getKey: ({ match }) => {
				const issueKey = getIssueKey(match);
				return issueKey || '';
			},
			getData: (...args) => getData(...args),
			isBrowserOnly: true,
		}),
	),
);
