import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeViewMenuItemLabel: {
		id: 'navigation-apps-sidebar-nav4-display-settings.change-view-action.change-view-menu-item-label',
		defaultMessage: 'Change view',
		description: 'Used as the button label text for the "Change view" menu item',
	},
	starredAndRecentProjectsLabel: {
		id: 'navigation-apps-sidebar-nav4-display-settings.change-view-action.starred-and-recent-projects-label',
		defaultMessage: 'Starred and recent projects',
		description: 'Used as the label text for the Starred and recent projects option',
	},
	starredProjectsLabel: {
		id: 'navigation-apps-sidebar-nav4-display-settings.change-view-action.starred-projects-label',
		defaultMessage: 'Starred projects only',
		description: 'Used as the label text for the Starred projects only option',
	},
	mostRecentOnlyLabel: {
		id: 'navigation-apps-sidebar-nav4-display-settings.change-view-action.most-recent-only-label',
		defaultMessage: 'Most recent project only',
		description: 'Used as the label text for the Most recent project only option',
	},
	listBulletedIcon: {
		id: 'navigation-apps-sidebar-nav4-display-settings.change-view-action.list-bulleted-icon',
		defaultMessage: 'List bulleted icon',
		description: 'The label for the list bulleted icon',
	},
	chevronRightIcon: {
		id: 'navigation-apps-sidebar-nav4-display-settings.change-view-action.chevron-right-icon',
		defaultMessage: 'Chevron right icon',
		description: 'The label for the chevron right icon',
	},
});
