import React, { useCallback, useEffect, useMemo } from 'react';
import { ChoreographerContextProvider } from '@atlassian/jira-choreographer-services/src/ChoreographerContextProvider.tsx';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { getTrialDaysLeft } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils/src/utils.tsx';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import {
	SOFTWARE_PROJECT,
	projectTypeToCanonicalId,
	toProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	ANALYTICS_SOURCE_NAME,
	PACKAGE_NAME,
	TEAM_NAME,
	STARGATE_URL,
} from '../../common/constants.tsx';
import {
	useTrialSprintBoardCtaLink,
	useIsNotTrialSprintBoardProject,
} from '../../controllers/index.tsx';
import { getIsTrialSprintBoardModalDismissed } from '../../services/index.tsx';
import { trialSprintBoardModalEntryPoint } from './entrypoint.tsx';
import type { AsyncTrialSprintBoardModalEntryPointProps } from './types.tsx';
import { getMessageId } from './utils.tsx';

export const EntryPointContainer = ({
	currentProjectId,
}: AsyncTrialSprintBoardModalEntryPointProps) => {
	const cloudId = useCloudId();
	const isNotTrialSprintBoardProject = useIsNotTrialSprintBoardProject(currentProjectId);
	const ctaLink = useTrialSprintBoardCtaLink();
	const { data: orgId } = useOrgId();

	const appEditions = useAppEditions();
	const { data: project } = useProject();
	const projectType = toProjectType(project?.type);

	const productEntitlementDetails = useProductEntitlementDetails();
	const productKey = projectType && projectTypeToCanonicalId(projectType);
	const entitlementDetails = productKey && productEntitlementDetails?.[productKey];

	const currentDate = useMemo(() => Date.now(), []);
	const daysLeft = getTrialDaysLeft(currentDate, entitlementDetails?.trialEndTime) ?? 0;

	// Show the modal three times 10/11, 12/13 and 14+ days after site creation
	const eligibleForFirstModal = daysLeft === 20 || daysLeft === 19;
	const eligibleForSecondModal = daysLeft === 18 || daysLeft === 17;
	const eligibleForThirdModal = daysLeft <= 16;
	const eligibleDaysLeft = eligibleForFirstModal || eligibleForSecondModal || eligibleForThirdModal;

	const messageId = getMessageId(daysLeft);

	const coordinationClient = useMemo(
		() => new CoordinationClient(cloudId, STARGATE_URL, false, orgId, 'engagement'),
		[cloudId, orgId],
	);

	const onClose = useCallback(() => {
		if (messageId) {
			coordinationClient.stop(messageId);
		}
	}, [coordinationClient, messageId]);

	const runtimeProps = useMemo(
		() => ({
			ctaLink,
			onClose,
			messageId: messageId ?? '',
			coordinationClient,
		}),
		[coordinationClient, ctaLink, messageId, onClose],
	);
	const entryPointParams = useMemo(() => ({}), []);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		trialSprintBoardModalEntryPoint,
		entryPointParams,
	);

	/**
	 * Eligibility criteria for TSB Modal
	 * - Must not currently be on the trial sprint board project page, but must be on a JSW project
	 * - Must be on JSW Premium edition
	 * - Must be in their 10/11th, 12/13th or 14th+ day in trial,
	 * - CTA Link has been generated correctly
	 * - Modal must not be dismissed forever by user (value stored in local storage)
	 *  */
	const isEligibleForTrialSprintBoardModal =
		isNotTrialSprintBoardProject &&
		projectType === SOFTWARE_PROJECT &&
		appEditions.software === PREMIUM_EDITION &&
		Boolean(ctaLink) &&
		eligibleDaysLeft &&
		getIsTrialSprintBoardModalDismissed() !== true &&
		messageId;

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isEligibleForTrialSprintBoardModal) {
			const conditionallyLoadEntryPoint = async () => {
				const started = await coordinationClient.start(messageId);
				if (started) {
					entryPointActions.load();
				}
			};
			conditionallyLoadEntryPoint();
		}
	}, [
		ctaLink,
		isNotTrialSprintBoardProject,
		coordinationClient,
		entryPointActions,
		isEligibleForTrialSprintBoardModal,
		daysLeft,
		messageId,
	]);

	return (
		isEligibleForTrialSprintBoardModal && (
			<ChoreographerContextProvider isChoreographed={coordinationClient.enableChoreographer}>
				<ModalEntryPointContainer
					id={ANALYTICS_SOURCE_NAME}
					packageName={PACKAGE_NAME}
					teamName={TEAM_NAME}
					errorFallback="unmount"
					runtimeProps={runtimeProps}
					entryPointActions={entryPointActions}
					entryPointReferenceSubject={entryPointReferenceSubject}
				/>
			</ChoreographerContextProvider>
		)
	);
};

export const AsyncTrialSprintBoardModalEntryPoint = (
	props: AsyncTrialSprintBoardModalEntryPointProps,
) => {
	return (
		<ErrorBoundary id={`async-${ANALYTICS_SOURCE_NAME}`} packageName={PACKAGE_NAME}>
			<ReportErrors
				teamName={TEAM_NAME}
				id={`async-${ANALYTICS_SOURCE_NAME}`}
				packageName={PACKAGE_NAME}
			>
				<EntryPointContainer {...props} />
			</ReportErrors>
		</ErrorBoundary>
	);
};
