import { fg } from '@atlassian/jira-feature-gating';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { productsJcsFormFieldsRoute } from '@atlassian/jira-router-routes-admin-pages-jcs-form-fields-routes/src/productsJcsFormFieldsRoute.tsx';
import { productsJcsFormFieldsEntryPoint as main } from '@atlassian/jira-spa-apps-products-jcs-form-fields/entrypoint.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { formFieldsResource } from '@atlassian/jira-router-resources-service-desk-customer-service-form-fields/src/services/index.tsx';

const productsJcsFormFieldsEntryPointPage = createPageEntryPoint({
	main,
	topNavigationMenuId: MENU_ID.SETTINGS,
});

export const productsJcsFormFieldsRouteEntry = createEntry(productsJcsFormFieldsRoute, () => ({
	group: 'global-settings',
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},
	component: ErrorPagesNotFound,
	entryPoint() {
		return fg('jcs_forms_resiliency_fast_follows')
			? productsJcsFormFieldsEntryPointPage
			: undefined;
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		formFieldsResource,
	],
}));
