import { isFedRamp } from '@atlassian/atl-context';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { pageLoadIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	forgeBoardModuleResource,
	forgeProjectModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarSoftwareClassic, {
	ForPaintClassicSoftwareProjectSidebar as AsyncForPaintSidebarSoftwareClassic,
} from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	shouldLoadUIFBoardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { classicSoftwareBoardRoute } from '@atlassian/jira-router-routes-software-classic-board-routes/src/classicSoftwareBoardRoute.tsx';
import {
	UIFBoardSpa,
	RapidBoardSpaPage,
} from '@atlassian/jira-router-routes-user-board-entries/src/ui/async.tsx';
import type { ViewModeType } from '@atlassian/jira-issue-view-common-types/src/context-type.tsx';
import { getViewModeFromLocalStorage } from '@atlassian/jira-issue-context-service/src/utils.tsx';
import { isLocalStorageAvailable } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/utils.tsx';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/ClassicBoardSkeleton.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { classicBoardCapability } from './common/constants.tsx';
import { classicBoardPageEntryPoint } from './ui/page-entry-point/index.tsx';

/**
 * Ordinarily it would be inadvisable to evaluate something like this in
 * a static route, as this could lead to inconsistent behaviour between
 * the server and the browser.
 *
 * In this case it is necessary and safe because:
 * 1) There are two versions of the board that share the same route
 *    and we can only tell them apart at runtime. When the old version
 *    is eventually removed, this should be cleaned up.
 * 2) No requests for board data are made on the server, so even
 *    if the value is incorrect on the server it won't be utilised
 * */

const getLayout = () => {
	let viewMode: ViewModeType = 'DEFAULT';
	if (isLocalStorageAvailable()) {
		viewMode = getViewModeFromLocalStorage();
	}
	return composeLayouts(
		genericProjectLayout,
		pageLoadIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }], viewMode),
	);
};

/**
 * @deprecated delete when cleaning up blu-5799-jira-software-board-entrypoint-route
 */
const getHorizontalNavResources = () =>
	fg('blu-5799-jira-software-board-entrypoint-route') ? [] : [horizontalNavJiraResource];

export const classicSoftwareBoardRouteEntry = createEntry(classicSoftwareBoardRoute, () => ({
	group: 'classic-software',
	ufoName: 'classic-board',
	component: RapidBoardSpaPage,
	skeleton: ClassicBoardSkeleton,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-board'),

	layout: getLayout(),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: componentWithFG(
			'make_nav_sidebar_lazyforpaint_in_board_and_backlog',
			AsyncForPaintSidebarSoftwareClassic,
			AsyncSidebarSoftwareClassic,
		),
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
		sidebarId: fg('blu-5799-jira-software-board-entrypoint-route') ? SIDEBAR_ID.CONTENT : undefined,
	},

	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// delete when cleaning up blu-5799-jira-software-board-entrypoint-route
		...getHorizontalNavResources(),
		// end of resources expanded from getNavigationSidebarProjectResource
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
		// Load the NBT resource if we opt-in UIF, not fedRamp is enabled
		resourceWithCondition2(
			() => shouldLoadUIFBoardResources() && !isFedRamp(),
			nextBestTaskFeatureResource,
		),
		getEditModelResource(),
		getBoardDetailsResource(),
		forgeBoardModuleResource,
		resourceWithCondition2(() => getIsSoftwareThemingEnabled(), projectThemeResource),
	],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, UIFBoardSpa],

	preloadOptions: {
		earlyChunks: ['software-cmp-board-early-entry'],
	},

	meta: {
		capability: classicBoardCapability,
	},

	earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,

	bifrost: {
		bundling: {
			packageName:
				'@atlassian/jira-router-routes-software-classic-board-entries/src/classicSoftwareBoardRouteEntry.tsx',
			whichExportToUse: 'classicSoftwareBoardRouteEntry',
		},
		enabled: true,
		manifestName: 'jira-spa-software-board.json',
	},

	entryPoint: () =>
		(fg('blu-7261-change-blu-5799-to-support-nav3') || getWillShowNav4()) &&
		fg('blu-5799-jira-software-board-entrypoint-route')
			? classicBoardPageEntryPoint
			: undefined,
}));
