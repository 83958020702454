import { resources as customerLogResources } from '@atlassian/eoc-customer-logs/resources';
import { resources as integrationAutomationResources } from '@atlassian/eoc-integration-automation/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalIntegrationLogRoute } from '@atlassian/jira-router-routes-eoc-integration-automation-routes/src/globalIntegrationLogRoute.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazySidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-operations/src/ui/index.tsx';
import { IntegrationAutomation } from './ui/IntegrationAutomation.tsx';
import { IntegrationLog } from './ui/IntegrationLog.tsx';
import { LazyIntegrationAutomation } from './ui/async.tsx';

const getNavigationSpecifications = () => {
	return fg('operations_nav4_remaining_items')
		? {
				sidebarId: SIDEBAR_ID.OPERATIONS_GENERAL_CONFIGURATION,
			}
		: {
				sidebar: AsyncSidebarGlobalSettingsProducts,
				horizontal: AsyncHorizontalOperationsConfigurationNav,
				onlyShowHorizontalOnNav4: true,
			};
};

export const globalIntegrationLogRouteEntry = createEntry(globalIntegrationLogRoute, () => ({
	group: 'eoc',
	basePath: '/jira/settings/products/ops',
	component: IntegrationAutomation,
	slot: IntegrationLog,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...customerLogResources(),
		integrationAutomationResources.configuredIntegrationsResource,
		integrationAutomationResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		...getNavigationSpecifications(),
	},
	forPaint: fg('operations_nav4_remaining_items')
		? [LazySidebarContent, LazyAtlassianNavigation, LazyIntegrationAutomation]
		: [
				LazyHorizontalOperationsConfigurationNav,
				LazyAtlassianNavigation,
				LazyIntegrationAutomation,
			],
}));
