/**
 * @generated SignedSource<<ec0d5db49437667573ca835bd84e9756>>
 * @relayHash 841436c7487840af84c959f83e5a4493
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4443dbc3773e515f952da0ae8f171c483657c4548c4768b6b9c144da0822a4f1

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineQuery } from './src_jiraBusinessTimelineQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4443dbc3773e515f952da0ae8f171c483657c4548c4768b6b9c144da0822a4f1",
    "metadata": {},
    "name": "src_jiraBusinessTimelineQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
