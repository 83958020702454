import React, { useRef } from 'react';
import { NAVIGATION_TOP_MARKS } from '@atlassian/jira-atlassian-navigation-performance-metrics/src/constants.tsx';
import {
	NavTopPerformanceMark,
	NavTopSSRPerformanceMark,
} from '@atlassian/jira-atlassian-navigation-performance-metrics/src/index.tsx';
import { EXPERIENCE_NAVIGATION_TOP_MAIN } from '@atlassian/jira-atlassian-navigation/src/common/constants.tsx';
import { AtlassianNavigationStart } from '@atlassian/jira-atlassian-navigation/src/controllers/atlassian-navigation-start/index.tsx';
import { useNav4Theme } from '@atlassian/jira-atlassian-navigation/src/controllers/theme/main.tsx';
import { JiraAtlassianNavigationSkeleton as TopNavigationSkeleton } from '@atlassian/jira-atlassian-navigation/src/ui/skeleton/index.tsx';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout.tsx';
import { gridSize, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsFullscreen } from '@atlassian/jira-fullscreen-button/src/common/utils/hooks/use-is-fullscreen/index.tsx';
import { useTopNavState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/top-nav-controller/index.tsx';
import { sendNavExposure } from '@atlassian/jira-navigation-apps-nav-exposure/src/services/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	TopNavigation,
	UNSAFE_DO_NOT_USE_BANNER_HEIGHT,
} from '@atlassian/jira-navigation-system/src/index.tsx';
import Placeholder, { PlaceholderNew } from '@atlassian/jira-placeholder/src/index.tsx';
import { RenderTracerMark } from '@atlassian/jira-render-tracer-analytics/src/main.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { TopNavigationSkipLink } from '../page-container/page-container-skip-links/index.tsx';
import { ZIndexWrapper } from '../z-index-wrapper/index.tsx';
import { AsyncAtlassianNavigation } from './async-atlassian-navigation/index.tsx';

/* IMPORTANT:
 * When making any changes to the OLD component, please ensure that you also make the same changes to the
 * NEW component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */

const APP_NAVIGATION_TRACE_KEY = 'app_navigation';
const LegacyPageNavigationStyles = () => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
	<style
		// eslint-disable-next-line react/no-danger
		dangerouslySetInnerHTML={{
			__html: `
                    #page-body { width: 100vw; max-width: 100%; }
                    #cloneissueprogressform { margin-top: ${gridSize * 3}px; }
                    [data-testid="Content"] {margin-top: calc(${PAGE_LAYOUT_OFFSET_TOP})!important;}
                    #view-projects-content { margin-top: calc(-1 * ${PAGE_LAYOUT_OFFSET_TOP}); } /* admin project dir hack */
                `,
		}}
	/>
);

/**
 * This test ID is used in pollinator tests. Do not change lightly.
 * It's only added once the component is rendered client-side.
 */
const TEST_ID = 'page-layout.top-nav';

function NewTopNav() {
	const { shouldRender } = useTopNavState();

	const theme = useNav4Theme();

	// Signal that Nav4 has been seen. Memoized, so it's safe to use it like this.
	if (fg('jira_nav_version_exposure')) {
		sendNavExposure({ componentName: 'nav4' });
	}

	if (!shouldRender) {
		return null;
	}

	return (
		<UFOSegment name="nav4.topNav">
			<JSErrorBoundary
				id="topNavigationSkipLink"
				packageName="jiraPageContainerV2"
				fallback="unmount"
			>
				<TopNavigationSkipLink />
			</JSErrorBoundary>
			<LegacyPageNavigationStyles />

			<TopNavigation isFixed testId={TEST_ID} theme={theme}>
				{!__SERVER__ && <AtlassianNavigationStart />}
				<JSErrorBoundary
					id="navigation.atlassian-navigation"
					packageName="jiraPageContainerV2"
					fallback="flag"
				>
					<PlaceholderNew name="top_navigation_skeleton" fallback={<TopNavigationSkeleton />}>
						<AsyncAtlassianNavigation />
					</PlaceholderNew>
				</JSErrorBoundary>
			</TopNavigation>
		</UFOSegment>
	);
}

/**
 * This has been inlined from `@atlassian/jira-common-constants/src/page-layout`
 * because it isn't used anywhere else, and to prevent new usage.
 */
const HORIZONTAL_GLOBAL_NAV_HEIGHT = 56;

/**
 * Implements a responsive top navigation bar that adjusts for fullscreen view and renders
 * based on application state. It ensures seamless navigation experience across different
 * sections of the application while handling errors gracefully and tracking performance.
 */
function OldTopNav() {
	const fullscreenRef = useRef<HTMLDivElement>(null);
	const { isFullscreen } = useIsFullscreen(fullscreenRef.current);
	const { shouldRender } = useTopNavState();
	const onFail = useExperienceFail({
		experience: EXPERIENCE_NAVIGATION_TOP_MAIN,
	});

	if (fg('jira_nav_version_exposure')) {
		sendNavExposure({ componentName: 'nav3' });
	}

	const height = isFullscreen ? 0 : HORIZONTAL_GLOBAL_NAV_HEIGHT;
	if (!shouldRender) {
		return null;
	}
	return (
		<UFOSegment name="top-navigation">
			<JSErrorBoundary
				id="topNavigationSkipLink"
				packageName="jiraPageContainer"
				fallback="unmount"
			>
				<TopNavigationSkipLink />
			</JSErrorBoundary>
			<LegacyPageNavigationStyles />
			<ZIndexWrapper
				zIndex={layers.navigation}
				// @ts-expect-error - TS2322 - Type '{ children: Element; zIndex: 200; top: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, any>, any, any>> & Readonly<...> & Readonly<...>'.
				top={UNSAFE_DO_NOT_USE_BANNER_HEIGHT}
				data-fullscreen-id="fullscreen.page-container-v2.topnav"
				ref={fullscreenRef}
			>
				<TopNavigation isFixed height={height} id="ak-jira-navigation">
					{!__SERVER__ && <AtlassianNavigationStart />}
					<JSErrorBoundary
						id="navigation.atlassian-navigation"
						packageName="jiraPageContainer"
						fallback="flag"
						onError={onFail}
					>
						<Placeholder name="top_navigation_skeleton" fallback={<TopNavigationSkeleton />}>
							<NavTopPerformanceMark metricKey={NAVIGATION_TOP_MARKS.bundleLoadStart} />
							<NavTopSSRPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderSsrStart} />
							<RenderTracerMark type="start" traceKey={APP_NAVIGATION_TRACE_KEY} />
							<AsyncAtlassianNavigation />
							<RenderTracerMark type="end" traceKey={APP_NAVIGATION_TRACE_KEY} />
							<NavTopSSRPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderSsrEnd} />
						</Placeholder>
					</JSErrorBoundary>
				</TopNavigation>
			</ZIndexWrapper>
		</UFOSegment>
	);
}

export const TopNav = componentWithCondition(getWillShowNav4, NewTopNav, OldTopNav);
