import { useEffect, useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { usePageLayoutResize } from '@atlassian/jira-navigation-system/src/index.tsx';
import { NAVIGATION_UI_STORAGE_KEY } from '@atlassian/jira-router-resources-navigation-ui-state/src/constants.tsx';
import { navigationUiStateResource } from '@atlassian/jira-router-resources-navigation-ui-state/src/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { useLayoutStoreActions, DEFAULT_LEFT_SIDEBAR_WIDTH } from '../../store/index.tsx';

import { useLeftSidebarState } from '../left-sidebar-controller/index.tsx';
import { useRightSidebarState } from '../right-sidebar-controller/index.tsx';
import type { RightSidebarState } from '../../../../common/types.tsx';
import { setRemoteLayoutState } from './set-remote-layout-state/index.tsx';

/**
 * Synchronizes the layout's local state with the remote server to ensure consistency across sessions.
 * It monitors changes in the layout, such as sidebar adjustments, and updates the server accordingly.
 * Critical errors encountered during the update process are captured and reported to facilitate
 * troubleshooting and continuous improvement. This component plays a pivotal role in maintaining
 * a seamless user experience by ensuring that layout preferences are consistently reflected.
 */
const LayoutRemoteStateUpdaterOld = () => {
	// left-sidebar
	const { leftSidebarState } = usePageLayoutResize();
	const { isFlyoutOpen, isLeftSidebarCollapsed, leftSidebarWidth } = leftSidebarState;
	const resolvedLeftSidebarWidth: number = Math.max(leftSidebarWidth, DEFAULT_LEFT_SIDEBAR_WIDTH);
	const [, { setLeftSidebarState }] = useLayoutStoreActions();
	const isCollapsed: boolean = leftSidebarState.isLeftSidebarCollapsed;

	// right-sidebar
	const rightSidebarState = useRightSidebarState();
	const accountId = useAccountId();
	const { data, loading, error } = useResource(navigationUiStateResource);
	const isLayoutStateReady = leftSidebarWidth !== 0 && leftSidebarState.lastLeftSidebarWidth !== 0;
	useEffect(() => {
		if (!isLayoutStateReady) return;
		if (error) return;
		setLeftSidebarState({
			width: resolvedLeftSidebarWidth,
			isCollapsed,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			isFlyoutOpen: isFlyoutOpen as boolean,
			isVisible: true,
		});
	}, [
		error,
		isCollapsed,
		isFlyoutOpen,
		isLayoutStateReady,
		resolvedLeftSidebarWidth,
		setLeftSidebarState,
	]);
	const updateData = useCallback(async () => {
		if (loading) return;
		if (error) return;
		if (!isLayoutStateReady) return;
		const remoteData = {
			...data,
			leftSidebar: {
				width: resolvedLeftSidebarWidth,
				isCollapsed: isLeftSidebarCollapsed,
			},
			rightSidebar: rightSidebarState,
		};
		if (accountId) {
			try {
				await setUserProperties(accountId, NAVIGATION_UI_STORAGE_KEY, JSON.stringify(remoteData));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				fireErrorAnalytics({
					error: e,
					meta: {
						id: 'updateServerLayoutState',
						packageName: 'jiraLayoutController',

						teamName: 'magma',
					},
				});
			}
		}
	}, [
		accountId,
		data,
		error,
		isLayoutStateReady,
		isLeftSidebarCollapsed,
		loading,
		resolvedLeftSidebarWidth,
		rightSidebarState,
	]);
	useEffect(() => {
		updateData();
	}, [isLeftSidebarCollapsed, resolvedLeftSidebarWidth, updateData]);
	return null;
};

const LayoutRemoteStateUpdaterOldImprovedStateManagement = () => {
	// This prevents eccessive state updates on page loads
	const [initialLoad, setInitialLoad] = useState(true);
	const [localLeftSidebarWidthState, setLocalLeftSidebarWidthState] = useState<number>();
	const [localLeftSidebarCollapsedState, setLocalLeftSidebarCollapsedState] = useState<boolean>();
	const [localRightSidebarState, setLocalRightSidebarState] = useState<RightSidebarState>();

	// left-sidebar
	const { leftSidebarState } = usePageLayoutResize();
	const { isFlyoutOpen, isLeftSidebarCollapsed, leftSidebarWidth } = leftSidebarState;
	const resolvedLeftSidebarWidth: number = Math.max(leftSidebarWidth, DEFAULT_LEFT_SIDEBAR_WIDTH);
	const [, { setLeftSidebarState }] = useLayoutStoreActions();
	const isCollapsed: boolean = leftSidebarState.isLeftSidebarCollapsed;

	// right-sidebar
	const rightSidebarState = useRightSidebarState();
	const accountId = useAccountId();
	const { data, loading, error } = useResource(navigationUiStateResource);
	const isLayoutStateReady = leftSidebarWidth !== 0 && leftSidebarState.lastLeftSidebarWidth !== 0;

	// compares local vs external state coming via chain of callbacks
	// to determine if we really need to send an update of layout state
	const sideBarChanged = useCallback(
		(
			externalLeftSidebarWidth: number,
			externalIsLeftSidebarCollapsed: boolean,
			externalRightSidebarState: RightSidebarState,
		): boolean => {
			let changeDetected = false;
			if (!isEqual(externalLeftSidebarWidth, localLeftSidebarWidthState)) {
				setLocalLeftSidebarWidthState(externalLeftSidebarWidth);
				changeDetected = true;
			}
			if (!isEqual(externalIsLeftSidebarCollapsed, localLeftSidebarCollapsedState)) {
				setLocalLeftSidebarCollapsedState(externalIsLeftSidebarCollapsed);
				changeDetected = true;
			}
			if (!isEqual(externalRightSidebarState, localRightSidebarState)) {
				setLocalRightSidebarState(externalRightSidebarState);
				changeDetected = true;
			}
			return changeDetected;
		},
		[localLeftSidebarCollapsedState, localLeftSidebarWidthState, localRightSidebarState],
	);

	useEffect(() => {
		if (!isLayoutStateReady) return;
		if (error) return;
		setLeftSidebarState({
			width: resolvedLeftSidebarWidth,
			isCollapsed,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			isFlyoutOpen: isFlyoutOpen as boolean,
			isVisible: true,
		});
	}, [
		error,
		isCollapsed,
		isFlyoutOpen,
		isLayoutStateReady,
		resolvedLeftSidebarWidth,
		setLeftSidebarState,
	]);
	const updateData = useCallback(async () => {
		if (loading) return;
		if (error) return;
		if (!isLayoutStateReady) return;
		if (!sideBarChanged(resolvedLeftSidebarWidth, isLeftSidebarCollapsed, rightSidebarState))
			return;
		const remoteData = {
			...data,
			leftSidebar: {
				width: resolvedLeftSidebarWidth,
				isCollapsed: isLeftSidebarCollapsed,
			},
			rightSidebar: rightSidebarState,
		};
		if (accountId) {
			try {
				await setUserProperties(accountId, NAVIGATION_UI_STORAGE_KEY, JSON.stringify(remoteData));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				fireErrorAnalytics({
					error: e,
					meta: {
						id: 'updateServerLayoutState',
						packageName: 'jiraLayoutController',

						teamName: 'magma',
					},
				});
			}
		}
	}, [
		accountId,
		data,
		error,
		isLayoutStateReady,
		isLeftSidebarCollapsed,
		loading,
		resolvedLeftSidebarWidth,
		rightSidebarState,
		sideBarChanged,
	]);
	useEffect(() => {
		if (initialLoad) {
			setInitialLoad(false);
			setLocalLeftSidebarWidthState(resolvedLeftSidebarWidth);
			setLocalLeftSidebarCollapsedState(isLeftSidebarCollapsed);
			setLocalRightSidebarState(rightSidebarState);
		} else {
			updateData();
		}
	}, [
		isLeftSidebarCollapsed,
		resolvedLeftSidebarWidth,
		updateData,
		rightSidebarState,
		initialLoad,
	]);
	return null;
};

/**
 * The old layout remote state updater used a hook to subscribe to the DS left sidebar state,
 * and would then update the `react-sweet-state` store as well as the remote state.
 *
 * Now the side nav integration is wired to update the `react-sweet-state` store directly,
 * and this will just update the remote state. This is because the new side nav does not expose its
 * state through a hook. We can change this in the future if necessary.
 *
 * In the future, we could also consider moving the remote update logic into the `react-sweet-state`
 * action which updates the left sidebar, removing the need for a subscriber like this.
 */
const LayoutRemoteStateUpdaterNew = () => {
	const accountId = useAccountId();
	const leftSidebar = useLeftSidebarState();
	const rightSidebar = useRightSidebarState();

	const isLeftSidebarCollapsed = leftSidebar.isCollapsed;
	const leftSidebarWidth = leftSidebar.width;

	useEffect(() => {
		if (!accountId) {
			return;
		}

		setRemoteLayoutState({
			accountId,
			state: {
				rightSidebar,
				leftSidebar: {
					isCollapsed: isLeftSidebarCollapsed,
					width: leftSidebarWidth,
				},
			},
		});
	}, [accountId, isLeftSidebarCollapsed, leftSidebarWidth, rightSidebar]);

	return null;
};

const LayoutRemoteStateUpdaterNewImprovedStateManagement = () => {
	const accountId = useAccountId();
	const leftSidebar = useLeftSidebarState();
	const rightSidebar = useRightSidebarState();

	const isLeftSidebarCollapsed = leftSidebar.isCollapsed;
	const leftSidebarWidth = leftSidebar.width;
	// This prevents eccessive state updates on page loads
	const [initialLoad, setInitialLoad] = useState(true);
	const [localLeftSidebarWidthState, setLocalLeftSidebarWidthState] = useState<number>();
	const [localLeftSidebarCollapsedState, setLocalLeftSidebarCollapsedState] = useState<boolean>();
	const [localRightSidebarState, setLocalRightSidebarState] = useState<RightSidebarState>();

	// compares local vs external state coming via chain of callbacks
	// to determine if we really need to send an update of layout state
	const sideBarChanged = useCallback(
		(
			externalLeftSidebarWidth: number,
			externalIsLeftSidebarCollapsed: boolean,
			externalRightSidebarState: RightSidebarState,
		): boolean => {
			let changeDetected = false;
			if (!isEqual(externalLeftSidebarWidth, localLeftSidebarWidthState)) {
				setLocalLeftSidebarWidthState(externalLeftSidebarWidth);
				changeDetected = true;
			}
			if (!isEqual(externalIsLeftSidebarCollapsed, localLeftSidebarCollapsedState)) {
				setLocalLeftSidebarCollapsedState(externalIsLeftSidebarCollapsed);
				changeDetected = true;
			}
			if (!isEqual(externalRightSidebarState, localRightSidebarState)) {
				setLocalRightSidebarState(externalRightSidebarState);
				changeDetected = true;
			}
			return changeDetected;
		},
		[localLeftSidebarCollapsedState, localLeftSidebarWidthState, localRightSidebarState],
	);

	useEffect(() => {
		if (!accountId) {
			return;
		}
		if (initialLoad) {
			setInitialLoad(false);
			setLocalLeftSidebarWidthState(leftSidebarWidth);
			setLocalLeftSidebarCollapsedState(isLeftSidebarCollapsed);
			setLocalRightSidebarState(rightSidebar);
			return;
		}
		if (!sideBarChanged(leftSidebarWidth, isLeftSidebarCollapsed, rightSidebar)) return;

		setRemoteLayoutState({
			accountId,
			state: {
				rightSidebar,
				leftSidebar: {
					isCollapsed: isLeftSidebarCollapsed,
					width: leftSidebarWidth,
				},
			},
		});
	}, [
		accountId,
		isLeftSidebarCollapsed,
		leftSidebarWidth,
		rightSidebar,
		initialLoad,
		sideBarChanged,
	]);

	return null;
};

const LayoutRemoteStateUpdaterNewFeatureGated = componentWithFG(
	'cher-1158-remove-excessive-sidebar-layout-updates',
	LayoutRemoteStateUpdaterNewImprovedStateManagement,
	LayoutRemoteStateUpdaterNew,
);

const LayoutRemoteStateUpdaterOldFeatureGated = componentWithFG(
	'cher-1158-remove-excessive-sidebar-layout-updates',
	LayoutRemoteStateUpdaterOldImprovedStateManagement,
	LayoutRemoteStateUpdaterOld,
);

export const LayoutRemoteStateUpdater = componentWithCondition(
	getWillShowNav4,
	LayoutRemoteStateUpdaterNewFeatureGated,
	LayoutRemoteStateUpdaterOldFeatureGated,
);
