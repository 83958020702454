import { type SerializableRecord } from '@post-office/serializable-record';
import {
	type AnyPlacementPipe,
	type MessageCategory,
	type MessageCreationType,
	type RecommendationSession,
	type RequestContext,
	type TransformMetadataResult,
} from '@post-office/shared-contracts';

import { MessageViewed } from './message-viewed-component';

type RenderPropsFrom<T extends AnyPlacementPipe> = Parameters<T['render']>[0];

type TransformMetadataPropsFrom<T extends AnyPlacementPipe> = Parameters<T['transformMetadata']>[0];

type PlacementContextFrom<T extends AnyPlacementPipe> = Parameters<T['hydrate']>[1];

type MessageContextFrom<T extends AnyPlacementPipe> = Parameters<T['hydrate']>[2];

type HydrateResponseFrom<T extends AnyPlacementPipe> = Awaited<ReturnType<T['hydrate']>>;

type ExtractStringKeys<T extends Record<PropertyKey, unknown>> = Extract<keyof T, string>;

type PlacementRenderPropsFrom<T extends AnyPlacementPipe> =
	ReturnType<T['hydrateExample']> extends Record<string, never>
		? RenderPropsFrom<T>
		: Omit<RenderPropsFrom<T>, ExtractStringKeys<ReturnType<T['hydrateExample']>>>;

type HydrateExampleConfig<T extends AnyPlacementPipe> =
	PlacementRenderPropsFrom<T> extends Record<string, never>
		? {
				requestContext?: Partial<RequestContext>;
			}
		: {
				requestContext?: Partial<RequestContext>;
				additionalRenderProps: PlacementRenderPropsFrom<T>;
			};

type BaseExampleHydrator<T extends AnyPlacementPipe> = (
	requestContext: Partial<RequestContext>,
	placementContext: PlacementContextFrom<T>,
	messageContext: MessageContextFrom<T>,
) => RenderPropsFrom<T>;

type ExampleHydrator<T extends AnyPlacementPipe> =
	PlacementRenderPropsFrom<T> extends Record<string, never>
		? (
				hydrateExample: T['hydrateExample'],
				config?: HydrateExampleConfig<T>,
			) => BaseExampleHydrator<T>
		: (
				hydrateExample: T['hydrateExample'],
				config: HydrateExampleConfig<T>,
			) => BaseExampleHydrator<T>;

export const initializeRender =
	<T extends AnyPlacementPipe>() =>
	<U extends (renderProps: RenderPropsFrom<T>) => JSX.Element | null>(render: U) =>
	(
		renderProps: RenderPropsFrom<T>, // render
	) => {
		const rendered = render(renderProps);
		return (
			<>
				<MessageViewed />
				{rendered}
			</>
		);
	};

export const initializeTransformMetadata =
	<T extends AnyPlacementPipe>() =>
	(transformMetadata: T['transformMetadata']) =>
	(params: TransformMetadataPropsFrom<T>): TransformMetadataResult =>
		transformMetadata(params);

export type Hydrator = <P extends Record<string, unknown>>(
	requestContext: RequestContext,
	placementContext: P,
	messageContext: SerializableRecord,
) => Promise<unknown>;

export type HydratedMessageShape = {
	messageTemplateId: string;
	messageInstanceId: string;
	messageCategory: MessageCategory;
	recommendationSession?: RecommendationSession;
	triggerId: string;
	messageCreationType?: MessageCreationType;
	data: Record<string, unknown>;
};

export const initializeHydrate =
	<T extends AnyPlacementPipe>() =>
	(hydrate: T['hydrate']) =>
	(
		requestContext: RequestContext,
		placementContext: PlacementContextFrom<T>,
		messageContext: MessageContextFrom<T>,
	) =>
		hydrate(requestContext, placementContext, messageContext);

const exampleHydrator =
	<T extends AnyPlacementPipe>(
		hydrateExample: T['hydrateExample'],
		config?: HydrateExampleConfig<T>,
	): BaseExampleHydrator<T> =>
	(_requestContext, placementContext, messageContext) => {
		const _config = config as
			| (HydrateExampleConfig<T> & { additionalRenderProps?: PlacementRenderPropsFrom<T> })
			| undefined;

		return {
			...hydrateExample({ ...(_config?.requestContext ?? {}) }, placementContext, messageContext),
			...(_config?.additionalRenderProps ?? {}),
		};
	};

export const initializeHydrateExample =
	<T extends AnyPlacementPipe>(): ExampleHydrator<T> =>
	(hydrateExample: T['hydrateExample'], config?: HydrateExampleConfig<T> | undefined) =>
		exampleHydrator<T>(hydrateExample, config);

const initializeValidate =
	<T extends AnyPlacementPipe>() =>
	(validate: (apiResponse: unknown) => HydrateResponseFrom<T>) =>
	(apiResponse: unknown): HydrateResponseFrom<T> => {
		try {
			validate(apiResponse);
		} catch (error) {
			throw error instanceof Error
				? error
				: new Error(typeof error === 'string' ? error : 'Unknown validation error');
		}

		return apiResponse as HydrateResponseFrom<T>;
	};

export const createServerPipeline = <T extends AnyPlacementPipe>() => ({
	createHydrate: initializeHydrate<T>(),
	createHydrateExample: initializeHydrateExample<T>(),
	createTransformMetadata: initializeTransformMetadata<T>(),
});

export const createClientPipeline = <T extends AnyPlacementPipe>() => ({
	createValidate: initializeValidate<T>(),
	createRender: initializeRender<T>(),
});
