// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import Store from '../../store.tsx';

const useSweetStatePageId = createHook(Store, {
	selector: (state) => state.currentPage.uuid,
});

export const usePageId = () => {
	const [pageId] = useSweetStatePageId();
	return pageId;
};
