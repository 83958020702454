import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getUserBoardNavigationResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { userBoardVelocityRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/userBoardVelocityRoute.tsx';
import VelocityPage, {
	LazyNextGenVelocityReport,
} from '@atlassian/jira-router-routes-next-gen-entries/src/ui/software/reports/velocity/index.tsx';

export const userBoardVelocityRouteEntry = createEntry(userBoardVelocityRoute, () => ({
	group: 'classic-software',
	ufoName: 'software-reports.velocity-report-cmp',
	component: VelocityPage,
	layout: genericProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
	},
	resources: [...getNavigationResources(), ...getUserBoardNavigationResources()],
	forPaint: [LazyAtlassianNavigation, LazyNextGenVelocityReport],
}));
