import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectSummaryRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskLegacyAutomationRedirectSummaryRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { automationRedirect } from './ui/service-desk-automation/redirect/index.tsx';

/* Old urls.
 * Do not remove these redirects, ever.
 * Because customers will have bookmarks and saved links and we need them to keep working forever.
 */
export const projectSettingsServicedeskLegacyAutomationRedirectSummaryRouteEntry = createEntry(
	projectSettingsServicedeskLegacyAutomationRedirectSummaryRoute,
	() => ({
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServiceDesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [],
		component: automationRedirect('summary'),
	}),
);
