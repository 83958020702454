import type { Metric } from '../types.tsx';
import messages from './messages.tsx';

export const CUSTOM_FIELDS_KEY = 'customField';

export const CUSTOM_FIELDS: Metric = {
	key: CUSTOM_FIELDS_KEY,
	visible: true,
	name: messages.name,
	description: messages.description,
	tabLabels: [messages.chart, messages.reviewAndAnalyze],
	warningMessage: messages.warning,
	exceededMessage: messages.exceeded,
	risks: [messages.risk1],
	mitigations: [],
	currentValueLabel: messages.currentValueLabel,
	currentValueAriaLabel: messages.currentValueAriaLabel,
	thresholdValueLabel: messages.thresholdValueLabel,
	thresholdValueAriaLabel: messages.thresholdValueAriaLabel,
	recommendationUrl:
		'https://support.atlassian.com/jira-cloud-administration/docs/optimize-your-custom-fields/',
	recommendationUrlText: messages.recommendationUrlText,
	graphHeader: messages.graphHeader,
	showTooltip: true,
};
