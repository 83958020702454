import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { advancedRoadmapsPlanPageInitialChecksResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { advancedRoadmapsSummaryDataResource } from '@atlassian/jira-router-resources-advanced-roadmaps-summary/src/index.tsx';
import { getNavigationSidebarPlanResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { arjSummaryEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-routes/src/arjSummaryEmbedRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { planSummaryEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-summary/entrypoint.tsx';
import { PlanSummaryEmbedPage, LazyPlanSummaryEmbed } from './ui/index.tsx';

const planSummaryEmbedRouteEntry = createChromelessEntryPoint({
	main: planSummaryEntryPoint,
});

export const arjSummaryEmbedRouteEntry = createEntry(arjSummaryEmbedRoute, () => ({
	group: 'software',
	component: componentWithFG('smart_links_for_plans', PlanSummaryEmbedPage, ErrorPagesNotFound),
	entryPoint: () => (fg('migrate_plans_to_entrypoints') ? planSummaryEmbedRouteEntry : undefined),
	layout: createLayout({
		isChromeless: true,
	}),

	resources: [
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsSummaryDataResource,
		themePreferenceResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	forPaint: [LazyPlanSummaryEmbed],
}));
