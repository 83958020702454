import React, { useCallback } from 'react';
import DeleteFiltersModal from '@atlassian/jira-delete-filters-modal/src/index.tsx';
import { useFlagService } from '@atlassian/jira-flags';
import { useQueryParam, useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

export type Props = {
	isOpen: boolean;
	name: string;
	filterId: string;
	onClose: () => void;
	onRefetch: (options: { onComplete: () => void }) => void;
};
export const DeleteFilterModal = ({ isOpen, name, filterId, onClose, onRefetch }: Props) => {
	const { showFlag } = useFlagService();

	const { push } = useRouterActions();
	const [filterQueryParam] = useQueryParam('filter');

	const onSuccess = useCallback(() => {
		onRefetch({ onComplete: () => undefined });

		if (filterQueryParam === filterId) {
			push('/jira/filters');
		}

		onClose();

		showFlag({
			id: 'filter-deleted',
			title: [messages.filterDeletedModalTitleSuccessNonFinal, { name }],
			type: 'success',
			messageId:
				'navigation-apps-sidebar-nav4-sidebars-content-filters.common.ui.filter-actions.delete-filter-modal.show-flag.success',
			messageType: 'transactional',
		});
	}, [filterId, filterQueryParam, name, onClose, onRefetch, push, showFlag]);

	const onFailure = useCallback(() => {
		onClose();
		showFlag({
			id: 'filter-delete-failed',
			title: messages.filterDeletedModalTitleErrorNonFinal,
			description: messages.filterDeletedModalDescriptionErrorNonFinal,
			type: 'error',
			messageId:
				'navigation-apps-sidebar-nav4-sidebars-content-filters.common.ui.filter-actions.delete-filter-modal.show-flag.error',
			messageType: 'transactional',
		});
	}, [onClose, showFlag]);

	const filter = { name, id: filterId };

	return (
		isOpen && (
			<DeleteFiltersModal
				baseUrl=""
				filter={filter}
				onFilterDeleted={onSuccess}
				onFilterDeleteFailed={onFailure}
				onFilterInitiateFailed={onFailure}
				onCancel={onClose}
			/>
		)
	);
};
