/** @jsx jsx */
import React, {
	forwardRef,
	type ReactNode,
	useCallback,
	type MouseEvent,
	type KeyboardEvent,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import { styled as styled2, css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import DefaultButton from '@atlaskit/button';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import type { SearchTemplate } from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { EPIC_LINK } from '../../constants.tsx';
import { ClearButton } from '../clear-button/index.tsx';
import { FieldDescriptionPopup } from '../field-description-popup/index.tsx';
import { LoadingStateAnimationWrapper } from '../loading-state/styled.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Button = styled2(DefaultButton)({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		overflow: 'visible',
	},
});

export type PickerTriggerProps = {
	isInvalid: boolean;
	isSelected: boolean;
	isLoading?: boolean;
	hasValues: boolean;
	children: ReactNode;
	testId: string;
	onClick: (event: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	onClear?: () => void;
	searchTemplate?: SearchTemplate;
	fieldDescription: string | null;
	isIrremovable?: boolean;
};

const isKeyboardEvent = (
	event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): event is KeyboardEvent<HTMLElement> => (event as KeyboardEvent).key !== undefined;

const PickerTrigger = forwardRef<HTMLElement, PickerTriggerProps>(
	(
		{
			children,
			isInvalid,
			isSelected,
			isLoading,
			hasValues,
			onClick,
			onClear,
			testId,
			searchTemplate,
			fieldDescription,
			isIrremovable = false,
		}: PickerTriggerProps,
		ref,
	) => {
		const intl = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleOnClear = useCallback(
			(e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
				e.stopPropagation();

				const isKeyboard = isKeyboardEvent(e);

				// only clear on click, enter key or space key
				const shouldClear = (isKeyboard && (e.key === 'Enter' || e.key === ' ')) || !isKeyboard;

				onClear && shouldClear && onClear();

				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				});
				fireUIAnalytics(analyticsEvent, 'removeFilter', {
					keyboard: isKeyboard,
				});
			},
			[createAnalyticsEvent, onClear],
		);

		if (isLoading === true) {
			return (
				<LoadingStateAnimationWrapper>
					<DefaultButton
						isSelected
						testId={`${testId}.loading`}
						{...(fg('add_nin_press_interactions') && {
							interactionName: 'jql-builder-basic-picker-trigger',
						})}
					>
						<ButtonContent>{children}</ButtonContent>
					</DefaultButton>
				</LoadingStateAnimationWrapper>
			);
		}

		const Content = (
			<ButtonContent>
				{searchTemplate === EPIC_LINK.searchTemplate && (
					<Tooltip
						content={
							<FormattedMessage
								{...messages.deprecatedEpicLinkMessage}
								values={{
									// @ts-expect-error - Type '(chunks: string) => JSX.Element' is not assignable to type 'ReactNode'.
									strong: (chunks: string) => (
										<Text as="strong" color="inherit" size="UNSAFE_small">
											{chunks}
										</Text>
									),
								}}
							/>
						}
					>
						<IconWrapperLeft>
							<WarningIcon label="" color={token('color.icon.warning')} LEGACY_size="small" />
						</IconWrapperLeft>
					</Tooltip>
				)}
				{children}
			</ButtonContent>
		);

		const IconAfterContent = (
			<IconWrapper visualRefresh={isVisualRefreshEnabled()}>
				{fieldDescription && (
					<FieldDescriptionPopup
						fieldDescription={fieldDescription}
						isParentPickerActive={hasValues || isSelected}
					/>
				)}
				{!isIrremovable ? (
					<ClearButton onClick={handleOnClear} label={intl.formatMessage(messages.removeField)} />
				) : (
					<ChevronDownIcon
						label=""
						color={
							// eslint-disable-next-line jira/ff/no-preconditioning
							isVisualRefreshEnabled() &&
							fg('visual-refresh-headers-buttons') &&
							!(isSelected || hasValues)
								? token('color.icon.subtle')
								: 'currentColor'
						}
					/>
				)}
			</IconWrapper>
		);

		return isInvalid ? (
			<InvalidButton
				isSelected={isSelected || hasValues}
				onClick={onClick}
				ref={ref}
				testId={testId}
				iconAfter={IconAfterContent}
			>
				{Content}
			</InvalidButton>
		) : (
			<Button
				isSelected={isSelected || hasValues}
				aria-expanded={isSelected}
				onClick={onClick}
				ref={ref}
				testId={testId}
				iconAfter={IconAfterContent}
			>
				{Content}
			</Button>
		);
	},
);

export default PickerTrigger;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContent = styled2.span({
	display: 'inline-flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled2.span<{ visualRefresh: boolean }>((props) => ({
	display: 'inline-flex',
	alignItems: 'center',
	/* Offset inner icon padding to match designs, as changing icon size to small (contrary
    /* to what Atlaskit docs say) does reduce icon shape size, and we don't want that */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	marginRight: props.visualRefresh ? token('space.0') : token('space.negative.075'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapperLeft = styled2.span({
	marginRight: token('space.050'),
	marginLeft: token('space.negative.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InvalidButton = forwardRef<HTMLElement, ComponentProps<typeof Button>>(
	(props, ref) => <Button {...props} ref={ref} css={styles} />,
);

const styles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text.danger')} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.danger.hovered')} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&:focus, &&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text.danger')} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.danger.hovered')} !important`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TooltipLink = styled2.a({
	color: token('color.link'),
	'&:hover': {
		color: token('color.link.pressed'),
	},
});
