/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JiraServicedeskQueueAri } from '@atlassian/ari/jira-servicedesk/queue';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common/src/index.tsx';
import mainSettingsNavigationQuery from '@atlassian/jira-relay/src/__generated__/mainSettingsNavigationQuery.graphql.ts';

export const queuesConfigEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() => {
		return import(
			/* webpackChunkName: "async-servicedesk-queues-config", jiraSpaEntry: "async-servicedesk-queues-config" */ './src/index.tsx'
		);
	}),
	getPreloadProps: ({
		context: { match, query, route },
		tenantContext: { activationId, cloudId },
	}: EntryPointRouteParams) => {
		const queueId = match.params.queueId;
		let { projectKey } = getProjectKeyId(match, query);
		projectKey = projectKey ?? '';

		const queueAri = queueId
			? JiraServicedeskQueueAri.create({
					siteId: cloudId,
					queueId: queueId || '',
					activationId,
				}).toString()
			: '';

		const input = queueAri ? { scopeId: queueAri } : { projectKeyQuery: { cloudId, projectKey } };
		return {
			queries: {
				mainSettingsNavigationQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters: mainSettingsNavigationQuery,
					variables: {
						input,
						first: NAVIGATION_ITEMS_PAGE_SIZE,
					},
				},
			},
			extraProps: { query, match, route },
		};
	},
});
