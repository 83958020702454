/**
 * @generated SignedSource<<d07eb7632c7c8519e83224e9ac018576>>
 * @relayHash 497df43b1252a8dc0b4f0ec9cd482e92
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fa621fa4bb6543d468797211342e1086ef2a6b35d10869637d74ad38c4e9685b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type srcReportsOverviewPageQuery$variables = {
  boardId: string;
  cloudId: string;
  isBoardContext: boolean;
  projectKey: string;
};
export type srcReportsOverviewPageQuery$data = {
  readonly jira_projectByIdOrKey?: {
    readonly projectType: JiraProjectType;
  };
  readonly " $fragmentSpreads": FragmentRefs<"reportCategories_reportsOverviewPage">;
};
export type srcReportsOverviewPageQuery = {
  response: srcReportsOverviewPageQuery$data;
  variables: srcReportsOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isBoardContext"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "idOrKey",
    "variableName": "projectKey"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "projectType"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "concreteType": "JiraReportCategoryConnection",
  "kind": "LinkedField",
  "name": "reportCategories",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraReportCategoryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraReportCategoryNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "concreteType": "JiraReportConnection",
              "kind": "LinkedField",
              "name": "reports",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraReportConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraReport",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        (v6/*: any*/),
                        {
                          "kind": "ScalarField",
                          "name": "key"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "imageUrl"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "description"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "url"
                        },
                        (v8/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            },
            (v8/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "srcReportsOverviewPageQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "reportCategories_reportsOverviewPage"
      },
      {
        "condition": "isBoardContext",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": (v4/*: any*/),
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "jira_projectByIdOrKey",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v5/*: any*/),
                  "action": "THROW",
                  "path": "jira_projectByIdOrKey.projectType"
                }
              ]
            },
            "action": "THROW",
            "path": "jira_projectByIdOrKey"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "srcReportsOverviewPageQuery",
    "selections": [
      {
        "condition": "isBoardContext",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jira_projectByIdOrKey",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v8/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      },
      {
        "condition": "isBoardContext",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "boardId"
                  }
                ],
                "kind": "LinkedField",
                "name": "jiraBoard",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "type": "JiraBoard"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fa621fa4bb6543d468797211342e1086ef2a6b35d10869637d74ad38c4e9685b",
    "metadata": {},
    "name": "srcReportsOverviewPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "48e488c0e3939efa5d53df92fe5fb373";

export default node;
