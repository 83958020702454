import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { softwareSummaryEmbedClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareSummaryEmbedClassicRoute.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	SummaryEmbedPage,
	LazySummaryEmbedView,
} from '@atlassian/jira-router-routes-software-summary-embed-entries/src/ui/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { summarySoftwareEmbedEntrypoint } from '@atlassian/jira-spa-apps-summary/software-embed/entrypoint.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';

const summarySoftwareEmbedPageEntrypoint = createChromelessEntryPoint({
	main: summarySoftwareEmbedEntrypoint,
});

export const softwareSummaryEmbedClassicRouteEntry = createEntry(
	softwareSummaryEmbedClassicRoute,
	() => ({
		group: 'classic-software',
		ufoName: 'classic-summary',
		component: componentWithCondition(
			() => expVal('jsw-summary-page-embed-smart-link-experiment', 'isEnabled', false),
			SummaryEmbedPage,
			ErrorPagesNotFound,
		),
		layout: chromelessLayout,
		resources: [
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			// end of resources expanded from getNavigationSidebarProjectResource
		],
		forPaint: [LazySummaryEmbedView],
		entryPoint: () => {
			if (fg('project_summary_page_entry_point_migration')) {
				return summarySoftwareEmbedPageEntrypoint;
			}
		},
	}),
);
