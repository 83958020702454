/**
 * @generated SignedSource<<bc8f47418a0aaa6d19370311cfed6f24>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4PlansForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PlansList">;
  readonly " $fragmentType": "Nav4PlansForLanding";
};
export type Nav4PlansForLanding$key = {
  readonly " $data"?: Nav4PlansForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4PlansForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4PlansForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "PlansList"
    }
  ],
  "type": "Query"
};

(node as any).hash = "4a68bb76768003096c8b0a4da492d88a";

export default node;
