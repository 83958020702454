/* eslint-disable jira/filename-case */

// We can start the evaluation at module level if we ran the component in SSR
// This needs to be an empty string because the router breaks if it's null or undefined
let serverIssuePreloadIdentifier = '';

export const setServerEvaluatedIssuePreload = (identifier: string) => {
	serverIssuePreloadIdentifier = identifier;
};

/**
 * returns piece of the issue-view used as a part of layout and the one to preload for the page
 */
export const getServerEvaluatedIssuePreload = () => serverIssuePreloadIdentifier;
