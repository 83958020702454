import React, { useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import CreateDashboardModal from '@atlassian/jira-create-dashboard-modal/src/async.tsx';
import { useRouteByName } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useRouterActions, generatePath } from '@atlassian/react-resource-router';
import { MODAL_ID } from '@atlassian/jira-atlassian-navigation/src/common/constants.tsx';

const styles = xcss({ position: 'absolute' });

export const Dashboards = () => {
	const isAnonymous = useIsAnonymous();
	const [isOpen, { off: modalClose }] = useSwitchModals(MODAL_ID.DASHBOARD_CREATE);
	const { path } = useRouteByName('dashboard-edit') ?? {};
	const { push } = useRouterActions();
	const onSuccess = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7031 - Binding element 'id' implicitly has an 'any' type.
		(_, { id }) => {
			path && push(generatePath(path, { dashboardId: id }));
			modalClose();
		},
		[modalClose, path, push],
	);

	if (isAnonymous) {
		return null;
	}

	return isOpen ? (
		<Box xcss={styles}>
			<CreateDashboardModal onSuccess={onSuccess} onCancel={modalClose} />
		</Box>
	) : null;
};
