/**
 * @generated SignedSource<<086fc8a80466dbab70300b14e65169cd>>
 * @relayHash 97f7e29b9656db740fc7b8aa0a23f4d6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 00edac7f68a877717beb0b54982122a8afa0dbc0016892d64c3d13228d7c8fb7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiSupportWebsiteOverviewServiceHubPageQuery } from './uiSupportWebsiteOverviewServiceHubPageQuery.graphql';

const node: PreloadableConcreteRequest<uiSupportWebsiteOverviewServiceHubPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "00edac7f68a877717beb0b54982122a8afa0dbc0016892d64c3d13228d7c8fb7",
    "metadata": {},
    "name": "uiSupportWebsiteOverviewServiceHubPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
