import React, { memo } from 'react';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/sidebar-boundary/index.tsx';
import type { JsmProjectMenuProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-jsm-project-menu/src/ui/project-menu/JsmProjectMenu.tsx';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/common/ui/skeleton/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazySidebarContent = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-jsm-project-menu" */ './ui/project-menu'
	).then(({ JsmProjectMenu }) => JsmProjectMenu),
);

export const AsyncJsmProjectMenu = memo(({ projectData, spotlight }: JsmProjectMenuProps) => (
	<SidebarBoundary
		id="nav4.sidebar.jsm-project-menu"
		packageName="jiraNavigationAppsSidebarNav4JsmProjectMenu"
		teamName="jsm-getting-started"
		ufoName="nav4.sidebar.jsm-project-menu"
	>
		<Placeholder name="nav4.sidebar.jsm-project-menu.loading-lazy" fallback={<Skeleton />}>
			<LazySidebarContent projectData={projectData} spotlight={spotlight} />
		</Placeholder>
	</SidebarBoundary>
));
