import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectsResponse } from './types.tsx';

export type GetJpdProjectsOptions = {
	query?: string;
	projectIds?: string[];
};

export const getJpdProjects = async ({ query = '', projectIds = [] }: GetJpdProjectsOptions) => {
	const queryParams = new URLSearchParams({
		typeKey: PRODUCT_DISCOVERY_PROJECT,
		query,
		expand: 'issueTypes',
	});

	projectIds.forEach((id) => queryParams.append('id', id));

	const { values } = await fetchJson<ProjectsResponse>(
		`/rest/api/3/project/search?${queryParams.toString()}`,
	);

	return values;
};
