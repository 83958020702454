// eslint-disable-next-line jira/import-whitelist
import { preloadPostOfficePlacementWithTenantContext } from '@atlassian/jira-post-office-provider/src/preload-placement.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postOfficeScreenSpaceFlagsResource = createResource<any>({
	type: 'POST_OFFICE_PLACEMENT__SCREEN_SPACE_FLAGS',
	getKey: () => 'state',
	getData: async (_, { tenantContext }) => {
		return preloadPostOfficePlacementWithTenantContext('screen-space-flags', tenantContext);
	},
	maxAge: Infinity,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postOfficeJiraSideNavResource = createResource<any>({
	type: 'POST_OFFICE_PLACEMENT__JIRA_SIDE_NAV',
	getKey: () => 'state',
	getData: async (_, { tenantContext }) => {
		const { isAnonymous } = tenantContext;
		if (isAnonymous) {
			return null;
		}

		return preloadPostOfficePlacementWithTenantContext('jira-side-nav', tenantContext);
	},
	maxAge: Infinity,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postOfficeJiraYourWorkResource = createResource<any>({
	type: 'POST_OFFICE_PLACEMENT__JIRA_YOUR_WORK',
	getKey: () => 'state',
	getData: async (_, { tenantContext }) => {
		const { isAnonymous } = tenantContext;
		if (isAnonymous) {
			return null;
		}

		return preloadPostOfficePlacementWithTenantContext('jira-your-work', tenantContext);
	},
	maxAge: Infinity,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postOfficeJiraTopBannerResource = createResource<any>({
	type: 'POST_OFFICE_PLACEMENT__JIRA_TOP_BANNER',
	getKey: () => 'state',
	getData: async (_, { tenantContext }) => {
		const { isSiteAdmin } = tenantContext;
		if (isSiteAdmin) {
			return preloadPostOfficePlacementWithTenantContext('jira-top-banner', tenantContext);
		}

		return null;
	},
	maxAge: Infinity,
});

export const conditionalPostOfficeJiraTopBannerResource = resourceWithCondition2(
	() => fg('jira-issue-term-refresh-banner'),
	postOfficeJiraTopBannerResource,
);
