import React from 'react';
import queryString from 'query-string';
import { Redirect, type RouteContext } from '@atlassian/react-resource-router';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { ASSETS_APP_ROUTE_PREFIX } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';

export const AssetsAppRedirect = ({ match, query }: RouteContext) => {
	const pattern = /\/jira\/servicedesk\/(assets|insight)/;

	let redirectionUrl: string = match.url.replace(pattern, ASSETS_APP_ROUTE_PREFIX);
	if (Object.keys(query).length > 0) {
		redirectionUrl += `?${queryString.stringify(query)}`;
	}

	const analyticsAttributes = {
		matchedRoute: match.path,
	};

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="assetsAppRedirect">
			<Redirect to={redirectionUrl} push={false} />
			<FireScreenAnalytics attributes={analyticsAttributes} />
		</ContextualAnalyticsData>
	);
};
