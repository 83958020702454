import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';

export const ErrorPage = ({ error }: { error: Error }) => (
	<ErrorWrapper>
		<JSErrorPageAsync error={error} />
	</ErrorWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorWrapper = styled.div({
	backgroundColor: token('elevation.surface'),
	color: token('color.text'),
});
