import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/uiFeaturesPlansSettingsQuery$parameters';

const toPlanAri = ({
	siteId,
	activationId,
	planId,
}: {
	siteId: string;
	activationId: string;
	planId: string;
}): string => {
	return `ari:cloud:jira:${siteId}:plan/activation/${activationId}/${planId}`;
};

export const featuresEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-plan-features-entrypoint" */ './ui/index.tsx'),
	),
	getPreloadProps: ({ context: { match }, tenantContext }: EntryPointRouteParams) => {
		return {
			queries: {
				plan: {
					variables: {
						planId: toPlanAri({
							siteId: tenantContext.cloudId,
							activationId: tenantContext.activationId,
							planId: String(match.params.planId),
						}),
					},
					options: {
						fetchPolicy: 'network-only' as const,
					},
					parameters,
				},
			},
		};
	},
});
