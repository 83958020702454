// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../model/types.tsx';
import { getNextTask } from '../../utils.tsx';
import { nextOpenTask } from '../next-open-task/index.tsx';

// Currently this action is only used when the next card opened does NOT need to display a nudge
// TODO: Update or create a new action if you need to support this functionality
export const openNextTask =
	(): Action<State> =>
	({ getState, dispatch }) => {
		const { openTask, tasks } = getState();

		if (!tasks) {
			return;
		}
		const { items } = tasks;
		const taskIdToExpand = getNextTask(items, openTask);
		if (taskIdToExpand === null) {
			return;
		}
		dispatch(nextOpenTask(taskIdToExpand));
	};
