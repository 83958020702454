import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/JourneyConfigurationPageQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const journeyConfigEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-servicedesk-journey-configuration-edit" */ './src/ui/JourneyConfigurationPage'
			),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => {
		return {
			queries: {
				jiraJourneyConfiguration: {
					options: {
						fetchPolicy: 'network-only' as const,
					},
					parameters,
					variables: {
						cloudId: tenantContext.cloudId,
						journeyId: context.match.params.journeyId ?? '',
						isEditing: context.match.path?.includes('journeys/:journeyId/edit'),
					},
				},
			},
			entryPoints: {},
			extraProps: {},
		};
	},
});
