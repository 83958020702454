import camelCase from 'lodash/camelCase';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { versionDetailsRelayResourceCustom } from '@atlassian/jira-router-resources-software-versions/src/controllers/index.tsx';
import { softwareVersionDetailEmbedRoute } from '@atlassian/jira-router-routes-software-versions-routes/src/softwareVersionDetailEmbedRoute.tsx';
import { VersionDetailSkeleton } from '@atlassian/jira-skeletons/src/ui/versions/VersionDetailSkeleton.tsx';
import { LazyVersionDetail, VersionDetailPage } from './ui/version-detail/index.tsx';

// RouteEntry for the Embed view for Version Details. This is only used for Smartlinks in Confluence.
export const softwareVersionDetailEmbedRouteEntry = createEntry(
	softwareVersionDetailEmbedRoute,
	() => ({
		group: 'software',
		ufoName: 'software.version-details-page-embed',
		component: VersionDetailPage,
		skeleton: VersionDetailSkeleton,
		canTransitionIn: () => true,
		layout: createLayout({
			isChromeless: true,
		}),
		resources: [
			...getNavigationResources(),
			versionDetailsRelayResourceCustom,
			projectContextResource,
		],
		forPaint: [LazyVersionDetail],
		meta: {
			reporting: {
				id: 'software-version-detail-embed',
				packageName: camelCase('software-version-detail-embed'),
				teamName: 'fusion-solaris',
			},
		},
	}),
);
