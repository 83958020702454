import React, { useMemo, type ReactNode } from 'react';
import { ProfilerContext as RelayProfilerContext } from 'react-relay';
import type { ProfilerContextType } from 'react-relay/relay-hooks/ProfilerContext';
import type { EnhancedUFOInteractionContextType } from '@atlaskit/react-ufo/common';
import { useInteractionContext } from '@atlaskit/react-ufo/interaction-context';
import { type RequestInfo, extractModuleName } from '@atlaskit/react-ufo/interaction-metrics';
import PlatformUFOSegment from '@atlaskit/react-ufo/segment';
import {
	type GlobalReactLooselyLazyProfiler,
	type EventInfo,
	ProfilerContext as RLLProfilerContext,
} from '@atlassian/react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';

type UfoRllProfiler = (typeof GlobalReactLooselyLazyProfiler)['current'] & {
	getInteractionContext: () => EnhancedUFOInteractionContextType;
};

type UfoRelayProfiler = ProfilerContextType & {
	getInteractionContext: () => EnhancedUFOInteractionContextType;
};

type Props = {
	name: string;
	children: ReactNode;
	mode?: 'list' | 'single';
};

/** A portion of the page we apply measurement to */
function JiraUFOSegmentWrapper({ children }: { children: ReactNode }) {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const interactionContext = useInteractionContext() as EnhancedUFOInteractionContextType;

	const relayProfilerContext: UfoRelayProfiler = useMemo(
		() => ({
			getInteractionContext: () => interactionContext,
			wrapPrepareQueryResource<T>(cb: () => T): T {
				return cb();
			},
			retainQuery(this: UfoRelayProfiler, info: RequestInfo) {
				this.getInteractionContext().retainQuery(info);
			},
		}),
		[interactionContext],
	);

	const reactLooselyLazyContext: { current: UfoRllProfiler } = useMemo(() => {
		const moduleLoadingRequests: {
			[key: string]: {
				start: number;
				timeoutId: NodeJS.Timeout | string | number | undefined;
			};
		} = {};

		return {
			current: {
				getInteractionContext() {
					return interactionContext;
				},
				onPreload(this: UfoRllProfiler, moduleId: string, _priority?: number) {
					if (fg('ufo-disable-rll-bundle-loads-jira')) {
						return;
					}
					this.getInteractionContext().addPreload(extractModuleName(moduleId), performance.now());
				},
				onLoadStart(this: UfoRllProfiler, info: EventInfo): void {
					if (fg('ufo-disable-rll-bundle-loads-jira')) {
						return;
					}
					const timeoutId = setTimeout(() => {
						delete moduleLoadingRequests[info.identifier];
					}, 30000);
					const request = {
						start: performance.now(),
						timeoutId,
					};
					moduleLoadingRequests[info.identifier] = request;
				},
				onLoadComplete(this: UfoRllProfiler, info: EventInfo): void {
					if (fg('ufo-disable-rll-bundle-loads-jira')) {
						return;
					}
					const request = moduleLoadingRequests[info.identifier];
					if (request) {
						clearTimeout(request.timeoutId);
						delete moduleLoadingRequests[info.identifier];
						this.getInteractionContext()?.addLoad(
							extractModuleName(info.identifier),
							request.start,
							performance.now(),
						);
					}
				},
				placeholderFallBackMounted(
					this: UfoRllProfiler,
					id: string,
					name?: string,
					lazyWait?: boolean,
				): void {
					if (
						(name === 'nav4-ignore.sidebar' || name === 'nav4-ignore.sidebar.anonymous') &&
						fg('afo-3421-remove-sidebar-nav4-suspense')
					) {
						return;
					}

					if (lazyWait) {
						return;
					}
					this.getInteractionContext().addHoldByID(
						this.getInteractionContext().labelStack,
						id,
						name,
					);
				},
				placeholderFallBackUnmounted(
					this: UfoRllProfiler,
					id: string,
					name?: string,
					lazyWait?: boolean,
				): void {
					if (
						(name === 'nav4-ignore.sidebar' || name === 'nav4-ignore.sidebar.anonymous') &&
						fg('afo-3421-remove-sidebar-nav4-suspense')
					) {
						return;
					}
					if (lazyWait) {
						return;
					}
					this.getInteractionContext().removeHoldByID(
						this.getInteractionContext().labelStack,
						id,
						name,
					);
				},
			},
		};
	}, [interactionContext]);

	return (
		<RelayProfilerContext.Provider value={relayProfilerContext}>
			<RLLProfilerContext.Provider value={reactLooselyLazyContext}>
				{children}
			</RLLProfilerContext.Provider>
		</RelayProfilerContext.Provider>
	);
}

export default function UFOSegment({ name: segmentName, children, mode = 'single' }: Props) {
	return (
		<PlatformUFOSegment name={segmentName} mode={mode}>
			<JiraUFOSegmentWrapper>{children}</JiraUFOSegmentWrapper>
		</PlatformUFOSegment>
	);
}
