// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createActionsHook,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';

type State = {
	isContextReady: boolean;
	isJqlEditorReady: boolean;
};

type Actions = {
	setIsContextReady: (status: State['isContextReady']) => Action<State>;
	setJqlEditorReady: (status: State['isJqlEditorReady']) => Action<State>;
};

const initialState: State = {
	isContextReady: false,
	isJqlEditorReady: false,
};

const Store = createStore<State, Actions>({
	initialState,
	actions: {
		setIsContextReady:
			(isContextReady) =>
			({ setState }) => {
				setState({ isContextReady });
			},
		setJqlEditorReady:
			(isJqlEditorReady) =>
			({ setState }) => {
				setState({ isJqlEditorReady });
			},
	},
});

export const useRenderSynchronizerStoreActions = createActionsHook(Store);

export const useIsContextReady = createHook(Store, {
	selector: (state) => state.isContextReady,
});

export const useIsJqlEditorReady = createHook(Store, {
	selector: (state) => state.isJqlEditorReady,
});
