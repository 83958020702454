import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	yourWork: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-your-work.your-work.your-work',
		defaultMessage: 'Your work',
		description: 'Message for your work nav menu item in the sidebar',
	},
	forYou: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-your-work.your-work.for-you',
		defaultMessage: 'For you',
		description: 'Message for for you nav menu item in the sidebar',
	},
});
