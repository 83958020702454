import { fetch } from '@atlassian/eoc-fetch';

import { type TeamsResponse } from '../../common/types';

export interface GetTeamsParams {
	accountId?: string;
	keyword?: string;
	size?: number;
	offset?: number;
	showAvatar?: boolean;
	includeTeamExternalDetails?: boolean;
}

export const getTeams = ({ url, queryParams }: { url?: string; queryParams: GetTeamsParams }) => {
	const params = new URLSearchParams();

	if (queryParams.keyword) {
		params.append('keyword', queryParams.keyword);
	}

	if (queryParams.size) {
		params.append('size', queryParams.size.toString());
	}

	if (queryParams.offset) {
		params.append('offset', queryParams.offset.toString());
	}

	if (queryParams.accountId) {
		params.append('memberId', queryParams.accountId);
	}

	if (queryParams.showAvatar) {
		params.append('showAvatar', String(queryParams.showAvatar));
	}

	if (queryParams.includeTeamExternalDetails) {
		params.append('includeTeamExternalDetails', String(queryParams.includeTeamExternalDetails));
	}

	if (url) {
		return fetch<TeamsResponse>(`${url}${params.toString() ? `?${params.toString()}` : ''}`, {
			exactUrl: true,
		});
	}

	return fetch<TeamsResponse>(`/v1/teams${params.toString() ? `?${params.toString()}` : ''}`);
};
