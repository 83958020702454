import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsCreateArticleRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsCreateArticleRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { navigation } from './common/constants.tsx';
import { helpCenterSettingCreateArticleAppPageEntryPoint } from './ui/create-article/index.tsx';

export const helpCenterSettingsCreateArticleRouteEntry = createEntry(
	helpCenterSettingsCreateArticleRoute,
	() => ({
		group: 'helpcenter-settings',
		perfMetricKey: 'help-center-settings.create-article',
		layout: serviceProjectLayout,
		navigation,
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('enable_articles_page_csm')
				? helpCenterSettingCreateArticleAppPageEntryPoint
				: undefined;
		},
		resources: [...getServiceDeskSettingsResources()],
	}),
);
