import { useMessagePlacementHooks } from '@atlassian/post-office-context';
import {
	type MessageProps,
	createPlacementComponent,
	useInitialData,
} from '@post-office/placement-common';
import { useCallback, useEffect, useState } from 'react';

import { MessageRenderer, type renderer } from '../__generated__/client/render';
import { PLACEMENT_ID } from '../__generated__/common';
import { withJiraTopBannerIntlProvider } from '../__generated__/intl-provider';
import { type JiraTopBannerInitialData } from '../api/types';
import { type Placement as JiraTopBannerPlacement } from '../contracts/Placement';

export type Message = MessageProps<typeof renderer>;

export const JiraTopBannerPlacementComponent = withJiraTopBannerIntlProvider(
	createPlacementComponent<JiraTopBannerPlacement>(
		PLACEMENT_ID,
		({ initialData }) => {
			const { data, isLoading } = useInitialData<JiraTopBannerInitialData>(initialData);
			const [{ message, status }, setMessageState] = useState<{
				message: Message | undefined;
				status: 'loading' | 'done';
			}>({
				message: undefined,
				status: 'loading',
			});

			const { onMessageDismiss, onMessageClicked, onMessageSnooze } = useMessagePlacementHooks();

			const handleDismiss = useCallback(() => {
				setMessageState({ message: undefined, status: 'done' });
			}, []);

			useEffect(() => onMessageDismiss(handleDismiss), [onMessageDismiss, handleDismiss]);
			useEffect(() => onMessageClicked(handleDismiss), [onMessageClicked, handleDismiss]);
			useEffect(() => onMessageSnooze(handleDismiss), [onMessageSnooze, handleDismiss]);

			useEffect(() => {
				if (!isLoading) {
					const newMessage = data?.messages[0];

					setMessageState({
						message: newMessage,
						status: 'done',
					});
				}
			}, [data, isLoading]);

			if (isLoading || status === 'loading') {
				return null;
			}

			return (
				message && <MessageRenderer {...message} messageInstanceId={message.messageInstanceId} />
			);
		},
		{
			choreographer: {
				isDefaultEnabled: false,
			},
		},
	),
);

export default JiraTopBannerPlacementComponent;
