import { useMemo } from 'react';
import { useBannerCookieConsent } from '@atlassian/jira-cookie-consent-banner/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useBannerIPBlocklisting } from '@atlassian/jira-ip-block-listing-banner/src/controllers/index.tsx';
import { useIsChromeless } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/chromeless/index.tsx';
import { useBannerAnnouncement } from '@atlassian/jira-platform-ui-banners-announcement/src/controllers/announcement/index.tsx';
import { useBannerEocTransition } from '@atlassian/jira-platform-ui-banners-eoc-transition/src/controllers/index.tsx';
import { useBannerMailExpiry } from '@atlassian/jira-platform-ui-mail-expiry-reminder/src/controllers/use-banner-mail-expiry/index.tsx';
import { useBannerReturnToMobile } from '@atlassian/jira-return-to-mobile-banner/src/controllers/index.tsx';

type ReturnType = {
	name: string | null;
	height: number;
	isEnabled: boolean;
};

const noBanner: ReturnType = {
	name: null,
	height: 0,
	isEnabled: false,
};

export const useBanner = (): ReturnType => {
	const [isChromeless] = useIsChromeless();
	const announcement = useBannerAnnouncement();

	const transition = useBannerEocTransition();
	const returnToMobile = useBannerReturnToMobile();
	const IPBlocklisting = useBannerIPBlocklisting();

	const cookieConsent = fg('jfp-magma-cookie-controls-banner-show') // eslint-disable-next-line react-hooks/rules-of-hooks
		? useBannerCookieConsent()
		: undefined;
	const mailExpiry = useBannerMailExpiry();

	return useMemo(() => {
		// cookie consent banner has the highest priority
		if (cookieConsent?.isEnabled) return cookieConsent;
		if (isChromeless) return noBanner;
		if (IPBlocklisting.isEnabled) return IPBlocklisting;
		if (returnToMobile.isEnabled) return returnToMobile;
		if (announcement.isEnabled) return announcement;
		if (transition?.isEnabled) return transition;
		if (mailExpiry?.isEnabled) return mailExpiry;

		return noBanner;
	}, [
		cookieConsent,
		isChromeless,
		IPBlocklisting,
		returnToMobile,
		announcement,
		transition,
		mailExpiry,
	]);
};
