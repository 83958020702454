import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { entitlementsCustomerQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-customer-details/src/services/entitlements-customer-query-resource/index.tsx';
import { uiCustomerDetailsPageQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-customer-details/src/services/ui-customer-details-page-query-resource/index.tsx';
import { servicedeskCustomerDetailsRoute } from '@atlassian/jira-router-routes-servicedesk-customers-routes/src/servicedeskCustomerDetailsRoute.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { ServicedeskCustomerDetails, LazyServicedeskCustomerDetails } from './ui/index.tsx';

export const servicedeskCustomerDetailsRouteEntry = createEntry(
	servicedeskCustomerDetailsRoute,
	() => ({
		group: 'servicedesk',
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		component: ServicedeskCustomerDetails,
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomerDetails],
		perfMetricKey: 'jsm-customer-details-component-load',
		meta: {
			reporting: {
				id: APP_NAMES.CUSTOMER_DETAILS,
				packageName: toPackageName(APP_NAMES.CUSTOMER_DETAILS),
				teamName: 'boysenberry',
			},
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
			entitlementsCustomerQueryResource,
			uiCustomerDetailsPageQueryResource,
		],
	}),
);
