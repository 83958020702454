/**
 * @generated SignedSource<<669304670c931d09af8646f09ee71460>>
 * @relayHash 3b0412c013eb910bffa938b54599e765
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 589c5cc207a17b7fcd72acde1a46f074a3ac0a2e82da10793f61efb4fb0a786e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_adminPagesPerformance_Overview_Query$variables = {
  cloudId: string;
  schemaMigrationEnabled: boolean;
};
export type main_adminPagesPerformance_Overview_Query$data = {
  readonly jira: {
    readonly resourceUsageMetrics?: {
      readonly " $fragmentSpreads": FragmentRefs<"main_adminPagesPerformance_OverviewPage">;
    };
    readonly resourceUsageMetricsV2?: {
      readonly " $fragmentSpreads": FragmentRefs<"main_adminPagesPerformance_OverviewPageV2">;
    };
  };
};
export type main_adminPagesPerformance_Overview_Query = {
  response: main_adminPagesPerformance_Overview_Query$data;
  variables: main_adminPagesPerformance_Overview_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "schemaMigrationEnabled"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
},
v3 = {
  "kind": "ScalarField",
  "name": "currentValue"
},
v4 = {
  "kind": "ScalarField",
  "name": "warningValue"
},
v5 = {
  "kind": "ScalarField",
  "name": "thresholdValue"
},
v6 = {
  "kind": "ScalarField",
  "name": "cleanupValue"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "main_adminPagesPerformance_Overview_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "condition": "schemaMigrationEnabled",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v1/*: any*/),
                    "concreteType": "JiraResourceUsageMetricConnection",
                    "kind": "LinkedField",
                    "name": "resourceUsageMetrics",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "main_adminPagesPerformance_OverviewPage"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.resourceUsageMetrics"
                }
              ]
            },
            {
              "condition": "schemaMigrationEnabled",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v1/*: any*/),
                    "concreteType": "JiraResourceUsageMetricConnectionV2",
                    "kind": "LinkedField",
                    "name": "resourceUsageMetricsV2",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "main_adminPagesPerformance_OverviewPageV2"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.resourceUsageMetricsV2"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "main_adminPagesPerformance_Overview_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "schemaMigrationEnabled",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v1/*: any*/),
                "concreteType": "JiraResourceUsageMetricConnection",
                "kind": "LinkedField",
                "name": "resourceUsageMetrics",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraResourceUsageMetric",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "condition": "schemaMigrationEnabled",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v1/*: any*/),
                "concreteType": "JiraResourceUsageMetricConnectionV2",
                "kind": "LinkedField",
                "name": "resourceUsageMetricsV2",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "589c5cc207a17b7fcd72acde1a46f074a3ac0a2e82da10793f61efb4fb0a786e",
    "metadata": {},
    "name": "main_adminPagesPerformance_Overview_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f41d000bf8612aeb0ad7a3bfa4b9b2cb";

export default node;
