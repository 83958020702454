import { isFedRamp } from '@atlassian/atl-context';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { settingsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-routes/src/settingsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { VirtualAgentSettingsSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/settings/VirtualAgentSettingsSkeleton.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { settingsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-settings/entrypoint.tsx';
import { FedRAMPFallbackRedirectToProjectSettings } from './common/index.tsx';

const settingsPageEntryPoint = createPageEntryPoint({
	main: settingsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const settingsRouteEntry = createEntry(settingsRoute, () => ({
	group: 'project-settings-servicedesk',
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServiceDesk,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},
	entryPoint() {
		if (isFedRamp()) {
			return undefined;
		}
		return settingsPageEntryPoint;
	},
	// this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
	component: FedRAMPFallbackRedirectToProjectSettings,
	skeleton: VirtualAgentSettingsSkeleton,
	resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
	meta: {
		reporting: {
			id: APP_NAMES.VIRTUAL_AGENT_SETTINGS,
			packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_SETTINGS),
			teamName: 'jsd-flux-capacitors',
		},
	},
}));
