import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { NAVIGATION_UI_STORAGE_KEY, LOGGER_LOCATION } from './constants.tsx';
import type { UIControllerShape } from './types.tsx';

// TODO - JFP-2294 add prefix for webstorage
const localStorageProvider = createLocalStorageProvider('');
export const safeGetLocalStorage = () => {
	if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
		return window.localStorage;
	}

	return {
		setItem: noop,
		getItem: noop,
	};
};

const queryLocalStorageSync = (): UIControllerShape | undefined => {
	const localStorage = safeGetLocalStorage();
	const item = fg('jfp-vulcan-browser-storage-migration')
		? localStorageProvider.get(NAVIGATION_UI_STORAGE_KEY)
		: localStorage.getItem(NAVIGATION_UI_STORAGE_KEY);

	if (!__SERVER__ && item) {
		try {
			return fg('jfp-vulcan-browser-storage-migration') ? item : JSON.parse(item);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			log.safeErrorWithoutCustomerData(LOGGER_LOCATION, 'Fail to parse sync local storage data', e);
		}
	}
};

export const queryLocalStorage = (): Promise<UIControllerShape | undefined> =>
	new Promise((resolve) => {
		const item = queryLocalStorageSync();
		resolve(item);
	});

export const updateLocalStorageData = (data: UIControllerShape) => {
	const localStorage = safeGetLocalStorage();
	fg('jfp-vulcan-browser-storage-migration')
		? localStorageProvider.set(NAVIGATION_UI_STORAGE_KEY, data)
		: localStorage.setItem(NAVIGATION_UI_STORAGE_KEY, JSON.stringify(data));
};
