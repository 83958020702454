import shuffle from 'lodash/shuffle';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { RecommendationType, recommendationContext } from '../../common/types.tsx';
import type { State } from './types.tsx';

export const loadRecommendations =
	(
		recommendations: Array<RecommendationType>,
		recommendationContext: recommendationContext,
		analyticsEvent: UIAnalyticsEvent,
	): Action<State> =>
	async ({ getState, setState }) => {
		const { status } = getState();
		if (status !== 'idle') return;

		setState({ status: 'loading' });

		const shuffledRecommendations = shuffle(recommendations);

		try {
			const recommendationStatus = await Promise.all(
				shuffledRecommendations.map(async (recommendation) => {
					let isEnabled = false;
					try {
						isEnabled = await recommendation.shouldRender(recommendationContext, analyticsEvent);
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						recommendation.onLoadError(error);
					}
					return {
						key: recommendation.key,
						isEnabled,
					};
				}),
			);

			const selectedRecommendation = recommendationStatus.find((result) => result.isEnabled);

			setState({
				status: 'success',
				recommendationKey: selectedRecommendation?.key || null,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				status: 'error',
				error,
			});
		}
	};

const setRecommendationDismissal =
	(): Action<State> =>
	({ setState }) => {
		setState({ recommendationKey: null });
	};

export const actions = {
	loadRecommendations,
	setRecommendationDismissal,
} as const;
