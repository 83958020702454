import React, { forwardRef, useMemo, type Ref, useState, useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import { Box, Text, Stack, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import Button, { type ButtonProps } from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/core/migration/add';
import { JastBuilder, type Jast } from '@atlaskit/jql-ast';
import type { ExternalError } from '@atlaskit/jql-editor';
import { useIntl } from '@atlassian/jira-intl';
import type { EditorContainerProps } from '@atlassian/jira-jql-builder/src/common/types.tsx';
import JQLBuilder, {
	type SearchMode,
	type SearchModeSwitcherProps,
} from '@atlassian/jira-jql-builder/src/index.tsx';
import ToggleButtons from '@atlassian/jira-toggle-buttons/src/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { getSystemAndGlobalFieldKeys, type Field } from '../services/fields/index.tsx';
import messages from './messages.tsx';
import LoadingState from './loading-state/index.tsx';

const AddFilterButtonComponent = forwardRef((props: ButtonProps, ref: Ref<HTMLElement>) => {
	const { formatMessage } = useIntl();
	return (
		<Button
			ref={ref}
			{...props}
			iconAfter={<AddIcon label={formatMessage(messages.addFilterLabel)} />}
		>
			{formatMessage(messages.addFilterLabel)}
		</Button>
	);
});

const SearchModeSwitcher = ({
	isBasicModeDisabled,
	onUpdateSearchMode,
	searchMode,
}: SearchModeSwitcherProps) => {
	const { formatMessage } = useIntl();

	const options = [
		{
			id: 'basic',
			label: isBasicModeDisabled ? (
				<Tooltip content={formatMessage(messages.basicModeDisabledTooltip)} tag="span">
					<Box as="span">{formatMessage(messages.basicMode)}</Box>
				</Tooltip>
			) : (
				formatMessage(messages.basicMode)
			),
			isDisabled: isBasicModeDisabled,
			testId: '',
		},
		{
			id: 'advanced',
			label: formatMessage(messages.advancedMode),
			testId: '',
		},
	];
	return (
		<Box paddingBlockEnd="space.100">
			<ToggleButtons
				label={formatMessage(messages.label)}
				options={options}
				selectedOption={searchMode}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onChange={(value) => onUpdateSearchMode(value as SearchMode)}
			/>
		</Box>
	);
};

const EditorContainer = ({ children }: EditorContainerProps) => (
	<Stack xcss={editorContainerStyles}>{children}</Stack>
);

type Props = {
	value?: string;
	projectKeys: string[];
	onChange?: (value: string) => void;
	checkDelay?: number;
};

const excludedFieldTypes = [
	'project',
	'jira.polaris:interval',
	'jira.polaris:formula',
	'jira.polaris:delivery-status',
	'jira.polaris:delivery-progress',
	'jira.polaris:count-issue-comments',
	'jira.polaris:count-insights',
	'jira.polaris:atlassian-project',
	'jira.polaris:atlassian-project-status',
	'com.atlassian.jira.plugin.system.customfieldtypes:goals',
	'jira.polaris:count-linked-issues',
	'com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf',
];

export const CollectionAdvancedFilters = ({
	projectKeys,
	onChange,
	value,
	checkDelay = 500,
}: Props) => {
	const { formatMessage } = useIntl();
	const [errors, setErrors] = useState<ExternalError[]>([]);
	const [searchMode, setSearchMode] = useState<SearchMode>('basic');
	const { generalDataLoadingFailedError } = useErrorHandlers();
	const [availableFields, setAvailableFields] = useState<
		{ [jqlFieldValue: string]: Field } | undefined
	>();

	useEffect(() => {
		getSystemAndGlobalFieldKeys()
			.then((fields) => setAvailableFields(fields))
			.catch(generalDataLoadingFailedError);
	}, [generalDataLoadingFailedError]);

	const debouncedCheck = useMemo(() => {
		// Update our errors after a "checkDelay" debounce
		return debounce((jql: string) => {
			const jast: Jast = new JastBuilder().build(jql);
			// Format JQL parse errors to be shown as custom error messages in the editor
			const errorMessages: ExternalError[] = jast.errors.map(({ description }) => ({
				message: description,
				type: 'error',
			}));
			if (jast.query?.orderBy) {
				errorMessages.push({
					message: formatMessage(messages.orderByError),
					type: 'error',
				});
			}
			setErrors(errorMessages);
			if (errorMessages.length === 0) {
				onChange?.(jql);
			}
		}, checkDelay);
	}, [checkDelay, formatMessage, onChange]);

	const isFieldIncludedInAutocomplete = useCallback(
		(fieldValue?: string) =>
			!!(
				availableFields &&
				fieldValue &&
				!!availableFields[fieldValue] &&
				!excludedFieldTypes.includes(availableFields[fieldValue].type)
			),
		[availableFields],
	);

	if (!projectKeys.length)
		return (
			<Box paddingBlockStart="space.050">
				<Text color="color.text.subtlest">{formatMessage(messages.selectProjectsFirst)}</Text>
			</Box>
		);

	if (!availableFields) return <LoadingState />;

	return (
		<JQLBuilderVerticalLayoutFixer>
			<JQLBuilder
				query={value ?? ''}
				analyticsSource="jpdRoadmapFilters"
				components={{
					SearchModeSwitcher,
					EditorContainer,
					AddFilterButtonComponent,
				}}
				jqlMessages={errors}
				userPreferencesData={null}
				onUpdate={searchMode === 'basic' ? onChange : debouncedCheck}
				projectKeys={projectKeys}
				excludedFields={['project']}
				basicModeVisibleFields={[]}
				onUpdateSearchMode={setSearchMode}
				hideTextSearchInput
				isFieldIncludedInAutocomplete={isFieldIncludedInAutocomplete}
				viewContext="JPD_ROADMAPS"
			/>
		</JQLBuilderVerticalLayoutFixer>
	);
};

const editorContainerStyles = xcss({ flex: 1 });

// Should be fixed properly by implementing vertical layout in JQLBuilder: https://pi-dev-sandbox.atlassian.net/browse/POL-13293
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const JQLBuilderVerticalLayoutFixer = styled.div({
	// Without visual refresh, to be removed in POL-13638
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-vc="jql-builder-ui-container"]': {
		flexDirection: 'column-reverse',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-testid="jql-builder.ui.search-mode-switcher-renderer.toggle-container"]': {
			marginLeft: '0',
			marginRight: '0',
		},
	},
	// With visual refresh
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'ul[data-vc="jql-builder-basic-editor-container"]': {
		flexDirection: 'column',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'>li': {
			marginLeft: '0',
			marginRight: '0',
		},
	},
});
