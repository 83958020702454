import React, {
	type AllHTMLAttributes,
	useState,
	useRef,
	useCallback,
	type ReactNode,
	type SyntheticEvent,
	type KeyboardEvent,
	memo,
} from 'react';
import { styled } from '@compiled/react';
import keycode from 'keycode';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { media } from '@atlaskit/primitives/responsive';
import Textfield, { type TextFieldProps } from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from './messages.tsx';
import { ClearFieldIcon } from './ui/clearfield-icon/index.tsx';
import { SearchFieldIconDynamic, SearchFieldIcon } from './ui/searchfield-icon/index.tsx';

type RenderCustomSearchField = (args: {
	textFieldProps: TextFieldProps;
	searchIcon: ReactNode;
}) => ReactNode;

export type Props = {
	isAutocompleteDisabled?: boolean;
	isAlwaysExpanded?: boolean;
	isCompact?: boolean;
	shouldFitContainerWidth?: boolean;
	value?: string;
	autoFocus?: boolean;
	isDisabled?: boolean;
	placeholderAlwaysVisible?: boolean;
	searchIconVisible?: boolean;
	placeholder?: string;
	increasedWidth?: boolean;
	reducedHeight?: boolean;
	inputRef?: {
		current: null | HTMLInputElement;
	};
	maxLength?: number;
	describedById?: string;
	ariaLabelText?: string;
	id?: string;
	renderCustomSearchField?: RenderCustomSearchField;
	// @deprecated - to be removed when cleaning up visual-refresh FG
	searchIconPrimaryColor?: string;
	onEnter?: (value: string) => void;
	onFocus?: (arg1: SyntheticEvent<HTMLElement>) => void;
	onBlur?: (arg1: SyntheticEvent<HTMLElement>) => void;
	onChange: (value: string, analyticsEvent?: UIAnalyticsEvent) => void;
	testId?: string;
	inputProps?: Omit<AllHTMLAttributes<HTMLInputElement>, 'disabled'>;
	isResponsive?: boolean;
	showIconBeforeInput?: boolean;
};

// This class prevents filled in input field causing confirmation dialog
// when user wants to navigate away. This functionality is coming from Jira monolith.
const className = 'ajs-dirty-warning-exempt';

const SearchFieldUnmemoized = ({
	onEnter,
	onChange,
	onFocus,
	onBlur,
	placeholder = '',
	inputRef,
	value: externalValue,
	autoFocus = false,
	isCompact = false,
	isDisabled = false,
	shouldFitContainerWidth = false,
	isAutocompleteDisabled = false,
	placeholderAlwaysVisible = false,
	searchIconVisible = true,
	isAlwaysExpanded = false,
	increasedWidth = false,
	reducedHeight = false,
	maxLength,
	describedById = '',
	ariaLabelText,
	id = undefined,
	renderCustomSearchField,
	searchIconPrimaryColor,
	testId,
	inputProps = {},
	isResponsive = false,
	showIconBeforeInput = false,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isFocused, setIsFocused] = useState(false);
	const [value, setValue] = useState(externalValue || '');
	const { formatMessage } = useIntl();
	const textInput = useRef<unknown>(null);

	const previousValueRef = useRef<unknown>();
	const previousValue = previousValueRef.current;

	if (externalValue !== previousValue && externalValue !== value) {
		// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'SetStateAction<string>'.
		setValue(externalValue);
	}

	previousValueRef.current = externalValue;

	const handleOnFocus = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			setIsFocused(true);
			onFocus && onFocus(event);
		},
		[onFocus],
	);
	const handleOnBlur = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			onBlur && onBlur(event);
			setIsFocused(false);
		},
		[onBlur],
	);

	const handleOnChange = useCallback(
		({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
			const { value: val } = currentTarget;
			const analyticsEvent: UIAnalyticsEvent = createAnalyticsEvent({
				action: 'changed',
			});
			setValue(val);
			onChange(val, analyticsEvent);
		},
		[createAnalyticsEvent, onChange],
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			const analyticsEvent: UIAnalyticsEvent = createAnalyticsEvent({
				action: 'changed',
			});

			if (event.key === 'Escape') {
				setValue('');
				onChange('', analyticsEvent);
			}

			if (onEnter && event.keyCode === keycode('enter')) {
				event.preventDefault();
				onEnter(event.currentTarget && event.currentTarget.value);
			}
		},
		[createAnalyticsEvent, onChange, onEnter],
	);

	const handleOnClear = useCallback(() => {
		if (value !== '') {
			const analyticsEvent: UIAnalyticsEvent = createAnalyticsEvent({
				action: 'changed',
			});
			setValue('');
			onChange('', analyticsEvent);
		}

		if (inputRef && inputRef.current !== null) {
			inputRef.current.blur();
		}

		if (textInput.current !== null) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			textInput.current.blur();
		}
	}, [createAnalyticsEvent, inputRef, onChange, value]);

	const isExpanded = isFocused || value !== '' || isAlwaysExpanded;

	const textFieldProps: TextFieldProps = {
		...inputProps,
		'aria-label': ariaLabelText || placeholder || formatMessage(messages.search),
		'aria-describedby': describedById,
		type: 'text',
		// @ts-expect-error - TS2322 - Type '{ 'aria-label': string; 'aria-describedby': string; type: string; 'data-test-id': string; name: string; className: string; placeholder: string; onFocus: (event: React.SyntheticEvent<HTMLInputElement, Event>) => void; ... 9 more ...; maxLength: number | undefined; }' is not assignable to type 'TextfieldProps'.
		'data-test-id': 'searchfield',
		testId: testId ?? 'searchfield',
		name: 'search',
		className,
		placeholder,
		onFocus: handleOnFocus,
		onBlur: handleOnBlur,
		width: '120px',
		onChange: handleOnChange,
		onKeyDown: handleKeyDown,
		ref: inputRef || textInput,
		isCompact,
		autoFocus,
		autoComplete: isAutocompleteDisabled ? 'off' : 'on',
		value,
		maxLength,
		isDisabled,
	};

	const searchIconDynamic = (
		<SearchFieldIconDynamic
			onClear={handleOnClear}
			hasValue={value !== ''}
			clearIconLabel={formatMessage(messages.clear)}
			searchIconVisible={searchIconVisible}
			primaryColor={isVisualRefreshEnabled() ? undefined : searchIconPrimaryColor}
			onlyIconSpacing={isResponsive && !isExpanded}
		/>
	);

	const searchIcon = showIconBeforeInput ? (
		<SearchFieldIcon
			searchIconVisible={searchIconVisible}
			onlyIconSpacing={isResponsive && !isExpanded}
		/>
	) : undefined;

	const clearIcon =
		showIconBeforeInput && value !== '' ? (
			<ClearFieldIcon onClear={handleOnClear} clearIconLabel={formatMessage(messages.clear)} />
		) : undefined;

	return (
		<Container
			shouldFitContainerWidth={shouldFitContainerWidth}
			{...(isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? { reducedHeight } : {})}
		>
			<InputWrapper
				isExpanded={isExpanded}
				shouldFitContainerWidth={shouldFitContainerWidth}
				maxWidth={increasedWidth ? 328 : 184}
				placeholderAlwaysVisible={placeholderAlwaysVisible}
				isResponsive={isResponsive}
			>
				{renderCustomSearchField !== undefined ? (
					renderCustomSearchField({
						textFieldProps,
						searchIcon: searchIconDynamic,
					})
				) : (
					<Textfield
						{...{ ...textFieldProps }}
						elemBeforeInput={searchIcon}
						elemAfterInput={!showIconBeforeInput ? searchIconDynamic : clearIcon}
						{...(id !== undefined ? { id } : {})}
					/>
				)}
			</InputWrapper>
		</Container>
	);
};

SearchFieldUnmemoized.displayName = 'Search';
SearchFieldUnmemoized.whyDidYouRender = true;

export const SearchField = memo(SearchFieldUnmemoized);

SearchField.displayName = 'Search';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ shouldFitContainerWidth: boolean; reducedHeight?: boolean }>(
	{
		display: 'flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ shouldFitContainerWidth }) => (shouldFitContainerWidth ? 'width: 100%' : ''),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ reducedHeight }) => (reducedHeight ? 'height: 32px' : ''),
);

type InputWrapperProps = {
	isExpanded?: boolean;
	shouldFitContainerWidth?: boolean;
	placeholderAlwaysVisible?: boolean;
	isResponsive?: boolean;
	maxWidth?: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputWrapper = styled.div<InputWrapperProps>(
	{
		display: 'flex',
		transition: 'all 0.3s cubic-bezier(0.15, 1, 0.3, 1)',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"input[type='text']::-ms-clear": {
			display: 'none',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"input[type='text']::placeholder": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			opacity: ({ placeholderAlwaysVisible }) => (placeholderAlwaysVisible ? '1' : '0'),
			transition: 'opacity 0.35s ease-in-out',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"input[type='text']:focus::placeholder": {
			opacity: 1,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isResponsive, isExpanded, maxWidth, shouldFitContainerWidth }) =>
		isResponsive
			? {
					width: `${maxWidth}px`,
					[media.below.md]: {
						width: isExpanded ? `${maxWidth}px` : '30px',
						height: isExpanded ? 'auto' : '32px',
						"input[type='text']": {
							padding: isExpanded
								? `${token('space.050')} ${token('space.075')}`
								: token('space.0'),
							width: isExpanded ? 'auto' : 0,
						},
						"input[type='text']::placeholder": {
							opacity: isExpanded ? 1 : 0,
						},
					},
					[media.only.md]: {
						width: isExpanded ? `${maxWidth}px` : '120px',
					},
				}
			: {
					width: shouldFitContainerWidth ? '100%' : `${isExpanded ? maxWidth : 120}px`,
				},
);
