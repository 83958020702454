import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { polarisSettingsEarlyAccessFeaturesRoute } from '@atlassian/jira-router-routes-polaris-routes/src/polarisSettingsEarlyAccessFeaturesRoute.tsx';
import { polarisProductEAPSettingsPageEntryPoint } from './ui/settings/early-access-features/index.tsx';

export const polarisSettingsEarlyAccessFeaturesRouteEntry = createEntry(
	polarisSettingsEarlyAccessFeaturesRoute,
	() => ({
		isStrictModeEnabled: false,
		group: 'global-settings',
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],

		entryPoint() {
			return polarisProductEAPSettingsPageEntryPoint;
		},
	}),
);
