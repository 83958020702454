import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { ListSkeleton } from '@atlassian/jira-skeletons/src/ui/list/ListSkeleton.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { classicSoftwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { softwareListClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareListClassicRoute.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';
import { navigationResource } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { fieldsResource } from '@atlassian/jira-router-resources-business-fields/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { softwareListRouteEntry } from './ui/software/list/index.tsx';

export const softwareListClassicRouteEntry = createEntry(softwareListClassicRoute, () => ({
	group: 'classic-software',
	skeleton: ListSkeleton,

	entryPoint() {
		return softwareListRouteEntry;
	},

	layout: composeLayouts(
		classicSoftwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},

	// TODO: This is a copy of getProjectViewResources, but it has been copied in order to replace
	//  with .
	resources: [
		// the below 5 resources are copied from getBusinessProjectResources
		// they should be feature flagged and removed when it is safe to do so or
		// moved out of business packages
		// start getBusinessProjectResources
		projectCreatePermissionsResource,
		navigationResource,
		// eslint-disable-next-line camelcase
		DEPRECATED_DO_NOT_USE_projectDetailsResource,
		// end getBusinessProjectResources
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		fieldsResource,
		resourceWithCondition2(() => getIsSoftwareThemingEnabled(), projectThemeResource),
	],
	afterPaint: [LazyIssueApp],
	ufoName: 'classic-list',
}));
