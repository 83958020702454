import React, { useState, useEffect } from 'react';
import { EntryPointContainer } from 'react-relay';
import { usePageLayoutEntryPoint } from '@atlassian/jira-entry-point-page-layout/src/index.tsx';
import { LEFT_SIDEBAR } from '@atlassian/jira-entry-point-page-layout-fields/src/index.tsx';
import { EntryPointReferencePlaceholder } from '@atlassian/jira-entry-point-placeholder/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { Skeleton as ProjectSettingsSkeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/common/ui/skeleton/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { NavigateBackProvider } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-back-button/src/controllers/navigate-back-context/index.tsx';
import { GLOBAL } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-layer.tsx';
import { SIDEBAR } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-container.tsx';
import { V4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/nav-state.tsx';
import { getNav4Rollout } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-nav4-rollout/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { SidebarSelector } from '../regular/index.tsx';
import { getNav4SidebarId, useGetParamsForGetNav4SidebarId } from '../../common/logic.tsx';

// Nav4SidebarEntryPointContainer and SidebarSelector are designed to have the same features.
// Therefore, this component needs to have the NavigateBack & ContextualAnalyticsData.
export const SidebarEntryPointSelector = componentWithFG(
	'blu-5799-jira-software-board-entrypoint-route',
	Nav4SidebarEntryPointContainer,
	SidebarSelector,
);

function Nav4SidebarEntryPointContainer() {
	const currentRoute = useCurrentRoute();
	const paramsForGetNav4SidebarId = useGetParamsForGetNav4SidebarId();
	const currentSidebarId = getNav4SidebarId(paramsForGetNav4SidebarId);
	const currentEntryPointRef = usePageLayoutEntryPoint(LEFT_SIDEBAR);

	const [sidebarConfig, setSidebarConfig] = useState(() => {
		// We want to avoid switching between the SidebarSelector and the sidebar entryPoint as we navigate through the SPA.
		// To do that, we need to keep using the __initial__ sidebar entryPoint if we haven't changed sidebars.
		// When we do switch sidebars, keep using SidebarSelector from that point onwards (for now).
		// When all of the sidebars are using entryPoints, we can deprecate the SidebarSelector.
		const attributes = {
			navigationLayer: GLOBAL,
			navigationContainer: SIDEBAR,
			navState: V4,
			stage: getNav4Rollout().stage,
		};

		return {
			Component: () =>
				currentEntryPointRef && currentRoute.navigation?.sidebarId === currentSidebarId ? (
					<UFOSegment name="nav4-sidebar-entrypoint">
						<JSErrorBoundary
							fallback="flag"
							id="nav4-sidebar-entrypoint"
							packageName="jira-page-container-v2"
						>
							<NavigateBackProvider>
								<ContextualAnalyticsData attributes={attributes}>
									<EntryPointReferencePlaceholder
										name="nav4-sidebar-entrypoint-placeholder"
										fallback={<ProjectSettingsSkeleton />}
										entryPointReference={currentEntryPointRef}
									>
										<EntryPointContainer
											entryPointReference={currentEntryPointRef}
											// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
											props={{} as never}
										/>
									</EntryPointReferencePlaceholder>
								</ContextualAnalyticsData>
							</NavigateBackProvider>
						</JSErrorBoundary>
					</UFOSegment>
				) : (
					<SidebarSelector />
				),
			sidebarId: currentSidebarId,
		};
	});

	useEffect(() => {
		if (sidebarConfig.sidebarId !== currentSidebarId) {
			setSidebarConfig({ Component: SidebarSelector, sidebarId: currentSidebarId });
		}
	}, [currentSidebarId, sidebarConfig.sidebarId]);

	return <sidebarConfig.Component />;
}
