/**
 * @generated SignedSource<<e12d4f314c52bc34112ad7130fbed63f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStatus = "ACTIVE" | "ARCHIVED" | "DELETED" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectMenuItem$data = {
  readonly projectType: JiraProjectType;
  readonly status: JiraProjectStatus;
  readonly " $fragmentSpreads": FragmentRefs<"JcsProjectMenu" | "JiraBusinessProjectMenu" | "JpdProjectMenu" | "JsmProjectMenu" | "JswProjectMenu">;
  readonly " $fragmentType": "Nav4ProjectMenuItem";
};
export type Nav4ProjectMenuItem$key = {
  readonly " $data"?: Nav4ProjectMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjcsprojecttypem3relayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4ProjectMenuItem",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "status"
      },
      "action": "THROW",
      "path": "status"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectType"
      },
      "action": "THROW",
      "path": "projectType"
    },
    {
      "kind": "FragmentSpread",
      "name": "JiraBusinessProjectMenu"
    },
    {
      "kind": "FragmentSpread",
      "name": "JsmProjectMenu"
    },
    {
      "kind": "FragmentSpread",
      "name": "JpdProjectMenu"
    },
    {
      "kind": "FragmentSpread",
      "name": "JswProjectMenu"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjcsprojecttypem3relayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "JcsProjectMenu"
        }
      ]
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "02c3a87c3a12d872d12055c4d3fcc2b5";

export default node;
