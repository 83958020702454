import {
	USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
	USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_SOFTWARE,
} from '@atlassian/jira-onboarding-core/src/constants.tsx';
import type {
	TailoredOnboardingM2P5TailoredTeamType,
	TailoredOnboardingTeamType,
	TailoredOnboardingTeamTypeWithSoftware,
} from '@atlassian/jira-tailored-onboarding/src/types.tsx';

// @todo: remove in `jira_tailored_onboarding_single_config` cleanup
export const TeamType: Record<
	string,
	TailoredOnboardingTeamType | TailoredOnboardingM2P5TailoredTeamType
> = {
	productManagement: USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	projectManagement: USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
	design: USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	itSupport: USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	otherPersonal: USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
	marketing: USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	operations: USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	software: USER_SEGMENTATION_TEAM_TYPE_SOFTWARE,
	// Fallback team type config used for team types we aren't catering for e.g. Data science
	// and also used if the team type is unexpected/unknown
	everythingElse: 'everythingElse',
} as const;

// @todo: rename to TeamType in `jira_tailored_onboarding_single_config` cleanup
export const TeamTypeWithPrefix = {
	productManagement: `teamType.${USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT}`,
	projectManagement: `teamType.${USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT}`,
	design: `teamType.${USER_SEGMENTATION_TEAM_TYPE_DESIGN}`,
	itSupport: `teamType.${USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT}`,
	otherPersonal: `teamType.${USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL}`,
	marketing: `teamType.${USER_SEGMENTATION_TEAM_TYPE_MARKETING}`,
	operations: `teamType.${USER_SEGMENTATION_TEAM_TYPE_OPERATIONS}`,
	software: `teamType.${USER_SEGMENTATION_TEAM_TYPE_SOFTWARE}`,
	// Fallback team type config used for team types we aren't catering for e.g. Data science
	// and also used if the team type is unexpected/unknown
	everythingElse: 'teamType.everythingElse',
} as const satisfies Record<string, `teamType.${TailoredOnboardingTeamTypeWithSoftware}`>;

export const Feature = {
	hasBacklog: 'feature.hasBacklog',
	hasSprint: 'feature.hasSprint',
} as const;
