import { Component } from 'react';
import { di } from 'react-magnetic-di';
import { withAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import getScopeData from '@atlassian/jira-common-navigation/src/get-scope-data/index.tsx';
import type { ScopeData } from '@atlassian/jira-common-navigation/src/get-scope-data/types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { withFlagService, type FlagService } from '@atlassian/jira-flags';
import getMeta from '@atlassian/jira-get-meta';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { withRouter, type RouteContext } from '@atlassian/react-resource-router';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { errorFlag } from './flags/index.tsx';

const THRESHOLD = 30 * 1000;
type Props = RouteContext & {
	flagService: FlagService;
	createAnalyticsEvent: (arg1: { action: string }) => UIAnalyticsEvent;
};
const getScopeType = (scope: ScopeData) => {
	switch (scope.identifierType) {
		case 'boardId':
			return 'board';
		case 'projectId':
		case 'projectKey':
			return scope.pageType === 'settings' ? 'projectSettings' : 'project';
		default:
			return '';
	}
};
// TODO : JFP 2250, JFP-2251 - Please add prefix here for the sessionStorageProvider and update in the test as well
const sessionStorageProvider = createSessionStorageProvider('');

/**
 * Manages URL-based redirection to support legacy routing systems while preventing redirect loops.
 * It employs session storage to track recent redirection attempts, identifying and mitigating potential loops
 * by logging relevant analytics and halting further redirects within a specified time frame.
 */
// eslint-disable-next-line jira/react/no-class-components
export class RedirectToLegacyRouting extends Component<Props> {
	componentDidMount() {
		// eslint-disable-next-line jira/react-magnetic-di/no-unneeded
		di(sessionStorage, sessionStorageProvider, window);
		const {
			location: { pathname, search },
			flagService,
			createAnalyticsEvent,
		} = this.props;
		const redirectPathname = pathname.startsWith('//') ? pathname.substr(1) : pathname;
		const lastRedirectTime = fg('jfp-vulcan-browser-storage-migration')
			? Number(sessionStorageProvider.get(redirectPathname))
			: Number(sessionStorage.getItem(redirectPathname));

		if (!lastRedirectTime) {
			fg('jfp-vulcan-browser-storage-migration')
				? sessionStorageProvider.set(redirectPathname, Date.now().toString())
				: sessionStorage.setItem(redirectPathname, Date.now().toString());
		} else if (Date.now() - lastRedirectTime < THRESHOLD) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const scopeData = getScopeData(window.location.href, getMeta);
			const scope = getScopeType(scopeData);
			log.safeErrorWithoutCustomerData(
				'redirect-loop.error',
				'Redirect error caused by invalid url',
				{
					scope,
				},
			);
			fireOperationalAnalytics(
				createAnalyticsEvent({
					action: 'entered',
				}),
				'redirect-loop entered',
				'endeavour.spa.redirect-loop',
				{
					scope,
				},
			);
			flagService.showFlag(errorFlag());
			fg('jfp-vulcan-browser-storage-migration')
				? sessionStorageProvider.remove(redirectPathname)
				: sessionStorage.removeItem(redirectPathname);
			return;
		} else {
			fg('jfp-vulcan-browser-storage-migration')
				? sessionStorageProvider.remove(redirectPathname)
				: sessionStorage.removeItem(redirectPathname);
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.assign(redirectPathname + search);
	}

	render() {
		return null;
	}
}
// @ts-expect-error - TS2345 - Argument of type 'ForwardRefExoticComponent<Pick<WithAnalyticsEventsProps, never> & RefAttributes<any>>' is not assignable to parameter of type 'ComponentType<{ flagService: FlagService; }>'. | TS2345 - Argument of type 'typeof RedirectToLegacyRouting' is not assignable to parameter of type '(((props: WithAnalyticsEventsProps) => ReactElement<any, string | ((props: any) => ReactElement<any, any> | null) | (new (props: any) => Component<any, any, any>)> | null) & typeof RedirectToLegacyRouting) | ((new (props: WithAnalyticsEventsProps) => Component<...>) & typeof RedirectToLegacyRouting)'.
export default withRouter(withFlagService(withAnalyticsEvents()(RedirectToLegacyRouting)));
