import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	createRoadmap: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.action-buttons-static.create-roadmap',
		defaultMessage: 'Create roadmap',
		description:
			'Used as the alt text for an icon button that when used navigates to create roadmap',
	},
});
