// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import { type State, initialState } from '../common/types.tsx';
import { actions, type Actions } from './actions/index.tsx';
import { getReturnFocusTo } from './selectors/index.tsx';

const Store = createStore<State, Actions>({
	name: 'JiraSoftwareModalDialog',
	initialState,
	actions,
});

export const useModalDialogActions = createActionsHook(Store);

export const useReturnFocusTo = createStateHook(Store, {
	selector: getReturnFocusTo,
});
