import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JSResourceForUserVisible } from '@atlassian/react-async';

export const nativeIssueTableEntryPoint = createEntryPoint({
	root: JSResourceForUserVisible(() =>
		import(
			/* webpackChunkName: "async-issue-navigator-native-issue-table-entrypoint" */ './index.tsx'
		).then((module) => module.NativeIssueTableEntryPoint),
	),
	getPreloadProps: () => ({}),
});
