const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

export function daysBetween(firstDate: Date, secondDate: Date) {
	if (!isValidDate(firstDate) || !isValidDate(secondDate)) {
		return -1;
	}

	const first = firstDate.getTime();
	const second = secondDate.getTime();
	const millisecondsBetween = Math.abs(second - first);

	return Math.round(millisecondsBetween / MILLISECONDS_IN_A_DAY);
}

// https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date/44198641#44198641
function isValidDate(date: any) {
	return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}
