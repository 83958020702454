import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSharedNavigationSingleResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { connectProjectRoute } from '@atlassian/jira-router-routes-connect-routes/src/connectProjectRoute.tsx';
import { getProjectConnectRouteDefaultResources } from './common/index.tsx';
import { connectProjectsPageEntrypoint } from './ui/spa/connect/projects/index.tsx';

export const connectProjectRouteEntry = createEntry(connectProjectRoute, () => ({
	group: 'connect',
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},
	layout: genericProjectLayout,
	ufoName: 'jira-connect',
	// Match resources that are fetched for legacyConnectProjectRouteEntry
	// See src/packages/router-routes/legacy/connect-project/entries/src/index.tsx
	resources: [
		...getProjectConnectRouteDefaultResources(),
		...getServiceDeskSharedNavigationSingleResources(),
		getConsolidationStateResource(),
	],
	entryPoint: () => connectProjectsPageEntrypoint,
}));
