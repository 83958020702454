// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { LayoutState } from '../../../../common/types.tsx';

export const setIsFullscreen =
	(isFullscreen: boolean): Action<LayoutState> =>
	({ setState }) => {
		setState({
			isFullscreen,
		});
	};
