import { createLayout, type Layout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncTransitionHomeRenderer = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-eoc-changeboarding-layout-transition-home-renderer" */ '@atlassian/jira-eoc-changeboarding/src/ui/transition-home/main.tsx'
		).then((module) => module.TransitionHomeRenderer),
	{ ssr: false },
);
AsyncTransitionHomeRenderer.displayName = 'AsyncTransitionHomeRenderer';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncWelcomeModalRenderer = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-eoc-changeboarding-layout-welcome-modal-renderer" */ '@atlassian/jira-eoc-changeboarding/src/ui/wizard/main.tsx'
		).then((module) => module.WelcomeModalRenderer),
	{ ssr: false },
);
AsyncWelcomeModalRenderer.displayName = 'AsyncWelcomeModalRenderer';

export const MigrationHubRightSidebar = componentWithFG(
	'operations-migration-hub-jira',
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyAfterPaint(
		() =>
			import(
				/* webpackChunkName: "async-ops-migration-hub-right-sidebars" */ '@atlassian/jira-ops-settings-layout/src/ui/right-sidebars'
			),
		{ ssr: false },
	),
	() => null,
);
MigrationHubRightSidebar.displayName = 'MigrationHubRightSidebar';

export const MigrationHubOnboarding = componentWithFG(
	'operations-migration-hub-jira',
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyAfterPaint(
		() =>
			import(
				/* webpackChunkName: "async-ops-migration-hub-onboarding" */ '@atlassian/jira-ops-settings-layout/src/ui/onboarding'
			),
		{ ssr: false },
	),
	() => null,
);
MigrationHubOnboarding.displayName = 'MigrationHubOnboarding';

export const eocChangeboardingLayout: Layout = createLayout({
	get globalComponents() {
		return [AsyncTransitionHomeRenderer, AsyncWelcomeModalRenderer, MigrationHubOnboarding];
	},
	rightSidebars: [MigrationHubRightSidebar],
});
