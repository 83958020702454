import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { customPracticeQueueBoardRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customPracticeQueueBoardRoute.tsx';
import { getQueuesResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getRapidBoardDataResource,
	getRapidboardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { horizontalNavQueueResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-queue/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { AsyncHorizontalNavQueue } from '@atlassian/jira-horizontal-nav-queue/src/async.tsx';

import { BoardSkeleton, boardPageEntryPoint, boardPageNav3EntryPoint } from './ui/board/index.tsx';

export const customPracticeQueueBoardRouteEntry = createEntry(
	customPracticeQueueBoardRoute,
	() => ({
		group: 'servicedesk',
		component: ErrorPagesNotFound,
		entryPoint: () => {
			if (fg('jsm_views_inside_queues_-_main_flag')) {
				return getWillShowNav4() ? boardPageEntryPoint : boardPageNav3EntryPoint;
			}
			return undefined;
		},
		layout: serviceProjectLayout,
		skeleton: BoardSkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal:
				// eslint-disable-next-line jira/ff/no-preconditioning
				fg('jsm_views_inside_queues_-_main_flag') && !getWillShowNav4()
					? AsyncHorizontalNavQueue
					: undefined,
		},

		resources: [
			...getQueuesResources(),
			getConsolidationStateResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			resourceWithCondition2(() => !__SERVER__, uifBoardResource),
			resourceWithCondition2(() => !getWillShowNav4(), horizontalNavQueueResource),
		],
		forPaint: [LazyAtlassianNavigation],
	}),
);
