import React from 'react';
import { globalEscalationsListInfoResource } from '@atlassian/eoc-escalation-policies/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalEscalationsRoute } from '@atlassian/jira-router-routes-eoc-global-escalations-routes/src/globalEscalationsRoute.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazySidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-operations/src/ui/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LazyGlobalEscalations = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-global-escalations", jiraSpaEntry: "async-jira-eoc-global-escalations" */ '@atlassian/jira-eoc-global-escalations'
	).then((module) => module.GlobalEscalationsPage),
);

const GlobalEscalationsComponent = () => (
	<LazyPage
		Page={LazyGlobalEscalations}
		pageId="async-jira-eoc-global-escalations"
		shouldShowSpinner={false}
	/>
);

const getNavigationSpecifications = () => {
	return fg('operations_nav4_remaining_items')
		? {
				sidebarId: SIDEBAR_ID.OPERATIONS_GENERAL_CONFIGURATION,
			}
		: {
				sidebar: AsyncSidebarGlobalSettingsProducts,
				horizontal: AsyncHorizontalOperationsConfigurationNav,
				onlyShowHorizontalOnNav4: true,
			};
};

export const globalEscalationsRouteEntry = createEntry(globalEscalationsRoute, () => ({
	group: 'eoc',
	component: GlobalEscalationsComponent,
	layout: opsSettingsLayout,
	resources: [
		...getNavigationResources(),
		globalEscalationsListInfoResource,
		getConsolidationStateResource(),
		navigationSidebarGlobalResource,
	],

	navigation: {
		menuId: MENU_ID.SETTINGS,
		...getNavigationSpecifications(),
	},

	forPaint: fg('operations_nav4_remaining_items')
		? [LazySidebarContent, LazyAtlassianNavigation, LazyGlobalEscalations]
		: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyGlobalEscalations],
}));
