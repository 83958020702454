import type { DocNode } from '@atlaskit/adf-schema';
import type { IncidentSuggestion } from '../common/types.tsx';
import type { ServerRawResponse } from './types.tsx';

// Resource doesn't allow imports of getEmptyADF();
const EMPTY_DOCUMENT: DocNode = {
	version: 1,
	type: 'doc',
	content: [],
};

const parseToADF = (adfString: string): DocNode => {
	try {
		return JSON.parse(adfString);
	} catch (error) {
		return EMPTY_DOCUMENT;
	}
};

const transformContent = (content: { title: string; description: string }) => {
	return {
		title: parseToADF(content.title),
		description: parseToADF(content.description),
	};
};

const exhaustiveCheck = (_: never) => null;

export const transformResponse = (response: ServerRawResponse): IncidentSuggestion => {
	return {
		type: 'OpsPanelSuggestions',
		incidentSummary: {
			...response.incidentSummary.content,
			description: parseToADF(response.incidentSummary.content.description),
		},
		probableRCA: response.probableRCA?.content
			? {
					...response.probableRCA.content,
					description: parseToADF(response.probableRCA.content.description),
				}
			: null,
		suggestedActions: response.suggestedActions
			.map((action) => {
				switch (action.type) {
					case 'SEVERITY':
						return {
							...action,
							context: {
								...action.context,
								suggestion: {
									...action.context.suggestion,
									value: action.context.suggestion.name,
								},
							},
							content: transformContent(action.content),
						};
					case 'AFFECTED_SERVICES':
						return {
							...action,
							context: {
								...action.context,
								suggestion: action.context.suggestion.map((service) => ({
									id: service.id,
									name: service.name,
									data: service,
								})),
							},
							content: transformContent(action.content),
						};
					case 'PROBLEM_TICKET':
					case 'MAJOR_INCIDENT':
					case 'PRIORITY':
					case 'RESPONDERS':
					case 'RCA_WITH_AUTOMATION':
						return {
							...action,
							content: transformContent(action.content),
						};
					default:
						return exhaustiveCheck(action);
				}
			})
			.filter(Boolean),
	};
};
