// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import type { TopNavigationState } from '../../../../common/types.tsx';
import { layoutStore } from '../../store/index.tsx';

const useTopNav = createHook(layoutStore, {
	selector: (state) => ({
		...state.topNavigation,
		shouldRender: !state.isChromeless,
	}),
});

type TopNavState = TopNavigationState & {
	shouldRender: boolean;
};

export const useTopNavState = (): TopNavState => {
	const [state] = useTopNav();
	return state;
};
