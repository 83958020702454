const FALLBACK_URL = '/admin/billing';

export function buildLearnMoreLink(href?: string | null): string {
	if (!href) {
		return FALLBACK_URL;
	}

	try {
		const url = new URL(href);

		// Check if this is a HAMS URL by looking for 'change-edition' in the path
		if (url.pathname.includes('change-edition')) {
			// For HAMS URLs, we want to remove /premium from the path
			url.pathname = url.pathname.replace('/premium', '');
			return url.toString();
		}

		// For CCP URLs, we just need to remove the offering parameters
		url.searchParams.delete('offeringName');
		url.searchParams.delete('offeringname');
		return url.toString();
	} catch {
		return FALLBACK_URL;
	}
}
