import React, { useState } from 'react';
import { useRegisterNudgeTarget } from '@atlassiansox/nudge-tooltip';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/utility/add';
import { ButtonItem, MenuGroup } from '@atlaskit/menu';
import { Stack, xcss } from '@atlaskit/primitives';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { BUTTON } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	FIXED_ITEM_DROPDOWN_ACTION,
	FIXED_ITEM_BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	LEVEL_TWO,
	LEVEL_ONE,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { SECTION_ITEM_TEAMS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_TEAMS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MenuLinkItem } from '@atlassian/navigation-system';
import { useLocation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-location/index.tsx';
import { JWM_INVITE_TEAM_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { isTailoredOnboardingEnabled } from '@atlassian/jira-tailored-onboarding/src/controllers/index.tsx';
import { InviteTeamNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-invite-team-tour/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	CREATE_A_TEAM,
	INVITE_PEOPLE,
	MEATBALL_DROPDOWN,
	PEOPLE_SEARCH_HREF,
	PLUS_BUTTON,
} from '../common/constants.tsx';
import messages from './messages.tsx';
import { useCreateTeamModal } from './use-create-team-modal/index.tsx';
import { useInvitePeopleModal } from './use-invite-people-modal.tsx';

export function TeamsMenu() {
	const [isOpenTeamsPopup, openTeamsPopup] = useState(false);
	const { formatMessage } = useIntl();
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.TEAMS);

	const location = useLocation();
	const path = location.pathname + location.search;
	const isSelected = path === PEOPLE_SEARCH_HREF;

	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const actionSubjectId = MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_TEAMS];
	const label = formatMessage(messages.addButton);

	const [createTeamModal, createTeamModalTrigger] = useCreateTeamModal();
	const invitePeopleModalTrigger = useInvitePeopleModal();

	const onLinkClick = () => {
		sendSectionItemAnalyticsEvent({
			menuId: L1_MENU_ID.TEAMS,
			sectionItem: SECTION_ITEM_TEAMS,
		});
	};

	const onPlusButtonClick = () => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId,
			attributes: {
				level: LEVEL_ONE,
				componentType: FIXED_ITEM_BUTTON,
				parentItemId: SECTION_ITEM_TEAMS,
				itemId: PLUS_BUTTON,
			},
		});
		openTeamsPopup(true);
	};

	const isTailoredOnboarding = isTailoredOnboardingEnabled();

	const peopleMenuWrapperRef = useRegisterNudgeTarget<HTMLAnchorElement>(
		JWM_INVITE_TEAM_NUDGE_ID,
		isTailoredOnboarding || fg('tailored_onboarding_m2p5_gate'),
	).ref;

	return (
		<>
			{createTeamModal}
			{(isTailoredOnboarding || fg('tailored_onboarding_m2p5_gate')) && <InviteTeamNudgeAsync />}
			<MenuLinkItem
				ref={
					isTailoredOnboarding || fg('tailored_onboarding_m2p5_gate')
						? peopleMenuWrapperRef
						: undefined
				}
				testId="navigation-apps-sidebar-nav4-sidebars-content-teams.ui.sidenav-teams"
				href={PEOPLE_SEARCH_HREF}
				isSelected={isSelected}
				elemBefore={icon}
				onClick={onLinkClick}
				actionsOnHover={
					<>
						<Popup
							shouldRenderToParent
							placement="bottom-start"
							messageId="navigation-apps-sidebar-nav4-sidebars-content-teams.ui.popup"
							messageType="transactional"
							onClose={() => openTeamsPopup(false)}
							trigger={(triggerProps) => (
								<IconButton
									{...triggerProps}
									icon={AddIcon}
									appearance="subtle"
									spacing="compact"
									testId="navigation-apps-sidebar-nav4-sidebars-content-teams.ui.sidenav-teams-popup-trigger"
									isSelected={isOpenTeamsPopup}
									label={label}
									tooltip={{ content: label }}
									isTooltipDisabled={false}
									onClick={onPlusButtonClick}
								/>
							)}
							isOpen={isOpenTeamsPopup}
							content={() => (
								<Stack xcss={teamsMenuStyle}>
									<ButtonItem
										onClick={() => {
											fireUIAnalytics(createAnalyticsEvent({}), {
												action: 'clicked',
												actionSubject: 'dropdownItem',
												actionSubjectId,
												attributes: {
													level: LEVEL_TWO,
													componentType: FIXED_ITEM_DROPDOWN_ACTION,
													parentItemId: SECTION_ITEM_TEAMS,
													itemId: INVITE_PEOPLE,
												},
											});
											invitePeopleModalTrigger();
											openTeamsPopup(false);
										}}
									>
										{formatMessage(messages.invitePeople)}
									</ButtonItem>
									<ButtonItem
										onClick={() => {
											fireUIAnalytics(createAnalyticsEvent({}), {
												action: 'clicked',
												actionSubject: 'dropdownItem',
												actionSubjectId,
												attributes: {
													level: LEVEL_TWO,
													componentType: FIXED_ITEM_DROPDOWN_ACTION,
													parentItemId: SECTION_ITEM_TEAMS,
													itemId: CREATE_A_TEAM,
												},
											});
											createTeamModalTrigger();
											openTeamsPopup(false);
										}}
									>
										{formatMessage(messages.createTeam)}
									</ButtonItem>
								</Stack>
							)}
						/>
						<MoreNavMenuButton
							onOpen={() => {
								fireUIAnalytics(createAnalyticsEvent({}), {
									action: 'clicked',
									actionSubject: BUTTON,
									actionSubjectId,
									attributes: {
										level: LEVEL_ONE,
										componentType: FIXED_ITEM_BUTTON,
										itemId: MEATBALL_DROPDOWN,
									},
								});
							}}
							MenuItems={() => (
								<MenuGroup>
									<HideL1MenuItemSection menuId={MENU_ID_TEAMS} hasSeparator={false} />
								</MenuGroup>
							)}
						/>
					</>
				}
			>
				{formattedMessage}
			</MenuLinkItem>
		</>
	);
}

const teamsMenuStyle = xcss({
	minWidth: '260px',
	paddingBlock: 'space.100',
});
