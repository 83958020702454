import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarAssetsReports from '@atlassian/jira-navigation-apps-sidebar-assets-reports/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { assetsReportsTemplateRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsReportsTemplateRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import {
	AssetsReports,
	LazyServicedeskCmdbAssetsReports,
} from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/ui/reports/index.tsx';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { LazyAssetsLayout, AssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { AssetsPageConfigContainer } from '@atlassian/jira-assets-app-page-config-container/src/ui/async.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';

export const assetsReportsTemplateRouteEntry = createEntry(assetsReportsTemplateRoute, () => ({
	group: 'assets',
	LayoutOverrideComponent: AssetsLayout,
	PageConfigContainerOverride: AssetsPageConfigContainer,
	skeleton: Spinner,
	component: AssetsReports,
	resources: [...getAssetsAppResources(), ...getServicedeskPageContextResources()],
	forPaint: [LazyAssetsLayout, LazyServicedeskCmdbAssetsReports],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarAssetsReports,
	},
	meta: {
		reporting: {
			id: APP_NAMES.INSIGHT_ASSETS_REPORTS,
			packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS),
			teamName: 'ITSM SAGA',
		},
	},
	perfMetricKey: 'service-management.insight-cmdb.load-assets-reports-template',
}));
