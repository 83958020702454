import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { Nav4NotificationsData$key } from '@atlassian/jira-relay/src/__generated__/Nav4NotificationsData.graphql';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { Nav4Notifications } from './Nav4Notifications.tsx';

type Nav4NotificationsDataProps = {
	queryReference: Nav4NotificationsData$key;
};

export const Nav4NotificationsData = (props: Nav4NotificationsDataProps) => {
	const { queryReference } = props;
	const fragmentData = useFragment<Nav4NotificationsData$key>(
		graphql`
			fragment Nav4NotificationsData on Query {
				...Nav4Notifications
			}
		`,
		queryReference,
	);

	return (
		<JSErrorBoundary
			id="nav4-notifications-error-boundary"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
			fallback="flag"
		>
			<Nav4Notifications fragmentData={fragmentData} />
		</JSErrorBoundary>
	);
};
