import type { InvariantJiraRoute } from '@atlassian/jira-route-types/src/InvariantJiraRoute.tsx';

export const customQueueRoute: InvariantJiraRoute = {
	// TODO: We should change the name after 'split_loading_of_queues_agent_view_from_issue_view'
	// is rolled out. The name will not be semantically correct anymore
	name: 'servicedesk-queues-custom-view',
	path: '/jira/servicedesk/projects/:projectKey/queues/custom/:queueId',
	query: ['oldIssueView?'],
	exact: true,
};
