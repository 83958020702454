import { fg } from '@atlassian/jira-feature-gating';
import { isMobileAndInMvpOrExistingUsersExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { getWillShowNav4ForBeta } from './get-will-show-nav4-for-beta/index.tsx';
import { getWillShowNav4ForDogfooding } from './get-will-show-nav4-for-dogfooding/index.tsx';
import { getWillShowNav4ForEap } from './get-will-show-nav4-for-eap/index.tsx';
import { getWillShowNav4ForGa } from './get-will-show-nav4-for-ga/index.tsx';

// Returns true if the user should see nav4 instead of nav3.
// We don't inline feature gates as our logic to show nav4 is not trivial and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design#Decision-points
// All changes to this function should be replicated in the backend (see readme.md).
// All changes to this function should be communicated to Atlassian Home (see readme.md).
export const getWillShowNav4 = () =>
	fg('jira_nav4') &&
	(getWillShowNav4ForDogfooding() ||
		getWillShowNav4ForEap() ||
		getWillShowNav4ForBeta() ||
		getWillShowNav4ForGa() ||
		isMobileAndInMvpOrExistingUsersExperiment());
