import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { featuresResource } from '@atlassian/jira-router-resources-service-project-features/src/services/index.tsx';
import { projectSettingsServicedeskItsmFeaturesRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskItsmFeaturesRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import ServiceDeskItsmFeatures, {
	LazyProjectSettingsServiceDeskItsmFeatures,
} from './ui/service-desk-itsm-features/index.tsx';

export const projectSettingsServicedeskItsmFeaturesRouteEntry = createEntry(
	projectSettingsServicedeskItsmFeaturesRoute,
	() => ({
		group: 'project-settings-servicedesk',
		ufoName: 'jsm-features-settings',
		component: ServiceDeskItsmFeatures,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			featuresResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskItsmFeatures],
		perfMetricKey: 'jsm-features-settings',
	}),
);
