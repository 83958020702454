import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore } from '@atlassian/react-sweet-state';
import type { StorageNew, Storage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import * as actions from './actions/index.tsx';
import type { State } from './model/types.tsx';
import type { Actions } from './types.tsx';

let iphStorage: Storage | StorageNew | undefined;

const createStorage = () => {
	if (iphStorage) return iphStorage;
	iphStorage = createSessionStorageProvider('iph');
	return iphStorage;
};

const initialState: State = {
	navigationData: {
		articleId: { id: '', type: 'HELP_ARTICLE' },
		// FIXME: initialState breaks SSR
		history: __SERVER__ ? [] : createStorage().get('navigationData') || [],
	},
	releaseNotesNotifications: 100,
};

export default createStore<State, Actions>({
	name: 'help-panel',
	initialState,
	actions,
});
