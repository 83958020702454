import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { arjDependenciesEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-routes/src/arjDependenciesEmbedRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { planDependenciesReportEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-dependencies-report/entrypoint.tsx';
import { PlanDependenciesReportEmbedPage, LazyPlanDependenciesReportEmbed } from './ui/index.tsx';

const planDependenciesReportEmbedRouteEntry = createChromelessEntryPoint({
	main: planDependenciesReportEntryPoint,
});

export const arjDependenciesEmbedRouteEntry = createEntry(arjDependenciesEmbedRoute, () => ({
	group: 'software',
	component: componentWithFG(
		'smart_links_for_plans',
		PlanDependenciesReportEmbedPage,
		ErrorPagesNotFound,
	),
	entryPoint: () =>
		fg('migrate_plans_to_entrypoints') ? planDependenciesReportEmbedRouteEntry : undefined,
	layout: createLayout({
		isChromeless: true,
	}),

	resources: [
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		themePreferenceResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	forPaint: [LazyPlanDependenciesReportEmbed],
}));
