import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	MenuList,
	MenuListItem,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import { MENU_ID_PROJECTS_VIEW_ALL_STARRED } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import messages from './messages.tsx';
import type { FavoriteItemsType, RecentItemsType } from './types.tsx';

const VIEW_ALL_STARRED_HREF = '/jira/your-work?starred';

const RenderStarredSection = ({ favoriteItems }: { favoriteItems: FavoriteItemsType }) => {
	const { formatMessage } = useIntl();

	return (
		favoriteItems.hasItems && (
			<>
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{formatMessage(messages.starred)}</MenuSectionHeading>
						<MenuList>{favoriteItems.list}</MenuList>
					</MenuSection>
				</MenuListItem>
				{favoriteItems.hasNextPage && (
					<Nav4MenuLinkItem href={VIEW_ALL_STARRED_HREF} menuId={MENU_ID_PROJECTS_VIEW_ALL_STARRED}>
						{formatMessage(messages.viewAllStarred)}
					</Nav4MenuLinkItem>
				)}
			</>
		)
	);
};

const RenderRecentSection = ({ recentItems }: { recentItems: RecentItemsType }) => {
	const { formatMessage } = useIntl();

	return (
		recentItems.hasItems && (
			<MenuListItem>
				<MenuSection>
					<MenuSectionHeading>{formatMessage(messages.recent)}</MenuSectionHeading>
					<MenuList>{recentItems.list}</MenuList>
				</MenuSection>
			</MenuListItem>
		)
	);
};

export const Nav4ProjectsSections = ({
	favoriteItems,
	recentItems,
}: {
	favoriteItems: FavoriteItemsType;
	recentItems: RecentItemsType;
}) => (
	<>
		<RenderStarredSection favoriteItems={favoriteItems} />
		<RenderRecentSection recentItems={recentItems} />
	</>
);
