import { resources as alertPoliciesResources } from '@atlassian/eoc-policies/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async.tsx';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { alertPoliciesRoute } from '@atlassian/jira-router-routes-eoc-policies-routes/src/alertPoliciesRoute.tsx';
import { AlertPoliciesComponent, LazyAlertPolicies } from './ui/AlertPoliciesComponent.tsx';

export const alertPoliciesRouteEntry = createEntry(alertPoliciesRoute, () => ({
	group: 'eoc',
	component: AlertPoliciesComponent,
	layout: opsTeamLayout,
	resources: [
		...getNavigationResources(),
		...alertPoliciesResources(),
		...teamDashboardResources(),
		getConsolidationStateResource(),
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		onlyShowHorizontalOnNav4: true,
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyAlertPolicies,
		LazyTeamDashboardSidebar,
	],
}));
