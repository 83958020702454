/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { BANNER_NAME as BANNER_NAME_COOKIE_CONSENT } from '@atlassian/jira-cookie-consent-banner/src/constants.tsx';
import { CookieConsentBannerAsync as CookieConsentBanner } from '@atlassian/jira-cookie-consent-banner/src/ui/CookieConsentBannerAsync.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { BANNER_NAME as BANNER_NAME_IPBLOCKLISTING } from '@atlassian/jira-ip-block-listing-banner/src/common/constants.tsx';
import { IPBlocklistingBannerAsync as BannerIPBlocklisting } from '@atlassian/jira-ip-block-listing-banner/src/utils.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { Banner as AkBanner } from '@atlassian/jira-navigation-system/src/index.tsx';
import { useBanner } from '@atlassian/jira-platform-controllers-global-banner/src/controllers/index.tsx';
import { NAME as BANNER_NAME_ANNOUNCEMENT } from '@atlassian/jira-platform-ui-banners-announcement/src/common/constants.tsx';
import { BannerAnnouncementAsync as BannerAnnouncement } from '@atlassian/jira-platform-ui-banners-announcement/src/ui/BannerAnnouncementAsync.tsx';
import { BANNER_NAME as BANNER_NAME_TRANSITION } from '@atlassian/jira-platform-ui-banners-eoc-transition/src/common/constants.tsx';
import { BannerTransitionAsync } from '@atlassian/jira-platform-ui-banners-eoc-transition/src/ui/index.tsx';
import { BANNER_NAME as BANNER_NAME_MAIL_EXPIRY } from '@atlassian/jira-platform-ui-mail-expiry-reminder/src/constants.tsx';
import { MailExpiryBannerAsync as MailExpiryBanner } from '@atlassian/jira-platform-ui-mail-expiry-reminder/src/ui/index.tsx';
import { BANNER_NAME as BANNER_NAME_RETURN_TO_MOBILE } from '@atlassian/jira-return-to-mobile-banner/src/common/constants.tsx';
import { ReturnToMobileBanner as BannerReturnToMobile } from '@atlassian/jira-return-to-mobile-banner/src/utils.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ZIndexWrapper } from '../z-index-wrapper/index.tsx';
import { AsyncNav4PromptBanner as Nav4PromptBanner } from './nav4-prompt-banner/AsyncNav4PromptBanner.tsx';
import { useNav4PromptBanner } from './nav4-prompt-banner/use-nav4-prompt-banner.tsx';

/**
 * This test ID is used in pollinator tests. Do not change lightly.
 */
const TEST_ID = 'page-layout.banner';

/* IMPORTANT:
 * When making any changes to the OLD component, please ensure that you also make the same changes to the
 * NEW component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */
export const NewBanner = () => {
	const banner = useBanner();
	return banner.isEnabled ? (
		<UFOSegment name="banner">
			<AkBanner height={banner.height} testId={TEST_ID}>
				<BannerContent bannerName={banner.name} />
			</AkBanner>
		</UFOSegment>
	) : null;
};

/**
 * Dynamically displays a banner based on the current context and feature flags. It supports several types of banners,
 * each serving a different purpose (e.g., announcements, cookie consent). The decision to display a particular banner is
 * made through a combination of fetched banner data and the evaluation of feature flags, ensuring that the presented banner
 * is relevant to the user's current interaction and system state.
 */
export const OldBanner = () => {
	const banner = useBanner();
	return banner.isEnabled ? (
		<UFOSegment name="banner">
			<ZIndexWrapper zIndex={layers.banner}>
				<AkBanner height={banner.height}>
					<BannerContent bannerName={banner.name} />
				</AkBanner>
			</ZIndexWrapper>
		</UFOSegment>
	) : null;
};

/** This component extends the functionality of OldBanner to include the Nav4 prompt banner.
 * If an existing banner is enabled, it displays both the existing banner and the Nav4 prompt banner underneath it.
 * If there is no existing banner, only the Nav4 prompt banner is displayed.
 * Put the logic into a separate component to make it easier to manage and remove once the Nav4 prompt is no longer needed.
 */
export const OldBannerWithNav4PromptBanner = ({
	onDismiss,
}: {
	onDismiss: (value: boolean) => void;
}) => {
	const banner = useBanner();
	const oldBannerHeight = banner.isEnabled ? banner.height || 56 : 0;
	// the prompt banner height is 44px;
	// if the `banner.height` is undefined, use the default banner height which is 56px;
	const bannerContainerHeight = oldBannerHeight + 44;

	// TODO: check with dismissible banners
	return (
		<UFOSegment name="banner">
			<ZIndexWrapper zIndex={layers.banner}>
				<AkBanner height={bannerContainerHeight}>
					<Nav4PromptBanner onDismiss={onDismiss} />
					{/* wrapping Old Banner in case if it uses `height: 100%` and occupies all the space made for 2 banners */}
					{/* not putting dynamic height in the oldBannerWrapper css, as the linter suggested to add it to the style prop instead */}
					{/* eslint-disable-next-line jira/react/no-style-attribute */}
					<div css={[oldBannerWrapper]} style={{ height: oldBannerHeight }}>
						{banner.isEnabled && <BannerContent bannerName={banner.name} />}
					</div>
				</AkBanner>
			</ZIndexWrapper>
		</UFOSegment>
	);
};

/** Adding the position value so that old banner's Dismiss icon is displayed properly */
const oldBannerWrapper = css({
	position: 'relative',
});

/** Should be removed on blu-6963-nav4-phase-1-admin-banner cleanup */
export const OldBannerForGARollout = () => {
	const { shouldShowPromptBanner, setDismissed } = useNav4PromptBanner();

	return shouldShowPromptBanner ? (
		<OldBannerWithNav4PromptBanner onDismiss={setDismissed} />
	) : (
		<OldBanner />
	);
};

const BannerContent = ({ bannerName }: { bannerName: string | null }) => (
	<>
		{bannerName === BANNER_NAME_ANNOUNCEMENT && <BannerAnnouncement />}
		{bannerName === BANNER_NAME_IPBLOCKLISTING && <BannerIPBlocklisting />}
		{bannerName === BANNER_NAME_RETURN_TO_MOBILE && <BannerReturnToMobile />}
		{bannerName === BANNER_NAME_COOKIE_CONSENT && fg('jfp-magma-cookie-controls-banner-show') && (
			<CookieConsentBanner />
		)}
		{bannerName === BANNER_NAME_TRANSITION && <BannerTransitionAsync />}
		{bannerName === BANNER_NAME_MAIL_EXPIRY && <MailExpiryBanner />}
	</>
);

export const Banner = componentWithCondition(getWillShowNav4, NewBanner, OldBannerForGARollout);
