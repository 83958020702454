import { resources as alertDetailResources } from '@atlassian/eoc-alert-detail/resources';
import { resources as alertListResources } from '@atlassian/eoc-alert-list/resources';
import { getAutomationDiscoveryDismissResource } from '@atlassian/jira-router-resources-eoc-alert-detail/src/automation-dicovery-dismiss-resource/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	AsyncSidebarEocGlobalAlerts,
	LazyGlobalAlertsSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-global-alerts/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocGlobalAlertsDetailRoute } from '@atlassian/jira-router-routes-eoc-global-alerts-routes/src/eocGlobalAlertsDetailRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { AlertListComponent, LazyAlertList } from './ui/AlertListComponent.tsx';

export const eocGlobalAlertsDetailRouteEntry = createEntry(eocGlobalAlertsDetailRoute, () => ({
	group: 'eoc',
	component: AlertListComponent,
	resources: [
		...getNavigationResources(),
		...alertListResources(),
		...alertDetailResources(),
		getConsolidationStateResource(),
		resourceWithCondition2(
			() => fg('automation_discoverability_in_jsm'),
			getAutomationDiscoveryDismissResource,
		),
	],
	navigation: {
		menuId: MENU_ID.HOME,
		sidebar: AsyncSidebarEocGlobalAlerts,
	},
	forPaint: [LazyAtlassianNavigation, LazyAlertList, LazyGlobalAlertsSidebar],
}));
