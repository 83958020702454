import React, { useCallback } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import Link from '@atlaskit/link';
import Lozenge from '@atlaskit/lozenge';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { type ShowFlagFn, toFlagId, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { PROJECTS_NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-id.tsx';
import {
	DROPDOWN_ITEM,
	NAVIGATION_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	CUSTOM_ITEM,
	CUSTOM_ITEM_BUTTON,
	CUSTOM_ITEM_DROPDOWN_ACTION,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	DISMISS_RECOMMENDATION,
	JWM_SAMPLE_PROJECT,
	MEATBALL_DROPDOWN,
	WHY_AM_I_SEEING_THIS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { PROJECT_RECOMMENDATION } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import {
	LEVEL_ONE,
	LEVEL_THREE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	fireOperationalAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type { JiraLinkConfig } from '@atlassian/jira-router-components/src/ui/router-link-config/index.tsx';
import { UNLICENSED } from '@atlassian/jira-shared-types/src/edition.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { OriginSource, RecommendationContext } from '../../common/types.tsx';
import { RecommendationActionMenu } from '../../common/ui/recommendation-action-menu/recommendation-action-menu.tsx';
import { PACKAGE_NAME } from '../../constants.tsx';
import {
	BRAIN_JSW_TO_JWM_IMPLICIT_SITE_BOOLEAN,
	CREATE_JWM_SAMPLE_PROJECT_ENDPOINT,
	CREATE_JWM_SAMPLE_PROJECT_ERROR_FLAG_ID,
	CREATE_JWM_SAMPLE_PROJECT_KEY,
	PROJECTS_MENU_EXISTING_USERS,
	SITE_USER_TRAIT_HAS_CREATED_JWM_PROJECT,
	SITE_USER_TRAIT_HAS_CREATED_SAMPLE_PROJECT,
	SITE_USER_TRAIT_HAS_DISMISSED_EXPERIMENT,
	SITE_USER_TRAIT_VIEWED_EXPERIMENT_DROPDOWN_DATE,
} from './constants.tsx';
import messages from './messages.tsx';
import { SampleProjectAvatar } from './project-avatar/index.tsx';

export const CreateJwmSampleProjectRecommendation = ({
	recommendationKey,
	dismissRecommendation,
}: {
	recommendationKey: string;
	dismissRecommendation: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const createSampleProjectClicked = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: NAVIGATION_ITEM,
				action: 'clicked',
			}),
			PROJECTS_NAVIGATION_ITEM,
			{
				level: LEVEL_ONE,
				componentType: CUSTOM_ITEM,
				itemType: PROJECT_RECOMMENDATION,
				itemId: JWM_SAMPLE_PROJECT,
			},
		);
		createJwmSampleProject(showFlag);
	}, [createAnalyticsEvent, showFlag]);

	const onLearnMoreClicked = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: DROPDOWN_ITEM,
				action: 'clicked',
			}),
			PROJECTS_NAVIGATION_ITEM,
			{
				level: LEVEL_THREE,
				componentType: CUSTOM_ITEM_DROPDOWN_ACTION,
				parentItemType: PROJECT_RECOMMENDATION,
				parentItemId: JWM_SAMPLE_PROJECT,
				itemId: WHY_AM_I_SEEING_THIS,
			},
		);
	}, [createAnalyticsEvent]);

	const onDismissClicked = useCallback(() => {
		dismissRecommendation();

		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: DROPDOWN_ITEM,
				action: 'clicked',
			}),
			PROJECTS_NAVIGATION_ITEM,
			{
				level: LEVEL_THREE,
				componentType: CUSTOM_ITEM_DROPDOWN_ACTION,
				parentItemType: PROJECT_RECOMMENDATION,
				parentItemId: JWM_SAMPLE_PROJECT,
				itemId: DISMISS_RECOMMENDATION,
			},
		);
		showFlag({
			type: 'success',
			isAutoDismiss: true,
			title: formatMessage(messages.dismissConfirmationTitle),
			description: formatMessage(messages.dismissConfirmationText),
			messageId:
				'navigation-apps-sidebar-nav4-sidebars-content-growth-recommendations.ui.jwm-sample-project-recommendation.show-flag.success',
			messageType: 'transactional',
		});
	}, [dismissRecommendation, createAnalyticsEvent, showFlag, formatMessage]);

	const recommendationActionMenuClickHandler = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
			}),
			PROJECTS_NAVIGATION_ITEM,
			{
				level: LEVEL_TWO,
				componentType: CUSTOM_ITEM_BUTTON,
				parentItemType: PROJECT_RECOMMENDATION,
				parentItemId: JWM_SAMPLE_PROJECT,
				itemId: MEATBALL_DROPDOWN,
			},
		);
	}, [createAnalyticsEvent]);

	return (
		<>
			<Nav4MenuLinkItem<JiraLinkConfig>
				elemBefore={<SampleProjectAvatar />}
				elemAfter={
					<Lozenge appearance="new" isBold={false}>
						{formatMessage(messages.lozengeText)}
					</Lozenge>
				}
				href=""
				menuId={recommendationKey}
				onClick={createSampleProjectClicked}
				actionsOnHover={
					<RecommendationActionMenu
						onClick={recommendationActionMenuClickHandler}
						onLearnMoreClicked={onLearnMoreClicked}
						onDismissClicked={onDismissClicked}
						title={formatMessage(messages.modalTitle)}
						body={
							fg('remove-learn-more-link-from-jwm-sample-project')
								? formatMessage(messages.modalBody, {
										br: <br />,
									})
								: formatMessage(messages.modalBodySpork, {
										br: <br />,
										link: (
											<Link
												href="https://www.atlassian.com/software/jira/work-management"
												target="_blank"
											>
												{formatMessage(messages.learnMoreLinkSpork)}
											</Link>
										),
									})
						}
						primaryCta={formatMessage(messages.modalCta)}
					/>
				}
			>
				{formatMessage(messages.ctaHeadingText)}
			</Nav4MenuLinkItem>
		</>
	);
};

const checkEligibility = (recommendationContext: RecommendationContext) => {
	const {
		licensedProducts,
		firstActivationDateMs,
		edition,
		accountId,
		siteTraitsData,
		userTraitsData,
	} = recommendationContext;

	if (!licensedProducts?.[LICENSED_PRODUCTS.JIRA_SOFTWARE])
		return { isEligible: false, reason: 'User does not have jira software' };

	if (edition === UNLICENSED) return { isEligible: false, reason: 'User is unlicensed' };

	if (!accountId) return { isEligible: false, reason: 'Account Id is null' };

	if (!userTraitsData) {
		return { isEligible: false, reason: 'user traits are null' };
	}

	if (!siteTraitsData) {
		return { isEligible: false, reason: 'site traits are null' };
	}

	if (
		userTraitsData?.some(
			(trait) => trait.name === SITE_USER_TRAIT_HAS_CREATED_JWM_PROJECT && trait.value,
		)
	)
		return {
			isEligible: false,
			reason: `User has trait ${SITE_USER_TRAIT_HAS_CREATED_JWM_PROJECT}`,
		};

	if (
		userTraitsData?.some(
			(trait) => trait.name === SITE_USER_TRAIT_HAS_CREATED_SAMPLE_PROJECT && trait.value,
		)
	)
		return {
			isEligible: false,
			reason: `User has trait ${SITE_USER_TRAIT_HAS_CREATED_SAMPLE_PROJECT}`,
		};

	if (
		userTraitsData?.some(
			(trait) => trait.name === SITE_USER_TRAIT_HAS_DISMISSED_EXPERIMENT && trait.value,
		)
	)
		return {
			isEligible: false,
			reason: `User has trait ${SITE_USER_TRAIT_HAS_DISMISSED_EXPERIMENT}`,
		};

	if (!firstActivationDateMs)
		return {
			isEligible: false,
			reason: 'firstActivationDateMs is undefined',
		};

	if (
		differenceInDays(new Date(), firstActivationDateMs) > 28 &&
		userTraitsData?.some(
			(trait) =>
				trait.name === SITE_USER_TRAIT_VIEWED_EXPERIMENT_DROPDOWN_DATE &&
				differenceInDays(new Date(), new Date(trait.value)) > 28,
		)
	) {
		return {
			isEligible: false,
			reason: 'Experiment dropdown date trait is older than 28 days',
		};
	}

	if (
		differenceInDays(new Date(), firstActivationDateMs) > 28 &&
		!siteTraitsData?.some(
			({ name, value }) => name === BRAIN_JSW_TO_JWM_IMPLICIT_SITE_BOOLEAN && value,
		)
	)
		return {
			isEligible: false,
			reason: 'User activation date is greater than 28 days and JSW to JWM ML trait is not set',
		};

	return { isEligible: true, reason: 'Pass all checks' };
};

export const jwmSampleProjectRecommendation = {
	key: CREATE_JWM_SAMPLE_PROJECT_KEY,
	shouldRender: (
		recommendationContext: RecommendationContext,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { isEligible, reason } = checkEligibility(recommendationContext);

		fireOperationalAnalytics(
			analyticsEvent,
			'jiraAtlassianNavigation.loadJwmSampleProjectRecommendation succeeded',
			{ isEligible, reason },
		);

		return isEligible;
	},
	Component: CreateJwmSampleProjectRecommendation,
};

const jwmSampleProjectUrlWithOriginSource = (taskId: string, originSource: OriginSource) =>
	`/jira/core/sample/onboarding/${taskId}?originSource=moon-${originSource}`;

const createJwmSampleProject = async (showFlag: ShowFlagFn) => {
	try {
		const response = await performPostRequest(CREATE_JWM_SAMPLE_PROJECT_ENDPOINT);
		if (response?.taskId) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(
				jwmSampleProjectUrlWithOriginSource(response.taskId, PROJECTS_MENU_EXISTING_USERS),
			);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'createJwmSampleProjectService',
				packageName: PACKAGE_NAME,
				teamName: 'navigation',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		showFlag({
			key: toFlagId(CREATE_JWM_SAMPLE_PROJECT_ERROR_FLAG_ID),
			type: 'error',
			title: messages.errorFlagTitle,
			description: messages.errorFlagText,
			messageId:
				'navigation-apps-sidebar-nav4-sidebars-content-growth-recommendations.ui.jwm-sample-project-recommendation.show-flag.error.success',
			messageType: 'transactional',
		});

		return error;
	}
};
