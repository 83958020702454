/* eslint-disable jira/import-whitelist */
import type { graphqlContextPanelQuery$data as AIContextPanelGraphQLResponse } from '@atlassian/jira-relay/src/__generated__/graphqlContextPanelQuery.graphql.ts';
import { FetchError } from '@atlassian/jira-early-script-utils/src/types.tsx';
import type { AIContextServicePanelResourceResponse } from '../../types.tsx';
import { parseAdf, transformContent } from '../../utils.tsx';

export const transformGraphQlResponse = (
	graphQLResponse: AIContextPanelGraphQLResponse | undefined,
	traceIds?: string[],
): AIContextServicePanelResourceResponse => {
	const data =
		graphQLResponse?.agentAI_contextPanel?.__typename === 'AgentAIContextPanelResponse'
			? graphQLResponse?.agentAI_contextPanel
			: null;
	return {
		type: 'ServicePanelSuggestions',
		traceIds,
		summary: parseAdf(data?.summary),
		reporterDetails: parseAdf(data?.reporterDetails),
		nextSteps: parseAdf(data?.nextSteps),
		suggestedEscalation: parseAdf(data?.suggestedEscalation),
		suggestedActions: data?.suggestedActions
			? // eslint-disable-next-line @typescript-eslint/no-explicit-any
				data?.suggestedActions?.map((action: any) => ({
					type: action.type,
					context: {
						...action.context,
						suggestion: action.context?.suggestion,
					},
					content: transformContent(action?.content),
				}))
			: [],
	};
};

export const handleGraphQLresponse = (
	graphQLResponse: AIContextPanelGraphQLResponse | undefined,
	traceIds?: string[],
): AIContextServicePanelResourceResponse => {
	const responseType = graphQLResponse?.agentAI_contextPanel?.__typename;
	if (responseType === 'AgentAIContextPanelResponse') {
		return transformGraphQlResponse(graphQLResponse, traceIds);
	}
	if (responseType === 'QueryError') {
		const errorMessage = graphQLResponse?.agentAI_contextPanel?.message ?? 'Unknown error';
		const errorStatus = graphQLResponse?.agentAI_contextPanel?.extensions?.[0]?.statusCode ?? 500;
		throw new FetchError(
			errorStatus,
			`Error processing graphQL query: ${errorMessage}`,
			traceIds?.[0],
		);
	}
	throw new FetchError(500, 'Failed to fetch data from AGG', traceIds?.[0]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleGraphQLError = (error: any, traceIds?: string[]) => {
	throw new FetchError(500, `Failed to fetch data from AGG : ${error?.message}`, traceIds?.[0]);
};
