import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncPersonalSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-personal-settings/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { digestsNotificationsPersonalSettingsRoute } from '@atlassian/jira-router-routes-personal-settings-routes/src/digestsNotificationsPersonalSettingsRoute.tsx';
import type DigestsNotificationsPersonalSettingsType from '@atlassian/jira-spa-apps-personal-settings-digests-notifications/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyDigestsNotificationsPersonalSettings = lazyForPaint<
	typeof DigestsNotificationsPersonalSettingsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-personal-settings-digests", jiraSpaEntry: "async-personal-settings-digests" */ '@atlassian/jira-spa-apps-personal-settings-digests-notifications'
		),
	),
);

const DigestsNotificationsPersonalSettingsComponent = () => (
	<LazyPage
		Page={LazyDigestsNotificationsPersonalSettings}
		pageId="personal-settings-notifications"
		shouldShowSpinner
	/>
);

export const digestsNotificationsPersonalSettingsRouteEntry = createEntry(
	digestsNotificationsPersonalSettingsRoute,
	() => ({
		group: 'personal-settings',
		component: DigestsNotificationsPersonalSettingsComponent,
		navigation: {
			sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
			menuId: MENU_ID.PROFILE,
			sidebar: AsyncPersonalSettingsSidebar,
		},
		resources: [...getNavigationResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyDigestsNotificationsPersonalSettings],
	}),
);
