import {
	forgeProjectModuleResource,
	getNavigationSidebarGlobalSettingsResources,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { connectGeneralResources } from '@atlassian/jira-router-resources-connect-general/src/index.tsx';
import { connectProjectResources } from '@atlassian/jira-router-resources-connect-project/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectSettingsResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';

export const getGeneralConnectRouteResources = () => [
	...getBusinessProjectResources(),
	...getNavigationResources(),
	navigationSidebarGlobalResource,
	...getNavigationSidebarGlobalSettingsResources(),
	// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
	incidentExperimentTraitResource,
	projectContextResource,
	forgeProjectModuleResource,
	staticSidebarShortcutsResource,
	staticProjectSidebarNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
	// end of resources expanded from getNavigationSidebarProjectResource
	...getNavigationSidebarProjectSettingsResource(),
	connectGeneralResources,
	getConsolidationStateResource(),
];

export const getProjectConnectRouteDefaultResources = () => [
	...getBusinessProjectResources(),
	...getNavigationResources(),
	// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
	incidentExperimentTraitResource,
	projectContextResource,
	forgeProjectModuleResource,
	staticSidebarShortcutsResource,
	staticProjectSidebarNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
	// end of resources expanded from getNavigationSidebarProjectResource
	...getNavigationSidebarProjectSettingsResource(),
	connectProjectResources,
];
