import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	JIRA_EDITION_AWARENESS_FOR_STANDARD_TO_PREMIUM_PACKAGE_NAME,
	JIRA_EDITION_AWARENESS_FOR_STANDARD_TO_PREMIUM_PILL_DISMISSED,
} from './types.tsx';

const ProductKeyToHAMSMap: Record<string, string> = {
	jira: 'jira-software',
	'jira-product-discovery': 'jira-product-discovery',
	'jira-service-management': 'jira-servicedesk',
};

/**
 * When querying AGG for entitlements we need to pass through the `hamsProductKey` which is
 * a specific key used to identify the product, we're just mapping our product keys to those
 * hams keys for queries
 */
export const getHamsKey = (product: string): string => {
	return ProductKeyToHAMSMap[product];
};

export const setEditionAwarenessPillDismissed = async (value: boolean): Promise<void> => {
	try {
		await userPreferenceAPI.set(
			JIRA_EDITION_AWARENESS_FOR_STANDARD_TO_PREMIUM_PILL_DISMISSED,
			value,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		fireErrorAnalytics({
			error: err,
			meta: {
				id: 'setEditionAwarenessPillDismissed',
				packageName: JIRA_EDITION_AWARENESS_FOR_STANDARD_TO_PREMIUM_PACKAGE_NAME,
			},
		});
	}
};

export const fetchEditionAwarenessPillDismissed = async (): Promise<boolean | null> => {
	try {
		return await userPreferenceAPI.get(
			JIRA_EDITION_AWARENESS_FOR_STANDARD_TO_PREMIUM_PILL_DISMISSED,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		if (err.statusCode !== 404) {
			// 404 is acceptable as it means user hasn't dismissed it yet
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'fetchEditionAwarenessPillDismissed',
					packageName: JIRA_EDITION_AWARENESS_FOR_STANDARD_TO_PREMIUM_PACKAGE_NAME,
				},
			});
		}
		return null;
	}
};
