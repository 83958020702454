import { fg } from '@atlassian/jira-feature-gating';
import {
	getIsAdmin,
	getIsNav4TenantOptIn,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';
import { getIsInPreGaStage } from '../get-is-in-pre-ga-stage.tsx';
import { getWillShowNav4UserOptOutForDogfooding } from './get-will-show-nav4-user-opt-out-for-dogfooding/index.tsx';
import { getWillShowNav4UserOptOutForVisualRefresh } from './get-will-show-nav4-user-opt-out-for-visual-refresh/index.tsx';

// We don't inline feature gates as our logic to show nav4 is not trivial and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design#Decision-points
// All changes to this function should be replicated in the backend (see readme.md).
export const getWillShowNav4UserOptOut = () => {
	// Pre-GA logic.
	if (
		// eslint-disable-next-line jira/ff/no-preconditioning
		(fg('jira_nav4') &&
			(getWillShowNav4UserOptOutForDogfooding() ||
				fg('jira_nav4_eap') ||
				fg('jira_nav4_beta_existing-customers'))) ||
		// User opt out is being shared with visual refresh. It should show if visual refresh is on regardless of nav4.
		(getWillShowNav4UserOptOutForVisualRefresh() && !fg('nav4_visual_refresh_merger'))
	) {
		return true;
	}

	if (!fg('jira_nav4')) {
		return false;
	}

	// Previous stages were mutually exclusive. GA is not.
	// Therefore we need to make sure the customer is not in another stage before showing user opt out.
	if (getIsInPreGaStage()) {
		return false;
	}

	// Later GA phases eclipse earlier phases.
	if (fg('jira_nav4_ga_phase-3')) {
		return false;
	}

	if (fg('jira_nav4_ga_phase-2')) {
		return true;
	}

	if (fg('jira_nav4_ga_phase-1')) {
		return !getIsAdmin() && getIsNav4TenantOptIn();
	}

	return false;
};
