type EmceeQueryParams = {
	source?: string;
	s?: string;
	installDialogOpen?: boolean;
	requestDialogOpen?: boolean;
};

type JiraQueryParams = {
	['project.key']?: string;
	['project.id']?: string;
};

const sanitiseParams = (params?: EmceeQueryParams | JiraQueryParams) =>
	params
		? // eslint-disable-next-line @typescript-eslint/no-explicit-any
			Object.entries(params).reduce<Record<string, any>>((res, [key, value]: [any, any]) => {
				if (value) {
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'EmceeQueryParams | JiraQueryParams'.
					res[key] = params[key];
				}
				return res;
			}, {})
		: {};

const addQueryParamsToLink = (
	params: EmceeQueryParams | JiraQueryParams | null | undefined,
	link: string,
) => {
	// @ts-expect-error - TS2345 - Argument of type 'EmceeQueryParams | JiraQueryParams | null | undefined' is not assignable to parameter of type 'EmceeQueryParams | JiraQueryParams | undefined'.
	const queryParams = sanitiseParams(params);
	const queryString = new URLSearchParams(queryParams).toString();
	return queryString ? `${link}?${queryString}` : link;
};

export const getEmceeDiscoverLink = (params: EmceeQueryParams | null | undefined) => {
	const link = '/jira/marketplace/discover';
	return addQueryParamsToLink(params, link);
};

export const getEmceeAppListingLink = (
	appKey: string,
	params: EmceeQueryParams | null | undefined,
	jiraQueryParams: JiraQueryParams | null | undefined,
) =>
	addQueryParamsToLink(
		{
			...jiraQueryParams,
			...params,
		},
		`/jira/marketplace/discover/app/${appKey}`,
	);

export const getAppRequestsLink = (params?: EmceeQueryParams) => {
	const link = '/jira/marketplace/manage/app-requests';
	return addQueryParamsToLink(params, link);
};

export const getManagePromotionsLink = (params?: EmceeQueryParams) => {
	const link = '/jira/marketplace/manage/promotions';
	return addQueryParamsToLink(params, link);
};
