/**
 * @generated SignedSource<<0bdb22daaee472bfd28adbbd101ff4ab>>
 * @relayHash 2d96d1b7dc1ae51001775a78f827ae46
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e418b064f8fea2498cdcd449510e8ac04a0e7832adf4834103291179a5fba118

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraAtlassianIntelligenceFeatureEnum = "AI_MATE" | "NATURAL_LANGUAGE_TO_JQL" | "%future added value";
export type JiraIssueSearchOperationScope = "NIN_GLOBAL" | "NIN_GLOBAL_SCHEMA_REFACTOR" | "NIN_GLOBAL_SHADOW_REQUEST" | "NIN_PROJECT" | "NIN_PROJECT_SCHEMA_REFACTOR" | "NIN_PROJECT_SHADOW_REQUEST" | "%future added value";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  namespace?: string | null | undefined;
  parentIssueKey: string;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHideDoneEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchScope = {
  operationScope?: JiraIssueSearchOperationScope | null | undefined;
};
export type main_IssueNavigatorQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns: number;
  atlassianIntelligenceProductFeature: JiraAtlassianIntelligenceFeatureEnum;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsContext?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  groupBy?: string | null | undefined;
  isPaginating?: boolean | null | undefined;
  issueSearchInput: JiraIssueSearchInput;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  projectKey: string;
  scope?: JiraIssueSearchScope | null | undefined;
  shouldQueryFieldSetsById: boolean;
  shouldQueryHasChildren?: boolean | null | undefined;
  shouldQueryProject: boolean;
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
  viewId?: string | null | undefined;
};
export type main_IssueNavigatorQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey?: {
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_project">;
    } | null | undefined;
    readonly userPreferences: {
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_userPreferences">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_jira">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueQuery">;
};
export type main_IssueNavigatorQuery = {
  response: main_IssueNavigatorQuery$data;
  variables: main_IssueNavigatorQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider": isReparentingEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import bulkOpsInNinEnabled_provider from '@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isRefactorNinToViewSchemaEnabled_provider from '@atlassian/jira-relay-provider/src/is-refactor-nin-to-view-schema-enabled.relayprovider';
import isReparentingEnabled_provider from '@atlassian/jira-relay-provider/src/jira-list-reparenting.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "atlassianIntelligenceProductFeature"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsContext"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupBy"
},
v11 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isPaginating"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v19 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "shouldQueryHasChildren"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryProject"
},
v21 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "staticViewInput"
},
v22 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewConfigInput"
},
v23 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v24 = {
  "kind": "Variable",
  "name": "projectKey",
  "variableName": "projectKey"
},
v25 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v26 = [
  (v25/*: any*/)
],
v27 = [
  (v25/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v28 = {
  "kind": "ScalarField",
  "name": "__id"
},
v29 = {
  "kind": "ClientExtension",
  "selections": [
    (v28/*: any*/)
  ]
},
v30 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "feature",
        "variableName": "atlassianIntelligenceProductFeature"
      }
    ],
    "kind": "ObjectValue",
    "name": "atlassianIntelligenceProductFeatureInput"
  },
  (v25/*: any*/)
],
v31 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v32 = {
  "kind": "ScalarField",
  "name": "key"
},
v33 = {
  "kind": "ScalarField",
  "name": "id"
},
v34 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v35 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v36 = {
  "kind": "Variable",
  "name": "fieldSetsInput",
  "variableName": "fieldSetsInput"
},
v37 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v38 = {
  "kind": "Variable",
  "name": "issueSearchInput",
  "variableName": "issueSearchInput"
},
v39 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v40 = {
  "kind": "Variable",
  "name": "options",
  "variableName": "options"
},
v41 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v42 = {
  "kind": "ScalarField",
  "name": "isResolved"
},
v43 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v44 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v45 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v44/*: any*/),
    (v33/*: any*/)
  ]
},
v46 = {
  "kind": "InlineFragment",
  "selections": [
    (v43/*: any*/),
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v45/*: any*/),
        (v33/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v47 = {
  "args": [
    {
      "kind": "Literal",
      "name": "ids",
      "value": [
        "status"
      ]
    }
  ],
  "concreteType": "JiraIssueFieldConnection",
  "kind": "LinkedField",
  "name": "fieldsById",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueFieldEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v31/*: any*/),
            (v46/*: any*/),
            (v33/*: any*/)
          ]
        }
      ]
    }
  ],
  "storageKey": "fieldsById(ids:[\"status\"])"
},
v48 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v49 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v48/*: any*/),
        (v33/*: any*/)
      ]
    },
    (v33/*: any*/)
  ]
},
v50 = {
  "kind": "ClientExtension",
  "selections": [
    (v28/*: any*/),
    {
      "kind": "ScalarField",
      "name": "isHighlightedIssueRow"
    }
  ]
},
v51 = {
  "kind": "Variable",
  "name": "filterId",
  "variableName": "filterId"
},
v52 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v53 = {
  "kind": "Variable",
  "name": "namespace",
  "variableName": "namespace"
},
v54 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v55 = [
  {
    "kind": "Variable",
    "name": "context",
    "variableName": "fieldSetsContext"
  },
  (v51/*: any*/),
  (v52/*: any*/),
  (v53/*: any*/),
  (v54/*: any*/)
],
v56 = {
  "kind": "ScalarField",
  "name": "text"
},
v57 = {
  "kind": "ScalarField",
  "name": "name"
},
v58 = {
  "kind": "ScalarField",
  "name": "picture"
},
v59 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v60 = {
  "kind": "ScalarField",
  "name": "type"
},
v61 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v62 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v63 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v57/*: any*/),
                (v33/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v64 = {
  "kind": "ScalarField",
  "name": "value"
},
v65 = [
  (v64/*: any*/),
  (v33/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
],
v66 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v67 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "concreteType": "JiraCascadingOption",
      "kind": "LinkedField",
      "name": "cascadingOption",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "parentOptionValue",
          "plural": false,
          "selections": (v65/*: any*/)
        },
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "childOptionValue",
          "plural": false,
          "selections": (v65/*: any*/)
        }
      ]
    },
    (v66/*: any*/)
  ],
  "type": "JiraCascadingSelectField"
},
v68 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v69 = {
  "alias": "value",
  "kind": "ScalarField",
  "name": "id"
},
v70 = {
  "alias": "label",
  "kind": "ScalarField",
  "name": "name"
},
v71 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "args": (v68/*: any*/),
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v69/*: any*/),
                (v70/*: any*/),
                (v33/*: any*/)
              ]
            }
          ]
        }
      ],
      "storageKey": "selectedComponentsConnection(first:1000)"
    },
    (v66/*: any*/)
  ],
  "type": "JiraComponentsField"
},
v72 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v73 = {
  "kind": "InlineFragment",
  "selections": [
    (v72/*: any*/),
    (v57/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraDateTimePickerField"
},
v74 = {
  "kind": "InlineFragment",
  "selections": [
    (v56/*: any*/),
    (v57/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v75 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v76 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCommentSummary",
      "kind": "LinkedField",
      "name": "commentSummary",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canAddComment"
        },
        (v75/*: any*/)
      ]
    }
  ],
  "type": "JiraCommentSummaryField"
},
v77 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "kind": "ScalarField",
      "name": "uri"
    },
    (v66/*: any*/)
  ],
  "type": "JiraUrlField"
},
v78 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v33/*: any*/)
  ]
},
v79 = [
  (v78/*: any*/)
],
v80 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v81 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "fieldOptions",
      "plural": false,
      "selections": [
        (v75/*: any*/)
      ]
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "fieldOption",
      "plural": false,
      "selections": [
        (v64/*: any*/),
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v79/*: any*/)
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v79/*: any*/)
        },
        (v33/*: any*/),
        (v80/*: any*/)
      ]
    },
    (v66/*: any*/)
  ],
  "type": "JiraSingleSelectField"
},
v82 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v33/*: any*/),
                (v64/*: any*/),
                (v80/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    (v66/*: any*/)
  ],
  "type": "JiraMultipleSelectField"
},
v83 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v33/*: any*/),
                (v64/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    (v66/*: any*/)
  ],
  "type": "JiraCheckboxesField"
},
v84 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v85 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v86 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v31/*: any*/),
                (v84/*: any*/),
                (v57/*: any*/),
                (v58/*: any*/),
                (v33/*: any*/),
                (v85/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "email"
                    }
                  ],
                  "type": "AtlassianAccountUser"
                }
              ]
            }
          ]
        }
      ]
    },
    (v66/*: any*/)
  ],
  "type": "JiraMultipleSelectUserPickerField"
},
v87 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "concreteType": "JiraTeamView",
      "kind": "LinkedField",
      "name": "selectedTeam",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedId"
        },
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedName"
        }
      ]
    },
    (v66/*: any*/)
  ],
  "type": "JiraTeamViewField"
},
v88 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraTimeTrackingField",
          "kind": "LinkedField",
          "name": "timeTrackingField",
          "plural": false,
          "selections": [
            (v33/*: any*/),
            {
              "concreteType": "JiraEstimate",
              "kind": "LinkedField",
              "name": "originalEstimate",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "timeInSeconds"
                }
              ]
            },
            {
              "concreteType": "JiraTimeTrackingSettings",
              "kind": "LinkedField",
              "name": "timeTrackingSettings",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "workingHoursPerDay"
                },
                {
                  "kind": "ScalarField",
                  "name": "workingDaysPerWeek"
                },
                {
                  "kind": "ScalarField",
                  "name": "defaultFormat"
                },
                {
                  "kind": "ScalarField",
                  "name": "defaultUnit"
                }
              ]
            },
            (v43/*: any*/),
            (v57/*: any*/),
            (v60/*: any*/),
            (v66/*: any*/)
          ]
        },
        (v33/*: any*/)
      ]
    }
  ],
  "type": "JiraOriginalTimeEstimateField"
},
v89 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "selectedOption",
      "plural": false,
      "selections": [
        {
          "alias": "label",
          "kind": "ScalarField",
          "name": "value"
        },
        (v33/*: any*/),
        (v69/*: any*/)
      ]
    },
    (v66/*: any*/)
  ],
  "type": "JiraRadioSelectField"
},
v90 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "args": (v68/*: any*/),
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v33/*: any*/),
                (v57/*: any*/),
                (v69/*: any*/),
                (v70/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "versionId"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "selectedVersionsConnection(first:1000)"
    },
    (v66/*: any*/)
  ],
  "type": "JiraMultipleVersionPickerField"
},
v91 = {
  "kind": "ScalarField",
  "name": "message"
},
v92 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraCmdbMediaClientConfig",
                      "kind": "LinkedField",
                      "name": "mediaClientConfig",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "clientId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "fileId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "mediaBaseUrl"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "mediaJwtToken"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v91/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v93 = [
  (v57/*: any*/),
  (v33/*: any*/)
],
v94 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "componentsOld",
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v93/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v95 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v96 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    },
    (v57/*: any*/),
    (v95/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v97 = {
  "kind": "InlineFragment",
  "selections": [
    (v72/*: any*/)
  ],
  "type": "JiraDateTimePickerField"
},
v98 = {
  "kind": "ScalarField",
  "name": "summary"
},
v99 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v32/*: any*/),
        (v98/*: any*/),
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v33/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v100 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v101 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v57/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v45/*: any*/),
        (v33/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v102 = [
  (v57/*: any*/)
],
v103 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": (v68/*: any*/),
      "concreteType": "JiraLabelConnection",
      "kind": "LinkedField",
      "name": "selectedLabelsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraLabelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraLabel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v102/*: any*/)
            }
          ]
        },
        (v75/*: any*/)
      ],
      "storageKey": "selectedLabelsConnection(first:1000)"
    },
    (v57/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v104 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    (v33/*: any*/)
  ]
},
v105 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v78/*: any*/),
    (v33/*: any*/)
  ]
},
v106 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v107 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v106/*: any*/),
        (v57/*: any*/),
        (v33/*: any*/)
      ]
    },
    (v33/*: any*/)
  ]
},
v108 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v109 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v32/*: any*/),
        (v104/*: any*/),
        (v33/*: any*/)
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v32/*: any*/),
            (v105/*: any*/),
            (v107/*: any*/),
            (v104/*: any*/),
            (v33/*: any*/)
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v32/*: any*/),
            (v105/*: any*/),
            (v107/*: any*/),
            (v104/*: any*/),
            (v33/*: any*/),
            (v41/*: any*/),
            (v108/*: any*/),
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "ids",
                  "value": [
                    "summary",
                    "issuetype",
                    "status"
                  ]
                }
              ],
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fieldsById",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v31/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v43/*: any*/),
                            (v56/*: any*/)
                          ],
                          "type": "JiraSingleLineTextField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v43/*: any*/),
                            {
                              "concreteType": "JiraIssueType",
                              "kind": "LinkedField",
                              "name": "issueType",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "issueTypeId"
                                },
                                (v57/*: any*/),
                                (v106/*: any*/),
                                (v48/*: any*/),
                                (v33/*: any*/)
                              ]
                            }
                          ],
                          "type": "JiraIssueTypeField"
                        },
                        (v46/*: any*/),
                        (v33/*: any*/)
                      ]
                    }
                  ]
                }
              ],
              "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
            }
          ]
        },
        (v57/*: any*/),
        (v66/*: any*/)
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v110 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v111 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        (v57/*: any*/),
        (v110/*: any*/),
        (v33/*: any*/)
      ]
    },
    (v57/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v112 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v57/*: any*/),
        (v32/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v33/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v113 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v93/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v114 = {
  "kind": "ScalarField",
  "name": "sprintId"
},
v115 = {
  "kind": "ScalarField",
  "name": "state"
},
v116 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraSprintConnection",
      "kind": "LinkedField",
      "name": "selectedSprintsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSprintEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v33/*: any*/),
                (v114/*: any*/),
                (v57/*: any*/),
                (v115/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "endDate"
                }
              ]
            }
          ]
        }
      ]
    },
    (v57/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraSprintField"
},
v117 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    {
      "args": (v68/*: any*/),
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v93/*: any*/)
            }
          ]
        }
      ],
      "storageKey": "selectedVersionsConnection(first:1000)"
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v118 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v119 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v57/*: any*/),
        (v106/*: any*/),
        (v33/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v120 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        (v75/*: any*/),
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    (v108/*: any*/),
                    (v32/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v31/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v45/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v33/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v33/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v33/*: any*/)
              ]
            }
          ]
        }
      ],
      "storageKey": "issueLinkConnection(first:1)"
    }
  ],
  "type": "JiraIssueLinkField"
},
v121 = {
  "kind": "ScalarField",
  "name": "number"
},
v122 = [
  (v121/*: any*/)
],
v123 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isStoryPointField"
        },
        (v121/*: any*/),
        (v57/*: any*/),
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": (v122/*: any*/)
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            (v121/*: any*/),
            (v57/*: any*/),
            (v66/*: any*/)
          ]
        },
        (v66/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v124 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v60/*: any*/),
          (v61/*: any*/),
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v31/*: any*/),
                      (v43/*: any*/),
                      (v60/*: any*/),
                      (v62/*: any*/),
                      (v33/*: any*/),
                      (v63/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v67/*: any*/),
                          (v71/*: any*/),
                          (v73/*: any*/),
                          (v74/*: any*/),
                          (v76/*: any*/),
                          (v77/*: any*/),
                          (v81/*: any*/),
                          (v82/*: any*/),
                          (v83/*: any*/),
                          (v86/*: any*/),
                          (v87/*: any*/),
                          (v88/*: any*/),
                          (v89/*: any*/),
                          (v90/*: any*/)
                        ]
                      },
                      (v92/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          (v94/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v56/*: any*/)
                            ],
                            "type": "JiraSingleLineTextField"
                          }
                        ]
                      },
                      (v96/*: any*/),
                      (v97/*: any*/),
                      (v99/*: any*/),
                      (v100/*: any*/),
                      (v74/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v57/*: any*/),
                              (v44/*: any*/),
                              (v33/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      (v101/*: any*/),
                      (v103/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v59/*: any*/),
                              (v57/*: any*/),
                              (v33/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      (v109/*: any*/),
                      (v111/*: any*/),
                      (v112/*: any*/),
                      (v113/*: any*/),
                      (v116/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v31/*: any*/),
                              (v84/*: any*/),
                              (v58/*: any*/),
                              (v57/*: any*/),
                              (v33/*: any*/),
                              (v85/*: any*/)
                            ]
                          },
                          (v57/*: any*/),
                          (v95/*: any*/),
                          (v66/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      (v117/*: any*/),
                      (v118/*: any*/),
                      (v119/*: any*/),
                      (v120/*: any*/),
                      (v123/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v125 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fieldSetIds",
          "variableName": "fieldSetIds"
        },
        (v52/*: any*/)
      ],
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsById",
      "plural": false,
      "selections": (v124/*: any*/)
    }
  ]
},
v126 = {
  "kind": "InlineFragment",
  "selections": [
    (v60/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v127 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": (v55/*: any*/),
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsForIssueSearchView",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v31/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v43/*: any*/),
                                (v56/*: any*/),
                                (v57/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v43/*: any*/),
                                (v57/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v31/*: any*/),
                                    (v57/*: any*/),
                                    (v58/*: any*/),
                                    (v33/*: any*/)
                                  ]
                                }
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v43/*: any*/),
                                {
                                  "concreteType": "JiraIssueType",
                                  "kind": "LinkedField",
                                  "name": "issueType",
                                  "plural": false,
                                  "selections": [
                                    (v57/*: any*/),
                                    (v59/*: any*/),
                                    (v33/*: any*/)
                                  ]
                                }
                              ],
                              "type": "JiraIssueTypeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v43/*: any*/),
                                (v45/*: any*/)
                              ],
                              "type": "JiraStatusCategoryField"
                            },
                            (v33/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    (v125/*: any*/),
    {
      "condition": "shouldQueryFieldSetsById",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": (v55/*: any*/),
          "concreteType": "JiraIssueFieldSetConnection",
          "kind": "LinkedField",
          "name": "fieldSetsForIssueSearchView",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSetEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldSet",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v60/*: any*/),
                    (v61/*: any*/),
                    {
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fields",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v43/*: any*/),
                                (v60/*: any*/),
                                (v62/*: any*/),
                                (v63/*: any*/),
                                {
                                  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                  "kind": "Condition",
                                  "passingValue": true,
                                  "selections": [
                                    (v67/*: any*/),
                                    (v71/*: any*/),
                                    (v73/*: any*/),
                                    (v126/*: any*/),
                                    (v76/*: any*/),
                                    (v77/*: any*/),
                                    (v81/*: any*/),
                                    (v82/*: any*/),
                                    (v83/*: any*/),
                                    (v86/*: any*/),
                                    (v87/*: any*/),
                                    (v88/*: any*/),
                                    (v89/*: any*/),
                                    (v90/*: any*/)
                                  ]
                                },
                                (v92/*: any*/),
                                {
                                  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                  "kind": "Condition",
                                  "passingValue": false,
                                  "selections": [
                                    (v94/*: any*/)
                                  ]
                                },
                                (v96/*: any*/),
                                (v97/*: any*/),
                                (v99/*: any*/),
                                (v100/*: any*/),
                                (v126/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "concreteType": "JiraStatusCategory",
                                      "kind": "LinkedField",
                                      "name": "statusCategory",
                                      "plural": false,
                                      "selections": (v102/*: any*/)
                                    }
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v101/*: any*/),
                                (v103/*: any*/),
                                (v109/*: any*/),
                                (v111/*: any*/),
                                (v112/*: any*/),
                                (v113/*: any*/),
                                (v116/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v60/*: any*/),
                                    {
                                      "kind": "LinkedField",
                                      "name": "user",
                                      "plural": false,
                                      "selections": [
                                        (v84/*: any*/),
                                        (v85/*: any*/)
                                      ]
                                    },
                                    (v95/*: any*/),
                                    (v66/*: any*/)
                                  ],
                                  "type": "JiraSingleSelectUserPickerField"
                                },
                                (v117/*: any*/),
                                (v118/*: any*/),
                                (v119/*: any*/),
                                (v120/*: any*/),
                                (v123/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v128 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v98/*: any*/),
    {
      "concreteType": "JiraIssueTypeField",
      "kind": "LinkedField",
      "name": "issueTypeField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueType",
          "kind": "LinkedField",
          "name": "issueType",
          "plural": false,
          "selections": [
            (v59/*: any*/)
          ]
        }
      ]
    }
  ]
},
v129 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v130 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": (v129/*: any*/),
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSets",
      "plural": false,
      "selections": (v124/*: any*/),
      "storageKey": "fieldSets(first:500)"
    }
  ]
},
v131 = {
  "kind": "ScalarField",
  "name": "totalIssueSearchResultCount"
},
v132 = [
  {
    "kind": "Literal",
    "name": "maxCursors",
    "value": 7
  }
],
v133 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v134 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v135 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v136 = [
  (v135/*: any*/),
  (v133/*: any*/),
  (v134/*: any*/)
],
v137 = {
  "concreteType": "JiraPageCursor",
  "kind": "LinkedField",
  "name": "last",
  "plural": false,
  "selections": (v136/*: any*/)
},
v138 = {
  "concreteType": "JiraPageCursor",
  "kind": "LinkedField",
  "name": "first",
  "plural": false,
  "selections": [
    (v133/*: any*/),
    (v135/*: any*/),
    (v134/*: any*/)
  ]
},
v139 = {
  "kind": "ScalarField",
  "name": "firstIssuePosition"
},
v140 = {
  "kind": "ScalarField",
  "name": "lastIssuePosition"
},
v141 = {
  "kind": "LinkedField",
  "name": "issueSearchError",
  "plural": false,
  "selections": [
    (v31/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "messages"
        }
      ],
      "type": "JiraInvalidJqlError"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v91/*: any*/),
        {
          "kind": "ScalarField",
          "name": "errorType"
        }
      ],
      "type": "JiraInvalidSyntaxError"
    }
  ]
},
v142 = {
  "kind": "ScalarField",
  "name": "jql"
},
v143 = {
  "kind": "ScalarField",
  "name": "isCappingIssueSearchResult"
},
v144 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "args": (v129/*: any*/),
          "concreteType": "JiraIssueFieldSetConnection",
          "kind": "LinkedField",
          "name": "fieldSets",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSetEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldSet",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fields",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v43/*: any*/),
                                (v31/*: any*/),
                                (v60/*: any*/),
                                (v33/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          "storageKey": "fieldSets(first:500)"
        }
      ]
    }
  ]
},
v145 = [
  (v25/*: any*/),
  (v51/*: any*/),
  (v38/*: any*/),
  (v53/*: any*/),
  (v54/*: any*/)
],
v146 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueSearchViewResult"
},
v147 = {
  "kind": "ScalarField",
  "name": "viewId"
},
v148 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "fieldSetSelectedState": "SELECTED"
    }
  },
  (v52/*: any*/)
],
v149 = {
  "args": (v148/*: any*/),
  "concreteType": "JiraIssueSearchFieldSetConnection",
  "kind": "LinkedField",
  "name": "fieldSets",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueSearchFieldSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueSearchFieldSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v61/*: any*/)
          ]
        }
      ]
    }
  ]
},
v150 = {
  "kind": "ScalarField",
  "name": "hasDefaultFieldSets"
},
v151 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v152 = {
  "kind": "ScalarField",
  "name": "jqlTerm"
},
v153 = {
  "kind": "ScalarField",
  "name": "isSortable"
},
v154 = {
  "concreteType": "JiraFieldSetPreferences",
  "kind": "LinkedField",
  "name": "fieldSetPreferences",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "width"
    }
  ]
},
v155 = {
  "concreteType": "JiraFieldType",
  "kind": "LinkedField",
  "name": "fieldType",
  "plural": false,
  "selections": [
    (v151/*: any*/)
  ]
},
v156 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isPlaceHolderField"
    }
  ]
},
v157 = {
  "args": (v148/*: any*/),
  "concreteType": "JiraIssueSearchFieldSetConnection",
  "kind": "LinkedField",
  "name": "fieldSets",
  "plural": false,
  "selections": [
    (v75/*: any*/),
    {
      "concreteType": "JiraIssueSearchFieldSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueSearchFieldSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v60/*: any*/),
            (v151/*: any*/),
            (v152/*: any*/),
            (v153/*: any*/),
            (v154/*: any*/),
            (v155/*: any*/),
            (v156/*: any*/)
          ]
        }
      ]
    },
    (v29/*: any*/)
  ]
},
v158 = {
  "kind": "Variable",
  "name": "staticViewInput",
  "variableName": "staticViewInput"
},
v159 = [
  (v158/*: any*/)
],
v160 = {
  "kind": "ScalarField",
  "name": "hideDone"
},
v161 = {
  "args": (v159/*: any*/),
  "concreteType": "JiraIssueSearchViewConfigSettings",
  "kind": "LinkedField",
  "name": "viewConfigSettings",
  "plural": false,
  "selections": [
    (v160/*: any*/)
  ]
},
v162 = {
  "kind": "ScalarField",
  "name": "isHierarchyEnabled"
},
v163 = {
  "kind": "ScalarField",
  "name": "canEnableHierarchy"
},
v164 = {
  "kind": "Variable",
  "name": "groupBy",
  "variableName": "groupBy"
},
v165 = [
  (v164/*: any*/),
  (v38/*: any*/),
  (v158/*: any*/)
],
v166 = {
  "concreteType": "JiraSpreadsheetGroupByConfig",
  "kind": "LinkedField",
  "name": "groupByConfig",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraField",
      "kind": "LinkedField",
      "name": "groupByField",
      "plural": false,
      "selections": [
        (v43/*: any*/),
        (v33/*: any*/)
      ]
    }
  ]
},
v167 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": (v165/*: any*/),
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewSettings",
      "plural": false,
      "selections": [
        (v163/*: any*/),
        (v166/*: any*/),
        (v162/*: any*/),
        (v160/*: any*/)
      ]
    }
  ],
  "type": "JiraSpreadsheetView",
  "abstractKey": "__isJiraSpreadsheetView"
},
v168 = {
  "kind": "ScalarField",
  "name": "isGroupingEnabled"
},
v169 = {
  "kind": "ScalarField",
  "name": "canEnableGrouping"
},
v170 = [
  (v162/*: any*/),
  (v163/*: any*/),
  (v168/*: any*/),
  (v169/*: any*/)
],
v171 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": (v159/*: any*/),
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewConfigSettings",
      "plural": false,
      "selections": (v170/*: any*/)
    }
  ],
  "type": "JiraIssueSearchView"
},
v172 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v31/*: any*/),
    {
      "kind": "ScalarField",
      "name": "statusCode"
    }
  ]
},
v173 = {
  "kind": "InlineFragment",
  "selections": [
    (v91/*: any*/),
    (v172/*: any*/)
  ],
  "type": "QueryError"
},
v174 = [
  (v33/*: any*/)
],
v175 = {
  "kind": "InlineFragment",
  "selections": (v174/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v176 = {
  "kind": "Variable",
  "name": "scope",
  "variableName": "scope"
},
v177 = {
  "kind": "Variable",
  "name": "viewConfigInput",
  "variableName": "viewConfigInput"
},
v178 = [
  (v34/*: any*/),
  (v35/*: any*/),
  (v25/*: any*/),
  (v36/*: any*/),
  (v37/*: any*/),
  (v38/*: any*/),
  (v39/*: any*/),
  (v40/*: any*/),
  (v176/*: any*/),
  (v177/*: any*/)
],
v179 = {
  "concreteType": "JiraIssue",
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v31/*: any*/),
    (v33/*: any*/),
    (v32/*: any*/),
    (v41/*: any*/),
    (v42/*: any*/),
    (v47/*: any*/),
    (v49/*: any*/),
    (v50/*: any*/),
    (v127/*: any*/),
    (v128/*: any*/)
  ]
},
v180 = {
  "args": [
    {
      "items": [
        {
          "kind": "Variable",
          "name": "filterByProjectKeys.0",
          "variableName": "projectKey"
        }
      ],
      "kind": "ListValue",
      "name": "filterByProjectKeys"
    }
  ],
  "kind": "ScalarField",
  "name": "hasChildren"
},
v181 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v180/*: any*/)
  ]
},
v182 = {
  "kind": "ScalarField",
  "name": "endCursor"
},
v183 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v184 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v182/*: any*/),
    {
      "kind": "ScalarField",
      "name": "startCursor"
    },
    (v183/*: any*/),
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    }
  ]
},
v185 = {
  "concreteType": "JiraIssueNavigatorPageInfo",
  "kind": "LinkedField",
  "name": "issueNavigatorPageInfo",
  "plural": false,
  "selections": [
    (v139/*: any*/),
    (v140/*: any*/)
  ]
},
v186 = {
  "args": (v132/*: any*/),
  "concreteType": "JiraPageCursors",
  "kind": "LinkedField",
  "name": "pageCursors",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraPageCursor",
      "kind": "LinkedField",
      "name": "around",
      "plural": true,
      "selections": (v136/*: any*/)
    },
    (v137/*: any*/),
    (v138/*: any*/)
  ],
  "storageKey": "pageCursors(maxCursors:7)"
},
v187 = [
  {
    "concreteType": "JiraIssueEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v133/*: any*/),
      (v179/*: any*/),
      (v31/*: any*/),
      {
        "condition": "shouldQueryHasChildren",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          (v180/*: any*/),
          {
            "args": [
              (v24/*: any*/)
            ],
            "kind": "ScalarField",
            "name": "canHaveChildIssues"
          }
        ]
      },
      (v29/*: any*/),
      (v181/*: any*/),
      (v130/*: any*/)
    ]
  },
  (v184/*: any*/),
  (v185/*: any*/),
  (v186/*: any*/),
  (v131/*: any*/),
  (v141/*: any*/),
  (v142/*: any*/),
  (v143/*: any*/),
  (v29/*: any*/),
  (v144/*: any*/)
],
v188 = [
  "issueSearchInput"
],
v189 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": (v165/*: any*/),
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewSettings",
      "plural": false,
      "selections": (v170/*: any*/)
    }
  ],
  "type": "JiraSpreadsheetView",
  "abstractKey": "__isJiraSpreadsheetView"
},
v190 = [
  (v172/*: any*/),
  (v91/*: any*/)
],
v191 = [
  (v34/*: any*/),
  (v35/*: any*/),
  (v36/*: any*/),
  (v37/*: any*/),
  (v38/*: any*/),
  (v39/*: any*/),
  (v40/*: any*/),
  (v176/*: any*/)
],
v192 = [
  (v34/*: any*/),
  (v35/*: any*/),
  (v37/*: any*/),
  (v164/*: any*/),
  (v38/*: any*/),
  (v39/*: any*/),
  (v176/*: any*/)
],
v193 = {
  "kind": "LinkedField",
  "name": "fieldValue",
  "plural": false,
  "selections": [
    (v31/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraPriority",
          "kind": "LinkedField",
          "name": "priority",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "priorityId"
            },
            (v33/*: any*/),
            (v110/*: any*/)
          ]
        },
        (v151/*: any*/)
      ],
      "type": "JiraJqlPriorityFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v31/*: any*/),
            (v84/*: any*/),
            (v33/*: any*/),
            (v58/*: any*/)
          ]
        },
        (v151/*: any*/)
      ],
      "type": "JiraJqlUserFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraStatus",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": (v174/*: any*/)
        },
        (v151/*: any*/),
        (v45/*: any*/)
      ],
      "type": "JiraJqlStatusFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": (v122/*: any*/),
      "type": "JiraJqlNumberFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "option",
          "plural": false,
          "selections": [
            (v80/*: any*/),
            (v33/*: any*/),
            (v78/*: any*/)
          ]
        },
        (v151/*: any*/)
      ],
      "type": "JiraJqlOptionFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "sprint",
          "plural": false,
          "selections": [
            (v114/*: any*/),
            (v33/*: any*/),
            (v115/*: any*/)
          ]
        },
        (v151/*: any*/)
      ],
      "type": "JiraJqlSprintFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraGoal",
          "kind": "LinkedField",
          "name": "goal",
          "plural": false,
          "selections": [
            (v33/*: any*/),
            {
              "kind": "ScalarField",
              "name": "status"
            },
            {
              "kind": "ScalarField",
              "name": "score"
            }
          ]
        },
        (v151/*: any*/)
      ],
      "type": "JiraJqlGoalsFieldValue"
    }
  ]
},
v194 = {
  "kind": "ScalarField",
  "name": "fieldType"
},
v195 = {
  "kind": "ScalarField",
  "name": "issueCount"
},
v196 = [
  (v36/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
],
v197 = [
  (v34/*: any*/),
  (v35/*: any*/),
  (v36/*: any*/),
  (v37/*: any*/),
  (v38/*: any*/),
  (v39/*: any*/),
  (v40/*: any*/),
  (v176/*: any*/),
  (v177/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      (v23/*: any*/)
    ],
    "kind": "Fragment",
    "name": "main_IssueNavigatorQuery",
    "selections": [
      {
        "args": [
          (v24/*: any*/),
          {
            "items": [
              {
                "kind": "Variable",
                "name": "projectKeys.0",
                "variableName": "projectKey"
              }
            ],
            "kind": "ListValue",
            "name": "projectKeys"
          }
        ],
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_issueQuery"
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v26/*: any*/),
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "main_issueNavigator_IssueNavigator_userPreferences"
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "main_issueNavigator_IssueNavigator_jira"
          },
          {
            "condition": "shouldQueryProject",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v27/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "main_issueNavigator_IssueNavigatorUI_project"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v12/*: any*/),
      (v9/*: any*/),
      (v13/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v14/*: any*/),
      (v23/*: any*/),
      (v15/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/),
      (v18/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v16/*: any*/),
      (v20/*: any*/),
      (v11/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      (v17/*: any*/),
      (v10/*: any*/),
      (v19/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "main_IssueNavigatorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v26/*: any*/),
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "jqlBuilderSearchMode"
              },
              {
                "kind": "ScalarField",
                "name": "isNaturalLanguageSpotlightTourEnabled"
              },
              (v29/*: any*/)
            ]
          },
          {
            "args": (v30/*: any*/),
            "kind": "ScalarField",
            "name": "shouldShowAtlassianIntelligence"
          },
          {
            "args": (v30/*: any*/),
            "kind": "LinkedField",
            "name": "atlassianIntelligenceAction",
            "plural": false,
            "selections": [
              (v31/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "isAccessible"
                  }
                ],
                "type": "JiraAccessAtlassianIntelligenceFeature"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "link"
                  }
                ],
                "type": "JiraEnableAtlassianIntelligenceDeepLink"
              }
            ]
          },
          {
            "condition": "shouldQueryProject",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v27/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  (v32/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "projectId"
                  },
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "editIssues",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "type",
                            "value": "EDIT_ISSUES"
                          }
                        ],
                        "concreteType": "JiraProjectAction",
                        "kind": "LinkedField",
                        "name": "action",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "canPerform"
                          }
                        ],
                        "storageKey": "action(type:\"EDIT_ISSUES\")"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "projectType"
                  },
                  (v33/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v34/*: any*/),
                  (v35/*: any*/),
                  (v25/*: any*/),
                  (v36/*: any*/),
                  (v37/*: any*/),
                  (v38/*: any*/),
                  (v39/*: any*/),
                  (v40/*: any*/)
                ],
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearchStable",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v32/*: any*/),
                          (v33/*: any*/),
                          (v41/*: any*/),
                          (v42/*: any*/),
                          (v47/*: any*/),
                          (v49/*: any*/),
                          (v50/*: any*/),
                          (v127/*: any*/),
                          (v128/*: any*/)
                        ]
                      },
                      (v31/*: any*/),
                      (v29/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "hasChildren"
                          }
                        ]
                      },
                      (v130/*: any*/)
                    ]
                  },
                  (v131/*: any*/),
                  {
                    "args": (v132/*: any*/),
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "around",
                        "plural": true,
                        "selections": [
                          (v133/*: any*/),
                          (v134/*: any*/),
                          (v135/*: any*/)
                        ]
                      },
                      (v137/*: any*/),
                      (v138/*: any*/)
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                  },
                  {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                      (v139/*: any*/),
                      (v140/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "firstIssueKeyFromNextPage"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "lastIssueKeyFromPreviousPage"
                      }
                    ]
                  },
                  (v141/*: any*/),
                  (v142/*: any*/),
                  (v143/*: any*/),
                  (v29/*: any*/),
                  (v144/*: any*/)
                ]
              },
              {
                "args": (v145/*: any*/),
                "kind": "LinkedField",
                "name": "issueSearchViewResult",
                "plural": false,
                "selections": [
                  (v31/*: any*/),
                  (v146/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v147/*: any*/),
                      (v149/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v33/*: any*/),
                          (v150/*: any*/),
                          (v157/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v161/*: any*/),
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "args": (v159/*: any*/),
                                    "concreteType": "JiraIssueSearchViewConfigSettings",
                                    "kind": "LinkedField",
                                    "name": "viewConfigSettings",
                                    "plural": false,
                                    "selections": [
                                      (v162/*: any*/),
                                      (v163/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "type": "JiraIssueSearchView"
                          },
                          (v167/*: any*/),
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              (v171/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v165/*: any*/),
                                    "concreteType": "JiraIssueSearchViewConfigSettings",
                                    "kind": "LinkedField",
                                    "name": "viewSettings",
                                    "plural": false,
                                    "selections": [
                                      (v168/*: any*/),
                                      (v169/*: any*/)
                                    ]
                                  }
                                ],
                                "type": "JiraSpreadsheetView",
                                "abstractKey": "__isJiraSpreadsheetView"
                              }
                            ]
                          }
                        ],
                        "type": "JiraIssueSearchViewMetadata",
                        "abstractKey": "__isJiraIssueSearchViewMetadata"
                      }
                    ],
                    "type": "JiraIssueSearchView"
                  },
                  (v173/*: any*/),
                  (v175/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v178/*: any*/),
                    "concreteType": "JiraIssueConnection",
                    "kind": "LinkedField",
                    "name": "issueSearch",
                    "plural": false,
                    "selections": (v187/*: any*/)
                  },
                  {
                    "args": (v178/*: any*/),
                    "filters": (v188/*: any*/),
                    "handle": "connection",
                    "key": "IssueNavigatorIssueSearchPagination__issueSearch",
                    "kind": "LinkedHandle",
                    "name": "issueSearch"
                  },
                  {
                    "args": (v145/*: any*/),
                    "kind": "LinkedField",
                    "name": "issueSearchViewResult",
                    "plural": false,
                    "selections": [
                      (v31/*: any*/),
                      (v146/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v147/*: any*/),
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": (v159/*: any*/),
                                "concreteType": "JiraIssueSearchViewConfigSettings",
                                "kind": "LinkedField",
                                "name": "viewConfigSettings",
                                "plural": false,
                                "selections": [
                                  (v162/*: any*/),
                                  (v160/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v33/*: any*/),
                              (v150/*: any*/),
                              {
                                "args": (v148/*: any*/),
                                "concreteType": "JiraIssueSearchFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSets",
                                "plural": false,
                                "selections": [
                                  (v75/*: any*/),
                                  {
                                    "concreteType": "JiraIssueSearchFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueSearchFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v61/*: any*/),
                                          (v60/*: any*/),
                                          (v151/*: any*/),
                                          (v152/*: any*/),
                                          (v153/*: any*/),
                                          (v154/*: any*/),
                                          (v156/*: any*/),
                                          (v155/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v29/*: any*/)
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v161/*: any*/),
                                  {
                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": [
                                      {
                                        "args": (v159/*: any*/),
                                        "concreteType": "JiraIssueSearchViewConfigSettings",
                                        "kind": "LinkedField",
                                        "name": "viewConfigSettings",
                                        "plural": false,
                                        "selections": [
                                          (v163/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "type": "JiraIssueSearchView"
                              },
                              (v167/*: any*/),
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isJiraIssueSearchViewMetadata"
                                  },
                                  (v33/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "args": (v159/*: any*/),
                                        "concreteType": "JiraIssueSearchViewConfigSettings",
                                        "kind": "LinkedField",
                                        "name": "viewConfigSettings",
                                        "plural": false,
                                        "selections": [
                                          (v163/*: any*/),
                                          (v168/*: any*/),
                                          (v169/*: any*/)
                                        ]
                                      }
                                    ],
                                    "type": "JiraIssueSearchView"
                                  },
                                  (v189/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraIssueSearchViewMetadata",
                            "abstractKey": "__isJiraIssueSearchViewMetadata"
                          }
                        ],
                        "type": "JiraIssueSearchView"
                      },
                      (v173/*: any*/),
                      (v175/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "args": [
                      (v25/*: any*/),
                      (v38/*: any*/),
                      (v53/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "viewConfigInput",
                        "variableName": "staticViewInput"
                      },
                      (v54/*: any*/)
                    ],
                    "kind": "LinkedField",
                    "name": "jiraIssueSearchView",
                    "plural": false,
                    "selections": [
                      (v31/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isJiraView"
                      },
                      {
                        "concreteType": "QueryError",
                        "kind": "LinkedField",
                        "name": "error",
                        "plural": false,
                        "selections": (v190/*: any*/)
                      },
                      (v33/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v147/*: any*/),
                          (v149/*: any*/)
                        ],
                        "type": "JiraIssueSearchViewMetadata",
                        "abstractKey": "__isJiraIssueSearchViewMetadata"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": (v165/*: any*/),
                            "concreteType": "JiraIssueSearchViewConfigSettings",
                            "kind": "LinkedField",
                            "name": "viewSettings",
                            "plural": false,
                            "selections": [
                              (v168/*: any*/),
                              (v169/*: any*/),
                              {
                                "concreteType": "JiraSpreadsheetGroupByConfig",
                                "kind": "LinkedField",
                                "name": "groupByConfig",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraField",
                                    "kind": "LinkedField",
                                    "name": "groupByField",
                                    "plural": false,
                                    "selections": [
                                      (v43/*: any*/),
                                      (v33/*: any*/),
                                      (v57/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "typeKey"
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v162/*: any*/),
                              (v160/*: any*/)
                            ]
                          },
                          (v142/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "filterId"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v150/*: any*/),
                              (v157/*: any*/),
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  (v171/*: any*/),
                                  (v189/*: any*/)
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v161/*: any*/),
                                  {
                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": [
                                      {
                                        "args": (v159/*: any*/),
                                        "concreteType": "JiraIssueSearchViewConfigSettings",
                                        "kind": "LinkedField",
                                        "name": "viewConfigSettings",
                                        "plural": false,
                                        "selections": [
                                          (v162/*: any*/),
                                          (v163/*: any*/),
                                          (v160/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "type": "JiraIssueSearchView"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v165/*: any*/),
                                    "concreteType": "JiraIssueSearchViewConfigSettings",
                                    "kind": "LinkedField",
                                    "name": "viewSettings",
                                    "plural": false,
                                    "selections": [
                                      (v160/*: any*/),
                                      (v163/*: any*/),
                                      (v166/*: any*/),
                                      (v162/*: any*/)
                                    ]
                                  }
                                ],
                                "type": "JiraSpreadsheetView",
                                "abstractKey": "__isJiraSpreadsheetView"
                              }
                            ],
                            "type": "JiraIssueSearchViewMetadata",
                            "abstractKey": "__isJiraIssueSearchViewMetadata"
                          }
                        ],
                        "type": "JiraSpreadsheetView",
                        "abstractKey": "__isJiraSpreadsheetView"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": (v191/*: any*/),
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issues",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  (v133/*: any*/),
                                  (v179/*: any*/),
                                  (v31/*: any*/),
                                  (v29/*: any*/),
                                  (v181/*: any*/),
                                  (v130/*: any*/)
                                ]
                              },
                              (v184/*: any*/),
                              (v185/*: any*/),
                              (v186/*: any*/),
                              (v131/*: any*/),
                              (v141/*: any*/),
                              (v142/*: any*/),
                              (v143/*: any*/),
                              (v29/*: any*/),
                              (v144/*: any*/)
                            ]
                          },
                          {
                            "args": (v191/*: any*/),
                            "filters": (v188/*: any*/),
                            "handle": "connection",
                            "key": "IssueNavigatorDetailedViewPagination__issues",
                            "kind": "LinkedHandle",
                            "name": "issues"
                          }
                        ],
                        "type": "JiraDetailedView"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": (v192/*: any*/),
                            "concreteType": "JiraSpreadsheetGroupConnection",
                            "kind": "LinkedField",
                            "name": "groups",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraSpreadsheetGroupEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  (v133/*: any*/),
                                  {
                                    "concreteType": "JiraSpreadsheetGroup",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v31/*: any*/),
                                      (v33/*: any*/),
                                      (v142/*: any*/),
                                      (v193/*: any*/),
                                      (v194/*: any*/),
                                      (v195/*: any*/)
                                    ]
                                  },
                                  (v31/*: any*/)
                                ]
                              },
                              (v184/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "groupByField"
                              },
                              (v142/*: any*/),
                              {
                                "concreteType": "JiraSpreadsheetGroup",
                                "kind": "LinkedField",
                                "name": "firstGroup",
                                "plural": false,
                                "selections": [
                                  {
                                    "args": (v196/*: any*/),
                                    "concreteType": "JiraIssueConnection",
                                    "kind": "LinkedField",
                                    "name": "issues",
                                    "plural": false,
                                    "selections": [
                                      (v144/*: any*/)
                                    ]
                                  },
                                  (v33/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "QueryError",
                                "kind": "LinkedField",
                                "name": "errors",
                                "plural": true,
                                "selections": (v190/*: any*/)
                              },
                              {
                                "condition": "isPaginating",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraSpreadsheetGroup",
                                    "kind": "LinkedField",
                                    "name": "firstGroup",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "args": (v196/*: any*/),
                                        "concreteType": "JiraIssueConnection",
                                        "kind": "LinkedField",
                                        "name": "issues",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v41/*: any*/),
                                                  (v33/*: any*/),
                                                  (v31/*: any*/),
                                                  (v32/*: any*/),
                                                  (v42/*: any*/),
                                                  (v47/*: any*/),
                                                  (v49/*: any*/),
                                                  (v50/*: any*/),
                                                  {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": false,
                                                    "selections": [
                                                      (v125/*: any*/),
                                                      {
                                                        "condition": "shouldQueryFieldSetsById",
                                                        "kind": "Condition",
                                                        "passingValue": false,
                                                        "selections": [
                                                          {
                                                            "args": (v55/*: any*/),
                                                            "concreteType": "JiraIssueFieldSetConnection",
                                                            "kind": "LinkedField",
                                                            "name": "fieldSetsForIssueSearchView",
                                                            "plural": false,
                                                            "selections": (v124/*: any*/)
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v31/*: any*/),
                                              (v133/*: any*/),
                                              (v29/*: any*/),
                                              (v130/*: any*/)
                                            ]
                                          },
                                          (v131/*: any*/),
                                          (v143/*: any*/),
                                          {
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                              (v182/*: any*/),
                                              (v183/*: any*/)
                                            ]
                                          },
                                          (v29/*: any*/)
                                        ]
                                      },
                                      {
                                        "args": (v196/*: any*/),
                                        "filters": [
                                          "fieldSetsInput"
                                        ],
                                        "handle": "connection",
                                        "key": "firstGroupSection_nativeIssueTable__issues",
                                        "kind": "LinkedHandle",
                                        "name": "issues"
                                      },
                                      (v142/*: any*/),
                                      (v193/*: any*/),
                                      (v194/*: any*/),
                                      (v195/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v29/*: any*/)
                            ]
                          },
                          {
                            "args": (v192/*: any*/),
                            "filters": (v188/*: any*/),
                            "handle": "connection",
                            "key": "IssueNavigatorGroupedListViewPagination__groups",
                            "kind": "LinkedHandle",
                            "name": "groups"
                          }
                        ],
                        "type": "JiraGroupedListView"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": (v197/*: any*/),
                            "concreteType": "JiraIssueConnection",
                            "kind": "LinkedField",
                            "name": "issues",
                            "plural": false,
                            "selections": (v187/*: any*/)
                          },
                          {
                            "args": (v197/*: any*/),
                            "filters": (v188/*: any*/),
                            "handle": "connection",
                            "key": "IssueNavigatorListViewPagination__issues",
                            "kind": "LinkedHandle",
                            "name": "issues"
                          }
                        ],
                        "type": "JiraListView"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e418b064f8fea2498cdcd449510e8ac04a0e7832adf4834103291179a5fba118",
    "metadata": {},
    "name": "main_IssueNavigatorQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider": isReparentingEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider
    }
  }
};
})();

(node as any).hash = "344055adbfdb9e9e6653707fee1061b5";

export default node;
