/**
 * @generated SignedSource<<47d32ff7c36122fa39897b48fe0f9a0e>>
 * @relayHash c7324e9819e70c7df227b1da0e5f9ed8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9559fd56bec53bc3ae3d1aac1974c98b76d67946b87c7eda3c47edc8bca6c42b

import type { ConcreteRequest, Query } from 'relay-runtime';
export type standardToPremiumPubTouchpointQuery$variables = {
  cloudId: string;
  productKey: string;
  shouldSkip: boolean;
};
export type standardToPremiumPubTouchpointQuery$data = {
  readonly tenantContexts?: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly paidEntitlement: {
        readonly experienceCapabilities: {
          readonly changeOfferingV2: {
            readonly experienceUrl: string | null | undefined;
            readonly isAvailableToUser: boolean | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly subscription: {
          readonly accountDetails: {
            readonly invoiceGroup: {
              readonly invoiceable: boolean | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type standardToPremiumPubTouchpointQuery = {
  response: standardToPremiumPubTouchpointQuery$data;
  variables: standardToPremiumPubTouchpointQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productKey"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldSkip"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "productKey"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "inTrialOrPreDunning": false
    }
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "offeringName",
    "value": "premium"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "isAvailableToUser"
},
v6 = {
  "kind": "ScalarField",
  "name": "experienceUrl"
},
v7 = {
  "kind": "ScalarField",
  "name": "invoiceable"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = [
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "standardToPremiumPubTouchpointQuery",
    "selections": [
      {
        "condition": "shouldSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "TenantContext",
            "kind": "LinkedField",
            "name": "tenantContexts",
            "plural": true,
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "entitlementInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": "paidEntitlement",
                    "args": (v3/*: any*/),
                    "kind": "LinkedField",
                    "name": "entitlement",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "experienceCapabilities",
                        "plural": false,
                        "selections": [
                          {
                            "args": (v4/*: any*/),
                            "kind": "LinkedField",
                            "name": "changeOfferingV2",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": "changeOfferingV2(offeringName:\"premium\")"
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "accountDetails",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "invoiceGroup",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "storageKey": "entitlement(where:{\"inTrialOrPreDunning\":false})"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "standardToPremiumPubTouchpointQuery",
    "selections": [
      {
        "condition": "shouldSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "TenantContext",
            "kind": "LinkedField",
            "name": "tenantContexts",
            "plural": true,
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "entitlementInfo",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": "paidEntitlement",
                    "args": (v3/*: any*/),
                    "kind": "LinkedField",
                    "name": "entitlement",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "experienceCapabilities",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "args": (v4/*: any*/),
                            "kind": "LinkedField",
                            "name": "changeOfferingV2",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": "changeOfferingV2(offeringName:\"premium\")"
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "accountDetails",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "invoiceGroup",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v10/*: any*/),
                                    "type": "CcpInvoiceGroup"
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v10/*: any*/),
                            "type": "CcpSubscription"
                          }
                        ]
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": "entitlement(where:{\"inTrialOrPreDunning\":false})"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9559fd56bec53bc3ae3d1aac1974c98b76d67946b87c7eda3c47edc8bca6c42b",
    "metadata": {},
    "name": "standardToPremiumPubTouchpointQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "855ed0d991a0791b463b2dda5ef90efb";

export default node;
