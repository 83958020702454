import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { LazyAssetsLayout, AssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { assetsSearchRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsSearchRoute.tsx';
import { assetsSearchEntryPoint } from '@atlassian/jira-assets-app-search/entrypoint.tsx';
import { AssetsPageConfigContainer } from '@atlassian/jira-assets-app-page-config-container/src/ui/async.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';

const assetsSearchPageEntryPoint = createChromelessEntryPoint({
	main: assetsSearchEntryPoint,
});

export const assetsSearchRouteEntry = createEntry(assetsSearchRoute, () => ({
	group: 'assets',
	LayoutOverrideComponent: AssetsLayout,
	PageConfigContainerOverride: AssetsPageConfigContainer,
	skeleton: Spinner,
	entryPoint: () => assetsSearchPageEntryPoint,
	resources: getAssetsAppResources(),
	forPaint: [LazyAssetsLayout],
	perfMetricKey: 'assets-app.interact-with-search-page',
}));
