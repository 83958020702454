// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

const initialState: State = {
	status: 'idle',
};

const store = createStore<State, Actions>({
	actions,
	initialState,
	name: 'atlassian-navigation-recommendation',
});

export const useProjectMenuRecommendations = createHook(store);
