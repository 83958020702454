import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { servicedeskProjectSummaryRoute } from '@atlassian/jira-router-routes-servicedesk-project-summary-routes/src/servicedeskProjectSummaryRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { ProjectSummarySkeleton } from '@atlassian/jira-skeletons/src/ui/servicedesk/project-summary/ProjectSummarySkeleton.tsx';
import { getProjectSummaryFilterFieldsResource } from '@atlassian/jira-router-resources-service-desk-project-summary/src/services/filters/index.tsx';
import { projectSummaryPageEntryPoint } from './ui/index.tsx';

export const servicedeskProjectSummaryRouteEntry = createEntry(
	servicedeskProjectSummaryRoute,
	() => ({
		group: 'servicedesk',
		ufoName: 'jsm.project-summary',

		component: componentWithCondition(
			() => fg('jsm-project-summary'),
			() => null,
			ErrorPagesNotFound,
		),
		skeleton: ProjectSummarySkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		layout: serviceProjectLayout,

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
			getProjectSummaryFilterFieldsResource(),
		],

		entryPoint: () => (fg('jsm-project-summary') ? projectSummaryPageEntryPoint : undefined),

		meta: {
			reporting: {
				id: 'project-summary',
				packageName: toPackageName(APP_NAMES.PROJECT_SUMMARY),
				teamName: 'jsd-shield',
			},
		},
	}),
);
