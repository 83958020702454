import { fg } from '@atlassian/jira-feature-gating';

export const getSettingsPath = (path: string, isJCS: boolean, helpCenterId?: string) => {
	if (!helpCenterId) {
		return isJCS ? path.replace('/jira/servicedesk/', '/jira/helpcenter/') : path;
	}
	return fg('berry-4386-add-helpcenterid-to-routes')
		? path
				.replace('/jira/servicedesk/', '/jira/helpcenter/')
				.replace('/settings', `/settings/${helpCenterId}`)
		: path.replace('/jira/servicedesk/', '/jira/helpcenter/');
};
