import React from 'react';
import { styled } from '@compiled/react';
import Badge, { type BadgeProps } from '@atlaskit/badge';
import { token } from '@atlaskit/tokens';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { SelectValue } from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { labelContainerTestId, badgeTestId } from '../constants.tsx';

export const hasValue = (value: SelectValue): boolean =>
	Array.isArray(value) ? value.length > 0 : typeof value === 'object' && value !== null;

type Props = {
	isInvalid?: boolean;
	label?: string | null;
	value?: SelectValue;
	isSelected?: boolean;
};

export const TriggerComponentNoOperator = ({
	isInvalid = false,
	label = '',
	value,
	isSelected,
}: Props) => {
	if (!value || !hasValue(value)) {
		return isVisualRefreshEnabled() && fg('visual-refresh-headers-buttons') ? (
			<Box xcss={triggerContainerStyles}>
				<Box xcss={triggerStyles} testId={labelContainerTestId}>
					<Text weight="medium" color={!isSelected ? 'color.text.subtle' : 'inherit'}>
						{label}
					</Text>
				</Box>
			</Box>
		) : (
			<Container>
				<LabelContainer data-testid={labelContainerTestId}>{label}</LabelContainer>
			</Container>
		);
	}

	const valueMap = value.map((v) =>
		v.optionType !== 'error' && v.label !== undefined ? v.label : '',
	);
	const valueLength = valueMap.length;
	const [firstValue, ...rest] = valueMap;

	const badgeAppearance: BadgeProps['appearance'] = isInvalid ? 'important' : 'primary';

	return (
		<Container>
			<LabelContainer data-testid={labelContainerTestId}>
				<StyledLabel>{label}:</StyledLabel>
				{firstValue}
			</LabelContainer>
			{valueLength > 1 ? (
				<StyledBadge>
					<Badge testId={badgeTestId} appearance={badgeAppearance}>{`+${rest.length}`}</Badge>
				</StyledBadge>
			) : null}
		</Container>
	);
};

const triggerContainerStyles = xcss({
	display: 'inline-flex',
	alignItems: 'center',
});

const triggerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	maxWidth: `${36 * gridSize}px`,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.span({
	display: 'inline-flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${36 * gridSize}px`,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledLabel = styled.span({
	fontWeight: token('font.weight.bold'),
	marginRight: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledBadge = styled.span({
	display: 'inline-flex',
	alignItems: 'center',
	marginLeft: token('space.050'),
});
