import type { InvariantJiraRoute } from '@atlassian/jira-route-types/src/InvariantJiraRoute.tsx';

// This route is used to catch transitions to non-matching paths
// Make sure `path` is kept as ''
// Make sure `exact` is kept as false
export const legacyRedirectRoute: InvariantJiraRoute = {
	path: '',
	name: 'legacy-redirect',
	exact: false,
};
