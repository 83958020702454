// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TabIcon } from './ui/tab-icon';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	type TabConfiguration,
	type TabsConfiguration,
	type TabDetailsProps,
	TabsConfigurationProvider,
	useTabConfiguration,
	useTabsConfiguration,
} from './common/tabs-configuration-context';

export const NAVIGATION_ITEMS_PAGE_SIZE = 75;

export const TAB_UNDERLINE_HEIGHT = 2;
export const TAB_HEIGHT = 36;
export const TAB_ANGLE = 5;
export const TAB_GAP = 12;

/**
 * We show a count of the overflowing tabs (tabs in the more menu)
 * This number is the maximum count we will show. If there are more tabs, we show "<MAX_MORE_TAB_COUNT>+" e.g. "9+"
 * Note: If this number changed to two-digits, the CSS in navigation-kit-ui-tab-list may need to be updated, as this would affect the width calculation of the More menu
 */
export const MAX_MORE_TAB_COUNT = 9;

export const MORE_TRIGGER_ID = 'MORE';
