import React, { useCallback, useEffect, type PropsWithChildren } from 'react';
import Button from '@atlaskit/button/new';
import PremiumIcon from '@atlaskit/icon/core/premium';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import { useJqlDebuggerStoreActions } from '../../controllers/jql-debugger-data/index.tsx';
import type { OnSearchCallback } from '../../common/types.tsx';
import messages from './messages.tsx';

export type JQLDebuggerProps = PropsWithChildren<{
	testId: string;
}>;

export type JQLDebuggerWithPanelProps = {
	testId: string;
	jqlErrors?: string[];
	onSearch?: OnSearchCallback;
	query?: string;
	entryPointActions: EntryPointActions;
};

function useImportOnClickHandler() {
	const importer = useCallback(() => {
		return import(/* webpackChunkName: "jql-debugger-on-click" */ './on-click');
	}, []);

	useEffect(() => {
		importer();
	}, [importer]);

	return importer;
}

export function FixErrorButton(props: JQLDebuggerWithPanelProps) {
	const { testId, jqlErrors, onSearch, query, entryPointActions } = props;

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const debuggerStoreActions = useJqlDebuggerStoreActions();
	const importer = useImportOnClickHandler();

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'fixWithAiButton viewed', {
			debuggerPanel: true,
		});
	}, [createAnalyticsEvent]);

	return (
		<Button
			appearance="primary"
			iconBefore={PremiumIcon}
			onMouseOver={() => {
				entryPointActions?.preload();
			}}
			testId="jql-debugger.ui.fix-error-button.button"
			onClick={async () => {
				entryPointActions?.load();
				const { onClick } = await importer();
				onClick({
					testId,
					query: query || '',
					jqlErrors,
					onSearch,
					createAnalyticsEvent,
					debuggerStoreActions,
				});
			}}
		>
			{formatMessage(messages.fixErrorWithAiNonFinal)}
		</Button>
	);
}
