import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const sandboxBundlerBifrostEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-sandbox-bundler-entrypoints", jiraSpaEntry: "async-sandbox-bundler-entrypoints" */ '@atlassian/jira-spa-apps-sandbox-bundler'
			),
	),
	getPreloadProps: () => ({}),
});
