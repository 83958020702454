import React from 'react';

import type { GlobalMoreMenuProps, MenuItemConfigData } from './types';
import { MoreMenuPopup } from './ui/more-menu-popup';

export function GlobalMoreMenu({
	productItemsData,
	appItemsData = [],
	menuItemComponent,
	customizeSidebarMenuItem,
	moreMenuItemLabel,
	peekingMenuIds = [],
	onClickMoreMenuTrigger,
}: GlobalMoreMenuProps) {
	const checkIsHiddenMenuItem = ({ menuId, visible }: MenuItemConfigData) =>
		!visible && !peekingMenuIds.includes(menuId);
	const hiddenProductItemsData = productItemsData.filter(checkIsHiddenMenuItem);
	const hiddenAppItemsData = appItemsData.filter(checkIsHiddenMenuItem);

	if (hiddenProductItemsData.length === 0 && hiddenAppItemsData.length === 0) {
		return <>{customizeSidebarMenuItem}</>;
	}

	return (
		<MoreMenuPopup
			productItemsData={hiddenProductItemsData}
			appItemsData={hiddenAppItemsData}
			moreMenuItemComponent={menuItemComponent}
			customizeSidebarMenuItem={customizeSidebarMenuItem}
			moreMenuItemLabel={moreMenuItemLabel}
			onClickMoreMenuTrigger={onClickMoreMenuTrigger}
		/>
	);
}
