import React, { forwardRef, type ReactNode } from 'react';

import mergeRefs from '@atlaskit/ds-lib/merge-refs';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { PopupTrigger } from '@atlaskit/popup/experimental';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { MenuItemBase } from '../menu-item';
import { COLLAPSE_ELEM_BEFORE_TYPE } from '../menu-item-signals';
import type { MenuItemCommonProps, MenuItemOnClick } from '../types';

export type FlyoutMenuItemTriggerProps = MenuItemCommonProps & {
	/**
	 * `ReactNode` to be placed visually before the `children`.
	 *
	 * This `ReactNode` will be rendered visually on top of the main
	 * interactive element for the menu item. If this element does not
	 * contain an interactive element (`button` or `a`) then `pointer-events`
	 * will be set to `none` on this slot so that users can click through
	 * this element onto the main interactive element of the menu item.
	 */
	elemBefore?: ReactNode | COLLAPSE_ELEM_BEFORE_TYPE;

	/**
	 * Called when the user has clicked on the trigger.
	 */
	onClick?: MenuItemOnClick<HTMLButtonElement>;

	/**
	 * Indicates that the menu item is selected.
	 */
	isSelected?: boolean;
};

/**
 * __FlyoutMenuItemTrigger__
 *
 * The button that toggles the flyout menu.
 */
export const FlyoutMenuItemTrigger = forwardRef<HTMLButtonElement, FlyoutMenuItemTriggerProps>(
	(
		{
			children,
			elemBefore,
			onClick,
			isSelected,
			interactionName,
			testId,
			isContentTooltipDisabled,
			visualContentRef,
		},
		forwardedRef,
	) => (
		<PopupTrigger>
			{({
				ref,
				'aria-controls': ariaControls,
				'aria-expanded': ariaExpanded,
				'aria-haspopup': ariaHasPopup,
			}) => (
				<MenuItemBase
					testId={testId}
					ref={mergeRefs([ref, forwardedRef])}
					visualContentRef={visualContentRef}
					elemBefore={elemBefore}
					elemAfter={
						<Box paddingInline="space.075">
							<ChevronRightIcon label="" color={token('color.icon', '#44546F')} />
						</Box>
					}
					onClick={onClick}
					ariaControls={ariaControls}
					ariaExpanded={ariaExpanded}
					ariaHasPopup={ariaHasPopup}
					interactionName={interactionName}
					isContentTooltipDisabled={isContentTooltipDisabled}
					isSelected={isSelected}
				>
					{children}
				</MenuItemBase>
			)}
		</PopupTrigger>
	),
);
