import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { globalIssueNavigatorEntryPoint } from '@atlassian/jira-spa-apps-global-issue-navigator/entrypoint.tsx';
import { nativeIssueTableEntryPoint } from '@atlassian/jira-native-issue-table/src/ui/entrypoint.tsx';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';

export const globalIssueNavigatorGinRedirectEntrypoint = createEntryPoint({
	root: JSResourceForUserVisible(
		() =>
			import(
				/* webpackChunkName: "async-global-issue-navigator-gin-redirect-entrypoint" */ './index.tsx'
			),
	),
	getPreloadProps: (entryPointParams: EntryPointRouteParams) => {
		const issueKey = entryPointParams?.context?.match?.params?.issueKey;

		return {
			entryPoints: {
				globalIssueNavigatorEntryPoint: {
					entryPoint: globalIssueNavigatorEntryPoint,
					entryPointParams,
				},
				...(!issueKey
					? {
							nativeIssueTableEntryPoint: {
								entryPoint: nativeIssueTableEntryPoint,
								entryPointParams,
							},
						}
					: {}),
			},
		};
	},
});
