import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { helpCenterSettingsNotFoundRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsNotFoundRoute.tsx';
import { NotFound } from './ui/not-found/index.tsx';

export const helpCenterSettingsNotFoundRouteEntry = createEntry(
	helpCenterSettingsNotFoundRoute,
	() => ({
		group: 'customer-service',
		component: NotFound,
	}),
);
