import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { serviceDeskAssetsImportTypeForgeDataResource } from '@atlassian/jira-router-resources-assets-import-type-forge-data/src/index.tsx';
import { assetsSchemaConfigurationRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsSchemaConfigurationRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { assetsSchemaSettingsEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-object-schema-configuration/src/entrypoint.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { LazyAssetsLayout, AssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { AssetsPageConfigContainer } from '@atlassian/jira-assets-app-page-config-container/src/ui/async.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';

const assetsSchemaSettingsPageEntryPoint = createChromelessEntryPoint({
	main: assetsSchemaSettingsEntryPoint,
});

export const assetsSchemaConfigurationRouteEntry = createEntry(
	assetsSchemaConfigurationRoute,
	() => ({
		group: 'assets',
		LayoutOverrideComponent: AssetsLayout,
		PageConfigContainerOverride: AssetsPageConfigContainer,
		skeleton: Spinner,
		entryPoint: () => assetsSchemaSettingsPageEntryPoint,
		pageId: () => 'spa-apps/servicedesk/insight/cmdb-object-schema-configuration',
		resources: [...getAssetsAppResources(), serviceDeskAssetsImportTypeForgeDataResource],
		forPaint: [LazyAssetsLayout],
		perfMetricKey: 'service-management.insight-cmdb.interact-with-object-schema-config',
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_SCHEMA_CONFIGURATION),
				teamName: 'krispy-krew',
			},
		},
	}),
);
