import React, { type ReactElement, useMemo, useCallback, memo } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { SECTION_ITEM_DASHBOARDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_DASHBOARDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import {
	L1_MENU_ID,
	type LeftSidebarEntryPointConfig,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { useExpandableEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-expandable-entry-point-trigger/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { LEFT_SIDEBAR } from '@atlassian/jira-entry-point-page-layout-fields/src/index.tsx';
import { usePageLayoutEntryPoint } from '@atlassian/jira-entry-point-page-layout/src/index.tsx';
import { RouteEntryPointContainer } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/route-entry-point-adapter/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants.tsx';
import { Footer } from '../../../common/ui/footer/index.tsx';
import { nav4DashboardsContentEntryPoint } from './async-content/entrypoint.tsx';

export type DashboardsDefaultProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
};

export const DashboardsDefault = memo(_DashboardsDefault);

function _DashboardsDefault(props: DashboardsDefaultProps) {
	const { actionsOnHover, isExpanded } = props;
	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const cloudId = useCloudId();

	// Ignore this error as our component does not need the context.
	// @ts-expect-error - Property 'context' is missing in type '{ tenantContext: { cloudId: string; }; }' but required in type 'EntryPointRouteParams'.ts(2741)
	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const entryPointParams: EntryPointRouteParams = useMemo(
		() => ({ tenantContext: { cloudId } }),
		[cloudId],
	);

	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, react-hooks/rules-of-hooks
	const leftSidebarEPRef = usePageLayoutEntryPoint(LEFT_SIDEBAR) as LeftSidebarEntryPointConfig;
	const dashboardsEPRef = leftSidebarEPRef?.entryPoints?.dashboards;
	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		nav4DashboardsContentEntryPoint,
		entryPointParams,
	);
	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const triggerRef = useExpandableEntryPointTrigger(entryPointActions);
	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.DASHBOARDS);
	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const epContainerProps = useMemo(
		() => ({
			id: 'nav4-sidebar-dashboards-default',
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
			errorFallback: 'flag' as const,
			fallback: <SkeletonNavMenuItems />,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			runtimeProps: entryPointParams as never,
		}),
		[entryPointParams],
	);

	// This callback makes sure that the EP is loaded when the _system_ determines that the expandable
	// should be open.
	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const onSystemToggleFn = useCallback(
		(hasExpanded: boolean) => {
			if (hasExpanded) {
				entryPointActions.load();
			}
		},
		[entryPointActions],
	);

	// Suppressed due to new lint violation when upgrading to eslint-plugin-react-hooks v5
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const onExpansionToggleFn = useCallback(
		(hasExpanded: boolean) => {
			if (hasExpanded) {
				entryPointActions.load();
				sendSectionItemAnalyticsEvent({
					menuId: L1_MENU_ID.DASHBOARDS,
					sectionItem: SECTION_ITEM_DASHBOARDS,
				});
			}
		},
		[entryPointActions, sendSectionItemAnalyticsEvent],
	);

	const menuId = MENU_ID_DASHBOARDS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onSystemToggle={onSystemToggleFn}
			onExpansionToggle={onExpansionToggleFn}
		>
			<ExpandableMenuItemTrigger
				interactionName="nav4-sidebar-dashboards-default"
				actionsOnHover={actionsOnHover}
				aria-label={formattedMessage}
				elemBefore={icon}
				ref={triggerRef}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<RouteEntryPointContainer
					epContainerProps={epContainerProps}
					jiraEPRef={entryPointReferenceSubject}
					nestedEPRef={dashboardsEPRef}
					placeholderEntryPoint={nav4DashboardsContentEntryPoint}
				/>
				<Footer />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}
