import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { assetsRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsRoute.tsx';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { landingPageEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-cmdb-landing-page/src/entrypoint.tsx';
import { LazyAssetsLayout, AssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { AssetsPageConfigContainer } from '@atlassian/jira-assets-app-page-config-container/src/ui/async.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';

const assetsPageEntryPoint = createChromelessEntryPoint({
	main: landingPageEntryPoint,
});

export const assetsRouteEntry = createEntry(assetsRoute, () => ({
	group: 'assets',
	LayoutOverrideComponent: AssetsLayout,
	PageConfigContainerOverride: AssetsPageConfigContainer,
	skeleton: Spinner,
	entryPoint: () => assetsPageEntryPoint,
	pageId: () => 'spa-apps/servicedesk/insight/cmdb-landing-page',
	resources: getAssetsAppResources(),
	forPaint: [LazyAssetsLayout],
	perfMetricKey: 'service-management.insight-cmdb.interact-with-landing-page',
}));
