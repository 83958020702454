/**
 * @generated SignedSource<<b9b9d7b454a73eff8adf8e815e0ef8fe>>
 * @relayHash 9d3616546ae5d04c5eb5104942c55cab
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a4a1fa1f00dc32a0c9a06cc2503c38fc752dd1049b2dc0f81cecd881890d81d7

import type { ConcreteRequest, Query } from 'relay-runtime';
export type editionAwarenessNextQuery$variables = {
  chargeElement: string;
  cloudId: string;
  hamsProductKey: string;
  isCurrentUserBillingAdminAttributeFgEnabled: boolean;
  isNewEaJiraAudienceAndTimingEnabled: boolean;
  offeringKey?: string | null | undefined;
};
export type editionAwarenessNextQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly experienceCapabilities: {
          readonly changeOfferingV2?: {
            readonly experienceUrl: string | null | undefined;
            readonly isAvailableToUser: boolean | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly latestUsageForChargeElement: number | null | undefined;
        readonly transactionAccount?: {
          readonly isCurrentUserBillingAdmin: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type editionAwarenessNextQuery = {
  response: editionAwarenessNextQuery$data;
  variables: editionAwarenessNextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chargeElement"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hamsProductKey"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCurrentUserBillingAdminAttributeFgEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNewEaJiraAudienceAndTimingEnabled"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offeringKey"
},
v6 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "hamsProductKey"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "offeringKey",
    "variableName": "offeringKey"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "experienceUrl"
},
v10 = {
  "kind": "ScalarField",
  "name": "isAvailableToUser"
},
v11 = {
  "args": [
    {
      "kind": "Variable",
      "name": "chargeElement",
      "variableName": "chargeElement"
    }
  ],
  "kind": "ScalarField",
  "name": "latestUsageForChargeElement"
},
v12 = {
  "kind": "ScalarField",
  "name": "isCurrentUserBillingAdmin"
},
v13 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v14 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "editionAwarenessNextQuery",
    "selections": [
      {
        "args": (v6/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v7/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      {
                        "condition": "isNewEaJiraAudienceAndTimingEnabled",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "args": (v8/*: any*/),
                            "kind": "LinkedField",
                            "name": "changeOfferingV2",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/),
                  {
                    "condition": "isCurrentUserBillingAdminAttributeFgEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "transactionAccount",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "editionAwarenessNextQuery",
    "selections": [
      {
        "args": (v6/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v7/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "condition": "isNewEaJiraAudienceAndTimingEnabled",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "args": (v8/*: any*/),
                            "kind": "LinkedField",
                            "name": "changeOfferingV2",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/),
                  {
                    "condition": "isCurrentUserBillingAdminAttributeFgEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "transactionAccount",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v14/*: any*/)
                            ],
                            "type": "CcpTransactionAccount"
                          }
                        ]
                      }
                    ]
                  },
                  (v14/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a4a1fa1f00dc32a0c9a06cc2503c38fc752dd1049b2dc0f81cecd881890d81d7",
    "metadata": {},
    "name": "editionAwarenessNextQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0815a3d05dade2a96a9e399fd5b5948e";

export default node;
