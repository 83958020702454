import React, { Fragment, useCallback, useMemo, type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useExperienceAbort } from '@atlassian/jira-experience-tracker/src/ui/experience-abort/index.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { getUserLocation } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { SidebarStart, useSidebarExperience } from '../../controllers/metrics/index.tsx';
import { Fallback } from './fallback/index.tsx';

type Props = {
	children: ReactNode;
	id: string;
	packageName: string;
	teamName?: string;
	ufoName?: string;
};

export const SidebarBoundary = ({ children, id, packageName, teamName, ufoName = '' }: Props) => {
	const { experience, attributes } = useSidebarExperience();
	const onFail = useExperienceFail({ experience, attributes });
	const onAbort = useExperienceAbort({ experience, attributes });

	const userLocation = getUserLocation();
	const isNotSSR = !__SERVER__;

	const extraEventData = useMemo(() => ({ userLocation }), [userLocation]);

	const fallback = useCallback(({ error }: { error: Error }) => <Fallback error={error} />, []);

	const handleError = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(location: string, error: any) => {
			const { statusCode } = error;

			if (statusCode >= 400 && statusCode < 500) {
				onAbort('Sidebar failed with validation error', { error });
			} else {
				onFail(location, error);
			}
		},
		[onAbort, onFail],
	);

	const UFOSegmentORFragment = ufoName ? UFOSegment : Fragment;

	return (
		<UFOSegmentORFragment name={ufoName}>
			<JSErrorBoundary
				id={id}
				packageName={packageName}
				extraEventData={extraEventData}
				fallback={fallback}
				teamName={teamName}
				onError={handleError}
			>
				{isNotSSR && <SidebarStart />}
				{children}
			</JSErrorBoundary>
		</UFOSegmentORFragment>
	);
};
