import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	disabledItemAriaLabel: {
		id: 'navigation.global-shortcuts.menu-link-item.disabled-item-aria-label',
		description:
			'Additional context after the label text for screen reader users when an item is disabled',
		defaultMessage: '(currently unavailable)',
	},
});
