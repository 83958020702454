export const PAGE_SIZE_QUERY_PARAM = 'size';
export const NANOSECONDS_IN_MILLISECOND = 1000000;

export const getHeaderWidths = (params: { showEventCategoryField: boolean }) => {
	const { DATE_HEADER_WIDTH, CATEGORY_HEADER_WIDTH, TYPE_HEADER_WIDTH } =
		params.showEventCategoryField
			? {
					// Widths for audit logs page
					DATE_HEADER_WIDTH: 16,
					CATEGORY_HEADER_WIDTH: 16,
					TYPE_HEADER_WIDTH: 10,
				}
			: {
					// Widths for integration-specific log page
					DATE_HEADER_WIDTH: 14,
					CATEGORY_HEADER_WIDTH: 0, // This category is not visible in this page
					TYPE_HEADER_WIDTH: 12,
				};

	const columnsToShow = [DATE_HEADER_WIDTH, CATEGORY_HEADER_WIDTH, TYPE_HEADER_WIDTH];

	let sum = 0;
	columnsToShow.forEach((width) => {
		sum += width;
	});

	return {
		DATE_HEADER_WIDTH: `${DATE_HEADER_WIDTH}%`,
		CATEGORY_HEADER_WIDTH: `${CATEGORY_HEADER_WIDTH}%`,
		TYPE_HEADER_WIDTH: `${TYPE_HEADER_WIDTH}%`,
		CHANGE_SUMMARY_HEADER_WIDTH: `${100 - sum}%`,
	} as const;
};

export const DETAIL_ITEM_BASE_WIDTH = 250;
