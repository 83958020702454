/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import reportCategoriesSVG from './assets/report-categories-skeleton.tpl.svg';

const containerStyles = {
	maxWidth: '100%',
};

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		borderRightWidth: 0,
	},
});

export const ReportsOverviewSkeleton = () => (
	<AppSkeletonImage
		src={reportCategoriesSVG}
		containerStyles={containerStyles}
		css={customCssStyles}
	/>
);
