import type { JiraSpaRoute, Match, Query, RouterContext } from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-name-types/src/RouteName.tsx';
import {
	ENTITY_ID,
	MENU_ID_DASHBOARDS,
	MENU_ID_DASHBOARDS_VIEW_ALL,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<RouteName>([
	'dashboards-directory',
	'dashboard-edit',
	'dashboard-view',
	'dashboard-wallboard',
]);

function convert(routeContext: RouterContext<JiraSpaRoute>): SelectedPath {
	const routeName: RouteName = routeContext.route.name;
	const { match, query } = routeContext;
	const path: string[] = [MENU_ID_DASHBOARDS];
	return convertRouteToPath({ routeName, match, query, path });
}

function convertNew(args: { routeName: RouteName; match: Match; query: Query }): SelectedPath {
	const { routeName, match, query } = args;
	const path: string[] = [MENU_ID_DASHBOARDS];
	return convertRouteToPath({ routeName, match, query, path });
}

function convertRouteToPath(args: {
	routeName: RouteName;
	match: Match;
	query: Query;
	path: string[];
}): SelectedPath {
	const { routeName, match, query, path } = args;
	switch (routeName) {
		case 'dashboards-directory':
			path.push(MENU_ID_DASHBOARDS_VIEW_ALL);
			break;

		case 'dashboard-wallboard':
			query.dashboardId && path.push(ENTITY_ID.DASHBOARD(query.dashboardId));
			break;

		default: {
			const dashboardId = match.params.dashboardId;

			if (dashboardId === 'last-visited') {
				// This is not really correct. The problem is we cannot tell what the true dashboardId is in this case.
				path.push(MENU_ID_DASHBOARDS_VIEW_ALL);
			} else {
				dashboardId && path.push(ENTITY_ID.DASHBOARD(dashboardId));
			}

			break;
		}
	}

	return path;
}

export const dashboardsConverter = { routes, convert, convertNew };
