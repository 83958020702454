import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { settingsAutomationRoute } from '@atlassian/jira-router-routes-settings-automation-routes/src/settingsAutomationRoute.tsx';
import { conditionalPostOfficeJiraTopBannerResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';
import Automation, { LazyGlobalAutomation } from './ui/index.tsx';

export const settingsAutomationRouteEntry = createEntry(settingsAutomationRoute, () => ({
	group: 'global-settings',
	component: Automation,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsSystem,
	},

	resources: [
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		conditionalPostOfficeJiraTopBannerResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyGlobalAutomation],
}));
