import {
	AFFECTS_VERSIONS_TYPE,
	ASSIGNEE_TYPE,
	ATTACHMENT_TYPE,
	CASCADING_SELECT_CF_TYPE,
	COMPONENTS_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	DESCRIPTION_TYPE,
	DUE_DATE_TYPE,
	FIX_VERSIONS_TYPE,
	FLAGGED_CF_TYPE,
	GOALS_CF_TYPE,
	GROUP_CF_TYPE,
	ISSUE_TYPE,
	LABELS_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	NUMBER_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	PARENT_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	PROJECT_PICKER_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	RANK_CF_TYPE,
	SELECT_CF_TYPE,
	SPRINT_TYPE,
	START_DATE_TYPE,
	STATUS_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	STORY_POINTS_TYPE,
	SUMMARY_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	TIME_ESTIMATE_TYPE,
	TIME_TRACKING_TYPE,
	USER_CF_TYPE,
	URL_CF_TYPE,
	VERSION_CF_TYPE,
	VOTES_TYPE,
	WATCHES_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export const DEFAULT_ATTRIBUTES = {
	isInlineEditing: false,
	isCommandPaletteEditing: false,
	isDragEditing: false,
	isContextMenuEditing: false,
	isIssueViewEditing: false,
};

export const START_ACTION = 'clicked';
export const START_ACTION_SUBJECT = 'item';
export const START_ACTION_SUBJECT_ID = 'issue';

export const END_ACTION = 'updated';
export const END_ACTION_SUBJECT = 'issue';
export const END_ACTION_SUBJECT_ID = 'ended';

export const ACTION_TAKEN_ADD = 'add';
export const ACTION_TAKEN_REMOVE = 'remove';

export const DRAG_DROP_UNKNOWN_FIELD_ID = 'unknown';

// Some fields do not have a concrete field type when session starts, i.e. when it's unclear which field type the user is
// interacting with. Fields with these ids will update the field key when they become known.
export const UNKNOWN_FIELD_IDS = [DRAG_DROP_UNKNOWN_FIELD_ID];

export const FIELD_TYPE_MAP_OLD: Record<string, string> = {
	[ASSIGNEE_TYPE]: 'singleUserPicker',
	[FIX_VERSIONS_TYPE]: 'versions',
	[VERSION_CF_TYPE]: 'versions',
	[ORGANIZATIONS_CF_TYPE]: 'organizations',
	[SPRINT_TYPE]: 'link',
	[MULTI_VERSION_CF_TYPE]: 'multiVersion',
	[TEXT_AREA_CF_TYPE]: 'richText',
	[TEXT_CF_TYPE]: 'singleLineText',
	[SELECT_CF_TYPE]: 'singleSelect',
	[MULTI_CHECKBOXES_CF_TYPE]: 'checkbox',
	[MULTI_SELECT_CF_TYPE]: 'multiSelect',
	[USER_CF_TYPE]: 'singleUser',
	[PEOPLE_CF_TYPE]: 'multiUser',
	[RADIO_BUTTONS_CF_TYPE]: 'radio',
	[URL_CF_TYPE]: 'url',
	[GROUP_CF_TYPE]: 'singleGroup',
	[MULTI_GROUP_CF_TYPE]: 'multiGroup',
};

// These constants are used by the data team for analytics purposes, and should not be changed without approval
// See https://hello.atlassian.net/wiki/spaces/APDS/pages/2880244762/One+Update+Event+to+Rule+Them+All+Detailed+Spec for more details
export const FIELD_TYPE_MAP: Record<string, string> = {
	[AFFECTS_VERSIONS_TYPE]: 'affectsVersions',
	[ASSIGNEE_TYPE]: 'singleUserPicker',
	[ATTACHMENT_TYPE]: 'attachment',
	[CASCADING_SELECT_CF_TYPE]: 'cascadingSelect',
	[COMPONENTS_TYPE]: 'components',
	[DATE_CF_TYPE]: 'date',
	[DATETIME_CF_TYPE]: 'dateTime',
	[DESCRIPTION_TYPE]: 'richText',
	[DUE_DATE_TYPE]: 'date',
	[FIX_VERSIONS_TYPE]: 'fixVersions',
	[FLAGGED_CF_TYPE]: 'flag',
	[GOALS_CF_TYPE]: 'goals',
	[GROUP_CF_TYPE]: 'singleGroup',
	[ISSUE_TYPE]: 'issuetype',
	[LABELS_CF_TYPE]: 'labels',
	[MULTI_CHECKBOXES_CF_TYPE]: 'checkbox',
	[MULTI_GROUP_CF_TYPE]: 'multiGroup',
	[MULTI_SELECT_CF_TYPE]: 'multiSelect',
	[MULTI_USER_CF_TYPE]: 'multiUser',
	[MULTI_VERSION_CF_TYPE]: 'multiVersion',
	[NUMBER_CF_TYPE]: 'float',
	[ORGANIZATIONS_CF_TYPE]: 'organizations',
	[PARENT_TYPE]: 'parent',
	[PEOPLE_CF_TYPE]: 'multiUser',
	[PRIORITY_TYPE]: 'priority',
	[PROJECT_PICKER_CF_TYPE]: 'project',
	[RADIO_BUTTONS_CF_TYPE]: 'radio',
	[RANK_CF_TYPE]: 'rank',
	[START_DATE_TYPE]: 'date',
	[SELECT_CF_TYPE]: 'singleSelect',
	[SPRINT_TYPE]: 'sprint',
	[STATUS_TYPE]: 'status',
	[STORY_POINT_ESTIMATE_CF_TYPE]: 'storyPointEstimate',
	[STORY_POINTS_TYPE]: 'storyPointEstimate',
	[SUMMARY_TYPE]: 'singleLineText',
	[TEAMS_PLATFORM_CF_TYPE]: 'team',
	[TEXT_AREA_CF_TYPE]: 'richText',
	[TEXT_CF_TYPE]: 'singleLineText',
	[TIME_ESTIMATE_TYPE]: 'originalTimeEstimate',
	[TIME_TRACKING_TYPE]: 'timeTracking',
	[URL_CF_TYPE]: 'url',
	[USER_CF_TYPE]: 'singleUser',
	[VERSION_CF_TYPE]: 'version',
	[VOTES_TYPE]: 'votes',
	[WATCHES_TYPE]: 'watchers',
};

export const ENTITY_FIELD_KEYS = {
	WEB_LINK: 'weblink',
	ATTACHMENT: 'attachment',
	ISSUE_LINKS: 'linkIssue',
	PARENT: 'parent',
} as const;
