/** @jsx jsx */
import React, { type ComponentType, type ReactNode } from 'react';
import { jsx, styled } from '@compiled/react';
import { EntryPointContainer, type EntryPoint, type EntryPointComponent } from 'react-relay';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import type { ErrorBoundaryFallbackComponent } from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useHorizontalNavComponent } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/controllers/nav-component-controller/index.tsx';
import { NavErrorBoundary } from '@atlassian/jira-horizontal-nav-error-boundary/src/ui/js-error-boundary/index.tsx';
import { usePageLayoutEntryPoint } from '@atlassian/jira-entry-point-page-layout/src/index.tsx';
import { HORIZONTAL_NAVIGATION } from '@atlassian/jira-entry-point-page-layout-fields/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { EntryPointReferencePlaceholder } from '@atlassian/jira-entry-point-placeholder/src/index.tsx';
import { HorizontalNavSkeleton } from '@atlassian/jira-horizontal-nav-common/src/ui/horizontal-nav-skeleton/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { ProjectViewNavigationSkipLink } from '../page-container/page-container-skip-links/index.tsx';

const NavErrorBoundaryFallback: ErrorBoundaryFallbackComponent = () =>
	// null = equivalent of "unmount"
	// We should do more graceful degradation.
	// Let's review this after dogfooding:
	// Tracked https://hello.jira.atlassian.cloud/browse/SEA-3059
	null;

const entrypointContainerProps = {};

export const HorizontalNav = () => {
	const HorizontalNavComponent = useHorizontalNavComponent();
	// Consume the horizontal nav entry point if defined, otherwise fallback to the old horizontal nav component
	const horizontalNavEntryPointReference =
		usePageLayoutEntryPoint<
			EntryPoint<
				EntryPointComponent<{}, {}, {}, { fallback: () => JSX.Element | undefined }>,
				{ fallback: () => JSX.Element | undefined }
			>
		>(HORIZONTAL_NAVIGATION);

	const horizontalNavFallback =
		horizontalNavEntryPointReference?.extraProps?.fallback || HorizontalNavSkeleton;

	if (horizontalNavEntryPointReference && getWillShowNav4()) {
		return (
			<>
				<NavErrorBoundary
					packageName="page-container"
					id="projectNavigationSkipLink"
					fallback="unmount"
				>
					<ProjectViewNavigationSkipLink />
				</NavErrorBoundary>
				<UFOSegment name="horizontal-nav-through-entry-point">
					<JSErrorBoundary
						fallback="unmount"
						id="horizontal-nav-through-entry-point"
						packageName="page-container"
					>
						<EntryPointReferencePlaceholder
							name="horizontal-nav-through-entry-point"
							fallback={horizontalNavFallback()}
							entryPointReference={horizontalNavEntryPointReference}
						>
							<EntryPointContainer
								entryPointReference={horizontalNavEntryPointReference}
								props={entrypointContainerProps}
							/>
						</EntryPointReferencePlaceholder>
					</JSErrorBoundary>
				</UFOSegment>
			</>
		);
	}

	return (
		HorizontalNavComponent && (
			<NavErrorBoundary
				packageName="page-container"
				id="spa-horizontal-nav"
				fallback={NavErrorBoundaryFallback}
			>
				<NavErrorBoundary
					packageName="page-container"
					id="projectNavigationSkipLink"
					fallback="unmount"
				>
					<ProjectViewNavigationSkipLink />
				</NavErrorBoundary>
				<HorizontalNavComponent />
			</NavErrorBoundary>
		)
	);
};

type PropsOld = {
	children: ReactNode;
	navComponent: ComponentType;
};

/**
 * @deprecated no longer used with jira_nav4
 */
export const HorizontalNavWrapper = ({
	children,
	navComponent: HorizontalNavComponent,
}: PropsOld) => {
	return (
		<ThemedContainer>
			<NavErrorBoundary
				packageName="page-container"
				id="spa-horizontal-nav"
				fallback={NavErrorBoundaryFallback}
			>
				<NavErrorBoundary
					packageName="page-container"
					id="projectNavigationSkipLink"
					fallback="unmount"
				>
					<ProjectViewNavigationSkipLink />
				</NavErrorBoundary>
				<HorizontalNavComponent />
			</NavErrorBoundary>

			{children}
		</ThemedContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ThemedContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: Tokens.ELEVATION_SURFACE,
	// When an image background is applied via the background css var the image
	// will be darkened in dark mode by blending with a black transparent background.
	backgroundBlendMode: 'darken',
	overflow: 'hidden',
});
