/**
 * @generated SignedSource<<785072f51d21d16958bb928643423d65>>
 * @relayHash 33626f82049926e0af6bb735ebc05da6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2a879e0600cdbac62dfd1ca3ebcdb31a9c6c6ec5059915ec1118fc00b8b38b1c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JswExpandableProjectOldQuery$variables = {
  noOfBoardsToFetch: number;
  projectId: string;
};
export type JswExpandableProjectOldQuery$data = {
  readonly jira: {
    readonly jiraProject: {
      readonly " $fragmentSpreads": FragmentRefs<"JswBoards">;
    };
  };
};
export type JswExpandableProjectOldQuery = {
  response: JswExpandableProjectOldQuery$data;
  variables: JswExpandableProjectOldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "noOfBoardsToFetch"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v5 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "JswExpandableProjectOldQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v2/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProject",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "noOfBoardsToFetch",
                        "variableName": "noOfBoardsToFetch"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "JswBoards"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProject"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "JswExpandableProjectOldQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              {
                "alias": "projectKey",
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "projectId"
              },
              {
                "kind": "ScalarField",
                "name": "projectType"
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "noOfBoardsToFetch"
                  }
                ],
                "concreteType": "JiraBoardConnection",
                "kind": "LinkedField",
                "name": "boards",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraBoardEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraBoard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          (v5/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "defaultNavigationItem",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "url"
                                  }
                                ],
                                "type": "JiraNavigationItem",
                                "abstractKey": "__isJiraNavigationItem"
                              },
                              (v7/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraFavouriteValue",
                            "kind": "LinkedField",
                            "name": "favouriteValue",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v3/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "boardType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "canEdit"
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__id"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "mostRecentlyViewedBoard",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "type": "JiraBoard"
                  },
                  (v7/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "name": "softwareBoardCount"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2a879e0600cdbac62dfd1ca3ebcdb31a9c6c6ec5059915ec1118fc00b8b38b1c",
    "metadata": {},
    "name": "JswExpandableProjectOldQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "33dadadc55cb4a4fdb3886a96ace3059";

export default node;
