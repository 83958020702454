import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { rapidboardLastVisitedProjectRedirectRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/rapidboardLastVisitedProjectRedirectRoute.tsx';
import { RedirectToLastVisitedProjectPage } from '@atlassian/jira-router-routes-common/src/ui/last-visited-project-page/index.tsx';

export const rapidboardLastVisitedProjectRedirectRouteEntry = createEntry(
	rapidboardLastVisitedProjectRedirectRoute,
	() => ({
		group: 'classic-software',
		component: RedirectToLastVisitedProjectPage,
		layout: genericProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},

		isRedirect: true,
		resources: [...getNavigationResources()],
		forPaint: [LazyAtlassianNavigation],
	}),
);
