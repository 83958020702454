import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { proformaIssueFormsGeneralRoute } from '@atlassian/jira-router-routes-proforma-routes/src/proformaIssueFormsGeneralRoute.tsx';
import ProformaIssueFormsGeneral, {
	LazyProformaIssueFormsGeneral,
} from './ui/issue-forms-general/index.tsx';

export const proformaIssueFormsGeneralRouteEntry = createEntry(
	proformaIssueFormsGeneralRoute,
	() => ({
		group: 'proforma',
		layout: globalLayout,
		resources: [...getNavigationResources()],
		component: ProformaIssueFormsGeneral,
		forPaint: [LazyAtlassianNavigation, LazyProformaIssueFormsGeneral],
		perfMetricKey: 'issue-forms-general',
	}),
);
