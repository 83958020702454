/**
 * @generated SignedSource<<85f01dd9a4cb6fbc8475a6a481fd9b0c>>
 * @relayHash 75f3bb306c6596d20be18db68be629b5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID aae4d7be0b1132780de81554c978143eee155583036b48e02a7d4324df80cc9c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarQuery } from './src_jiraBusinessCalendarQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "aae4d7be0b1132780de81554c978143eee155583036b48e02a7d4324df80cc9c",
    "metadata": {},
    "name": "src_jiraBusinessCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
