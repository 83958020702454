import type { IncomingMailServerExpiryStatusGetResponse } from '@atlassian/jira-router-resources-incoming-mail-expiry-status/src/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createStore,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

export const initialState: State = {
	isBannerShown: false,
	isAdmin: false,
	OauthTypes: ['GMAIL_OAUTH'],
};

export const actions = {
	onDismiss:
		(): Action<State> =>
		({ getState, setState }) => {
			setState({
				...getState(),
				isBannerShown: false,
			});
		},
	onStateFetch:
		({
			showBanner,
			isAdmin,
			OauthTypes = ['GMAIL_OAUTH'],
		}: IncomingMailServerExpiryStatusGetResponse): Action<State> =>
		({ getState, setState }) => {
			setState({
				...getState(),
				isBannerShown: showBanner && isAdmin,
				isAdmin,
				OauthTypes,
			});
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	name: 'mail-expiry-banner',
	initialState,
	actions,
});

export const useBannerActions = createActionsHook(Store);
export const useBannerState = createStateHook(Store);
