import isEqual from 'lodash/isEqual';
import type { Match, Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { calcRapidBoardResetStateV2, getApdexMatchObject, isValidRoute } from './utils/index.tsx';

type CheckResetPageProps = {
	currentMatch: Match;
	currentRoute: Route;
	nextMatch: Match;
	nextRoute: Route;
	action: 'PUSH' | 'REPLACE' | 'POP';
};

const isReplaceActionAndPIN = (action: 'PUSH' | 'REPLACE' | 'POP', route: Route): boolean =>
	action === 'REPLACE' && route.group === 'issue-navigator';

const isReplaceActionAndJPD = (action: 'PUSH' | 'REPLACE' | 'POP', route: Route): boolean =>
	action === 'REPLACE' &&
	route.name === 'polaris-ideas' &&
	fg('jpd-ignore-page-reset-on-route-replacing');

/**
 * Determines whether a page should reset upon navigation, factoring in specific conditions
 * related to route changes and user actions. This function aims to enhance user experience
 * by intelligently refreshing page content when necessary.
 */
export const createCheckShouldResetPage = () => {
	let rapidBoardPaths: Array<string> = [];
	return ({ currentMatch, currentRoute, nextMatch, nextRoute, action }: CheckResetPageProps) => {
		let shouldReset = true;
		if (
			isReplaceActionAndPIN(action, nextRoute) ||
			isReplaceActionAndJPD(action, nextRoute) ||
			currentRoute.isRedirect === true
		) {
			shouldReset = false;
		} else {
			const cleanMatch = getApdexMatchObject(currentMatch, currentRoute);
			const cleanNextMatch = getApdexMatchObject(nextMatch, nextRoute);
			shouldReset = !isEqual(cleanMatch, cleanNextMatch) && shouldReset;
			if (shouldReset) {
				// RapidboardPaths is being persisted here.
				// resetDone boolean is calculated in the util function.
				const [paths, resetBoolean] = calcRapidBoardResetStateV2(
					rapidBoardPaths,
					nextRoute.name,
					!!nextMatch.query.useStoredSettings,
				);
				rapidBoardPaths = paths;
				if (isValidRoute(nextRoute) || nextRoute.group === 'classic-software') {
					shouldReset = !resetBoolean;
				}
			}
		}
		return shouldReset;
	};
};
