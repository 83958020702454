import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessReportsOverviewRoute } from '@atlassian/jira-router-routes-business-reports-routes/src/jiraBusinessReportsOverviewRoute.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';
import { reportsOverviewPageEntryPoint as reportsOverviewPageEntry } from '@atlassian/jira-reports-overview-page/entrypoint.tsx';

const reportsOverviewPageEntryPoint = createPageEntryPoint({
	main: reportsOverviewPageEntry,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

export const jiraBusinessReportsOverviewRouteEntry = createEntry(
	jiraBusinessReportsOverviewRoute,
	() => ({
		group: 'classic-business',
		ufoName: 'jwm.reports-overview',

		entryPoint() {
			return reportsOverviewPageEntryPoint;
		},

		layout: businessProjectLayout,
		skeleton: ThemedLazySkeleton,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			// delete when cleaning up getWillShowNav4
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavJira,
		},

		resources: getProjectViewResources(),
	}),
);
