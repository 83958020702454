import { fg } from '@atlassian/jira-feature-gating';

export const createTitleFromTemplate = (title: string, template: string) =>
	template.replace(/%s/, title);

export const ripTitleTemplateFromMeta = (getMeta: (arg1: string) => string | null) =>
	getMeta('ajs-app-title');

export const checkDocumentTitle = (
	symbol: symbol,
	contextArray: (TitleContextObject | symbol)[],
) => {
	const lastDocumentTitle = contextArray[contextArray.length - 1];
	if (fg('jira-concurrent-document-title')) {
		if (typeof lastDocumentTitle === 'object' && lastDocumentTitle.symbol === symbol) {
			return true;
		}

		return false;
	}

	if (lastDocumentTitle === symbol) {
		return true;
	}

	return false;
};

export type TitleContextObject = {
	title: string;
	symbol: symbol;
};

export type State = {
	title: string;
	defaultTitle: string;
	contextArray: (TitleContextObject | symbol)[];
};

export type DocumentTitleContainerProps = {
	titleSuffix: string | null;
};
