import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessTimelineRoute } from '@atlassian/jira-router-routes-business-timeline-routes/src/jiraBusinessTimelineRoute.tsx';
import { timelineEntrypoint as legacyTimelineEntrypoint } from '@atlassian/jira-spa-apps-business-timeline/entrypoint.tsx';
import { timelineEntrypoint } from '@atlassian/jira-spa-apps-harmonised-business-timeline/entrypoint.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';

const timelinePageEntrypoint = createPageEntryPoint({
	main: timelineEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

const legacyTimelinePageEntrypoint = createPageEntryPoint({
	main: legacyTimelineEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

export const jiraBusinessTimelineRouteEntry = createEntry(jiraBusinessTimelineRoute, () => ({
	group: 'classic-business',
	ufoName: 'jwm.timeline-view',
	layout: businessProjectLayout,
	skeleton: ThemedLazySkeleton,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		// delete when cleaning up getWillShowNav4
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalNavJira,
	},

	resources: getProjectViewResources(),

	entryPoint: () =>
		/**
		 * IMPORTANT - we can't use `isJiraTimelineHarmonisationM1Enabled`
		 * here, as static route generation needs us to use `expValEquals`
		 * and `fg` directly. Any changes to this logic must be duplicated
		 * in `@atlassian/jira-business-timeline-rollout`
		 */
		// eslint-disable-next-line jira/ff/no-preconditioning
		expValEquals('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', true) &&
		fg('refactor_nin_to_jira_view_schema') &&
		expValEquals('jira_timeline_harmonisation_m1', 'isEnabled', true)
			? timelinePageEntrypoint
			: legacyTimelinePageEntrypoint,
}));
