/**
 * @generated SignedSource<<9d31a2a7e7aa24f0bb9199cbe5b83962>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Notifications$data = {
  readonly notifications: {
    readonly unseenNotificationCount: number;
  } | null | undefined;
  readonly " $fragmentType": "Nav4Notifications";
};
export type Nav4Notifications$key = {
  readonly " $data"?: Nav4Notifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Notifications">;
};

import Nav4NotificationsQuery_graphql from './Nav4NotificationsQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": Nav4NotificationsQuery_graphql
    }
  },
  "name": "Nav4Notifications",
  "selections": [
    {
      "concreteType": "InfluentsNotificationQuery",
      "kind": "LinkedField",
      "name": "notifications",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "unseenNotificationCount"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "d448e13b720687c07d75fad3fc7575b7";

export default node;
