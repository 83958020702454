import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareSummaryClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareSummaryClassicRoute.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	SummaryPage,
	LazySummaryView,
} from '@atlassian/jira-router-routes-software-summary-entries/src/ui/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { classicSoftwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { summarySoftwareEntrypoint } from '@atlassian/jira-spa-apps-summary/software/entrypoint.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';

const summarySoftwarePageEntrypoint = createPageEntryPoint({
	main: summarySoftwareEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

/**
 * @deprecated delete when cleaning up project_summary_page_entry_point_migration
 */
const getHorizontalNavResources = () =>
	fg('project_summary_page_entry_point_migration') ? [] : [horizontalNavJiraResource];

export const softwareSummaryClassicRouteEntry = createEntry(softwareSummaryClassicRoute, () => ({
	group: 'classic-software',
	ufoName: 'classic-summary',
	component: componentWithCondition(
		() => expVal('helios_summary_page_experiment', 'isEnabled', false),
		SummaryPage,
		ErrorPagesNotFound,
	),
	layout: classicSoftwareProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(), // these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// delete when cleaning up project_summary_page_entry_point_migration
		...getHorizontalNavResources(),
		// end of resources expanded from getNavigationSidebarProjectResource
		resourceWithCondition2(() => getIsSoftwareThemingEnabled(), projectThemeResource),
	],
	forPaint: [LazyAtlassianNavigation, LazySummaryView],

	entryPoint: () => {
		if (fg('project_summary_page_entry_point_migration')) {
			return summarySoftwarePageEntrypoint;
		}
	},
}));
