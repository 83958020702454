/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { type FunctionComponent, useCallback, useState } from 'react';

import { jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { cssMap, cx } from '@atlaskit/css';
import AkModalDialog, {
	ModalTransition as AkModalTransition,
	CloseButton,
	ModalBody,
} from '@atlaskit/modal-dialog';
import { Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { GlobalInviteProps, ModalDialogContentProps } from '../../types';

import { DEFAULT_TEST_ID, MODAL_DIALOG_TEST_ID, TRIGGER_ELEMENT_TEST_ID } from './constants';
import { messages } from './messages';

const styles = cssMap({
	close: {
		paddingTop: token('space.300', '24px'),
	},
});

// eslint-disable-next-line @typescript-eslint/ban-types
export function withModalDialog(Content: FunctionComponent<ModalDialogContentProps>) {
	return function ModalDialog({
		testId = DEFAULT_TEST_ID,
		triggerElement: TriggerElement,
		children,
		...props
	}: GlobalInviteProps) {
		const { formatMessage } = useIntl();

		const [isModalDialogOpen, setIsModalDialogOpen] = useState<boolean>(false);

		const openModalDialog = useCallback(() => {
			setIsModalDialogOpen(true);
		}, []);

		const closeModalDialog = useCallback(() => {
			setIsModalDialogOpen(false);
		}, []);

		const focusDialog = useCallback((node: HTMLElement) => {
			// Focus the dialog's container when it finishes opening
			node.focus();
		}, []);

		return (
			<div data-testid={testId}>
				{TriggerElement ? (
					<TriggerElement onClick={openModalDialog} />
				) : (
					<Button
						shouldFitContainer
						onClick={openModalDialog}
						testId={`${testId}--${TRIGGER_ELEMENT_TEST_ID}`}
					>
						{children}
					</Button>
				)}

				<AkModalTransition>
					{isModalDialogOpen ? (
						<AkModalDialog
							width="small"
							shouldScrollInViewport
							shouldReturnFocus
							onClose={closeModalDialog}
							onOpenComplete={focusDialog}
							label={formatMessage(messages.modalDialogLabel)}
							testId={`${testId}--${MODAL_DIALOG_TEST_ID}`}
						>
							<ModalBody>
								<Flex justifyContent="end" xcss={cx(styles.close)}>
									<CloseButton onClick={closeModalDialog} />
								</Flex>
								<Content closeModalDialog={closeModalDialog} {...props} />
							</ModalBody>
						</AkModalDialog>
					) : null}
				</AkModalTransition>
			</div>
		);
	};
}
