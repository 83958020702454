import { fg } from '@atlassian/jira-feature-gating';
import { getIsNav4UserOptOut } from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';

// All dogfooding feature gates will remain in dogfooding and not advance.
// They will allow the dogfooding audience to continue to have nav4 while other cohorts are enabled or disabled.
// We don't inline feature gates as our logic to show nav4 is not trivial and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design#Decision-points
export const getWillShowNav4ForDogfooding = () => {
	if (!fg('jira_nav4_dogfooding')) return false;

	// eslint-disable-next-line jira/ff/inline-usage
	const phase1 = fg('jira_nav4_dogfooding_phase-1');
	// eslint-disable-next-line jira/ff/inline-usage
	const phase2 = fg('jira_nav4_dogfooding_phase-2');
	const phase3 = !(phase1 || phase2);

	return phase3 || (phase2 && !getIsNav4UserOptOut()) || (phase1 && !getIsNav4UserOptOut());
};
