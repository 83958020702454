import { createResource } from '@atlassian/react-resource-router';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { DismissData } from '@atlassian/jira-automation-discoverability/src/common/types.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';

const PROPERTY_KEY = 'jira.user.issue.automation-discoverability.panel.state';

export const getAutomationDiscoveryDismissResource = createResource<DismissData>({
	type: 'getAutomationDiscoveryDismiss',
	getKey: () => 'getAutomationDiscoveryDismiss',
	getData: async (): Promise<DismissData> => {
		try {
			const res = await performGetRequest(`/rest/api/3/mypreferences?key=${PROPERTY_KEY}`);
			return res;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error.statusCode === NOT_FOUND) {
				return null;
			}
			fireErrorAnalytics({
				error,
				meta: {
					id: 'getDismissData',
					teamName: 'ITOps Phantom',
					packageName: 'jiraEocAutomationDiscovery',
				},
			});
			throw error;
		}
	},
	maxAge: 5 * 60 * 1000,
});
