import cond from 'lodash/cond';
import constant from 'lodash/constant';
import otherwise from 'lodash/stubTrue';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { isMobileAndInMvpOrExistingUsersExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import {
	getIsAdmin,
	getIsNav4TenantOptIn,
	getIsNav4UserOptIn,
	getIsNav4UserOptOut,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';
import type { Nav4Rollout } from '../../types.tsx';
import { NAV4_ROLLOUT_MOBILE, NAV4_ROLLOUT_NONE } from '../../constants.tsx';
import { getNav4RolloutForBetaCohort } from './get-nav4-rollout-for-beta/index.tsx';
import { getNav4RolloutForDogfooding } from './get-nav4-rollout-for-dogfooding/index.tsx';
import { getNav4RolloutForEap } from './get-nav4-rollout-for-eap/index.tsx';

// eslint-disable-next-line jira/ff/inline-usage
export const getNav4Rollout = cond<Nav4Rollout>([
	[() => !fg('jira_nav4'), constant(NAV4_ROLLOUT_NONE)],
	[() => fg('jira_nav4_dogfooding'), getNav4RolloutForDogfooding],
	[() => fg('jira_nav4_eap'), getNav4RolloutForEap],
	[
		() => fg('jira_nav4_beta_existing-customers'),
		getNav4RolloutForBetaCohort('existing-customers'),
	],
	[() => fg('jira_nav4_beta_sandboxes'), getNav4RolloutForBetaCohort('sandboxes')],
	[
		() => expVal('jira_nav4_beta_free-customers', 'willShowNav4', false),
		getNav4RolloutForBetaCohort('free-customers'),
	],
	[
		() => expVal('jira_nav4_beta_new-customers', 'willShowNav4', false),
		getNav4RolloutForBetaCohort('new-customers'),
	],
	[
		() => expVal('confluence_jira_nav4_new_customers', 'willShowNav4', false),
		getNav4RolloutForBetaCohort('new-customers-x'),
	],
	// Later GA phases eclipse earlier phases.
	[() => fg('jira_nav4_ga_phase-3'), () => ({ stage: 'ga', phase: 3 })],
	[
		() => fg('jira_nav4_ga_phase-2'),
		() => ({ stage: 'ga', phase: 2, isUserOptOut: getIsNav4UserOptOut() }),
	],
	[
		() => fg('jira_nav4_ga_phase-1'),
		() => ({
			stage: 'ga',
			phase: 1,
			isAdmin: getIsAdmin(),
			isTenantOptIn: getIsNav4TenantOptIn(),
			isUserOptIn: getIsNav4UserOptIn(),
			isUserOptOut: getIsNav4UserOptOut(),
		}),
	],
	[
		() => fg('jira_nav4_ga_sandboxes'),
		() => ({ stage: 'ga', cohort: 'sandboxes', isUserOptIn: getIsNav4UserOptIn() }),
	],
	[() => isMobileAndInMvpOrExistingUsersExperiment(), constant(NAV4_ROLLOUT_MOBILE)],
	[otherwise, constant(NAV4_ROLLOUT_NONE)],
]);
