export const PACKAGE_NAME = '@atlassian/jira-trial-sprint-board';
export const TEAM_NAME = 'growth-tako';
export const ANALYTICS_SOURCE_NAME = 'trialSprintBoard';
export const TRAIT_NAME = 'jira_premium_sample_project_id';
export const STARGATE_URL = '/gateway/api';

export const FIRST_MODAL_MESSAGE_ID = 'prj-trial-sprint-board-v2-msg-1';
export const SECOND_MODAL_MESSAGE_ID = 'prj-trial-sprint-board-v2-msg-2';
export const THIRD_MODAL_MESSAGE_ID = 'prj-trial-sprint-board-v2-msg-3';
export const TSB_LOCAL_STORAGE_PROVIDER_KEY = 'trial-sprint-board';
export const TSB_MODAL_DISMISSED_LOCAL_STORAGE_KEY = 'trial-sprint-board-dismissed';
