import { TEAM_MANAGED_BACKLOG } from '@atlassian/jira-capabilities/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import {
	forgeBacklogModuleResource,
	forgeProjectModuleResource,
	forgeSprintModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { backlogResource } from '@atlassian/jira-router-resources-next-gen-backlog/src/services/backlog/index.tsx';
import { customFiltersResource } from '@atlassian/jira-router-resources-next-gen-backlog/src/services/custom-filters/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { softwareBacklogEmbedRoute } from '@atlassian/jira-router-routes-software-backlog-routes/src/softwareBacklogEmbedRoute.tsx';
import { BacklogPage, LazyBacklogPage } from './ui/BacklogPage.tsx';

export const softwareBacklogEmbedRouteEntry = createEntry(softwareBacklogEmbedRoute, () => ({
	group: 'software',
	component: BacklogPage,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-backlog'),
	layout: createLayout({
		isChromeless: true,
	}),
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		backlogResource,
		customFiltersResource,
		forgeBacklogModuleResource,
		forgeSprintModuleResource,
		themePreferenceResource,
	],
	afterPaint: [LazyIssueApp],
	forPaint: [LazyBacklogPage],
	ufoName: 'next-gen-backlog-embed',
	meta: {
		capability: TEAM_MANAGED_BACKLOG,
	},
}));
