import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { servicedeskCmdbInsightRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import LandingPage, { LazyServicedeskCmdbLandingPage } from './ui/landing-page/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbInsightRouteEntry = createEntry(servicedeskCmdbInsightRoute, () => ({
	group: 'servicedesk',
	layout: globalLayout,
	navigation: { menuId: MENU_ID.INSIGHT },
	component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : LandingPage,
	resources: getCmdbResources(),
	forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbLandingPage],
	perfMetricKey: 'service-management.insight-cmdb.interact-with-landing-page',
}));
