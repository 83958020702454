/** @jsx jsx */
import { useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import differenceBy from 'lodash/differenceBy';
import { Box, Inline } from '@atlaskit/primitives';
import Select, {
	CheckboxOption,
	components,
	useAsync,
	type SelectProps,
	type StylesConfig,
} from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import type { GroupBase } from '@atlaskit/react-select';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { ProjectOption } from './types.tsx';
import { loadProjectsOptions } from './utils.tsx';

export const ProjectSelect = ({
	components: customComponents,
	...props
}: SelectProps<ProjectOption, true>) => {
	const { formatMessage } = useIntl();
	const { options, ...selectProps } = useAsync<ProjectOption, true, GroupBase<ProjectOption>, {}>({
		loadOptions: (inputValue) => loadProjectsOptions({ query: inputValue }),
		cacheOptions: true,
		defaultOptions: true,
	});

	const finalOptions = useMemo(() => {
		const allOptions: GroupBase<ProjectOption>[] = [];
		const currentValue: ProjectOption[] = Array.isArray(props.value) ? props.value : [];

		if (currentValue.length > 0) {
			allOptions.push({
				options: currentValue,
			});
		}

		if (Array.isArray(options) && options.length > 0) {
			allOptions.push({
				options: differenceBy(options, currentValue, 'value'),
			});
		}

		return allOptions;
	}, [props.value, options]);

	return (
		<Select<ProjectOption, true>
			{...selectProps}
			options={finalOptions}
			placeholder={formatMessage(messages.selectFieldPlaceholder)}
			components={{
				Option: ({ children, ...rest }) => (
					<CheckboxOption {...rest}>
						<Box paddingInlineStart="space.100">
							<Inline space="space.100" alignBlock="center">
								<img css={projectAvatarStyles} alt={rest.data.label} src={rest.data.imageUrl} />
								{children}
							</Inline>
						</Box>
					</CheckboxOption>
				),
				MultiValueLabel: ({ children, ...rest }) => (
					<components.MultiValueLabel {...rest}>
						<Inline space="space.050" alignBlock="center">
							<img css={projectAvatarStyles} alt={rest.data.label} src={rest.data.imageUrl} />
							{children}
						</Inline>
					</components.MultiValueLabel>
				),
				...customComponents,
			}}
			closeMenuOnSelect={false}
			hideSelectedOptions={false}
			isMulti
			styles={stylesConfig}
			{...props}
		/>
	);
};

const projectAvatarStyles = css({
	width: token('space.200'),
	height: token('space.200'),
	borderRadius: token('border.radius.050'),
});

const stylesConfig: StylesConfig<ProjectOption, true> = {
	menuList: (styles) => ({
		...styles,
		padding: 0,
	}),
	group: (styles) => ({
		...styles,
		padding: `${token('space.075')} 0 ${token('space.075')} 0`,
		':not(:first-of-type)': {
			borderTop: `1px solid ${token('color.border')}`,
		},
	}),
};
