import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/DashboardsContentViewQuery$parameters';
import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const nav4DashboardsContentEntryPoint = createEntryPoint({
	root: JSResourceForUserVisible(() =>
		import(
			/* webpackChunkName: "nav4-dashboards-content-entry-point" */ './DashboardsContentViewQuery'
		).then(({ DashboardsContentViewQuery }) => DashboardsContentViewQuery),
	),
	getPreloadProps: (params: EntryPointRouteParams) => {
		const cloudId = params.tenantContext?.cloudId || '';

		return {
			queries: {
				queryRef: {
					parameters,
					variables: {
						cloudId,
					},
				},
			},
		};
	},
});
