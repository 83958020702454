import memoize from 'lodash/memoize';
import getMeta from '@atlassian/jira-get-meta';
import {
	IS_ADMIN,
	IS_NAV4_TENANT_OPT_IN,
	IS_NAV4_TENANT_OPT_OUT,
	IS_NAV4_USER_OPT_IN,
	IS_NAV4_USER_OPT_OUT,
} from './constants.tsx';

// Only read each meta tag once using `memoize`.
export const getMetaBoolean = memoize((name: string) => getMeta(`ajs-${name}`) === 'true');

export const getIsAdmin = () => getMetaBoolean(IS_ADMIN);
export const getIsNav4TenantOptIn = () => getMetaBoolean(IS_NAV4_TENANT_OPT_IN);
export const getIsNav4TenantOptOut = () => getMetaBoolean(IS_NAV4_TENANT_OPT_OUT);
export const getIsNav4UserOptIn = () => getMetaBoolean(IS_NAV4_USER_OPT_IN);
export const getIsNav4UserOptOut = () => getMetaBoolean(IS_NAV4_USER_OPT_OUT);
