export const LEGACY_PERSONAL_SETTINGS_PATH = '/secure/ViewPersonalSettings.jspa';

export const BASE_PERSONAL_SETTINGS_PATH = '/jira/settings/personal';

export const GENERAL_PERSONAL_SETTINGS_PATH = `${BASE_PERSONAL_SETTINGS_PATH}/general`;

export const NOTIFICATIONS_PERSONAL_SETTINGS_PATH = `${BASE_PERSONAL_SETTINGS_PATH}/notifications`;

export const DIGEST_NOTIFICATIONS_PERSONAL_SETTINGS_PATH = `${BASE_PERSONAL_SETTINGS_PATH}/digests`;

export const APPS_PERSONAL_SETTINGS_PATH = `${BASE_PERSONAL_SETTINGS_PATH}/apps`;

export const OPS_NOTIFICATIONS_PERSONAL_SETTINGS_PATH = `${BASE_PERSONAL_SETTINGS_PATH}/alert-notifications`;

export function getGeneralPersonalSettingsPath() {
	return GENERAL_PERSONAL_SETTINGS_PATH;
}

export function getOpsPersonalSettingsPath() {
	return OPS_NOTIFICATIONS_PERSONAL_SETTINGS_PATH;
}

export function getNotificationsPersonalSettingsPath() {
	return NOTIFICATIONS_PERSONAL_SETTINGS_PATH;
}

export function getAppsPersonalSettingsPath() {
	return APPS_PERSONAL_SETTINGS_PATH;
}
