import React, { useMemo, useCallback, type Ref, useEffect } from 'react';
import OriginTracing from '@atlassiansox/origin-tracing';
import { SpotlightTarget } from '@atlaskit/onboarding';
import Tooltip from '@atlaskit/tooltip';
import {
	EditionAwarenessButton,
	EditionAwarenessLinkButton,
} from '@atlassian/growth-pattern-library-edition-awareness-button';
import { useCommerceExperienceDrawer } from '@atlassian/jira-commerce-experience-drawer/src/ui/index.tsx';
import { useCommerceExperienceEmbedExperiment } from '@atlassian/jira-commerce-experience-drawer/src/ui/utils.tsx';

import { convertToJiraProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useJsmPremiumPillExperiment } from '@atlassian/jira-edition-awareness-dropdown/src/common/utils.tsx';
import { WAC_URL } from '@atlassian/jira-external-urls/src/constants.tsx';
import { getAdminBaseUrl } from '@atlassian/jira-external-urls/src/utils/get-admin-base-url/index.tsx';
import { UNSAFE_noExposureExp, expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { editionAwarenessTrialModalEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-trial-modal/entrypoint.tsx';
import { isEligibleForJsmJpdTrialAwarenessV2 } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-trial-modal/src/common/utils.tsx';
import {
	getTrialDaysLeft,
	isInGracePeriod,
	useLoggedInPage,
} from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils/src/utils.tsx';
import {
	ContextualAnalyticsData,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getApplicationKeyForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import {
	PREMIUM_EDITION,
	STANDARD_EDITION,
	type ApplicationEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { EACommonAnalyticsAttributes } from '../types.tsx';

import { BillingDetailsPopup } from './billing-details-popup/main.tsx';
import type { TriggerProps } from './billing-details-popup/types.tsx';
import { productAbbreviations } from './constants.tsx';
import { Dropdown, type DropdownTriggerProps } from './dropdown/index.tsx';
import type { TrialPillButtonProps } from './types.tsx';

type TrialButtonTextProps = {
	inGracePeriod: boolean;
	trialDaysLeft?: number;
	edition: ApplicationEdition;
	trialCountdownExperimentEnabled?: boolean;
	trialLengthDays?: number | null;
};

type TooltipTextProps = {
	showAddPaymentDetails: boolean;
};

const TrialButtonText = ({
	inGracePeriod,
	trialDaysLeft,
	edition,
	trialCountdownExperimentEnabled,
	trialLengthDays,
}: TrialButtonTextProps) => {
	if (inGracePeriod) {
		return (
			<FormattedMessage
				id="navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.add-payment-details-sentence-case"
				defaultMessage="Add payment details"
				description="Button title prompting to add payment details"
			/>
		);
	}

	// Trial Countdown Experiment
	// If trial is still in its first half, show trial message instead of trial days left
	const shouldShowTrialMessage =
		trialCountdownExperimentEnabled &&
		!!trialDaysLeft &&
		!!trialLengthDays &&
		trialDaysLeft > Math.ceil(trialLengthDays / 2);
	if (shouldShowTrialMessage) {
		if (edition === PREMIUM_EDITION) {
			return (
				<FormattedMessage
					id="navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.premium-trial"
					defaultMessage="Premium trial"
					description="Button title informing the user is on a premium trial"
				/>
			);
		}
		if (edition === STANDARD_EDITION) {
			return (
				<FormattedMessage
					id="navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.standard-trial"
					defaultMessage="Standard trial"
					description="Button title informing the user is on a standard trial"
				/>
			);
		}
	}

	return (
		<FormattedMessage
			id="navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.days-left-button"
			defaultMessage="{trialDaysLeft, plural, one {# day} other {# days}} left"
			description="Trial information button title showing how many days is left in trial"
			values={{
				trialDaysLeft,
			}}
		/>
	);
};

const TooltipText = ({ showAddPaymentDetails }: TooltipTextProps) => {
	return showAddPaymentDetails ? (
		<FormattedMessage
			id="navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.add-payment-details-tooltip"
			defaultMessage="Add payment details to keep your subscription"
			description="Tooltip for add payment details button"
		/>
	) : (
		<FormattedMessage
			id="navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.trial-button-tooltip"
			defaultMessage="More about your trial and how to pay"
			description="Tooltip for trial information button"
		/>
	);
};

const TrialPillButtonInner = ({
	productKey,
	projectType,
	edition,
	entitlementDetails,
	invoiceGroupId,
	transactionAccountId,
	isBillingAdmin,
	trialLengthDays,
	preDunningStatus,
}: TrialPillButtonProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const locale = useLocale();
	const environment = useEnvironment();
	const cloudId = useCloudId();
	const isSiteAdmin = useIsSiteAdmin();
	const { trialEndTime, billingSourceSystem } = entitlementDetails;
	const atlOrigin = useMemo(() => new OriginTracing({ product: 'jira' }), []);

	const currentDate = useMemo(() => Date.now(), []);
	const trialDaysLeft = getTrialDaysLeft(currentDate, trialEndTime);
	const inGracePeriod = fg('jira_edition_awareness_isgraceperiod_check_fix')
		? preDunningStatus === 'IN_PRE_DUNNING'
		: isInGracePeriod(currentDate, trialEndTime);

	const isEnglishLocale = locale.startsWith('en');
	const isJswStandard = productKey === 'jira-software' && edition === STANDARD_EDITION;

	// User sees JSTO if they are a billing and site admin, and in a JSW standard trial that is not in grace period
	const jstoTarget = !!isBillingAdmin && isSiteAdmin && isJswStandard && !inGracePeriod;
	const shouldShowJsmJpdTrialAwarenessModalV2 = isEligibleForJsmJpdTrialAwarenessV2(projectType);

	const rawJstoUrl = `${
		WAC_URL[environment]
	}/software/jira?ref=edition_awareness_standard_trial_${billingSourceSystem.toLowerCase()}&cloudSiteId=${cloudId}`;
	const jstoUrl = useLoggedInPage({ pageUrl: rawJstoUrl });

	const addBillingDetailsUrl =
		billingSourceSystem === 'CCP'
			? `${getAdminBaseUrl()}/billing/${transactionAccountId}/${invoiceGroupId}/payment-flow?referrer=${productAbbreviations[productKey]}`
			: `${getAdminBaseUrl()}/s/${cloudId}/billing/paymentdetails/add`;

	// Trial Countdown (TC) Experiment
	const [trialCountdownExperimentConfig, fireTrialCountdownExperimentExposure] =
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		UNSAFE_noExposureExp('jira_edition_awareness_trial_countdown');
	// TC Statsig value
	const trialCountdownExperimentValue = trialCountdownExperimentConfig.get<boolean | undefined>(
		'isEnabled',
		undefined,
	);
	// User is eligible for TC experiment if the FG is on, they are not in grace period, and are on CCP
	const isEligibleForTrialCountdownExperiment =
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_edition_awareness_trial_countdown_gate') &&
		!inGracePeriod &&
		!!trialDaysLeft &&
		!!trialLengthDays &&
		billingSourceSystem === 'CCP';
	// TC Experiment Features are enabled when they are eligible and in variation (isEnabled = true)
	const trialCountdownExperimentEnabled =
		isEligibleForTrialCountdownExperiment && trialCountdownExperimentValue;

	// Additional TC experiment analytics attributes for eligible users
	const trialCountdownExperimentAnalyticsAttributes = useMemo(
		() =>
			isEligibleForTrialCountdownExperiment
				? {
						trialCountdownExperimentEnabled,
						trialLengthDays,
						trialDaysLeft,
					}
				: {},
		[
			isEligibleForTrialCountdownExperiment,
			trialCountdownExperimentEnabled,
			trialLengthDays,
			trialDaysLeft,
		],
	);

	// Fires TC experiment exposure for eligible users
	useEffect(() => {
		if (isEligibleForTrialCountdownExperiment) {
			fireTrialCountdownExperimentExposure();
		}
	}, [fireTrialCountdownExperimentExposure, isEligibleForTrialCountdownExperiment]);

	const { getIsEligibleForJsmPremiumPillExperiment } = useJsmPremiumPillExperiment();
	const isJSMPremiumPillExperiment =
		getIsEligibleForJsmPremiumPillExperiment() &&
		expValEquals('jsm_premium_trial_pill_experiment', 'cohort', 'variation');

	const analyticsAttributes: EACommonAnalyticsAttributes = useMemo(() => {
		const attributes: EACommonAnalyticsAttributes = {
			edition,
			productKey,
			projectType,
			isEnglishLocale,
			entitlementDetails,
			transactionAccountId,
			invoiceGroupId,
			isBillingAdmin,
			...trialCountdownExperimentAnalyticsAttributes,
		};

		return attributes;
	}, [
		edition,
		entitlementDetails,
		invoiceGroupId,
		isEnglishLocale,
		productKey,
		projectType,
		transactionAccountId,
		isBillingAdmin,
		trialCountdownExperimentAnalyticsAttributes,
	]);

	const {
		fireExposure: fireCommerceEmbedExperimentExposure,
		isEligible: isEligibleForCommerceEmbedExperiment,
		isVariation: isVariationInCommerceEmbedExperiment,
	} = useCommerceExperienceEmbedExperiment({
		isCcpBillingSourceSystem: billingSourceSystem === 'CCP',
		isJstoTarget: jstoTarget,
	});

	const getUrl = () => {
		if (jstoTarget) {
			return atlOrigin.addToUrl(jstoUrl);
		}
		if (inGracePeriod) {
			return atlOrigin.addToUrl(addBillingDetailsUrl);
		}
		return undefined;
	};
	const url = getUrl();

	const [_, commerceExperienceDrawerActions] = useCommerceExperienceDrawer();

	const onClick = useCallback(() => {
		const target = jstoTarget ? 'JSTO' : 'BillingScreen';

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'editionAwarenessTrialPill',
			{
				...analyticsAttributes,
				...(jstoTarget || inGracePeriod
					? {
							target,
							...atlOrigin.toAnalyticsAttributes({ hasGeneratedId: true }),
						}
					: {}),
				...(shouldShowJsmJpdTrialAwarenessModalV2
					? {
							jsmJpdTrialAwarenessModalV2: {
								shouldShowJsmJpdTrialAwarenessModalV2,
							},
						}
					: {}),
				isEligibleForCommerceEmbedExperiment,
				isVariationInCommerceEmbedExperiment,
			},
		);
	}, [
		inGracePeriod,
		createAnalyticsEvent,
		isEligibleForCommerceEmbedExperiment,
		jstoTarget,
		analyticsAttributes,
		atlOrigin,
		shouldShowJsmJpdTrialAwarenessModalV2,
		isVariationInCommerceEmbedExperiment,
	]);

	const commerceEmbedExperimentOnClick = useCallback(() => {
		if (isEligibleForCommerceEmbedExperiment) {
			fireCommerceEmbedExperimentExposure();
		}

		if (isVariationInCommerceEmbedExperiment) {
			commerceExperienceDrawerActions.openDrawer(url || '');
		}
	}, [
		commerceExperienceDrawerActions,
		fireCommerceEmbedExperimentExposure,
		isEligibleForCommerceEmbedExperiment,
		isVariationInCommerceEmbedExperiment,
		url,
	]);

	useEffect(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'viewed',
				actionSubject: 'button',
			}),
			'editionAwarenessTrialPill',
			{
				...analyticsAttributes,
				isSiteAdmin,
			},
		);
	}, [analyticsAttributes, createAnalyticsEvent, isSiteAdmin]);

	const trialModalEntryPointParams = useMemo(
		() => ({
			cloudId,
			productKey,
			jiraProjectType: convertToJiraProjectType(projectType),
			jiraApplicationKey: getApplicationKeyForProject(projectType),
		}),
		[cloudId, productKey, projectType],
	);
	const trialModalEntryPointProps = useMemo(
		() => ({ projectType, edition }),
		[projectType, edition],
	);

	// Trial Countdown Experiment
	// If trial is 3 days or less, swap the button to the warning state
	const shouldShowWarningStatus = trialCountdownExperimentEnabled && trialDaysLeft <= 3;
	const buttonStatus = shouldShowWarningStatus ? 'warning' : 'default';

	if (isJSMPremiumPillExperiment) {
		return (
			<Dropdown
				applicationEdition={PREMIUM_EDITION}
				productKey={productKey}
				analyticsAttributes={analyticsAttributes}
				trialEndTime={trialEndTime}
			>
				{(triggerProps: DropdownTriggerProps) => (
					<EditionAwarenessButton
						{...triggerProps.triggerProps}
						onClick={(...args) => {
							onClick();
							triggerProps.onClick && triggerProps.onClick(...args);
						}}
						ref={triggerProps.ref}
						status={buttonStatus}
						upgradeIconType="gem"
					>
						<TrialButtonText
							inGracePeriod={inGracePeriod}
							trialDaysLeft={trialDaysLeft}
							edition={edition}
							trialCountdownExperimentEnabled={trialCountdownExperimentEnabled}
							trialLengthDays={trialLengthDays}
						/>
					</EditionAwarenessButton>
				)}
			</Dropdown>
		);
	}

	const shouldShowLinkButton = url !== undefined;
	const shouldShowCommerceEmbedExperimentButton =
		shouldShowLinkButton && isVariationInCommerceEmbedExperiment;

	if (shouldShowCommerceEmbedExperimentButton) {
		return (
			<Tooltip content={<TooltipText showAddPaymentDetails={inGracePeriod} />} position="bottom">
				{(tooltipProps) => (
					<EditionAwarenessButton
						{...tooltipProps}
						onClick={() => {
							onClick();
							commerceEmbedExperimentOnClick();
						}}
						status={buttonStatus}
						{...(inGracePeriod ? { icon: 'missing-payment-details' } : { upgradeIconType: 'gem' })}
					>
						<TrialButtonText
							inGracePeriod={inGracePeriod}
							trialDaysLeft={trialDaysLeft}
							edition={edition}
							trialCountdownExperimentEnabled={trialCountdownExperimentEnabled}
							trialLengthDays={trialLengthDays}
						/>
					</EditionAwarenessButton>
				)}
			</Tooltip>
		);
	}

	if (shouldShowLinkButton) {
		return (
			<Tooltip content={<TooltipText showAddPaymentDetails={inGracePeriod} />} position="bottom">
				{(tooltipProps) => (
					<EditionAwarenessLinkButton
						{...tooltipProps}
						onClick={() => {
							onClick();
							// This is to fire the exposure for the control group
							commerceEmbedExperimentOnClick();
						}}
						href={url}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						ref={tooltipProps.ref as Ref<HTMLAnchorElement>}
						status={buttonStatus}
						{...(inGracePeriod ? { icon: 'missing-payment-details' } : { upgradeIconType: 'gem' })}
						target="_blank"
					>
						<TrialButtonText
							inGracePeriod={inGracePeriod}
							trialDaysLeft={trialDaysLeft}
							edition={edition}
							trialCountdownExperimentEnabled={trialCountdownExperimentEnabled}
							trialLengthDays={trialLengthDays}
						/>
					</EditionAwarenessLinkButton>
				)}
			</Tooltip>
		);
	}

	if (shouldShowJsmJpdTrialAwarenessModalV2) {
		return (
			<ModalEntryPointPressableTrigger
				entryPoint={editionAwarenessTrialModalEntryPoint}
				entryPointParams={trialModalEntryPointParams}
				entryPointProps={trialModalEntryPointProps}
				interactionName="edition-awareness-trial-modal"
				useInternalModal={false}
			>
				{(modalProps) => (
					<EditionAwarenessButton
						onClick={onClick}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						ref={modalProps.ref as Ref<HTMLButtonElement>}
						status={buttonStatus}
						upgradeIconType="gem"
					>
						<TrialButtonText
							inGracePeriod={inGracePeriod}
							trialDaysLeft={trialDaysLeft}
							edition={edition}
							trialCountdownExperimentEnabled={trialCountdownExperimentEnabled}
							trialLengthDays={trialLengthDays}
						/>
					</EditionAwarenessButton>
				)}
			</ModalEntryPointPressableTrigger>
		);
	}

	return (
		<BillingDetailsPopup
			analyticsAttributes={analyticsAttributes}
			edition={edition}
			addBillingDetailsUrl={addBillingDetailsUrl}
			billingSourceSystem={billingSourceSystem}
		>
			{(triggerProps: TriggerProps) => (
				<EditionAwarenessButton
					{...triggerProps.triggerProps}
					onClick={(...args) => {
						onClick();
						triggerProps.onClick && triggerProps.onClick(...args);
					}}
					status={buttonStatus}
					upgradeIconType="gem"
				>
					<TrialButtonText
						inGracePeriod={inGracePeriod}
						trialDaysLeft={trialDaysLeft}
						edition={edition}
						trialCountdownExperimentEnabled={trialCountdownExperimentEnabled}
						trialLengthDays={trialLengthDays}
					/>
				</EditionAwarenessButton>
			)}
		</BillingDetailsPopup>
	);
};

export const TrialPillButton = (props: TrialPillButtonProps) => {
	const isSiteAdmin = useIsSiteAdmin();
	const { isBillingAdmin } = props;

	return (
		<ContextualAnalyticsData attributes={{ isSiteAdmin, isBillingAdmin }}>
			<SpotlightTarget name="edition-awareness-trial-pill">
				<TrialPillButtonInner {...props} />
			</SpotlightTarget>
		</ContextualAnalyticsData>
	);
};
