/**
 * @generated SignedSource<<ad0f12f4849b9a67be4a9939134d918e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraPlanStatus = "ACTIVE" | "ARCHIVED" | "TRASHED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type PlanMenuItem$data = {
  readonly defaultNavigationItem?: {
    readonly url?: string | null | undefined;
  } | null | undefined;
  readonly favouriteValue: {
    readonly isFavourite: boolean | null | undefined;
  } | null | undefined;
  readonly isReadOnly: boolean | null | undefined;
  readonly planId: AGG$Long;
  readonly planStatus: JiraPlanStatus | null | undefined;
  readonly scenario: {
    readonly scenarioId: AGG$Long;
  };
  readonly title: string | null | undefined;
  readonly " $fragmentType": "PlanMenuItem";
};
export type PlanMenuItem$key = {
  readonly " $data"?: PlanMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcmigrateplansnavigationtorelayrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "PlanMenuItem",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "planId"
      },
      "action": "THROW",
      "path": "planId"
    },
    {
      "kind": "ScalarField",
      "name": "title"
    },
    {
      "kind": "ScalarField",
      "name": "isReadOnly"
    },
    {
      "kind": "ScalarField",
      "name": "planStatus"
    },
    {
      "concreteType": "JiraFavouriteValue",
      "kind": "LinkedField",
      "name": "favouriteValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isFavourite"
        }
      ]
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraScenario",
        "kind": "LinkedField",
        "name": "scenario",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "scenarioId"
            },
            "action": "THROW",
            "path": "scenario.scenarioId"
          }
        ]
      },
      "action": "THROW",
      "path": "scenario"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcmigrateplansnavigationtorelayrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "defaultNavigationItem",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "url"
                }
              ],
              "type": "JiraSoftwareBuiltInNavigationItem"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraPlan"
};

(node as any).hash = "7f7a892ec8cae58cbf0430a7e53973c3";

export default node;
