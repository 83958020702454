import React, { type ReactNode } from 'react';
import Button from '@atlaskit/button/standard-button';
import {
	ModalTransition,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import { JiraModal as Modal } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';

export type RecommendationModalProps = {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	body: ReactNode;
	primaryCta: ReactNode;
};

export const RecommendationModal = ({
	isOpen,
	onClose,
	title,
	body,
	primaryCta,
}: RecommendationModalProps) => (
	<ModalTransition>
		{isOpen && (
			<Modal
				messageId="atlassian-navigation-recommendations.common.ui.recommendation-section.recommendation-modal.modal"
				messageType="transactional"
				shouldCloseOnEscapePress
				onClose={onClose}
			>
				<ModalHeader>
					<ModalTitle>{title}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Text as="p">{body}</Text>
				</ModalBody>
				<ModalFooter>
					<Button appearance="primary" onClick={onClose}>
						{primaryCta}
					</Button>
				</ModalFooter>
			</Modal>
		)}
	</ModalTransition>
);
