/**
 * @generated SignedSource<<aa72fd11424a345ad990ff2118581009>>
 * @relayHash 1455b2fa35398335414b05c31ac5905b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID edf14cc7b5c6adb7c7fd445da5ed2d2cc6a4823009240d112b538f444f583263

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "edf14cc7b5c6adb7c7fd445da5ed2d2cc6a4823009240d112b538f444f583263",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
