import { stringify } from 'query-string';
import memoizeOne from 'memoize-one';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal/src/index.tsx';
import type { Data, FetchArgs, QueryParamExpand } from './types.tsx';

export const unmemoisedFetchData = ({ params, header }: FetchArgs): Promise<Data> => {
	const queryParams = {
		...params,
		expand: Object.keys(params.expand || {}).filter(
			(key) =>
				params.expand &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				params.expand[key as keyof NonNullable<QueryParamExpand['expand']>],
		),
	};

	const query = stringify(queryParams, { arrayFormat: 'none' });

	const queryString = query ? `?${query}` : '';
	const requestUrl = `/rest/api/2/user${queryString}`;
	// @ts-expect-error - TS2345 - Argument of type '{ headers: HeaderParamForceAccountId; } | {}' is not assignable to parameter of type 'RequestInit | undefined'.
	return performGetRequest(requestUrl, header ? { headers: header } : {});
};

const fetchDataNew = memoizeOne(
	unmemoisedFetchData,
	(newArgs, lastArgs) =>
		// compare params and headers to see if it should be memoised.
		isShallowEqual(newArgs[0].params, lastArgs[0].params) &&
		isShallowEqual(newArgs[0].header, lastArgs[0].header),
);

/**
 * `fetchData` is memoised by default by its params and headers.
 * Ensure that this behaviour is expected for you, otherwise, use the unmemoised version.
 */
export const fetchData = functionWithCondition(
	() => fg('user-iv-llc-unnecessary-api'),
	fetchDataNew,
	unmemoisedFetchData,
);
