/** @jsx jsx */
import type { CSSProperties } from 'react';
import { css, jsx } from '@compiled/react';
import {
	CustomItem,
	type CustomItemComponentProps,
	type CustomItemProps,
} from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';
import { useSidebarTheme } from '../../../controllers/sidebar-theme/index.tsx';

const bgColorCssVar = '--header-bg-color';
const textColorCssVar = '--header-text-color';

const containerStyles = css({
	// Need to increase specificity here until CustomItem has been migrated to @compiled/react
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'&&': {
		color: `var(${textColorCssVar})`,
		position: 'relative',
		userSelect: 'auto',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-item-title]': {
		font: token('font.heading.xsmall'),
		color: `var(${textColorCssVar}, ${token('color.text')})`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'div&:hover': {
		cursor: 'default',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'div&:active': {
		color: token('color.text'),
	},
});

const bgColorThemeStyles = css({
	// Need to increase specificity here until CustomItem has been migrated to @compiled/react
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'&&': {
		backgroundColor: `var(${bgColorCssVar})`,
	},
});

const colorThemeStyles = css({
	// Need to increase specificity here until CustomItem has been migrated to @compiled/react
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'&&, [data-item-description]': {
		color: `var(${textColorCssVar})`,
	},
});

export const HeaderContainer = ({ className, ...props }: CustomItemComponentProps) => {
	const theme = useSidebarTheme();
	const item = theme?.navigation?.item;
	return (
		<div
			css={[
				containerStyles,
				item?.background?.default && bgColorThemeStyles,
				item?.color?.default && colorThemeStyles,
			]}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={className}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{
					[bgColorCssVar]: item?.background?.default,
					[textColorCssVar]: item?.color?.default,
				} as CSSProperties
			}
			{...props}
		/>
	);
};

type CustomHeaderProps = CustomItemProps & {
	className?: string;
};

export const CustomHeader = ({ iconBefore, iconAfter, children, ...rest }: CustomHeaderProps) => {
	return (
		<CustomItem
			{...rest}
			component={HeaderContainer}
			iconBefore={iconBefore}
			iconAfter={iconAfter}
			// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
			overrides={{
				Title: {
					// eslint-disable-next-line @atlaskit/design-system/use-heading
					render: (_, { children: title, ...props }) => <h2 {...props}>{title}</h2>,
				},
			}}
		>
			{children}
		</CustomItem>
	);
};
