import { fg } from '@atlassian/jira-feature-gating';
import { cacheableTypes } from '../../const.tsx';

const calculateTransferType = (
	name: string,
	type: string,
	duration: number,
	size: number | undefined,
) => {
	if (fg('ufo_support_other_resource_type_js')) {
		if (!cacheableTypes.includes(type) && !(type === 'other' && name.includes('.js'))) {
			return null;
		}
	} else if (!cacheableTypes.includes(type)) {
		return null;
	}

	if ((size === undefined || size === 0) && duration === 0) {
		return CHRSummary.MEMORY_KEY;
	}
	if (size === 0 && duration > 0) {
		return CHRSummary.DISK_KEY;
	}
	if (size === undefined) {
		return null;
	}

	return CHRSummary.NETWORK_KEY;
};

export class CHRSummary {
	static MEMORY_KEY = 'mem';

	static DISK_KEY = 'disk';

	static NETWORK_KEY = 'net';

	bundles = {
		[CHRSummary.MEMORY_KEY]: 0,
		[CHRSummary.DISK_KEY]: 0,
		[CHRSummary.NETWORK_KEY]: 0,
	};

	bundlesCount = 0;

	size = {
		[CHRSummary.MEMORY_KEY]: 0,
		[CHRSummary.DISK_KEY]: 0,
		[CHRSummary.NETWORK_KEY]: 0,
	};

	sizeTotal = 0;

	add(item: PerformanceResourceTiming) {
		const type = calculateTransferType(
			item.name,
			item.initiatorType,
			item.duration,
			item.transferSize,
		);
		if (type === null) return;

		this.bundles[type] += 1;
		this.bundlesCount += 1;
		this.size[type] += item.encodedBodySize;
		this.sizeTotal += item.encodedBodySize;
	}
}
