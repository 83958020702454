import React from 'react';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import ErrorIcon from '@atlaskit/icon/core/error';
import { token } from '@atlaskit/tokens';
import Lozenge from '@atlaskit/lozenge';
import Button from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import { FixErrorButton } from '../fix-error-button/index.tsx';
import {
	useDebuggerStatus,
	useJqlErrors,
	useJqlEditorOnSearch,
	useJqlQuery,
	useErrorComponentTestId,
	useErrorComponentValidationId,
} from '../../controllers/jql-debugger-data/index.tsx';
import { GeneratingAnimation } from '../generating-animation/index.tsx';
import messages from './messages.tsx';

type Props = {
	entryPointActions: EntryPointActions;
};

export function ErrorSection(props: Props) {
	const { entryPointActions } = props;
	const { formatMessage } = useIntl();
	const [query] = useJqlQuery();
	const [status] = useDebuggerStatus();
	const [jqlErrors] = useJqlErrors();
	const [onSearch] = useJqlEditorOnSearch();
	const [errorComponentTestId] = useErrorComponentTestId();
	const [errorComponentValidationId] = useErrorComponentValidationId();

	return (
		<Inline alignBlock="center" spread="space-between" space="space.200">
			<Inline alignBlock="start" spread="space-between" space="space.100">
				<Box as="h5" xcss={jqlErrorHeadingStyles}>
					<ErrorIcon color={token('color.icon')} label="" />
					<Text weight="bold">
						{formatMessage(
							jqlErrors?.length > 1
								? messages.jqlDebuggerHeadingPluralNonFinal
								: messages.jqlDebuggerHeadingNonFinal,
						)}
					</Text>
				</Box>
				{/* When the AI response is loading, we render the "Fixing JQL" text and animation over the error message. In all other cases, we show the error */}
				{status === 'LOADING_RESULT' ? (
					<Inline alignBlock="center" space="space.050">
						<GeneratingAnimation alt={formatMessage(messages.jqlDebuggerLoadingNonFinal)} />
						<Text>{formatMessage(messages.jqlDebuggerLoadingNonFinal)}</Text>
					</Inline>
				) : (
					// The wrappers around the ul element try to closely resemble the same structure as the default error message in the platform package
					// See: platform/packages/jql/jql-editor/src/ui/jql-editor-footer-content/jql-messages/errors/index.tsx
					<Box testId={errorComponentTestId}>
						<span role="alert" id={errorComponentValidationId}>
							<Box as="ul" xcss={errorBoxStyles} testId="jql-debugger.ui.error-section.error-list">
								{jqlErrors.map((error) => (
									<Box as="li" key={error} xcss={listItemStyle}>
										<Text color="color.text.accent.red">{error}</Text>
									</Box>
								))}
							</Box>
						</span>
					</Box>
				)}
			</Inline>
			<Box xcss={headerEndStyles}>
				{/* The beta lozenge should only be shown on a successful result as per the Figma file */}
				{status === 'VIEW_RESULT' ? (
					<Lozenge appearance="new">{formatMessage(messages.betaLozenge)}</Lozenge>
				) : null}
				{status === 'INIT' && (
					<FixErrorButton
						testId="jql-debugger.ui.error-section.trigger-button"
						jqlErrors={jqlErrors}
						onSearch={onSearch}
						query={query}
						entryPointActions={entryPointActions}
					/>
				)}
				{status === 'LOADING_RESULT' ? (
					// Render a placeholder button when loading, so that the height of the panel stays the same most of the time
					// This way, the transitions are smoother with less layout shifts
					<Box xcss={placeholderButtonStyle}>
						<Button isDisabled appearance="subtle">
							{null}
						</Button>
					</Box>
				) : null}
			</Box>
		</Inline>
	);
}

const errorBoxStyles = xcss({
	flex: 1,
	marginBlockStart: '0',
	paddingInlineStart: 'space.025',
});

const headerEndStyles = xcss({
	alignSelf: 'flex-start',
});

const listItemStyle = xcss({
	listStyle: 'none',
});

const placeholderButtonStyle = xcss({
	opacity: 0,
	width: '0',
});

const jqlErrorHeadingStyles = xcss({
	display: 'flex',
	whiteSpace: 'nowrap',
	alignItems: 'center',
	gap: 'space.075',
});
