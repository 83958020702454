// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	createContainer,
	type HookReturnValue,
} from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;
export type UseCreateProjectPermissionType = () => HookReturnValue<State, Actions>;

const Store = createStore<State, Actions>({
	name: 'create-project-permissions',
	initialState: {
		canCreateProject: false,
		canCreateClassicProject: false,
		canCreateNextGenProject: false,
		isFetching: false,
		fetchError: null,
		promise: null,
		hasFetchedOnce: false,
		hasSuccessOnce: false,
	},
	actions,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CreateProjectPermissionContainer = createContainer<State, Actions, Record<any, any>>(
	Store,
);
export const useCreateProjectPermission = createHook(Store);

export const useCreateProjectPermissionActions = createHook(Store, {
	selector: null,
});
