// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State, NavigationCollection } from '../../types.tsx';
import { updateCollectionsState } from '../utils.tsx';

export const updateCollection =
	(collectionUUID: string, patch: Partial<NavigationCollection>): Action<State, Props> =>
	async ({ setState, getState }, { cloudId, onSuccess }) => {
		const state = getState();

		if (!cloudId || state.collections.isLoading || !state.collections.initialized) {
			return;
		}

		if (!collectionUUID) {
			return;
		}

		const nextState = updateCollectionsState(state, collectionUUID, (prevNavigationCollection) => ({
			...prevNavigationCollection,
			...patch,
		}));

		if (nextState) {
			setState(nextState);
			onSuccess?.('updateCollection');
		}
	};
