import React, { memo, useContext } from 'react';
import AddIcon from '@atlaskit/icon/utility/add';
import { MenuGroup } from '@atlaskit/menu';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { CommonNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/common-nav-menu-button/main.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { AnalyticContext } from '../../controllers/analytic-context/index.tsx';
import { MEATBALL_DROPDOWN } from '../../constants.tsx';
import { messages } from './messages.tsx';

type ActionButtonsStaticProps = {
	isReadOnly?: boolean;
	isCreadRoadmapLoading?: boolean;
	isCreateRoadmapDisabled?: boolean;
	createRoadmapTooltipContent?: string;
	onCreateRoadmapClick?: () => void;
};

export const ActionButtonsStatic = memo(
	({
		isReadOnly,
		isCreadRoadmapLoading,
		isCreateRoadmapDisabled,
		createRoadmapTooltipContent,
		onCreateRoadmapClick,
	}: ActionButtonsStaticProps) => {
		const { formatMessage } = useIntl();
		const { fireUIAnalyticForFixButton } = useContext(AnalyticContext);

		return (
			<>
				{!isReadOnly && (
					<Tooltip content={createRoadmapTooltipContent}>
						<CommonNavMenuButton
							icon={AddIcon}
							label={formatMessage(messages.createRoadmap)}
							isLoading={isCreadRoadmapLoading}
							isDisabled={isCreateRoadmapDisabled}
							onClick={onCreateRoadmapClick}
						/>
					</Tooltip>
				)}

				<MoreNavMenuButton
					onOpen={() => fireUIAnalyticForFixButton({ itemId: MEATBALL_DROPDOWN })}
					MenuItems={() => (
						<MenuGroup>
							<HideL1MenuItemSection menuId={L1_MENU_ID.ROADMAPS} hasSeparator={false} />
						</MenuGroup>
					)}
				/>
			</>
		);
	},
);
