import { useEffect } from 'react';

// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook } from '@atlassian/react-sweet-state';

import type { State } from '../../services/initial-project-template-name/types.tsx';
import { actions } from './actions.tsx';

type Actions = typeof actions;
export const initialState: State = {} as const;

const store = createStore<State, Actions>({
	name: 'jira-initial-project-template-name',
	initialState,
	actions,
});

export const useFetchInitialProjectTemplateNameSweetState = createHook(store);

// This hook returns initial project template key from project properties
// Avoid using this hook to determine project template key, as it won't be set with new project create API
export const useFetchInitialProjectTemplateName = ({
	shouldFetch,
	projectId,
}: {
	shouldFetch: boolean;
	projectId: number | undefined;
}) => {
	const [state, { fetchInitialProjectTemplateName }] =
		useFetchInitialProjectTemplateNameSweetState();

	useEffect(() => {
		if (shouldFetch && projectId) {
			fetchInitialProjectTemplateName(`${projectId}`);
		}
	}, [fetchInitialProjectTemplateName, projectId, shouldFetch]);

	if (!projectId) {
		return {
			data: undefined,
			isReady: true,
		};
	}

	if (!shouldFetch) {
		return {
			// Still return the data if it's already fetched
			// leave it to consumer to decide what to do with it
			data: state[`${projectId}`]?.templateKey,
			isReady: true,
		};
	}

	return {
		data: state[`${projectId}`]?.templateKey,
		isReady: state[`${projectId}`]?.fetchStatus === 'fetched',
	};
};
