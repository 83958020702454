import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { workflowIssuesResource } from '@atlassian/jira-router-resources-business-board-workflow-issues/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessBoardRoute } from '@atlassian/jira-router-routes-business-board-routes/src/jiraBusinessBoardRoute.tsx';
import { boardPageEntryPoint } from './ui/board/index.tsx';

export const jiraBusinessBoardRouteEntry = createEntry(jiraBusinessBoardRoute, () => ({
	group: 'classic-business',
	ufoName: 'jwm.jwm-board',
	layout: composeLayouts(
		businessProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),

	skeleton: ThemedLazySkeleton,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		// delete when cleaning up getWillShowNav4
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalNavJira,
	},

	resources: [...getProjectViewResources(), workflowIssuesResource],

	preloadOptions: {
		earlyChunks: ['business-board-early-entry'],
	},

	entryPoint: () => boardPageEntryPoint,
}));
