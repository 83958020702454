import { createResource } from 'react-resource-router';

import type { KnowledgeSourcesResourceResponse } from './types';

const TWENTY_FOUR_HOURS_IN_MS = 86400000;

export const knowledgeSourcesResource = createResource<KnowledgeSourcesResourceResponse>({
	type: 'CSM_AGENT_CONFIGURATION_KNOWLEDGE_SOURCES',
	getKey: () => 'knowledge-sources',
	getDataLoader: () =>
		import(/* webpackChunkName: "ai-mate/csm-knowledge-config-resource" */ './get-data'),
	maxAge: TWENTY_FOUR_HOURS_IN_MS,
	isBrowserOnly: true,
});
