import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { softwareProformaIssueFormsDirectRoute } from '@atlassian/jira-router-routes-proforma-routes/src/softwareProformaIssueFormsDirectRoute.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import ProformaIssueFormsDirect, {
	LazyProformaIssueFormsDirect,
} from './ui/issue-forms-direct/index.tsx';

export const softwareProformaIssueFormsDirectRouteEntry = createEntry(
	softwareProformaIssueFormsDirectRoute,
	() => ({
		group: 'software',
		layout: softwareProjectLayout,
		resources: [projectContextResource],
		component: ProformaIssueFormsDirect,
		navigation: {
			menuId: MENU_ID.PROJECTS,
		},
		forPaint: [LazyProformaIssueFormsDirect],
		perfMetricKey: 'issue-forms-direct',
	}),
);
