/**
 * @generated SignedSource<<4a7eabd336a54085b12e6024b67b0182>>
 * @relayHash 367268a979a58b37662cf273a88851ee
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID dd468ae0c857364119a123f5e1f38c418844966a4acd4018dddf66eb56286f0b

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraSidebarMenuDisplayMode = "MOST_RECENT_ONLY" | "STARRED" | "STARRED_AND_RECENT" | "%future added value";
export type projectsViewOptionsQuery$variables = {
  cloudId: string;
};
export type projectsViewOptionsQuery$data = {
  readonly jira_projectsSidebarMenu: {
    readonly displayMode: JiraSidebarMenuDisplayMode | null | undefined;
  } | null | undefined;
};
export type projectsViewOptionsQuery = {
  response: projectsViewOptionsQuery$data;
  variables: projectsViewOptionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "displayMode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "projectsViewOptionsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraProjectsSidebarMenu",
        "kind": "LinkedField",
        "name": "jira_projectsSidebarMenu",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "projectsViewOptionsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraProjectsSidebarMenu",
        "kind": "LinkedField",
        "name": "jira_projectsSidebarMenu",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "dd468ae0c857364119a123f5e1f38c418844966a4acd4018dddf66eb56286f0b",
    "metadata": {},
    "name": "projectsViewOptionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "fc0b49221c780edfae3aba8b40f0e778";

export default node;
