/**
 * @generated SignedSource<<6c8059f14a3201b31e0652bd91e511bc>>
 * @relayHash ad9b22c18ca56c0cec6bb77e087f1a60
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b5a6395a2023b01b4b8728b98901f39635c74a467c84cd540ba5af518baccad2

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { HorizontalNavQueueInnerQuery } from './HorizontalNavQueueInnerQuery.graphql';

const node: PreloadableConcreteRequest<HorizontalNavQueueInnerQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b5a6395a2023b01b4b8728b98901f39635c74a467c84cd540ba5af518baccad2",
    "metadata": {},
    "name": "HorizontalNavQueueInnerQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
