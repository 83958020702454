import defaultStorePlugin from 'store/plugins/defaults';
import {
	AtlBrowserStorageLocal,
	AtlBrowserStorageSession,
} from '@atlassian/browser-storage-controls';
import { fg } from '@atlassian/jira-feature-gating';
import cleanUpPlugin from './controllers/storage-cleanup-plugin/index.tsx';
import type { Storage, StorageNew, ExpirableStorageNew, ExpirableStorage } from './types.tsx';

export const plugins = [defaultStorePlugin, cleanUpPlugin];

export const WebStorageType = {
	LocalStorage: 'LocalStorage',
	SessionStorage: 'SessionStorage',
};

export const createStorageProvider = (
	storageType: (typeof WebStorageType)[keyof typeof WebStorageType],
	appPrefix = '',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: any,
): Storage | StorageNew => {
	const oldStore = store.namespace(appPrefix);
	const getKeyWithPrefix = (key: string) =>
		appPrefix?.trim().length ? `${appPrefix}.${key}` : key;
	const storageAPI =
		storageType === WebStorageType.LocalStorage ? AtlBrowserStorageLocal : AtlBrowserStorageSession;
	return {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		set: (key: string, value: any) => {
			try {
				const prefixedKey = getKeyWithPrefix(key);
				const stringValue = JSON.stringify(value);
				if (fg('jfp-vulcan-browser-storage-providers')) {
					storageAPI.setItem(prefixedKey, stringValue);
				} else {
					if (fg('jfp-vulcan-browser-storage-hot-114899')) {
						// write to the old storejs store as well
						oldStore.set(key, value);
					}
					store.set(prefixedKey, stringValue);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error setting item in storage:', err);
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setStrictlyNecessaryItem: (key: string, value: any) => {
			try {
				const prefixedKey = getKeyWithPrefix(key);
				const stringValue = JSON.stringify(value);
				if (fg('jfp-vulcan-browser-storage-providers')) {
					storageAPI.setStrictlyNecessaryItem(prefixedKey, stringValue);
				} else {
					throw Error('Method setStrictlyNecessaryItem is not supported.');
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error setting item in storage:', err);
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		get: (key: string): any | undefined => {
			try {
				const prefixedKey = getKeyWithPrefix(key);
				let item;
				if (fg('jfp-vulcan-browser-storage-providers')) {
					item = storageAPI.getItem(prefixedKey);
					if (typeof item === 'undefined') {
						// handle a case when customer has all data with keys like `__storejs__`
						const itemFromOldStore = oldStore.get(key);
						const stringValue = JSON.stringify(itemFromOldStore);
						storageAPI.setItem(prefixedKey, stringValue); // migrate to new store with new key
						return itemFromOldStore;
					}
				} else {
					if (fg('jfp-vulcan-browser-storage-hot-114899')) {
						// handle the hot
						const itemFromOldStore = oldStore.get(key);
						const itemFromNewStore = store.get(prefixedKey);
						if (typeof itemFromNewStore !== 'undefined') {
							return JSON.parse(itemFromNewStore);
						}
						if (typeof itemFromOldStore !== 'undefined') {
							const stringValue = JSON.stringify(itemFromOldStore);
							store.set(prefixedKey, stringValue); // copy that value to new store with new key
							return itemFromOldStore;
						}
						return undefined;
					}
					item = store.get(prefixedKey);
				}
				return item ? JSON.parse(item) : undefined;
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error getting item from storage:', err);
				return undefined;
			}
		},
		remove: (key: string) => {
			try {
				const prefixedKey = getKeyWithPrefix(key);
				if (fg('jfp-vulcan-browser-storage-providers')) {
					storageAPI.removeItem(prefixedKey); // remove data from new store
					oldStore.remove(key); // remove data from old store as well
				} else {
					if (fg('jfp-vulcan-browser-storage-hot-114899')) {
						oldStore.remove(key);
					}
					store.remove(prefixedKey);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error removing item from storage:', err);
			}
		},
		clearAll: () => {
			if (!appPrefix) {
				throw Error(
					"appPrefix is necessary to access clearAll method. It's used to separate apps.",
				);
			}
			try {
				if (fg('jfp-vulcan-browser-storage-providers')) {
					for (let i = 0; i < storageAPI.length(); i++) {
						const key = storageAPI.key(i);
						if (key && key.startsWith(`${appPrefix}.`)) {
							storageAPI.removeItem(key);
							i--;
						}
					}
					oldStore.clearAll(); // clear old store as well
				} else {
					if (fg('jfp-vulcan-browser-storage-hot-114899')) {
						oldStore.clearAll();
					}
					store.clearAll();
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error clearing storage:', err);
			}
		},

		length: (): number | undefined => {
			try {
				if (fg('jfp-vulcan-browser-storage-providers')) {
					return storageAPI.length();
				}
				throw Error('Method length is not supported.');
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error getting storage length:', err);
			}
		},
	};
};

export const createExpirableStorageProvider = (
	storageType: (typeof WebStorageType)[keyof typeof WebStorageType],
	appPrefix: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: any,
): ExpirableStorage | ExpirableStorageNew => {
	if (!appPrefix) {
		throw Error("appPrefix can not be empty. It's used to separate apps");
	}
	const oldStore = store.namespace(appPrefix);
	const getKeyWithPrefix = (key: string) => `${appPrefix}.${key}`;
	const storageAPI =
		storageType === WebStorageType.LocalStorage ? AtlBrowserStorageLocal : AtlBrowserStorageSession;

	return {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		set: (key: string, value: any, expiration?: number): void => {
			try {
				const prefixedKey = getKeyWithPrefix(key);
				const item = {
					value,
					expiration,
				};
				if (fg('jfp-vulcan-browser-storage-providers')) {
					storageAPI.setItem(prefixedKey, JSON.stringify(item));
				} else {
					if (fg('jfp-vulcan-browser-storage-hot-114899')) {
						// handle the hot,write to old store as well
						oldStore.set(key, value, expiration);
					}
					store.set(prefixedKey, JSON.stringify(item));
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(`Error setting item with key=${key}:`, error);
			}
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setStrictlyNecessaryItem: (key: string, value: any, expiration?: number): void => {
			try {
				const item = {
					value,
					expiration,
				};
				if (fg('jfp-vulcan-browser-storage-providers')) {
					storageAPI.setStrictlyNecessaryItem(getKeyWithPrefix(key), JSON.stringify(item));
				} else {
					throw Error('Method setStrictlyNecessaryItem is not supported.');
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(`Error setting item with key=${key}:`, error);
			}
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		get: (key: string): any | undefined => {
			const prefixedKey = getKeyWithPrefix(key);
			try {
				let itemStr;
				if (fg('jfp-vulcan-browser-storage-providers')) {
					itemStr = storageAPI.getItem(prefixedKey);
					if (!itemStr) {
						// check in the old store
						const itemFromOldStore = oldStore.get(key);
						if (typeof itemFromOldStore !== 'undefined') {
							const item = {
								value: itemFromOldStore,
								expiration: oldStore.getExpiration(key),
							};
							storageAPI.setItem(prefixedKey, JSON.stringify(item)); // migrate to new store with new key
							return itemFromOldStore;
						}
					}
				} else if (fg('jfp-vulcan-browser-storage-hot-114899')) {
					// handle the hot
					const itemFromOldStore = oldStore.get(key);
					const itemFromNewStore = store.get(prefixedKey);
					if (typeof itemFromNewStore !== 'undefined') {
						itemStr = itemFromNewStore;
					} else if (typeof itemFromOldStore !== 'undefined') {
						const expiration = oldStore.getExpiration(key);
						const item = {
							value: itemFromOldStore,
							expiration,
						};
						store.set(prefixedKey, JSON.stringify(item)); // copy that value to new store with new key
						return itemFromOldStore;
					}
				} else {
					itemStr = store.get(prefixedKey);
				}
				if (!itemStr) return undefined;
				const item = JSON.parse(itemStr);
				if (!item.expiration || item.expiration >= Date.now()) {
					return item.value;
				}
				storageAPI.removeItem(prefixedKey);
				return undefined;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(`Error getting item with key=${key}:`, error);
				return undefined;
			}
		},

		getExpiration: (key: string): number | undefined => {
			const prefixedKey = getKeyWithPrefix(key);
			try {
				let itemStr;
				if (fg('jfp-vulcan-browser-storage-providers')) {
					itemStr = storageAPI.getItem(prefixedKey);
					if (!itemStr) {
						// check in the old store
						const itemFromOldStore = oldStore.get(key);
						if (typeof itemFromOldStore !== 'undefined') {
							const expiration = oldStore.getExpiration(key);
							const item = {
								value: itemFromOldStore,
								expiration,
							};
							storageAPI.setItem(prefixedKey, JSON.stringify(item)); // migrate to new store with new key
							return expiration;
						}
					}
				} else if (fg('jfp-vulcan-browser-storage-hot-114899')) {
					// handle the hot
					const itemFromOldStore = oldStore.get(key);
					const itemFromNewStore = store.get(prefixedKey);
					if (typeof itemFromNewStore !== 'undefined') {
						itemStr = itemFromNewStore;
					} else if (typeof itemFromOldStore !== 'undefined') {
						const expiration = oldStore.getExpiration(key);
						const item = {
							value: itemFromOldStore,
							expiration,
						};
						store.set(prefixedKey, JSON.stringify(item)); // copy that value to new store with new key
						return expiration;
					}
				} else {
					itemStr = store.get(prefixedKey);
				}
				if (!itemStr) return undefined;
				const item = JSON.parse(itemStr);
				if (item.expiration && item.expiration >= Date.now()) {
					return item.expiration;
				}
				storageAPI.removeItem(prefixedKey);
				return undefined;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(`Error getting expiration for item with key=${key}:`, error);
				return undefined;
			}
		},

		remove: (key: string): void => {
			try {
				const prefixedKey = getKeyWithPrefix(key);
				if (fg('jfp-vulcan-browser-storage-providers')) {
					storageAPI.removeItem(prefixedKey);
					oldStore.remove(key);
				} else {
					if (fg('jfp-vulcan-browser-storage-hot-114899')) {
						oldStore.remove(key);
					}
					store.remove(prefixedKey);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(`Error removing item with key=${key}:`, error);
			}
		},

		removeExpiredKeys: (): void => {
			try {
				if (fg('jfp-vulcan-browser-storage-providers')) {
					for (let i = 0; i < storageAPI.length(); i++) {
						const key = storageAPI.key(i);
						if (key && key.startsWith(`${appPrefix}.`)) {
							const itemStr = storageAPI.getItem(key);
							if (itemStr) {
								const item = JSON.parse(itemStr);
								if (item.expiration && item.expiration < Date.now()) {
									storageAPI.removeItem(key);
									i--;
								}
							}
						}
					}
					oldStore.removeExpiredKeys(); // clean up old keys too
				} else {
					if (fg('jfp-vulcan-browser-storage-hot-114899')) {
						oldStore.removeExpiredKeys();
					}
					store.removeExpiredKeys();
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error removing expired keys:', error);
			}
		},
		clearAll: () => {
			try {
				if (fg('jfp-vulcan-browser-storage-providers')) {
					for (let i = 0; i < storageAPI.length(); i++) {
						const key = storageAPI.key(i);
						if (key && key.startsWith(`${appPrefix}.`)) {
							storageAPI.removeItem(key);
							i--;
						}
					}
					oldStore.clearAll();
				} else {
					if (fg('jfp-vulcan-browser-storage-hot-114899')) {
						oldStore.clearAll();
					}
					store.clearAll();
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error clearing storage:', err);
			}
		},

		length: (): number | undefined => {
			try {
				if (fg('jfp-vulcan-browser-storage-providers')) {
					return storageAPI.length();
				}
				throw Error('Method length is not supported.');
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Error getting storage length:', err);
			}
		},
	};
};
