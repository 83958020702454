import { useEffect } from 'react';
import { initialCachedEndpointState } from '@atlassian/jira-onboarding-core/src/services/cached-endpoint/constants.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { useIsTargetedForReverseTrials } from '../use-is-targeted/index.tsx';
import { actions } from './actions.tsx';
import type { PremiumOnSignupState } from './types.tsx';

type Actions = typeof actions;

const store = createStore<PremiumOnSignupState, Actions>({
	name: 'jsw-onboarding-reverse-trial',
	initialState: initialCachedEndpointState,
	actions,
});

export const useOnboardingPremiumTrialSweetState = createHook(store);

export const useFetchOnboardingReverseTrial = () => {
	const isTargetedForReverseTrials = useIsTargetedForReverseTrials();

	const [{ fetchedOnce, isFetching, data }, { getPremiumTrialOnSignup }] =
		useOnboardingPremiumTrialSweetState();

	useEffect(() => {
		if (isTargetedForReverseTrials && !fetchedOnce && !isFetching && data === undefined) {
			// @ts-expect-error Arguments for the rest parameter 'args' were not provided.
			getPremiumTrialOnSignup();
		}
	}, [data, fetchedOnce, getPremiumTrialOnSignup, isFetching, isTargetedForReverseTrials]);

	if (!isTargetedForReverseTrials) {
		// If not targeted, no fetching is done. Data is ready to be read
		return {
			premiumTrialOnSignup: undefined,
			reverseTrialCohort: data?.reverseTrialCohort,
			isReady: true,
		};
	}
	return {
		premiumTrialOnSignup: data?.premiumTrialOnSignup,
		reverseTrialCohort: data?.reverseTrialCohort,
		isReady: fetchedOnce && !isFetching,
	};
};
