import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskNotificationsInternalNotificationsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskNotificationsInternalNotificationsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { LazyInternalNotificationSettingsPage } from '@atlassian/jira-servicedesk-settings-notification-internal/src/ui/async.tsx';
import { serviceDeskInternalNotificationsNextGenRedirect } from './ui/service-desk-internal-notifications-next-gen-redirect/index.tsx';
import { LazyProjectSettingsServiceDeskInternalNotifications } from './ui/service-desk-internal-notifications/index.tsx';

export const projectSettingsServicedeskNotificationsInternalNotificationsRouteEntry = createEntry(
	projectSettingsServicedeskNotificationsInternalNotificationsRoute,
	() => ({
		group: 'project-settings-servicedesk',
		ufoName: 'service-management.internal-notifications',
		component: serviceDeskInternalNotificationsNextGenRedirect,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [
			LazyProjectSettingsServiceDeskInternalNotifications,
			LazyInternalNotificationSettingsPage,
		],
	}),
);
