import React, { useEffect, type ReactNode } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { createRouterSelector } from '@atlassian/react-resource-router';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { helpCenterSettingsRedirectQuery } from '@atlassian/jira-relay/src/__generated__/helpCenterSettingsRedirectQuery.graphql';
import { HelpHelpCenterAri } from '@atlassian/ari/help/help-center';
import { fg } from '@atlassian/jira-feature-gating';
import { CUSTOMER_SERVICE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { isHelpCenterSettingsPath } from '../../utils/is-help-center-settings-path/index.tsx';
import { useIsJCSProjectType } from '../../utils/use-is-jcs/index.tsx';
import { getSettingsPath } from '../../utils/get-settings-path/index.tsx';
import messages from './messages.tsx';

type Page =
	| 'kb'
	| 'customer-notifications'
	| 'email'
	| 'ticket-types-config'
	| 'ticket-type-fields';

type Props = {
	page: Page;
	children: ReactNode;
};

export const getPageForAnalytics = (page: Page, pathname: string) => {
	if (page === 'customer-notifications') {
		const customerNotificationsPage = pathname.split('/settings/customer-notifications')[1];

		if (customerNotificationsPage === '/template') {
			return 'customer-notifications-template';
		}
		if (customerNotificationsPage === '/account-notification/invite-customer') {
			return 'customer-notifications-invite-customer';
		}
		if (/rule\/\d+/.test(customerNotificationsPage)) {
			return 'customer-notifications-rule';
		}
	}

	return page;
};

export const getRedirectTo = (page: Page, pathname: string, helpCenterId?: string) => {
	switch (page) {
		case 'kb':
			return getSettingsPath(pathname.replace('/confluence-kb', '/kb'), true, helpCenterId);
		default:
			return getSettingsPath(pathname, true, helpCenterId);
	}
};

const useLocation = createRouterSelector((state) => state.location);

// This component is used for redirecting users from project settings pages to
// their help center settings equivalents
export const HelpCenterSettingsRedirectOld = ({ page, children }: Props) => {
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const useLocationOld = createRouterSelector((state) => state.location);
	const location = useLocationOld();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: projectContext } = useProjectContext();
	const isJCS = useIsJCSProjectType(projectContext?.projectType);

	useEffect(() => {
		if (isJCS && !isHelpCenterSettingsPath()) {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'projectSettingsToHelpCenterSettings redirected',
				{
					page: getPageForAnalytics(page, location.pathname),
				},
			);

			showFlag({
				messageId:
					'servicedesk-customer-service-common.ui.help-center-settings-redirect.show-flag.error',
				messageType: 'transactional',
				type: 'error',
				title: formatMessage(messages.errorTitle),
				description: formatMessage(
					fg('jcs_project_type_m3') ? messages.description : messages.errorDescription,
				),
				isAutoDismiss: true,
			});
		}
	}, [createAnalyticsEvent, formatMessage, isJCS, location.pathname, page, showFlag]);

	if (isJCS && !isHelpCenterSettingsPath()) {
		return <ScreenRedirect to="/jira/helpcenter" />;
	}

	return children;
};

// This component is used for redirecting users from project settings pages to
// their help center settings equivalents
export const HelpCenterSettingsRedirectNew = ({ page, children }: Props) => {
	const location = useLocation();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: projectContext } = useProjectContext();
	const isJCS = projectContext?.projectType === CUSTOMER_SERVICE_PROJECT;

	useEffect(() => {
		if (isJCS && !isHelpCenterSettingsPath()) {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'projectSettingsToHelpCenterSettings redirected',
				{
					page: getPageForAnalytics(page, location.pathname),
				},
			);
		}
	}, [createAnalyticsEvent, isJCS, location.pathname, page]);

	if (isJCS && !isHelpCenterSettingsPath()) {
		return (
			<HelpCenterSettingsRedirectWithHelpCenterId
				page={page}
				pathname={location.pathname}
				projectId={projectContext.projectId}
			/>
		);
	}

	return children;
};

export const HelpCenterSettingsRedirectWithHelpCenterId = ({
	page,
	pathname,
	projectId,
}: {
	page: Page;
	pathname: string;
	projectId: number | undefined;
}) => {
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const activationId = useActivationId();
	const cloudId = useCloudId();
	const jiraProjectAri = `ari:cloud:jira:${cloudId}:project/${projectId}`;
	const jiraWorkspaceAri = `ari:cloud:jira:${cloudId}:workspace/${activationId}`;

	const {
		helpCenter: { helpCenterByHoistedProjectIdRouted: helpCenter },
	} = useLazyLoadQuery<helpCenterSettingsRedirectQuery>(
		graphql`
			query helpCenterSettingsRedirectQuery(
				$cloudId: ID!
				$jiraProjectAri: ID!
				$jiraWorkspaceAri: ID!
			) {
				helpCenter(cloudId: $cloudId) @required(action: THROW) {
					helpCenterByHoistedProjectIdRouted(
						projectAri: $jiraProjectAri
						workspaceAri: $jiraWorkspaceAri
					) @optIn(to: ["HelpCenterSlugTest", "HelpCenterAggBeta"]) @required(action: THROW) {
						__typename
						... on HelpCenter {
							id
						}
					}
				}
			}
		`,
		{ cloudId, jiraProjectAri, jiraWorkspaceAri },
	);

	if (helpCenter.__typename !== 'HelpCenter') {
		showFlag({
			messageId:
				'servicedesk-customer-service-common.ui.help-center-settings-redirect.show-flag.error.1',
			messageType: 'transactional',
			type: 'error',
			title: formatMessage(messages.errorTitle),
			description: formatMessage(messages.errorDescription),
			isAutoDismiss: true,
		});
		return <ScreenRedirect to="/jira/helpcenter" />;
	}

	return (
		<ScreenRedirect
			to={getRedirectTo(page, pathname, HelpHelpCenterAri.parse(helpCenter.id).helpCenterId)}
			persistSearchParams
		/>
	);
};

export const HelpCenterSettingsRedirect = componentWithCondition(
	() => fg('jcs_project_type_m3'),
	HelpCenterSettingsRedirectNew,
	HelpCenterSettingsRedirectOld,
);
