import React from 'react';
import type ServicedeskQueuesConfigType from '@atlassian/jira-spa-apps-servicedesk-queues-config/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';

/**
 * @deprecated remove when jsm_views_inside_queues_-_main_flag is cleaned up
 */
export const LazyServicedeskQueuesViewsConfig = lazyForPaint<typeof ServicedeskQueuesConfigType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-queues-views-config", jiraSpaEntry: "async-servicedesk-queues-config" */ '@atlassian/jira-spa-apps-servicedesk-queues-config/src/index.tsx'
		),
);

/**
 * @deprecated remove when jsm_views_inside_queues_-_main_flag is cleaned up
 */
export const ServicedeskQueuesViewsConfig = (props: RouteContext) => {
	const { query } = props;
	return (
		<LazyPage
			Page={LazyServicedeskQueuesViewsConfig}
			pageId={`spa-apps/servicedesk/queues/views-config/${query.config}`}
			shouldShowSpinner
		/>
	);
};
