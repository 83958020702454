import { useMemo, type PropsWithChildren } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { JqlDebuggerJqlBuilderContextProviderProps } from '../../common/types.tsx';
import { useJqlDebuggerStoreActions } from '../../controllers/jql-debugger-data/index.tsx';
import { useRenderSynchronizerStoreActions } from '../../controllers/render-synchronizer/index.tsx';
import { jqlMessagesToErrors } from '../../common/jql-messages-to-errors.tsx';

/**
 * Provide additional context about the JQL Builder context to the JQL Debugger
 *
 * This additional context exists primarily because we need to pass some props from JQL builder to the customComponent override of ErrorMessage
 * Since ErrorMessage component (jql-debugger trigger-button) is passed as a component, we cannot pass the additional props as regular props
 * Hence we wrap the parent with this provider and pass the props as context
 */
export const JqlDebuggerJqlBuilderContextProvider = (
	props: PropsWithChildren<JqlDebuggerJqlBuilderContextProviderProps>,
) => {
	const { jqlMessages, query, onSearch, children } = props;
	const jqlDebuggerStoreActions = useJqlDebuggerStoreActions();
	const renderSyncActions = useRenderSynchronizerStoreActions();

	// A jqlMessage can be error, warning or info
	// We only case about the error scenario
	// Here we map the jqlMessages object list to a list of plain strings
	const jqlErrors = useMemo(() => jqlMessagesToErrors(jqlMessages), [jqlMessages]);

	if (fg('gravityai-2553-fix-jql-debugger-flicker')) {
		// JQL Debugger is not ready when:
		// 1. AI is not enabled
		// 2. onSearch callback is not available - i.e. we cannot apply the corrected jql to the editor
		//
		// In such cases, we want to keep the default error layout
		if (onSearch && query) {
			jqlDebuggerStoreActions.setOnSearch(onSearch);
			jqlDebuggerStoreActions.setJqlQuery(query);

			renderSyncActions.setIsContextReady(true);
		} else {
			renderSyncActions.setIsContextReady(false);
		}

		return children;
	}

	if (jqlErrors?.length && onSearch && query) {
		jqlDebuggerStoreActions.setJqlErrors(jqlErrors);
		jqlDebuggerStoreActions.setOnSearch(onSearch);
		jqlDebuggerStoreActions.setJqlQuery(query);

		renderSyncActions.setIsContextReady(true);
	} else {
		renderSyncActions.setIsContextReady(false);
	}

	return children;
};
