import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsIssueFieldsManagementRoute } from '@atlassian/jira-router-routes-admin-pages-fields-routes/src/globalSettingsIssueFieldsManagementRoute.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { conditionalPostOfficeJiraTopBannerResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';
import { FieldsPage, LazyFieldsPage } from './ui/spa/admin-pages/fields/fields-page/index.tsx';

export const globalSettingsIssueFieldsManagementRouteEntry = createEntry(
	globalSettingsIssueFieldsManagementRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'global-admin.issues.fields',
		component: componentWithFG('centralized-fields-page-m1', FieldsPage, ErrorPagesNotFound),
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		canTransitionIn: () => fg('centralized-fields-page-m1'),
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			conditionalPostOfficeJiraTopBannerResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyFieldsPage],
	}),
);
