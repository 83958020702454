import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const polarisProductEAPSettingsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-polaris-settings-eap", jiraSpaEntry: "async-polaris-ideas" */ './src/ui'
			),
	),
	getPreloadProps: () => ({}),
});
