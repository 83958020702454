import { useMemo } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	createResource,
	useResource,
	type RouterContext,
	type Match,
	type Query,
} from '@atlassian/react-resource-router';
import type { IrremovableFieldsConfig } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import { TEAM_NAME, PACKAGE_NAME } from '../../common/constants.tsx';
import type {
	FilterFieldResponse,
	FilterFieldResponseData,
	FiltersProps,
	FetchFilterFieldsResult,
} from './types.tsx';
import { QUERY } from './gql.tsx';

const RESOURCE_TYPE_NAME = 'projectSummaryFilterFieldResource';
export const RESOURCE_TYPE_FILTER_FIELD = 'RESOURCE_TYPE_FILTER_FIELD';
const REQUEST_TYPE_FILTER_TYPE = 'com.atlassian.servicedesk:vp-origin';
const REQUEST_TYPE_FILTER_SEARCH_TEMPLATE = 'com.atlassian.servicedesk:vp-origin-searcher';
const PRIORITY_FILTER_TYPE = 'priority';
const PRIORITY_FILTER_SEARCH_TEMPLATE = 'priority';
const CREATED_FILTER_TYPE = 'created';
const CREATED_FILTER_SEARCH_TEMPLATE = 'created';

const reportError = (error: Error) =>
	fireErrorAnalytics({
		error,
		sendToPrivacyUnsafeSplunk: true,
		meta: {
			id: RESOURCE_TYPE_NAME,
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
		},
	});

const fetchFields = ['request type', 'priority', 'created'];

const fetchFilterFields = async (
	cloudId: string,
	jqlContext: string,
): Promise<FetchFilterFieldsResult> => {
	try {
		const responses = await Promise.all(
			fetchFields.map((searchString) =>
				performPostRequest<FilterFieldResponse>('/gateway/api/graphql', {
					body: JSON.stringify({
						query: QUERY,
						variables: {
							cloudId,
							contextFieldsFilter: {
								searchString,
							},
							jqlContext,
						},
					}),
					headers: {
						'content-type': 'application/json',
						'X-ExperimentalApi': 'JiraJqlBuilder',
					},
				}),
			),
		);

		const tryGetField = ({
			data,
			name,
			type,
			searchTemplate,
			displayName,
		}: {
			data: FilterFieldResponseData;
			name: string;
			type: string;
			searchTemplate: string;
			displayName?: string;
		}) => {
			const edges = data?.jira?.jqlBuilder?.fields?.edges;

			const field = edges?.find(
				(edge) => edge?.node?.type === type && edge?.node?.searchTemplate?.key === searchTemplate,
			)?.node;

			if (!field) {
				reportError(new Error(`Unable to prefetch ${name}`));
			}

			return field
				? {
						...field,
						searchTemplate: field.searchTemplate.key,
						fieldType: field.type,
						displayName: displayName ?? field.displayName,
					}
				: undefined;
		};

		return {
			requestTypeField: tryGetField({
				data: responses[0].data,
				name: 'request type',
				type: REQUEST_TYPE_FILTER_TYPE,
				searchTemplate: REQUEST_TYPE_FILTER_SEARCH_TEMPLATE,
			}),
			priorityField: tryGetField({
				data: responses[1].data,
				name: 'priority',
				type: PRIORITY_FILTER_TYPE,
				searchTemplate: PRIORITY_FILTER_SEARCH_TEMPLATE,
			}),
			createdField: tryGetField({
				data: responses[2].data,
				name: 'created',
				type: CREATED_FILTER_TYPE,
				searchTemplate: CREATED_FILTER_SEARCH_TEMPLATE,
			}),
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (!isHttpClientErrorResponse(error)) {
			reportError(error);
		}

		return {
			requestTypeField: undefined,
			priorityField: undefined,
			createdField: undefined,
		};
	}
};

const getProjectKeyOrId = (match: Match, query: Query) => {
	const { projectKey, projectId } = getProjectKeyId(match, query);
	return projectKey || projectId;
};

const filterFieldsResource = createResource({
	type: RESOURCE_TYPE_FILTER_FIELD,
	getKey: ({ match, query }: RouterContext) =>
		RESOURCE_TYPE_FILTER_FIELD + getProjectKeyOrId(match, query),
	getData: ({ match, query }, { tenantContext: { cloudId } }) =>
		fetchFilterFields(cloudId, `project = '${getProjectKeyOrId(match, query)}'`),
	maxAge: Infinity,
});

export const getProjectSummaryFilterFieldsResource = () =>
	resourceWithCondition2(() => fg('jsm_project_summary_filters'), filterFieldsResource);

export const useGetProjectSummaryFilterFields = () => {
	const { data: filterResource } = useResource(filterFieldsResource);

	return useMemo(
		() =>
			({
				requestTypeField = filterResource?.requestTypeField,
				requestTypeDisplayName,
				priorityDisplayName,
				createdDisplayName,
			}: FiltersProps): IrremovableFieldsConfig => {
				const priorityField = filterResource?.priorityField;
				const createdField = filterResource?.createdField;

				return [
					...(requestTypeField
						? [
								{
									...requestTypeField,
									...(requestTypeDisplayName && { displayName: requestTypeDisplayName }),
								},
							]
						: []),
					'status',
					'assignee',
					...(priorityField
						? [
								{
									...priorityField,
									...(priorityDisplayName && { displayName: priorityDisplayName }),
								},
							]
						: []),
					...(createdField
						? [{ ...createdField, ...(createdDisplayName && { displayName: createdDisplayName }) }]
						: []),
				];
			},
		[filterResource],
	);
};
