import { createResource, type RouterDataContext } from '@atlassian/react-resource-router';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { FormFieldsResponse } from './types.tsx';
import { getUrl } from './utils.tsx';

export const searchFieldsFormQuery: { query?: string } = {};

export const setSearchFieldsFormQuery = (query: string) => {
	searchFieldsFormQuery.query = query;
};

export const formFieldsResource = createResource<FormFieldsResponse>({
	type: 'FORM_FIELDS_RESOURCE',
	// The query.page in getData() is cached, and only changes if getKey() returns a new value
	getKey: ({ query }: { query: { page?: string } }) => `form-fields${query?.page || 1}`,
	getData: async ({ query }: RouterDataContext) =>
		fetchJson(getUrl(query?.page, searchFieldsFormQuery.query)),
	maxAge: 0,
});
