import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { fieldSettingsRelayResource } from '@atlassian/jira-router-resources-project-settings-fields/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { projectSettingsSoftwareFieldsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareFieldsRoute.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import FieldSettingsPage, { LazyFieldsSettings } from './ui/project-settings/fields/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';

const isEfaExperimentExposed = () => {
	const [expConfig] = UNSAFE_noExposureExp('efa_and_project_fields_page_for_tmp');
	return expConfig.get('isEnabled', false);
};

export const projectSettingsSoftwareFieldsRouteEntry = createEntry(
	projectSettingsSoftwareFieldsRoute,
	() => ({
		group: 'project-settings-software',
		ufoName: 'project-settings.fields.jsw-tmp',
		component: componentWithCondition(
			isEfaExperimentExposed,
			FieldSettingsPage,
			ErrorPagesNotFound,
		),
		layout: softwareProjectLayout,
		navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources(), fieldSettingsRelayResource],
		forPaint: [LazyAtlassianNavigation, LazyFieldsSettings],
	}),
);
