import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { forgeServicedeskQueueRoute } from '@atlassian/jira-router-routes-forge-routes/src/forgeServicedeskQueueRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { forgeServiceDeskQueuePageEntrypoint } from './ui/servicedesk-queue/index.tsx';

export const forgeServicedeskQueueRouteEntry = createEntry(forgeServicedeskQueueRoute, () => ({
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [...getServiceDeskNavigationResources(), getConsolidationStateResource()],

	entryPoint: () => forgeServiceDeskQueuePageEntrypoint,
}));
