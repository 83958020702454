import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { metricsOverviewPageResource } from '@atlassian/jira-router-resources-performance-metric-overview/src/services/index.tsx';
import { globalSettingsPerformanceOverviewRoute } from '@atlassian/jira-router-routes-admin-pages-performance-routes/src/globalSettingsPerformanceOverviewRoute.tsx';
import Overview, { LazyOverview } from './ui/spa/admin-pages/performance/overview/index.tsx';

export const globalSettingsPerformanceOverviewRouteEntry = createEntry(
	globalSettingsPerformanceOverviewRoute,
	() => ({
		group: 'global-settings',
		component: Overview,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			metricsOverviewPageResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyOverview],
	}),
);
