import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createRoadmap: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.action-buttons.create-roadmap',
		defaultMessage: 'Create roadmap',
		description:
			'Used as the alt text for an icon button that when used navigates to create roadmap',
	},
	errorTitle: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.action-buttons.error-title',
		defaultMessage: 'We couldn’t create roadmap',
		description: 'Flag title to communicate an error while loading Roadmaps in Navigation.',
	},
	errorDescription: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.action-buttons.error-description',
		defaultMessage: 'Please try again or refresh the page.',
		description: 'Flag description for an error occurring while loading Roadmaps in Navigation.',
	},
	errorRefresh: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.action-buttons.error-refresh',
		defaultMessage: 'Try again',
		description: 'Action visible in the Flag to refresh Roadmaps in Navigation.',
	},
	infoTitleLimit: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.action-buttons.info-title-limit',
		defaultMessage: 'Reached limit',
		description: 'Flag title to communicate information about reaching a limit for a site.',
	},
});
