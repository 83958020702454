/**
 * @generated SignedSource<<9516f6b957580a1dd0913ff88ae3e10d>>
 * @relayHash 8ab96395b697c5b81fab0afb4eebcfef
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e0cc007557fb53672f36f25ae9fd169cd95e4b69654d21b1f471f604abc11db8

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListQuery } from './src_jiraBusinessListQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e0cc007557fb53672f36f25ae9fd169cd95e4b69654d21b1f471f604abc11db8",
    "metadata": {},
    "name": "src_jiraBusinessListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
