import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { legacyRedirectRoute } from '@atlassian/jira-router-routes-legacy-redirect-routes/src/legacyRedirectRoute.tsx';
import RedirectToLegacyRouting from '@atlassian/jira-spa-apps-common/src/redirect/legacy-routing/index.tsx';

// @ts-expect-error - Type '{}' is missing the following properties from type 'Pick<Omit<Omit<RouteContext & { flagService: FlagService; createAnalyticsEvent: (arg1: { action: string; }) => UIAnalyticsEvent; }, keyof WithAnalyticsEventsProps> & RefAttributes<...>, "flagService">, keyof RouteContext | keyof RefAttributes<...>>': query, location, route, match, action
const RedirectComponent = () => <RedirectToLegacyRouting />;

export const legacyRedirectRouteEntry = createEntry(legacyRedirectRoute, () => ({
	component: RedirectComponent,
	layout: globalLayout,
	resources: [],
	isRedirect: true,
	canTransitionIn: () => false,
	forPaint: [LazyAtlassianNavigation],
}));
