// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { NavigationCollection, Props, State } from '../../types.tsx';
import { expandSectionForSelectedCollection } from '../expand-section-for-selected-collection/index.tsx';
import {
	transformRemoteNavigationCollection,
	normalizeError,
	sortCollectionsComparator,
} from '../utils.tsx';

type LoadCollectionsOptions = {
	refresh?: boolean;
};

export const loadCollections =
	(options?: LoadCollectionsOptions): Action<State, Props, Promise<NavigationCollection[]>> =>
	async (
		{ setState, getState, dispatch },
		{ navigationCollectionsRemote, cloudId, onFailure, onSuccess },
	) => {
		if (
			!cloudId ||
			!navigationCollectionsRemote ||
			!navigationCollectionsRemote.fetch ||
			getState().collections.isLoading ||
			(getState().collections.initialized && !options?.refresh)
		) {
			return getState().collections.data;
		}

		setState({
			collections: {
				...getState().collections,
				isLoading: true,
				initialized: true,
				error: null,
				data: [],
			},
		});

		try {
			const collectionsRemote = await navigationCollectionsRemote.fetch();
			const data = collectionsRemote
				.map(transformRemoteNavigationCollection)
				.sort(sortCollectionsComparator);

			setState({
				collections: {
					...getState().collections,
					isLoading: false,
					initialized: true,
					error: null,
					data,
				},
			});

			dispatch(expandSectionForSelectedCollection());
			onSuccess?.('loadCollections');

			return data;
		} catch (maybeError: unknown) {
			const error = normalizeError(maybeError);

			setState({
				collections: {
					isLoading: false,
					initialized: true,
					error,
					data: [],
				},
			});

			onFailure?.(error, 'loadCollections');

			throw maybeError;
		}
	};
