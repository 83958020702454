import { configure as configureBundleEvalTimings } from '@atlaskit/react-ufo/bundle-eval-timing';
import {
	ModuleLoadingProfiler,
	type InteractionMetrics,
} from '@atlaskit/react-ufo/interaction-metrics';
import { init as platformInit } from '@atlaskit/react-ufo/interaction-metrics-init';
import {
	configure as configureResourceTimings,
	startResourceTimingBuffer,
} from '@atlaskit/react-ufo/resource-timing';
import type {
	AssetsConfigAllAtlassianArgs,
	AssetsConfigPreloadedArgs,
} from '@atlaskit/react-ufo/common';
import { configure as configureSsrTiming } from '@atlaskit/react-ufo/ssr';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { retrieveAndSetPageLoadTrace } from '@atlassian/jira-retrieve-and-set-page-load-trace/src/index.tsx';
import { getJiraCustomData } from '@atlassian/jira-ufo-jira-additional-payload/src/index.tsx';
import { GlobalReactLooselyLazyProfiler } from '@atlassian/react-loosely-lazy';
import { jiraBundleEvalTimingsConfig } from './common/utils/jira-bundle-eval-config.tsx';
import { jiraResourceTimingsConfig } from './common/utils/jira-resource-config.tsx';
import { jiraSsrConfig } from './common/utils/jira-ssr-config.tsx';

type Capability = 'feature_flag_access' | 'react_profiler';
type InteractionKind = 'page_load' | 'transition' | 'press' | 'typing' | 'legacy' | 'hover';
type UFONameOverride = {
	readonly [ufoName: string]: {
		readonly [metricKey: string]: string;
	};
};
// Defensively typed, since this is directly user-editable
// and they could delete empty members
type UFOConfigFromFF = {
	readonly doNotAbortActivePressInteraction?: string[];
	readonly doNotAbortActivePressInteractionOnTransition?: string[];
	readonly awaitBM3TTI?: string[];
	readonly ufoNameOverrides?: UFONameOverride;
	readonly rates?: {
		readonly [key: string]: number;
	};
	readonly rules?: readonly {
		readonly test?: string;
		readonly rate?: number;
	}[];
	readonly killswitch?: readonly string[];
	readonly typingMethod?: string;
	readonly capability?: Record<Capability, number>;
	readonly kind?: Record<InteractionKind, number>;
	readonly autoGeneratedRate?: number;
	readonly removePageSegmentsUFOPrefixes?: boolean;
	readonly removeInteractionsUFOPrefixes?: boolean;
};

const getUFOConfigFromLD = (): UFOConfigFromFF => getFeatureFlagValue('ufo.config', {});

const getUFOConfigFromStatsig = () => {
	// TODO: https://product-fabric.atlassian.net/browse/AFO-2949 - replace these with Statsig dynamic config values
	return {
		autoGeneratedRate: 1000,
		enabled: true,
		removeUFOPrefixList: ['project-versions', 'filters-directory'],
		removePageSegmentsUFOPrefixes: true,
		doNotAbortActivePressInteractionOnTransition: [
			'jsm-create-cmp-request-type-from-template',
			'jsm-create-tmp-request-type-from-template',
		],
		killswitch: ['jira.fe.load.UNKNOWN'],
		awaitForSegments: {
			'global-issue.create-modal': ['issue-view-details'],
			'global-issue.create-modal-subsequent': ['issue-view-details'],
		},
		removeAllUFOPrefix: true,
		rates: {
			'issue-view-activity-button-worklog': 1,
			'issue-view-activity-button-history': 1,
			'issue-view-activity-button-comments': 1,
			'arj-toggle-view-mode': 1,
			'global-issue.create-modal-subsequent': 5,
			unknown: 1000,
			'issue-view-activity-button-forge-app': 1,
			'view-mode': 1,
			'backlog.issue-view-opened': 5,
			'create-web-link-inline-field-type-tracing': 1000,
			'issue-view-activity-button-connect-app': 1,
			'single-line-text-field-type-tracing': 100,
			'issue-view-activity-button-all': 1,
			'backlog.card-hover': 100,
			'child-issue-inline-create-type-tracing': 100,
			'show-insights-button': 100,
			'board.issue-view-opened': 5,
			'issue-view-activity-button-jsd-comments': 1,
			'backlog.card-list-toggle': 5,
			'global-issue.create-modal': 5,
			'issue-view-activity-button-approvals': 1,
		},
		kind: {
			generic: 0,
			hover: 0,
			legacy: 1,
			page_load: 1,
			press: 1,
			transition: 1,
			typing: 0,
		},
		awaitBM3TTI: [
			'jwm_project_pages',
			'software-plan-dependencies',
			'advanced-roadmaps.page-plan.dependencies-tab.load-time',
			'servicedesk-cmdb-object-type-configuration',
			'service-management.insight-cmdb-object-type-configuration',
			'project-components-view',
			'project-components',
			'project-settings-components',
			'people-index',
			'people-profiles',
			'rapidboard-report',
			'classic-report',
			'project-settings-servicedesk-ticket-type-fields',
			'project-settings.issue-type.jsm-cmp-customer-view',
			'project-settings.issue-type.jsm-cmp-agent-view',
			'archived-project-direct-link',
			'project-archived',
			'servicedesk-queues-new',
			'service-management.queues.new',
			'board-settings-apps.insights-settings-page.page.load',
			'admin-oauth-credentials',
			'admin.oauth-credentials',
			'polaris-ideas',
			'project-settings-software-boards-csm',
			'jira.fe.page-load.software.next-gen.boards.column-status-mapping.page.load',
			'project-settings-servicedesk-language',
			'service-management.languages',
			'project-settings-servicedesk-language-key',
			'service-management.translations',
			'project-settings-servicedesk-classic-apps',
			'service-management.app-discovery',
			'marketplace-index',
			'embedded-marketplace',
			'project-settings-servicedesk-portal-settings',
			'service-management.portal-settings',
			'service-management.portal-settings.portal-groups',
			'project-settings-servicedesk-automation-create',
			'service-management.automation.create-rule',
			'servicedesk-customers-v2',
			'jsm-customers-component-load',
			'archived-project-direct-link',
			'project-archived',
			'project-settings-software-boards-csm',
			'software.next-gen.boards.column-status-mapping.page.load',
			'jira-connect',
			'knowledge-hub-category',
			'service-management.knowledge.category',
			'servicedesk-queues-landing',
			'servicedesk-practice-landing',
			'servicedesk-practice-custom-and-issue',
			'servicedesk-practice-custom-issue',
			'servicedesk-practice-custom-view',
			'servicedesk-queues-custom-and-issue',
			'servicedesk-queues-custom-issue',
			'servicedesk-queues-custom-view',
		],
		rules: [],
		capability: {
			feature_flag_access: 1,
			react_profiler: 1,
		},
		typingMethod: 'timeout',
		removeInteractionsUFOPrefixes: true,
		doNotAbortActivePressInteraction: [
			'global-issue.create-modal',
			'global-issue.create-modal-subsequent',
		],
		ufoNameOverrides: {
			'global-issue-navigator': {
				'global-issue-navigator': 'global-issue-navigator-jfe',
				'new-issue-navigator': 'global-new-issue-navigator',
			},
			'global-issue-navigator-jfe': {
				'global-issue-navigator-jfe': 'global-issue-navigator-jfe',
				'new-issue-navigator': 'global-new-issue-navigator',
			},
			'polaris-ideas': {
				'project-settings.details': 'project-settings-details-polaris-ideas',
			},
			'project-settings-servicedesk-portal-settings': {
				'service-management.portal-settings': 'service-management.portal-settings',
				'service-management.portal-settings.portal-groups':
					'service-management.portal-settings.portal-groups',
			},
			'project-settings-servicedesk-ticket-type-fields': {
				'project-settings.issue-type.jsm-cmp-agent-view':
					'project-settings.issue-type.jsm-cmp-agent-view',
				'project-settings.issue-type.jsm-cmp-customer-view':
					'project-settings.issue-type.jsm-cmp-customer-view',
			},
			'servicedesk-practice-custom-and-issue': {
				'service-management.queues.issue': 'servicedesk-practice-custom-issue',
				'service-management.queues.view': 'servicedesk-practice-custom-view',
			},
			'servicedesk-queues-custom-and-issue': {
				'service-management.queues.issue': 'servicedesk-queues-custom-issue',
				'service-management.queues.view': 'servicedesk-queues-custom-view',
			},
		},
		enableCompleteUsingDoubleRAF: true,
	};
};

function getAdditionalJiraFields(interaction: InteractionMetrics) {
	try {
		return { jira: getJiraCustomData(interaction) };
	} catch (error) {
		return {};
	}
}

function loadJiraAnalyticsWebClient() {
	return import(/* webpackChunkName: "AWC" */ '@atlassian/jira-product-analytics-web-client-async');
}

export default function init() {
	const ufoConfigFromFF = fg('ufo-use-statsig-config-jira')
		? getUFOConfigFromStatsig()
		: getUFOConfigFromLD();

	retrieveAndSetPageLoadTrace(getMeta);

	configureResourceTimings(jiraResourceTimingsConfig);
	startResourceTimingBuffer();

	if (!fg('jira-disable-ufo-bundle-eval-timings')) {
		configureBundleEvalTimings(jiraBundleEvalTimingsConfig);
	}

	configureSsrTiming(jiraSsrConfig);

	GlobalReactLooselyLazyProfiler.current = ModuleLoadingProfiler;

	const perfPushFY25ExperienceRates = {
		'issue-view': 1,
		'new-issue-navigator': 1,
		'classic-board': 1,
		'classic-backlog': 1,
		'next-gen-board': 1,
		'jwm.list-view': 1,
		'jwm.jwm-board': 1,
		'jwm.summary-view': 1,
		dashboard: 1,
		'software-issue-navigator': 1,
		'global-new-issue-navigator': 1,
		'issue-navigator': 1,
		'issue-navigator-classic': 1,
		'issue-navigator-core': 1,
		'servicedesk-issues': 1,
	};

	const ssrWhitelist = [];

	if (fg('add_ssr_placeholder_replacements_to_nin_and_nav')) {
		ssrWhitelist.push(
			'software-issue-navigator',
			'global-new-issue-navigator',
			'issue-navigator-classic',
			'issue-navigator-core',
			'servicedesk-issues',
		);
	}
	if (fg('add_ssr_placeholder_replacements_to_nav_for_iv')) {
		ssrWhitelist.push('issue-view');
	}

	if (fg('add_nav_ssr_placeholder_for_board_and_backlog')) {
		ssrWhitelist.push('classic-board', 'classic-backlog');
	}

	let assetsConfig = {};

	if (fg('new_chr_classification')) {
		assetsConfig = {
			classification: {
				all: true,
				allAtlassian: ({ entry }: AssetsConfigAllAtlassianArgs) => {
					const isAtlassian =
						entry.name.includes('atl-pass.net/') ||
						(entry.name.includes('cloudfront') && entry.name.includes('batch.js'));

					return isAtlassian;
				},
				preloaded: ({ entry, SSRDoneTime }: AssetsConfigPreloadedArgs) => {
					return SSRDoneTime && entry.startTime <= SSRDoneTime;
				},
			},
		};
	}

	platformInit(loadJiraAnalyticsWebClient(), {
		...ufoConfigFromFF,
		enableSegmentHighlighting: fg('ufo_enable_segment_highlighting_jira'),
		enableAdditionalPerformanceMarks: fg('ufo_enable_segment_highlighting_jira'),
		product: 'jira',
		region: getMeta('ajs-region') || 'unknown',
		additionalPayloadData: getAdditionalJiraFields,
		postInteractionLog: {
			enabled: fg('ufo_capture_late_rerenders_jira'),
			rates: perfPushFY25ExperienceRates,
		},
		experimentalInteractionMetrics: {
			enabled: fg('ufo-enable-experimental-holds-jira'),
			rates: perfPushFY25ExperienceRates,
		},
		manuallyTrackReactProfilerMounts: fg('ufo-manually-track-segment-mount-phase-jira'),
		vc: {
			enabled: true,
			heatmapSize: 200,
			oldDomUpdates: false,
			devToolsEnabled: fg('uip_vc_devtools'),
			selectorConfig: {
				id: true,
				role: !fg('uip_disable_vc_role_selector'),
				className: true,
				testId: false,
			},
			ssr: fg('uip_vc_ssr'),
			ssrWhitelist,
			stopVCAtInteractionFinish: fg('stop_vc_at_interaction_finish'),
		},
		enableBetterPageVisibilityApi: true,
		assetsConfig,
	});
}
