import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { portalSettingsContextResource } from '@atlassian/jira-router-resources-service-desk-portal-settings-context/src/services/index.tsx';
import { projectSettingsServicedeskChannelsPortalRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskChannelsPortalRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import ServiceDeskPortalSettings, {
	LazyProjectSettingsServiceDeskPortalSettings,
} from './ui/service-desk-portal-settings/index.tsx';

export const projectSettingsServicedeskChannelsPortalRouteEntry = createEntry(
	projectSettingsServicedeskChannelsPortalRoute,
	() => ({
		group: 'project-settings-servicedesk',
		component: ServiceDeskPortalSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			projectContextResource,
			portalSettingsContextResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskPortalSettings],
	}),
);
