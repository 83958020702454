import { createResource, type RouterContext } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { RESOURCE_TYPE_RAPIDBOARDS_WRM_BUNDLE } from '../../constants.tsx';
import { getRapidViewConfigFromRouteName } from '../utils/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wrmBundleResource = createResource<any>({
	type: RESOURCE_TYPE_RAPIDBOARDS_WRM_BUNDLE,
	getKey: ({ route }: RouterContext) => {
		if (fg('reports-overview-page-migration')) {
			return getRapidViewConfigFromRouteName(route.name).stateKey;
		}
		return 'state';
	},
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-wrm-bundle", jiraSpaEntry: "async-resource-rapidboards-wrm-bundle" */ './get-data'
		),
	maxAge: Number.MAX_SAFE_INTEGER,
	isBrowserOnly: true,
});
