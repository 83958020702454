/**
 * @generated SignedSource<<9589819072a0d79deedb3286a1ad0e09>>
 * @relayHash 7ca7a7e73175fabcafbcca8378817432
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e63ab64e310db9403bb03770e66a53a6920f135e0096a5f8f31a3ac88d170c0b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListQuery } from './src_jiraSoftwareListQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e63ab64e310db9403bb03770e66a53a6920f135e0096a5f8f31a3ac88d170c0b",
    "metadata": {},
    "name": "src_jiraSoftwareListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
