import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FabricChannel } from '@atlaskit/analytics-listeners';
import { CONVERSATION_ASSISTANT_CONTAINER_ID } from '@atlassian/conversation-assistant/utils';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	useActiveRightSidebarState,
	useRightSidebarController,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { PanelSplitter } from '@atlassian/navigation-system';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraConversationAssistant } from './ui/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	CONVERSATION_ASSISTANT_CONTAINER_ID,
	CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
} from '@atlassian/conversation-assistant/utils';

const PanelInLayout = () => {
	const { formatMessage } = useIntl();
	const rightSidebarCurrentState = useActiveRightSidebarState();
	const [localPanelId, setLocalPanelId] = useState<unknown>();
	const prevPanelIdRef = useRef<unknown>();
	const delayRef = useRef<unknown>(null);
	const { closeCurrentRightSidebar } = useRightSidebarController();
	const panelId = rightSidebarCurrentState?.panelId;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleResizeComplete = useCallback((): void => {
		createAnalyticsEvent({
			action: 'resized',
			actionSubject: 'aiMatePanel',
			tags: ['atlaskit', 'aiMate'],
		}).fire(FabricChannel.aiMate);
	}, [createAnalyticsEvent]);

	/**
	 * This use effect is used to add (when is necessary) a delay
	 * in the panelId => localPanelId syncing
	 */
	useEffect(() => {
		// @ts-expect-error - TS2769 - No overload matches this call.
		clearTimeout(delayRef.current);

		if (prevPanelIdRef.current === CONVERSATION_ASSISTANT_CONTAINER_ID) {
			/**
			 * The right-sidebar is showing the conversation assistant...
			 */
			if (
				rightSidebarCurrentState &&
				panelId !== undefined &&
				!rightSidebarCurrentState.isCollapsed &&
				!rightSidebarCurrentState.isMinimised
			) {
				/**
				 * and is open, not collapsed and we want to show a panel
				 * with an ID !== CONVERSATION_ASSISTANT_CONTAINER_ID
				 */
				setLocalPanelId(panelId);
			} else {
				/**
				 * and we want to close it
				 */
				delayRef.current = setTimeout(() => {
					setLocalPanelId(panelId);

					createAnalyticsEvent({
						action: 'closed',
						actionSubject: 'aiMatePanel',
						source: 'AIMate',
						tags: ['atlaskit', 'aiMate'],
					}).fire(FabricChannel.aiMate);
				}, 310);
			}
		} else {
			/**
			 * If the right sidebar is not showing the conversation assistant,
			 * we just keep localPanelId in sync with panelId
			 */
			setLocalPanelId(panelId);
		}

		prevPanelIdRef.current = panelId;

		// @ts-expect-error - TS2769 - No overload matches this call.
		return () => clearTimeout(delayRef.current);
	}, [createAnalyticsEvent, panelId, rightSidebarCurrentState]);

	if (localPanelId !== CONVERSATION_ASSISTANT_CONTAINER_ID) {
		return null;
	}

	return (
		<>
			{getWillShowNav4() && (
				<PanelSplitter
					label={formatMessage(messages.panelSplitter)}
					onResizeEnd={handleResizeComplete}
				/>
			)}
			<JiraConversationAssistant onClosePanel={closeCurrentRightSidebar} />
		</>
	);
};

const SafeConversationAssistantPanel = () => (
	<JSErrorBoundary
		fallback="unmount"
		id="right-side-conversation-assistant-panel"
		packageName="jiraConversationAssistant"
	>
		<PanelInLayout />
	</JSErrorBoundary>
);
export { SafeConversationAssistantPanel };
