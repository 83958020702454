import { eocCommonResources } from '@atlassian/eoc-resources';

import { downloadAuditLogsResource } from '../download-export-logs-service/resources';
import { logsResource } from '../logs/resources';
import { uiSettingsResource } from '../search/resources';

export const resources = () => [uiSettingsResource, logsResource, ...eocCommonResources()];

export const downloadResources = () => [downloadAuditLogsResource, ...eocCommonResources()];

export { uiSettingsResource, logsResource, downloadAuditLogsResource };
