import { di } from 'react-magnetic-di';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type HookActionsFunction,
	type Action,
	createHook,
	createStore,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

export const isInIframe = () => {
	di(window);
	if (__SERVER__) {
		return false;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return window !== window.top;
};

export const getIsDismissedInitialState = () => {
	if (__SERVER__) {
		return false;
	}
	return true;
};

// Fetching banner preferences and height in initial state to prevent layout shifts.
export const initialState: State = {
	isDismissed: getIsDismissedInitialState(),
	height: 0,
};

export const actions = {
	onShow:
		(): Action<State> =>
		({ getState, setState }) => {
			setState({
				...getState(),
				isDismissed: false,
			});
		},
	onDismiss:
		(): Action<State> =>
		({ getState, setState }) => {
			setState({
				...getState(),
				isDismissed: true,
			});
		},
	setHeight:
		(height: number): Action<State> =>
		({ getState, setState }) => {
			setState({
				...getState(),
				height,
			});
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	name: 'cookie-consent-banner',
	initialState,
	actions,
});

export const useCookieConsentBannerSettings = createHook(Store);
// @ts-expect-error - TS2322 - Type 'HookActionsFunction<BoundActions<State, { readonly onShow: () => Action<State>; readonly onDismiss: () => Action<State>; readonly setWidth: (width: number) => Action<State>; readonly setHeight: (height: number) => Action<...>; }>>' is not assignable to type 'HookActionsFunction<{ readonly onShow: () => Action<State>; readonly onDismiss: () => Action<State>; readonly setWidth: (width: number) => Action<State>; readonly setHeight: (height: number) => Action<...>; }>'.
export const useCookieConsentBannerSettingsActions: HookActionsFunction<Actions> =
	createActionsHook(Store);
