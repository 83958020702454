import { resources as alertListResources } from '@atlassian/eoc-alert-list/resources';
import { resources as groupDetailResources } from '@atlassian/eoc-group-detail/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	AsyncSidebarEocGlobalAlerts,
	LazyGlobalAlertsSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-global-alerts/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocGlobalGroupDetailRoute } from '@atlassian/jira-router-routes-eoc-group-detail-routes/src/eocGlobalGroupDetailRoute.tsx';
import GroupList, { LazyGroupDetail } from './ui/spa/eoc/group-detail/index.tsx';

export const eocGlobalGroupDetailRouteEntry = createEntry(eocGlobalGroupDetailRoute, () => ({
	group: 'eoc',
	component: GroupList,
	resources: [
		...getNavigationResources(),
		...groupDetailResources(),
		getConsolidationStateResource(),
		...alertListResources(),
	],
	navigation: {
		menuId: MENU_ID.HOME,
		sidebar: AsyncSidebarEocGlobalAlerts,
	},
	forPaint: [LazyAtlassianNavigation, LazyGroupDetail, LazyGlobalAlertsSidebar],
}));
