import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskCmdbAssetsReportsUnsubscribeRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbAssetsReportsUnsubscribeRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	AssetsReportsUnsubscribe,
	LazyServicedeskCmdbAssetsReportsUnsubscribe,
} from './ui/reports/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbAssetsReportsUnsubscribeRouteEntry = createEntry(
	servicedeskCmdbAssetsReportsUnsubscribeRoute,
	() => ({
		group: 'servicedesk',
		layout: globalLayout,
		navigation: { menuId: MENU_ID.INSIGHT },
		component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : AssetsReportsUnsubscribe,
		resources: [
			...getNavigationResources(),
			...getServicedeskPageContextResources(),
			workspaceResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbAssetsReportsUnsubscribe],
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE),
				teamName: 'ITSM SAGA',
			},
		},
	}),
);
