// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createStore,
	createContainer,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { addCollection } from './actions/add-collection/index.tsx';
import { loadCollections } from './actions/load-collections/index.tsx';
import { deleteCollection } from './actions/delete-collection/index.tsx';
import { copyCollection } from './actions/copy-collection/index.tsx';
import { expandSectionForSelectedCollection } from './actions/expand-section-for-selected-collection/index.tsx';
import { updateCollection } from './actions/update-collection/index.tsx';
import type { State, Props } from './types.tsx';

const actions = {
	addCollection,
	loadCollections,
	expandSectionForSelectedCollection,
	updateCollection,
	deleteCollection,
	copyCollection,
};

type Actions = typeof actions;

const initialState: State = {
	collections: {
		data: [],
		error: null,
		initialized: false,
		isLoading: false,
	},
	activeCollectionUUID: undefined,
};

export const NavigationCollectionsStore = createStore<State, Actions, Props>({
	initialState,
	actions,
	name: 'PolarisNavigationCollectionsStore',
});

const onPropsChange = ({ setState }: StoreActionApi<State>, props: Props) => {
	setState({
		activeCollectionUUID: props.activeCollectionUUID,
	});
};

export const NavigationCollectionsContainer = createContainer<State, Actions, Props>(
	NavigationCollectionsStore,
	{
		onInit: () => onPropsChange,
		onUpdate: () => onPropsChange,
	},
);

export const useNavigationCollectionsActions = createActionsHook(NavigationCollectionsStore);
