import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { HorizontalNavSkeleton } from '@atlassian/jira-horizontal-nav-common/src/ui/horizontal-nav-skeleton/index.tsx';
import { customPracticeQueueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customPracticeQueueRoute.tsx';
import { customIssueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customIssueRoute.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import type HorizontalNavQueue from './ui/HorizontalNavQueue.tsx';

const LazyHorizontalNavQueue = lazyForPaint<typeof HorizontalNavQueue>(() =>
	import(/* webpackChunkName: "async-horizontal-nav-queue" */ './ui/HorizontalNavQueue').then(
		(HorizontalNavQueue) => HorizontalNavQueue,
	),
);

export const AsyncHorizontalNavQueue = () => {
	const route = useCurrentRoute();
	const [issueKey] = usePathParam('issueKey');

	const isIssueView =
		route.name === customIssueRoute.name ||
		(route.name === customPracticeQueueRoute.name && issueKey);

	const content = !isIssueView && (
		<Placeholder name="horizontal-nav-queue" fallback={<HorizontalNavSkeleton />}>
			<LazyHorizontalNavQueue />
		</Placeholder>
	);

	return getWillShowNav4() ? (
		content
	) : (
		<Box xcss={containerStyles}>
			<Box xcss={styles}>{content}</Box>
		</Box>
	);
};

const containerStyles = xcss({ position: 'relative' });
const styles = xcss({ position: 'absolute', width: '100%' });
