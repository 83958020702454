import React, { type ComponentType } from 'react';
import { ModalTransition, ModalBody, ModalTitle, ModalHeader } from '@atlaskit/modal-dialog';
import { JiraModal as AkModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import ViewTracker from '@atlassian/jira-analytics-web-react/src/components/view-tracker.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { ModalDialog as SoftwareModalDialog } from '@atlassian/jira-software-modal-dialog/src/ui/modal-dialog/index.tsx';
import messages from './messages.tsx';

const ModalDialog = componentWithFG(
	'jfp_a11y_fix_filter_actions_modal_focus',
	SoftwareModalDialog,
	AkModalDialog,
);

export type Props = {
	isOpen: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Footer: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Content: ComponentType<Record<any, any>>;
	name: string;
	onCancel: () => void;
	intl: IntlShape;
};

const DeleteModal = ({
	intl: { formatMessage },
	isOpen,
	Content,
	Footer,
	onCancel,
	name,
}: Props) => (
	<ModalTransition>
		{isOpen && (
			<ShortcutScope>
				<ModalDialog
					messageId="delete-filters-modal.modal-dialog"
					messageType="transactional"
					width="small"
					onClose={onCancel}
				>
					<ModalHeader>
						<ModalTitle appearance="danger">{formatMessage(messages.title, { name })}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Content />
						<ViewTracker />
					</ModalBody>
					<Footer onClose={onCancel} />
				</ModalDialog>
			</ShortcutScope>
		)}
	</ModalTransition>
);

export default injectIntl(DeleteModal);
