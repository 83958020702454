import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { customPracticeQueueCalendarRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customPracticeQueueCalendarRoute.tsx';
import { getQueuesResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { getCalendarFilterFieldsResource } from '@atlassian/jira-router-resources-servicedesk-calendar/src/services/filters/index.tsx';
import { horizontalNavQueueResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-queue/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { AsyncHorizontalNavQueue } from '@atlassian/jira-horizontal-nav-queue/src/async.tsx';

import {
	CalendarSkeleton,
	calendarPageEntryPoint,
	calendarPageNav3EntryPoint,
} from './ui/calendar/index.tsx';

export const customPracticeQueueCalendarRouteEntry = createEntry(
	customPracticeQueueCalendarRoute,
	() => ({
		group: 'servicedesk',
		component: ErrorPagesNotFound,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal:
				// eslint-disable-next-line jira/ff/no-preconditioning
				fg('jsm_views_inside_queues_-_main_flag') && !getWillShowNav4()
					? AsyncHorizontalNavQueue
					: undefined,
		},
		forPaint: [LazyAtlassianNavigation],
		entryPoint: () => {
			if (fg('jsm_views_inside_queues_-_main_flag')) {
				return getWillShowNav4() ? calendarPageEntryPoint : calendarPageNav3EntryPoint;
			}
			return undefined;
		},
		skeleton: CalendarSkeleton,
		resources: [
			...getQueuesResources(),
			getConsolidationStateResource(),
			getCalendarFilterFieldsResource(),
			resourceWithCondition2(() => !getWillShowNav4(), horizontalNavQueueResource),
			resourceWithCondition2(() => !__SERVER__, uifBoardResource),
		],
	}),
);
