import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { serviceDeskServicesWithoutProjectRoute } from '@atlassian/jira-router-routes-servicedesk-services-routes/src/serviceDeskServicesWithoutProjectRoute.tsx';
import ServiceDeskServicesWithoutProjectContext, {
	LazyServicedeskServicesWithoutProjectContext,
} from './ui/services-without-project-context/index.tsx';

export const serviceDeskServicesWithoutProjectRouteEntry = createEntry(
	serviceDeskServicesWithoutProjectRoute,
	() => ({
		group: 'servicedesk',
		ufoName: 'service-management.services-without-project',
		component: ServiceDeskServicesWithoutProjectContext,
		resources: [...getNavigationResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskServicesWithoutProjectContext],
		meta: {
			reporting: {
				id: 'servicedesk-services-without-project',
				packageName: 'jiraSpaAppsServicedeskServicesWithoutProjectContext"',
				teamName: 'Falcons',
			},
		},
	}),
);
