/**
 * @generated SignedSource<<bbab04c071b13e6260e5510bf40dce7d>>
 * @relayHash 444679d14e7ecdbf4baf8b6b3566d94f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0c34169bf7c147ea89ec061b04eaf98de6da2b49dee73be2fbc895253423b35f

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4NotificationsQuery$variables = Record<PropertyKey, never>;
export type Nav4NotificationsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Notifications">;
};
export type Nav4NotificationsQuery = {
  response: Nav4NotificationsQuery$data;
  variables: Nav4NotificationsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "Nav4NotificationsQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Nav4Notifications"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Nav4NotificationsQuery",
    "selections": [
      {
        "concreteType": "InfluentsNotificationQuery",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "unseenNotificationCount"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0c34169bf7c147ea89ec061b04eaf98de6da2b49dee73be2fbc895253423b35f",
    "metadata": {},
    "name": "Nav4NotificationsQuery",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "d448e13b720687c07d75fad3fc7575b7";

export default node;
