import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { activityFeedResource } from '@atlassian/jira-router-resources-business-summary-activity-feed/src/index.tsx';
import { summaryDataResource } from '@atlassian/jira-router-resources-business-summary-data/src/index.tsx';
import { jiraBusinessSummaryEmbedRoute } from '@atlassian/jira-router-routes-business-summary-routes/src/jiraBusinessSummaryEmbedRoute.tsx';
import { summaryEmbedEntrypoint } from '@atlassian/jira-spa-apps-business-summary-embed/entrypoint.tsx';
import { summaryBusinessEmbedEntrypoint } from '@atlassian/jira-spa-apps-summary/business-embed/entrypoint.tsx';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * @deprecated delete this entrypoint when cleaning up jwm-summary-page-migration experiment flag
 */
const summaryEmbedPageEntrypoint = createChromelessEntryPoint({
	main: summaryEmbedEntrypoint,
});

const summaryEmbedBusimessPageEntrypoint = createChromelessEntryPoint({
	main: summaryBusinessEmbedEntrypoint,
});

const getSummaryPageResource = () => {
	return [
		...(fg('helios_biz_project_remove_rrr') ? [] : [summaryDataResource]),
		...(fg('helios_biz_project_fast_activity_feed') ? [] : [activityFeedResource]),
	];
};

export const jiraBusinessSummaryEmbedRouteEntry = createEntry(
	jiraBusinessSummaryEmbedRoute,
	() => ({
		group: 'classic-business',
		ufoName: 'jwm.summary-view-embed',
		layout: chromelessLayout,
		skeleton: ThemedLazySkeleton,

		resources: [...getProjectViewResources(), ...getSummaryPageResource()],

		entryPoint: () => {
			if (expVal('jwm-summary-page-migration', 'isEnabled', false)) {
				return summaryEmbedBusimessPageEntrypoint;
			}

			return summaryEmbedPageEntrypoint;
		},
	}),
);
