import { useEffect } from 'react';
import {
	performGetRequest,
	performPatchRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService, toFlagId } from '@atlassian/jira-flags';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore, type StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';
import type { StoreState } from './types.tsx';

type Actions = typeof actions;
export const flagId = toFlagId('byok-settings-enabled-success-flag-key');
const LEARN_MORE_ABOUT_BYOK_COMPLIANCE_SETTING_LINK =
	'https://support.atlassian.com/security-and-access-policies/docs/data-managed-with-byok-encryption/24h';

const actions = {
	fetchByokComplianceSetting:
		(cloudId: string) =>
		async ({ setState, getState }: StoreActionApi<StoreState>) => {
			const state = getState();
			if (state.loading === true) return;

			setState({
				...state,
				loading: true,
			});

			try {
				const { data } = await performGetRequest(
					`/gateway/api/jsm/ops/web/${cloudId}/v1/customers/byok-setting`,
				);
				setState({
					...state,
					data,
					loading: false,
					error: undefined,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({
					...state,
					loading: false,
					error,
				});
			}
		},
	updateByokComplianceSetting:
		(option: boolean, cloudId: string) =>
		async ({ setState, getState }: StoreActionApi<StoreState>) => {
			const state = getState();
			if (state.loading === true) return;

			const { showFlag, dismissFlag } = useFlagsService();
			setState({
				...state,
				loading: true,
			});

			try {
				await performPatchRequest(`/gateway/api/jsm/ops/web/${cloudId}/v1/customers/byok-setting`, {
					body: JSON.stringify({
						ugcPrimaryLogEnabledForByok: option,
					}),
				});
				if (option) {
					showFlag({
						id: flagId,
						title: messages.byokComplianceSettingEnabledSuccessTitle,
						type: 'success',
						description: messages.byokComplianceSettingEnabledSuccessDescription,
						messageId:
							'products-jsm-compliance-main.services.byok-compliance-setting.show-flag.success',
						messageType: 'transactional',
						actions: [
							{
								content: messages.flagOk,
								onClick: () => {
									dismissFlag(flagId);
								},
							},
							{
								content: messages.flagLearnMore,
								href: LEARN_MORE_ABOUT_BYOK_COMPLIANCE_SETTING_LINK,
								target: '_blank',
							},
						],
					});
				}
				setState({
					...state,
					data: {
						byokCustomer: state.data?.byokCustomer ?? false,
						ugcPrimaryLogEnabledForByok: option,
					},
					loading: false,
					error: undefined,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({
					...state,
					loading: false,
					error,
				});
			}
		},
};

const Store = createStore<StoreState, Actions>({
	initialState: {
		data: null,
		loading: false,
		error: undefined,
	},
	actions,
	name: 'BYOKComplianceSetting',
});

const useByokComplianceSettingData = createHook(Store);

export const useByokComplianceSettingWithInitialisation = () => {
	const [ByokComplianceSettingState, { fetchByokComplianceSetting, updateByokComplianceSetting }] =
		useByokComplianceSettingData();
	const { cloudId } = useTenantContext();

	useEffect(() => {
		if (!ByokComplianceSettingState.data && fg('audit-logs-byok-encrypted-data')) {
			fetchByokComplianceSetting(cloudId);
		}
	}, [ByokComplianceSettingState.data, cloudId, fetchByokComplianceSetting]);

	return {
		loading: ByokComplianceSettingState.loading,
		error: ByokComplianceSettingState.error,
		data: ByokComplianceSettingState.data,
		isByokComplianceSettingEnabled:
			ByokComplianceSettingState.data?.ugcPrimaryLogEnabledForByok ?? false,
		isByokCustomer: ByokComplianceSettingState.data?.byokCustomer ?? false,
		enableSetting: () => updateByokComplianceSetting(true, cloudId),
		disableSetting: () => updateByokComplianceSetting(false, cloudId),
	};
};
