import {
	WRM_BOARD_RESOURCES_FHD_ARRAY,
	WRM_BACKLOG_RESOURCES_FHD_ARRAY,
	WRM_RESOURCES_ARRAY,
} from '../../../constants.tsx';
import type { RapidViewRouteConfig } from './types.tsx';

export const RAPID_VIEW_ROUTE_CONFIG: Record<string, RapidViewRouteConfig> = {
	BOARD: {
		routes: [
			'rapidboard-board',
			'rapidboard-board-embed',
			'rapidboard-user-board',
			'servicedesk-boards',
			'servicedesk-queues-custom-board',
			'servicedesk-queues-practice-custom-board',
		],
		resources: WRM_BOARD_RESOURCES_FHD_ARRAY,
		stateKey: 'board-state',
	},
	BACKLOG: {
		routes: ['rapidboard-backlog', 'rapidboard-user-backlog', 'rapidboard-backlog-embed'],
		resources: WRM_BACKLOG_RESOURCES_FHD_ARRAY,
		stateKey: 'backlog-state',
	},
	DEFAULT: {
		resources: WRM_RESOURCES_ARRAY,
		stateKey: 'report-state',
	},
} as const;
