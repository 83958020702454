/**
 * @generated SignedSource<<3fbad09b7a5f56fa90258f09408fcab9>>
 * @relayHash 09e2853d076a3827094f0b2a31a60cad
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e0425937923f1cfadd146be550944e5e36b0ec94fb0732af23a465a8c1c70cf5

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListEmbedQuery } from './src_jiraBusinessListEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e0425937923f1cfadd146be550944e5e36b0ec94fb0732af23a465a8c1c70cf5",
    "metadata": {},
    "name": "src_jiraBusinessListEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
