import React, { memo } from 'react';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/sidebar-boundary/index.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Nav4RoadmapsProps } from './ui/roadmaps/index.next.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';
import { RoadmapsStatic } from './ui/roadmaps/static/index.tsx';
import { ActionButtonsStatic } from './common/ui/action-buttons-static/index.tsx';
import { useIsCollectionSelected } from './common/utils/router.tsx';

const LazySidebarContent = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-roadmaps" */ './ui/roadmaps/index.next.tsx'
	).then(({ Nav4RoadmapsContainer }) => Nav4RoadmapsContainer),
);

export const Nav4RoadmapsNext = memo(({ isExpanded }: Nav4RoadmapsProps) => {
	const isCollectionSelected = useIsCollectionSelected();

	return (
		<SidebarBoundary
			id="nav4.sidebar.roadmaps"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
			ufoName="nav4-sidebar-roadmaps"
		>
			<Placeholder
				name="nav4-sidebar-roadmaps"
				fallback={
					<RoadmapsStatic isExpanded={isExpanded} actionsOnHover={<ActionButtonsStatic />}>
						{isCollectionSelected && <SkeletonNavMenuItems />}
					</RoadmapsStatic>
				}
			>
				<LazySidebarContent isExpanded={isExpanded} />
			</Placeholder>
		</SidebarBoundary>
	);
});
