import { JiraProjectAri } from '@atlassian/ari/jira';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { withGiraAggAnonSsrFix } from '@atlassian/jira-navigation-apps-resources/src/common/utils.tsx';
// eslint-disable-next-line jira/import-whitelist
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common/src/index.tsx';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import QUERY, {
	type projectNavigation_horizontalNavigationQuery as BusinessHorizontalNavQuery,
} from '@atlassian/jira-relay/src/__generated__/projectNavigation_horizontalNavigationQuery.graphql';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';

const RESOURCE_TYPE_KEY = 'RESOURCE_TYPE_BUSINESS_HORIZONTAL_NAVIGATION';

const getProjectAri = (projectId: string, cloudId: string): string => {
	try {
		return projectId
			? JiraProjectAri.create({
					siteId: cloudId,
					projectId,
				}).toString()
			: '';
	} catch (err) {
		log.safeErrorWithoutCustomerData(
			'jiraRouterResourcesNavigationItems',
			'Failed to get project ari',
		);
		return '';
	}
};

export const businessHorizontalNav3Resource = withGiraAggAnonSsrFix(
	createRelayResource<BusinessHorizontalNavQuery>({
		type: RESOURCE_TYPE_KEY,
		getQuery: ({ match, query }, { tenantContext }) => {
			const { projectKey, projectId } = getProjectKeyId(match, query);
			const { cloudId } = tenantContext;
			const projectIdOrKey = projectKey || projectId;

			const scopeId = getProjectAri(projectId, cloudId);

			return {
				parameters: QUERY,
				variables: {
					cloudId,
					projectIdOrKey,
					first: NAVIGATION_ITEMS_PAGE_SIZE,
					isConsolidatedQuery: false,
					containerNavigationInput: scopeId
						? { scopeId }
						: { projectKeyQuery: { cloudId, projectKey } },
					useContainerNavigation: false,
					useNewProjectHeader: false,
					projectKey,
					projectAri: scopeId,
					useProjectKey: Boolean(projectKey),
					isJiraCustomTemplatesUxEnabled: fg('jira_custom_templates_ux'),
				},
				options: { fetchPolicy: 'store-and-network' },
			};
		},
	}),
);
