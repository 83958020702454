import { getTrialButtonBillingInformationGraphql } from './trial-button/index.tsx';
import { getBillingInformationGraphql } from './commerce-shared-api/index.tsx';
import type {
	BillingInformationArgs,
	BillingInformation,
	TrialButtonBillingInformation,
} from './types.tsx';

export const getBillingInformation = async ({
	cloudId,
	productKey,
	relayEnvironment,
}: BillingInformationArgs): Promise<BillingInformation> => {
	if (!cloudId) {
		throw new Error('empty cloudId');
	}

	if (!productKey) {
		throw new Error('empty productKey');
	}

	return getBillingInformationGraphql(cloudId, productKey, relayEnvironment);
};

/**
 * Used for intermediate phase of EA migration where we are performing Audience and Timing
 * logic in platform and thus do not need to fetch all the information required by the
 * commerceSharedApiEditionAwarenessTrialQuery query
 */
export const getBillingInformationForTrialButton = async ({
	cloudId,
	productKey,
	relayEnvironment,
}: BillingInformationArgs): Promise<TrialButtonBillingInformation> => {
	if (!cloudId) {
		throw new Error('empty cloudId');
	}

	if (!productKey) {
		throw new Error('empty productKey');
	}

	return getTrialButtonBillingInformationGraphql(cloudId, productKey, relayEnvironment);
};
