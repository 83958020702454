import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noCreatorsLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.no-creators-left',
		defaultMessage: 'No creators left',
		description:
			'Informs the user that there are no creators left and they may need to upgrade their product',
	},
	limitedCreatorsLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.limited-creators-left',
		defaultMessage: 'Limited creators left',
		description:
			'Informs the user that there are limited creators left before they need to upgrade their product',
	},
	noAgentsLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.no-agents-left',
		defaultMessage: 'No agents left',
		description:
			'Informs the user that there are no agents left and they may need to upgrade their product',
	},
	limitedAgentsLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.limited-agents-left',
		defaultMessage: 'Limited agents left',
		description:
			'Informs the user that there are limited agents left before they need to upgrade their product',
	},
	noUsersLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.no-users-left',
		defaultMessage: 'No users left',
		description:
			'Label for an upgrade button shown to users on a Jira Free plan prompting them to upgrade to a paid plan, indicates that the customer has reached the user limit of the free plan and needs to upgrade to be able to add more users',
	},
	limitedUsersLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.limited-users-left',
		defaultMessage: 'Limited users left',
		description:
			'Label for an upgrade button shown to users on a Jira Free plan prompting them to upgrade to a paid plan, indicates that the customer is close to reaching the user limit of the free plan and will needs to upgrade soon to be able to add more users',
	},
	upgrade: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.upgrade',
		defaultMessage: 'Upgrade',
		description: 'Informs the user that they can upgrade their product',
	},
	eduOrgDiscount: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.edu-org-discount.non-final',
		defaultMessage: 'Education discount',
		description: 'Informs the user that there is a discount available for educational sites',
	},
});
