import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsCustomerNotificationsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsCustomerNotificationsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { navigation } from './common/constants.tsx';
import {
	HelpCenterSettingsCustomerNotifications,
	LazyHelpCenterSettingsCustomerNotifications,
} from './ui/customer-notifications/index.tsx';

export const helpCenterSettingsCustomerNotificationsRouteEntry = createEntry(
	helpCenterSettingsCustomerNotificationsRoute,
	() => ({
		group: 'helpcenter-settings',
		perfMetricKey: 'help-center-settings.customer-notifications',
		layout: serviceProjectLayout,
		navigation,
		component: componentWithFG(
			'jcs_master_flag',
			HelpCenterSettingsCustomerNotifications,
			ErrorPagesNotFound,
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsCustomerNotifications],
		resources: [...getServiceDeskSettingsResources()],
	}),
);
