import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { projectVersionsRelayResource } from '@atlassian/jira-router-resources-project-versions/src/controllers/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectVersionsRoute } from '@atlassian/jira-router-routes-projects-versions-routes/src/projectVersionsRoute.tsx';
import type RelayProjectVersionsType from '@atlassian/jira-spa-apps-relay-project-versions/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyRelayProjectVersions = lazyForPaint<typeof RelayProjectVersionsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-relay-project-versions", jiraSpaEntry: "async-relay-project-versions" */ '@atlassian/jira-spa-apps-relay-project-versions'
		),
	),
);

const RelayVersions = () => (
	<LazyPage
		Page={LazyRelayProjectVersions}
		pageId="relay-project-versions"
		shouldShowSpinner={false}
	/>
);

export const projectVersionsRouteEntry = createEntry(projectVersionsRoute, () => ({
	group: 'components',
	resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		projectVersionsRelayResource,
	],

	component: RelayVersions,
	layout: genericProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},

	forPaint: [LazyAtlassianNavigation, LazyRelayProjectVersions],
}));
