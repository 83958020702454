import {
	resources as opsReportsResources,
	opsReportsPermissionResource,
} from '@atlassian/eoc-ops-reports-common/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { productsJsmGlobalOpsReportsRedirectRoute } from '@atlassian/jira-router-routes-products-jsm-global-ops-reports-routes/src/productsJsmGlobalOpsReportsRedirectRoute.tsx';
import { LazyGlobalOpsReports, OpsReportsRedirect } from './ui/eoc/ops-reports/index.tsx';

export const productsJsmGlobalOpsReportsRedirectRouteEntry = createEntry(
	productsJsmGlobalOpsReportsRedirectRoute,
	() => ({
		group: 'global-settings',
		component: OpsReportsRedirect,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			...opsReportsResources(),
			opsReportsPermissionResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyGlobalOpsReports],
	}),
);
