import React, { type ReactNode, useMemo, useEffect, useRef, useCallback, StrictMode } from 'react';
import { addBreadcrumb } from '@sentry/browser';
import { createPath } from 'history/PathUtils';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import getUFORouteName from '@atlaskit/react-ufo/route-name';
import traceUFORedirect from '@atlaskit/react-ufo/trace-redirect';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PlaceholderWithNativeSuspense } from '@atlassian/jira-placeholder/src/index.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { stopInitialPageLoadTimingFromPerformanceMarkStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { useSpaStateActions } from '@atlassian/jira-spa-state-controller/src/common/index.tsx';
import { createCheckShouldResetPage } from '@atlassian/jira-spa-state-controller/src/main.tsx';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import {
	type Location,
	type MatchedRoute,
	matchRoute,
	createRouterSelector,
	useRouterActions,
	type Route,
} from '@atlassian/react-resource-router';
import { useShouldPerformAction, type RemoteAction } from '@atlassian/remote-actions';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import transitionValidator from '../services/transition-validator/index.tsx';
import { ConcurrentRootComponent } from './concurrent-route-component/index.tsx';
import { RouteEntryPointContainer } from './entry-point-container/index.tsx';

type Props = {
	initRoute: MatchedRoute | null;
	routes: Route[];
};
const REMOTE_ACTIONS_OFF = 'OFF' as const;
const REMOTE_ACTIONS_PERFORM = 'PERFORM' as const;
const useRouterLocation = createRouterSelector((state) => state.location);
const useRouterAction = createRouterSelector((state) => state.action);
const useRouterQuery = createRouterSelector((state) => state.query);
const useRouterRoute = createRouterSelector((state) => state.route);
const useRouterMatch = createRouterSelector((state) => state.match);
const useRouterRoutes = createRouterSelector((state) => state.routes);
const useIsLazyRoutes = createRouterSelector((state) => state.isLazyRoutes);
let hasPendingForceReloadAction = false;

const getRemoteActionsRolloutStage = () =>
	fg('remote_actions_-_killswitch_-_temporary') ? REMOTE_ACTIONS_PERFORM : REMOTE_ACTIONS_OFF;

// @ExportForTesting
export const setHasPendingForceReloadAction = (value: boolean) => {
	hasPendingForceReloadAction = value;
};
// @ExportForTesting
export const getHasPendingForceReloadAction = () => hasPendingForceReloadAction;
const fireRemoteActionAnalytics = (
	analyticsEvent: UIAnalyticsEvent,
	eventAction: string,
	remoteAction: RemoteAction,
	currentRoute: MatchedRoute | null,
	targetRoute: MatchedRoute | null,
	attempts: number,
	lastAttemptedAt: number | undefined,
) =>
	fireOperationalAnalytics(analyticsEvent, `remoteAction ${eventAction}`, {
		source: 'transitionBlocker',
		actionId: remoteAction != null && remoteAction.id != null ? String(remoteAction.id) : '',

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		frontendVersion: window.BUILD_KEY,
		currentRouteName: currentRoute?.route.name || '',
		targetRouteName: targetRoute?.route.name || '',
		remoteActionsRolloutStage: getRemoteActionsRolloutStage(),
		attempts,
		lastAttemptedAt,
	});
const updateSentryBreadcrumb = (route: Route, nextRoute: Route | undefined) => {
	const from = route.path + (route.query ? `?${route.query.join('&')}` : '');
	const to = nextRoute?.path + (nextRoute?.query ? `?${nextRoute.query.join('&')}` : '');
	addBreadcrumb({
		type: 'navigation',
		data: {
			from,
			to,
		},
	});
};
/**
 * Manages the routing, analytics, and remote actions within a single-page application (SPA).
 * This component sets up initial routes, enables analytics tracking, and handles SPA transitions.
 * It incorporates custom logic for page reloading or redirection under certain conditions
 * and ensures safe rendering of route components within an error boundary context.
 */
const Spa = ({ routes, initRoute = null }: Props) => {
	useMemo(() => {
		setMark('jira-spa/view.start');
	}, []);
	const checkShouldResetPage = useMemo(() => createCheckShouldResetPage(), []);
	const currentMatchObjectRef = useRef(initRoute);
	useEffect(() => {
		stopInitialPageLoadTimingFromPerformanceMarkStart('jira-spa/view', 'jira-spa/view.start', true);
	}, []);
	const { registerBlock } = useRouterActions();
	const location = useRouterLocation();
	const action = useRouterAction();
	const query = useRouterQuery();
	const route = useRouterRoute();
	const match = useRouterMatch();
	const isLazyRoutes = useIsLazyRoutes();
	const routerRoutes = useRouterRoutes();

	// If the Lazy Route Map experiment is enabled, we want to use the routes from
	// the router, not from props as they will be changing dynamically during page lifetime
	if (isLazyRoutes) {
		// eslint-disable-next-line no-param-reassign
		routes = routerRoutes;
	}

	const { shouldPerformAction, performAction } = useShouldPerformAction({
		actionName: 'forceReload',
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();
	/* We update currentMatch only if it's not a redirect. So in canTransitionIn we can compare
	 * actual routes instead of comparing against the redirect one */
	if (route.isRedirect !== true) {
		currentMatchObjectRef.current = {
			route,
			match,
		};
	}
	const [, { resetCurrentPage }] = useSpaStateActions();

	/* We regiser the blocker on every render to be sure that it gets added as first item
	 * even when the route changes (so it gets called last). It will be added only once anyway.
	 * This is our custom block() API that accepts promises.
	 * This code allows us to disable an SPA transition and trigger a full page reload
	 * in case the route we are moving out (or in) needs it. */
	const transitionBlocker = useCallback(
		(nextLocation: Location, nextAction: 'PUSH' | 'REPLACE') => {
			// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Query | undefined'.
			const nextMatch = matchRoute(routes, nextLocation.pathname, nextLocation.search);
			const canDo = transitionValidator(currentMatchObjectRef.current, nextMatch);

			// Checking how many routes is a proxy for determining whether the lazy routes have been loaded
			// canDo might still be true if this is a transition from an issue to an issue
			if (isLazyRoutes && !canDo && routes.length <= 3) {
				log.safeInfoWithoutCustomerData('lazy_routes.transition', 'failure', {
					route: nextMatch?.route.name,
				});
			}

			if (!canDo) {
				const windowAction = nextAction === 'REPLACE' ? 'replace' : 'assign';

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.location[windowAction](createPath(nextLocation));
			}
			const remoteActionsRolloutStage = getRemoteActionsRolloutStage();
			if (remoteActionsRolloutStage !== REMOTE_ACTIONS_OFF && createAnalyticsEvent !== null) {
				const currentMatch = currentMatchObjectRef.current;
				if (
					canDo &&
					currentMatch?.route.name !== nextMatch?.route.name &&
					shouldPerformAction &&
					hasPendingForceReloadAction === false
				) {
					hasPendingForceReloadAction = true;
					const windowAction = nextAction === 'REPLACE' ? 'replace' : 'assign';
					performAction(
						(remoteAction, { attempts, lastAttemptedAt }) => {
							fireRemoteActionAnalytics(
								createAnalyticsEvent({}),
								'performed',
								remoteAction,
								currentMatch,
								nextMatch,
								attempts,
								lastAttemptedAt,
							);
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							window.location[windowAction](createPath(nextLocation));
						},
						(remoteAction, { attempts, lastAttemptedAt }) => {
							fireRemoteActionAnalytics(
								createAnalyticsEvent({}),
								'cancelled',
								remoteAction,
								currentMatch,
								nextMatch,
								attempts,
								lastAttemptedAt,
							);
						},
					);
				}
			}
			return canDo;
		},
		[performAction, routes, shouldPerformAction, createAnalyticsEvent, isLazyRoutes],
	);
	const resetCurrentPageBeforeRouteChangeBlocker = useCallback(
		(nextLocation: Location, nextAction: 'PUSH' | 'REPLACE' | 'POP') => {
			// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Query | undefined'.
			const nextMatchObject = matchRoute(routes, nextLocation.pathname, nextLocation.search);
			if (nextMatchObject && match && route) {
				const { match: nextMatch, route: nextRoute } = nextMatchObject;
				const shouldReset = checkShouldResetPage({
					nextMatch,
					nextRoute,
					currentMatch: match,
					currentRoute: route,
					action: nextAction,
				});
				const nextRouteUfoName = getUFORouteName(nextRoute);
				if (shouldReset) {
					resetCurrentPage(nextRouteUfoName, nextRoute.name);
					GlobalPageLoadExperience.setPageLoadId(nextRoute.perfMetricKey || 'UNKNOWN');
				}
				const isCurrentRouteRedirecting = route.isRedirect === true;
				if (isCurrentRouteRedirecting) {
					const currentRouteName = getUFORouteName(route);
					traceUFORedirect(currentRouteName, nextRouteUfoName, nextRoute.name, performance.now());
				}
			}
			updateSentryBreadcrumb(route, nextMatchObject?.route);
			return true;
		},
		[checkShouldResetPage, match, resetCurrentPage, route, routes],
	);
	registerBlock(transitionBlocker);
	registerBlock(resetCurrentPageBeforeRouteChangeBlocker);

	const renderRouteComponent = useCallback(() => {
		const {
			component: RouteComponent,
			shouldOptOutConcurrentMode,
			isStrictModeEnabled,
			skeleton,
		} = route;
		const Skeleton = skeleton || Spinner;

		if (fg('jira-concurrent-incremental')) {
			if (!shouldOptOutConcurrentMode) {
				return fg('fix-suspense-tesseract-error') ? (
					<PlaceholderWithNativeSuspense name="concurrent-root" fallback={<Skeleton />}>
						<ConcurrentRootComponent />
					</PlaceholderWithNativeSuspense>
				) : (
					<ConcurrentRootComponent />
				);
			}
		}

		if (RouteComponent) {
			if (fg('fix-suspense-tesseract-error')) {
				return (
					<ConditionalStrictMode isEnabled={isStrictModeEnabled}>
						<PlaceholderWithNativeSuspense name="route-component" fallback={<Skeleton />}>
							<RouteComponent
								location={location}
								route={route}
								match={match}
								action={action}
								query={query}
							/>
						</PlaceholderWithNativeSuspense>
					</ConditionalStrictMode>
				);
			}

			return (
				<ConditionalStrictMode isEnabled={isStrictModeEnabled}>
					<RouteComponent
						location={location}
						route={route}
						match={match}
						action={action}
						query={query}
					/>
				</ConditionalStrictMode>
			);
		}
	}, [action, location, match, query, route]);

	if (!(route && match)) {
		return null;
	}
	const { meta, skeleton } = route;
	const Skeleton = skeleton || Spinner;
	const entryPoint = route.entryPoint?.();
	const { reporting } = meta || {};

	const renderRouteEntryPointContainer = () => {
		if (fg('fix-suspense-tesseract-error')) {
			return (
				<PlaceholderWithNativeSuspense name="route-entry-point-container" fallback={<Skeleton />}>
					<RouteEntryPointContainer
						location={location}
						route={route}
						match={match}
						action={action}
						query={query}
					/>
				</PlaceholderWithNativeSuspense>
			);
		}

		return (
			<RouteEntryPointContainer
				location={location}
				route={route}
				match={match}
				action={action}
				query={query}
			/>
		);
	};

	/* Render a <Route> component instead of the page directly so the match prop can be
	 * propagated down to components wrapped with the withRouter HOC. Unfortunately, this
	 * means we forfeit the right to access query params via match is provided by <Route>
	 * rather than our custom matchRoute function. */
	return (
		<UFOLabel name="route-component">
			<JSErrorBoundary
				// explicitly coerce undefined to string to make flow happy
				id={`routeApp.${String(route.name)}`}
				packageName="jiraSpa"
				extraEventData={{
					routeGroup: route.group ?? '',
					isSSR: __SERVER__,
				}}
				fallback="page"
				sendToPrivacyUnsafeSplunk={Boolean(reporting)}
				{...reporting}
			>
				{entryPoint ? renderRouteEntryPointContainer() : renderRouteComponent()}
			</JSErrorBoundary>
		</UFOLabel>
	);
};

type ConditionalStrictModeProps = {
	isEnabled?: boolean;
	children: ReactNode;
};

function ConditionalStrictMode(props: ConditionalStrictModeProps): JSX.Element {
	// Default to true
	const { isEnabled = true, children } = props;

	// This flag just gives us a kill switch
	if (isEnabled && fg('jfp-jira-incremental-strict-mode')) {
		return <StrictMode>{children}</StrictMode>;
	}

	return <>{children}</>;
}

export default Spa;
