import {
	USER_SEGMENTATION_TEAM_TYPE_DESIGN,
	USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT,
	USER_SEGMENTATION_TEAM_TYPE_MARKETING,
	USER_SEGMENTATION_TEAM_TYPE_OPERATIONS,
	USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL,
	USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT,
	USER_SEGMENTATION_TEAM_TYPE_SOFTWARE,
} from '@atlassian/jira-onboarding-core/src/constants.tsx';
import {
	TAILORED_ONBOARDING_SUMMARY,
	TAILORED_ONBOARDING_LIST,
	TAILORED_ONBOARDING_TIMELINE,
	TAILORED_ONBOARDING_PAGES,
	TAILORED_ONBOARDING_FORM,
	TAILORED_ONBOARDING_INVITE,
	TAILORED_ONBOARDING_BOARD,
	TAILORED_ONBOARDING_CALENDAR,
	TAILORED_ONBOARDING_BACKLOG,
	TAILORED_ONBOARDING_CODE,
} from '../../../../common/constants.tsx';
import type { Cards, TaskList } from '../../../../common/types.tsx';
import { messages } from './messages.tsx';
import { PROJECT_MANAGEMENT_VARIANTS } from './project-management/index.tsx';
import { PRODUCT_MANAGEMENT_VARIANTS } from './product-management/index.tsx';
import { DESIGN_VARIANTS } from './design/index.tsx';
import { IT_SUPPORT_VARIANTS } from './it-support/index.tsx';
import { OTHER_PERSONAL_VARIANTS } from './other-personal/index.tsx';
import { MARKETING_VARIANTS } from './marketing/index.tsx';
import { OPERATIONS_VARIANTS } from './operations/index.tsx';
import { EVERYTHING_ELSE_VARIANTS } from './everything-else/index.tsx';
import { SOFTWARE_VARIANTS } from './software/index.tsx';
import { TAILORED_ONBOARDING_SINGLE_CONFIG } from './shared-config.tsx';

export const TASK_LIST: TaskList = Object.freeze({
	root: {
		title: messages.rootTitle,
		items: [
			TAILORED_ONBOARDING_SUMMARY,
			TAILORED_ONBOARDING_CALENDAR,
			TAILORED_ONBOARDING_LIST,
			TAILORED_ONBOARDING_TIMELINE,
			TAILORED_ONBOARDING_PAGES,
			TAILORED_ONBOARDING_FORM,
			TAILORED_ONBOARDING_INVITE,
			TAILORED_ONBOARDING_BOARD,
		],
	},
});

// https://hello.atlassian.net/wiki/spaces/agile/pages/1093679513/Documentation+QS+Card+manager
export const TASK_VARIANTS: Cards = {
	...PRODUCT_MANAGEMENT_VARIANTS,
	...PROJECT_MANAGEMENT_VARIANTS,
	...DESIGN_VARIANTS,
	...IT_SUPPORT_VARIANTS,
	...OTHER_PERSONAL_VARIANTS,
	...MARKETING_VARIANTS,
	...OPERATIONS_VARIANTS,
	...EVERYTHING_ELSE_VARIANTS,
};

const getTaskVariants = (teamType: string): Cards => {
	switch (teamType) {
		case USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT:
			return PRODUCT_MANAGEMENT_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT:
			return PROJECT_MANAGEMENT_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_DESIGN:
			return DESIGN_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT:
			return IT_SUPPORT_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL:
			return OTHER_PERSONAL_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_MARKETING:
			return MARKETING_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_OPERATIONS:
			return OPERATIONS_VARIANTS;
		default:
			return EVERYTHING_ELSE_VARIANTS;
	}
};

export const TAILORED_ONBOARDING = {
	taskList: TASK_LIST,
	getTaskVariants,
} as const;

export const TAILORED_ONBOARDING_WITH_SINGLE_CONFIG_TASK_LIST: TaskList = Object.freeze({
	root: {
		title: messages.rootTitle,
		items: [
			TAILORED_ONBOARDING_SUMMARY,
			TAILORED_ONBOARDING_CALENDAR,
			TAILORED_ONBOARDING_LIST,
			TAILORED_ONBOARDING_TIMELINE,
			TAILORED_ONBOARDING_PAGES,
			TAILORED_ONBOARDING_FORM,
			TAILORED_ONBOARDING_INVITE,
			TAILORED_ONBOARDING_BOARD,
			TAILORED_ONBOARDING_BACKLOG,
			TAILORED_ONBOARDING_CODE,
		],
	},
});

export const TAILORED_ONBOARDING_WITH_SINGLE_CONFIG = {
	taskList: TAILORED_ONBOARDING_WITH_SINGLE_CONFIG_TASK_LIST,
	taskVariants: TAILORED_ONBOARDING_SINGLE_CONFIG,
} as const;

// Tailored Onboarding M2P5
export const M2P5_TASK_LIST: TaskList = Object.freeze({
	root: {
		title: messages.rootTitle,
		items: [
			TAILORED_ONBOARDING_SUMMARY,
			TAILORED_ONBOARDING_CALENDAR,
			TAILORED_ONBOARDING_LIST,
			TAILORED_ONBOARDING_TIMELINE,
			TAILORED_ONBOARDING_PAGES,
			TAILORED_ONBOARDING_FORM,
			TAILORED_ONBOARDING_INVITE,
			TAILORED_ONBOARDING_BOARD,
		],
	},
});

// https://hello.atlassian.net/wiki/spaces/agile/pages/1093679513/Documentation+QS+Card+manager
export const M2P5_TASK_VARIANTS: Cards = {
	...PRODUCT_MANAGEMENT_VARIANTS,
	...PROJECT_MANAGEMENT_VARIANTS,
	...DESIGN_VARIANTS,
	...IT_SUPPORT_VARIANTS,
	...SOFTWARE_VARIANTS,
	...OTHER_PERSONAL_VARIANTS,
	...EVERYTHING_ELSE_VARIANTS,
};

export const getM2P5TaskVariants = (teamType: string): Cards => {
	switch (teamType) {
		case USER_SEGMENTATION_TEAM_TYPE_PRODUCT_MANAGEMENT:
			return PRODUCT_MANAGEMENT_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_PROJECT_MANAGEMENT:
			return PROJECT_MANAGEMENT_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_DESIGN:
			return DESIGN_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_IT_SUPPORT:
			return IT_SUPPORT_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_OTHER_PERSONAL:
			return OTHER_PERSONAL_VARIANTS;
		case USER_SEGMENTATION_TEAM_TYPE_SOFTWARE:
			return SOFTWARE_VARIANTS;
		default:
			return EVERYTHING_ELSE_VARIANTS;
	}
};

export const TAILORED_ONBOARDING_M2P5 = {
	taskList: M2P5_TASK_LIST,
	getM2P5TaskVariants,
} as const;
