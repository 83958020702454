export enum Products {
	// ATLAS_* - Need to be standalone apps to sit in this space
	ATLAS_HOME = 'ATLAS_HOME',
	ATLAS_GOALS = 'ATLAS_GOALS',
	ATLAS_TEAMS = 'ATLAS_TEAMS',
	ATLAS_HUB = 'ATLAS_HUB',
	JIRA_SOFTWARE = 'JIRA_SOFTWARE',
	CONFLUENCE = 'CONFLUENCE',
	LOOM = 'LOOM',
}
