import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	AIContextServicePanelApiResponse,
	AIContextServicePanelResourceResponse,
} from '../types.tsx';
import { transformData } from '../utils.tsx';
import { getContextPanelSuggestions } from './graphql/index.tsx';

export const getServiceSuggestionUrl = (issueId: string): string =>
	`/gateway/api/firebolt/1/context-panel/${issueId}`;

export const getServiceSuggestionData = (
	issueId: string,
	cloudId: string,
): Promise<AIContextServicePanelResourceResponse> => {
	if (fg('context-panel-graphql-migration')) {
		return getContextPanelSuggestions(cloudId, issueId);
	}
	const url = getServiceSuggestionUrl(issueId);
	return performGetRequest<AIContextServicePanelApiResponse>(url).then(transformData);
};
