/**
 * @generated SignedSource<<7e8254497be74fe1420b745f075025fe>>
 * @relayHash 9a9861d3247c936a35bd5e9ede43dec9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fc9ce67d4f42bf19709e27394ddcec5fd75853763835e0101d01770eaf3168dc

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneysPageQuery } from './JourneysPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneysPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "fc9ce67d4f42bf19709e27394ddcec5fd75853763835e0101d01770eaf3168dc",
    "metadata": {},
    "name": "JourneysPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
