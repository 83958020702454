import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { servicedeskCmdbInsightObjectSchemaRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightObjectSchemaRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import { LazyServicedeskCmdbObjectSchemaV2, ObjectSchemaV2 } from './ui/object-schema/index.tsx';
import { AssetsAppRedirect } from './ui/redirect/assets-app-redirect/index.tsx';

export const servicedeskCmdbInsightObjectSchemaRouteEntry = createEntry(
	servicedeskCmdbInsightObjectSchemaRoute,
	() => ({
		group: 'servicedesk',
		layout: globalLayout,
		navigation: { menuId: MENU_ID.INSIGHT },
		component: fg('assets_as_an_app_v2') ? AssetsAppRedirect : ObjectSchemaV2,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaV2],
		perfMetricKey: 'service-management.insight-cmdb.load-schema-page',
	}),
);
