import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getQueuesResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskQueuesAddonRoute } from '@atlassian/jira-router-routes-servicedesk-queues-routes/src/servicedeskQueuesAddonRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import ServiceDeskQueuesAddonView, {
	LazyServicedeskQueuesAddonView,
} from './ui/addon-view/index.tsx';

export const servicedeskQueuesAddonRouteEntry = createEntry(servicedeskQueuesAddonRoute, () => ({
	group: 'servicedesk',
	component: ServiceDeskQueuesAddonView,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [...getQueuesResources(), getConsolidationStateResource()],
	forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesAddonView],
}));
