import React, { useMemo, type Ref } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { CustomisableSidebarModalEntrypoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-customization-modal/src/entrypoint.tsx';
import type { CustomizableMenuItemData } from '@atlassian/customization-modal';
import { CustomizeSidebarMenuItem } from '../customize-sidebar-menu-item/index.tsx';
import messages from '../customize-sidebar-menu-item/messages.tsx';

type JiraCustomizationModalProps = {
	productItemsData: CustomizableMenuItemData[];
	appItemsData: CustomizableMenuItemData[];
};

export function CustomizeSidebar({ productItemsData, appItemsData }: JiraCustomizationModalProps) {
	const entryPointParams = useMemo(
		() => ({
			productItemsData,
			appItemsData,
		}),
		[productItemsData, appItemsData],
	);

	const { formatMessage } = useIntl();

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={CustomisableSidebarModalEntrypoint}
			entryPointProps={entryPointParams}
			title={formatMessage(messages.customizeSidebar)}
			useInternalModal={false}
		>
			{}
			{({ ref }) => (
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				<CustomizeSidebarMenuItem ref={ref as Ref<HTMLButtonElement>} />
			)}
		</ModalEntryPointPressableTrigger>
	);
}
