import React, {
	createContext,
	useCallback,
	useContext,
	useState,
	useMemo,
	type ReactNode,
} from 'react';
import { jiraHomeRoute } from '@atlassian/jira-router-routes-jira-home-routes/src/jiraHomeRoute.tsx';
import { useRouterActions, usePathParam } from '@atlassian/react-resource-router';
import { storageProvider } from './utils.tsx';

const STORAGE_KEY = 'back-button-url';

type NavigateBackContextType = {
	saveCurrentRoute: (currentUrl: string) => void;
	goBack: () => void; // @deprecated - cleanup with https://jplat.atlassian.net/browse/BLU-6485
	previousUrl: string | null /* use this instead of goBack() */;
};

const NavigateBackContext = createContext<NavigateBackContextType | undefined>(undefined);

export const useNavigateBack = () => {
	const context = useContext(NavigateBackContext);
	if (!context) {
		throw new Error('useNavigateBack must be used within a NavigateBackProvider');
	}
	return context;
};

/**
 * Uses session storage instead of relying on browser history - to track of the last non-Settings menu page, to go "Back" to. See usages of saveCurrentRoute() - when new route is saved.
 */
export function NavigateBackProvider({ children }: { children: ReactNode }) {
	const { push } = useRouterActions();
	const [projectKey] = usePathParam('projectKey');
	const defaultInitialUrl = projectKey ? `/projects/${projectKey}` : jiraHomeRoute.path;
	const [previousUrl, setPreviousUrl] = useState<string>(
		() => storageProvider.get(STORAGE_KEY) ?? defaultInitialUrl,
	);

	const saveCurrentRoute = useCallback((currentUrl: string) => {
		setPreviousUrl(currentUrl);
		storageProvider.set(STORAGE_KEY, currentUrl);
	}, []);

	// Remove this method when cleaning up blu-5799-jira-software-board-entrypoint-route
	const goBack = useCallback(
		() => push(previousUrl ?? defaultInitialUrl),
		[defaultInitialUrl, previousUrl, push],
	);

	const value = useMemo(
		() => ({ saveCurrentRoute, goBack, previousUrl }),
		[saveCurrentRoute, goBack, previousUrl],
	);

	return <NavigateBackContext.Provider value={value}>{children}</NavigateBackContext.Provider>;
}
