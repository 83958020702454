import React, { useCallback, useState } from 'react';

import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import { Popup, PopupContent, PopupTrigger } from '@atlaskit/popup/experimental';
import { Box, xcss } from '@atlaskit/primitives';
import { MenuList } from '@atlassian/navigation-system';
import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

import { MoreMenuSection } from './more-menu-section';
import type { MoreMenuPopupProps } from './types';

const popupContentContainerStyles = xcss({
	position: 'relative',
	marginInline: 'space.negative.050',
});

const popupContentStyles = xcss({
	padding: 'space.050',
});

export function MoreMenuPopup({
	productItemsData,
	appItemsData,
	moreMenuItemComponent,
	customizeSidebarMenuItem,
	moreMenuItemLabel,
	onClickMoreMenuTrigger,
}: MoreMenuPopupProps) {
	const [isOpen, setIsOpen] = useState(false);
	const closeMenu = () => setIsOpen(false);

	const onClick = useCallback(() => {
		setIsOpen(true), onClickMoreMenuTrigger && onClickMoreMenuTrigger();
	}, [onClickMoreMenuTrigger]);

	return (
		<Popup isOpen={isOpen}>
			<PopupTrigger>
				{(triggerProps) => (
					<MenuButtonItem
						{...triggerProps}
						elemBefore={<ShowMoreHorizontalIcon label="" color="currentColor" spacing="spacious" />}
						onClick={onClick}
					>
						{moreMenuItemLabel}
					</MenuButtonItem>
				)}
			</PopupTrigger>
			{/* Adding listitem role as the parent is a UL */}
			<Box xcss={popupContentContainerStyles} role="listitem">
				<PopupContent
					shouldFitContainer
					placement="bottom-start"
					// Offset the top margin of the menu section.
					// This will keep an even spacing of menu items between those inside the dropdown and those above,
					// and keep the icons aligned
					offset={[-4, 4]}
					onClose={() => setIsOpen(false)}
					shouldRenderToParent
					strategy="absolute"
				>
					{() => (
						<Box xcss={popupContentStyles}>
							<MenuList>
								<MoreMenuSection
									itemsData={productItemsData}
									moreMenuItemComponent={moreMenuItemComponent}
									closeMenu={closeMenu}
								/>
								<MoreMenuSection
									itemsData={appItemsData}
									moreMenuItemComponent={moreMenuItemComponent}
									closeMenu={closeMenu}
								/>
								{customizeSidebarMenuItem}
							</MenuList>
						</Box>
					)}
				</PopupContent>
			</Box>
		</Popup>
	);
}
