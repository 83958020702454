import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { featuresEntryPoint } from '@atlassian/jira-portfolio-3-plan-features/src/entrypoint.tsx';

export const planSettingsFeaturesEntryPoint = createEntryPoint({
	root: JSResourceForUserVisible(
		() => import(/* webpackChunkName: "async-plan-settings-features-entrypoint" */ './index'),
	),
	getPreloadProps: (entryPointParams: EntryPointRouteParams) => {
		return {
			entryPoints: {
				preLoadFeaturesEntryPoint: {
					entryPoint: featuresEntryPoint,
					entryPointParams,
				},
			},
		};
	},
});
