import { useCallback, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	setIsGlobalScopeEnrolmentEnabledInLocalStorage,
	getIsGlobalScopeEnrolmentEnabledFromLocalStorage,
} from '@atlassian/jira-issue-navigator-global-scope-enrolment/src/services/global-scope-enrolment/local-storage/index.tsx';
import { setIsGlobalScopeEnrolmentEnabledUserPreference } from '@atlassian/jira-issue-navigator-global-scope-enrolment/src/services/global-scope-enrolment/set-global-scope-user-preference/index.tsx';
import { isNinGlobalScopeEnrolmentEnabledResource } from '@atlassian/jira-router-resources-is-nin-global-scope-enrolment-enabled/src/index.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import useFeedbackDialog from '../../common/ui/opt-out-feedback/index.tsx';
import messages from './messages.tsx';

export const useGlobalScopeOptOutFlow = (href?: string) => {
	const { showFlag } = useFlagsService();
	const isAnonymous = useIsAnonymous();

	const redirectToGin = useCallback(() => {
		if (href) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.assign(href);
		} else {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.reload();
		}
	}, [href]);

	const { renderFeedbackDialog } = useFeedbackDialog({
		onClose: redirectToGin,
		isAdminSettingsModal: false,
	});

	/**
	 * globalScopeOptOut
	 * Triggers the NIN Global Scope opt-out process, including all UI orchestration of feedback.
	 *
	 * The caller should handle "default" behaviour for when the user is already not enrolled,
	 * unless the `preventDefault` callback has been called. This allows for easy usage with links,
	 * where the onClick event's `preventDefault` can be passed.
	 *
	 * If using an event's `preventDefault`, remember to bind the event!
	 * ex: `globalScopeOptOut(event.preventDefault.bind(event));`
	 *
	 * @param {Function} preventDefault
	 */
	return useCallback(
		async (preventDefault?: () => void) => {
			// If the user has already opt-out, just allow the caller to handle the click
			if (!getIsGlobalScopeEnrolmentEnabledFromLocalStorage()) {
				return;
			}

			// Otherwise, take over the interaction
			preventDefault && preventDefault();

			try {
				// Update user preference and local storage
				!isAnonymous && (await setIsGlobalScopeEnrolmentEnabledUserPreference(false));
				setIsGlobalScopeEnrolmentEnabledInLocalStorage(false);
				renderFeedbackDialog();
				sessionStorage.setItem('showGinOptOutFlag', 'true');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				showFlag({
					type: 'error',
					title: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.errorOptOutTitleIssueTermRefresh
						: messages.errorOptOutTitle,
					description: messages.errorOptOutDesc,
					testId: 'issue-navigator-action-meatball-menu.ui.error-flag',
					messageId:
						'issue-navigator-changeboarding.controllers.global-scope-enrolment-flow.show-flag.error',
					messageType: 'transactional',
				});

				fireErrorAnalytics({
					meta: {
						id: 'optOutOfNin',
						packageName: 'jiraIssueNavigatorChangeboarding',
						teamName: 'empanada',
					},
					sendToPrivacyUnsafeSplunk: true,
					error,
				});
			}
		},
		[isAnonymous, renderFeedbackDialog, showFlag],
	);
};

export const useGlobalScopeEnrolmentEnable = () => {
	// We store localStorageData as state so that it's not recalculated during subsequent renders. We want that as a
	// guarantee, not just as a performance optimisation, so we're using useState rather than useMemo.
	//
	// The reason we want to guarantee it's only fetched during the initial render is that we want
	// isNinGlobalScopeEnrolmentEnabled to be stable if there's localStorageData. This can prevent a bug where fetch
	// true from localStorage, we render NIN, the resource returns false, we update local storage to false, and then
	// GinRedirect is rendered again by a parent component and localStorage now returns false and we redirect later
	// than we should.
	const [localStorageData] = useState(getIsGlobalScopeEnrolmentEnabledFromLocalStorage);

	const { data: resourceData } = useResource(isNinGlobalScopeEnrolmentEnabledResource);
	const [{ isInitialRender }] = useSpaStateTransition();

	// We assume NIN global scope is enabled if this is an initial render, otherwise we rely on localStorageData if it
	// exists, otherwise we use resourceData. resourceData is async, and can block for a long time, so we optimise for
	// speed rather than correctness by trusting localStorageData.
	let isNinGlobalScopeEnrolmentEnabled: boolean | null;
	if (isInitialRender) {
		isNinGlobalScopeEnrolmentEnabled = true;
	} else if (localStorageData !== null && localStorageData !== undefined) {
		isNinGlobalScopeEnrolmentEnabled = localStorageData;
	} else {
		isNinGlobalScopeEnrolmentEnabled = resourceData;
	}

	return [isNinGlobalScopeEnrolmentEnabled, resourceData];
};
