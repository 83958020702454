import React from 'react';
import Flag, { AutoDismissFlag } from '@atlaskit/flag';
import { Box, xcss } from '@atlaskit/primitives';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { boldIconMap, iconMap } from './constants.tsx';
import { messages } from './messages.tsx';
import type { CommonFlagProps } from './types.tsx';

export const commonFlagTestId = 'platform.ui.flags.common.ui.common-flag-v2';
// standard delay time in ms, added for SR users to announce the status flag
const ANNOUNCEMENT_DELAY = 2500;

/**
 * Renders a flag component with dynamic behavior, automatically choosing between auto-dismissing
 * and standard modes based on properties. It dynamically selects an appropriate icon and supports
 * internationalization and accessibility, making it versatile for various use cases.
 */
const CommonFlag = (props: Omit<CommonFlagProps, keyof MaybeChoreographedComponentProps>) => {
	const {
		flagType,
		icon,
		delayAnnouncement = ANNOUNCEMENT_DELAY,
		isAutoDismiss,
		...flagProps
	} = props;
	const { formatMessage } = useIntl();
	const StyledIcon = flagProps.appearance ? boldIconMap[flagType] : iconMap[flagType];

	return (
		<Box
			xcss={containerStyles}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...(flagProps.testId && {
				'data-test-id': flagProps.testId,
			})}
		>
			{isAutoDismiss ? (
				<AutoDismissFlag
					testId={`${commonFlagTestId}-auto-dismiss`}
					{...flagProps}
					delayAnnouncement={delayAnnouncement}
					icon={icon ?? <StyledIcon label={formatMessage(messages[flagType])} /> ?? null}
				/>
			) : (
				<Flag
					testId={commonFlagTestId}
					{...flagProps}
					delayAnnouncement={delayAnnouncement}
					icon={icon ?? <StyledIcon label={formatMessage(messages[flagType])} /> ?? null}
				/>
			)}
		</Box>
	);
};

export default CommonFlag;

const containerStyles = xcss({
	wordWrap: 'break-word',
});
