import React, { useEffect, useRef } from 'react';

import { graphql, useLazyLoadQuery } from 'react-relay';

import { HeadingItem, Section } from '@atlaskit/menu';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { standardToPremiumPubTouchpointQuery } from '@atlassian/jira-relay/src/__generated__/standardToPremiumPubTouchpointQuery.graphql.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { EditionAwarenessStandardPubTouchpoint } from '@atlassian/edition-awareness-standard-pub-touchpoint';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIsEditionAwarenessPillDismissed } from '../../services/is-ea-pill-dismissed/index.tsx';
import { StandardToPremiumModalEntrypointTrigger } from '../modal/trigger.tsx';
import { getHamsKey } from '../../utils/index.tsx';
import { useProductAndEdition } from '../../services/use-product-and-edition/index.tsx';
import { buildLearnMoreLink } from '../../utils/buildLearnMoreLink.tsx';
import messages from './messages.tsx';

function useFireExposureEvent(isEligibleAudience: boolean, cohort: string) {
	const hasSent = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (!isEligibleAudience || hasSent.current) {
			return;
		}
		createAnalyticsEvent({
			eventType: 'track',
			action: 'exposed',
			actionSubject: 'feature',
			attributes: {
				flagKey: 'edition_awareness_upsell_jira_ab',
				cohort,
			},
		}).fire();

		hasSent.current = true;
	}, [createAnalyticsEvent, isEligibleAudience, cohort]);
}

function StandardToPremiumPubTouchpointImpl() {
	const [, { off: closeMenu }] = useTopMenus(MENU_ID.PROFILE);

	const { formatMessage } = useIntl();
	const isSiteAdmin = useIsSiteAdmin();
	const cloudId = useCloudId();
	const { product = '', edition } = useProductAndEdition();

	const {
		hasFetched: hasFetchedDismissedEditionAwarenessPillState,
		isDismissed: hasDismissedEditionAwarenessPill,
	} = useIsEditionAwarenessPillDismissed();

	/**
	 * We won't know for certain if the user is currently on a paid standard plan until we query for the entitlement
	 * However, we can know for certain if they are not on any type of standard plan
	 * If they are not on a standard plan, we can skip the query
	 */
	let isPossiblyPaidStandard = false;
	if (edition === 'standard') {
		if (product === 'jira') {
			isPossiblyPaidStandard = fg('edition_awareness_upsell_jira_fg');
		}
		if (product === 'jira-service-management') {
			isPossiblyPaidStandard = fg('edition_awareness_upsell_jsm_fg');
		}
	}

	// basic eligibility conditions
	const isEligibleForExperiment =
		isPossiblyPaidStandard &&
		isSiteAdmin &&
		hasFetchedDismissedEditionAwarenessPillState &&
		hasDismissedEditionAwarenessPill;

	let cohort = 'not-enrolled';
	if (isEligibleForExperiment) {
		cohort = expVal('edition_awareness_upsell_jira_ab', 'cohort', 'not-enrolled');
	}

	// Fire the gasv3 exposure event if eligible
	useFireExposureEvent(isEligibleForExperiment, cohort);

	// Only call api if the user is eligible and enrolled in the experiment
	const shouldSkip = cohort !== 'treatment';

	const data = useLazyLoadQuery<standardToPremiumPubTouchpointQuery>(
		graphql`
			query standardToPremiumPubTouchpointQuery(
				$cloudId: ID!
				$productKey: String!
				$shouldSkip: Boolean!
			) {
				tenantContexts(cloudIds: [$cloudId])
					@skip(if: $shouldSkip)
					@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
					entitlementInfo(hamsProductKey: $productKey) {
						paidEntitlement: entitlement(where: { inTrialOrPreDunning: false }) {
							experienceCapabilities {
								changeOfferingV2(offeringName: "premium") {
									isAvailableToUser
									experienceUrl
								}
							}
							subscription {
								accountDetails {
									invoiceGroup {
										invoiceable
									}
								}
							}
						}
					}
				}
			}
		`,
		{
			cloudId,
			shouldSkip,
			productKey: product ? getHamsKey(product) : '', // will be skipped regardless if product is undefined
		},
	);

	const paidEntitlement = data?.tenantContexts?.[0]?.entitlementInfo?.paidEntitlement;
	const isInvoiceable = paidEntitlement?.subscription?.accountDetails?.invoiceGroup?.invoiceable;
	const canUpgrade = Boolean(
		paidEntitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser &&
			paidEntitlement?.experienceCapabilities?.changeOfferingV2?.experienceUrl,
	);

	if (!paidEntitlement || !isInvoiceable || !canUpgrade) {
		return null;
	}

	const experienceUrl =
		paidEntitlement.experienceCapabilities?.changeOfferingV2?.experienceUrl ?? undefined;

	const learnMoreUrl = buildLearnMoreLink(experienceUrl);

	return (
		<Section hasSeparator>
			<HeadingItem>{formatMessage(messages.upgrade)}</HeadingItem>
			<StandardToPremiumModalEntrypointTrigger
				product={product}
				experienceUrl={experienceUrl}
				learnMoreUrl={learnMoreUrl}
				onClick={closeMenu}
			>
				<EditionAwarenessStandardPubTouchpoint product={product} />
			</StandardToPremiumModalEntrypointTrigger>
		</Section>
	);
}

function StandardToPremiumPubTouchpointWithErrorBoundary() {
	return (
		<ErrorBoundary
			id="editionAwarenessStandardToPremiumPubTouchpoint"
			packageName="jiraEditionAwarenessForStandardToPremium"
		>
			<StandardToPremiumPubTouchpointImpl />
		</ErrorBoundary>
	);
}

export const StandardToPremiumPubTouchpoint = componentWithFG(
	'edition_awareness_upsell_jira_ab_fg',
	StandardToPremiumPubTouchpointWithErrorBoundary,
	() => <></>,
);
