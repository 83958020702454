import React from 'react';
import { FlagsBoundary } from '@atlassian/jira-flag-boundary/src/FlagsBoundary.tsx';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { type AsyncFlagProps, CommonFlag } from '../index.tsx';

const AkFlag = ({
	packageName = 'flags',
	...props
}: AsyncFlagProps & MaybeChoreographedComponentProps) => (
	<FlagsBoundary packageName={packageName}>
		<CommonFlag flagType="info" {...props} />
	</FlagsBoundary>
);

export default AkFlag;
