// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createActionsHook,
	type Action,
	createStateHook,
} from '@atlassian/react-sweet-state';

type EventCallback = ({ onComplete }: { onComplete: () => void }) => void;

export type State = {
	/**
	 * Stores a list of callback functions that should run when a delete event is published
	 * e.g. a delete event can be published when a user deletes a filter from filters directory
	 */
	callbacks: EventCallback[];
};

export type Actions = {
	/**
	 * Other components can subscribe to delete event changes, and pass a callback function so that the callback function is run when a delete event is published
	 * e.g. the navigation sidebar can subscribe for delete event changes to update its filters data
	 */
	subscribe: (callback: EventCallback) => Action<State>;
	/**
	 * Other components can unsubscribe to delete event changes
	 * e.g. when the navigation sidebar unmounts, it no longer needs to subscribe for delete event changes
	 */
	unsubscribe: (callback: EventCallback) => Action<State>;
	/**
	 * Runs all callback functions that are subscribed when a delete event occurs
	 */
	publish: () => Action<State>;
};

const initialState: State = {
	callbacks: [],
};

export const actions: Actions = {
	subscribe:
		(callback) =>
		({ getState, setState }) => {
			if (!getState().callbacks.includes(callback)) {
				setState({ callbacks: [...getState().callbacks, callback] });
			}
		},
	unsubscribe:
		(callback) =>
		({ getState, setState }) => {
			if (getState().callbacks.includes(callback)) {
				setState({
					callbacks: getState().callbacks.filter((item) => item !== callback),
				});
			}
		},
	publish:
		() =>
		({ getState }) => {
			getState().callbacks.forEach((callback) => callback({ onComplete: () => undefined }));
		},
};

const Store = createStore<State, Actions>({ initialState, actions });

// for testing
export const useCallbacksState = createStateHook(Store);

export const useDeleteChangeActions = createActionsHook(Store);
