import get from 'lodash/get';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
// eslint-disable-next-line jira/import-whitelist
import { prefetchApiReadyMark } from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import { createResourceError } from '@atlassian/jira-router-resources-utils/src/utils/create-resource-error/index.tsx';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';

import { checkAuthentication } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import type { RouterDataContext, ResourceStoreContext } from '@atlassian/react-resource-router';
import type { FeaturesRestData, FeaturesData, FeaturesRestItem } from '../../types.tsx';

export const transformRestData = (data: FeaturesRestData): FeaturesData =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data.reduce<Record<string, any>>(
		(acc: FeaturesData, { feature, status, editable }: FeaturesRestItem) =>
			Object.assign(acc, {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[feature as string]: { status, editable },
			}),
		{},
	);

const getFeatures = async (baseUrl: string, projectKey: string): Promise<FeaturesData> => {
	try {
		const data: FeaturesRestData = await fetchJson(
			`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/features`,
		);

		setMark(prefetchApiReadyMark);
		return transformRestData(data);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		logger.safeErrorWithoutCustomerData('jiraServicedeskPracticeFeaturesResource', error);

		fireErrorAnalytics({
			meta: {
				id: 'jiraServicedeskPracticeFeaturesResource',
				packageName: 'jiraSpa',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		throw error;
	}
};

export const featuresResource = (
	{ match, query }: RouterDataContext,
	{ tenantContext }: ResourceStoreContext,
) => {
	const { params } = match;
	if (!checkAuthentication(tenantContext)) {
		throw createResourceError({
			component: 'spa.view.routes.servicedesk.features.getData',
			message: 'Current user is not authenticated',
			name: 'AuthenticationError',
			skipSentry: true,
		});
	}

	// projectKey from url path or issueKey
	const projectKey =
		getProjectKeyId(match, query).projectKey || get(params, 'issueKey', '')?.split('-')[0];

	if (projectKey == null) {
		return null;
	}

	return getFeatures(tenantContext.baseUrl, projectKey);
};

export default featuresResource;
