/**
 * @generated SignedSource<<db313e78bd4577e1f34af3f48f590ec2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsSectionsWithDisplaySettings$data = {
  readonly settings_navigationCustomisation: {
    readonly properties: {
      readonly nodes: ReadonlyArray<{
        readonly key: string | null | undefined;
        readonly value: string | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Nav4ProjectsSectionsWithDisplaySettings";
};
export type Nav4ProjectsSectionsWithDisplaySettings$key = {
  readonly " $data"?: Nav4ProjectsSectionsWithDisplaySettings$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsSectionsWithDisplaySettings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityAri"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "ownerAri"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4ProjectsSectionsWithDisplaySettings",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "entityAri",
          "variableName": "entityAri"
        },
        {
          "kind": "Variable",
          "name": "ownerAri",
          "variableName": "ownerAri"
        }
      ],
      "concreteType": "SettingsNavigationCustomisation",
      "kind": "LinkedField",
      "name": "settings_navigationCustomisation",
      "plural": false,
      "selections": [
        {
          "concreteType": "SettingsDisplayPropertyConnection",
          "kind": "LinkedField",
          "name": "properties",
          "plural": false,
          "selections": [
            {
              "concreteType": "SettingsDisplayProperty",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "key"
                },
                {
                  "kind": "ScalarField",
                  "name": "value"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "e6e0f204dff7282a7c0aeb808efb30c6";

export default node;
