import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmChangeManagementSettingsGitLabGatingResource } from '@atlassian/jira-router-resources-jsm-change-management-settings/src/controllers/index.tsx';
import { jsmRepackagingBannerResource } from '@atlassian/jira-router-resources-jsm-repackaging-banner/src/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { serviceDeskContextResource } from '@atlassian/jira-router-resources-service-desk-context/src/services/index.tsx';
import { featuresResource } from '@atlassian/jira-router-resources-service-project-features/src/services/index.tsx';
import { projectSettingsServicedeskChangeManagementRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskChangeManagementRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { changeManagementSettingsProjectConfigurationResource } from '@atlassian/jira-servicedesk-change-management-settings/src/services/change-management-configuration/get-project-configuration/resources/index.tsx';
import ServiceDeskChangeManagementSettings, {
	LazyProjectSettingsServiceDeskChangeManagement,
} from './ui/service-desk-change-management/index.tsx';

export const projectSettingsServicedeskChangeManagementRouteEntry = createEntry(
	projectSettingsServicedeskChangeManagementRoute,
	() => ({
		group: 'project-settings-servicedesk',
		ufoName: 'service-management.change-management-settings',
		component: ServiceDeskChangeManagementSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			serviceDeskContextResource,
			changeManagementSettingsProjectConfigurationResource,
			jsmChangeManagementSettingsGitLabGatingResource,
			featuresResource,
			getConsolidationStateResource(),
			jsmRepackagingBannerResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskChangeManagement],
	}),
);
