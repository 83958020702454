import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const dashboardWallboardEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "dashboard-wallboard-entrypoint" */ './src/index.tsx'),
	),
	getPreloadProps: () => {
		return {};
	},
});
