import { UNSAFE_noExposureExp, expValEquals } from '@atlassian/jira-feature-experiments';
import { isFedRamp } from '@atlassian/atl-context';
import { fg } from '@atlassian/jira-feature-gating';
import { BOARD_CREATE_COLUMN_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import {
	FREE_EDITION,
	STANDARD_EDITION,
	PREMIUM_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	isTailoredOnboardingTailoredTeamType,
	isTailoredOnboardingM2P5TailoredTeamType,
	isTailoredOnboardingWithScrumAndSoftwareKanbanTeamType,
} from '@atlassian/jira-tailored-onboarding/src/utils.tsx';
import type {
	TailoredOnboardingM2P5Variants,
	TailoredOnboardingTeamType,
} from '@atlassian/jira-tailored-onboarding/src/types.tsx';
import {
	REVERSE_TRIAL_ROOT_TASK,
	ROOT_TASK,
	BACKLOG_CREATE_SPRINT_NUDGE_ID,
} from '../../common/constants.tsx';
import type { Cards, TaskListItem, TaskList } from '../../common/types.tsx';
import { isRoadmapRoute } from '../../common/utils/roadmap-route.tsx';
import { USER_ROLE } from '../model/constants.tsx';
import type { ProjectState } from '../model/types.tsx';
import { DEVOPS } from './config/devops/index.tsx';
import { JSM } from './config/jsm/index.tsx';
import { JSW } from './config/jsw/index.tsx';
import { JWM } from './config/jwm/index.tsx';
import { getTasks } from './manager/index.tsx';
import type { GetTasksProps } from './types.tsx';
import { isJwmProject, isJsmProject } from './utils.tsx';
import {
	TAILORED_ONBOARDING,
	TAILORED_ONBOARDING_M2P5,
	TAILORED_ONBOARDING_WITH_SINGLE_CONFIG,
} from './config/tailored-onboarding/index.tsx';
import { Experiment } from './config/jsw/constants.tsx';
import { type TeamTypeWithPrefix, Feature } from './config/tailored-onboarding/constants.tsx';

const getTaskListItem = ({
	variants,
	taskVariants,
	taskList,
	taskGroupParent,
}: {
	variants: string[];
	taskVariants: Cards;
	taskList: TaskList;
	taskGroupParent: string;
}): TaskListItem => {
	const [expConfig] = UNSAFE_noExposureExp('jira_rt_quickstart_m1');
	if (variants.includes(JSW.experiment.jswReverseTrial) && !expConfig.get('isEnabled', false)) {
		const { title, items } =
			taskList[taskGroupParent === ROOT_TASK ? REVERSE_TRIAL_ROOT_TASK : taskGroupParent] ??
			taskList[REVERSE_TRIAL_ROOT_TASK];
		return {
			title,
			items: getTasks(variants, taskVariants, items),
		};
	}
	const { title, items } = taskList[taskGroupParent] ?? taskList[ROOT_TASK];

	return {
		title,
		items: getTasks(variants, taskVariants, items),
	};
};

const createJswTasks = (
	projectState: ProjectState | null,
	taskGroupParent: string,
	routeName: string,
	shouldShowScrumOnboardingExperience: boolean,
	mountedNudges: string[],
	canCreateProject: boolean,
	isEligibleToUpdateSitename: boolean,
	isCustomerTemplateProject: boolean,
	isCrossProjectBoardToPlanUpsell: boolean,
	isWizardSimplifyQS: boolean,
	canEditPlans: boolean,
	premiumTrialOnSignup: boolean | undefined,
): TaskListItem => {
	const variants: Set<string> = new Set();
	const isTmpScrumWithSprintPermission = mountedNudges.includes(BACKLOG_CREATE_SPRINT_NUDGE_ID);

	const isEligibleForKanbanTour = mountedNudges.includes(BOARD_CREATE_COLUMN_NUDGE_ID);

	if (isFedRamp()) {
		variants.add(JSW.experience.fedRamp);
	}

	if (premiumTrialOnSignup && fg('jsw_reverse_trials_feature_gate')) {
		variants.add(JSW.experiment.jswReverseTrial);
		expValEquals('jira_rt_quickstart_m1', 'isEnabled', true) &&
			variants.add(JSW.experiment.rtQuickstartM1);
	}

	if (isCustomerTemplateProject) {
		variants.add(JSW.projectProperties.isCreatedFromCustomerTemplate);
	}

	if (shouldShowScrumOnboardingExperience && isTmpScrumWithSprintPermission) {
		variants.add(JSW.experiment.scrumOnboarding);
	}

	if (isCrossProjectBoardToPlanUpsell) {
		variants.add(JSW.trait.crossProjectBoardToPlanUpsell);
	}

	if (canEditPlans) {
		variants.add(JSW.permissions.hasPlanEditPermissions);
	}

	if (isEligibleForKanbanTour || routeName !== 'software-boards') {
		variants.add(JSW.experience.kanbanOnboarding);
	}

	if (isWizardSimplifyQS) {
		variants.add(JSW.experiment.wizard);
	}

	if (canCreateProject === false) {
		variants.add(JSW.permissions.notCanCreateProject);
	}

	if (projectState?.isNextGen) {
		variants.add(JSW.projectStyle.teamManaged);
	} else {
		variants.add(JSW.projectStyle.companyManaged);
	}

	if (projectState?.isProjectAdmin) {
		variants.add(JSW.userRole.projectAdmin);
	}

	if (projectState?.userRole === USER_ROLE.JIRA_ADMIN) {
		variants.add(JSW.userRole.jiraAdmin);
		variants.add(JSW.userRole.admin);
	}

	if (projectState?.userRole === USER_ROLE.SITE_ADMIN) {
		variants.add(JSW.userRole.siteAdmin);
		variants.add(JSW.userRole.admin);
	}

	if (projectState?.edition === FREE_EDITION) {
		variants.add(JSW.edition.free);
	} else if (projectState?.edition === STANDARD_EDITION) {
		variants.add(JSW.edition.standard);
	} else if (projectState?.edition === PREMIUM_EDITION) {
		variants.add(JSW.edition.premium);
	}

	if (isRoadmapRoute(routeName)) {
		variants.add(JSW.route.softwareRoadmap);
	} else if (routeName === 'software-backlog') {
		variants.add(JSW.route.softwareBacklog);
	} else if (routeName === 'software-boards') {
		variants.add(JSW.route.softwareBoard);
	} else if (routeName === 'software-list' || routeName === 'software-list-classic') {
		variants.add(JSW.route.softwareList);
	} else if (routeName === 'project-settings-software-access') {
		variants.add(JSW.route.projectSettingsSoftwareAccess);
	} else if (routeName === 'issue') {
		variants.add(JSW.route.issue);
	} else if (
		(routeName === 'software-calendar' || routeName === 'software-calendar-classic') &&
		fg('software_calendar_quickstart_onboarding')
	) {
		variants.add(JSW.route.softwareCalendar);
	}

	if (isEligibleToUpdateSitename) {
		variants.add(JSW.experiment.customizeSiteName);
	}

	return getTaskListItem({
		variants: Array.from(variants.values()),
		taskVariants: JSW.taskVariants,
		taskList: JSW.taskList,
		taskGroupParent,
	});
};

const createJwmTasks = ({
	projectState,
	taskGroupParent,
	taskList = JWM.taskList,
	teamType,
	isEligibleForTailoredOnboardingM2P5,
}: {
	projectState: ProjectState | null;
	taskGroupParent: string;
	taskList?: TaskList;
	teamType?: string;
	isEligibleForTailoredOnboardingM2P5?: boolean;
}): TaskListItem => {
	const variants: string[] = [];

	variants.push(JWM.experiments.projectTheming);

	if (isEligibleForTailoredOnboardingM2P5) {
		return createTailoredOnboardingTasksM2P5({
			taskGroupParent,
			teamType,
		});
	}

	if (projectState?.isProjectAdmin) {
		variants.push(JWM.userRole.projectAdmin);
	}

	if (projectState?.userRole === USER_ROLE.JIRA_ADMIN) {
		variants.push(JWM.userRole.jiraAdmin);
	}

	if (projectState?.userRole === USER_ROLE.SITE_ADMIN) {
		variants.push(JWM.userRole.siteAdmin);
	}

	if (projectState?.isSampleProject) {
		variants.push(JWM.experiments.isSampleProject);
	} else {
		variants.push(JWM.experiments.isNotSampleProject);
	}

	if (projectState?.isTrelloCrossflow) {
		variants.push(JWM.experiments.trelloCrossflow);
	}

	return getTaskListItem({
		variants,
		taskVariants: JWM.taskVariants,
		taskList,
		taskGroupParent,
	});
};

const createJsmTasks = (
	projectState: ProjectState | null,
	taskGroupParent: string,
): TaskListItem => {
	const variants: string[] = [];

	variants.push(JSM.experiments.projectTheming);

	if (projectState?.isProjectAdmin) {
		variants.push(JSM.userRole.projectAdmin);
	}

	if (projectState?.userRole === USER_ROLE.JIRA_ADMIN) {
		variants.push(JSM.userRole.jiraAdmin);
	}

	if (projectState?.userRole === USER_ROLE.SITE_ADMIN) {
		variants.push(JSM.userRole.siteAdmin);
	}

	return getTaskListItem({
		variants,
		taskVariants: JSM.taskVariants,
		taskList: JSM.taskList,
		taskGroupParent,
	});
};

const createDevopsTasks = (taskGroupParent: string): TaskListItem => {
	const variants: string[] = [];

	return getTaskListItem({
		variants,
		taskVariants: DEVOPS.taskVariants,
		taskList: DEVOPS.taskList,
		taskGroupParent,
	});
};

type TailoredOnboardingVariants =
	| (typeof Experiment)[keyof typeof Experiment]
	| (typeof TeamTypeWithPrefix)[keyof typeof TeamTypeWithPrefix]
	| (typeof Feature)[keyof typeof Feature];

export const createTailoredOnboardingTasks = (
	taskGroupParent: string,
	teamType?: string,
	features?: { hasBacklog: boolean; hasSprint: boolean },
	isEligibleForTailoredOnboardingM3?: boolean,
	isEligibleForTailoredOnboardingScrumAndSoftwareKanban?: boolean,
): TaskListItem => {
	if (fg('jira_tailored_onboarding_single_config')) {
		const variants: Array<TailoredOnboardingVariants> = [];

		if (features?.hasBacklog) {
			variants.push(Feature.hasBacklog);
		}

		if (features?.hasSprint) {
			variants.push(Feature.hasSprint);
		}

		if (isEligibleForTailoredOnboardingM3) {
			variants.push(Experiment.tailoredOnboardingM3Eligible);
		}

		if (isEligibleForTailoredOnboardingScrumAndSoftwareKanban) {
			variants.push(Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible);
		}

		if (teamType && isTailoredOnboardingWithScrumAndSoftwareKanbanTeamType(teamType)) {
			variants.push(`teamType.${teamType}`);
		} else {
			variants.push('teamType.everythingElse');
		}

		return getTaskListItem({
			variants,
			taskVariants: TAILORED_ONBOARDING_WITH_SINGLE_CONFIG.taskVariants,
			taskList: TAILORED_ONBOARDING_WITH_SINGLE_CONFIG.taskList,
			taskGroupParent,
		});
	}

	// Following the same pattern as other configs and using an array here
	// but right now only one value(team type) will ever be in this array
	const variants: TailoredOnboardingTeamType[] = [];

	if (teamType && isTailoredOnboardingTailoredTeamType(teamType)) {
		variants.push(teamType);
	} else {
		variants.push('everythingElse');
	}

	return getTaskListItem({
		variants,
		taskVariants: TAILORED_ONBOARDING.getTaskVariants(teamType ?? 'everythingElse'),
		taskList: TAILORED_ONBOARDING.taskList,
		taskGroupParent,
	});
};

const createTailoredOnboardingTasksM2P5 = ({
	taskGroupParent,
	teamType,
}: {
	taskGroupParent: string;
	teamType: string | undefined;
}): TaskListItem => {
	const variants: TailoredOnboardingM2P5Variants[] = [];

	if (teamType && isTailoredOnboardingM2P5TailoredTeamType(teamType)) {
		variants.push(teamType);
	} else {
		variants.push('everythingElse');
	}

	return getTaskListItem({
		variants,
		taskVariants: TAILORED_ONBOARDING_M2P5.getM2P5TaskVariants(teamType ?? 'everythingElse'),
		taskList: TAILORED_ONBOARDING_M2P5.taskList,
		taskGroupParent,
	});
};

export const getAllTasks = ({
	projectState,
	isDevOpsProject,
	taskGroupParent,
	routeName,
	shouldShowScrumOnboardingExperience,
	mountedNudges,
	canCreateProject,
	isEligibleToUpdateSitename,
	isCustomerTemplateProject,
	isCrossProjectBoardToPlanUpsell,
	isWizardSimplifyQS = false,
	canEditPlans,
	premiumTrialOnSignup,
	taskList,
	teamType,
	isEligibleForTailoredOnboardingM2P5,
	isEligibleForTailoredOnboardingM3,
	isEligibleForTailoredOnboardingScrumAndSoftwareKanban,
	features,
}: GetTasksProps): TaskListItem => {
	if (isJwmProject(projectState)) {
		return createJwmTasks({
			projectState,
			taskGroupParent,
			taskList,
			teamType,
			isEligibleForTailoredOnboardingM2P5,
		});
	}
	if (isJsmProject(projectState)) {
		return createJsmTasks(projectState, taskGroupParent);
	}
	if (isDevOpsProject) {
		return createDevopsTasks(taskGroupParent);
	}
	// We cannot do these in the config itself as we need to fallback to createJswTasks for other users
	// And unlike previous QS experiments, we want to change the entire QS for these users so a new config makes sense over variants to the existing JSW one
	if (isEligibleForTailoredOnboardingM3 || isEligibleForTailoredOnboardingScrumAndSoftwareKanban) {
		return createTailoredOnboardingTasks(
			taskGroupParent,
			teamType,
			features,
			isEligibleForTailoredOnboardingM3,
			isEligibleForTailoredOnboardingScrumAndSoftwareKanban,
		);
	}
	return createJswTasks(
		projectState,
		taskGroupParent,
		routeName,
		shouldShowScrumOnboardingExperience,
		mountedNudges,
		canCreateProject,
		isEligibleToUpdateSitename,
		isCustomerTemplateProject,
		isCrossProjectBoardToPlanUpsell,
		isWizardSimplifyQS,
		canEditPlans,
		premiumTrialOnSignup,
	);
};
