import { resources as roleBasedNotificationsResources } from '@atlassian/eoc-role-based-notifications/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { roleBasedNotificationsRoute } from '@atlassian/jira-router-routes-eoc-role-based-notifications-routes/src/roleBasedNotificationsRoute.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazySidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-operations/src/ui/index.tsx';
import {
	LazyRoleBasedNotifications,
	RoleBasedNotificationsComponent,
} from './ui/RoleBasedNotificationsComponent.tsx';

const getNavigationSpecifications = () => {
	return fg('operations_nav4_remaining_items')
		? {
				sidebarId: SIDEBAR_ID.OPERATIONS_GENERAL_CONFIGURATION,
			}
		: {
				sidebar: AsyncSidebarGlobalSettingsProducts,
				horizontal: AsyncHorizontalOperationsConfigurationNav,
				onlyShowHorizontalOnNav4: true,
			};
};

export const roleBasedNotificationsRouteEntry = createEntry(roleBasedNotificationsRoute, () => ({
	group: 'eoc',
	component: RoleBasedNotificationsComponent,
	layout: opsSettingsLayout,
	resources: [
		...getNavigationResources(),
		roleBasedNotificationsResources[0],
		getConsolidationStateResource(),
		navigationSidebarGlobalResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		...getNavigationSpecifications(),
	},
	forPaint: fg('operations_nav4_remaining_items')
		? [LazySidebarContent, LazyAtlassianNavigation, LazyRoleBasedNotifications]
		: [
				LazyHorizontalOperationsConfigurationNav,
				LazyAtlassianNavigation,
				LazyRoleBasedNotifications,
			],
}));
