/**
 * @generated SignedSource<<ab5a4efb2e4c302f6507731481aad167>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4NotificationsData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Notifications">;
  readonly " $fragmentType": "Nav4NotificationsData";
};
export type Nav4NotificationsData$key = {
  readonly " $data"?: Nav4NotificationsData$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4NotificationsData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4NotificationsData",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4Notifications"
    }
  ],
  "type": "Query"
};

(node as any).hash = "359a3dc4bc79536755e5a1c2e1775462";

export default node;
