import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessTimelineEmbedRoute } from '@atlassian/jira-router-routes-business-timeline-routes/src/jiraBusinessTimelineEmbedRoute.tsx';
import { timelineEmbedEntrypoint } from '@atlassian/jira-spa-apps-business-timeline-embed/entrypoint.tsx';
import { timelineEntrypoint } from '@atlassian/jira-spa-apps-harmonised-business-timeline/entrypoint.tsx';

const timelineEmbedPageEntrypoint = createChromelessEntryPoint({
	main: timelineEntrypoint,
});

const legacyimelineEmbedPageEntrypoint = createChromelessEntryPoint({
	main: timelineEmbedEntrypoint,
});

export const jiraBusinessTimelineEmbedRouteEntry = createEntry(
	jiraBusinessTimelineEmbedRoute,
	() => ({
		group: 'classic-business',
		ufoName: 'jwm.timeline-embed-view',
		layout: chromelessLayout,
		skeleton: ThemedLazySkeleton,

		resources: getProjectViewResources(),

		entryPoint: () =>
			/**
			 * IMPORTANT - we can't use `isJiraTimelineHarmonisationM1Enabled`
			 * here, as static route generation needs us to use `expValEquals`
			 * and `fg` directly. Any changes to this logic must be duplicated
			 * in `@atlassian/jira-business-timeline-rollout`
			 */
			// eslint-disable-next-line jira/ff/no-preconditioning
			expValEquals('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', true) &&
			fg('refactor_nin_to_jira_view_schema') &&
			expValEquals('jira_timeline_harmonisation_m1', 'isEnabled', true)
				? timelineEmbedPageEntrypoint
				: legacyimelineEmbedPageEntrypoint,
	}),
);
