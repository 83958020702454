import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { notificationLogsRoute } from '@atlassian/jira-router-routes-servicedesk-notification-logs-routes/src/notificationLogsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { NotificationLogs, LazyNotificationLogs } from './ui/index.tsx';

export const notificationLogsRouteEntry = createEntry(notificationLogsRoute, () => ({
	group: 'servicedesk',
	layout: serviceProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},

	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
	],

	component: NotificationLogs,
	forPaint: [LazyAtlassianNavigation, LazyNotificationLogs],
	perfMetricKey: 'jsm-notification-logs-component-load',
}));
