import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { LoadingButton } from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import EditorPanelIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	INLINE_DIALOG,
	FireScreenAnalytics,
	ContextualAnalyticsData,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

interface PromotionalCardProps {
	imageUrl?: string;
	imageAltText?: string;
	header: string;
	description: string;
	primaryAction: ReactNode;
	reason: string;
	analyticsSource: string;
	isDismissLoading?: boolean;
	onDismiss: () => void;
}

export const PromotionalCard = ({
	imageUrl,
	imageAltText,
	header,
	description,
	primaryAction,
	reason,
	analyticsSource,
	isDismissLoading,
	onDismiss,
}: PromotionalCardProps) => {
	const { formatMessage } = useIntl();
	return (
		<ContextualAnalyticsData sourceType={INLINE_DIALOG} sourceName={analyticsSource}>
			<Wrapper>
				<ButtonWrapper>
					<LoadingButton
						appearance="subtle"
						spacing="none"
						isLoading={isDismissLoading}
						aria-label={formatMessage(messages.dismiss)}
						iconBefore={
							<CrossIcon
								LEGACY_size="medium"
								spacing="spacious"
								label=""
								color={token('color.icon.warning.inverse')}
							/>
						}
						onClick={(e, analyticsEvent) => {
							fireUIAnalytics(analyticsEvent, `${analyticsSource}Dismiss`);
							onDismiss();
						}}
					/>
				</ButtonWrapper>

				{imageUrl ? (
					<ImageContainer imageUrl={imageUrl}>
						{imageAltText ? <span role="img" aria-label={imageAltText} /> : null}
					</ImageContainer>
				) : null}

				<Content>
					<Header>
						<Heading size="medium" as="h2">
							{header}
						</Heading>
					</Header>
					<Description>{description}</Description>
					{primaryAction}
					<FireScreenAnalytics />
				</Content>

				<Footer>
					<Tooltip
						content={reason}
						onShow={(analyticsEvent) => fireUIAnalytics(analyticsEvent, 'promotionalCardInfo')}
					>
						<FooterContent tabIndex={0}>
							<EditorPanelIcon LEGACY_size="small" label="" />
							{formatMessage(messages.footerContent)}
						</FooterContent>
					</Tooltip>
				</Footer>
			</Wrapper>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	backgroundColor: token('elevation.surface'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: token('elevation.shadow.overflow'),
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 45}px`,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 6}px`,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageContainer = styled.div<{ imageUrl: string }>({
	backgroundSize: 'cover',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 17}px`,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundImage: (props) => `url(${props.imageUrl})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	overflow: 'hidden',
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	marginTop: token('space.150'),
	marginBottom: token('space.150'),

	color: token('color.text'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 7.5}px`,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border')}`,
	paddingTop: token('space.050'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContent = styled.div({
	display: 'flex',
	gap: token('space.050'),

	font: token('font.body.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.span({
	position: 'absolute',
	top: token('space.250'),
	right: token('space.300'),
});
