/**
 * @generated SignedSource<<f484e2be194c9eab86bf20a2e2829268>>
 * @relayHash 07fa485f228311d49dd69db917ab1e97
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ddefe3dbaca07d7d529f3e743eaf0ffb70bd6573940cb93fc12f3c9b85a6284c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardQuery } from './src_jiraBusinessBoardQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ddefe3dbaca07d7d529f3e743eaf0ffb70bd6573940cb93fc12f3c9b85a6284c",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
