import React, { useMemo } from 'react';
import { HeadingItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { PLANS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { plansDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/plansDirectoryRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DEFAULT_LOAD_COUNT_FAV } from '../../../../common/constants.tsx';
import { FavoriteButton } from '../../../../common/ui/menu/favourite-button/main.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { MenuItemViewAll } from '../../../../common/ui/menu/view-all/main.tsx';
import { usePlanNavItems as useItems } from '../../../../controllers/items/index.tsx';
import messages from './messages.tsx';
import type { FavoriteContentProps } from './types.tsx';

// request to include icon in atlaskit
// https://ecosystem.atlassian.net/jira/servicedesk/projects/DS/queues/issue/DS-7743
// https://jdog.jira-dev.com/browse/JPO-18296
const IconPlans = () => (
	<svg height={24} width={24} fill="none" viewBox="0 0 24 24">
		<path
			fill={token('color.icon')}
			d="M10 8a1 1 0 000 2h4a1 1 0 100-2h-4zM10 12a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zM10 14a1 1 0 100 2h2a1 1 0 100-2h-2z"
		/>

		<path
			fill={token('color.icon')}
			fillRule="evenodd"
			d="M8 4a2 2 0 012-2h4a2 2 0 012 2h1a2 2 0 012 2v12a2 2 0 01-2 2H7a2 2 0 01-2-2V6a2 2 0 012-2h1zm6 3a2 2 0 001.732-1H17v12H7V6h1.268A2 2 0 0010 7h4zm0-3h-4v1h4V4z"
			clipRule="evenodd"
		/>
	</svg>
);

export const FavoriteContent = ({ testIdPrefix }: FavoriteContentProps) => {
	const { formatMessage } = useIntl();
	const { favoriteItems, changeFavorite: onChangeFavorite, changedItems } = useItems();

	const itemsToShow = useMemo(
		() => favoriteItems.slice(0, DEFAULT_LOAD_COUNT_FAV),
		[favoriteItems],
	);

	const testId = testIdConcat(testIdPrefix, 'items-starred');

	return favoriteItems.length > 0 ? (
		<span data-testId={testId}>
			<HeadingItem testId={testIdConcat(testId, 'heading')}>
				{formatMessage(messages.starred)}
			</HeadingItem>
			{itemsToShow.map((item, idx) => {
				const { url, id, favourite: isFavourite, title } = item;
				const contextItem = changedItems[item.id.toString()];
				const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
				const itemTitle = title || formatMessage(messages.untitledPlan);

				return (
					<MenuItem
						analytics={{
							actionSubjectId: 'plansNavigationMenuItem',
							menuItemId: id,
							menuItemType: 'starred',
							position: idx,
							starred: Boolean(isFavourite),
						}}
						href={url}
						iconBefore={<IconPlans />}
						iconAfter={
							<FavoriteButton
								id={String(id)}
								isFavorite={Boolean(isFavourite)}
								favoriteItemName={itemTitle}
								isPending={isPending}
								menuItemType="starred"
								onChangeFavorite={onChangeFavorite}
								type={PLANS_ITEM_TYPE}
							/>
						}
						key={id}
						testId={testIdConcat(testId, `item-${idx}`)}
						aria-label={fg('blu-6205_misc_nav3_a11y_fixes') ? itemTitle : undefined}
					>
						{itemTitle}
					</MenuItem>
				);
			})}
			{favoriteItems.length >= DEFAULT_LOAD_COUNT_FAV && (
				<MenuItemViewAll
					analytics={{
						actionSubjectId: 'plansNavigationMenuItem',
						menuItemId: 'viewAll',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						position: DEFAULT_LOAD_COUNT_FAV as number,
					}}
					href="/jira/plans"
					testId={testIdConcat(testId, 'item-view-all')}
					to={plansDirectoryRoute}
				/>
			)}
		</span>
	) : null;
};
