import React from 'react';
import { GLOBAL_APP_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import type { GlobalAppMenuId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { SECTION_ITEM_MAP } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { Assets } from './assets/index.tsx';
import { Goals } from './goals/index.tsx';

export type GlobalAppShortcutsProps = {
	menuId: GlobalAppMenuId;
};

export function GlobalAppShortcuts({ menuId }: GlobalAppShortcutsProps) {
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const onClick = () => {
		sendSectionItemAnalyticsEvent({
			menuId,
			sectionItem: SECTION_ITEM_MAP[menuId],
		});
	};

	switch (menuId) {
		case GLOBAL_APP_MENU_ID.GLOBAL_APP_GOALS:
			return <Goals onClick={onClick} />;
		case GLOBAL_APP_MENU_ID.GLOBAL_APP_ASSETS:
			return <Assets onClick={onClick} />;
		default:
			return null;
	}
}
