import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { plansDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/plansDirectoryRoute.tsx';
import { PlansDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import type PlansDirectoryType from '@atlassian/jira-spa-apps-directories-plans/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyPlansDirectory = lazyForPaint<typeof PlansDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-plans-directory", jiraSpaEntry: "async-plans-directory" */ '@atlassian/jira-spa-apps-directories-plans'
		),
	),
);

const PlansDirectoryComponent = () => (
	<LazyPage Page={LazyPlansDirectory} pageId="plans-directory" shouldShowSpinner={false} />
);

export const plansDirectoryRouteEntry = createEntry(plansDirectoryRoute, () => ({
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental'),
	group: 'directories',
	component: PlansDirectoryComponent,
	skeleton: PlansDirectorySkeleton,
	layout: globalLayout,
	resources: [...getNavigationResources()],

	navigation: {
		menuId: MENU_ID.PLANS,
	},

	forPaint: [LazyAtlassianNavigation, LazyPlansDirectory],
}));
